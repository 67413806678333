<template>
  <transition name="fade">
    <div
      v-if="showOverlay"
      class="overlay"
      @click="onClick" />
  </transition>
</template>

<script>
export default {
  data() {
    return {
      showOverlay: false
    };
  },

  watch: {
    $potagerRoute() {
      this.showOverlay = this.$potagerRoute && (this.$potagerRoute.meta.isPanel || this.$potagerRoute.meta.isFlying);
    },
  },

  methods: {
    onClick() {
      this.$events.emit('request:closePanel');
      this.$events.emit('flyingPage:close');
    },
  },

  created() {
    this.$events.on('overlay:show', () => {
      this.showOverlay = true;
    });
    this.$events.on('overlay:hide', () => {
      this.showOverlay = false;
    });
  },

  beforeUnmount() {
    this.$events.off('overlay:show');
    this.$events.off('overlay:hide');
  },
};
</script>

<style lang="scss">
.overlay {
  @apply fixed inset-0 bg-charcoal/50 z-110 backdrop-blur min-h-screen cursor-pointer;
}
</style>
