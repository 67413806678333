<template>
  <div class="blog">
    <blog-body :is-loading="!content.id">
      <template #heading>
        <heading
          :back="{ name: 'suppliers-list' }"
          :background="content.image"
          :is-loading="!content.id"
          modifiers="blog">
          <template #title>
            {{ content.title }}
          </template>
          <template #baseline>
            <potager-badge
              v-if="content.isBio"
              class="align-middle"
              theme="forest">
              Bio
            </potager-badge>
            <template v-if="content.city">
              {{ content.city }}
              <template v-if="content.region">
                ({{ content.region }})
              </template>
            </template>
          </template>
        </heading>
      </template>

      <blog-section v-if="content.description">
        <cms
          :content="content.description"
          with-hat />
      </blog-section>

      <blog-section
        v-if="content.article && content.article.length"
        v-intersect="{
          once: true,
          callback: () => { hasIntersected = true; },
        }">
        <wordpress-article :sections="content.article" />
      </blog-section>

      <blog-social
        :item="content"
        context="supplier" />

      <blog-cross-content
        :exclude="content.id"
        :max="3"
        type="supplier" />
    </blog-body>

    <push-subscription class="mb-10 bp768:my-6" />

    <micro-datas
      :content="content"
      context="supplier" />
  </div>
</template>

<script>
import BlogMixin from 'Mixins/BlogMixin'; // Where the magic happened
import NewsletterPopupMixin from 'Mixins/NewsletterPopupMixin';

import PushSubscription from 'Components/pushs/PushSubscription';
import WordpressArticle from 'Components/article/WordpressArticle';

import PotagerBadge from 'UI/PotagerBadge';

export default {

  mixins: [
    BlogMixin,
    NewsletterPopupMixin,
  ],

  components: {
    PushSubscription,
    WordpressArticle,
    PotagerBadge,
  },

  head() {
    return this.metaInfos;
  }
};
</script>
