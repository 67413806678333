<template>
  <form
    class="potager-form"
    @submit.prevent="onSubmit">
    <slot />

    <transition name="appear-from-top">
      <potager-info
        v-if="errors.global"
        class="mb-6 mt-0"
        status="warning">
        <template #icon>
          <icon-road-sign-warning />
        </template>
        {{ errors.global }}
      </potager-info>
    </transition>

    <slot name="submit" />
  </form>
</template>

<script>
import IconRoadSignWarning from 'Icons/regular/IconRoadSignWarning';
import PotagerInfo from 'UI/PotagerInfo';
import { propsBuilder } from 'UI/Tools';

export const props = {
  models: {
    type: Array,
    required: false,
    default: () => [],
  },
  id: {
    type: [String, Number],
    required: true,
  },
};

export default {

  components: {
    IconRoadSignWarning,
    PotagerInfo,
  },

  data: () => ({
    errors: {},
  }),

  provide() {
    return {
      formId: this.id,
    };
  },

  props: propsBuilder(props),

  watch: {
    models: {
      handler() {
        this.$nextTick(() => {
          this.$emit('canSubmit', !this.hasErrors);
        });
      },
      immediate: true,
      deep: true,
    },
    hasErrors() {
      this.$emit('canSubmit', !this.hasErrors);
    },
  },

  computed: {
    hasErrors() {
      return Object.keys(this.errors).length > 0;
    },
  },

  mounted() {
    this.$events.on(`add:formError:${this.id}`, (error) => {
      delete this.errors.global;

      if (error) {
        if (typeof error === 'string' || !error.field) {
          this.errors.global = error;
        } else {
          this.errors[error.field] = error.message;
        }
      }
    });

    this.$events.on(`remove:formError:${this.id}`, (field) => {
      if (field) {
        delete this.errors[field];
      }

      delete this.errors.global;
    });
  },

  methods: {
    onSubmit() {
      this.errors = {};
      this.$events.emit(`request:formError:${this.id}`);

      this.$nextTick(() => {
        if (!this.hasErrors) {
          this.$emit('onSubmit');
        }
      });
    },
  },

  unmounted() {
    this.$events.off(`add:formError:${this.id}`);
    this.$events.off(`remove:formError:${this.id}`);
  },
};
</script>
