export default {
  name: 'datalist',

  valid(value, constraint) {
    return !constraint.options.values
      .map((option) => option.toLowerCase())
      .includes(value.toLowerCase())
      ? {
        field: constraint.field,
        message: constraint.options.message || 'Cette valeur n\'est pas valide.',
        code: null,
      }
      : null;
  },
};
