<template>
  <ul
    :class="[
      'card-box-labels flex flex-wrap',
      'absolute bottom-0 left-0 p-3 bp425:p-2 z-20 w-full',
      {
        'card-box-labels--align': labels.length > 2,
      }
    ]">
    <potager-tooltip
      v-for="(label, index) in labels"
      :key="index"
      class="card-box-labels__item mt-1 mr-1 flex items-center"
      tag="li"
      :message="label.label">
      <potager-picture
        :alt="label.image.alt"
        :src="label.image"
        class="w-10" />
    </potager-tooltip>
  </ul>
</template>

<script>
import PotagerPicture from 'UI/PotagerPicture';
import PotagerTooltip from 'UI/PotagerTooltip';

export default {

  components: {
    PotagerTooltip,
    PotagerPicture,
  },

  props: {
    labels: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.card-box-labels {
  $this: &;

  &--align {
    #{$this}__item {
      &:nth-child(odd):first-child {
        width: 100%;
      }
    }
  }
}
</style>
