/* eslint-disable no-param-reassign */
class Sticky {
  constructor(el, vm, settings) {
    this.el = el;
    this.settings = {
      placeholder: true,
      stop: false,
      left: 0,
      bottom: 0,
      ...settings,
      always: false,
    };
    this.onScroll = () => {
      if (this.canBeSticky()) {
        el.style.position = this.isFixed() ? 'fixed' : 'absolute';
        el.style.bottom = `${this.getOffsetBottom() + this.settings.bottom}px`;
        el.style.left = `${this.settings.left}px`;
        el.classList.add('sticky-element');
      } else {
        el.style.position = undefined;
        el.style.bottom = undefined;
        el.style.left = undefined;
        el.classList.remove('sticky-element');
      }
    };
    this.observer = new ResizeObserver(() => {
      this.init();
    });
    this.observer.observe(el);

    vm.$nextTick(() => {
      this.init();
      window.addEventListener('scroll', this.onScroll);
    });
  }

  init() {
    this.stop = this.settings.stop ? document.querySelector(this.settings.stop) : null;
    this.addPlaceholder();
    this.onScroll();
  }

  unmounted() {
    this.removePlaceholder();
    this.observer.disconnect();
    window.removeEventListener('scroll', this.onScroll);
  }

  addPlaceholder() {
    if (this.canBeSticky() && this.settings.placeholder) {
      const H = this.getElementHeight();

      const createPlaceholder = () => {
        this.placeholder = document.createElement('div');
        this.placeholder.style.height = `${H}px`;

        if (this.stop) {
          this.el.after(this.placeholder);
        } else {
          document.querySelector('.main-footer').appendChild(this.placeholder);
        }
      };

      if (this.placeholder) {
        const PH = this.placeholder.getBoundingClientRect?.()?.height;
        if (H !== PH) this.placeholder.style.height = `${H}px`;
      } else {
        createPlaceholder();
      }
    }
  }

  removePlaceholder() {
    if (this.placeholder) {
      this.placeholder.remove();
    }
  }

  canBeSticky() {
    return this.getElementHeight() < window.innerHeight;
  }

  isFixed() {
    if (this.settings.always) return true;
    return this.stop ? this.stop?.getBoundingClientRect?.()?.top >= window.innerHeight : true;
  }

  getElementHeight() {
    return this.el?.getBoundingClientRect?.()?.height;
  }

  getOffsetBottom() {
    return this.isFixed() ? 0 : this.stop?.getBoundingClientRect?.()?.height;
  }
}

export default {
  inserted(el, bind, vnode) {
    el.stickyBottom = new Sticky(el, vnode.context, bind.value);
  },

  unmounted(el) {
    el.stickyBottom?.unbind();
    el.stickyBottom = undefined;
  },

};
