<template>
  <potager-link
    v-gtm="{ prefix: gtmPrefix }"
    :aria-label="getLabel"
    :to="getLink"
    class="potager-app-button"
    target="_blank">

    <potager-picture
      :class="[
        'potager-app-button__img',
        `potager-app-button__img--${store}`,
      ]"
      width="148px"
      :src="getImage"
      :title="getLabel" />

    <slot />
  </potager-link>
</template>

<script>
import { APP_IOS_LINK, APP_ANDROID_LINK } from 'Classes/Constants';

import PotagerPicture from 'UI/PotagerPicture';
import { propsBuilder } from 'UI/Tools';

export const props = {
  store: {
    type: String,
    default: 'apple',
    values: ['google', 'apple'],
  },
  theme: {
    type: String,
    default: undefined,
    values: [undefined, 'stroke'],
  },
};

export default {

  components: {
    PotagerPicture,
  },

  props: propsBuilder(props),

  computed: {
    getLink() {
      return this.store === 'google' ? APP_ANDROID_LINK : APP_IOS_LINK;
    },
    getLabel() {
      return this.store === 'google' ? 'Disponible sur Google Play' : 'Télécharger dans l\'App Store';
    },
    getImage() {
      const theme = this.theme ? `-${this.theme}` : '';
      return this.store === 'google' ? `google-play${theme}.svg` : `apple-store${theme}.svg`;
    },
    gtmPrefix() {
      return `app-${this.store}`;
    },
  },
};
</script>
