<template>
  <cms
    v-if="sections && sections.length"
    :content="data"
    :is-small="isSmall"
    with-hat />
</template>

<script>
import Cms from 'Components/cms/Cms';

export default {
  components: {
    Cms,
  },

  props: {
    sections: {
      type: Array,
      required: true,
    },
    content: {
      type: String,
      required: false,
      default: null,
    },
    youtubeLink: {
      type: String,
      required: false,
      default: '',
    },
    isSmall: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    data() {
      let content = '';

      this.sections.forEach((section) => {
        content += `<h2>${section.question}</h2>`;
        if (section.photo) {
          content += `
            <a
              href="${section.photo.url}"
              seo>
              <img
                src="${section.photo.url}"
                alt="${section.photo.alt}"
                title="${section.photo.title}">
            </a>
          `;
        }
        content += section.answer;
      });

      return content;
    },
  },
};
</script>
