/**
 * Detect the version of Internet Explorer or Edge.
 *
 * @returns {number|undefined} The version number of IE/Edge, or undefined if not detected.
 */
export const IEDetectVersion = () => {
  const ua = window.navigator.userAgent;
  const match = ua.match(/(?:MSIE |Trident\/.*rv:|Edge\/)(\d+)/);
  return match ? parseInt(match[1], 10) : undefined;
};
