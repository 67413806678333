<template>
  <div class="relative bg-white text-white overflow-hidden">
    <potager-container>
      <div class="flex flex-col items-center py-24 bp1024:py-20 bp768:py-14 text-center">
        <h2 class="text-charcoal font-extrabold text-6xl bp1024:text-4xl bp375:text-4xl mb-8 bp768:mb-4">
          Ça vous botte&nbsp;?
        </h2>
        <p class="text-charcoal text-xl.5 bp768:text-lg font-normal">
          Offrez dès maintenant des corbeilles de fruits
          vraiment bons et de saison à vos salariés.
        </p>
        <div class="mt-10 bp768:flex bp768:flex-col">
          <potager-button
            :href="TALLY_URL"
            class="mr-2.5 bp768:mb-2.5 bp768:w-full"
            theme="british-racing"
            target="_blank">
            Obtenir un devis
          </potager-button>
        </div>
      </div>
    </potager-container>
  </div>
</template>

<script>
import PotagerContainer from 'UI/PotagerContainer';
import PotagerButton from 'UI/PotagerButton';
import { TALLY_URL } from 'Classes/Contents';

export default {

  components: {
    PotagerContainer,
    PotagerButton,
  },

  data: () => ({
    TALLY_URL,
  }),

};
</script>
