<template>
  <div
    v-if="isVisible"
    class="bg-go-green">
    <potager-alert
      v-if="isBadIE"
      id="IE-accepted"
      theme="zest">
      Notre site n’étant plus compatible avec Internet Explorer (<a
        href="https://www.microsoft.com/fr-fr/microsoft-365/windows/end-of-ie-support"
        target="_blank">
        Microsoft ayant annoncé la fin du maintien du service
      </a>), nous vous conseillons donc d’utiliser un autre navigateur.
    </potager-alert>

    <potager-alert
      v-if="isTestModeActivated"
      id="test-mode"
      :redirect="{ name: 'test-mode' }"
      theme="zest">
      Mode test <b>activé</b> !
    </potager-alert>

    <potager-alert
      :id="showedAlert?.id"
      :key="showedAlert?.id"
      :clickable="!!showedAlert?.action"
      :closable="showedAlert?.closable"
      :redirect="showedAlert?.link"
      :theme="showedAlert?.colorType ? showedAlert.colorType.toLowerCase() : undefined"
      :transition="alerts?.length > 0 ? 'fade' : 'collapse'"
      @onClick="showedAlert?.action ? showedAlert?.action() : null"
      @onDismiss="dismissedAlerts.push($event)">
      <span v-html="showedAlert?.message" />
    </potager-alert>

    <potager-alert
      v-if="getNeededActionOrders?.length"
      id="order-needed-action"
      :redirect="getNeededActionOrdersLink"
      closable
      theme="zest">
      ACTION REQUISE - Votre commande n°{{ getNeededActionOrders[0].number }} n’a pas encore été validée.
      <template #action>
        <potager-button
          :to="getNeededActionOrdersLink"
          size="small"
          theme="white">
          Voir ma commande
        </potager-button>
      </template>
    </potager-alert>
  </div>
</template>

<script>
import Cookies from 'js-cookie';

import { mapActions, mapGetters } from 'vuex';
import { SET_HAS_SHOWN_ALERTS_ACTION } from 'Stores/types/appActionTypes';

import { IEDetectVersion } from 'Classes/utils/BrowserDetectUtils';

import SeasonalCouponMixin from 'Mixins/SeasonalCouponMixin';

import PotagerAlert from 'UI/PotagerAlert';
import PotagerButton from 'UI/PotagerButton';

import ModalSubscribedOnHoliday from 'Modals/ModalSubscribedOnHoliday.vue';

export default {
  mixins: [
    SeasonalCouponMixin,
  ],

  components: {
    PotagerAlert,
    PotagerButton,
  },

  data: () => ({
    isBadIE: IEDetectVersion() <= 11,
    showedAlertIndex: 0,
    dismissedAlerts: [],
    interval: null,
  }),

  computed: {
    ...mapGetters('session', [
      'isLoggedIn',
      'isTestModeActivated',
    ]),
    ...mapGetters('user', [
      'getNeededActionOrders',
      'getUserAlerts',
    ]),
    actions() {
      return {
        'subscribed-on-public-holiday': () => this.$modal.open(ModalSubscribedOnHoliday)
      };
    },
    isVisible() {
      const hasAlerts = this.showedAlert || this.isBadIE || this.isTestModeActivated || this.getNeededActionOrders?.length;
      return this.$potagerRoute?.meta?.header !== false && !!hasAlerts;
    },
    getNeededActionOrdersLink() {
      return this.getNeededActionOrders.length ? {
        name: 'mon-compte_orders_detail',
        params: {
          orderId: this.getNeededActionOrders[0].id,
        },
      } : null;
    },
    showPromotedAlert() {
      // hide promoted banner on "static content" pages
      // this basically keep it on homepage, tunnel & boutique
      const allMatchNames = this.$potagerRoute.matched.map((match) => match.name);
      const excludedPageTypes = ['static', 'content'];
      const functionalConditions = !!this.promotedCoupon && this.isFirstOrder;
      const pagesCondition = allMatchNames.length > 0 && !excludedPageTypes.some((layout) => allMatchNames.includes(layout));
      return functionalConditions && pagesCondition;
    },
    promotedCouponAlert() {
      return this.promotedCoupon ? {
        id: this.promotedCoupon.id,
        message: this.promotedCoupon.promotedTitle,
        action: this.showCouponPolicy,
        colorType: 'go-green',
        closable: true,
      } : null;
    },
    alerts() {
      const userAlerts = this.getUserAlerts
        .map((alert) => ({
          ...alert,
          colorType: alert.colorType === 'beryl' ? 'beryl-green' : alert.colorType,
          action: this.actions?.[alert.type],
          closable: alert.type !== 'subscribed-on-public-holiday',
        }))
        .map((alert) => ({
          ...alert,
          message: alert.action ? `${alert.message} <b><u>En&nbsp;savoir&nbsp;plus</u></b>` : alert.message,
        }));

      return (this.showPromotedAlert
        ? [this.promotedCouponAlert, ...userAlerts]
        : userAlerts)
        .filter(alert => Cookies.get(`alert-${alert.id}`) !== 'true')
        .filter(alert => !this.dismissedAlerts
          .includes(alert.id));
    },
    showedAlert() {
      return this.alerts[this.showedAlertIndex];
    },
  },

  methods: {
    ...mapActions('app', {
      SET_HAS_SHOWN_ALERTS_ACTION
    }),
    setInterval() {
      // alternate between alerts each 10 seconds
      this.interval = setInterval(() => {
        this.showedAlertIndex = (this.showedAlertIndex + 1) % this.alerts.length;
      }, 10000);
    },
  },

  watch: {
    '$route.name'() {
      this.showedAlertIndex = 0; // Else, we have an issue when we route changes from a page that allows promoted alert to a page that doesn't
    },
    alerts: {
      handler(next, prev) {
        const hadMultipleAlerts = prev?.length > 1;
        const hasMultipleAlerts = next?.length > 1;

        if (!hadMultipleAlerts && hasMultipleAlerts) {
          this.setInterval();
        } else if (hadMultipleAlerts && !hasMultipleAlerts) {
          clearInterval(this.interval);
        }
      },
      immediate: true,
      deep: true,
    },
    isVisible: {
      handler(isVisible) {
        this.SET_HAS_SHOWN_ALERTS_ACTION(isVisible);
      },
      immediate: true,
    },
  },
};
</script>
