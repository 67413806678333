import MetaInfosService from 'Classes/services/MetaInfoService';

export default {
  methods: {
    generateMetaIfPanelClosed(config) {
      if (!config) return {};
      if (window.location.href.includes('#/')) return {};
      return MetaInfosService.generate(config);
    },
  },
};
