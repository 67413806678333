<template>
  <div
    v-if="pagerState && pagerState.nbPages > 1"
    class="pager">
    <potager-button
      :class="[
        'pager__btn',
        'pager__btn--prev',
      ]"
      :is-disabled="firstPageSelected"
      :to="prevPageRoute"
      theme="stroke-grey">
      <template #icon>
        <icon-arrow-left1 />
      </template>
    </potager-button>

    <template v-for="(page, i) in pages">
      <potager-button
        v-if="page.breakView"
        :key="`breakview-${i}`"
        :class="[
          'pager__btn',
          'pager__btn--breakview',
        ]"
        :is-disabled="true"
        label="..."
        theme="stroke-grey" />
      <potager-button
        v-else
        :key="i"
        :class="{
          'pager__btn': true,
          'pager__btn--index': true,
          'pager__btn--selected': page.selected,
        }"
        :is-disabled="page.disabled"
        :label="page.index"
        :theme="page.selected ? 'default' : 'stroke-grey'"
        :to="getPageRoute(page.index)" />
    </template>

    <potager-button
      :class="[
        'pager__btn block',
        'pager__btn--next',
      ]"
      :is-disabled="lastPageSelected"
      :to="nextPageRoute"
      theme="stroke-grey">
      <template #icon>
        <icon-arrow-right1 />
      </template>
    </potager-button>
  </div>
</template>

<script>
import IconArrowLeft1 from 'Icons/regular/IconArrowLeft1';
import IconArrowRight1 from 'Icons/regular/IconArrowRight1';
import PotagerButton from 'UI/PotagerButton';

export default {

  components: {
    IconArrowLeft1,
    IconArrowRight1,
    PotagerButton,
  },

  data: () => ({
    pageRange: 3,
    marginPages: 1,
  }),

  props: {
    response: {
      type: Object,
      required: false,
      default: null,
    },
  },

  computed: {
    firstPageSelected() {
      return this.pagerState.currentPage === 1;
    },
    lastPageSelected() {
      return (this.pagerState.currentPage === this.pagerState.nbPages) || (this.pagerState.nbPages === 1);
    },
    nextPageRoute() {
      const targetPage = this.pagerState.currentPage + 1;
      return this.getPageRoute(targetPage);
    },
    prevPageRoute() {
      const targetPage = this.pagerState.currentPage - 1;
      return this.getPageRoute(targetPage);
    },
    pages() {
      const items = {};
      if (this.pagerState.nbPages <= this.pageRange) {
        for (let index = 1; index <= this.pagerState.nbPages; index += 1) {
          const page = {
            index,
            selected: index === this.pagerState.currentPage,
            route: this.getPageRoute(index),
          };
          items[index] = page;
        }
      } else {
        const halfPageRange = Math.floor(this.pageRange / 2);
        const setPageItem = (index) => {
          const page = {
            index,
            selected: index === this.pagerState.currentPage,
            route: this.getPageRoute(index),
          };
          items[index] = page;
        };
        const setBreakView = (index) => {
          const breakView = {
            disabled: true,
            breakView: true,
          };
          items[index] = breakView;
        };
        // 1st - loop thru low end of margin pages
        for (let i = 1; i <= this.marginPages; i += 1) {
          setPageItem(i);
        }
        // 2nd - loop thru selected range
        let selectedRangeLow = 1;
        if (this.pagerState.currentPage - halfPageRange > 0) {
          selectedRangeLow = this.pagerState.currentPage - halfPageRange;
        }
        let selectedRangeHigh = selectedRangeLow + this.pageRange - 1;
        if (selectedRangeHigh >= this.pagerState.nbPages) {
          selectedRangeHigh = this.pagerState.nbPages;
          selectedRangeLow = selectedRangeHigh - this.pageRange + 1;
        }

        for (let i = (selectedRangeLow - 1); i < (selectedRangeHigh + 1) && i < this.pagerState.nbPages; i += 1) {
          setPageItem(i + 1);
        }
        // Check if there is breakView in the left of selected range
        if (selectedRangeLow > this.marginPages + 1) {
          setBreakView(selectedRangeLow - 1);
        }
        // Check if there is breakView in the right of selected range
        if (selectedRangeHigh < this.pagerState.nbPages - this.marginPages) {
          setBreakView(selectedRangeHigh + 1);
        }
        // 3rd - loop thru high end of margin pages
        for (let i = this.pagerState.nbPages; i > this.pagerState.nbPages - this.marginPages; i -= 1) {
          setPageItem(i);
        }
      }

      return items;
    },
    targetRoute() {
      const { $route } = this;
      return {
        name: $route.name,
        query: { ...$route.query },
        params: { ...$route.params },
      };
    },
    pagerState() {
      return {
        currentPage: this.response?.currentPage,
        maxPerPage: this.response?.maxPerPage,
        nbPages: this.response?.nbPages,
        previousPage: this.response?.previousPage,
        nextPage: this.response?.nextPage,
        nbResults: parseInt(this.response?.nbResults, 10),
      };
    },
  },

  methods: {
    getPageRoute(pageNumber) {
      const routeObject = this.targetRoute;
      routeObject.params.page = pageNumber === 1 ? null : pageNumber;
      const route = this.$router.resolve(routeObject);
      return route.href;
    },
  },

};
</script>
