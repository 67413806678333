<template>
  <component
    :is="background ? 'potager-picture' : 'div'"
    v-if="!isInAppWebView"
    :class="[
      'heading',
      extraclasses,
      { 'heading--loading': isLoading, },
    ]"
    :height="modifiers?.includes('v2') ? '350px' : undefined"
    :position="backgroundPosition"
    :src="background"
    :title="title"
    background
    no-lazy-load
    placeholder>
    <potager-button
      v-if="back"
      :to="back"
      class="heading__back print:hidden"
      is-circle
      theme="white">
      <template #icon>
        <icon-arrow-left1 />
      </template>
    </potager-button>

    <potager-container
      v-if="!isLoading"
      class="heading__container">
      <h1 class="heading__title">
        <slot name="title" />
      </h1>

      <p
        v-if="$slots.baseline"
        class="heading__baseline">
        <slot name="baseline" />
      </p>

      <slot />
    </potager-container>

    <slot name="bottom" />
  </component>
</template>

<script>
import isArray from 'lodash/isArray';

import IconArrowLeft1 from 'Icons/regular/IconArrowLeft1';

import PotagerContainer from 'UI/PotagerContainer';
import PotagerButton from 'UI/PotagerButton';
import PotagerPicture, { props as potagerPictureProps } from 'UI/PotagerPicture';

import MobileAppMixin from 'Mixins/MobileAppMixin';

export default {
  components: {
    IconArrowLeft1,
    PotagerContainer,
    PotagerButton,
    PotagerPicture,
  },

  data: () => ({
    componentName: 'heading',
  }),

  mixins: [
    MobileAppMixin,
  ],

  props: {
    background: {
      type: [String, Array, Object],
      required: false,
      default: null,
    },
    backgroundPosition: potagerPictureProps.position,
    title: {
      type: String,
      required: false,
      default: null,
    },
    back: {
      type: Object,
      required: false,
      default: null,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    modifiers: {
      type: [String, Array],
      required: false,
      default: null,
      validator: (val) => {
        const values = ['text-black', 'smaller', 'blog', 'blog-list', 'simple', 'concept', 'v2'];
        if (typeof val === 'string') return values.includes(val);
        return !val.filter((v) => !values.includes(v)).length;
      },
    },
  },

  computed: {
    extraclasses() {
      const {
        modifiers,
        componentName: base,
      } = this;
      const list = [];

      if (modifiers && base) {
        if (typeof modifiers === 'string') {
          list.push(`${base}--${modifiers}`);
        } else if (isArray(modifiers)) {
          modifiers.forEach((m) => {
            list.push(`${base}--${m}`);
          });
        } else if (typeof modifiers === 'object') {
          const renameModifiers = Object.keys(modifiers)
            .map((key) => {
              const newKey = `${base}--${key}`;
              return { [newKey]: modifiers[key] };
            });
          list.push(renameModifiers);
        }
      }
      return list;
    },
  },

};
</script>

<style lang="scss" scoped>
.heading {
  $this: &;

  &--v2 {
    @apply text-center;

    #{$this} {
      &__baseline {
        max-width: inherit;
      }

      &__baseline,
      &__title {
        @apply block text-center mx-auto;
        text-shadow: 0 0 60px rgba(0, 0, 0, 1);
      }

      &__title {
        @apply text-4xl bp768:text-3xl.5 font-extrabold;
      }

      &__baseline {
        @apply text-xl.5 bp768:text-lg font-bold;
      }
    }
  }
}
</style>
