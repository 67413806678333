export const UPDATE_BASKET_ACTION = 'UPDATE_BASKET_ACTION';
export const ADD_BOX_BASKET_ACTION = 'ADD_BOX_BASKET_ACTION';
export const DELETE_BOX_BASKET_ACTION = 'DELETE_BOX_BASKET_ACTION';
export const EDIT_BOX_QUANTITY_BASKET_ACTION = 'EDIT_BOX_QUANTITY_BASKET_ACTION';

export const UPDATE_DELIVERY_POINT_BASKET_ACTION = 'UPDATE_DELIVERY_POINT_BASKET_ACTION';
export const UPDATE_DELIVERY_DATE_INDEX_ACTION = 'UPDATE_DELIVERY_DATE_INDEX_ACTION';
export const SET_BASKET_EXPIRATION_ACKNOWLEDGE_ACTION = 'SET_BASKET_EXPIRATION_ACKNOWLEDGE_ACTION';

export const EMPTY_BASKET_ACTION = 'EMPTY_BASKET_ACTION';
