<template>
  <div>

    <heading
      :modifiers="['smaller', 'text-black']"
      :background="null">
      <template #title>
        Conditions générales de vente
      </template>
    </heading>

    <blog-body>
      <blog-section>
        <cms>
          <h2>
            CGV en vigueur au 1er juin 2022 – potagercity.fr
          </h2>
          <p>
            La société Potager City au capital de 31 037€ est immatriculée au RCS de Lyon sous le n°499 600 781 (N° de
            TVA intracommunautaire : FR65499600781). Elle exploite en France métropolitaine un site Internet accessible
            aux adresses suivantes : www.potagercity.fr / www.potagercity.com / www.potagercity.net /
            www.potagercity.org / www.potager-city.fr / www.potager-city.com / www.potager-city.net /
            www.potager-city.org
          </p>
          <p>
            En utilisant le Service proposé par Potager City, vous acceptez l’ensemble des conditions générales de vente
            telles que mentionnées ci-après.
          </p>

          <h2>
            Définitions
          </h2>
          <p>
            Chaque mot ou expressions ci-après aura, lorsqu’il ou elle sera employé(e) avec une majuscule dans le
            présent contrat, au singulier ou au pluriel, la signification suivante : « Commande » désigne le contrat de
            vente conclu entre le Consommateur et Potager City, au moyen du Site Potagercity.fr / Potagercity.com /
            Potagercity.net / Potagercity.org / Potager-city.fr / Potager-city.com / Potager-city.net /
            Potager-city.org. « Conditions Générales de Vente et d’utilisation » désigne les conditions générales de
            vente des Produits aux Consommateurs, accessibles à l’adresse http://potagercity.fr et lors de la
            confirmation d’une commande. « Consommateur » désigne la personne physique, majeure et capable, passant une
            Commande sur le Site www.potagercity.fr. « Service » désigne la vente par la société Potager City aux
            Consommateurs de Produits commandés par ceux-ci sur le site www.potagercity.fr. « Site » désigne le site
            internet exploité par la société Potager City accessible sous l’adresse www.potagercity.fr /
            www.potagercity.com / www.potagercity.net / www.potagercity.org / www.potager-city.fr / www.potager-city.com
            / www.potager-city.net / www.potager-city.org
          </p>
        </cms>
      </blog-section>

      <blog-section>
        <cms>
          <h2>
            A. Objet
          </h2>
          <p>
            Les présentes Conditions Générales de Vente et d’utilisation ont pour objet de définir les termes et
            conditions auxquels Potager City offre la possibilité au Consommateur d’acheter des produits ou services,
            présentés sur le site www.potagercity.fr.
          </p>

          <h2>
            B. Inscription
          </h2>
          <ol>
            <li>
              Lors du passage de sa première commande, le Consommateur procède à son inscription en remplissant un
              formulaire disponible sur le Site www.potagercity.fr
            </li>
            <li>
              A cette occasion, le Consommateur s’engage à fournir des informations exactes et complètes et à
              actualiser, le cas échéant, les informations fournies à Potager City dans les plus brefs délais. Le
              Consommateur pourra procéder à la modification des informations le concernant directement en ligne, via
              son compte. Potager City s’engager à préserver la confidentialité des informations fournies par le
              Consommateur, qui disposera d’un droit d’accès, de modification ou de suppression sur les données le
              concernant, conformément aux dispositions de la loi n°78-17 relative à l’informatique, aux fichiers et aux
              libertés. Il pourra l’exercer en suivant les instructions détaillées sur la
              <potager-link :to="{ name:'confidentialite-et-donnees-personnelles' }">
                page Confidentialité et données personnelles.
              </potager-link>
              Au cas où l’une de ces informations s’avérerait fausse, incomplète ou obsolète, Potager City se
              réserve le droit, sans aucune indemnité et sans préavis, de suspendre ou de fermer le compte du
              Consommateur et de lui refuser pour l’avenir l’accès à tout ou partie des parties des produits ou services
              proposés par Potager City, sans préjudice de la réparation que Potager City serait fondée à réclamer au
              titre du préjudice y afférent.
            </li>
            <li>
              Lors de la validation de son inscription au Service, le Consommateur pourra utiliser soit son adresse
              électronique associée à un mot de
              passe, soit la connexion via un partenaire tiers (Google ou Apple) qui lui permettront d’accéder au
              Service.
            </li>
            <li>
              Le Consommateur devra impérativement préserver la
              confidentialité de cet identifiant et de son mot de passe afin d’éviter toute utilisation non autorisée
              par un tiers. A défaut, le Consommateur sera réputé responsable de toutes les violations des présentes
              Conditions Générales d’Utilisation commises par ledit tiers. Toute utilisation du Service effectuée par le
              biais du mot de passe d’un Consommateur est réputée effectuée par ce dernier. Potager City ne pourra être
              tenu responsable de toute perte ou dommage survenu en raison du manquement aux obligations de
              confidentialité incombant au Consommateur.
            </li>
          </ol>

          <h2>
            C. Acceptation des conditions générales de vente
          </h2>
          <ol>
            <li>
              Le Service est proposé au Consommateur sous réserve de son acceptation inconditionnelle des présentes
              Conditions Générales de Vente. En validant son inscription au Service, le Consommateur déclare et
              reconnaît, en conséquence, avoir lu, compris et accepté sans réserve les présentes Conditions Générales de
              Vente.
            </li>
            <li>
              Potager City se réserve le droit de modifier en tout ou partie et à tout moment les présentes Conditions
              Générales de Vente en informant préalablement le Consommateur desdites modifications, par publication des
              modifications sur le Site. Toutes les modifications seront effectives 30 jours après avoir été mises en
              ligne sur le Site. Il appartient en conséquence au Consommateur de se référer régulièrement à la dernière
              version des Conditions Générales de Vente disponible en permanent sur le Site www.potagercity.fr. Le
              Consommateur a la possibilité de résilier son compte sans aucune pénalité dans les 30 jours suivant la
              notification ou la publication des dites modifications sur simple demande adressée à Potager City par
              l’intermédiaire du formulaire à l’adresse suivante : www.potagercity.com/contact/. A défaut, le
              Consommateur est réputé accepter cette dernière version à chaque nouvelle Commande.
            </li>
            <li>
              En cas de non-respect des présentes Conditions Générales de Vente, Potager City se réserve le droit, sans
              aucune indemnité ni préavis, de suspendre ou fermer le compte du Consommateur et de lui refuser pour
              l’avenir l’accès à tout ou partie du Service, sans préjudice des différentes actions délictuelles ou
              contractuelles de droit commun qui pourraient lui être ouvertes.
            </li>
          </ol>

          <h2>
            D. Abonnement
          </h2>
          <p>
            Lors de son inscription sur le Site, le Consommateur choisit de souscrire à une formule d’abonnement
            hebdomadaire d’une durée indéterminée ou de commander quand il le souhaite.<br>
          </p>
          <ol>
            <li>
              <p>
                En cas d’abonnement, la commande hebdomadaire sera automatiquement reconduite tous les jeudis, à moins
                que :
              </p>
              <ul>
                <li>
                  le Consommateur ne suspende l’abonnement à partir de son compte, au plus tard deux jours calendaires
                  avant
                  le jour prévu de la prochaine livraison. Le Consommateur aura la possibilité de reprendre l’abonnement
                  à
                  tout moment à partir de son compte.
                </li>
                <li>
                  le Consommateur n’annule la prochaine livraison prévue à partir de son compte, au plus tard deux jours
                  calendaires avant le jour prévu de la prochaine livraison. Ainsi :
                  <ul>
                    <li>
                      Si la livraison est prévue le mardi, la suspension ou l’annulation devra être effectuée avant le
                      dimanche précédent à 23h59.
                    </li>
                    <li>
                      Si la livraison est prévue le mercredi, la suspension ou l’annulation devra être effectuée avant
                      le
                      lundi précédent à 23h59.
                    </li>
                    <li>
                      Si la livraison est prévue le jeudi, la suspension ou l’annulation devra être effectuée avant le
                      mardi précédent à 23h59.
                    </li>
                    <li>
                      Si la livraison est prévue le vendredi, la suspension ou l’annulation devra être effectuée avant
                      le mercredi précédent à 23h59.
                    </li>
                  </ul>
                </li>
                <li>
                  Potager City n’assure pas de livraison de commandes sur le point de retrait choisi par le consommateur
                  à la date de livraison prévue dans son abonnement (jour férié, point de retrait fermé…)
                </li>
              </ul>
              <p>
                A défaut de suspension de l’abonnement ou d’annulation de la prochaine livraison dans les conditions
                susvisées, la prochaine livraison ne fera l’objet d’aucun remboursement au Consommateur. En outre, dans
                ce cas, la suspension de l’abonnement ne prendra effet qu’après la dite livraison.
              </p>
              <p>
                En cas de désabonnement, le Consommateur a la possibilité de reprendre l’abonnement à tout moment à
                partir de son compte.
              </p>
              <p>
                Le Consommateur a la possibilité de commander ponctuellement des produits complémentaires à son
                abonnement sur le Site dans les conditions décrites à l’article E des présentes Conditions Générales de
                Vente et d’utilisation. Cette possibilité est totalement libre et gratuite, sous réserve du paiement du
                seul prix des produits commandés.
              </p>
            </li>
            <li>
              <p>
                En l’absence d’abonnement, le Consommateur a la possibilité de commander quand il le souhaite
                sur le Site dans les conditions décrites à l’article E des présentes Conditions Générales de Vente et
                d’utilisation. Cette possibilité est totalement libre et gratuite, sous réserve du paiement du seul prix
                des produits commandés.
              </p>
            </li>
          </ol>

          <h2>
            E. Processus de commande
          </h2>
          <ol>
            <li>
              Dans le cas où le Consommateur a un abonnement actif, il peut compléter sa commande chaque semaine avec
              des paniers complémentaires et/ou des produits à l’unité. Le Consommateur a la possibilité de choisir
              parmi l’ensemble des produits
              proposés à la vente celui ou ceux qu’il souhaite ajouter à la prochaine livraison prévue en cliquant sur
              le bouton « Ajouter à ma commande ». Lesdits produits seront automatiquement ajoutés à la Commande faisant
              l’objet de la prochaine livraison prévue, sous réserve que l’ajout desdits produits ait été effectué au
              moins 2 jours calendaires avant la prochaine livraison prévue.
            </li>
            <li>
              Pour les cas où le Consommateur aurait suspendu son abonnement ou aurait choisi de ne pas s’abonner, le
              Consommateur a la possibilité de passer
              une Commande ponctuelle. A cet effet, le Consommateur peut choisir parmi l’ensemble des produits proposés
              à la vente celui ou ceux qu’il souhaite acheter en cliquant sur le bouton « Ajouter à ma commande ». Le
              Consommateur doit ensuite valider le contenu du panier.
            </li>
            <li>
              Le Consommateur peut annuler librement la livraison de ses prochaines Commandes en se connectant à son
              compte. L’annulation doit survenir au plus tard deux jours calendaires avant le jour prévu de la
              livraison. A défaut d’annulation dans ce délai, la prochaine livraison ne fera l’objet d’aucun
              remboursement au Consommateur.
              <ul>
                <li>
                  Si la livraison est prévue le mardi, l’annulation sera possible jusqu’au dimanche précédent à
                  23h59.
                </li>
                <li>
                  Si la livraison est prévue le mercredi, l’annulation sera possible jusqu’au lundi précédent à
                  23h59.
                </li>
                <li>Si la livraison est prévue le jeudi, suspension possible jusqu’au mardi précédent à 23h59</li>
                <li>
                  Si la livraison est prévue le vendredi, suspension possible jusqu’au mercredi précédent à 23h59.
                </li>
              </ul>
            </li>
            <li>
              Dans le cadre d’une commande hors abonnement, l’annulation de la livraison est impossible depuis le compte
              du client. Le Consommateur devra contacter le service client de Potager City par courrier électronique ou
              via le formulaire de contact qui étudiera la faisabilité de l’annulation.
            </li>
            <li>
              Le Consommateur reconnaît avoir pris connaissance et avoir accepté les conditions générales de vente. Les
              paniers proposés par Potager City sont annoncés avec une fourchette de poids la plus précise possible. Les
              aléas de production et l’utilisation de produits non calibrés peuvent faire varier les poids des paniers
              annoncés de quelques centaines de grammes. Les photos et mises en avant graphiques ne sont pas
              contractuelles.
            </li>
            <li>
              Les systèmes d’enregistrement automatique des Commandes mis en œuvre par Potager City sont expressément
              admis comme valant preuve de la nature, du contenu, du prix et de la date de la Commande par le
              Consommateur.
            </li>
            <li>
              Dès réception de la Commande, Potager City confirme au Consommateur la Commande, son montant total ainsi
              que l’identité de la société Potager City auprès de laquelle le Consommateur passe la Commande. Cette
              confirmation est effectuée par courrier électronique à l’adresse communiquée par le Consommateur. Il est
              stipulé que le contrat de vente n’est formé qu’à compter de la confirmation de la Commande par Potager
              City.
            </li>
            <li>
              Dans l’hypothèse où l’un des produits ou services objets de la Commande ne serait plus disponible à la
              vente, Potager City en avertira immédiatement par courrier électronique le Consommateur qui bénéficiera en
              fonction du produit concerné soit d’un remboursement sous forme d’avoir à valoir sur une prochaine
              commande soit d’un produit de substitution du dit produit par un produit équivalent, au même prix, proposé
              par Potager City.
            </li>
            <li>
              Le consommateur s’interdit de revendre les Produits. Toute Commande passée par un Consommateur est pour
              son usage personnel ou pour l’usage personnel de la personne au nom de laquelle la livraison doit être
              effectuée.
            </li>
          </ol>

          <h2>
            F. Modalités de paiement
          </h2>
          <ol>
            <li>
              Le paiement des produits et services achetés par le Consommateur à Potager City s’effectue par carte
              bancaire, dans un environnement sécurisé sur le serveur du prestataire de paiement de Potager City,
              Stripe et/ou par Compte de Titres Restaurant auprès des prestataires Edenred et Swile. Le paiement des
              Produits achetés par le Consommateur est effectué en Euros.
            </li>
            <li>
              Une fois la Commande validée par le Consommateur, les coordonnées bancaires communiquées par le
              Consommateur auprès du serveur du partenaire de paiement de Potager City sont utilisées par ledit
              partenaire de paiement afin de vérifier au premier Euro la solvabilité du compte bancaire correspondant
              aux coordonnées bancaires communiquées par le Consommateur. Dans l’hypothèse où le compte du Consommateur
              ne serait pas provisionné, Potager City pourra refuser la Commande en refusant de confirmer la Commande,
              la conclusion du contrat de vente n’intervenant que sous condition suspensive de la confirmation de la
              Commande par Potager City. Une fois la commande validée par Potager City, le compte bancaire du
              Consommateur est débité le jour de la livraison de la commande de la somme correspondant aux produits et
              services de sa Commande.
            </li>
            <li>
              Dans le cadre du rattachement d’un compte Titres Restaurant Swile ou Edenred, Potager City tentera en
              premier lieu de prélever la somme correspondant au total de la commande sur le dit compte Titres
              Restaurant. En cas de disponibilité des fonds nécessaires au paiement intégral de la commande, le montant
              sera prélevé sur le compte Titres Restaurant. Dans le cas d’une disponibilité partielle, Potager City
              prélèvera le maximum du montant disponible sur le compte Titres Restaurant et le montant restant sur la
              carte bancaire enregistrée comme moyen de paiement principal par le Consommateur dans son compte Potager
              City. Dans le cas d’une absence de fonds sur le compte Titres Restaurant, Potager City prélèvera
              l’intégralité du montant de la commande sur la carte bancaire enregistrée comme moyen de paiement
              principal par le Consommateur dans son compte Potager City.
            </li>
            <li>
              Durant ses achats, le Consommateur peut être amené à utiliser des codes de réduction. Les codes promo sont
              soumis à des règles d’utilisation qui peuvent varier en fonction des conditions : sur un modèle de
              commande, avec un montant minimum de commande, uniquement sur la première commande, si un même type de
              code promo n’est pas cumulable, etc. Dans le cas où le Consommateur se heurte à un blocage d’application de
              code promo suite une règle, un message d’information se déclenchera et affichera la raison du blocage.
            </li>
          </ol>

          <h2>
            G. Remise des produits
          </h2>
          <ol>
            <li>
              Le Consommateur qui a commandé doit être présent au lieu, date et créneau horaire choisis lors de sa
              Commande afin de réceptionner sa Commande.
            </li>
            <li>
              Lors de la remise, le Consommateur doit être en mesure de prouver son identité et de fournir la référence
              de sa Commande figurant sur la confirmation envoyée par Potager City. Le Consommateur s’engage, si on le
              lui demande, à signer le bon d’émargement présenté par la personne chargée de la livraison. Par la
              signature du bon d’émargement, le Consommateur reconnaît avoir reçu les Produits objets de sa Commande.
              Sur le bon d’émargement, le Consommateur peut porter toute mention qui lui semble nécessaire. Dans
              l’hypothèse où le Consommateur ne serait pas en mesure de recevoir personnellement les Produits qu’il a
              commandés, il pourra donner procuration à un tiers de réceptionner la livraison. Cette personne pourra
              alors signer le bon d’émargement et de faire toute remarque utile sur ce dernier.
            </li>
            <li>
              Dans l’hypothèse où Potager City se trouve dans l’impossibilité de respecter les délais prévus, le
              Consommateur en est informé par tout moyen tel que courrier électronique ou téléphone.
            </li>
          </ol>

          <h2>
            H. Responsabilité et garantie
          </h2>
          <ol>
            <li>
              Le Consommateur demeure seul juge du contenu approprié et adapté des produits ou services commandés à
              Potager City.
            </li>
            <li>
              Le Consommateur est informé de ce qu’il ne pourra rechercher la responsabilité de Potager City au titre de
              la responsabilité du fait des produits défectueux que, conformément aux dispositions de l’article 1245 du
              Code civil, s’il justifie d’un défaut affectant le produit que lui aurait vendu Potager City, l’existence
              d’un préjudice spécifique, et d’un lieu de causalité entre ce défaut et ce préjudice.
            </li>
            <li>
              Le Consommateur accepte de dégager Potager City, ses dirigeants, agents, préposés, prestataires ou
              employés, de toute responsabilité pour tous les préjudices résultant de l’utilisation du Site
              www.potagercity.fr, à ce titre, et sans limiter la portée des autres dispositions des présentes Conditions
              Générales de Vente, Potager City ne peut notamment être considéré, hors le cas de sa faute directe, comme
              responsable des dommages résultant : de la perte, de l’altération ou de l’accès frauduleux à des données ;
              de la transmission accidentelle par le biais du service ou par voie de courrier électronique de virus ou
              d’autres éléments nuisibles ; de l’attitude, de la conduite ou du comportement d’un tiers ou d’un autre
              Consommateur ; de la non-conclusion d’une vente ou d’un achat ; du coût des biens ou services de
              remplacement.
            </li>
            <li>
              Les réparations dues par Potager City en cas de défaillance qui résulteraient d’une faute établie à son
              encontre correspondront au seul préjudice direct. Ces dommages indirects sont ceux qui ne résultent pas
              directement et exclusivement de la défaillance des prestations de Potager City.
            </li>
            <li>
              Potager City ne garantit pas que le Service proposé sera continu, sans interruption provisoire, sans
              suspension ou sans erreur. Potager City se réserve le droit de suspendre ou de retirer un bien ou un
              service, pour des raisons techniques ou en cas de violation des stipulations contractuelles et/ou des
              dispositions légales. Potager City ne peut engager sa responsabilité pour les dommages consécutifs à de
              tels faits.
            </li>
          </ol>

          <h2>
            I. Force majeure
          </h2>
          <p>
            Tous les événements, de quelque nature qu’ils soient, échappant à la volonté de Potager City, irrésistibles,
            imprévisibles et tendant à retarder ou à empêcher l’exécution de la Commande constituent, de convention
            expresse, une cause de suspension et/ou d’extinction des obligations de Potager City envers le Consommateur,
            sans indemnité au profit du Consommateur, qui ne pourra prétendre, le cas échéant, qu’au remboursement de sa
            Commande.
          </p>

          <h2>
            J. Données personnelles, Cookies
          </h2>
          <ol>
            <li>
              Les données personnelles recueillies sur le Consommateur lors de son inscription au Service sont soumises
              aux dispositions de la loi n°78-17 du 6 janvier 1978 relative à l’Informatique, aux fichiers et aux
              Libertés. Le Consommateur dispose en conséquence du droit d’opposition prévu à l’article 26 de ladite loi,
              du droit d’accès prévu aux articles 34 à 38 de ladite loi, et du droit de rectification prévu à l’article
              36 de ladite loi. Ces droits d’accès et de rectification peuvent être exercés par courrier électronique,
              via le formulaire de contact du site Internet. Potager City ne communique pas à des tiers les données
              personnelles recueillies sur le Consommateur et ne les transfère pas en dehors de l’Espace Economique
              Européen.
            </li>
            <li>
              La connexion à un serveur Internet a pour effet d’envoyer au navigateur un certain nombre de données qui
              sont stockées sur le disque dur de l’ordinateur du Consommateur. Les logiciels permettant de recueillir
              ces informations s’appellent des « cookies ». Le Consommateur peut, s’il le désire, modifier son
              navigateur de façon à refuser les cookies. Cette manipulation n’empêchera pas la connexion sur le Site
              Potager City, mais pourra retarder les délais de chargement des pages et nécessitera une identification
              systématique du Consommateur lors de sa connexion au Site Potager City par la communication de son login
              et de son mot de passe.
            </li>
          </ol>

          <h2>
            K. Propriété intellectuelle
          </h2>
          <p>
            Le code et l’agencement des pages, les textes, le logo et les personnages Potager City sont la propriété
            exclusive de la société Potager City. Ces éléments sont protégés par la loi française et les lois
            internationales de protection de la propriété intellectuelle. Les marques « Potager City », et les logos et
            personnages « Potager City » sont des marques déposées. Leur reproduction constitue, par conséquent, une
            contrefaçon.
          </p>

          <h2>
            L. Liens hypertextes
          </h2>
          <ol>
            <li>
              Le Site www.potagercity.fr se réserve le droit de créer des liens hypertextes dirigeant vers des sites
              Internet tiers ou d’autres sources Internet. Des tiers peuvent créer des liens hypertextes dirigeant vers
              le Site www.potagercity.fr. Dans la mesure où Potager City ne peut contrôler ces sites et ces sources
              externes, le Consommateur reconnaît que Potager City ne peut être tenu pour responsable de la mise à
              disposition de ces sites et sources externes. La société Potager City ne peut, par conséquent, être tenue
              pour responsable quant aux contenus, publicités, contenus, produits, services ou tout autre matériel
              disponibles sur ou à partir de ces sites ou sources externes. En outre, le Consommateur reconnaît que la
              société Potager City ne peut être tenue pour responsable de tous dommages ou pertes avérés ou allégués,
              consécutifs ou en relation avec l’utilisation ou avec le fait d’avoir fait confiance au contenu, à des
              biens ou des services disponibles sur ou à partir de ces sites ou sources externes.
            </li>
            <li>
              Aucune responsabilité ne peut être retenue à l’encontre de Potager City quant au contenu des sites
              référencés inclus dans l’index, ni pour toute erreur ou omission contenue sur lesdits sites Internet, ni
              pour tout contenu non objectif, licite ou illicite diffusé sur lesdits sites Internet.
            </li>
            <li>
              Le Consommateur reconnaît qu’il doit faire preuve de discernement et supporter tous les risques afférents
              à l’utilisation qu’il fait du contenu et, notamment, lorsqu’il se fie à l’opportunité, l’utilité ou le
              caractère complet de ce contenu, qu’il soit créé par Potager City ou par des tiers accessible à partir du
              Site www.potagercity.fr, par des liens et notamment par ses services de recherche.
            </li>
          </ol>

          <h2>
            M. Modification et interruption de service
          </h2>
          <p>
            Potager City se réserve le droit, à tout moment, de modifier ou d’interrompre de manière temporaire ou
            définitive tout ou partie du Service proposé pour des raisons techniques ou de mise en conformité du
            Consommateur avec les stipulations contractuelles ou les dispositions légales, et ce sans avoir à en
            informer préalablement le Consommateur. Le Consommateur ne peut engager la responsabilité de Potager City à
            raison des modifications et interruptions effectuées.
          </p>

          <h2>
            N. Résiliation pour faute du consommateur
          </h2>
          <p>
            En cas de violations ou d’agissements par le Consommateur contraires aux présentes Conditions Générales de
            Vente, Potager City peut mettre fin à tout ou partie du droit d’accès correspondant à son compte et son mot
            de passe, voire de supprimer son compte et son mot de passe ; et/ou retirer ou déplacer tout ou partie du
            contenu concernant le Consommateur et disponible sur le Site www.potagercity.fr; et/ou interrompre
            unilatéralement et à tout moment la fourniture du Service. La résiliation pour faute de l’accès au Service
            en application des termes des présentes Conditions Générales d’Utilisation peut intervenir sans mise en
            œuvre préalable. Le cas échéant, Potager City est en droit de désactiver ou supprimer à tout moment et avec
            effet immédiat son compte et tout dossier ou fichier présent dans ce compte et/ou interdire tout accès
            ultérieur à ces fichiers ou aux Services. Enfin, le Consommateur reconnaît que la société Potager City ne
            pourra être tenue pour responsable à son encontre ou à l’encontre de tiers pour toute résiliation de son
            accès au Service motivée par la violation des présentes Conditions Générales d’Utilisation et/ou de
            dispositions légales.
          </p>
          <p>
            Tout <b>usage abusif</b> du code de parrainage personnel d’un client fera l'objet d'une suppression du
            crédit des dits coupons attribués, sans contestation. Le compte client pourra être supprimé temporairement
            ou définitivement.
          </p>
          <p>
            <b>Chaque consommateur est limité à un compte client par personne physique.</b> Tout abus fera l’objet d’une
            suppression des doublons et comptes multiples d’une seule et même personne physique, sans contestation.
          </p>

          <h2>
            O. Résiliation par le consommateur
          </h2>
          <p>
            Le Consommateur qui souhaite résilier son abonnement peut effectuer la demande via son compte ou peut en
            faire la demande via notre formulaire disponible à l’adresse suivante :
            <potager-link :to="{ name: 'contact' }">www.potagercity.fr/contact/</potager-link>
            .
          </p>

          <h2>
            P. Intégralité des engagements
          </h2>
          <p>
            Les présentes Conditions Générales de Vente constituent la totalité de l’accord passé entre Potager City et
            le Consommateur pour ce qui concerne l’utilisation du Service. Elles se substituent à tout accord
            éventuellement intervenu antérieurement entre Potager City et le Consommateur.
          </p>

          <h2>
            Q. Droit de rétractation
          </h2>
          <p>
            Compte-tenu de la nature alimentaire périssable des fruits et légumes, le Consommateur ne pourra exercer de
            droit de rétractation, conformément aux dispositions de l’article L. 221-28 4° du code de la consommation.
            Le délai de rétractation de 14 jours ne s’applique pas pour les contrats de fourniture de produits
            périssables, agroalimentaires, telles que les marchandises vendues par Potager City.
          </p>
          <p>
            L’annulation doit survenir au plus tard deux jours avant le jour prévu de la livraison.
          </p>
          <ul>
            <li>Si la livraison est prévue le mardi, suspension possible jusqu’au dimanche à 23h59.</li>
            <li>Si la livraison est prévue le mercredi, suspension possible jusqu’au lundi à 23h59.</li>
            <li>Si la livraison est prévue le jeudi, suspension possible jusqu’au mardi à 23h59.</li>
            <li>Si la livraison est prévue le vendredi, suspension possible jusqu’au mercredi à 23h59.</li>
          </ul>
          <p>
            Toutefois le Consommateur pourra modifier ou annuler sa commande sous réserve que sa commande ne soit pas
            déjà en cours de préparation, dans les conditions prévues aux articles D et E des présentes Conditions
            Générales de Vente.
          </p>
          <p>
            Si le panier est en cours de livraison ou a déjà été livré dans un point relais ou une entreprise, aucune
            modification ou annulation ne sera possible.
          </p>

          <h2>
            R. Divers
          </h2>
          <p>
            Le défaut d’exercice par Potager City des droits qui lui sont reconnus par les termes des présentes
            Conditions Générales d’Utilisation ne constitue pas une renonciation à faire valoir ses droits. Si une ou
            plusieurs dispositions des présentes Conditions Générales d’Utilisation sont déclarées nulles ou caduques
            par application d’une loi ou d’une décision judiciaire ou administrative, les autres dispositions conservent
            force obligatoire.
          </p>

          <h2>
            S. Titres restaurants papier
          </h2>
          <p>
            En complément des comptes Titres Restaurant évoqués dans le point F. Modalités de paiement de ces mêmes
            Conditions Générales de Vente, Le Consommateur peut envoyer par courrier des Titres Restaurants papiers qui
            seront ajoutés au compte du dit Consommateur sous forme de coupons de réduction. Le consommateur note qu’il
            prend la responsabilité de l’envoi de ses titres restaurants et que Potager City lui conseiller d’utiliser
            un mode d’envoi sécurisé. Potager City ne pourra pas être tenu responsable d’un/une
            éventuel(le) vol, perte de titres restaurants. Il est possible d’utiliser jusqu’à deux titres par commande.
          </p>

          <h2>
            T. Loi applicable
          </h2>
          <p>
            Les présentes conditions générales de vente sont soumises au droit français.
          </p>

          <h2>
            U. Confidentialité et données personnelles
          </h2>
          <p>
            Dans le cadre de l’utilisation des supports numériques de Potager City par les Utilisateurs et les
            Consommateurs, Potager City est amené à collecter et traiter certaines données à caractère personnel les
            concernant.
          </p>
          <p>
            Potager City met à disposition de ses Utilisateurs et ses Consommateurs une
            <potager-link :to="{ name:'confidentialite-et-donnees-personnelles' }">
              politique de confidentialité et des données personnelles.
            </potager-link>
          </p>
          <p>
            Conformément à la réglementation applicable à la protection des données à caractère personnel et en
            particulier le règlement Européen 2016/679 du 27 avril 2016, dit Règlement général sur la protection des
            données (le «&nbsp;RGPD&nbsp;»), les Utilisateurs et Consommateurs disposent d’un droit d’accès, de
            rectification,
            d’effacement, et à la portabilité des données les concernant et peuvent également, pour des motifs
            légitimes, s’opposer au traitement et, dans les cas prévus par la loi, demander la limitation du traitement
            de ces données. Pour exercer ces droits, Potager City invite chaque Utilisateur et Consommateur à contacter
            le service client en utilisant l’un de ces canaux de communication&nbsp;:
          </p>
          <ul>
            <li>
              Par email à <a
                href="mailto:droitsdespersonnes@potagercity.fr"
                target="_blank">
                droitsdespersonnes@potagercity.fr
              </a>
            </li>
            <li>
              Via notre formulaire de contact&nbsp;:
              <potager-link :to="{name:'contact'}">https://www.potagercity.fr/contact/</potager-link>
            </li>
            <li>
              Par courrier&nbsp;: Potager City - Droit sur les Données Personnelles - Lumen Cardinal Workside - 60&nbsp;Quai&nbsp;Perrache
              - 69002&nbsp;Lyon
            </li>
          </ul>
        </cms>
      </blog-section>
    </blog-body>

  </div>
</template>

<script>
import Heading from 'Components/heading/Heading';
import BlogBody from 'Components/blog/BlogBody';
import BlogSection from 'Components/blog/BlogSection';
import Cms from 'Components/cms/Cms';

import ToolsMixin from 'Mixins/ToolsMixin';

export default {

  mixins: [
    ToolsMixin,
  ],

  components: {
    Heading,
    BlogBody,
    BlogSection,
    Cms,
  },

  head() {
    return this.generateMetaIfPanelClosed({
      title: 'Conditions générales de vente',
      description: 'L\'ensemble des conditions générales de vente de Potager City sont présentées et détaillées sur cette page.',
    });
  },

};
</script>
