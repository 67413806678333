<template>
  <div class="popups">
    <div
      v-for="popup in getPopups"
      :key="popup.id"
      :style="{
        '--popup-width': popup.width || '440px',
        '--popup-height': popup.height,
        '--popup-top': popup.top,
        '--popup-bottom': popup.bottom || '.75rem',
        '--popup-left': popup.left || '.75rem',
        '--popup-right': popup.right,
      }"
      class="popups__popup fixed z-20 drop-shadow-xl">
      <transition
        :name="popup.transition"
        mode="out-in">
        <div v-if="getShowedPopups.includes(popup.name)">
          <div class="rounded-lg overflow-hidden">
            <component
              :is="popup.component"
              :key="popup.id"
              :popup="popup" />
          </div>

          <potager-button
            :class="[
              'popups__close-button absolute top-3 right-3 flex items-center justify-center text-center z-100',
              'bp425:top-0 bp425:left-1/2 bp425:transform bp425:-translate-x-1/2 bp425:-translate-y-1/2',
            ]"
            is-circle
            theme="bianca"
            @on-click="popup.close">
            <icon-close class="block mx-auto" />
          </potager-button>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import PotagerButton from 'UI/PotagerButton';

import IconClose from 'Icons/bold/IconClose';

export default {
  name: 'Popups',
  components: {
    PotagerButton,
    IconClose,
  },

  computed: {
    ...mapGetters('popups', [
      'getPopups',
      'getShowedPopups',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.popups {
  @media print {
    display: none;
  }

  &__popup {
    max-width: calc(100vw - 1.5rem);
    max-height: calc(100vh - 1.5rem);
    width: var(--popup-width);
    height: var(--popup-height);
    top: var(--popup-top);
    bottom: var(--popup-bottom);
    left: var(--popup-left);
    right: var(--popup-right);
  }
}
</style>
