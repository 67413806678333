<template>
  <potager-container
    :class="[
      'blog__body bg-white rounded-lg pb-6',
      {'pt-6': !$slots.heading}
    ]">
    <slot name="heading" />

    <potager-row collapse-y>
      <potager-col
        :bp1280="sidebar ? 8.5 : 12"
        :col="sidebar ? 8 : 12"
        bp640="12"
        class="blog__content print:w-full print:max-w-full">
        <template v-if="!isLoading">
          <slot />
        </template>

        <template v-else>
          <blog-section
            v-for="i in 3"
            :key="i"
            is-loading />
        </template>

        <slot name="pager" />
      </potager-col>

      <potager-col
        v-if="sidebar"
        bp1280="3.5"
        bp640="12"
        class="blog__aside-wrapper"
        col="4">
        <blog-aside :related-recipe-boxes="relatedRecipeBoxes" />
      </potager-col>
    </potager-row>
  </potager-container>
</template>

<script>
import PotagerContainer from 'UI/PotagerContainer';
import PotagerRow from 'UI/PotagerRow';
import PotagerCol from 'UI/PotagerCol';

import BlogAside from 'Components/blog/BlogAside';
import BlogSection from 'Components/blog/BlogSection';

export default {

  components: {
    PotagerContainer,
    PotagerRow,
    PotagerCol,
    BlogAside,
    BlogSection,
  },

  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    relatedRecipeBoxes: {
      type: Array,
      required: false,
      default: () => ([]),
    },
    sidebar: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.blog {
  &__body  {
    @apply mb-10;
  }
}
</style>
