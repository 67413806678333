<template>
  <component
    :is="tag"
    :class="[
      'potager-container',
      `potager-container--${size}`
    ]">
    <slot />
  </component>
</template>

<script>
import { propsBuilder } from 'UI/Tools';

export const props = {
  size: {
    type: String,
    required: false,
    default: 'medium',
    values: ['xxsmall', 'xsmall', 'small', 'medium-small', 'medium', 'wide'],
  },
  tag: {
    type: String,
    required: false,
    default: 'div',
  },
};

export default {

  props: propsBuilder(props),

};
</script>
