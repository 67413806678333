import TunnelTypes from 'Classes/workflow/TunnelTypesConstants';

export const test = ({
  state,
  name,
  expectedValue,
  newType,
  operator = 'equal',
}) => {
  // If multiple expectedValue
  if (Array.isArray(expectedValue)) {
    return expectedValue.some((value) => test({
      state,
      name,
      expectedValue: value,
      newType,
    }));
  }

  // If only one expectedValue
  if (operator === 'not') {
    return (newType ? newType.value : state.constraints[name].value) !== expectedValue;
  } else {
    return (newType ? newType.value : state.constraints[name].value) === expectedValue;
  }
};

const type = ({
  values,
  defaultValue
}) => ({
  type: 'type',
  values,
  value: defaultValue,
  default: defaultValue,
  test,
});

const typeConstraints = {};
Object.keys(TunnelTypes)
  .forEach((key) => {
    typeConstraints[key] = type({
      values: TunnelTypes[key].types,
      defaultValue: TunnelTypes[key].default,
    });
  });

export default typeConstraints;
