<template>
  <panel
    :back-route="backRoute"
    :is-loading="!getUserId"
    title="Sélectionner une carte">
    <div class="panel-section">
      <bank-cards-selection-list
        :selected-card-id="cardId"
        @onChange="onChange" />

      <potager-navigator :to="newCardRoute">
        Ajouter une carte de paiement
      </potager-navigator>
    </div>

    <template #footer>
      <transition name="fade">
        <potager-button
          :is-disabled="!cardId || !getUserId"
          :is-loading="isLoading"
          full-width
          label="Confirmer"
          theme="wild-willow"
          @onClick="submit" />
      </transition>
    </template>
  </panel>
</template>

<script>
import { mapGetters } from 'vuex';
import { PAYMENT_LOADERS } from 'Classes/Loaders';

import MetaInfosService from 'Classes/services/MetaInfoService';

import PaymentMethodsMixin from 'Mixins/payments/PaymentMethodsMixin';
import MobileAppMixin from 'Mixins/MobileAppMixin';

import PotagerButton from 'UI/PotagerButton';
import PotagerNavigator from 'UI/PotagerNavigator';

import Panel from 'Components/panel/Panel';
import BankCardsSelectionList from 'Components/bankCard/BankCardsSelectionList';

export default {

  mixins: [
    PaymentMethodsMixin,
    MobileAppMixin,
  ],

  components: {
    Panel,
    BankCardsSelectionList,
    PotagerButton,
    PotagerNavigator,
  },

  data: () => ({
    cardId: null,
  }),

  computed: {
    ...mapGetters('user', [
      'getUserId',
      'getBankCards',
    ]),
    isLoading() {
      return this.$wait.is(PAYMENT_LOADERS);
    },
    orderId() {
      return this.$potagerRoute.params.orderId;
    },
    backRoute() {
      return {
        name: 'mon-compte_orders_detail',
        params: { orderId: this.orderId },
      };
    },
    newCardRoute() {
      return {
        name: 'mon-compte_orders_detail_add-card',
        params: { orderId: this.orderId },
      };
    },
    order() {
      return this.$store.getters['user/getOrderById'](this.orderId);
    },
  },

  methods: {
    onChange(e) {
      this.cardId = e;
    },
    submit() {
      this.doOrderAuthorizationByCardId(this.order, this.cardId)
        .finally(() => {
          if (this.isInAppWebView) {
            this.redirectWebApp();
          } else {
            this.$router.push(this.backRoute)
              .catch(() => {
              });
          }
        });
    },
  },

  mounted() {
    if (this.$potagerRoute.params.cardId) this.cardId = this.$potagerRoute.params.cardId;
  },

  head: MetaInfosService.generate({
    title: 'Sélectionner une carte',
  }),

};
</script>
