<template>
  <potager-switch-button
    v-model="activeTab"
    class="mx-auto">
    <template #left>
      En cours
    </template>

    <template #right>
      Passées
    </template>
  </potager-switch-button>
</template>

<script>
import PotagerSwitchButton from 'UI/PotagerSwitchButton.vue';

export default {
  components: {
    PotagerSwitchButton,
  },

  data: () => ({
    activeTab: true,
  }),

  watch: {
    activeTab(val) {
      const status = val ? 'en-cours' : 'historique';
      if (this.$potagerRoute.params.status !== status) {
        this.$router.push({
          name: 'mon-compte_orders',
          params: { status }
        });
      }
    },
  },

  created() {
    this.activeTab = this.$potagerRoute.params.status !== 'historique';
  }
};
</script>
