<template>
  <panel
    :back-route="backRoute"
    title="Mot de passe">
    <div class="panel-section">
      <potager-form
        id="update-password-form"
        class="panel-section__line"
        @onSubmit="updatePassword">
        <potager-input
          v-model="oldPassword"
          label="Ancien mot de passe"
          name="confirmPassword"
          no-password-validator
          type="password" />

        <potager-input
          v-model="password"
          constraints-validation="onBlur"
          label="Nouveau mot de passe"
          name="password"
          type="password"
          @onError="setError" />

        <potager-input
          v-model="confirmPassword"
          :constraints="confirmNewPwdConstraints"
          constraints-validation="onBlur"
          label="Confirmation nouveau mot de passe"
          name="confirmNewPassword"
          no-password-validator
          type="password"
          @onError="setError" />

        <potager-button
          :disabled="!canSubmit"
          :is-loading="$wait.is('UPDATE_USER_PASSWORD_ACTION')"
          full-width
          label="Valider"
          type="submit" />
      </potager-form>
    </div>
  </panel>
</template>

<script>
import Panel from 'Components/panel/Panel';
import PotagerButton from 'UI/PotagerButton';
import PotagerInput from 'UI/Form/PotagerInput';

import { UPDATE_USER_PASSWORD_ACTION } from 'Stores/types/userActionsTypes';
import MetaInfosService from 'Classes/services/MetaInfoService';
import PotagerForm from 'UI/Form/PotagerForm.vue';

export default {

  components: {
    PotagerForm,
    Panel,
    PotagerButton,
    PotagerInput,
  },

  data() {
    return {
      backRoute: { name: 'mon-compte_info_perso' },
      password: '',
      oldPassword: '',
      confirmPassword: '',
      error: null,
    };
  },

  computed: {
    canSubmit() {
      return this.password && this.oldPassword && this.confirmPassword && !this.error?.length;
    },
    confirmNewPwdConstraints() {
      return [{
        constraint: 'equal',
        field: 'confirmNewPassword',
        options: { value: this.password },
      }];
    },
  },

  methods: {
    setError(value) {
      this.error = value;
    },
    updatePassword() {
      if (this.canSubmit) {
        const {
          password,
          confirmPassword,
          oldPassword
        } = this;
        this.$store.dispatch(`user/${UPDATE_USER_PASSWORD_ACTION}`, {
          password,
          confirmPassword,
          oldPassword,
        })
          .then(() => {
            this.$notify({
              type: 'success',
              title: 'Mot de passe modifié',
              text: 'Votre mot de passe a bien été modifié.',
            });

            this.$router.push(this.backRoute);
          });
      }
    },
  },

  head: MetaInfosService.generate({
    title: 'Mot de passe',
  }),
};
</script>
