<template>
  <div class="flex flex-col">
    <potager-switch
      :model-value="gateway"
      class="my-0 mr-2"
      @update:modelValue="changeGateway">
      <b>Passerelle de paiement&nbsp;:&nbsp;{{ getPaymentGateway }}</b>
      ({{ getForcedPaymentGateway ? 'Forcée' : 'Par défaut' }})
    </potager-switch>

    <small>
      <b>NOTE</b>&nbsp;:&nbsp;Une carte de paiement enregistrée sur une passerelle continuera de l'utiliser,
      indépendamment de la passerelle sélectionnée ici.
    </small>

    <div class="mt-2">
      <potager-button
        size="small"
        theme="light-red"
        @onClick="resetGateway">
        Réinitialiser
      </potager-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import PotagerSwitch from 'UI/Form/PotagerSwitch';
import PotagerButton from 'UI/PotagerButton';
import { PaymentMethodIntegration } from 'potagerlogic/dist/Enums/PaymentMethods';

export default {
  components: {
    PotagerButton,
    PotagerSwitch
  },

  data() {
    return {
      gateway: PaymentMethodIntegration.Paygreen,
    };
  },
  computed: {
    ...mapGetters('user', [
      'getForcedPaymentGateway',
      'getPaymentGateway',
    ]),
  },

  watch: {
    getPaymentGateway: {
      handler(val) {
        const isPaygreen = val === PaymentMethodIntegration.Paygreen;
        if (isPaygreen !== this.gateway) {
          this.gateway = isPaygreen;
        }
      },
      immediate: true,
    },
  },

  methods: {
    changeGateway() {
      this.gateway = !this.gateway;
      this.$store.commit('user/setForcedPaymentGateway', this.gateway ? PaymentMethodIntegration.Paygreen : PaymentMethodIntegration.Stripe);
    },
    resetGateway() {
      this.$store.commit('user/setForcedPaymentGateway', null);
    },
  },
};
</script>
