<template>
  <div class="social-media flex-col font-semibold">
    Partager sur :

    <div class="flex">
      <s-facebook
        v-if="facebook"
        :share-options="{
          url: facebook.url,
          quote: facebook.text,
        }"
        class="social-media__social-button__link">
        Facebook
      </s-facebook>

      <s-twitter
        v-if="twitter"
        :share-options="{
          url: twitter.url,
          text: twitter.text,
        }"
        class="social-media__social-button__link">
        Twitter
      </s-twitter>

      <s-pinterest
        v-if="pinterest"
        :share-options="{
          url: pinterest.url,
          description: pinterest.description,
          media: pinterest.image,
        }"
        class="social-media__social-button__link">
        Pinterest
      </s-pinterest>

      <s-email
        v-if="mail"
        :share-options="{
          subject: mail.subject,
          body: mail.body,
        }"
        class="social-media__social-button__link">
        Email
      </s-email>

      <a
        v-if="canPrint"
        class="social-media__social-button__link social-media__social-button__link--imprimer"
        href="#"
        @click.prevent="print()">
        <span class="title-social">
          Imprimer
        </span>
      </a>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    facebook: {
      type: Object,
      required: false,
      default: () => ({
        url: 'https://www.potagercity.fr/',
      }),
    },
    twitter: {
      type: Object,
      required: false,
      default: () => ({
        url: 'https://www.potagercity.fr/',
        text: 'Découvrez cette article Potager City',
      }),
    },
    pinterest: {
      type: Object,
      required: false,
      default: () => ({
        url: 'https://www.potagercity.fr/',
        description: 'Découvrez cette article Potager City',
        image: 'https://dummyimage.com/600x400/000/fff',
      }),
    },
    mail: {
      type: Object,
      required: false,
      default: null,
    },
    canPrint: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  methods: {
    print() {
      window.print();
    },
  },
};
</script>
