<template>
  <div class="bg-bianca pb-20 bp1024:pb-14 bp640:pb-12">
    <heading
      class="mb-20 bp1024:mb-14 bp640:mb-12"
      background="background-potager"
      :modifiers="
        ['v2']">
      <template #title>
        Le manifeste du&nbsp;bon
      </template>

      <template #baseline>
        Une histoire de pépites bien produites et bien choisies
      </template>
    </heading>

    <content-two-column
      class="mb-20 bp1024:mb-14 bp640:mb-12"
      icon="😋"
      src="manifesto-carottes-fanes">
      <template #title>
        Depuis 15 ans, une histoire de <b class="text-british-racing font-extrabold">
          bons produits.
        </b>
      </template>

      <template #text>
        <p>
          Des trouvailles, comme les 1ers avocats corses. Des surprises, comme les mandarines Satsuma. Des navets
          goûtés dès 6h du matin à la pointe de l’Opinel. Des visites chez nos producteurs partenaires, qui commencent
          par des légumes qui croquent et qui terminent on ne sait comment avec des bulles qui trinquent.
        </p>
        <br>
        <p>
          <b class="font-bold">
            Vous dénicher le meilleur des fruits et légumes : voilà notre métier. Nous sommes dénicheurs de bon.
          </b>
        </p>
      </template>

      <template #picture-extra>
        <potager-picture
          no-lazy-load
          class="manifesto__badge w-[180px] bp640:w-[120px] -left-5 bottom-20 "
          :width="$mq.bp640 ? '120px' : '180px'"
          src="badge-product" />
      </template>
    </content-two-column>

    <content-background-picture
      class="mb-20 bp1024:mb-14 bp640:mb-12"
      src="background-cagettes-poires">
      C’est une croyance forte dans les terroirs et les savoir-faire, et&nbsp;dans ceux qui les font vivre.
    </content-background-picture>

    <content-two-column
      class="mb-20 bp1024:mb-14 bp640:mb-12"
      icon="🍅"
      src="manifesto-soupe-potimarron">
      <template #title>
        <b class="text-british-racing font-extrabold">
          Une histoire simple
        </b> qui se partage
      </template>

      <template #text>
        <p>
          Le bon pour Potager City c’est d’abord une histoire de pépites bien produites et bien choisies.
        </p>
        <br>
        <p>
          Et un produit bien cueilli et bien choisi n'a pas besoin de grand chose...
          <b class="font-bold">Notre conviction : que la recette
            du bon a avant tout besoin d'ingrédients simples.</b>
          Que la gourmandise se cuisine mieux avec des produits bruts.
        </p>
        <br>
        <p>
          Le bon, c’est l’authenticité et le partage. C’est vous proposer une sélection de fruits et légumes en flux
          tendu, à peine cueillis, déjà rôtis.
        </p>
        <br>
        <p>
          C’est vous proposer le meilleur de nos trouvailles, celles qui viennent du bon endroit, au bon prix, et au
          bon moment. Pour l’amour des marmites qui fument, des peaux qui croustillent, et des quartiers à partager.
          Tout simplement.
        </p>
      </template>

      <template #picture-extra>
        <potager-picture
          no-lazy-load
          class="manifesto__badge w-[180px] bp640:w-[120px] -right-5 bottom-36"
          :width="$mq.bp640 ? '120px' : '180px'"
          src="badge-season" />
      </template>
    </content-two-column>

    <content-two-column
      icon="🧑‍🌾"
      src="manifesto-salade-producteur">
      <template #title>
        <b class="text-british-racing font-extrabold">
          Une histoire de bon sens
        </b> et de confiance
      </template>

      <template #text>
        <p>
          Le bon, c’est une histoire de bon sens, celui qui va vers plus de convictions dans les sélections,
        </p>
        <br>
        <p>
          C’est une croyance forte dans les terroirs et les savoir-faire, et dans ceux qui les font vivre.
        </p>
        <br>
        <p>
          BIO, HVE... C’est savoir apprécier les labels mais préférer la confiance. Celle que nous avons en nos partenaires, en leurs convictions et en leurs méthodes de production. Des producteurs qui se battent pour faire vivre des pratiques engagées et respectueuses.
        </p>
        <br>
        <p>
          Le bon pour Potager City, c’est d’abord Laurent, Paola, Christine, Michel, Enrique. Leurs coups de fils tôt le matin, leurs déconvenues quand il a gelé. Leur joie quand le vent a tourné et qu’ils pourront nous livrer.
        </p>
        <br>
        <p class="mb-10">
          C’est le bon maraîcher, le bon arboriculteur, le bon partenaire.
        </p>

        <manifesto-productors />
      </template>

      <template #picture-extra>
        <potager-picture
          no-lazy-load
          class="manifesto__badge w-[180px] bp640:w-[120px] -bottom-5 left-20"
          :width="$mq.bp640 ? '120px' : '180px'"
          src="badge-productor" />
      </template>
    </content-two-column>
  </div>
</template>

<script>
import Heading from 'Components/heading/Heading';
import ContentTwoColumn from 'Components/content/ContentTwoColumn';
import ContentBackgroundPicture from 'Components/content/ContentBackgroundPicture';
import ManifestoProductors from 'Components/manifesto/ManifestoProductors';

import PotagerPicture from 'UI/PotagerPicture';

import ToolsMixin from 'Mixins/ToolsMixin';

export default {
  name: 'ManifestoPage',

  components: {
    ManifestoProductors,
    PotagerPicture,
    ContentBackgroundPicture,
    ContentTwoColumn,
    Heading,
  },

  mixins: [
    ToolsMixin,
  ],

  head() {
    return this.generateMetaIfPanelClosed({
      title: 'Notre Manifeste',
      description: 'Le bon pour Potager City c’est d’abord une histoire de pépites bien produites et bien choisies.',
    });
  },
};
</script>

<style lang="scss" scoped>
.manifesto {
  &__badge {
    transform: rotate(16deg);
    @apply absolute shadow-lg rounded-full;
    @apply bp1024:-left-5 bp1024:bottom-20 #{!important};
    @apply bp640:-top-5 bp640:right-5 bp640:left-auto bp640:bottom-auto #{!important};
  }
}
</style>
