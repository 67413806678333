import LengthValidator from 'Classes/validators/LengthValidator';
import RegexValidator from 'Classes/validators/RegexValidator';

export const VALIDATORS = [
  {
    validator: LengthValidator,
    constraint: { options: { min: 8 } },
    label: 'Au moins 8 caractères.',
  },
  {
    validator: RegexValidator,
    constraint: { regex: /.*[A-Z]/ },
    label: 'Au moins une majuscule.',
    message: 'Veuillez saisir au minimum 1 caractère en majuscule',
  },
  {
    validator: RegexValidator,
    constraint: { regex: /.*[a-z]/ },
    label: 'Au moins une minuscule.',
    message: 'Veuillez saisir au minimum 1 caractère en minuscule',
  },
  {
    validator: RegexValidator,
    constraint: { regex: /.*[0-9]/ },
    label: 'Au moins un chiffre.',
    message: 'Veuillez saisir au minimum 1 chiffre',
  },
];

// Les 100 premiers mots de la liste de Richelieu
// https://github.com/tarraschk/richelieu/blob/master/french_passwords_top1000.txt
export const richelieuList = ['123456', '123456789', 'azerty', '1234561', 'qwerty', 'marseille', '000000', '1234567891', 'doudou', '12345', 'loulou', '123', 'password', 'azertyuiop', '12345678', 'soleil', 'chouchou', '1234', '1234567', '123123', '123451', 'bonjour', '111111', 'nicolas', 'jetaime', 'coucou', 'motdepasse', 'Status', 'julien', 'thomas', 'camille', '010203', 'chocolat', 'iloveyou', 'iloveyou1', 'portugal', '1234567890', 'alexandre', '654321', 'maxime', '00000', 'wxcvbn', 'oceane', 'pompier', '12345671', 'marine', '0000', 'maison', 'isabelle', 'celine', 'sandrine', 'pierre', 'caroline', 'elodie', 'olivier', 'mohamed', 'romain', 'badoo', 'football', 'princesse', 'nathalie', 'boubou', 'vincent', 'anthony', 'aurelie', 'caramel', 'dragon', 'sophie', 'amour', 'papillon', 'antoine', 'louloute', '0123456789', 'sebastien', 'audrey', '666666', 'emilie', 'naruto', 'france', '987654321', 'voiture', '789456', 'amandine', 'pauline', 'laurent', 'stephane', 'melanie', 'vanille', 'benjamin', 'chipie', 'valentin', '159753', 'morgane', 'marion', 'sabrina', 'michel', 'aaaaaa', 'mar', 'cheval', 'samsung'];

export default {
  name: 'password',

  valid(value, constraint) {
    const errors = [...VALIDATORS].map((e, index) => ({
      ...e.validator.valid(value, {
        ...constraint,
        ...e.constraint,
        message: e.message,
      }) || {},
      index,
    })).filter((e) => !!e.message);

    return errors.length
      ? {
        field: constraint.field,
        message: errors[0]?.message || 'Cette valeur n\'est pas valide.',
        errors,
      }
      : null;
  },

};
