/**
 * Strips HTML entities from the given content.
 *
 * @param {string} content - The content to process.
 * @returns {string} - The content with HTML entities replaced.
 */
export const stripHtmlEntities = (content) => {
  return content?.replace(/&#(\d+);/g, (match, dec) => String.fromCharCode(dec));
};

/**
 * Decodes HTML entities in a string.
 *
 * @param {string} str - The string containing HTML entities.
 * @returns {string} - The decoded string.
 */
export const decodeHtmlEntities = (str) => {
  if (str && typeof str === 'string') {
    const element = document.createElement('div');
    element.innerHTML = str;

    str = element.textContent;
    element.textContent = '';
  }
  return str;
};

/**
 * Replaces multiple consecutive spaces or non-breaking spaces with a single space.
 *
 * @param {string} str - The string to process.
 * @returns {string} - The processed string with double spaces replaced.
 */
export const cleanDoubleSpaces = (str) => {
  return str?.replace(/(&nbsp;| )(&nbsp;| )+/g, ' ');
};

/**
 * Replaces a space followed by certain punctuation marks with a non-breaking space.
 *
 * @param {string} str - The string to process.
 * @returns {string} - The processed string with spaces replaced by non-breaking spaces.
 */
export const cleanSpaces = (str) => {
  return str?.replace(/[ ]([?|!|;|:])/g, '&nbsp;$1');
};

/**
 * Replaces Unicode line separator characters with a space.
 *
 * @param {string} str - The string to process.
 * @returns {string} - The processed string with Unicode line separators replaced.
 */
export const cleanUnicodeSpaces = (str) => {
  return str?.replace(/\u2028/g, ' ');
};

/**
 * Removes all <br> tags from the given string.
 *
 * @param {string} str - The string to process.
 * @returns {string} - The processed string with <br> tags removed.
 */
export const cleanBr = (str) => {
  return str?.replace(/<br>/g, '');
};

/**
 * Removes empty HTML tags from the given string.
 *
 * @param {string} str - The string to process.
 * @returns {string} - The processed string with empty tags removed.
 */
export const cleanEmptyTags = (str) => {
  return str
    ?.replace(/<[^/>][^>]*>(&nbsp;)<\/[^>]+>/g, '')
    .replace(/<[^/>(iframe)][^>]*><\/[^>]+>/g, '');
};

/**
 * Removes iframes to prevent cookie placement.
 *
 * @param {string} str - The string to process.
 * @returns {string} - The processed string with iframes removed.
 */
export const cleanIframes = (str) => {
  return str?.replace(/<iframe.+?<\/iframe>/g, '');
};

/**
 * Cleans up a WordPress string by applying multiple cleaning functions.
 *
 * @param {string} str - The string to process.
 * @returns {string} - The cleaned string.
 */
export const cleanWordpress = (str) => {
  let text = str;

  if (text) {
    text = cleanSpaces(text);
    text = cleanUnicodeSpaces(text);
    text = cleanDoubleSpaces(text);
    text = cleanIframes(text);
    text = cleanEmptyTags(text);
  }

  return text;
};

/**
 * Strips HTML tags and entities from the given text.
 *
 * @param {string} text - The text to process.
 * @returns {string} - The cleaned text.
 */
export const stripHtml = (text) => {
  let t = text;
  if (t) {
    t = t.replace(/<p>(&nbsp;)+<\/p>/g, '');
    t = t.replace(/<\/?[^>]+(>|$)/g, '');
    t = t.replace(/(?:\r\n|\r|\n)/g, ' ');
    t = stripHtmlEntities(t);
    t = decodeHtmlEntities(t);
    t = t.replace(/  +/g, ' ');
  }
  return t;
};

/**
 * Converts a duration to milliseconds based on the specified type.
 *
 * @param {number} duration - The duration to convert.
 * @param {string} type - The type of duration (e.g., 'seconds', 'minutes', 'hours', 'days', 'weeks', 'months', 'years').
 * @returns {number} - The duration in milliseconds.
 */
export const duration = (duration, type) => {
  const millisecondMultiplier = {
    seconds: 1000,
    minutes: 1000 * 60,
    hours: 1000 * 60 * 60,
    days: 1000 * 60 * 60 * 24,
    weeks: 1000 * 60 * 60 * 24 * 7,
    months: 1000 * 60 * 60 * 24 * 29.5,
    years: 1000 * 60 * 60 * 24 * 365.25
  };

  // Calculate and return the duration in milliseconds
  return duration * (millisecondMultiplier[type] || 0);
};

/**
 * Extracts a research word from the given content.
 *
 * @param {Object} content - The content object containing the name property.
 * @param {string} content.name - The name to extract the research word from.
 * @returns {string|null} - The extracted research word or null if not found.
 */
export const getContentResearchWord = (content) => {
  const str = content?.name;
  const entry = str?.toLowerCase();
  if (!entry) return null;

  const specialCasesIncludes = new Map([
    ['pomme de terre', 'pomme de terre'],
    ['fruit de la passion', 'fruit de la passion'],
    ['jeune pousse', 'jeune pousse'],
    ['noix de coco', 'noix de coco'],
    ['petit pois', 'petit pois'],
    ['fruits secs', 'fruits secs'],
    ['trompette de la mort', 'champignon'],
    ['petit épeautre', 'graine'],
  ]);

  for (const [key, value] of specialCasesIncludes) {
    if (entry.includes(key)) return value;
  }

  return entry.split(' ')[0];
};
