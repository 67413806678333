import {
  PAY_BASKET_BY_PAYMENT_METHODS_ACTION,
  UPDATE_USER_ACTION,
} from 'Stores/types/userActionsTypes';

import PaymentMethodsMixin from 'Mixins/payments/PaymentMethodsMixin';

export default {
  mixins: [PaymentMethodsMixin],

  data() {
    return {
      order: null,
    };
  },

  computed: {
    isLoading() {
      return this.$wait.is([
        PAY_BASKET_BY_PAYMENT_METHODS_ACTION,
        UPDATE_USER_ACTION,
      ]);
    },
  },

  methods: {
    submitBasketPayment() {
      this.$store.dispatch(`user/${PAY_BASKET_BY_PAYMENT_METHODS_ACTION}`, this.getFlattenPaymentMethods)
        .then(({ data }) => {
          this.order = data.data.orders[0];
          this.onBasketSuccess();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    onBasketSuccess() {
      const orderId = this.order.id;

      // doOrderRequiresAction is used to check if we need to trigger 3DS
      // nextTick to wait for the DOM to be updated
      this.$nextTick(() => {
        this.doOrderRequiresAction(this.order)
          .then(() => {
            this.$router.push({
              name: 'basket_payment_success',
              params: { orderId },
            });
          })
          .catch((err) => {
            console.error(err);
            this.$router.push({
              name: 'mon-compte_orders_detail',
              params: { orderId },
            });
          });
      });
    },
  },
};
