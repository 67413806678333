<template>
  <div>
    <div
      :class="{
        'cms': true,
        'cms--with-hat': withHat,
        'cms--is-small': isSmall,
      }"
      v-html="cleanWP(content)" />
    <div
      v-if="$slots.default"
      :class="{
        'cms': true,
        'cms--with-hat': withHat,
        'cms--is-small': isSmall,
      }">
      <slot />
    </div>
  </div>
</template>

<script>
import { cleanWordpress } from 'Classes/utils/WordpressUtils';

export default {
  props: {
    content: {
      type: String,
      required: false,
      default: '',
    },
    withHat: {
      type: Boolean,
      required: false,
      default: false,
    },
    isSmall: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  methods: {
    cleanWP(str) {
      return cleanWordpress(str);
    },
  },

};
</script>
