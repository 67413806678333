export default {
  methods: {
    createSellsyWidget(hash) {
      const sellsyScript = document.createElement('script');
      sellsyScript.setAttribute('id', 'sellsy');
      sellsyScript.setAttribute('src', `https://snippet.sellsy.com/${hash}`);
      document.head.appendChild(sellsyScript);
    },
    removeSellsyWidget() {
      document.body.removeChild(document.getElementById('sellsy_snippet'));
    },
  },
};
