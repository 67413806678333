<template>
  <potager-button
    :theme="theme"
    class="main-header-burger"
    is-circle
    @onClick="toggleMenu">
    <template #icon>
      <transition
        mode="out-in"
        name="fade-fast">
        <icon-close
          v-if="active"
          class="text-xs" />
        <icon-navigation-menu
          v-else
          class="text-lg" />
      </transition>
      <template />
    </template>
  </potager-button>
</template>

<script>
import PotagerButton from 'UI/PotagerButton';

import IconClose from 'Icons/bold/IconClose';
import IconNavigationMenu from 'Icons/bold/IconNavigationMenu';

export default {
  name: 'MainHeaderBurger',

  components: {
    PotagerButton,
    IconClose,
    IconNavigationMenu,
  },

  props: {
    active: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: 'bianca',
    },
  },

  emits: ['toggleMenu'],

  methods: {
    toggleMenu() {
      this.$emit('toggleMenu');
    },
  },
};
</script>
