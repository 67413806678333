<template>
  <flying-page>
    <div class="supplier-detail">
      <transition name="fade-abs">
        <skeleton
          v-if="supplier.loading"
          context="supplier" />

        <div v-else>
          <potager-picture
            :src="supplier.image"
            :title="supplier.title"
            background
            class="supplier-detail__poster" />

          <div
            v-if="!supplier.loading"
            class="supplier-detail__content">
            <section class="supplier-detail__section">
              <h1 class="supplier-detail__title">
                {{ supplier.title }}
              </h1>
              <h2
                v-if="supplier.region"
                class="supplier-detail__baseline">
                Localisation : {{ supplier.city }} ({{ supplier.region }})
              </h2>
              <h2
                v-if="supplier.isBio"
                class="supplier-detail__baseline">
                Produits BIO
              </h2>
              <div
                class="recipe-detail__description"
                v-html="supplier.description" />
            </section>

            <section
              v-if="supplier.youtube"
              class="supplier-detail__section print:hidden">
              <youtube-frame :url="supplier.youtube" />
            </section>

            <wordpress-article
              v-if="supplier.article && supplier.article.length"
              :sections="supplier.article"
              class="supplier-detail__section"
              is-small />

            <section
              v-if="boxesRelated.length"
              class="supplier-detail__section print:hidden">
              <h3 class="supplier-detail__subtitle">
                Des produits de {{ supplier.firstname }} sont disponibles
                dans nos paniers cette semaine
              </h3>

              <potager-row>
                <potager-col
                  v-for="(boxRelated, index) in boxesRelated"
                  :key="index"
                  col="12">
                  <card-product
                    :box="boxRelated"
                    landscape />
                </potager-col>
              </potager-row>
            </section>

            <section class="supplier-detail__section supplier-detail__section--grey print:hidden">
              <social-media
                :facebook="facebook"
                :mail="mail"
                :pinterest="pinterest"
                :twitter="twitter" />
            </section>

            <upselling-slider flying />

            <associated-content-slider
              :items="productsRelated"
              :title="`Tous les produits fournis par ${supplier.firstname}`" />

            <section
              v-if="!isSubscriptionActive"
              class="supplier-detail__section print:hidden">
              <push-subscription condensed />
            </section>
          </div>
        </div>
      </transition>

      <micro-datas
        v-if="supplier.id"
        :content="supplier"
        context="supplier" />
    </div>
  </flying-page>
</template>

<script>
import FlyingPageMixin from 'Mixins/FlyingPageMixin';

import PotagerPicture from 'UI/PotagerPicture';
import PotagerRow from 'UI/PotagerRow';
import PotagerCol from 'UI/PotagerCol';

import WordpressArticle from 'Components/article/WordpressArticle';
import YoutubeFrame from 'Components/videoFrame/YoutubeFrame';

import MetaInfosService from 'Classes/services/MetaInfoService';
import { isEmpty } from 'Classes/utils/BasicUtils';
import { getResolvedUrl, calcSlug } from 'Classes/utils/RouteUtils';

export default {

  mixins: [
    FlyingPageMixin,
  ],

  components: {
    PotagerPicture,
    PotagerRow,
    PotagerCol,
    WordpressArticle,
    YoutubeFrame,
  },

  data() {
    return {
      supplier: { loading: true },
    };
  },

  computed: {
    id() {
      return parseInt(this.$potagerRoute.params.id, 10);
    },
    boxesRelated() {
      return this.getDashboardProducts.filter((e) => {
        if (!e.loading) {
          return e.suppliers.filter((r) => r.id === this.id).length > 0;
        }
        return null;
      });
    },
    productsRelated() {
      return this.supplier.products;
    },
    mail() {
      return {
        id: this.supplier.id,
        subject: this.supplier.title,
        body: `Hello,\nViens découvrir ce producteur sur Potager City :\n\n${this.getSupplierLink}`,
        url: this.getSupplierLink,
      };
    },
    twitter() {
      return {
        url: this.getSupplierLink,
        text: `${this.supplier.title} - Potager City`,
      };
    },
    facebook() {
      return { url: this.getSupplierLink };
    },
    pinterest() {
      return {
        url: this.getSupplierLink,
        image: this.supplier.image ? this.supplier.image.url : null,
        description: `${this.supplier.title} - Potager City`,
      };
    },
    getSupplierLink() {
      if (this.supplier.id) {
        return getResolvedUrl('producteurs', {
          slug: calcSlug(this.supplier.title),
          id: this.supplier.id,
        });
      }
      return getResolvedUrl('suppliers-list');
    },
  },

  methods: {
    getSupplier() {
      this.$wait.start('GET_SUPPLIER');
      this.$api.supplier.findOneByIdFull(this.id)
        .then((response) => {
          this.supplier = response.data;
        })
        .catch(() => {
          this.$events.emit('404');
        })
        .finally(() => this.$wait.end('GET_SUPPLIER'));
    },
  },

  mounted() {
    this.getSupplier();

    if (!this.isLoaded) this.fetchDashboard();
  },

  head() {
    let metaInfo = {};

    if (this.supplier.id) {
      metaInfo = {
        title: this.supplier.title,
        description: this.supplier.description,
        image: this.supplier.image ? this.supplier.image.url : null,
        link: [{
          rel: 'canonical',
          href: this.getSupplierLink,
        }],
      };
    }

    if (!isEmpty(metaInfo)) {
      return MetaInfosService.generate(metaInfo);
    }

    return metaInfo;
  },

};
</script>
