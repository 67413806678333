<template>
  <div class="panel-section">
    <p class="panel-section__subtitle">
      Récapitulatif
    </p>

    <potager-receipt-details
      :lines="receiptLines" />

    <div class="panel-section__separator" />

    <p class="panel-section__subtitle flex justify-between">
      Code promo

      <potager-button
        :to="{ name: 'basket_coupons' }"
        class="underline not-italic"
        label="Ajouter"
        size="small"
        theme="link">
        {{ getSimulatedOrder?.coupons.length ? 'Modifier' : 'Ajouter' }}
      </potager-button>
    </p>

    <potager-receipt-details
      v-if="getSimulatedOrder?.coupons.length"
      :lines="couponsLines" />

    <div class="panel-section__separator" />

    <potager-receipt-details
      :lines="[totalLine]" />

    <subscriber-benefits-navigator :order="order" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import PotagerReceiptDetails from 'UI/PotagerReceiptDetails';
import PotagerButton from 'UI/PotagerButton';

import SubscriberBenefitsNavigator from 'Components/subscriberBenefits/SubscriberBenefitsNavigator';

import {
  getCouponsListLines,
  getReceiptLines,
  getTotalLine,
} from 'Classes/utils/ReceiptDetailsUtils';

export default {
  name: 'BasketReceiptDetails',
  components: {
    PotagerButton,
    SubscriberBenefitsNavigator,
    PotagerReceiptDetails,
  },
  computed: {
    ...mapGetters('basket', [
      'getSimulatedOrder',
      'getLinkedOrder',
      'getBasket',
    ]),
    receiptLines() {
      return this.order ? getReceiptLines(this.order, this.getLinkedOrder, this.getBasket) : [];
    },
    couponsLines() {
      return this.order ? getCouponsListLines(this.order) : [];
    },
    totalLine() {
      return this.order ? getTotalLine(this.order) : [];
    },
    order() {
      return this.getSimulatedOrder || this.getBasket;
    },
  },
};
</script>
