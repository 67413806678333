import findIndex from 'lodash/findIndex';

const BASE_CONFIG = {
  onInit: null,
  onSubmit: null,
};

export default class {
  constructor(questions, config, app) {
    this.questions = questions;
    this.config = { ...BASE_CONFIG, ...config };
    this.currentQuestionId = null;
    this.savedChoices = [];
    this.store = app.$store;
    this.router = app.$router;
  }

  init() {
    this.savedChoices = []; // On réinitialise les choix de l'utilisateur en cas de nouveau questionnaire
    this.setCurrentQuestionId(1);
    if (typeof this.config.onInit === 'function') {
      this.config.onInit(this)
        .then(({ questions }) => {
          if (questions) this.questions = questions;
        });
    }
  }

  setCurrentQuestionId(id) {
    this.currentQuestionId = parseInt(id, 10);
  }

  saveChoice(choice) {
    const savedIndex = findIndex(this.savedChoices, (e) => e.id === this.getCurrentQuestionId());
    const index = savedIndex !== -1 ? savedIndex : this.savedChoices.length;
    const id = this.getCurrentQuestionId();
    const {
      comment,
      nextQuestion,
      ...value
    } = choice;
    const choices = ['checkbox'].includes(this.getCurrentQuestion().type) ? [...this.savedChoices[savedIndex]?.choices || [], value] : [value];
    const data = {
      id,
      choices
    };
    this.savedChoices[index] = data;
    this.goToNextQuestion(choice.nextQuestion);
  }

  removeChoice(choice) {
    const index = findIndex(this.savedChoices, (e) => e.id === this.getCurrentQuestionId());
    const newChoices = this.savedChoices[index]?.choices.filter((e) => e.label !== choice.label);
    const data = {
      ...this.savedChoices[index],
      choices: newChoices,
    };
    this.savedChoices[index] = data;
  }

  toggleChoice(choice, add) {
    if (add) {
      this.saveChoice(choice);
    } else {
      this.removeChoice(choice);
    }
  }

  saveComment(choice, comment) {
    const savedQuestionIndex = findIndex(this.savedChoices, (e) => e.id === this.getCurrentQuestionId());
    const savedChoiceIndex = findIndex(this.savedChoices[savedQuestionIndex].choices, (e) => e.label === choice.label);
    const data = {
      ...this.savedChoices[savedQuestionIndex].choices[savedChoiceIndex],
      comment,
    };

    this.savedChoices[savedQuestionIndex].choices[savedChoiceIndex] = data;
  }

  goToNextQuestion(id) {
    if (id) {
      this.setCurrentQuestionId(id);
    }
  }

  goToPreviousQuestion() {
    if (this.savedChoices[this.savedChoices.length - 1].id === this.getCurrentQuestionId()) this.savedChoices.pop();
    this.setCurrentQuestionId(this.savedChoices[this.savedChoices.length - 1].id);
    this.savedChoices.pop();
  }

  submit(param = {}) {
    if (typeof this.config.onSubmit === 'function') this.config.onSubmit(this, param);
  }

  selectOptionsAdapter(options) {
    return options.map((option) => ({
      value: option.label,
      label: option.label,
    }));
  }

  getCurrentQuestion() {
    return this.getQuestionById(this.getCurrentQuestionId());
  }

  getCurrentQuestionId() {
    return this.currentQuestionId;
  }

  getQuestionById(id) {
    return this.questions[id];
  }

  getQuestionGroupName() {
    return `${this.getCurrentQuestionId()}-${this.getCurrentQuestion().type}`;
  }

  getCurrentCommentValue(choice) {
    if (this.savedChoices.length) {
      const savedQuestionIndex = findIndex(this.savedChoices, (e) => e.id === this.getCurrentQuestionId());
      const savedChoiceIndex = findIndex(this.savedChoices[savedQuestionIndex].choices, (e) => e.label === choice.label);
      return this.savedChoices[savedQuestionIndex].choices[savedChoiceIndex]?.comment || '';
    }
    return '';
  }

  getDidYouKnowBySelectedChoice(choice) {
    if (this.savedChoices.length) {
      const savedQuestionIndex = findIndex(this.savedChoices, (e) => e.id === this.getCurrentQuestionId());
      const dyk = this.savedChoices[savedQuestionIndex]?.choices.filter((e) => e?.label === choice?.label && e.didyouknow)[0]?.didyouknow;
      if (dyk) return dyk;
    }
    return null;
  }

  getDidYouKnowByCurrentQuestion() {
    return this.getCurrentQuestion()?.didyouknow || null;
  }

  canGoToPreviousQuestion() {
    return !!(this.savedChoices.length > 1 || (this.savedChoices.length && this.savedChoices[this.savedChoices.length - 1]?.id !== this.getCurrentQuestionId()));
  }

  isChoiceSelected(choice) {
    const currentQuestion = this.savedChoices.filter((e) => e.id === this.getCurrentQuestionId())[0];
    return !!currentQuestion?.choices.filter((e) => e.label === choice.label).length;
  }
}
