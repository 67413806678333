<template>
  <potager-container class="print:hidden">
    <potager-background
      :src="$mq.bp640 ? 'sponsorship-banner-mobile' : 'sponsorship-banner-desktop'"
      class="rounded-lg text-center bg-lavender-blush">
      <div class="max-w-[420px] mx-auto py-8">
        <p class="text-2xl.5 font-extrabold mb-5 text-byzantine bp640:text-xl.5">
          Parrainez vos proches&nbsp;!
        </p>

        <div class="flex leading-none mb-5 items-center bp640:flex-col">
          <div
            :class="[
              'w-1/2 pr-4 border-r border-charcoal/20 border-solid',
              'bp640:border-r-0 bp640:border-b bp640:pb-4 bp640:pr-0',
            ]">
            <p
              class="font-lore-alternates text-2xl font-bold whitespace-nowrap tracking-tight bp640:text-xl.5">
              10€ pour vous
            </p>

            <p class="text-sm font-medium">
              à chaque proche<br>parrainé
            </p>
          </div>

          <div
            :class="[
              'w-1/2 pl-4',
              'bp640:pt-4 bp640:pl-0',
            ]">
            <p
              class="font-lore-alternates text-2xl font-bold whitespace-nowrap tracking-tight bp640:text-xl.5">
              18€ pour eux
            </p>

            <p class="text-sm font-medium">
              sur leurs 3 premières commandes
            </p>
          </div>
        </div>

        <potager-button
          :to="route"
          theme="byzantine">
          Je parraine mes proches
        </potager-button>
      </div>
    </potager-background>
  </potager-container>
</template>

<script>
import PotagerContainer from 'UI/PotagerContainer';
import PotagerButton from 'UI/PotagerButton';
import PotagerBackground from 'UI/PotagerBackground';

import { mapGetters } from 'vuex';

export default {

  components: {
    PotagerContainer,
    PotagerButton,
    PotagerBackground,
  },

  data: () => ({
    route: { name: 'mon-compte-sponsorship-my-code' },
  }),

  computed: {
    ...mapGetters('app', [
      'getSponsorshipGodFatherValue',
      'getSponsorshipGodSonValue',
      'getSponsorshipGodFatherUnit',
    ]),
  },

  methods: {
    redirect() {
      this.$router.push(this.route)
        .catch(() => {
        });
    },
  },

};
</script>
