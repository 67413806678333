import store from 'Stores';
import { ADD_REDIRECTED_PAGE } from 'Stores/types/sessionMutationsTypes';

// eslint-disable-next-line padded-blocks
export default function (to, from, next) {
  // On check si la session réclame une redirection pour la route en question
  // Si c'est le cas, on redirige le user
  const redirect = store.getters['session/getRedirectedPageByName'](to.name);
  if (redirect?.to) {
    console.info('requestRedirectionGuard > Redirecting to', redirect.to);
    next({ name: redirect.to });
  }

    // On check si la route demande l'ajout d'une redirection d'une page
    // vers elle-même via la meta "addRedirect: routeName"
  // Si c'est le cas, on ajoute cette redirection à la session
  else if (to.meta?.addRedirect) {
    store.commit(`session/${ADD_REDIRECTED_PAGE}`, {
      from: to.meta.addRedirect,
      to: to.name,
    }, { root: true });
  } else {
    next();
  }
}
