<template>
  <basket-subscription-page
    v-if="!isSubscriptionActive && !isClosedStoreAndRegion && isUserLoaded" />

  <panel
    v-else
    :back-route="{ name: 'mon-compte' }"
    title="Mon abonnement">
    <template v-if="isClosedStoreAndRegion">
      <div class="panel-section">
        <p class="panel-section__subtitle">
          Abonnement non disponible
        </p>

        <p class="panel-section__p mb-0">
          L'abonnement n'est pas disponible dans votre région.
        </p>
      </div>
    </template>

    <template v-else-if="isSubscriptionActive">
      <div class="panel-section">
        <p class="panel-section__subtitle">
          Formule
        </p>

        <card-subscription
          :box="getSubscription.product"
          :redirect="{ name: 'tunnel-box-slider' }"
          class="panel-section__line">
          <template #footer>
            <potager-button
              v-gtm
              :to="{ name: 'tunnel-box-slider' }"
              full-width>
              Modifier
            </potager-button>
          </template>
        </card-subscription>
      </div>

      <dlp-resume
        :context="Context.Subscription">
        <div class="panel-section__subtitle">
          Livraison
        </div>
      </dlp-resume>

      <div class="panel-section--transparent">
        <div class="panel-section__separator my-10" />

        <div class="text-xl text-center font-bold mb-8">
          Livraisons à venir
        </div>

        <orders-time-slots-list />
      </div>

      <div class="flex justify-center">
        <potager-button
          v-gtm
          :to="{ name: 'mon-compte_subscription_suspend'}"
          is-rounded
          theme="british-racing">
          Me désabonner
        </potager-button>
      </div>
    </template>

    <div
      v-else
      class="panel__loader">
      <icon-ptcy-loader-dots />
    </div>
  </panel>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { GET_SUBSCRIPTION_TIME_SLOTS_ACTION } from 'Stores/types/userActionsTypes';

import BasketSubscriptionPage from 'Pages/basket/BasketSubscriptionPage';

import PotagerButton from 'UI/PotagerButton';

import Panel from 'Components/panel/Panel';
import DlpResume from 'Components/deliveryPoint/DlpResume';
import CardSubscription from 'Components/cards/CardSubscription';

import { tmsToFormat } from 'Classes/utils/DatesUtils';
import MetaInfosService from 'Classes/services/MetaInfoService';

import OrderActionMixin from 'Mixins/OrderActionMixin';

import IconPtcyLoaderDots from 'Icons/regular/IconPtcyLoaderDots';

import OrdersTimeSlotsList from 'Components/timeSlots/OrdersTimeSlotsList.vue';
import { Context } from 'potagerlogic/dist/Enums/Context';

export default {
  mixins: [
    OrderActionMixin,
  ],

  components: {
    OrdersTimeSlotsList,
    BasketSubscriptionPage,
    PotagerButton,
    Panel,
    DlpResume,
    CardSubscription,
    IconPtcyLoaderDots,
  },

  data() {
    return {
      Context,
    };
  },

  computed: {
    ...mapGetters('user', [
      'isUserLoaded',
      'isSubscriptionActive',
      'getSubscription',
    ]),
    ...mapGetters('dashboard', ['isClosedStoreAndRegion']),
  },

  methods: {
    ...mapActions('user', {
      getSubscriptionTimeSlots: GET_SUBSCRIPTION_TIME_SLOTS_ACTION,
    }),
    tmsToFormat,
  },

  created() {
    this.getSubscriptionTimeSlots();
  },

  head: MetaInfosService.generate({
    title: 'Abonnement',
  }),
};
</script>
