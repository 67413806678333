import homepageRoutes from 'Router/routes/homepageRoutes';
import tunnelRoutes from 'Router/routes/tunnelRoutes';
import staticsRoutes from 'Router/routes/staticsRoutes';
import webviewRoutes from 'Router/routes/webviewRoutes';
import sponsorshipRoutes from 'Router/routes/sponsorshipRoutes';
import b2bRoutes from 'Router/routes/b2bRoutes';
import bonCoingRoutes from 'Router/routes/bonCoingRoutes';
import panelsRoutes from 'Router/routes/panelsRoutes';
import fichesRoutes from 'Router/routes/fichesRoutes';
import miscRoutes from 'Router/routes/miscRoutes';

import Page404 from 'Pages/404';

export const Routes = [
  ...homepageRoutes,
  tunnelRoutes,
  staticsRoutes,
  webviewRoutes,
  sponsorshipRoutes,
  ...b2bRoutes,
  bonCoingRoutes,
  ...panelsRoutes,
  ...fichesRoutes,
  ...miscRoutes,
  {
    name: 'not-found',
    path: '/:pathMatch(.*)*',
    component: Page404,
    meta: {
      scrollToTop: true,
    },
  },
];

export default Routes;
