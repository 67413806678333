<template>
  <tunnel-body
    :is-ready="OAC.isReady()"
    align="stretch"
    class="text-center">
    <div v-if="OAC.isReady()">
      <transition
        mode="out-in"
        name="tunnel-oac--transition">
        <div
          :key="OAC.getCurrentStep().id"
          class="flex flex-col justify-between h-full">
          <div>
            <h1 class="text-xl font-extrabold mb-3 mx-10">
              Trouvons le panier qu'il vous faut&nbsp;!
            </h1>

            <p
              class="mb-10"
              v-html="OAC.getMessage()" />

            <collapsible-content
              v-for="(question, qi) in OAC.getCurrentStep().questions"
              :key="qi"
              :is-open="qi <= OAC.getSavedAnswers().filter((e) => e).length">
              <div class="pb-8">
                <p class="text-lg mb-4 text-british-racing">
                  <span class="font-bold">{{ question.title }}</span>
                  <template v-if="question.icon">
                    <template v-if="typeof question.icon === 'string'">
                      {{ question.icon }}
                    </template>
                    <component
                      :is="question.icon"
                      v-else />
                  </template>
                </p>
                <potager-row :condensed="$mq.bp640">
                  <potager-col
                    v-for="(answer, ai) in question.answers"
                    :key="ai"
                    :bp640="OAC.getCurrentStep().id === 'A' ? undefined : 12"
                    :col="12 / question.answers.length">
                    <potager-button
                      :theme="OAC.isAnswerSelected(answer) ? 'beryl-green' : 'beryl-light'"
                      class="px-0"
                      full-width
                      @onClick="OAC.saveAnswer(answer)">
                      {{ answer.label }}
                    </potager-button>
                  </potager-col>
                </potager-row>
              </div>
            </collapsible-content>

            <framboise-message v-if="OAC.getLegend()">
              <template #badge>
                À savoir <span class="font-normal">🤓</span>
              </template>
              {{ OAC.getLegend() }}
            </framboise-message>
          </div>

          <div
            v-if="OAC.getCurrentStep().allowSkip"
            class="text-center">
            <potager-button
              v-gtm
              :full-width="$mq.bp640"
              :to="{
                name: 'tunnel-box-slider',
                params: {
                  regionId: OAC.getRegionId(),
                  ref: PMEL,
                },
              }"
              is-rounded
              theme="white-rock">
              <icon-arrow-right
                v-if="!$mq.bp375"
                class="mr-2 align-top" />
              <span class="underline">
                Passer l'aide et voir tous les paniers !
              </span>
            </potager-button>
          </div>
        </div>
      </transition>
    </div>
  </tunnel-body>
</template>

<script>

import PotagerButton from 'UI/PotagerButton';
import PotagerRow from 'UI/PotagerRow';
import PotagerCol from 'UI/PotagerCol';
import CollapsibleContent from 'Components/collapsible/CollapsibleContent';
import TunnelBody from 'Components/tunnel/TunnelBody';
import FramboiseMessage from 'Components/framboise/FramboiseMessage';
import IconArrowRight from 'Icons/regular/IconArrowRight';

import OAC from 'Classes/oac';
import { PMEL } from 'Classes/Constants';
import Config from 'Classes/oac/Config';
import MetaInfoService from 'Classes/services/MetaInfoService';
import { getResolvedUrl } from 'Classes/utils/RouteUtils';

export default {

  components: {
    TunnelBody,
    FramboiseMessage,
    PotagerButton,
    PotagerRow,
    PotagerCol,
    CollapsibleContent,
    IconArrowRight,
  },

  data: () => ({
    OAC,
    PMEL,
  }),

  mounted() {
    OAC.init();
  },

  head() {
    return MetaInfoService.generate({
      title: 'Trouvons l\'abonnement qu\'il vous faut !',
      link: OAC.getCurrentStep() ? [{
        rel: 'canonical',
        href: getResolvedUrl(this.$route.name, {
          answers: OAC.getCurrentStep().id === Config.firstStepId ? null : OAC.getCurrentStep().id,
          regionId: OAC.getRegionId(),
        }),
      }] : null,
    });
  },

};
</script>

<style lang="scss">
@include define-transition(both, tunnel-oac--transition) {
  transition: transform .5s ease, opacity .5s ease;
}

@include define-transition(both, tunnel-oac--transition) {
  opacity: 0;
}

@include define-transition(enter, tunnel-oac--transition) {
  transform: translate(0, -1rem);
}

@include define-transition(leave, tunnel-oac--transition) {
  transform: translate(0, 1rem);
}
</style>
