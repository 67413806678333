<template>
  <div
    v-if="getTotal > 0"
    class="panel-section">
    <p class="panel-section__subtitle">
      PAIEMENT SÉCURISÉ
    </p>

    <p class="panel-section__line font-medium text-xs.5">
      <icon-lock-4 />
      Vos données sont cryptées et stockées par nos banques
    </p>

    <p class="panel-section__line font-medium text-xs.5">
      <icon-credit-card-disable />
      Vous pouvez demander la suppression de votre carte à tout moment
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import IconLock4 from 'Icons/regular/IconLock4';
import IconCreditCardDisable from 'Icons/regular/IconCreditCardDisable';
import PotagerInfo from 'UI/PotagerInfo';

export default {

  components: {
    IconLock4,
    IconCreditCardDisable,
    PotagerInfo,
  },

  computed: {
    ...mapGetters('basket', [
      'getTotal',
    ]),
  },

};
</script>
