<template>

  <div class="youtube-frame">
    <iframe
      :src="src"
      :class="[
        'youtube-frame__iframe',
        `optanon-category-${cookieCategory}`,
      ]"
      width="560"
      height="315"
      frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen />
    <span
      v-if="!isCookiesAllowed"
      class="youtube-frame__error">
      <p class="youtube-frame__error__title">Ce contenu est bloqué</p>
      <p class="youtube-frame__error__text">Le cookie Youtube est obligatoire pour voir ce contenu</p>
      <potager-button
        theme="white"
        @onClick="toggleCookiesSettings">
        Paramétrer mes cookies
      </potager-button>
    </span>
  </div>

</template>

<script>
import { mapGetters } from 'vuex';
import PotagerButton from 'UI/PotagerButton';

export default {

  components: {
    PotagerButton,
  },

  data: () => ({
    cookieCategory: 'C0056',
  }),

  props: {
    id: {
      type: String,
      required: false,
      default: '',
    },
    fullWidth: {
      type: Boolean,
      required: false,
      default: false,
    },
    autoPlay: {
      type: Boolean,
      required: false,
      default: false,
    },
    url: {
      type: String,
      required: false,
      default: null,
    },
  },

  computed: {
    ...mapGetters('session', [
      'getOptanonActiveGroups',
    ]),
    src() {
      if (this.url) return this.url;
      return `https://www.youtube.com/embed/${this.id}?rel=0&autoplay=${this.autoPlay}`;
    },
    isCookiesAllowed() {
      return this.getOptanonActiveGroups.includes(this.cookieCategory);
    },
  },

  methods: {
    toggleCookiesSettings() {
      if (typeof window.OneTrust?.ToggleInfoDisplay === 'function') {
        window.OneTrust.ToggleInfoDisplay();
      }
    },
  },

};
</script>
