import B2bLandingPage from 'Pages/b2b/B2bLandingPage';
import B2bLandingSuccessPage from 'Pages/b2b/B2bLandingSuccessPage';
import B2bBoxPage from 'Pages/b2b/B2bBoxPage';

const meta = {
  scrollToTop: true,
};

export default [
  {
    path: '/livraison-en-entreprise',
    redirect: '/livraison-en-entreprise/corbeilles-de-fruits-au-bureau',
  },
  {
    name: 'b2b-offers',
    path: '/livraison-en-entreprise/corbeilles-de-fruits-au-bureau',
    component: B2bLandingPage,
    meta,
  },
  {
    name: 'b2b-success',
    path: '/livraison-en-entreprise/corbeilles-de-fruits-au-bureau/merci',
    component: B2bLandingSuccessPage,
    meta,
  },
  {
    name: 'b2b-box',
    path: '/livraison-en-entreprise/box-de-fruits-et-legumes-livrees-au-bureau',
    component: B2bBoxPage,
    meta,
  },
];
