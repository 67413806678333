import store from 'Stores';

import { SET_CURRENT_INDEX, SET_TYPE } from 'Stores/types/tunnelMutationsTypes';

import { TUNNEL_TYPES } from 'Classes/workflow/TunnelTypesConstants';

const workflowGuard = (to, from, next) => {
  if (!store.getters['tunnel/getFlattenSteps']) store.dispatch('tunnel/init');

  /* Define variables */
  const routeBasedStep = store.getters['tunnel/getFlattenSteps']
    .find((step) => step.route === to.name);
  const routeBasedStepIndex = routeBasedStep ? routeBasedStep.index : null;
  const filteredRouteBasedStep = store.getters['tunnel/getAccessibleFlattenSteps']()
    .find((step) => step.route === to.name);
  let filteredRouteBasedStepIndex = filteredRouteBasedStep?.index;

  const fromRouteBasedStep = store.getters['tunnel/getFlattenSteps']
    .find((step) => step.route === from.name);
  const fromRouteBasedStepIndex = fromRouteBasedStep ? fromRouteBasedStep.index : null;
  // set the current step index based on the current route
  store.commit(`tunnel/${SET_CURRENT_INDEX}`, routeBasedStepIndex);

  // set debug mode
  const debugMode = routeBasedStep?.debugGuard || fromRouteBasedStep?.debugGuard;

  /* START Define type */
  // If we go forward in the tunnel,
  // Or if the 'from' step is not a tunnel step (like we come from the dashboard)
  if (fromRouteBasedStepIndex < routeBasedStepIndex || (!fromRouteBasedStep || !fromRouteBasedStep.name.includes('tunnel'))) {
    // If the new step needs a specific type (so isn't in the filtered steps),
    // we set the type to the type of the new step
    if (routeBasedStepIndex && !filteredRouteBasedStepIndex) {
      routeBasedStep.constraints
        .filter((constraint) => constraint.type === 'type' && constraint.operator !== 'not')
        .forEach((constraint) => {
          store.commit(`tunnel/${SET_TYPE}`, {
            name: constraint.name,
            value: constraint.value
          });
        });
    }
  }

  if (to.query) {
    Object.keys(to.query)
      .filter((key) => TUNNEL_TYPES.includes(key))
      .forEach((key) => {
        store.commit(`tunnel/${SET_TYPE}`, {
          name: key,
          value: to.query[key]
        });
      });
  }
  /* END Define type */

  // reload the filtered steps after setting the type
  filteredRouteBasedStepIndex = store.getters['tunnel/getAccessibleFlattenSteps']()
    .find((step) => step.route === to.name)?.index;
  let prevAccessibleStepRoute = store.getters['tunnel/getPrevStepRoute']();

  // if deliveryPoint saved, pass params to the next/prev step
  const deliveryPoint = store.getters['subscriptionBasket/getDeliveryPoint'];
  if (deliveryPoint) {
    const dlpParams = {
      deliveryPointRegionId: deliveryPoint?.regionId,
      deliveryPointId: deliveryPoint?.id,
    };
    if (prevAccessibleStepRoute) prevAccessibleStepRoute.params = { ...dlpParams, ...prevAccessibleStepRoute.params };
  }

  /* next */
  // !== undefined because 0 is a valid index
  if (filteredRouteBasedStepIndex !== undefined) {
    // if the step is accessible, we set it as the current step
    if (debugMode) {
      console.info('Tunnel: Accessible step');
      console.info('Tunnel: Next step is', to.name, 'from', from.name);
      console.info('^GUARD^', '-'.repeat(20));
    }

    console.info('TunnelWorkflowGuard > next');
    next();
  } else if (prevAccessibleStepRoute) {
    // if the step is not accessible, we redirect to the previous accessible step
    if (debugMode) {
      console.info('Tunnel: Inaccessible step');
      console.info('Tunnel: Redirecting to', prevAccessibleStepRoute.name, 'from', from.name, from);
      console.info('Tunnel: wanted step was', to.name, to);
      console.info('^GUARD^', '-'.repeat(20));
    }

    console.info('TunnelWorkflowGuard prevAccessibleStepRoute > Redirecting to', prevAccessibleStepRoute);
    next({
      ...prevAccessibleStepRoute,
      query: {
        ...to.query,
        ...prevAccessibleStepRoute.query
      }
    });
  } else {
    let firstStepRoute = store.getters['tunnel/getFirstStepRoute']();
    // if the step is not found, we redirect to the first step
    if (debugMode) {
      console.info('Tunnel: Inaccessible step');
      console.info('Tunnel: Redirecting to first step', store.getters['tunnel/getFirstStepRoute'](), 'from', from.name);
      console.info('Tunnel: wanted step was', to);
      console.info('^GUARD^', '-'.repeat(20));
    }

    console.info('TunnelWorkflowGuard getFirstStepRoute > Redirecting to', store.getters['tunnel/getFirstStepRoute']());
    next({
      ...firstStepRoute,
      query: {
        ...to.query,
        ...firstStepRoute.query
      }
    });
  }
};

export default workflowGuard;
