<template>
  <flying-page>
    <div class="article-detail">
      <transition name="fade-abs">
        <skeleton
          v-if="report.loading"
          context="supplier" />

        <div v-else>
          <potager-picture
            :src="report.image"
            :title="report.title"
            background
            class="article-detail__poster" />

          <div class="article-detail__content">
            <section class="article-detail__section">
              <h1 class="article-detail__title">
                {{ report.title }}
              </h1>
              <cms
                is-small
                with-hat>
                <p v-html="report.description" />
                <span v-html="report.content" />
              </cms>
            </section>

            <section
              v-if="boxesRelated.length"
              class="article-detail__section print:hidden">
              <h3 class="article-detail__subtitle">
                Cette sélection de produits pourrait vous intéresser
              </h3>

              <potager-row>
                <potager-col
                  v-for="(boxRelated, index) in boxesRelated"
                  :key="index"
                  col="12">
                  <card-product
                    :box="boxRelated"
                    landscape />
                </potager-col>
              </potager-row>
            </section>

            <section class="article-detail__section article-detail__section--grey print:hidden">
              <social-media
                :facebook="facebook"
                :mail="mail"
                :pinterest="pinterest"
                :twitter="twitter" />
            </section>

            <upselling-slider flying />

            <associated-content-slider
              :items="reportsRelated"
              subtitle="Quoi de neuf dernièrement chez Potager City ?"
              title="Nos derniers articles" />

            <section
              v-if="!isSubscriptionActive"
              class="article-detail__section print:hidden">
              <push-subscription condensed />
            </section>
          </div>
        </div>
      </transition>

      <micro-datas
        v-if="report.id"
        :content="report"
        context="article" />
    </div>
  </flying-page>
</template>

<script>
import FlyingPageMixin from 'Mixins/FlyingPageMixin';

import PotagerPicture from 'UI/PotagerPicture';
import PotagerRow from 'UI/PotagerRow';
import PotagerCol from 'UI/PotagerCol';

import Cms from 'Components/cms/Cms';

import MetaInfosService from 'Classes/services/MetaInfoService';
import { isEmpty } from 'Classes/utils/BasicUtils';
import { getResolvedUrl, calcSlug } from 'Classes/utils/RouteUtils';

import shuffle from 'lodash/shuffle';

export default {

  mixins: [
    FlyingPageMixin,
  ],

  components: {
    PotagerPicture,
    PotagerRow,
    PotagerCol,
    Cms,
  },

  data: () => ({
    report: { loading: true },
    reportsRelated: [],
  }),

  computed: {
    id() {
      return parseInt(this.$potagerRoute.params.id, 10);
    },
    boxesRelated() {
      return shuffle(this.getDashboardProducts)
        .splice(0, 2);
    },
    mail() {
      return {
        id: this.report.id,
        subject: this.report.title,
        body: `Hello,\nViens découvrir cet article sur Potager City :\n\n${this.canonical}`,
        url: this.canonical,
      };
    },
    twitter() {
      return {
        url: this.canonical,
        text: `${this.report.title} - Potager City`,
      };
    },
    facebook() {
      return { url: this.canonical };
    },
    pinterest() {
      return {
        url: this.canonical,
        image: this.report.image ? this.report.image.url : null,
        description: `${this.report.title} - Potager City`,
      };
    },
    canonical() {
      if (this.report.id) {
        return getResolvedUrl('reports', {
          slug: calcSlug(this.report.title),
          id: this.report.id,
        });
      }
      return getResolvedUrl('reports-list');
    },
  },

  methods: {
    getReport() {
      this.$api.report.findOne(this.id)
        .then((response) => {
          this.report = response.data;
        })
        .catch(() => {
          this.$events.emit('404');
        });
    },
    getRelatedReports() {
      this.$api.report.findAll()
        .then(({ data }) => {
          this.reportsRelated = data.data.results.filter((e) => e.id !== this.id);
        });
    },
  },

  mounted() {
    this.getReport();
    this.getRelatedReports();

    if (!this.isLoaded) this.fetchDashboard();
  },

  head() {
    let metaInfo = {};

    if (this.report.id) {
      metaInfo = {
        title: this.report.title,
        description: this.report.description,
        image: this.report.image ? this.report.image.url : null,
        link: [{
          rel: 'canonical',
          href: this.canonical,
        }],
      };
    }

    if (!isEmpty(metaInfo)) {
      return MetaInfosService.generate(metaInfo);
    }

    return metaInfo;
  },

};
</script>
