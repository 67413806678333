<template>
  <panel
    :back-route="getBackRoute"
    title="Point de livraison">
    <template #header>
      <p class="text-sm.5 font-medium text-center">
        Variétés ou pépites locales : nos produits changent en fonction de votre région.<br>
        Choisissez votre point de livraison pour commencer votre marché !
      </p>
    </template>

    <div class="h-full bg-bianca px-6 text-center">
      <dlp-search :context="context" />
    </div>
  </panel>
</template>

<script>
import { getDlpBackRoute } from 'Classes/utils/DlpRouteUtils';

import Panel from 'Components/panel/Panel';
import DlpSearch from 'Components/deliveryPoint/step1/DlpSearch';
import { Context } from 'potagerlogic/dist/Enums/Context';

export default {
  props: {
    context: {
      type: String,
      required: true,
    },
  },

  components: {
    DlpSearch,
    Panel,
  },

  computed: {
    getBackRoute() {
      return this.context !== Context.User
        ? getDlpBackRoute({
          context: this.context,
          currentRoute: this.$potagerRoute
        })
        : null;
    },
  },
};
</script>
