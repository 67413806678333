import { mapActions, mapGetters } from 'vuex';

import { REGISTER_TEST_BY_NAME } from 'Stores/types/ABTestActionsTypes';
import { PTCY_3863, PTCY_3910 } from 'Stores/ABTestStore';

export default {

  mounted() {
    // this.REGISTER_TEST_BY_NAME('PTCY-1234 - sample');
    this.REGISTER_TEST_BY_NAME(PTCY_3863);
    this.REGISTER_TEST_BY_NAME(PTCY_3910);
  },

  computed: {
    ...mapGetters('ABTest', [
      'getABTestValueByName',
    ]),
  },

  methods: {
    ...mapActions('ABTest', {
      REGISTER_TEST_BY_NAME,
    }),
    getABTestVariante(testId) {
      return parseInt(this.getABTestValueByName(testId), 10);
    },
  },
};
