<template>
  <panel
    :back-route="getBackRoute"
    title="Votre éligibilité">
    <template #header>
      <p class="text-sm.5 font-medium text-center">
        🌱&nbsp;La livraison à domicile pousse en france ! Vérifiez si votre code postal est éligible.
      </p>
    </template>

    <div class="h-full bg-bianca px-6">
      <dlp-search-home
        :context="context"
        class="mb-8"
        @onSubmit="onSubmit" />
    </div>
  </panel>
</template>

<script>
import { DELIVERY_HOME_TYPE } from 'Classes/workflow/TunnelTypesConstants';
import { getDlpSearchRoute, getDlpListRoute } from 'Classes/utils/DlpRouteUtils';

import Panel from 'Components/panel/Panel';
import DlpSearchHome from 'Components/deliveryPoint/step2/DlpSearchHome';

export default {
  props: {
    context: {
      type: String,
      required: true,
    },
  },

  components: {
    Panel,
    DlpSearchHome,
  },

  computed: {
    getBackRoute() {
      return getDlpSearchRoute({
        context: this.context,
        currentRoute: this.$potagerRoute
      });
    },
  },

  methods: {
    onSubmit() {
      this.$router.push(getDlpListRoute({
        context: this.context,
        type: DELIVERY_HOME_TYPE,
        currentRoute: this.$potagerRoute,
      }));
    },
  },
};
</script>
