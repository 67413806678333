import { ADD_ROUTE_TO_HISTORY_ACTION } from 'Stores/types/routeHistoryActionsTypes';
import { ADD_ROUTE_TO_HISTORY, UPDATE_ROUTE } from 'Stores/types/routeHistoryMutationsTypes';

export default {
  namespaced: true,
  state: {
    history: [],
    currentRoute: {},
  },
  getters: {
    getHistory: (state) => state.history,
    getLastRoute: (state) => state.history[state.history.length - 1],
    getCurrentRoute: (state) => state.currentRoute,
  },
  mutations: {
    [ADD_ROUTE_TO_HISTORY](state, route) {
      state.history = [...state.history.slice(-9), route];
    },
    [UPDATE_ROUTE](state, route) {
      state.currentRoute = route;
    },
  },
  actions: {
    [ADD_ROUTE_TO_HISTORY_ACTION]({ commit }, route) {
      if (this.getters['routeHistory/getLastRoute'] !== route) {
        commit(ADD_ROUTE_TO_HISTORY, route);
      }
    },
  },
};
