<template>
  <panel
    :back-route="{ name: 'mon-compte_payment-methods' }"
    :is-loading="isUserLoading"
    :title="getPanelTitle">
    <transition
      mode="out-in"
      name="slide-right-to-left-25pc">
      <div
        v-if="isLogin"
        key="login">
        <div class="panel-section">
          <p class="panel-section__subtitle">
            <span v-html="getTitle" />
            <potager-badge
              :is-loading="isEditSettingsLoading"
              :theme="getPMAccount.usableForSubscription ? 'wild-willow' : 'zest'"
              class="float-right"
              size="small">
              {{ getPMAccount.usableForSubscription ? 'Active' : 'Inactive' }}
            </potager-badge>

            <potager-input
              :model-value="getPMAccount.email"
              disabled
              label="Vous êtes connecté avec :" />
          </p>

          <potager-checkbox
            :is-loading="isEditSettingsLoading"
            :model-value="getPMAccount.usableForSubscription"
            class="panel-section__p"
            @update:modelValue="paymentMethod.actions.setDefault({isDefault: $event})">
            <span v-html="paymentMethod.helpers.setDefault" />
          </potager-checkbox>

          <p class="panel-section__p mt-4">
            <potager-button
              :is-loading="isLogoutLoading"
              full-width
              size="small"
              theme="stroke"
              @onClick="paymentMethod.actions.logout">
              Me déconnecter
            </potager-button>
          </p>

          <p class="panel-section__helper">
            <span v-html="paymentMethod.helpers.logout" />
          </p>
        </div>
      </div>

      <div
        v-else
        key="logout"
        class="panel-section">
        <p class="panel-section__subtitle">
          <span v-html="getTitle" />
        </p>

        <div class="panel-section__line">
          <p
            class="mb-4"
            v-html="paymentMethod.helpers.login" />

          <potager-button
            :is-loading="isLoginLoading"
            full-width
            size="small"
            @onClick="paymentMethod.actions.openAddModal(this, onSuccess)">
            Connecter
          </potager-button>
        </div>
      </div>
    </transition>
  </panel>
</template>

<script>
import { mapGetters } from 'vuex';
import { UPDATE_USER_ACTION } from 'Stores/types/userActionsTypes';

import GtmMixin from 'Mixins/GtmMixin';

import PotagerBadge from 'UI/PotagerBadge';
import PotagerInput from 'UI/Form/PotagerInput';
import PotagerButton from 'UI/PotagerButton';
import PotagerCheckbox from 'UI/Form/PotagerCheckbox';

import Panel from 'Components/panel/Panel';
import MetaInfosService from 'Classes/services/MetaInfoService';
import { getPaymentMethodByName } from 'Classes/payment-methods';
import { capitalize } from 'Classes/utils/WordingUtils';

export default {
  mixins: [
    GtmMixin,
  ],

  components: {
    PotagerBadge,
    PotagerInput,
    PotagerButton,
    PotagerCheckbox,
    Panel,
  },

  props: {
    name: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapGetters('user', [
      'getPaymentAccountByName',
    ]),
    isUserLoading() {
      return this.$wait.is(UPDATE_USER_ACTION);
    },
    isLogin() {
      return !!this.getPMAccount;
    },
    isEditSettingsLoading() {
      return this.$wait.is(this.paymentMethod.loaders.editSettings);
    },
    isLoginLoading() {
      return this.$wait.is(this.paymentMethod.loaders.login);
    },
    isLogoutLoading() {
      return this.$wait.is(this.paymentMethod.loaders.logout);
    },
    getPanelTitle() {
      return this.paymentMethod.title || this.paymentMethod.label;
    },
    paymentMethod() {
      return getPaymentMethodByName(this.name);
    },
    getPMAccount() {
      return this.getPaymentAccountByName(this.paymentMethod.name);
    },
    getTitle() {
      return this.isLogin
        ? `<span class="text-${this.paymentMethod.name}">${this.paymentMethod.title}</span>`
        : `Connecter votre <span class="text-${this.paymentMethod.name}">${this.paymentMethod.title}</span>`;
    },
    onSuccess() {
      return () => this.trackPaymentInfo({ payment_type: this.name });
    },
  },

  head() {
    return MetaInfosService.generate({
      title: capitalize(this.paymentMethod.label),
    });
  },

};
</script>
