<template>
  <div
    :class="[
      'potager-checkbox potager-input',
      `potager-checkbox--${type}`,
      {
        'potager-checkbox--disabled': disabled || isLoading,
        'potager-checkbox--selected': modelValue,
        'potager-checkbox--is-loading': isLoading,
        'potager-checkbox--has-error': isError
      },
    ]">
    <div class="flex flex-1">
      <div
        :class="['potager-checkbox__input-wrapper', {
          'potager-checkbox__input-wrapper--pulse': pulse && !modelValue}
        ]">
        <input
          :id="name || groupName || id || uid"
          v-model="value"
          :checked="value"
          :disabled="disabled"
          :name="name || groupName"
          :type="type"
          class="potager-checkbox__input">
        <template v-if="type === 'checkbox'">
          <div class="potager-checkbox__indicator" />
        </template>
        <template v-else>
          <div class="potager-checkbox__indicator" />
        </template>
      </div>

      <icon-ptcy-loader-spinner
        v-if="isLoading"
        class="potager-checkbox__loader" />

      <label
        :for="name || groupName || id || uid"
        class="potager-checkbox__label select-none flex-1">
        <slot />
      </label>
    </div>

    <potager-info
      v-if="errors.length"
      size="small"
      status="warning">
      <template #icon>
        <icon-road-sign-warning />
      </template>
      {{ errors[0] }}
    </potager-info>
  </div>
</template>

<script>
import IconPtcyLoaderSpinner from 'Icons/regular/IconPtcyLoaderSpinner';
import IconRoadSignWarning from 'Icons/regular/IconRoadSignWarning';

import { propsBuilder } from 'UI/Tools';
import InputMixin from 'UI/Form/Input/InputMixin';
import PotagerInfo from 'UI/PotagerInfo';
import { ref } from 'vue';

export const props = {
  id: {
    type: [Number, String],
    required: false,
    default: undefined,
  },
  modelValue: {
    type: [Boolean, Array],
    required: false,
    default: false,
  },
  groupName: {
    type: String,
    required: false,
    default: undefined,
  },
  name: {
    type: String,
    required: false,
    default: undefined,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  isLoading: {
    type: Boolean,
    required: false,
    default: false,
  },
  type: {
    type: String,
    required: false,
    default: 'checkbox',
    value: ['checkbox', 'radio'],
  },
  pulse: {
    type: Boolean,
    required: false,
    default: false,
  },
};

export default {
  emits: ['update:modelValue'],

  setup() {
    const uid = ref(Date.now());
    return { uid };
  },

  mixins: [
    InputMixin,
  ],

  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },

  watch: {
    modelValue() {
      this.resetErrors();
    },
  },

  components: {
    IconPtcyLoaderSpinner,
    PotagerInfo,
    IconRoadSignWarning,
  },

  props: propsBuilder(props),

};

</script>

<style lang="scss" scoped>
.potager-checkbox {
  $this: &;

  position: relative;
  display: inline-block;
  @apply text-xs.5;
  line-height: $baseLh;

  & + & {
    margin-top: 1rem;
  }

  &__input {
    z-index: 3;
    opacity: 0;
    cursor: pointer;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    &:checked + .potager-checkbox__indicator {
      opacity: 1;
    }

    &-wrapper {
      position: relative;
      width: 1.25rem;
      height: 1.25rem;
      max-width: 1.25rem;
      max-height: 1.25rem;
      min-width: 1.25rem;
      min-height: 1.25rem;
      margin-right: 0.5rem;
      display: inline-block;
      vertical-align: text-bottom;
      overflow: hidden;

      &:before {
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 50%;
        content: "";
        display: block;
        z-index: 1;
        width: 100%;
        height: 100%;
        background: $white;
        border: solid 1px $porcelain;
      }

      &--pulse {
        @apply text-xs.5;
        animation: pulse--valid 1s infinite;
      }
    }
  }

  &__indicator {
    z-index: 2;
    width: 0.5rem;
    height: 0.5rem;
    background: $britishRacing;
    opacity: 0;
    transition: opacity 0.2s ease;
    position: absolute;
    border-radius: 50%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &__loader {
    position: absolute;
    left: 0;
    top: -1px;
    z-index: 2;
    @apply text-base;
  }

  &--is-loading {
    pointer-events: none;

    .potager-checkbox__input-wrapper {
      opacity: 0;
    }
  }

  &--disabled {
    opacity: 0.5;

    &, * {
      cursor: not-allowed;
    }
  }

  &--has-error {
    #{$this} {
      &__input-wrapper:before {
        border-color: $error;
      }
    }
  }

  &--checkbox #{$this} {
    &__input-wrapper:before {
      border-radius: .25rem;
    }

    &__indicator {
      border-radius: .125rem;
    }
  }
}
</style>
