export const VALIDATED = 'validated';
export const PAYED = 'payed';
export const CAPTURED = 'captured';
export const PREPARATION = 'preparation';
export const DELIVERED = 'delivered';
export const REFUNDED = 'refunded';
export const UNPAYED = 'paiement_failed';
export const CANCELED_CAN_RESUME = 'canceled_can_resume';
export const CANCELED_CAN_NOT_RESUME = 'canceled_can_not_resume';

export const ONESHOT = 'one_shot';
export const ABONNEMENT = 'abonnement';

export const SHIPPED = 'S';
export const CANCELLED = 'X';
export const ORDER_STATUS_PENDING = 'P';
export const ORDER_STATUS_SUBSCRIPTION = 'A';
