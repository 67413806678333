export default {
  name: 'equal',

  valid(value, constraint) {
    const { value: val } = constraint.options;
    if (!val || !value || val === value) return null;

    return {
      field: constraint.field,
      message: constraint.options.message || 'Mot de passe non identique',
      code: null,
    };
  },
};
