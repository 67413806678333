<template>
  <panel
    title="Ajouter des codes promo"
    :back-route="{ name: 'mon-compte_coupons' }">
    <coupons-manager @onSuccess="onSuccess" />
  </panel>
</template>

<script>
import Panel from 'Components/panel/Panel';
import CouponsManager from 'Components/coupons/CouponsManager';

import { mapGetters } from 'vuex';
import MetaInfosService from 'Classes/services/MetaInfoService';

export default {

  components: {
    Panel,
    CouponsManager,
  },

  computed: {
    ...mapGetters('user', [
      'getCoupons',
    ]),
  },

  methods: {
    onSuccess() {
      if (this.getCoupons.length > 0) {
        this.$notify({
          type: 'success',
          title: 'Coupon ajouté',
          text: 'Votre coupon a bien été ajouté.',
        });

        this.$router.push({ name: 'mon-compte_coupons' }).catch(() => {});
      }
    },
  },

  head: MetaInfosService.generate({
    title: 'Ajouter des coupons',
  }),

};
</script>
