<template>
  <panel
    :back-route="computedBackRoute"
    back-btn
    title="Ajouter une carte bancaire">
    <div
      v-show="getPaymentGateway === PaymentMethodIntegration.Paygreen"
      class="panel-section">
      <paygreen-new-card-form
        :name="PaymentMethods.PaygreenBankCard.name"
        :set-primary="setPrimary"
        class="panel-section__line"
        legend-cgv
        @success="onSuccess" />
    </div>

    <div
      v-show="getPaymentGateway === PaymentMethodIntegration.Stripe || !getPaymentGateway"
      class="panel-section">
      <stripe-new-card-form
        :set-primary="setPrimary"
        class="panel-section__line"
        legend-cgv
        @success="onSuccess" />
    </div>
  </panel>
</template>

<script>
import { mapGetters } from 'vuex';
import MobileAppMixin from 'Mixins/MobileAppMixin';

import { PaymentMethods } from 'Classes/payment-methods';
import { PaymentMethodIntegration } from 'potagerlogic/dist/Enums/PaymentMethods';

import Panel from 'Components/panel/Panel';
import StripeNewCardForm from 'Components/stripe/StripeNewCardForm';
import PaygreenNewCardForm from 'Components/paygreen/PaygreenNewCardForm';

import { already_added } from 'Classes/StripeErrors';

import MetaInfosService from 'Classes/services/MetaInfoService';
import { Context } from 'potagerlogic/dist/Enums/Context';

export default {
  mixins: [
    MobileAppMixin,
  ],

  components: {
    PaygreenNewCardForm,
    Panel,
    StripeNewCardForm,
  },

  data: () => ({
    forceSetPrimary: false,
    PaymentMethods,
    PaymentMethodIntegration,
  }),

  props: {
    autoSetPrimary: {
      type: Boolean,
      required: false,
      default: false,
    },
    setPrimaryRoute: {
      type: Object,
      required: false,
      default: null,
    },
    context: {
      type: String,
      required: false,
      default: Context.User,
    },
  },

  computed: {
    ...mapGetters('user', [
      'getDefaultBankCard',
      'getPaymentGateway',
    ]),
    computedBackRoute() {
      const defaultBackRoute = this.context === Context.Basket ? 'basket' : 'mon-compte_payment-methods';

      const orderId = this.$potagerRoute?.params?.orderId;
      return orderId ? {
        name: 'mon-compte_orders_detail_select-card',
        params: {
          orderId,
        }
      } : {
        name: defaultBackRoute,
      };
    },
    computedSuccessRoute() {
      return this.context === Context.Basket ? { name: 'basket_payment' } : this.computedBackRoute;
    },
    setPrimary() {
      return this.autoSetPrimary || this.forceSetPrimary;
    },
    askPrimary() {
      return !this.autoSetPrimary && !this.forceSetPrimary && !!this.setPrimaryRoute;
    },
  },

  methods: {
    onSuccess(newCard) {
      if (newCard) {
        if (this.isInAppWebView) {
          this.redirectWebApp({
            success: true,
            newCard
          });
        } else {
          const params = { cardId: newCard.id };
          const askPrimary = this.askPrimary && !newCard.isExpired;

          let route = askPrimary ? this.setPrimaryRoute : this.computedSuccessRoute;
          if (this.$potagerRoute.query.back && !askPrimary) {
            route = JSON.parse(decodeURIComponent(this.$potagerRoute.query.back));
          }
          route = {
            name: route.name,
            params: {
              ...params,
              ...route.params,
            },
          };

          if (!askPrimary) {
            this.$notify({
              type: 'success',
              title: 'Carte ajoutée',
              text: 'Votre carte a bien été ajoutée.'
            });
          }

          this.$router.push(route)
            .catch(() => {
            });
        }
      } else {
        // signifie que la carte n'a pas été ajoutée car elle existe déjà en base
        if (this.isInAppWebView) {
          this.redirectWebApp({
            success: false,
            message: already_added
          });
        } else {
          this.$notify({
            type: 'warning',
            title: 'Carte déjà enregistrée',
            text: already_added,
          });
        }
      }
    },
  },

  mounted() {
    this.forceSetPrimary = !this.getDefaultBankCard;
  },

  head: MetaInfosService.generate({
    title: 'Ajouter une carte',
  }),
};
</script>
