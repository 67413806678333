<template>
  <potager-modal is-video>
    <template #body>
      <youtube-frame
        :id="videoId"
        auto-play />
    </template>
  </potager-modal>
</template>

<script>
import PotagerModal from 'UI/PotagerModal';
import YoutubeFrame from 'Components/videoFrame/YoutubeFrame';

export default {

  components: {
    PotagerModal,
    YoutubeFrame,
  },

  props: {
    videoId: {
      type: String,
      required: false,
      default: 'zPX7o9qzC8U',
    },
  },

};
</script>
