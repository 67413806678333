<template>
  <div>
    <transition
      :css="false"
      @before-enter="beforeEnter"
      @enter="enter"
      @after-enter="afterEnter"
      @enter-cancelled="enterCancelled"
      @before-leave="beforeLeave"
      @leave="leave"
      @after-leave="afterLeave">
      <div
        v-if="isOpen"
        :key="name"
        class="overflow-hidden transition-all duration-300">
        <div ref="body">
          <slot />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
/* eslint-disable no-param-reassign */

export default {

  props: {
    isOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
    name: {
      type: String,
      required: false,
      default: '',
    },
  },

  methods: {
    beforeEnter(el) {
      el.style.height = 0;
    },
    enter(el, done) {
      const h = this.$refs.body.offsetHeight;
      const onTransitionEnd = (e) => {
        done();
        e.target.removeEventListener('transitionend', onTransitionEnd);
      };

      el.addEventListener('transitionend', onTransitionEnd, false);

      this.$nextTick(() => {
        el.style.height = `${h}px`;
      });
    },
    afterEnter(el) {
      el.style.height = null;
    },
    enterCancelled(el) {
      el.style.height = null;
    },
    beforeLeave(el) {
      el.style.height = `${el.offsetHeight}px`;
    },
    leave(el, done) {
      const onTransitionEnd = (e) => {
        done();
        e.target.removeEventListener('transitionend', onTransitionEnd);
      };

      el.addEventListener('transitionend', onTransitionEnd, false);

      setTimeout(() => {
        el.style.height = '0px';
      }, 0);
    },
    afterLeave(el) {
      el.style.height = null;
    },
  },

};
</script>
