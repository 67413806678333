<template>
  <potager-modal :has-large-buttons="isOrderEmpty">
    <template #body>
      Vous souhaitez changer de jour de retrait et il se trouve
      {{ isPlural ? 'que les éléments présents' : 'que l\'élément présent' }} dans votre commande
      {{ isPlural ? 'sont épuisés' : 'est épuisé' }} pour ce jour en particulier.

      <div class="modal-container__out-of-stock-list">
        <p class="modal-container__out-of-stock-list__subtitle">
          {{ textBoxOutOfStock }}
        </p>
        <p
          v-for="(product, index) in products"
          :key="index"
          class="modal-container__out-of-stock-list__box">
          <span class="modal-container__out-of-stock-list__box__name">
            {{ product.boxName }}
          </span>
          <span class="modal-container__out-of-stock-list__box__price">
            -{{ formatPrice(product.price) }}
          </span>
        </p>
      </div>

      <h4 class="modal-container__subtitle">
        Que souhaitez-vous faire ?
      </h4>

      <p v-if="!isOrderEmpty">
        En confirmant le changement de jour de retrait,
        {{ isPlural ? 'les box épuisées seront supprimées' : 'la box épuisée sera supprimée ' }}
        de votre commande. En annulant, vous conserverez l’ancien jour de retrait et
        {{ isPlural ? 'les box seront toujours présentes' : 'la box sera toujours présente' }}
        dans votre commande.
      </p>
    </template>

    <template #footer>
      <potager-button
        :full-width="true"
        :label="isOrderEmpty ? 'Conserver la commande initiale' : 'Annuler'"
        theme="stroke"
        @onClick="goToCommand" />
      <potager-button
        v-if="!isOrderEmpty"
        :full-width="true"
        :is-loading="isLoading"
        label="Confirmer"
        @onClick="confirm" />
      <potager-button
        v-else
        :full-width="true"
        :is-loading="isLoading"
        label="Choisir un autre jour de retrait"
        @onClick="changeOrderPdl" />
    </template>
  </potager-modal>
</template>

<script>
import { formatPrice } from 'Classes/utils/PricesUtils';

import PotagerModal from 'UI/PotagerModal';
import PotagerButton from 'UI/PotagerButton';

import {
  UPDATE_SUBSCRIPTION_BASKET_DELIVERY_POINT_ACTION,
  CONFIRM_SUBSCRIPTION_BASKET_ACTION
} from 'Stores/types/subscriptionBasketActionsTypes';
import { UPDATE_DELIVERY_POINT_ORDER_ACTION } from 'Stores/types/userActionsTypes';

export default {

  components: {
    PotagerModal,
    PotagerButton,
  },

  props: {
    products: {
      type: Array,
      required: false,
      default: () => [],
    },
    order: {
      type: Object,
      required: false,
      default: () => {
      },
    },
    onConfirmed: {
      type: Function,
      required: false,
      default: null,
    },
  },

  methods: {
    formatPrice,
    close() {
      this.$modal.close();
    },
    confirm() {
      if (typeof this.onConfirmed === 'function') {
        this.onConfirmed()
          .then(() => {
            this.goToCommand();
          });
      }
    },
    changeOrderPdl() {
      this.close();
    },
    goToCommand() {
      this.close();
      this.$router.push({
        name: 'mon-compte_orders_detail',
        params: {
          orderId: this.order.id,
        },
      })
        .catch(() => {
        });
    },
  },

  computed: {
    isPlural() {
      return this.products.length > 1;
    },
    isOrderEmpty() {
      return this.order.boxes.length <= this.products.length;
    },
    textBoxOutOfStock() {
      if (this.isPlural) {
        return 'BOX ÉPUISÉES :';
      }
      return 'BOX ÉPUISÉE :';
    },
    isLoading() {
      return this.$wait.is([
        UPDATE_SUBSCRIPTION_BASKET_DELIVERY_POINT_ACTION,
        CONFIRM_SUBSCRIPTION_BASKET_ACTION,
        UPDATE_DELIVERY_POINT_ORDER_ACTION,
      ]);
    },
  },

};
</script>
