<template>
  <component
    tag="div"
    :is="noPasswordValidator ? 'div' : 'potager-tooltip'"
    :always="isFocus"
    class="w-full potager-input password-input__tooltip-wrapper"
    shadow
    theme="dark">
    <template
      v-if="!noPasswordValidator"
      #message>
      <ul class="text-xs.5 text-porcelain whitespace-nowrap text-left">
        <li
          v-for="(criteria, i) in VALIDATORS"
          :key="i">
          <span :class="!isCriteriaInError(i) && 'text-success font-bold'">
            <component
              :is="isCriteriaInError(i) ? IconDelete2 : IconCheckCircle1"
              class="text-xxs mr-1" />
            {{ criteria.label }}
          </span>
        </li>
      </ul>
    </template>

    <base-input
      ref="input"
      v-bind="$props"
      v-model="value"
      :autocomplete="noPasswordValidator ? 'on' : 'new-password'"
      :constraints="computedConstraints"
      :type="forceVisibility ? 'text' : type"
      class="z-10"
      @change="$emit('change', $event)"
      @onBlur="onBlur"
      @onEnter="$emit('onEnter', $event)"
      @onError="$emit('onError', $event)"
      @onFocus="onFocus"
      @onKeyDown="$emit('onKeyDown', $event)"
      @onKeyUp="$emit('onKeyUp', $event)"
      @onPrefixClick="$emit('onPrefixClick', $event)"
      @onReset="$emit('onReset', $event)"
      @onSubmit="$emit('onSubmit', $event)">
      <template #icon>
        <slot name="icon" />
      </template>

      <template #action>
        <potager-button
          is-square
          size="small"
          theme="white"
          type="button"
          @onClick="onToggleVisibility">
          <template #icon>
            <span class="text-sm.5 leading-0">
              <icon-view-off v-if="forceVisibility" />

              <icon-view1 v-else />
            </span>
          </template>
        </potager-button>
      </template>

      <template
        v-if="!noPasswordValidator"
        #innerInput>
        <transition name="appear-from-top">
          <div
            v-if="showStrengthScore"
            class="strength-score -mt-1 top-full w-full absolute left-0 right-0">
            <div
              class="strength-score__progress-wrapper h-1 w-full bg-white rounded-b overflow-hidden">
              <div
                :class="`strength-score__progress-bar ${passwordStrength.color} h-full transition-all origin-left duration-300`"
                :style="{transform: `scaleX(${passwordStrength.score/5})`}" />
            </div>
          </div>
        </transition>
      </template>
    </base-input>
  </component>
</template>
<script>

import asdfgh from 'asdfgh';

import { propsBuilder } from 'UI/Tools';
import PotagerButton from 'UI/PotagerButton';
import PotagerTooltip from 'UI/PotagerTooltip';
import InputMixin from 'UI/Form/Input/InputMixin';
import BaseInput from 'UI/Form/Input/BaseInput';

import IconView1 from 'Icons/regular/IconView1';
import IconViewOff from 'Icons/regular/IconViewOff';
import IconCheckCircle1 from 'Icons/bold/IconCheckCircle1';
import IconDelete2 from 'Icons/bold/IconDelete2';

import PasswordValidator, { VALIDATORS, richelieuList } from 'Classes/validators/PasswordValidator';

const passwordStrengthMapping = [
  {
    score: 1,
    label: 'Très faible',
    color: 'bg-monza',
  },
  {
    score: 2,
    label: 'Faible',
    color: 'bg-zest',

  },
  {
    score: 3,
    label: 'Moyen',
    color: 'bg-sunglow',
  },
  {
    score: 4,
    label: 'Fort',
    color: 'bg-sushi',
  },
  {
    score: 5,
    label: 'Très fort',
    color: 'bg-forest-green',
  },
];

export const props = {
  noPasswordValidator: {
    type: Boolean,
    required: false,
    default: false,
  },
};

export default {
  name: 'PasswordInput',
  props: propsBuilder(props),
  components: {
    PotagerTooltip,
    BaseInput,
    IconView1,
    IconViewOff,
    PotagerButton,
  },
  mixins: [InputMixin],

  emits: ['onFocus', 'onBlur'],

  mounted() {
    this.$events.on('request:inputVisibility', this.onRequestToggleVisibility);
  },
  data() {
    return {
      forceVisibility: false,
      VALIDATORS,
      IconCheckCircle1,
      IconDelete2,
      isFocus: false,
      passwordStrength: {},
    };
  },
  beforeUnmount() {
    this.$events.off('request:inputVisibility', this.onRequestToggleVisibility);
  },
  computed: {
    getPasswordValidatorsDetails() {
      return PasswordValidator.valid(this.value, 'password');
    },
    showStrengthScore() {
      return this.value && this.isFocus && !this.noPasswordValidator;
    },
    computedConstraints() {
      if (this.noPasswordValidator) {
        return this.constraints;
      }
      return [
        {
          constraint: 'password',
          field: this.name
        },
        ...this.constraints,
      ];
    },

  },

  watch: {
    value() {
      // check password strength
      // with asdfgh (a async wrapper of zxcvbn)
      if (this.showStrengthScore) {
        if (this.value) {
          asdfgh(this.value, richelieuList)
              .then((result) => {
                this.passwordStrength = passwordStrengthMapping.find((option) => (option.score - 1) === result.score);
              });
        } else {
          this.passwordStrength = {};
        }
      }
    },
  },

  methods: {
    onToggleVisibility() {
      this.forceVisibility = !this.forceVisibility;
    },
    onRequestToggleVisibility(val) {
      this.forceVisibility = val;
    },
    isCriteriaInError(index) {
      if (this.noPasswordValidator) return false;
      if (!this.value) return true;
      return this.getPasswordValidatorsDetails?.errors?.find((e) => e.index === index);
    },
    onFocus(e) {
      this.$emit('onFocus', e);
      this.isFocus = true;
    },
    onBlur(e) {
      this.$emit('onBlur', e);
      this.isFocus = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.strength-score {
  z-index: -1;
}

.password-input {
  &__tooltip-wrapper {
    &:deep().potager-tooltip--bottom {
      .potager-tooltip {
        &__body {
          @apply mt-1;
        }
      }
    }
  }
}
</style>
