<template>
  <potager-alert
    v-if="show"
    :id="promotedCoupon.id"
    clickable
    closable
    show-once
    theme="go-green"
    @onClick="showCouponPolicy">
    <span v-html="promotedCoupon.promotedTitle" />
  </potager-alert>
</template>

<script>
import PotagerAlert from 'UI/PotagerAlert';

import SeasonalCouponMixin from 'Mixins/SeasonalCouponMixin';

export default {
  name: 'AlertPromotedCoupon',
  components: { PotagerAlert },
  mixins: [
    SeasonalCouponMixin,
  ],
  computed: {
    show() {
      return !!this.promotedCoupon && this.isFirstOrder;
    },
  },
};
</script>
