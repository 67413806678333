<template>
  <panel
    back-btn
    class="coupon-luncheon-page"
    title="Comment ça marche ?">
    <div class="panel-section">
      <ol class="coupon-luncheon-page__howto__list panel-section__line">
        <li
          v-for="(step, i) in steps"
          :key="i"
          class="coupon-luncheon-page__howto__list__step">
          {{ step }}
        </li>
      </ol>

      <p class="panel-section__subtitle">
        Les titres restaurant acceptés
      </p>

      <ul class="coupon-luncheon-page__howto__type-list panel-section__line">
        <li
          v-for="type in types"
          :key="type"
          class="coupon-luncheon-page__howto__type-list__item">
          <img :src="type">
        </li>
      </ul>
    </div>

    <template #footer>
      <potager-button
        :full-width="true"
        :to="{ name: 'mon-compte_luncheons_add' }"
        label="Soumettre un lot" />
    </template>
  </panel>
</template>

<script>
import Panel from 'Components/panel/Panel';
import PotagerButton from 'UI/PotagerButton';

import CHEQUEDEJEUNER from 'Images/titres-restaurants/cheque-dejeuner@2x.jpg';
import TICKETRESTAURANT from 'Images/titres-restaurants/ticket-restaurant@2x.jpg';
import CHEQUERESTAURANTSODEXO from 'Images/titres-restaurants/cheque-restaurant-sodexo@2x.jpg';
import CHEQUEDETABLE from 'Images/titres-restaurants/cheque-de-table@2x.jpg';

export default {

  components: {
    Panel,
    PotagerButton,
  },

  data: () => ({
    steps: [
      'J’ajoute mes titres restaurant dans un lot et je le soumets pour validation.',
      'J’imprime le bordereau du lot que j\'ai soumis.',
      'J’envoie le bordereau imprimé et mes titres restaurant à l’adresse indiquée dans le bordereau.',
      'Dès réception du courrier, Potager City procède à la validation de tous vos titres restaurant. Ils seront automatiquement associés à votre compte et disponibles.',
    ],
    types: [
      CHEQUEDEJEUNER,
      TICKETRESTAURANT,
      CHEQUERESTAURANTSODEXO,
      CHEQUEDETABLE,
    ],
  }),

};
</script>

<style lang="scss">
.coupon-luncheon-page {
  &__howto {

    &__list {
      counter-reset: index;

      &__step {
        margin-left: 0;
        list-style: none;
        @apply text-sm.5;
        line-height: 1.5em;
        padding-left: 2.5rem;
        position: relative;
        margin-bottom: .5rem;

        @include bp768() {
          @apply text-xs.5;
        }

        &:before {
          content: counter(index);
          counter-increment: index;
          background: $charcoal;
          color: $white;
          position: absolute;
          left: 0;
          height: 1.5rem;
          width: 1.5rem;
          text-align: center;
          @apply text-xxs;
          line-height: 1.5rem;
          font-weight: $weightBold;
          text-indent: 1px;
          border-radius: 1rem;
          z-index: 2;
        }

        &:after {
          content: '';
          position: absolute;
          height: 100%;
          top: .75rem;
          left: .75rem;
          border-right: dashed 1px $charcoal;
          z-index: 1;
          opacity: .33;
        }

        &:last-child {
          margin-bottom: 0;

          &:after {
            display: none;
          }
        }
      }
    }

    &__type-list {

      &__item {
        max-height: 4rem;
        display: inline-block;
        margin: 0 .5rem;

        img {
          max-height: inherit;
          width: auto;
        }
      }
    }
  }

}
</style>
