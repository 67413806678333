import {
  formatPrice,
  getOrderShippingCost,
  getSubscriptionEnterpriseDiscount,
  getSubTotalOrder, getTotalOrder
} from 'Classes/utils/PricesUtils';

import { couponUnit } from 'Classes/utils/CouponsUtils';

/**
 * Get the order line.
 *
 * @param {Object} order - The current order object.
 * @returns {Object|null} The order line object or null if no order.
 */
export const getOrderLine = (order) => (order ? {
  label: 'Votre commande',
  value: `${formatPrice(getSubTotalOrder(order))}`,
} : null);

/**
 * Get the completion order line.
 *
 * @param {Object} order - The current order object.
 * @returns {Object|null} The completion order line object or null if no order.
 */
export const getCompletionOrderLine = (order) => (order ? {
  label: 'Votre panier en cours',
  value: `${formatPrice(getSubTotalOrder(order))}`,
} : null);

/**
 * Get the previous order line.
 *
 * @param {Object} order - The current order object.
 * @returns {Object|null} The previous order line object or null if no order.
 */
export const getPreviousOrderLine = (order) => (order ? {
  label: 'Votre commande en cours',
  value: `${formatPrice(getSubTotalOrder(order))}`,
} : null);

/**
 * Get the shipping cost line for an order.
 *
 * @param {Object} order - The current order object.
 * @param {Object} [order.deliveryPoint] - The delivery point object.
 * @param {boolean} [order.deliveryPoint.hasShippingCost] - Whether the delivery point has a shipping cost.
 * @returns {Object} The shipping cost line object.
 */
export const getShippingCostLine = (order) => {
  const cost = getOrderShippingCost(order);
  const isFree = !cost && order?.deliveryPoint?.hasShippingCost;

  return {
    label: 'Livraison',
    value: isFree ? 'Offerte' : formatPrice(cost, { freeString: 'Offerte' }),
    type: isFree || !cost ? 'discount' : undefined,
  };
};

/**
 * Get the enterprise discount line for an order.
 *
 * @param {Object} order - The current order object.
 * @returns {Object|null} The enterprise discount line object or null if no discount.
 */
export const getEntrepriseDiscountLine = (order) => {
  const discount = getSubscriptionEnterpriseDiscount(order);
  return discount && discount !== '0.00' ? {
    label: 'Remise entreprise',
    value: `-${formatPrice(discount)}`,
    type: 'discount',
  } : null;
};

/**
 * Get the list of coupon lines for an order.
 *
 * @param {Object} order - The current order object.
 * @param {Array<Object>} order.coupons - The list of coupons applied to the order.
 * @param {string} order.coupons[].couponCode - The code of the coupon.
 * @returns {Array<Object>} The list of coupon lines.
 */
export const getCouponsListLines = (order) => {
  return order.coupons
    .map((coupon) => ({
      label: coupon.couponCode,
      value: couponUnit(coupon),
      type: 'discount',
    }));
};

/**
 * Get the total line for an order.
 *
 * @param {Object} order - The current order object.
 * @returns {Object} The total line object.
 */
export const getTotalLine = (order) => {
  return {
    label: 'Total',
    value: `${formatPrice(getTotalOrder(order))}`,
    type: 'total',
  };
};

/**
 * Get the receipt lines for an order.
 *
 * @param {Object} order - The current order object.
 * @param {Object} [previousOrder] - The previous order object.
 * @param {Object} [completionOrder] - The completion order object.
 * @returns {Array<Object>} The list of receipt lines.
 */
export const getReceiptLines = (order, previousOrder, completionOrder) => {
  const lines = previousOrder && completionOrder
    ? [getCompletionOrderLine(completionOrder), getPreviousOrderLine(previousOrder)]
    : [getOrderLine(order)];

  return [...lines, getShippingCostLine(order), getEntrepriseDiscountLine(order)].filter(Boolean);
};
