<template>
  <potager-receipt-total
    :label="getLabel"
    :sub-totals="getRealTotal"
    :value="getValue" />
</template>

<script>
import { mapGetters } from 'vuex';
import { formatPrice, getTotalOrder } from 'Classes/utils/PricesUtils';

import PotagerReceiptTotal from 'UI/PotagerReceiptTotal';

export default {

  components: {
    PotagerReceiptTotal,
  },

  props: {
    totalOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    ...mapGetters('basket', [
      'getTotal',
      'getLinkedOrder',
      'getSimulatedOrder',
    ]),
    getLabel() {
      if (this.totalOnly) return undefined;

      return this.getRealTotal.length
        ? this.getSubtotal >= 0 ? 'MONTANT AJOUTÉ' : 'MONTANT SOUSTRAIT'
        : undefined;
    },
    getSubtotal() {
      return getTotalOrder(this.getSimulatedOrder) - getTotalOrder(this.getLinkedOrder);
    },
    getValue() {
      if (this.totalOnly) return `${formatPrice(getTotalOrder(this.getSimulatedOrder))}`;
      if (this.getSimulatedOrder && this.getLinkedOrder) {
        return `${formatPrice(this.getSubtotal)}`;
      }
      return `${formatPrice(this.getTotal)}`;
    },
    getRealTotal() {
      if (this.totalOnly) return null;
      const {
        getLinkedOrder,
        getSimulatedOrder
      } = this;
      const total = getLinkedOrder && getSimulatedOrder && getTotalOrder(getSimulatedOrder);
      return [
        ...(total ? [{
          label: 'Nouveau total de la commande',
          value: `${formatPrice(total)}`,
        }] : []),
      ];
    },
  },

};
</script>
