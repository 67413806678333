<template>
  <span>
    <span id="top" />

    <div
      ref="wrapper"
      class="tabs-wrapper"
      :style="sticky.styles">
      <nav
        ref="tabs"
        :class="{
          'tabs': true,
          'tabs--sticky': sticky.enabled,
          'tabs--top': sticky.enabled && sticky.position === 'top',
          'tabs--bottom': sticky.enabled && sticky.position === 'bottom',
        }">
        <potager-container
          class="tabs__list bp768:py-6 bp768:my-0"
          tag="ul">
          <li
            v-for="(tab, index) in tabs"
            :key="index"
            :class="{
              'tabs__list__item': true,
              'tabs__list__item--current': isCurrent(tab),
            }">
            <potager-link
              v-if="tab.route"
              v-wave
              :class="{
                'tabs__list__item__link': true,
                'tabs__list__item__link--current': isCurrent(tab),
              }"
              :to="tab.route"
              v-html="tab.label" />
            <span
              v-else-if="tab.anchor"
              v-scroll-to="{
                el: tab.anchor,
                offset,
              }"
              v-wave
              :class="{
                'tabs__list__item__link': true,
                'tabs__list__item__link--current': isCurrent(tab),
              }"
              v-html="tab.label" />
          </li>
        </potager-container>
      </nav>
    </div>
  </span>
</template>

<script>
import PotagerContainer from 'UI/PotagerContainer';

export default {

  components: {
    PotagerContainer,
  },

  data: () => ({
    sticky: {
      enabled: false,
      position: null,
      styles: {},
    },
  }),

  props: {
    tabs: {
      type: Array,
      required: true,
    },
    offset: {
      type: Number,
      required: false,
      default: 0,
    },
    isSticky: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  methods: {
    onScroll() {
      const { wrapper } = this.$refs;

      if (!wrapper) return;

      const { top, height } = wrapper.getBoundingClientRect();
      const { innerHeight } = window;

      const isStickyTop = top <= 0 && !this.$mq.bp1024;
      const isStickyBottom = top + height >= innerHeight && !this.$mq.bp1024;
      const enabled = (isStickyTop || isStickyBottom) && this.isSticky;

      let position = null;
      if (enabled) position = isStickyTop ? 'top' : 'bottom';

      const styles = enabled ? {
        height: `${height}px`,
      } : {};

      this.sticky = {
        enabled,
        position,
        styles,
      };
    },
    isCurrent(tab) {
      if (tab.route && tab.route.name === this.$route.name) return true;
      return false;
    },
  },

  mounted() {
    window.addEventListener('scroll', this.onScroll);
    window.addEventListener('resize', this.onScroll);
    this.onScroll();
  },

  beforeUnmount() {
    window.removeEventListener('scroll', this.onScroll);
    window.removeEventListener('resize', this.onScroll);
  },

};
</script>
