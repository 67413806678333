import { capitalize } from 'Classes/utils/WordingUtils';
import { formatPrice, getBoxPrice } from 'Classes/utils/PricesUtils';

/**
 * Get the weight of the product packaging.
 *
 * @param {Object} productPackaging - The product packaging object.
 * @param {string} [productPackaging.article] - The article of the product packaging.
 * @param {number} [productPackaging.quantity] - The quantity of the product packaging.
 * @param {string} [productPackaging.label] - The label of the product packaging.
 * @param {boolean} [productPackaging.displayOnlyName] - Whether to display only the name.
 * @param {string} [productPackaging.unit] - The unit of the product packaging.
 * @param {boolean} [productPackaging.isEstimate] - Whether the weight is an estimate.
 * @param {number} [productPackaging.value] - The value of the product packaging.
 * @param {Object} [options] - Additional options.
 * @param {string} [options.format='short'] - The format of the weight ('short' or 'long').
 * @returns {string} The formatted weight of the product packaging.
 */
export const getProductPackagingWeight = ({ productPackaging }, { format = 'short' } = {}) => {
  if (!productPackaging?.value) return '';

  const {
    article,
    quantity,
    label,
    displayOnlyName,
    unit,
    isEstimate,
    value
  } = productPackaging;
  let text = '';

  if (format === 'long' || displayOnlyName) {
    if (format === 'long' && article) text += capitalize(article);
    if (quantity) text += ` ${quantity > 1 ? quantity : format !== 'long' ? article : ''}`;
    text += ` ${label}`;
  }

  if (!displayOnlyName) {
    const unitText = unit.includes('pièce') ? ` ${value * quantity > 1 ? unit + 's' : unit}` : unit;
    if (quantity > 1 && format !== 'long') text += ` ${quantity}&nbsp;x&nbsp;`;
    text += `${format === 'long' ? ' de ' : ''}${value}${unitText}`;
  }

  if (isEstimate) text += format !== 'long' ? ' min.' : ' minimum';

  return text;
};

/**
 * Get the minimal weight of the product packaging.
 *
 * @param {Object} productPackaging - The product packaging object.
 * @param {string} [productPackaging.unit] - The unit of the product packaging.
 * @param {number} [productPackaging.value] - The value of the product packaging.
 * @param {boolean} [productPackaging.isEstimate] - Whether the weight is an estimate.
 * @returns {string} The minimal weight of the product packaging.
 */
export const getProductPackagingMinimalWeight = ({ productPackaging }) => {
  let text = '';
  if (!productPackaging?.value) return text;

  const unit = productPackaging.unit.indexOf('pièce') !== -1
    ? ` ${productPackaging.value > 1 ? productPackaging.unit + 's' : productPackaging.unit}`
    : productPackaging.unit;
  text += `${productPackaging.value}${unit}`;
  if (productPackaging.isEstimate) text += ' min.';

  return text;
};

/**
 * Get the price of the product packaging by weight.
 *
 * @param {Object} box - The box object.
 * @param {Object} box.productPackaging - The product packaging object.
 * @param {string} box.productPackaging.price - The price of the product packaging.
 * @param {string} box.productPackaging.masterUnit - The master unit of the product packaging.
 * @param {string} priceCategory - The category of the price.
 * @returns {string} The formatted price of the product packaging by weight.
 */
export const getProductPackagingPriceByWeight = ({ productPackaging }, priceCategory) => {
  if (!productPackaging?.price || !priceCategory || !productPackaging?.masterUnit) return '';
  const price = getBoxPrice(productPackaging, priceCategory);
  return `${formatPrice(price)}/${productPackaging.masterUnit.toLowerCase()}`;
};
