<template>
  <potager-container class="bp1024:pr-0 bp640:pr-6">
    <potager-row>
      <potager-col
        class="my-auto bp640:mb-12 py-0"
        col="4"
        offset="1"
        bp1024="6"
        offset-bp1024="0"
        bp640="12"
        offset-bp640="0">
        <highlights-icon
          v-if="icon"
          class="mb-4"
          :icon="icon" />

        <h2 class="content-two-column__title mb-10 bp640:mb-5 text-3xl bp768:text-xl.5 font-semibold">
          <slot name="title" />
        </h2>

        <div class="content-two-colum__text leading-normal">
          <slot name="text" />
        </div>
      </potager-col>

      <potager-col
        class="py-0"
        col="5"
        offset="1"
        bp640="12"
        offset-bp640="0">
        <slot name="picture">
          <potager-picture
            :height="$mq.bp640 ? undefined : '550px'"
            :src="src"
            class="w-full relative bp640:max-w-[400px]"
            extra-img-class="rounded-xl bp1024:rounded-r-none bp640:rounded-r-xl overflow-hidden"
            placeholder>
            <slot name="picture-extra" />
          </potager-picture>
        </slot>
      </potager-col>
    </potager-row>
  </potager-container>
</template>

<script>
import HighlightsIcon from 'Components/highlightsSlider/HighlightsIcon';

import PotagerContainer from 'UI/PotagerContainer';
import PotagerRow from 'UI/PotagerRow';
import PotagerCol from 'UI/PotagerCol';
import PotagerPicture from 'UI/PotagerPicture';

export default {
  name: 'ContentTwoColumn',

  components: {
    PotagerContainer,
    PotagerPicture,
    HighlightsIcon,
    PotagerCol,
    PotagerRow,
  },

  props: {
    icon: {
      type: String,
      default: undefined,
    },
    src: {
      type: String,
      required: true,
    },
  },
};
</script>
