<template>
  <div>
    <div class="overflow-hidden">
      <b2b-banner />

      <potager-container class="mb-24 bp768:mb-12 -mt-10 z-20">
        <highlights-slider />
      </potager-container>

      <b2b-slider-how-it-works />
    </div>

    <b2b-price-detail />

    <b2b-slider-services />

    <div class="overflow-hidden">
      <b2b-slider-reviews />
      <b2b-faq />
    </div>

    <b2b-credit />
  </div>
</template>

<script>
import ToolsMixin from 'Mixins/ToolsMixin';

import B2bBanner from 'Pages/b2b/B2bBanner';
import B2bSliderHowItWorks from 'Pages/b2b/B2bSliderHowItWorks';
import B2bPriceDetail from 'Pages/b2b/B2bPriceDetail';
import B2bSliderServices from 'Pages/b2b/B2bSliderServices';
import B2bSliderReviews from 'Pages/b2b/B2bSliderReviews';
import B2bFaq from 'Pages/b2b/B2bFaq';
import B2bCredit from 'Pages/b2b/B2bCredit';

import HighlightsSlider from 'Components/highlightsSlider/HighlightsSlider';

import PotagerContainer from 'UI/PotagerContainer';

export default {

  mixins: [
    ToolsMixin,
  ],

  components: {
    PotagerContainer,
    B2bBanner,
    HighlightsSlider,
    B2bSliderHowItWorks,
    B2bPriceDetail,
    B2bSliderServices,
    B2bSliderReviews,
    B2bFaq,
    B2bCredit,
  },

  head() {
    return this.generateMetaIfPanelClosed({
      title: 'Livraison de bonne humeur et de corbeilles de fruits au bureau !',
      description: 'À vous les pauses responsables et qui changent de d\'habitude ! Avec les corbeilles de fruits Potager City livrées dans vos bureaux, offrez une petite attention du quotidien à vos collaborateurs. À croquer, à éplucher, mais surtout : à partager !',
    });
  },

};
</script>
