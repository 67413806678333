<template>
  <panel
    :back-route="{ name: 'mon-compte_email' }"
    title="Code">
    <div class="panel-section">
      <p class="panel-section__p">
        <b>Veuillez saisir le code de confirmation</b> que vous avez reçu par e-mail ci-dessous.
      </p>

      <div class="panel-section__line">
        <potager-form
          id="confirmation-code-form"
          @onSubmit="confirmConfirmationCode">
          <potager-input
            v-model="confirmationCode"
            :constraints="[
              { constraint: 'required', field: 'confirmationCode' },
            ]"
            label="Code"
            name="confirmationCode" />

          <potager-button
            :is-loading="isLoading"
            full-width
            type="submit">
            Continuer
          </potager-button>
        </potager-form>

        <potager-button
          :to="{ name: 'mon-compte_renvoyer' }"
          full-width
          size="small"
          theme="link">
          Vous n'avez rien reçu ?
        </potager-button>
      </div>
    </div>
  </panel>
</template>

<script>
import PotagerInput from 'UI/Form/PotagerInput';
import PotagerButton from 'UI/PotagerButton';
import PotagerForm from 'UI/Form/PotagerForm';

import Panel from 'Components/panel/Panel';

import MetaInfosService from 'Classes/services/MetaInfoService';
import { CONFIRM_ACTION, UPDATE_USER_ACTION } from 'Stores/types/userActionsTypes';
import { AFTER_CONFIRM_LOGGIN_ACTION } from 'Stores/types/temporaryCustomerActionsTypes';

export default {

  components: {
    PotagerInput,
    PotagerButton,
    PotagerForm,
    Panel,
  },

  data: () => ({
    confirmationCode: null,
  }),

  computed: {
    isLoading() {
      return this.$wait.is([
        CONFIRM_ACTION,
        UPDATE_USER_ACTION,
      ]);
    },
  },

  methods: {
    confirmConfirmationCode() {
      const { confirmationCode } = this;
      this.$store.dispatch(`user/${CONFIRM_ACTION}`, confirmationCode)
        .then((response) => {
          this.$store.dispatch(`temporaryCustomer/${AFTER_CONFIRM_LOGGIN_ACTION}`, response)
            .then(() => {
              this.$router.push({ name: 'mon-compte_info_perso' })
                .catch(() => {
                });
            });
        });
    },
  },

  head: MetaInfosService.generate({
    title: 'Code',
  }),
};
</script>
