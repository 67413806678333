<template>
  <template v-if="skeleton">
    <time-slot-card
      v-for="index in timeSlots"
      :key="`timeslot-skeleton-${index}`"
      :time-slot="index"
      skeleton />
  </template>

  <transition-group
    v-else
    name="slide-left-to-right-2rem">
    <time-slot-card
      v-for="(item, index) in timeSlots.slice(0, page * perPage)"
      :key="`timeslot-${index}`"
      :order="item.timeSlot ? item : null"
      :time-slot="item.timeSlot || item" />
  </transition-group>

  <div
    v-if="page * perPage < timeSlots.length && !skeleton"
    class="text-center">
    <potager-button
      class="mt-4"
      is-rounded
      theme="british-racing"
      @click="page++">
      Charger plus
    </potager-button>
  </div>
</template>

<script>
import PotagerButton from 'UI/PotagerButton';

import TimeSlotCard from 'Components/timeSlots/TimeSlotCard';

export default {
  name: 'TimeslotsList',

  components: {
    PotagerButton,
    TimeSlotCard
  },

  props: {
    // can pass a list of orders too
    timeSlots: {
      type: Array,
      required: true,
    },
    skeleton: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      page: 1,
      perPage: 8,
    };
  },
};
</script>
