<template>
  <div>
    <dlp-postal-code-select
      ref="input"
      :is-loading="isLoading"
      class="mb-6"
      @onInput="onInput"
      @onSubmit="onSubmit" />

    <potager-button
      :is-disabled="!postalCode"
      :is-loading="isLoading"
      class="potager-button--tunnel mx-auto block"
      full-width
      theme="go-green"
      @onClick="onSubmitClick">
      Continuer
    </potager-button>

    <transition name="fade">
      <p
        v-if="error"
        class="text-error mt-5">
        Désolé, vous n’êtes pas (encore !) éligible à la livraison à domicile.
      </p>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import { UPDATE_DELIVERY_POINT, UPDATE_POSTAL_CODE } from 'Stores/types/homeDeliveryMutationsTypes';

import { FETCH_DELIVERY_POINT } from 'Stores/types/mapActionsTypes';

import GtmMixin from 'Mixins/GtmMixin';

import DlpPostalCodeSelect from 'Components/deliveryPoint/DlpPostalCodeSelect';

import PotagerButton from 'UI/PotagerButton';

export default {
  components: {
    PotagerButton,
    DlpPostalCodeSelect,
  },

  data: () => ({
    postalCode: undefined,
    error: false,
  }),

  computed: {
    isLoading() {
      return this.$wait.is([FETCH_DELIVERY_POINT]);
    },
  },

  mixins: [
    GtmMixin,
  ],

  methods: {
    ...mapMutations('homeDelivery', [
      UPDATE_DELIVERY_POINT,
    ]),
    ...mapActions('map', {
      fetchDeliveryPoint: FETCH_DELIVERY_POINT,
    }),
    onSubmitClick() {
      this.$refs.input.submit();
    },
    onInput(value) {
      this.error = false;
      this.postalCode = value;
    },
    onSubmit(found) {
      const {
        postalCode,
        city
      } = found;
      this.trackMapSearch({
        postalCode: postalCode.toString(),
        city
      }, undefined, 'HomeDelivery');

      if (found) {
        this.$store.commit(`homeDelivery/${UPDATE_POSTAL_CODE}`, found);
        this.$emit('onSubmit', found);
      } else {
        this.error = true;
      }
    },
  },
};
</script>
