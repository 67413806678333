<template>
  <div
    v-if="sanitizeItems && sanitizeItems.length"
    class="blog__section">

    <h2 class="blog__subtitle">
      D'autres idées gourmandes à explorer
    </h2>

    <potager-row class="content-list__content">
      <potager-col
        v-for="(item, i) in sanitizeItems"
        class="content-list-page__item bp640:mb-0"
        :key="i"
        col="4"
        bp1024="6"
        bp640="12">
        <card-content
          :content="item"
          class="bp640:pb-4 bp640:mb-4" />
      </potager-col>
    </potager-row>

    <p class="cross-selling__see-more">
      <potager-button
        :to="{ name: 'recettes-list'}"
        :full-width="$mq.bp1024"
        theme="stroke"
        label="Voir toutes nos recettes" />
    </p>

  </div>
</template>

<script>
import PotagerRow from 'UI/PotagerRow';
import PotagerCol from 'UI/PotagerCol';
import PotagerButton from 'UI/PotagerButton';
import CardContent from 'Components/cards/CardContent';

export default {

  components: {
    PotagerButton,
    PotagerRow,
    PotagerCol,
    CardContent,
  },

  data: () => ({
    max: 6,
    items: [],
  }),

  props: {
    recipe: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ingredients() {
      return this.recipe ? this.recipe.ingredients_v2 : null;
    },
    mainIngredient() {
      return this.ingredients?.filter((ingredient) => ingredient.supplierProductId)?.[0]?.product;
    },
    otherIngredients() {
      return this.ingredients?.filter((ingredient) => !ingredient.supplierProductId);
    },

    /**
   * Filters and sanitizes the items based on several conditions:
   * - Items containing the main ingredient.
   * - Items containing other ingredients.
   * - Sorts the items based on the quantity of the main ingredient.
   */
    sanitizeItems() {
      // Filter items with the main ingredient
      const recipesWithMainIngredient = this.items
        .filter((item) => item.ingredients_v2
          .some((ingredient) => ingredient.product === this.mainIngredient));

      // Filter items with other ingredients
      const recipesWithOtherIngredients = this.items
        .filter((item) => item.ingredients_v2
          .some((ingredient) => this.otherIngredients
            .some((otherIngredient) => ingredient.product === otherIngredient.product)));

      return [...recipesWithMainIngredient, ...recipesWithOtherIngredients]
      // Remove current recipe
        .filter((item) => item.id !== this.recipe.id)
      // Remove duplicates
        .filter((item, index, self) => self.findIndex((t) => t.id === item.id) === index)
      // Sort items by main ingredient quantity
        .sort((a, b) => {
          const aMain = a.ingredients_v2.find((ingredient) => ingredient.product === this.mainIngredient);
          const bMain = b.ingredients_v2.find((ingredient) => ingredient.product === this.mainIngredient);
          return aMain && bMain ? aMain.quantity - bMain.quantity : 0;
        })
        .slice(0, this.max);
    },
  },

  watch: {
    mainIngredient: {
      handler() {
        this.fetchRecipes();
      },
      immediate: true,
    },
  },

  methods: {
    fetchRecipes() {
      const research = this.mainIngredient || this.recipe.title;
      const page = 1;
      const maxPerPage = this.max * 4;

      this.$api.recipe.findAll({ research, page, maxPerPage })
        .then(({ data }) => {
          if (data.data) {
            this.items = data.data.results;
          }
        })
        .catch(() => {});
    },
  },

};
</script>
