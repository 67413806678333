<template>
  <component
    :is="wrapperTag"
    :class="wrapperClasses"
    :theme="theme"
    :to="redirect"
    class="select-none whitespace-nowrap"
    reset
    @click="onClick"
    @onClick="onClick">
    <template v-if="$slots.icon">
      <span class="potager-badge__icon">
        <slot name="icon" />
      </span>
    </template>

    <slot />

    <icon-ptcy-loader-dots
      v-if="isLoading"
      class="potager-badge__loader" />
  </component>
</template>

<script>
import { DEFAULT_THEMES } from 'Classes/Constants';

import IconPtcyLoaderDots from 'Icons/regular/IconPtcyLoaderDots';

import PotagerButton from 'UI/PotagerButton';

export const props = {
  theme: {
    type: String,
    required: false,
    default: 'default',
    values: [
      ...DEFAULT_THEMES,
      'default',
    ],
  },
  size: {
    type: String,
    required: false,
    default: 'small',
    values: ['mini', 'small', 'medium', 'large', 'auto'],
  },
  tag: {
    type: String,
    required: false,
    default: undefined,
  },
  redirect: {
    type: Object,
    required: false,
    default: null,
  },
  standalone: {
    type: Boolean,
    required: false,
    default: false,
  },
  isLoading: {
    type: Boolean,
    required: false,
    default: false,
  },
  rounded: {
    type: Boolean,
    required: false,
    default: false,
  },
  clickable: {
    type: Boolean,
    required: false,
    default: false,
  },
};

export default {
  components: {
    IconPtcyLoaderDots,
    PotagerButton,
  },
  props,
  computed: {
    wrapperClasses() {
      const defaultSlotChildren = this.$slots.default()[0].children;
      const textLength = defaultSlotChildren && typeof defaultSlotChildren === 'string'
        ? defaultSlotChildren.length
        : undefined;

      return [
        'potager-badge',
        `potager-badge--${this.theme}`,
        `potager-badge--${this.size}`,
        {
          'potager-badge--rounded': this.rounded,
          'potager-badge--circle': this.rounded && textLength === 1,
          'potager-badge--standalone': this.standalone,
          'potager-badge--is-loading': this.isLoading,
        },
      ];
    },
    wrapperTag() {
      return this.redirect
        ? 'potager-link'
        : (this.tag || (this.clickable
          ? PotagerButton
          : 'span'));
    },
  },
  methods: {
    onClick() {
      if (this.clickable) this.$emit('onClick');
    },
  },
};
</script>

<style lang="scss" scoped>
.potager-badge {
  $badge: &;
  @apply inline-flex items-center justify-center bg-white-smoke text-charcoal rounded;
  @apply font-bold text-center;
  gap: .25em;
  padding: 0 .5em;
  line-height: 2 !important;

  @include disableTap();

  &--rounded {
    @apply rounded-full;
    gap: .5em;
    padding: 0 .75em;
  }

  &--circle {
    width: 2em;
  }

  &--mini {
    @apply text-3xs;
  }

  &--small {
    @apply text-xxs.5;
  }

  &--medium {
    @apply text-xs;
  }

  &--large {
    @apply text-sm;
  }

  &--auto {
    padding: .28em .5em;
  }

  &__icon {
    font-size: 1em;
    line-height: 0;
    display: block;
    margin-left: .5em;
  }

  &__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.5em;
  }

  &--is-loading {
    &,
    #{$badge}__icon {
      color: transparent !important;
    }
  }

  &--white {
    @apply bg-white text-charcoal;
  }

  &--white-rock {
    @apply bg-white-rock text-charcoal;
  }

  &--wild-willow {
    @apply bg-wild-willow text-white;
  }

  &--forest {
    @apply bg-forest-green text-white;
  }

  &--go-green {
    @apply bg-go-green text-white;
  }

  &--charcoal {
    @apply bg-charcoal text-white;
  }

  &--success {
    @apply bg-success text-white;
  }

  &--beryl,
  &--beryl-green {
    @apply bg-beryl-green text-british-racing;
  }

  &--beryl-light {
    @apply bg-white text-beryl-green;
  }

  &--sandy-beach {
    @apply bg-sandy-beach text-mango-tango;
  }

  &--rossabel {
    @apply bg-rossabel text-cinnabar;
  }

  &--british-racing {
    @apply bg-british-racing text-white;
  }

  &--bianca {
    @apply bg-bianca text-charcoal;
  }

  &--cosmic-latte {
    @apply bg-cosmic-latte text-charcoal;
  }

  &--byzantine {
    @apply bg-byzantine text-white;
  }

  &--black {
    @apply bg-black text-white;
  }

  &--golden-dream {
    @apply bg-golden-dream text-charcoal;
  }

  &--monza {
    @apply bg-monza text-white;
  }

  &--sushi {
    @apply bg-sushi text-white;
  }

  &--lavender-indigo {
    @apply bg-lavender-indigo text-white;
  }

  &--zest {
    @apply bg-zest text-white;
  }

  &--mango-tango {
    @apply bg-mango-tango text-white;
  }

  &--yellow-crayola {
    @apply bg-yellow-crayola text-charcoal;
  }

  &--light-red {
    @apply bg-light-red text-maximum-red;
  }

  &--carnation {
    @apply bg-carnation text-white;
  }

  &--sunglow {
    @apply bg-sunglow text-charcoal;
  }

  &--stroke,
  &--stroke-monza,
  &--stroke-grey,
  &--stroke-white {
    @apply rounded-full;
  }

  &--stroke {
    @apply bg-transparent text-charcoal border border-charcoal;
  }

  &--stroke-monza {
    @apply bg-transparent text-monza border border-monza;
  }

  &--stroke-grey {
    @apply bg-transparent text-warm-grey border border-warm-grey;
  }

  &--stroke-white {
    @apply bg-transparent text-white border border-white;
  }

  &--lila {
    @apply bg-lavender text-lavender-indigo;
  }
}
</style>
