import AccountDlpSearchPage from 'Pages/account/dlp/step2/AccountDlpSearchPage';
import AccountDlpSearchHomePage from 'Pages/account/dlp/step2/AccountDlpSearchHomePage';
import AccountDlpFormHomePage from 'Pages/account/dlp/step3/AccountDlpFormHomePage';
import AccountDlpListPrivatePage from 'Pages/account/dlp/step3/AccountDlpListPrivatePage';
import AccountDlpMapLayout from 'Pages/account/dlp/AccountDlpMapLayout';
import DlpMapList from 'Components/deliveryPoint/step3/DlpMapList';
import DlpMapDetails from 'Components/deliveryPoint/step4/DlpMapDetails';
import AccountDlpUpdate from 'Pages/account/dlp/step5/AccountDlpUpdate';

import {
  DELIVERY_FUNCTIONAL_TYPE_HOME,
  DELIVERY_FUNCTIONAL_TYPE_PUBLIC,
  DELIVERY_FUNCTIONAL_TYPE_PRIVATE,
  DELIVERY_FUNCTIONAL_TYPE_HOME_PATH,
  DELIVERY_FUNCTIONAL_TYPE_PUBLIC_PATH,
  DELIVERY_FUNCTIONAL_TYPE_PRIVATE_PATH,
} from 'Classes/Constants';

import webviewGuard from 'Router/guards/webviewGuard';
import { Context } from 'potagerlogic/dist/Enums/Context';

const meta = {
  isPanel: true,
  requiresAuth: true,
  webview: true,
};

const contextMapping = [
  {
    name: Context.User,
    key: Context.User,
    path: 'mon-compte',
    meta: { requiresAuth: false }
  },
  {
    name: Context.UserRegistration,
    key: Context.UserRegistration,
    path: 'inscription',
    meta: { requiresAuth: false }
  },
  {
    name: Context.Basket,
    key: Context.Basket,
    path: 'mon-panier',
    meta: { requiresAuth: false }
  },
  {
    name: Context.SubscriptionBasket,
    key: Context.SubscriptionBasket,
    path: 'mon-abonnement',
    meta: {
      isPanel: false,
      requiresAuth: false
    }
  },
  {
    name: Context.Order,
    key: Context.Order,
    path: 'mon-compte/mes-commandes/en-cours/:orderId'
  },
  {
    name: Context.Subscription,
    key: Context.Subscription,
    path: 'mon-compte/mon-abonnement'
  },
  {
    name: 'webview-basket',
    // no key needed for webview because we redirect before gdd submission
    // see onDlpSelect in AccountDlpMapLayout
    key: 'webview-basket',
    path: 'webview',
    beforeEnter: webviewGuard,
    meta: {
      isPanel: false,
      header: false,
      footer: false,
    }
  },
  // uncomment if needed
  // {
  //   name: 'webview-order',
  //   key: Context.Order,
  //   path: 'webview/commande/:orderId',
  //   beforeEnter: webviewGuard,
  //   meta: {
  //     isPanel: false,
  //     header: false,
  //     footer: false,
  //   }
  // }
];

const deliveryPointsRoutes = contextMapping
  .map((context) => [
    {
      name: `account-dlp-search-step1-${context.name}`,
      path: `/${context.path}/livraison/recherche/:type?`,
      component: AccountDlpSearchPage,
      props: {
        context: context.key,
      },
      beforeEnter: context.beforeEnter,
      meta: {
        ...meta,
        ...context.meta,
      },
    },
    {
      name: `account-dlp-search-step1-${context.name}-${DELIVERY_FUNCTIONAL_TYPE_HOME}`,
      path: `/${context.path}/livraison/${DELIVERY_FUNCTIONAL_TYPE_HOME_PATH}/:regionId?`,
      component: AccountDlpSearchHomePage,
      props: {
        context: context.key,
      },
      beforeEnter: context.beforeEnter,
      meta: {
        ...meta,
        ...context.meta,
      },
    },
    // Delivery point Step 3 home
    {
      name: `account-dlp-list-${DELIVERY_FUNCTIONAL_TYPE_HOME}-step3-${context.name}`,
      path: `/${context.path}/livraison/${DELIVERY_FUNCTIONAL_TYPE_HOME_PATH}/formulaire`,
      component: AccountDlpFormHomePage,
      props: {
        context: context.key,
      },
      beforeEnter: context.beforeEnter,
      meta: {
        ...meta,
        ...context.meta,
      },
    },
    // Delivery point Step 3 private
    {
      name: `account-dlp-list-${DELIVERY_FUNCTIONAL_TYPE_PRIVATE}-step3-${context.name}`,
      path: `/${context.path}/livraison/${DELIVERY_FUNCTIONAL_TYPE_PRIVATE_PATH}/:search?`,
      component: AccountDlpListPrivatePage,
      props: {
        context: context.key,
      },
      beforeEnter: context.beforeEnter,
      meta: {
        ...meta,
        ...context.meta,
      },
    },
    {
      name: `account-dlp-map-${context.name}`,
      path: `/${context.path}/livraison/`,
      component: AccountDlpMapLayout,
      props: {
        context: context.key,
      },
      children: [
        // Delivery point Step 3 public
        {
          name: `account-dlp-list-${DELIVERY_FUNCTIONAL_TYPE_PUBLIC}-step3-${context.name}`,
          path: `${DELIVERY_FUNCTIONAL_TYPE_PUBLIC_PATH}/:latlng?`,
          component: DlpMapList,
          props: {
            context: context.key,
          },
          beforeEnter: context.beforeEnter,
          meta: {
            ...meta,
            ...context.meta,
          },
        },
        // Delivery point Step 4 private
        {
          name: `account-dlp-details-${DELIVERY_FUNCTIONAL_TYPE_PRIVATE}-step4-${context.name}`,
          path: `${DELIVERY_FUNCTIONAL_TYPE_PRIVATE_PATH}/confirmer/:deliveryPointRegionId/:deliveryPointId`,
          component: DlpMapDetails,
          props: {
            context: context.key,
          },
          beforeEnter: context.beforeEnter,
          meta: {
            ...meta,
            ...context.meta,
          },
        },
        // Delivery point Step 4 public
        {
          name: `account-dlp-details-${DELIVERY_FUNCTIONAL_TYPE_PUBLIC}-step4-${context.name}`,
          path: `${DELIVERY_FUNCTIONAL_TYPE_PUBLIC_PATH}/confirmer/:deliveryPointRegionId/:deliveryPointId`,
          component: DlpMapDetails,
          props: {
            context: context.key,
          },
          beforeEnter: context.beforeEnter,
          meta: {
            ...meta,
            ...context.meta,
          },
        },
        // Delivery point Step 4 home
        {
          name: `account-dlp-details-${DELIVERY_FUNCTIONAL_TYPE_HOME}-step4-${context.name}`,
          path: `${DELIVERY_FUNCTIONAL_TYPE_HOME_PATH}/confirmer/:deliveryPointRegionId/:deliveryPointId`,
          component: DlpMapDetails,
          props: {
            context: context.key,
          },
          beforeEnter: context.beforeEnter,
          meta: {
            ...meta,
            ...context.meta,
          },
        },
      ],
    },
    // Delivery point Step 5
    {
      name: `account-dlp-update-step5-${context.name}`,
      path: `/${context.path}/livraison/changement/:dlpId/:gddId`,
      component: AccountDlpUpdate,
      props: {
        context: context.key,
      },
      beforeEnter: context.beforeEnter,
      meta: {
        ...meta,
        ...context.meta,
      },
    },
  ])
  .flat();

export default [

  /*
     * Points de retrait
     */
  ...deliveryPointsRoutes,
];
