import { UPDATE_BASKET_ACTION } from 'Stores/types/basketActionsTypes';
import { FETCH_DASHBOARD_ACTION } from 'Stores/types/dashboardActionTypes';

import { POPIN_ERROR_EVENT, BASKET_TYPE } from 'Classes/Constants';

import store from 'Stores';

export const BT017_DEFAULT_ERROR = {
  eventType: POPIN_ERROR_EVENT,
  text: 'Ce produit est victime de son succès et n\'est plus disponible. Vous ne pouvez plus l\'ajouter à votre commande.',
  handle(app, response) {
    const data = response.data?.data || response.data;
    const basket = data?.basket;
    store.dispatch(`basket/${UPDATE_BASKET_ACTION}`, basket);
  },
};

export const BT017_ADD_BOX_ERROR = {
  eventType: POPIN_ERROR_EVENT,
  title: 'Victime de son succès',
  text: 'Oh nooooon… Le dernier élément vient d\'être vendu et n’est désormais plus disponible. Nous vous suggérons de choisir autre chose sur la boutique, il y a forcément quelque chose qui vous plaira 🙂',
  handle(app, response) {
    const data = response.data?.data || response.data;
    const basket = data?.basket;
    store.dispatch(`basket/${UPDATE_BASKET_ACTION}`, basket);
    store.dispatch(`dashboard/${FETCH_DASHBOARD_ACTION}`, { basketType: BASKET_TYPE });
  },
};

export const BT017_UPDATE_DELIVERY_POINT_ERROR = {
  eventType: POPIN_ERROR_EVENT,
  text: 'Oops ! Un produit présent dans votre panier est victime de son succès  et n\'est plus disponible pour le jour de livraison choisi. Si vous décidez appliquez ce changement, nous devront le retirer de votre panier.',
  handle(app, response) {
    const data = response.data?.data || response.data;
    const basket = data?.basket;
    store.dispatch(`basket/${UPDATE_BASKET_ACTION}`, basket);
  },
};

export default {
  default: BT017_DEFAULT_ERROR,
  'TemporaryCustomerApi.addBox': BT017_ADD_BOX_ERROR,
  'UserApi.addBox': BT017_ADD_BOX_ERROR,
  'TemporaryCustomerApi.setBasketDeliveryPoint': BT017_UPDATE_DELIVERY_POINT_ERROR,
  'UserApi.setBasketDeliveryPoint': BT017_UPDATE_DELIVERY_POINT_ERROR,
};
