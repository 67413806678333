<template>

  <div
    :class="extraclass"
    :style="extrastyle">

    <svg
      class="potager-wave__svg"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="100%"
      viewBox="0 0 500 500"
      preserveAspectRatio="none">

      <path
        :fill="fill"
        :d="path" />

    </svg>
  </div>

</template>

<script>
import PATHS from 'UI/PotagerWavePaths';

import { propsBuilder } from 'UI/Tools';

export const props = {
  index: {
    type: [Array, Number, String],
    required: true,
  },
  height: {
    type: [Array, String],
    required: true,
  },
  width: {
    type: [Array, String],
    required: false,
    default: null,
  },
  fill: {
    type: String,
    required: false,
    default: '#fff',
  },
  background: {
    type: String,
    required: false,
    default: '',
  },
  modifiers: {
    type: [Array, String],
    required: false,
    default: '',
    values: [
      'invert-x',
      'invert-y',
      'invert-xy',
      'on-top',
      'on-bottom',
      'on-left',
      'on-right',
      'outside',
    ],
  },
};

export default {

  props: propsBuilder(props),

  computed: {
    path() {
      const { index } = this;
      if (typeof index === 'number') return PATHS[index];

      if (this.$mq.bp768 && index[2]) return PATHS[index[2]];
      if (this.$mq.bp1024 && index[1]) return PATHS[index[1]];
      return PATHS[index[0]];
    },
    extraclass() {
      const prefix = 'potager-wave';
      const list = [prefix];
      if (this.modifiers) {
        const modifiers = typeof this.modifiers === 'string' ? this.modifiers.split(',') : this.modifiers;
        modifiers.forEach((m) => {
          list.push(`${prefix}--${m}`);
        });
      }
      return list;
    },
    extrastyle() {
      const styles = {};
      const { height, background, width } = this;

      if (typeof height === 'string') {
        styles.height = height;
      } else if (this.$mq.bp768 && height[2]) {
        styles.height = height[2];
      } else if (this.$mq.bp1024 && height[1]) {
        styles.height = height[1];
      } else {
        styles.height = height[0];
      }

      if (width) {
        if (typeof width === 'string') {
          styles.width = width;
        } else if (this.$mq.bp768 && width[2]) {
          styles.width = width[2];
        } else if (this.$mq.bp1024 && width[1]) {
          styles.width = width[1];
        } else {
          styles.width = width[0];
        }
      }

      if (background) {
        styles.backgroundColor = background;
      }

      return styles;
    },
  },

};
</script>
