<template>
  <ul class="card-box-tags">
    <potager-badge
      v-for="(tag, index) in tags"
      :key="index"
      :theme="tag.color"
      class="card-box-tags__item"
      size="small"
      tag="li">
      <template
        v-if="tag.icon"
        #icon>
        <component :is="tag.icon" />
      </template>

      {{ tag.label }}
    </potager-badge>
  </ul>
</template>

<script>
import IconPtcyBio from 'Icons/regular/IconPtcyBio';

import PotagerBadge from 'UI/PotagerBadge';

export default {

  components: {
    PotagerBadge,
    IconPtcyBio,
  },

  props: {
    tags: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.card-box-tags {
  @apply flex flex-wrap-reverse p-1 gap-1;
  @apply absolute top-0 left-0 z-20;
}
</style>

<style lang="scss">
.card-box-tags {
  .card-v2--small & {
    @apply text-3xs;
  }
}
</style>
