<template>
  <potager-modal>
    <template #body>
      {{ reasonMessage }} En conséquence, votre panier sera vidé.
      <br><br>
      <b>Êtes-vous sûr de vouloir continuer ?</b>
    </template>

    <template #footer>
      <potager-button
        :is-loading="isLoading"
        full-width
        label="Annuler"
        theme="stroke"
        @onClick="cancel" />

      <potager-button
        :is-loading="isLoading"
        full-width
        label="Ok, j'ai compris !"
        @onClick="deleteBasket" />
    </template>
  </potager-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { UPDATE_DELIVERY_POINT_BASKET_ACTION, EMPTY_BASKET_ACTION } from 'Stores/types/basketActionsTypes';

import PotagerModal from 'UI/PotagerModal';
import PotagerButton from 'UI/PotagerButton';

export default {

  components: {
    PotagerModal,
    PotagerButton,
  },

  props: {
    newRegionId: {
      type: Number,
      required: true,
    },
    groupDeliveryDayId: {
      type: Number,
      required: true,
    },
    action: {
      type: Function,
      required: true,
    },
    cancelHandler: {
      type: Function,
      required: false,
      default: () => {},
    },
  },

  computed: {
    ...mapGetters('session', [
      'isLoggedIn',
      'getRegionId',
    ]),
    isLoading() {
      return this.$wait.is([EMPTY_BASKET_ACTION, UPDATE_DELIVERY_POINT_BASKET_ACTION]);
    },
    reasonMessage() {
      if (this.newRegionId !== this.getRegionId) {
        return 'En sélectionnant ce point de retrait, vous changez de région et donc d\'offre.';
      }

      return 'En sélectionnant ce point de retrait, vous ne pourrez pas commander sur l\'offre de cette semaine.';
    },
  },

  methods: {
    cancel() {
      if (this.cancelHandler() instanceof Promise) {
        this.cancelHandler()
          .then(() => {
            this.$modal.close();
          });
      } else {
        this.cancelHandler();
        this.$modal.close();
      }
    },
    deleteBasket() {
      // check if deleteHandle is a promise
      if (this.action() instanceof Promise) {
        this.action()
          .then(() => {
            this.$modal.close();
          });
      } else {
        this.action();
        this.$modal.close();
      }
    },
  },
};
</script>
