<template>
  <section class="customer-reviews-slider">
    <potager-background
        class="customer-reviews-slider__greviews"
        src="logo-google-reviews"/>

    <div class="customer-reviews-slider__container">
      <potager-container>
        <h2 class="customer-reviews-slider__title">
          <strong>Des clients soignés</strong> aux petits oignons
        </h2>

        <potager-slider
            :items="USERS_REVIEWS"
            :settings="{
              autoplay: { delay: 4000 },
              centeredSlides: $mq.bp1024,
            }"
            :slides-per-group="1"
            :slides-per-view="{
              default: 1.2,
              768: 2,
              1024: 4,
            }"
            name="customer-reviews-slider"
            navigation
            overflow-visible
            pagination>
          <template v-slot:default="vProps">
            <card-review :review="vProps.item"/>
          </template>
        </potager-slider>
      </potager-container>
    </div>

    <potager-button
        :to="{ name: variante === 1 ? 'dashboard' : 'tunnel' }"
        class="customer-reviews-slider__cta"
        theme="go-green">
      Commander
    </potager-button>
  </section>
</template>

<script>
import PotagerContainer from 'UI/PotagerContainer';
import PotagerButton from 'UI/PotagerButton';
import PotagerBackground from 'UI/PotagerBackground';
import PotagerSlider from 'UI/PotagerSlider';

import CardReview from 'Components/cards/CardReview';

import ABTestMixin from 'Mixins/ABTestMixin';

import { USERS_REVIEWS } from 'Classes/Contents';

import { PTCY_3863 } from 'Stores/ABTestStore';

export default {

  components: {
    PotagerContainer,
    PotagerButton,
    PotagerBackground,
    PotagerSlider,
    CardReview,
  },

  mixins: [ABTestMixin],

  data: () => ({
    USERS_REVIEWS,
  }),
  computed: {
    variante() {
      return this.getABTestVariante(PTCY_3863);
    },
  },

};
</script>

<style lang="scss">
.customer-reviews-slider {
  background: $bianca;
  padding-top: 3rem;
  text-align: center;
  position: relative;

  @include bp640() {
    padding-top: 2rem;
  }

  &__container {
    overflow: hidden;
    padding-bottom: .5rem;
  }

  &__greviews {
    position: absolute !important;
    top: -3rem;
    right: 8.33%;
    height: 4.4375rem;
    width: 13.25rem;

    @include bp640() {
      transform: scale(.7);
      transform-origin: right;
    }
  }

  &__cta {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
  }

}
</style>
