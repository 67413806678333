<template>
  <potager-modal
    is-small
    without-footer
    without-header>
    <template #body>
      <potager-form
        id="edit-product-quantity"
        @onSubmit="submit">
        <potager-input
          v-model="value"
          :constraints="[
            { constraint: 'number', field: 'quantity', options: { min, max } },
          ]"
          :type="$mq.bp768 ? 'tel' : 'number'"
          external-label
          label="Quantité souhaitée"
          name="quantity" />

        <potager-button
          full-width
          theme="go-green"
          type="submit">
          Valider
        </potager-button>
      </potager-form>

      <potager-info
        v-if="value > max"
        size="small"
        status="warning">
        <template #icon>
          <icon-road-sign-warning />
        </template>
        Vous ne pouvez pas modifier la quantité au-delà de {{ max }}.
        Pour toute demande spécifique, veuillez contacter
        <potager-link
          :to="{ name: 'contact' }"
          target="_blank">
          notre service client.
        </potager-link>
      </potager-info>
    </template>
  </potager-modal>
</template>

<script>
import { mapGetters } from 'vuex';

import IconRoadSignWarning from 'Icons/regular/IconRoadSignWarning';

import PotagerModal from 'UI/PotagerModal';
import PotagerInput from 'UI/Form/PotagerInput';
import PotagerInfo from 'UI/PotagerInfo';
import PotagerForm from 'UI/Form/PotagerForm';
import PotagerButton from 'UI/PotagerButton';

export default {

  components: {
    IconRoadSignWarning,
    PotagerModal,
    PotagerInput,
    PotagerInfo,
    PotagerForm,
    PotagerButton,
  },

  data: () => ({
    value: null,
    min: 0,
  }),

  props: {
    initialQuantity: {
      type: Number,
      required: false,
      default: 0,
    },
    onSubmit: {
      type: Function,
      required: true,
    },
  },

  computed: {
    ...mapGetters('user', [
      'getBasketLimit',
    ]),
    max() {
      return this.getBasketLimit;
    },
  },

  methods: {
    submit() {
      this.$modal.close();
      if (typeof this.onSubmit === 'function') this.onSubmit(this.value);
    },
  },

  mounted() {
    if (this.initialQuantity) this.value = this.initialQuantity;
  },

};
</script>
