<template>
  <panel
    :back-route="backRoute"
    title="Suppression du compte">
    <div class="panel-section">
      <p class="panel-section__p">
        La suppression du compte entraîne l’effacement des données personnelles, met fin à un abonnement actif et annule
        automatiquement une commande en cours.
      </p>

      <p class="panel-section__p text-danger">
        <b>
          Attention, il ne sera plus possible de réactiver votre compte ensuite !
        </b>
      </p>

      <p class="panel-section__p">
        Pour en savoir plus, consultez
        <potager-link
          :to="{ name: 'confidentialite-et-donnees-personnelles' }"
          class="underline italic"
          target="_blank">
          notre politique concernant les données personnelles.
        </potager-link>
      </p>

      <div class="panel-section__line">
        <potager-button
          full-width
          label="Supprimer mon compte"
          theme="light-red"
          @onClick="deleteAccount" />
      </div>
    </div>
  </panel>
</template>

<script>
import Panel from 'Components/panel/Panel';
import PotagerButton from 'UI/PotagerButton';

import MetaInfosService from 'Classes/services/MetaInfoService';

import ModalDeleteAccount from 'Modals/ModalDeleteAccount';

export default {

  components: {
    Panel,
    PotagerButton,
  },

  data() {
    return {
      backRoute: { name: 'mon-compte_info_perso' },
    };
  },

  head: MetaInfosService.generate({
    title: 'Supression de compte',
  }),

  methods: {
    deleteAccount() {
      this.$modal.open(ModalDeleteAccount);
    },
  },
};
</script>
