import { getUrlParameter } from 'Classes/utils/RouteUtils';

export default class ConfigurationManager {
  static getByName(name) {
    switch (name) {
      case 'isMobile':
        return navigator.userAgent.match(/(iPhone)|(iPod)|(android)|(webOS)/i);
      case 'isMobileTablet':
        return navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i);
      case 'isIE':
        return (navigator.userAgent.match(/.net/i));
      case 'retina':
        return (window.devicePixelRatio > 1 || (window.matchMedia && window.matchMedia('(-webkit-min-device-pixel-ratio: 1.5),(-moz-min-device-pixel-ratio: 1.5),(min-device-pixel-ratio: 1.5)').matches));
      case 'isApple':
        return navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)/i);
      case 'isAndroid':
        return navigator.userAgent.match(/(android)/i);
      default:
        return null;
    }
  }
}

/**
 * Sets the in-app webview and tracking status based on the current URL and environment.
 */
export const setInAppWebview = () => {
  const isWebviewRoute = document.location.pathname.includes('webview');

  if (window.ReactNativeWebView || getUrlParameter('utm_source') === 'app' || isWebviewRoute || import.meta.env.VITE_SIMULATE_WEBVIEW === 'true') {
    localStorage.setItem('isInWebview', true);
  } else {
    localStorage.removeItem('isInWebview');
  }

  if (getUrlParameter('tracking') === 'true') {
    localStorage.setItem('tracking', true);
  } else {
    localStorage.removeItem('tracking');
  }
};

export const inAppWebView = () => {
  setInAppWebview();
  return !!localStorage.getItem('isInWebview') || false;
};

export const isProcessEnvProd = import.meta.env.VITE_WEB_ENV === 'production';
export const isApiProd = window.localStorage.getItem('api-switcher') === 'https://api.potagercity.fr';
export const isProd = isProcessEnvProd || isApiProd;

export const oneTrustTrack = () => {
  setInAppWebview();
  return !!localStorage.getItem('tracking') || false;
};
