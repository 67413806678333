<template>
  <transition
    :name="transition"
    @afterEnter="afterEnter">
    <div
      v-if="isOpen"
      class="flying-page__wrapper">
      <router-view :route="$potagerRoute" />
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';
import { FLYINGPAGE_TRANSITION_DEFAULT, FLYINGPAGE_TRANSITION_FORCE_WIDTH } from 'Classes/Constants';

export default {

  data: () => ({
    transition: FLYINGPAGE_TRANSITION_DEFAULT,
  }),

  computed: {
    ...mapGetters('user', [
      'getOrderById',
    ]),
    isOpen() {
      return this.$potagerRoute && this.$potagerRoute.meta.isFlying;
    },
    orderId() {
      return this.$potagerRoute.params.orderId;
    },
    isReplaceProduct() {
      return this.$potagerRoute.name === 'replace-detail-product-item';
    },
    closeRoute() {
      if (this.orderId) {
        return {
          name: 'mon-compte_orders_detail',
          params: { orderId: this.orderId },
        };
      }
      if (this.isReplaceProduct) {
        return {
          name: 'mon-compte_subscription_replace',
        };
      }
      return {
        path: this.$route.path,
      };
    },
  },

  methods: {
    close() {
      if (this.isOpen) {
        this.transition = FLYINGPAGE_TRANSITION_FORCE_WIDTH;
        this.$nextTick(() => {
          this.$router.push(this.closeRoute)
            .catch(() => {
            });
        });
      }
    },
    back() {
      this.$router.go(-1);
    },
    updateTransition(e) {
      this.transition = e;
    },
    afterEnter() {
      this.transition = FLYINGPAGE_TRANSITION_DEFAULT;
      this.$events.emit('flyingPage:isOpened');
    },
  },

  mounted() {
    this.$events.on('flyingPage:close', this.close);
    this.$events.on('flyingPage:back', this.back);
    this.$events.on('flyingPage:updateTransition', this.updateTransition);
  },

  beforeUnmount() {
    this.$events.off('flyingPage:close', this.close);
    this.$events.off('flyingPage:back', this.back);
    this.$events.off('flyingPage:updateTransition', this.updateTransition);
  },

};
</script>
