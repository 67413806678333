<template>
  <panel
    :back-route="backRoute"
    title="Modifier mon e-mail">
    <div class="panel-section">
      <p class="panel-section__p">
        <b>Veuillez saisir votre nouvel e-mail ci-dessous.</b>
      </p>

      <p class="panel-section__p">
        Nous vous recommandons d'utiliser votre adresse e-mail personnelle plutôt que celle fournie par votre
        entreprise, qui pourrait bloquer certains de nos e-mails.
      </p>

      <potager-form
        id="email-form"
        class="panel-section__line"
        @onSubmit="updateEmail">
        <potager-input
          v-model="email"
          :constraints="[
            { constraint: 'email', field: 'email' },
          ]"
          constraints-validation="onInput"
          label="E-mail"
          name="email"
          type="email" />

        <potager-button
          :disabled="!hasModifications"
          :is-loading="$wait.is('UPDATE_EMAIL_ACTION')"
          full-width
          label="Valider"
          type="submit" />
      </potager-form>
    </div>
  </panel>
</template>

<script>
import Panel from 'Components/panel/Panel';
import PotagerButton from 'UI/PotagerButton';
import PotagerInput from 'UI/Form/PotagerInput';
import PotagerForm from 'UI/Form/PotagerForm';

import { mapGetters } from 'vuex';
import { UPDATE_EMAIL_ACTION } from 'Stores/types/userActionsTypes';

import MetaInfosService from 'Classes/services/MetaInfoService';

export default {
  components: {
    PotagerForm,
    Panel,
    PotagerButton,
    PotagerInput,
  },

  data() {
    return {
      backRoute: { name: 'mon-compte_info_perso' },
      email: '',
    };
  },

  computed: {
    ...mapGetters('user', [
      'getEmail',
    ]),
    hasModifications() {
      return !!(this.email && this.email !== this.getEmail);
    },
  },

  methods: {
    updateEmail() {
      this.$store.dispatch(`user/${UPDATE_EMAIL_ACTION}`, { email: this.email })
        .then(() => {
          this.$router.push({ name: 'mon-compte_confirm-email' });
        });
    },
  },

  mounted() {
    this.email = this.getEmail || '';
  },

  head: MetaInfosService.generate({
    title: 'E-mail',
  }),
};
</script>
