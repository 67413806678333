<template>
  <div
    :class="[
      'card-content',
      'card-content--loading',
      { 'card-content--inline': inline }
    ]">
    <span class="card-content__content">

      <potager-picture
        class="card-content__pic skeleton"
        background />

      <div class="card-content__text">
        <header class="card-content__header">
          <div class="w-full h-6 skeleton" />
        </header>
      </div>
    </span>
  </div>
</template>

<script>
import PotagerPicture from 'UI/PotagerPicture';

export default {

  components: {
    PotagerPicture,
  },

  props: {
    inline: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

};
</script>
