<template>
  <div class="bg-beryl-green py-28 bp1024:py-24 bp768:py-16 mb-32 bp768:mb-0">
    <potager-container>
      <potager-row
        centered
        align="center">
        <potager-col :col="$mq.bp768 ? 12 : ($mq.bp1024 ? 10 : 4.5)">
          <h2 class="text-4xl font-black bp1024:text-3xl.5 bp1024:leading-tight pb-10 bp1024:pb-8">
            Vitaminez la qualité de vie au travail
          </h2>
          <p class="text-base bp1024:mb-10">
            Depuis 14 ans, l’équipe Potager City plante les graines d’une consommation plus responsable et qui a du goût&nbsp;! Et ça se passe aussi en entreprises&nbsp;!
            <br><br>
            A croquer, à éplucher ou à partager, dans votre salle de pause ou dans celle de réunion : offrez à vos collaborateurs une petite attention quotidienne, pleine de sens et qui change de d’habitude.
          </p>
        </potager-col>
        <potager-col
          :offset="$mq.bp1024 ? 0 : 0.5"
          :col="$mq.bp1024 ? 12 : 7"
          offset-bp1024="0">
          <div class="relative py-10 bp640:py-0">
            <div
              class="
                relative bg-white shadow-lg rounded-xl
                max-w-4/12 bp1024:max-w-full bp1024:w-7/12 bp640:w-full
                px-12 bp768:px-8 py-10 bp768:py-8 z-10 tracking-normal
              ">
              <potager-badge
                theme="rossabel"
                size="large">
                Un prix juste
              </potager-badge>
              <p class="text-british-racing font-extrabold m-4 ml-0">
                <b class="text-5xl.5 font-black">
                  25€
                </b>
                /corbeille
              </p>
              <ul class="py-7">
                <li
                  v-for="argument in content"
                  :key="argument.text"
                  class="flex mb-3">
                  <span class="text-sushi pr-5 pb-5 flex items-center">
                    <icon-check />
                  </span>
                  <p>
                    <b>
                      {{ argument.emphasis }}
                    </b>
                    {{ argument.text }}
                  </p>
                </li>
              </ul>
              <potager-button
                :href="TALLY_URL"
                theme="wild-willow"
                target="_blank">
                Obtenir un devis
              </potager-button>
            </div>
            <potager-picture
              v-if="!$mq.bp640"
              class="
                absolute top-0 right-0 bottom-0 rounded-xl overflow-hidden
                w-full max-w-4/12 bp1024:max-w-full bp1024:w-7/12 z-0 bg-white-smoke
              "
              background
              placeholder="bg-forest-green"
              position="center right"
              src="b2b-transport" />
          </div>
        </potager-col>
      </potager-row>
    </potager-container>
  </div>
</template>

<script>
import PotagerContainer from 'UI/PotagerContainer';
import PotagerRow from 'UI/PotagerRow';
import PotagerCol from 'UI/PotagerCol';
import PotagerPicture from 'UI/PotagerPicture';
import PotagerButton from 'UI/PotagerButton';
import PotagerBadge from 'UI/PotagerBadge';

import IconCheck from 'Icons/bold/IconCheck';
import { TALLY_URL } from 'Classes/Contents';

export default {

  components: {
    PotagerContainer,
    PotagerRow,
    PotagerCol,
    PotagerPicture,
    PotagerButton,
    PotagerBadge,
    IconCheck,
  },

  data: () => ({
    TALLY_URL,
    content: [
      {
        emphasis: '5kg de fruits',
        text: 'pour 10 à 15 collaborateurs.',
      },
      {
        emphasis: 'Minimum de 4 variétés de fruits',
        text: 'qui varient au fil des récoltes.',
      },
    ],
  }),

};
</script>
