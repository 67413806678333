export default [
  'M151 289.3C258.3 289.3 354.8 336.5 413.2 230.4 452.1 159.7 481 84 500 0L500 0 0 0 0 500C29.2 359.5 79.5 289.3 151 289.3Z',
  'M0 500C51.2 433.9 95.1 400.8 131.8 400.8 252.8 400.8 361.6 448.4 427.4 341.3 446.9 309.6 471 260 500 192.3L500 0 0 0 0 500Z',
  'M0 500L500 500C500 146 500-20 500 1.9 469.1 81.4 433 121.1 391.7 121.1 311.5 121.1 113.8 73.2 63.9 129.1 14 184.9 3.8 267.9 0 297.3 0 261.5 0 329.1 0 500Z',
  'M0 500L500 500C500 166.7 500 0 500 0 481.3 58.6 452.9 105.3 409.7 137.3 256.4 251 151.6 130.6 95.5 137.3 58.1 141.8 26.2 228.4 0 397.2L0 500Z',
  'M0 460.3C60.4 515.6 110.1 513.1 148.9 453 209.6 358.9 296.2 256.3 377.2 256.3 426.1 256.3 467 195.9 500 75.2 500 75.2 500 50.4 500 1 500 1 333.3 0.7 0 0L0 460.3Z',
  'M0 500C28.4 296.9 61.9 209.7 100.3 238.3 158 281.2 283.5 361.7 356.8 332.6 405.7 313.2 453.4 228.9 500 79.7L500 0 0 0 0 500Z',
  'M0 499.8C25.9 459 62.4 390.5 126.7 413.6 223.5 448.3 258.5 406.4 357.3 286.6 405.6 228 453.2 142.9 500 31.3L500 0 0 0.3 0 499.8Z',
  'M500 500L500 0C463.8 141.2 426.8 196.9 389 167.4 340.5 129.3 239.2 101.2 200.2 250.7 132.3 510.3 65.6 533.7 0 321L0 500 500 500Z',
  'M0 500L500 500 500 0C460.3 64.9 421 114.2 380.5 147.8 298.3 216.1 192.4 1.7 104.5 243.2 66.8 346.7 32.4 417.1 0 454.5L0 500Z',
  'M0 40.3C30.6 120 79.9 159.8 148 159.8 250.2 159.8 244.5 443 352.9 406.7 425.2 382.5 474.2 413.5 500 499.6L500 0 0 0 0 40.3Z',
  'M0 81.5C18.5 87 37.7 89.8 57.9 89.8 249.7 89.8 239.1 584.1 442.6 484.2 462.7 474.3 481.8 467.7 500 464.6L500 0 0 0 0 81.5Z',
  'M 0 0 Q 250 500 500 0 Z',
  'M0 0L0 200.2C3.3 233.3 8.1 317.5 12.5 215.2 15 221 17.6 226.4 20.1 231.6 21.9 247.3 23.6 256.2 25.1 241 27.3 244.9 29.6 248.3 31.8 251.2 35 308.9 37 287.7 41.3 262.4 41.3 262.1 41.4 261.8 41.4 261.5 47.1 267.3 52.8 273 58.5 278.7 59 280.5 59.5 282.2 60.1 283.8 66 303.5 71.8 324.8 77.2 297.2 106.9 326.1 136.7 352.6 166.4 371.3 173 375.5 179.6 379.3 186.1 382.6 222.9 401.4 259.4 382.5 296 381.7 296.1 390 301 404.4 301.2 396.9 301.3 391.5 301.5 386.5 301.7 381.7 332.6 382.6 363.7 390.9 394.6 388.4 394.6 388.8 394.7 389.2 394.8 389.6 387 402.2 381.4 404.6 376.2 423.2 374 430.9 372.7 473.2 371.1 500 376.4 497.4 381.8 498.9 387 491 393 481.9 398.8 461.8 404.8 452.4 406.2 450.2 408 472.1 409.6 482.8 408.1 420.5 409.6 379.9 413.7 393.4 416.4 402.1 418.9 445.8 416.3 493.4 425.2 493.7 433.3 453.4 442.5 457.9 451.4 462.1 460.8 492.9 469.8 465.9 479.5 436.8 489.9 439.8 500 424.6L500 0 0 0 0 0Z',
  'M500 500V374.5c-21.7-267.1-47.2-340.6-68.5-336.8-22.6 4.2-45.1 70.5-67.7 78.2-38.4 13.4-76.9-5.9-115.2 5.1-23.9 7-47.6 64.3-71.5 62.3-15.7-1.3-31.7-89.2-47.4-100.1-30.8-21.5-61.5-12.5-92.2-23.2C25.1 55.6 12.3 28.6 0 20.3V500h500z',
  'M500 0c.141 175.54.141 263.942 0 265.207-58.51 37.543-116.62-.295-174.93-1.898-.27 16.62-7.98 45.558-8.31 30.456a3815.105 3815.105 0 00-.78-30.372c-49.306 1.772-98.733 18.35-147.987 13.415-.088.801-.185 1.56-.28 2.362 12.312 25.183 21.274 30.077 29.613 67.282 3.411 15.27 5.478 99.975 8.147 153.548-8.486-5.273-17.078-2.151-25.435-17.97-9.546-18.139-18.817-58.466-28.353-77.238-2.225-4.303-5.047 39.357-7.598 60.87 2.333-124.567-.06-205.854-6.571-178.857-4.199 17.464-8.313 104.741-4.169 199.95-14.1.632-26.943-79.938-41.66-71.037-14.175 8.436-29.127 70.024-43.443 16.156C32.63 373.618 16.023 379.566 0 349.194V0h500z',
  'M500 424.597V500H371.057c5.328-2.636 10.723-1.076 15.97-8.985 5.994-9.07 11.814-29.233 17.802-38.619 1.396-2.151 3.169 19.679 4.77 30.435-1.465-62.283.038-102.927 4.126-89.428 2.636 8.731 5.22 52.37 2.617 99.974 8.854.317 16.917-39.969 26.158-35.518 8.9 4.218 18.287 35.012 27.276 8.078 9.736-29.128 20.164-26.154 30.224-41.34zM12.454 215.157a400.012 400.012 0 007.635 16.409c1.857 15.713 3.489 24.635 4.995 9.428 2.243 3.902 4.48 7.319 6.71 10.23 3.162 57.664 5.195 36.488 9.474 11.22.055-.337.11-.633.166-.949 5.699 5.78 11.397 11.537 17.093 17.253.514 1.73 1.022 3.438 1.525 5.083 5.984 19.636 11.705 40.981 17.106 13.33 29.773 28.98 59.535 55.408 89.245 74.18 6.58 4.155 13.156 7.93 19.73 11.263 36.737 18.771 73.222-.148 109.833-.95.17 8.31 5.01 22.78 5.217 15.229.148-5.358.311-10.42.49-15.186 30.958.885 61.992 9.175 92.916 6.707.056.4.117.78.176 1.18-7.73 12.593-13.357 15.04-18.593 33.642-2.142 7.635-3.44 49.988-5.115 76.774H0V200.16c3.333 33.135 8.124 117.355 12.454 14.996zM500 .02H0V0h500v0z',
  'M-.08 499.92l185.34-.01 104.23.01 13.53-19.87 19.81-28.15 8.63-13.26 6.66-11.38 6.04-11.8 3.9-8.89 2.8-7.34 1.87-5.65 1.32-4.53.17-1.4-.04-1.46-.52-2.56-1.08-2.7-1.61-2.85-2.12-2.98-2.6-3.11-3.07-3.24-3.5-3.34-3.9-3.46-6.6-5.38-7.38-5.58-10.94-7.73-8.92-5.98-16.01-10.26-17.1-10.52-28.71-17.14-39.7-23.47-23.8-14.44-12.58-7.93-8.83-5.77-8.2-5.58-7.5-5.37-6.72-5.14-4-3.3-3.6-3.16-3.16-3.04-2.72-2.92-2.23-2.77-2.08-3.26-1.4-3.6-.11-.56c-.17-.98.2-2.01 1.07-3.09l1.66-1.64c1-.84 2.28-1.7 3.8-2.6l3.35-1.8a93.74 93.74 0 013.98-1.86l4.57-1.91 7.89-2.97 5.9-2.04 6.36-2.08 6.81-2.12 7.22-2.17 11.52-3.3 8.1-2.25 21.43-5.73 22.74-5.85 70.9-17.89 22.81-5.9 17.36-4.66 8.28-2.3 11.86-3.4 7.47-2.22 10.48-3.28 9.49-3.2 7.08-2.6 6.27-2.52 4.37-1.96 3.78-1.91 3.14-1.86 2.48-1.8c.7-.6 1.3-1.17 1.78-1.74l1.04-1.68c.45-1.1.38-2.14-.24-3.15l-2.48-3.06-1.98-3.2-1.36-3.34-.79-3.43-.21-4.4.39-3.57 1.13-4.5 1.39-3.62 2.79-5.39 4.18-6.15 3.51-4.25 5.49-5.65 5.1-4.5 4.45-3.46 4.55-3.14 3.66-2.26 10.3-6.11 8.23-5.1 7.74-5.1 2.93-2.06 5.51-4.14 5-4.17c5.5-4.9 9.78-9.9 12.31-15.05H-.08v500z',
];
