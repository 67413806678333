import HomePage from 'Pages/home/HomePage';
import DashboardPage from 'Pages/dashboard/DashboardPage';

export default [
  {
    name: 'home',
    path: '/',
    component: HomePage,
    meta: {
      scrollToTop: true,
    },
  },
  {
    name: 'dashboard',
    path: '/boutique',
    component: DashboardPage,
    meta: {
      scrollToTop: true,
      bottomBar: true,
    },
  },
];
