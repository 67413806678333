import { PMEL, MEL } from 'Classes/Constants';
import store from 'Stores';

export default function (to, from, next) {
  const newTo = { ...to };

  if (!to.params.regionId) {
    const regionId = store.getters['session/getRegionId'];
    newTo.params = {
      ...newTo.params,
      regionId: regionId.toString()
    };
    console.info('tunnelBoxGuard > Redirecting to regionId:', regionId);
    next(newTo);
  } else if (to.params.ref === PMEL || !to.params.ref) {
    newTo.params = {
      ...newTo.params,
      ref: MEL
    };
    console.info('tunnelBoxGuard > Redirecting to ref:', MEL);
    next(newTo);
  } else {
    next();
  }
}
