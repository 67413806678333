/**
 * Create an array of numbers in a range, with a given step size.
 *
 * @param {number} [start=0] - The starting number of the range.
 * @param {number} [end=100] - The end of the range.
 * @param {number} [step=10] - The step size between each number in the array.
 * @returns {number[]} An array of numbers in the range.
 */
export const arrayOfNumberInRange = (start = 0, end = 100, step = 10) => {
  const arrayLength = Math.floor(((end - start) / step)) + 1;
  return [...Array(arrayLength)
    .keys()].map((x) => (x * step) + start);
};

/**
 * Check if a value is empty.
 * @param {*} value - The value to check.
 * @returns {boolean} True if the value is empty, false otherwise.
 */
export const isEmpty = (value) => {
  if (value == null) return true;
  if (Array.isArray(value) || typeof value === 'string') return value.length === 0;
  if (typeof value === 'object') return Object.keys(value).length === 0;
  return false;
};

/**
 * Generate a random number within a specified range and optionally append a unit.
 *
 * @param {number} min - The minimum value of the range.
 * @param {number} max - The maximum value of the range.
 * @param {string} [unit] - An optional unit to append to the generated number.
 * @returns {string|number} A random number within the range, optionally appended with the unit.
 */
export const randomize = (min, max, unit) => {
  return Math.floor(Math.random() * (max - min + 1) + min) + (unit || '');
};

/**
 * Validate if a given CSS selector is valid.
 *
 * @param {string} selector - The CSS selector to validate.
 * @returns {boolean} True if the selector is valid, false otherwise.
 */
export const isSelectorValid = (selector) => {
  try {
    document.createDocumentFragment()
      .querySelector(selector);
    return true;
  } catch {
    return false;
  }
};
