import { POPIN_ERROR_EVENT } from 'Classes/Constants';

export default {
  default: {
    text: 'La commande est à 0€, elle ne peut pas accepter de coupon supplémentaire.',
  },
  'PaymentApi.tryFreePaymentBasket': {
    eventType: POPIN_ERROR_EVENT,
    title: 'Avertissement',
    text: 'La commande est à 0€, elle ne peut pas accepter de coupon supplémentaire.',
    closeBtnText: 'Modifier',
    onCloseRedirect: { name: 'basket_coupons' },
  },
};
