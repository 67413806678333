<template>
  <section class="rassurance-they-trust-us">
    <potager-container
      id="ils-nous-font-confiance"
      size="small">
      <potager-row class="rassurance-they-trust-us__row">
        <potager-col
          bp640="12"
          col="6">
          <h2 class="rassurance-they-trust-us__title">
            <potager-spotlight
              :fill="wildWillow"
              inline-block
              type="stroke">
              {{ nbDlp }}
            </potager-spotlight>
            points relais
          </h2>
          <p class="rassurance-they-trust-us__text">
            À ce jour, plus de {{ roundThousand }} points relais sont référencés sur le territoire français.
            Nous privilégions les commerces de proximité qui partagent notre amour des bonnes choses,
            au savoir-faire reconnu et à l’activité complémentaire : fromagers, bouchers, fleuristes, chocolatiers...
          </p>

          <slot />

          <potager-button
            :full-width="$mq.bp768"
            :to="redirect"
            theme="stroke">
            Voir tous nos points relais
          </potager-button>
        </potager-col>

        <potager-col
          bp640="12"
          col="6">
          <potager-picture
            :title="`${nbDlp} commerces sont devenus points relais de paniers de fruits et légumes pour Potager City`"
            class="rassurance-they-trust-us__img"
            src="map-delivery-points" />
        </potager-col>
      </potager-row>
    </potager-container>
  </section>
</template>

<script>
import PotagerContainer from 'UI/PotagerContainer';
import PotagerButton from 'UI/PotagerButton';
import PotagerRow from 'UI/PotagerRow';
import PotagerCol from 'UI/PotagerCol';
import PotagerSpotlight from 'UI/PotagerSpotlight';
import PotagerPicture from 'UI/PotagerPicture';

import COLORS from 'potagerlogic/dist/Enums/Colors';

const { wildWillow } = COLORS;

export default {

  components: {
    PotagerContainer,
    PotagerButton,
    PotagerRow,
    PotagerCol,
    PotagerSpotlight,
    PotagerPicture,
  },

  data: () => ({
    wildWillow,
    nbDlp: null,
    redirect: {
      name: 'delivery-points',
    },
  }),

  mounted() {
    this.$api.deliveryPoints.count()
      .then((response) => {
        this.nbDlp = response.data[0];
      });
  },

  computed: {
    roundThousand() {
      return Math.floor(this.nbDlp / 1000) * 1000;
    },
  },

};
</script>
