import { isNextPotagerWeek, tmsToFormat } from 'Classes/utils/DatesUtils';

/**
 * Get the time slot for the given order.
 *
 * @param {Object} order - The order object.
 * @param {Date} order.date - The date of the order.
 * @param {Object} order.groupDeliveryDay - The group delivery day object.
 * @param {Object} order.groupDeliveryDay.timeSlot - The time slot for the group delivery day.
 * @param {Object} order.groupDeliveryDay.currentWeekTimeSlot - The current week time slot for the group delivery day.
 * @returns {Object} The time slot for the order.
 */
export const getOrderTimeSlot = (order) => {
  return isNextPotagerWeek(order?.date)
    ? order?.groupDeliveryDay?.timeSlot
    : order?.groupDeliveryDay?.currentWeekTimeSlot;
};

/**
 * Get the note for the given order.
 *
 * @param {Object} order - The order object.
 * @param {Object} order.deliveryPoint - The delivery point object.
 * @param {string} order.deliveryPoint.note - The note for the delivery point.
 * @returns {string|null} The note for the order.
 */
export const getOrderNote = (order) => {
  const dlpNote = order?.deliveryPoint?.note || '';
  const gddNote = getOrderTimeSlot(order)?.note || '';
  return dlpNote || gddNote ? `${dlpNote}${dlpNote && gddNote ? '<br>' : ''}${gddNote}` : null;
};

/**
 * Get the delivery date for the given order.
 *
 * @param {Object} order - The order object.
 * @param {Object} order.timeSlot - The time slot object.
 * @param {Date} order.timeSlot.date - The date of the time slot.
 * @returns {string|null} The formatted delivery date for the order.
 */
export const getOrderDeliveryDate = (order) => {
  return order?.timeSlot?.date
    ? tmsToFormat(order.timeSlot.date, 'iiii dd MMMM')
    : null;
};

/**
 * Get the next delivery hours for the given order.
 *
 * @param {Object} order - The order object.
 * @param {Object} order.groupDeliveryDay - The group delivery day object.
 * @param {Object} order.groupDeliveryDay.timeSlot - The time slot for the group delivery day.
 * @param {Date} order.groupDeliveryDay.timeSlot.date - The date of the time slot.
 * @param {string} order.groupDeliveryDay.timeSlot.startHour - The start hour of the time slot.
 * @param {string} order.groupDeliveryDay.timeSlot.endHour - The end hour of the time slot.
 * @returns {string|null} The next delivery hours for the order.
 */
export const getOrderNextDeliveryHours = (order) => {
  const ts = order?.groupDeliveryDay?.timeSlot;
  if (!ts || isNextPotagerWeek(ts.date)) return null;
  return `Dès ${tmsToFormat(ts.startHour, 'HH\'h\'mm')} jusqu'à ${tmsToFormat(ts.endHour, 'HH\'h\'mm')}`;
};

/**
 * Get the resumable date for the given order.
 *
 * @param {Object} order - The order object.
 * @param {Date} order.limitDate - The limit date of the order.
 * @returns {string|null} The formatted resumable date for the order.
 */
export const getOrderResumableDate = (order) => {
  return order?.limitDate
    ? tmsToFormat(order.limitDate, 'iiii dd MMMM, HH\'h\'mm')
    : null;
};
