<template>
  <nav class="main-header__nav">
    <ul
      v-for="(menuPart, index) in Object.values(menu)"
      :key="Object.keys(menu)[index]"
      :class="[
        `main-header__nav__list main-header__nav__list--${Object.keys(menu)[index]}
          h-full flex justify-center whitespace-nowrap`
      ]">
      <li
        v-for="(item, itemIndex) in menuPart"
        :key="itemIndex"
        class="main-header__nav__item relative font-semibold">
        <potager-button
          v-gtm
          :class="[
            'main-header__nav__item__trigger outline-none text-sm',
            {
              'text-warm-grey hover:text-british-racing': Object.keys(menu)[index] === 'right',
              'main-header__nav__item__trigger--active': isActive(item),
            },
          ]"
          :target="item.target"
          :theme="theme"
          :to="item.to || item.children[0].to">
          {{ item.label }}
        </potager-button>

        <div
          v-if="item.children"
          class="main-header__nav__item__children">
          <ul
            :class="[
              'main-header__nav__item__children__list flex',
              item.children.some((child) => child.children) ? 'flex-row': 'flex-col gap-5 p-8',
            ]">
            <li
              v-for="(child, j) in item.children"
              :key="j"
              :class="[
                'flex flex-1 relative',
                {
                  'flex flex-col p-8 border-r border-white-rock last:border-r-0': child.children,
                  'mb-6 after:block after:absolute after:-bottom-3 after:left-3 after:right-3 after:border-solid after:border-white-rock after:border-b': child.head
                }
              ]">
              <potager-button
                v-if="!child.children"
                v-gtm
                :to="child.to"
                class="text-left flex-1 justify-start"
                no-padding
                theme="white">
                <template
                  v-if="child.icon"
                  #icon>
                  <potager-picture
                    :src="child.icon"
                    :title="child.title"
                    class="w-5 h-5" />
                </template>

                <span class="flex w-full font-semibold">
                  {{ child.label }}
                </span>
              </potager-button>

              <template v-else>
                <p class="text-base font-bold mb-6">
                  {{ child.label }}
                </p>

                <ul class="flex flex-col gap-5">
                  <li
                    v-for="(secondChild, k) in child.children"
                    :key="`secondChild-${k}`">
                    <potager-button
                      v-gtm
                      :to="secondChild.to"
                      class="text-left flex-1 justify-start"
                      no-padding
                      theme="white">
                      <template
                        v-if="secondChild.icon"
                        #icon>
                        <potager-picture
                          :src="secondChild.icon"
                          :title="secondChild.title"
                          class="w-5 h-5" />
                      </template>

                      <span class="flex w-full font-semibold">
                        {{ secondChild.label }}
                      </span>
                    </potager-button>
                  </li>
                </ul>
              </template>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </nav>
</template>

<script>
import PotagerButton from 'UI/PotagerButton';
import PotagerPicture from 'UI/PotagerPicture';

export const props = {
  menu: {
    type: Object,
    required: true,
  },
  theme: {
    type: String,
    required: false,
    default: 'transparent',
  },
};

export default {
  props: {
    ...props,
  },

  components: {
    PotagerPicture,
    PotagerButton,
  },

  methods: {
    isActive(item) {
      if (item.children) {
        return item.children
          .some((child) => this.$route.name === child.to?.name);
      }

      return this.$route.name === item.to?.name;
    },
  },
};
</script>
