<template>
  <div class="framboise-message max-w-6/12 w-full mx-auto flex relative flex-col text-center leading-6">
    <IconPtcyFramboise :class="['text-5xl mx-auto mb-3', {'text-7xl mb-5': size === 'large'}]" />

    <h1 :class="['text-lg font-bold', $slots.default ? 'mb-2' : 'mb-6']">
      <slot name="title" />
    </h1>

    <div
      v-if="$slots.default"
      class="framboise-message__content mb-6">
      <slot />
    </div>
  </div>
</template>

<script>
import IconPtcyFramboise from 'Icons/color/IconPtcyFramboise';

export default {
  components: {
    IconPtcyFramboise,
  },

  props: {
    size: {
      type: String,
      default: 'medium',
      values: ['small', 'medium', 'large'],
    },
  },
};
</script>
