<template>
  <div class="blog">
    <blog-body :is-loading="!content.id">
      <template #heading>
        <heading
          :back="{ name: 'recettes-list' }"
          :background="content.image"
          :is-loading="!content.id"
          modifiers="blog">
          <template #title>
            {{ content.title }}
          </template>
          <preparation
            :recipe="content"
            class="preparation--heading" />
          <favori
            :id="id"
            class="carnet--heading" />
        </heading>
      </template>

      <blog-section>
        <cms
          :content="content.description"
          with-hat />
      </blog-section>

      <blog-section title="Ingrédients">
        <ingredient-list
          :ingredients="content.ingredients_v2"
          is-large />
      </blog-section>

      <blog-section
        v-intersect="{
          once: true,
          callback: () => { hasIntersected = true; },
        }"
        title="Déroulé de la recette">
        <recipe-steps
          :recipe="content"
          is-large />
      </blog-section>

      <blog-social
        :item="content"
        context="recipe" />

      <cross-selling-recipe
        :recipe="content"
        class="print:hidden" />

      <cross-selling-products class="print:hidden" />
    </blog-body>

    <push-subscription class="mb-10 bp768:my-6" />

    <micro-datas
      :content="content"
      context="recipe" />
  </div>
</template>

<script>
import BlogMixin from 'Mixins/BlogMixin'; // Where the magic happened
import SeasonalCouponMixin from 'Mixins/SeasonalCouponMixin';
import NewsletterPopupMixin from 'Mixins/NewsletterPopupMixin';

import Preparation from 'Components/recette/Preparation';
import IngredientList from 'Components/lists/IngredientList';
import RecipeSteps from 'Components/recipe/RecipeSteps';
import CrossSellingRecipe from 'Components/recette/CrossSellingRecipe';
import Favori from 'Components/recette/Favori';
import CrossSellingProducts from 'Components/recette/CrossSellingProducts';

export default {
  mixins: [
    BlogMixin,
    SeasonalCouponMixin,
    NewsletterPopupMixin,
  ],

  components: {
    CrossSellingProducts,
    Preparation,
    RecipeSteps,
    IngredientList,
    CrossSellingRecipe,
    Favori,
  },

  head() {
    return this.metaInfos;
  }
};
</script>
