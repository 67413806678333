import BasketPage from 'Pages/basket/BasketPage';
import BasketCouponsPage from 'Pages/basket/BasketCouponsPage';
import BasketPaymentPage from 'Pages/basket/BasketPaymentPage';
import BasketPaymentSuccessPage from 'Pages/basket/BasketPaymentSuccessPage';
import BasketCreditCardsManagePage from 'Pages/basket/BasketCreditCardsManagePage';
import AccountCreditCardsAddPage from 'Pages/account/paymentMethods/creditCards/AccountCreditCardsAddPage';
import BasketSubscriptionPage from 'Pages/basket/BasketSubscriptionPage';
import { Context } from 'potagerlogic/dist/Enums/Context';

const meta = {
  isPanel: true,
};

export default [

  /*
   * Mon panier
   */
  {
    name: 'basket',
    path: '/mon-panier/',
    component: BasketPage,
    meta,
  },
  {
    name: 'basket_coupons',
    path: '/mon-panier/coupons',
    component: BasketCouponsPage,
    meta,
  },

  /*
   * Mon panier - Paiement
   */
  {
    name: 'basket_payment_manage_card',
    path: '/mon-panier/paiement/choisir-une-carte/:cardId?',
    component: BasketCreditCardsManagePage,
    meta,
  },
  {
    name: 'basket_payment_add_card',
    path: '/mon-panier/paiement/ajouter-une-carte',
    component: AccountCreditCardsAddPage,
    meta,
    props: {
      context: Context.Basket,
    },
  },
  {
    name: 'basket_payment_success',
    path: '/mon-panier/paiement/success/:orderId?',
    component: BasketPaymentSuccessPage,
    meta,
  },
  {
    name: 'basket_payment',
    path: '/mon-panier/paiement/:cardId?',
    component: BasketPaymentPage,
    meta,
  },
  {
    name: 'basket_subscription',
    path: '/mon-panier/abonnement',
    component: BasketSubscriptionPage,
    meta,
  },
];
