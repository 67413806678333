<template>
  <div
    v-if="skeleton"
    class="card-title w-full skeleton" />

  <component
    :is="tag"
    v-else
    class="card-title">
    <slot />
  </component>
</template>

<script>
export default {
  name: 'CardTitle',

  props: {
    tag: {
      type: String,
      required: false,
      default: 'h3',
    },
    skeleton: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.card-title {
  @include multilineEllipsis(1rem, $baseLh, 2);
  @apply text-sm.5 leading-5 h-10;

  &:last-child {
    margin-bottom: 0;
  }
}
</style>

<style lang="scss">
.card-title {
  .card-v2--landscape & {
    @apply text-xs.5 bp425:leading-[18px] bp425:h-[36px];
  }

  .card-v2--small & {
    @apply text-xs.5;
    line-height: $baseLh;
  }

  .card-v2__pic & {
    @apply absolute bottom-0 left-1/2 transform -translate-x-1/2 z-20;
    color: $white;
    padding: 1.5rem;
    text-align: center;
    max-height: initial;
    -webkit-line-clamp: initial;

    @include bp768() {
      padding: .75rem;
      font-weight: $weightExtrabold;
      line-height: 1.125em;
    }
  }
}
</style>
