<template>
  <template v-if="currentQuestion">
    <section class="panel-section">
      <p class="panel-section__subtitle last:mb-0">
        {{ currentQuestion.title }}
      </p>
      <p
        v-if="currentQuestion.desc"
        class="panel-section__p mb-0">
        {{ currentQuestion.desc }}
      </p>

      <survey-did-you-know
        v-if="!!survey.getDidYouKnowByCurrentQuestion()"
        :did-you-know="survey.getDidYouKnowByCurrentQuestion()" />
    </section>

    <div class="panel-section">
      <potager-form
        id="survey-form"
        class="panel-section__line">
        <div
          v-for="(choice, index) in currentQuestion.choices"
          :key="`choice-${index}`"
          class="mb-4 last:mb-0">
          <potager-checkbox
            :id="index"
            :model-value="survey.isChoiceSelected(choice)"
            :type="currentQuestion.type"
            class="mb-0"
            name="survey-choice"
            @update:modelValue="(value) => onChoiceToggle(value, choice)">
            <potager-input
              v-if="choice.comment && choice.comment.type === 'input'"
              :label="choice.label"
              :model-value="survey.getCurrentCommentValue(choice)"
              @onFocus="survey.saveChoice(choice)"
              @update:modelValue="(value) => survey.saveComment(choice, value)" />
            <template v-else>
              {{ choice.label }}
            </template>
          </potager-checkbox>

          <collapsible-content
            v-if="choice.comment && choice.comment.type === 'select'"
            :is-open="survey.isChoiceSelected(choice)">
            <div class="pt-4">
              <potager-input
                :label="choice.comment.label"
                :model-value="survey.getCurrentCommentValue(choice)"
                :options="survey.selectOptionsAdapter(choice.comment.choices)"
                :placeholder="choice.label"
                name="comment"
                type="select"
                @update:modelValue="(value) => survey.saveComment(choice, value)" />
            </div>
          </collapsible-content>

          <survey-did-you-know
            :did-you-know="survey.getDidYouKnowBySelectedChoice(choice)"
            class="-mx-4" />
        </div>
      </potager-form>
    </div>
  </template>
  <div
    v-else
    class="text-center">
    <icon-ptcy-loader-spinner class="text-british-racing text-4xl" />
  </div>
</template>

<script>
import PotagerCheckbox from 'UI/Form/PotagerCheckbox';
import PotagerInput from 'UI/Form/PotagerInput';
import CollapsibleContent from 'Components/collapsible/CollapsibleContent';
import SurveyDidYouKnow from 'Components/surveys/SurveyDidYouKnow';
import PotagerForm from 'UI/Form/PotagerForm.vue';
import IconPtcyLoaderSpinner from 'Icons/regular/IconPtcyLoaderSpinner';

export default {

  components: {
    PotagerForm,
    PotagerCheckbox,
    PotagerInput,
    CollapsibleContent,
    SurveyDidYouKnow,
    IconPtcyLoaderSpinner,
  },

  props: {
    survey: {
      type: Object,
      required: true,
    },
  },

  computed: {
    currentQuestion() {
      return this.survey.getCurrentQuestion();
    },
    currentQuestionId() {
      return this.survey.getCurrentQuestionId();
    },
  },

  methods: {
    onChoiceToggle(value, choice) {
      const { type } = this.currentQuestion;
      if (type === 'radio') this.survey.saveChoice(choice);
      if (type === 'checkbox') this.survey.toggleChoice(choice, value);
    },
  },
};
</script>
