<template>
  <div class="bg-white rounded-md shadow-sm h-full">
    <div
      v-if="informationLabel"
      :class="[
        'rounded-t-md text-center px-4 py-1 bg-british-racing text-white text-xs.5 font-bold',
      ]"
      v-html="informationLabel" />

    <div class="potager-card rounded-md flex flex-col h-full">
      <div
        v-if="$slots['before-content']"
        class="potager-card__before-content">
        <slot name="before-content" />
      </div>

      <div
        :class="[
          'flex flex-col p-4',
          {'text-center': centered},
          {'rounded-b-none': $slots.details}
        ]">
        <slot />
      </div>

      <div
        v-if="$slots['after-content'] || hasDetails"
        class="potager-card__after-content mt-auto">
        <slot name="after-content" />

        <potager-button
          v-if="hasDetails"
          :disabled="modalItems.length === 0"
          class="rounded-t-none underline"
          full-width
          theme="white-rock"
          @onClick="onDetails">
          <span v-html="detailsLabel" />
        </potager-button>
      </div>
    </div>
  </div>
</template>

<script>
import ModalList from 'Components/modals/ModalList';
import ModalProductsList from 'Components/modals/ModalProductsList';
import PotagerButton from 'UI/PotagerButton';

export default {
  components: { PotagerButton },

  props: {
    centered: {
      type: Boolean,
      default: false,
    },
    detailsLabel: {
      type: String,
      default: 'Voir les détails',
    },
    informationLabel: {
      type: String,
      default: undefined,
    },
    modalTitle: {
      type: String,
      default: undefined,
    },
    modalItems: {
      type: Array,
      default: () => [],
    },
    modalBtnTheme: {
      type: String,
      default: undefined,
    },
    modalBtnLabel: {
      type: String,
      default: undefined,
    },
    modalBtnCallback: {
      type: Function,
      default: undefined,
    },
    modalBtnRoute: {
      type: [String, Object],
      default: undefined,
    },
  },

  computed: {
    hasDetails() {
      return this.modalTitle || this.modalItems.length || this.modalBtnLabel;
    },
    isModalProducts() {
      return this.modalItems.length && this.modalItems[0].id;
    },
  },

  methods: {
    onDetails() {
      const {
        modalItems, modalTitle, modalBtnTheme, modalBtnLabel,
      } = this;
      this.$modal.open(this.isModalProducts ? ModalProductsList : ModalList, {
        title: modalTitle,
        items: modalItems,
        btnLabel: modalBtnLabel,
        btnTheme: modalBtnTheme,
        btnCallback: this.modalBtnCallback,
        btnRoute: this.modalBtnRoute,
      });
    },
  },
};
</script>
