import { POPIN_ERROR_EVENT } from 'Classes/Constants';
import { UPDATE_USER } from 'Stores/types/userMutationsTypes';
import store from 'Stores';

export default {
  'UserApi.resumeOrder': {
    eventType: POPIN_ERROR_EVENT,
    text:
      'Oops ! Un produit présent dans votre commande est victime de son succès et n\'est desormais plus disponible pour le jour de livraison choisi. Vous pouvez tout de même reprendre cette commande mais ce produit ne sera plus présent.',
    handle(app, response) {
      const data = response.data?.data || response.data;
      store.commit(`user/${UPDATE_USER}`, data);
    },
  },
};
