import { PRICE_ONE_SHOT, PRICE_ONE_SHOT_ENTERPRISE, SUBSCRIPTION, SUBSCRIPTION_ENTERPRISE } from 'Classes/Constants';

// Calculators
/**
 * Get the blocked out price based on the price category.
 * @param {string} priceCategory - The category of the price.
 * @param {Object} box - The box object containing price details.
 * @returns {number} The blocked out price.
 */
export const getBoxBlockedOutPrice = (box, priceCategory) => {
  switch (priceCategory || box?.priceCategory) {
    case SUBSCRIPTION:
    case SUBSCRIPTION_ENTERPRISE:
      return box.price.blockedOutSubscription;
    case PRICE_ONE_SHOT:
    case PRICE_ONE_SHOT_ENTERPRISE:
    default:
      return box.price.blockedOutOneShot;
  }
};

/**
 * Get the price box based on the price category.
 * @param {string} priceCategory - The category of the price.
 * @param {Object} box - The box object.
 * @returns {Object} The price box.
 */
export const getBoxPrice = (box, priceCategory) => {
  switch (priceCategory || box?.priceCategory) {
    case SUBSCRIPTION_ENTERPRISE:
    case SUBSCRIPTION:
      return box.price.subscription;
    case PRICE_ONE_SHOT_ENTERPRISE:
    case PRICE_ONE_SHOT:
    default:
      return box.price.oneShot;
  }
};

/**
 * Get the price order based on the price category.
 * @param {string} priceCategory - The category of the price.
 * @param {Object} order - The order object.
 * @returns {Object} The price order.
 */
export const getOrderPrice = (order, priceCategory) => {
  return order[priceCategory || order?.priceCategory] || order.priceOneShot;
};

/**
 * Get the subtotal of an order.
 * @param {Object} order - The order object.
 * @param priceCategory
 * @returns {number} The subtotal of the order.
 */
export const getSubTotalOrder = (order, priceCategory) => {
  switch (priceCategory || order?.priceCategory) {
    case SUBSCRIPTION:
    case SUBSCRIPTION_ENTERPRISE:
      return order.priceSubscription.subTotal;
    case PRICE_ONE_SHOT_ENTERPRISE:
    case PRICE_ONE_SHOT:
    default:
      return order.priceOneShot.subTotal;
  }
};

/**
 * Get the total price of an order.
 * @param {Object} order - The order object.
 * @param priceCategory
 * @returns {string} The total price of the order.
 */
export const getTotalOrder = (order, priceCategory) => {
  if (order && (priceCategory || order.priceCategory)) {
    return getOrderPrice(order, priceCategory)?.total;
  }
  return '0.00';
};

/**
 * Get the enterprise discount for a subscription order.
 * @param {Object} order - The order object.
 * @param priceCategory
 * @returns {string} The enterprise discount for the subscription order.
 */
export const getSubscriptionEnterpriseDiscount = (order, priceCategory) => {
  switch (priceCategory || order?.priceCategory) {
    case SUBSCRIPTION_ENTERPRISE:
      return order.priceSubscriptionEnterprise?.enterpriseDiscount;
    default:
      return '0.00';
  }
};

/**
 * Get the total value of coupons applied to an order.
 * @param {Object} order - The order object.
 * @returns {number} The total value of coupons.
 */
export const getCouponsTotalOrder = (order) => {
  return getOrderPrice(order)?.totalCoupons;
};

/**
 * Get the shipping cost for an order.
 * @param {Object} order - The order object.
 * @returns {number} The shipping cost of the order.
 */
export const getOrderShippingCost = (order) => {
  return getOrderPrice(order)?.shippingCost;
};

export const getSubscriberReduction = (order) => {
  const publicPrice = getOrderPrice(order, PRICE_ONE_SHOT).subTotal;
  const subscriberPrice = getOrderPrice(order, SUBSCRIPTION).subTotal;
  return publicPrice - subscriberPrice;
};

// Formatters
/**
 * It takes a price, and returns a string
 * @param price - the price to be formatted
 * @param [options] - overwrite : convert0toString ; unit ; freeString ; fractionDigits
 * @returns A String
 */
export const formatPrice = (price, {
  unit = '€',
  freeString
} = {}) => {
  if (!price) return null;

  const numericalPrice = Number(price)
    .toFixed(2)
    .replace('.', ',');
  const formattedPrice = numericalPrice.endsWith(',00') ? numericalPrice.slice(0, -3) : numericalPrice;
  if (formattedPrice === '0' && freeString) return freeString;
  return `${formattedPrice}${unit}`;
};
