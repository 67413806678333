<template>
  <potager-container
    class="enterprise__bullet-points-wrapper"
    size="small">

    <h2
      v-if="title"
      class="enterprise__title enterprise__title--fullwidth">
      {{ title }}
    </h2>
    <potager-row
      class="enterprise__bullet-points"
      tag="ul">
      <potager-col
        v-for="(item, i) in items"
        :key="i"
        :class="[
          'enterprise__bullet-points__item',
          `enterprise__bullet-points__item--${item.icon}`,
        ]"
        col="6"
        bp640="12"
        tag="li">
        <component
          :class="[
            'enterprise__bullet-points__item__icon',
            item.color,
          ]"
          :is="item.icon" />
        <span v-html="item.text" />
      </potager-col>
    </potager-row>

  </potager-container>
</template>

<script>
import PotagerContainer from 'UI/PotagerContainer';
import PotagerRow from 'UI/PotagerRow';
import PotagerCol from 'UI/PotagerCol';

export default {

  components: {
    PotagerContainer,
    PotagerRow,
    PotagerCol,
  },

  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
    items: {
      type: Array,
      required: true,
    },
  },

};
</script>
