<template>
  <tunnel-body class="text-center">
    <h1 class="text-xl font-extrabold mb-3 mx-10">
      Votre lieu de livraison
    </h1>

    <p class="mb-10">
      👍&nbsp;Vous pourrez modifier votre choix quand vous voudrez.
    </p>

    <dlp-type
      :context="context"
      :home-route="getHomeRoute"
      :private-route="getPrivateRoute"
      :public-route="getPublicRoute" />

    <transition
      mode="out-in"
      name="tunnel-hub--transition">
      <router-view />
    </transition>
  </tunnel-body>
</template>

<script>
import { mapGetters } from 'vuex';

import MetaInfosService from 'Classes/services/MetaInfoService';
import {
  DELIVERY_PUBLIC_TYPE, DELIVERY_PRIVATE_TYPE, DELIVERY_HOME_TYPE, DELIVERY_TYPE,
} from 'Classes/workflow/TunnelTypesConstants';
import { MODAL_SUBSCRIPTION_INFORMATIONS_NAME } from 'Classes/Constants';

import TunnelBody from 'Components/tunnel/TunnelBody';
import DlpType from 'Components/deliveryPoint/step1/DlpType';
import ModalList from 'Components/modals/ModalList';

import GtmMixin from 'Mixins/GtmMixin';
import Cookies from 'js-cookie';
import { Context } from 'potagerlogic/dist/Enums/Context';

export default {

  mixins: [
    GtmMixin,
  ],

  components: {
    TunnelBody,
    DlpType,
  },

  props: {
    context: {
      type: String,
      default: Context.SubscriptionBasket,
    },
  },

  mounted() {
    if (!this.isLoggedIn) this.openSubscriptionInformationsModal();
  },

  computed: {
    ...mapGetters('session', [
      'isLoggedIn',
    ]),
    ...mapGetters('tunnel', [
      'getNextStepRoute',
    ]),
    getHomeRoute() {
      return this.getNextStepRoute({ newTypes: [{ [DELIVERY_TYPE]: DELIVERY_HOME_TYPE }] });
    },
    getPublicRoute() {
      return this.getNextStepRoute({ newTypes: [{ [DELIVERY_TYPE]: DELIVERY_PUBLIC_TYPE }] });
    },
    getPrivateRoute() {
      return this.getNextStepRoute({ newTypes: [{ [DELIVERY_TYPE]: DELIVERY_PRIVATE_TYPE }] });
    },
  },

  methods: {
    openSubscriptionInformationsModal() {
      const canShowModal = !Cookies.get(MODAL_SUBSCRIPTION_INFORMATIONS_NAME);

      if (canShowModal) {
        this.$modal.open(ModalList, {
          name: MODAL_SUBSCRIPTION_INFORMATIONS_NAME,
          showOnceCookie: true,
          title: 'Étapes d’inscription',
          items: [
            {
              title: 'Choisissez votre livraison',
              description: 'Pour accéder à notre boutique : nous avons besoin d’un lieu et d’un jour de livraison car <b class="font-bold">nous mettons à jour les produits chaque jeudi en fonction de la saison.</b>',
              icon: '❶',
            },
            {
              title: 'Créez votre compte',
              description: 'Finalisez votre inscription à Potager City.',
              icon: '❷',
            },
            {
              title: 'Accédez à notre boutique',
              description: 'Découvrez tous nos produits et passez commande quand vous voulez.',
              icon: '❸',
            },
          ],
          cancelBtnText: 'Fermer',
          showCancel: true,
        });
      }
    },
  },

  head() {
    return MetaInfosService.generate({
      title: 'Sélectionnez votre point de retrait',
    });
  },
};
</script>

<style lang="scss">
@include define-transition(both, tunnel-hub--transition) {
  transition: transform .5s ease, opacity .5s ease;
}

@include define-transition(both, tunnel-hub--transition) {
  opacity: 0;
}

@include define-transition(enter, tunnel-hub--transition) {
  transform: translate(-1rem, 0);
}

@include define-transition(leave, tunnel-hub--transition) {
  transform: translate(1rem, 0);
}
</style>
