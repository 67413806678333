<template>
  <section class="why-become-dlp-advantages">
    <potager-wave
      :index="9"
      :height="['15.625rem', '3rem']"
      :modifiers="['on-top']" />

    <potager-container id="vos-avantages">
      <h2 class="why-become-dlp-advantages__title">
        Vos avantages en devenant point relais
      </h2>

      <potager-row
        class="why-become-dlp-advantages__list"
        tag="ul">
        <potager-col
          v-for="(item, index) in items"
          :key="index"
          class="why-become-dlp-advantages__list__item"
          col="6"
          bp640="12">
          <component
            class="why-become-dlp-advantages__list__item__icon"
            :is="item.icon" />
          <p class="why-become-dlp-advantages__list__item__title">
            {{ item.title }}
          </p>
          <p
            class="why-become-dlp-advantages__list__item__text"
            v-html="item.text" />
        </potager-col>
      </potager-row>

      <potager-button
        :size="$mq.bp768 ? 'small' : undefined"
        :full-width="$mq.bp768"
        @onClick="$emit('onClick')">
        Devenir point relais
      </potager-button>
    </potager-container>

    <potager-wave
      :index="6"
      :height="['12rem', '3rem']"
      :modifiers="['on-bottom', 'invert-y']" />
  </section>
</template>

<script>
import IconMultipleActionsStar from 'Icons/regular/IconMultipleActionsStar';
import IconGiftBox from 'Icons/regular/IconGiftBox';

import PotagerContainer from 'UI/PotagerContainer';
import PotagerButton from 'UI/PotagerButton';
import PotagerRow from 'UI/PotagerRow';
import PotagerCol from 'UI/PotagerCol';
import PotagerWave from 'UI/PotagerWave';

export default {

  components: {
    PotagerContainer,
    PotagerButton,
    PotagerRow,
    PotagerCol,
    PotagerWave,
  },

  data: () => ({
    items: [
      {
        icon: IconMultipleActionsStar,
        title: 'Nouveaux clients',
        text: 'Vous approchez de nouveaux clients sensibles à la qualité de ce qu\'ils mangent dans votre commerce',
      },
      {
        icon: IconGiftBox,
        title: 'Un panier offert par semaine*',
        text: 'Chaque semaine nous vous offrons jusqu\'à un panier “Fruits et Légumes” d’une valeur de  <b>17,90€*</b>',
      },
    ],
  }),

};
</script>
