<template>
  <div class="text-center">
    <h1 class="text-xl font-extrabold mb-3 mx-10">
      {{ getMessageTitle }} <span class="font-normal">{{ getMessageEmoji }}</span>
    </h1>

    <p
      class="mb-10"
      v-html="getMessageText" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('session', [
      'isLoggedIn',
      'getOacRecommendedBox',
      'isOacRecommendedRefNotEnough',
    ]),
    ...mapGetters('user', [
      'isSubscriptionActive',
    ]),
    getMessageTitle() {
      if (this.getOacRecommendedBox && this.isOacRecommendedRefNotEnough) return 'Quelle grande tribu vous avez';
      if (this.isLoggedIn && this.isSubscriptionActive) {
        return 'Ils sont beaux, ils sont frais : (re)découvrez les paniers';
      }
      return 'Tadaaaaam';
    },
    getMessageEmoji() {
      if (this.getOacRecommendedBox && this.isOacRecommendedRefNotEnough) return '😍';
      if (this.isLoggedIn && this.isSubscriptionActive) return '📢';
      return '🎉';
    },
    getMessageText() {
      if (this.getOacRecommendedBox && this.isOacRecommendedRefNotEnough) {
        return `
          Nous avons un panier fait pour vous en termes de composition, mais il est un peu petit
          pour être suffisant. <b>Notre conseil</b> : choisissez notre panier recommandé et complétez-le
          chaque semaine en faisant vos courses dans nos rayons de produits à l’unité.
        `;
      }
      if (this.getOacRecommendedBox) {
        return `
          Selon vos réponses, le panier qui vous correspond le mieux est le
          <b>${this.getOacRecommendedBox.name}</b>.<br>
          Et si ma recommandation personnalisée ne vous tente pas, découvrez
          les autres paniers composés par l’équipe !
        `;
      }
      if (this.isLoggedIn && this.isSubscriptionActive) {
        return `
          <b>Changez votre panier en abonnement</b> par l’un de nos autres paniers. Ils sont tous différents
          en composition ou en format, mais ont tous été composés avec amour, raison, et saison !
        `;
      }
      return `
        Découvrez tous nos paniers par ici et choisissez votre préféré ! Mélange de fruits et légumes,
        100% fruits, 100% légumes, il y en a pour tous les goûts.
      `;
    },
  },

};
</script>
