<template>
  <transition
    appear
    name="appear-from-bottom">
    <potager-link
      v-show="visible"
      v-wave="$mq.bp1024"
      :class="[
        'basket-preview',
        'sticky-element',
      ]"
      :event="$mq.bp1024 ? 'click' : ''"
      :style="{ bottom, position }"
      :tag="$mq.bp1024 ? 'a' : 'div'"
      :to="$mq.bp1024 ? basketRoute : '#'">
      <div class="basket-preview__container">

        <potager-container class="basket-preview__wrapper">
          <div class="basket-preview__products-wrapper">
            <p class="basket-preview__title">
              Panier
              <potager-badge
                class="ml-1"
                rounded
                size="small">
                {{ getProducts.length }}
              </potager-badge>
            </p>
            <ul class="basket-preview__products-list">
              <basket-preview-item
                v-for="(groupedBoxes, index) in getGroupedProductsLoop"
                :key="index"
                :grouped-boxes="groupedBoxes" />
            </ul>
            <button
              v-if="nbGroupedProducts > getGroupedProductsLoop.length"
              class="basket-preview__products__show-all"
              @click="redirectToBasket" />
          </div>
          <span class="basket-preview__btn-wrapper">
            <potager-tooltip
              v-if="!$mq.bp1024"
              closable
              disabled
              shadow
              spacing=".5rem"
              theme="dark"
              width="13rem">
              <potager-button
                label="Voir mon panier"
                theme="white"
                @onClick="redirectToBasket" />
              <template #message>
                Pensez à valider votre panier pour finaliser votre commande.
              </template>
            </potager-tooltip>
            <icon-arrow-right1
              v-else
              class="basket-preview__btn-wrapper__icon" />
          </span>
        </potager-container>
      </div>
    </potager-link>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';
import groupBy from 'lodash/groupBy';

import IconArrowRight1 from 'Icons/regular/IconArrowRight1';

import PotagerContainer from 'UI/PotagerContainer';
import PotagerButton from 'UI/PotagerButton';
import PotagerBadge from 'UI/PotagerBadge';
import PotagerTooltip from 'UI/PotagerTooltip';

import BasketPreviewItem from 'Components/basketPreview/BasketPreviewItem';

export default {

  components: {
    IconArrowRight1,
    PotagerContainer,
    PotagerButton,
    PotagerBadge,
    PotagerTooltip,
    BasketPreviewItem,
  },

  data: () => ({
    basketRoute: { name: 'basket' },
    footer: null,
    unsticky: false,
    showBasketMessage: true,
    ready: false,
    footerHeight: 0,
  }),

  props: {
    offset: {
      type: Number,
      required: false,
      default: 0,
    },
  },

  computed: {
    ...mapGetters('basket', [
      'getProducts',
    ]),
    visible() {
      return this.getProducts.length && this.ready;
    },
    getGroupedProducts() {
      return groupBy(this.getProducts, 'id');
    },
    getGroupedProductsLoop() {
      return Object.keys(this.getGroupedProducts)
        .slice(0, 5)
        .map((id) => this.getGroupedProducts[id]);
    },
    nbGroupedProducts() {
      return Object.keys(this.getGroupedProducts).length;
    },
    bottom() {
      const bottom = (this.unsticky ? this.footerHeight : this.offset);
      return `${bottom}px`;
    },
    position() {
      return this.unsticky ? 'absolute' : 'fixed';
    },
  },

  methods: {
    onScroll() {
      if (this.footer) {
        const rect = this.footer.getBoundingClientRect();
        const wh = window.innerHeight;
        this.unsticky = !(rect.top + this.offset >= wh);
        this.footerHeight = rect.height;
      }
    },
    redirectToBasket() {
      this.showBasketMessage = false;
      this.$router.push(this.basketRoute)
        .catch(() => {
        });
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.ready = true;
      this.footer = document.querySelector('.main-footer');
      this.onScroll();
      document.addEventListener('scroll', this.onScroll);
    });
  },

  beforeUnmount() {
    document.removeEventListener('scroll', this.onScroll);
  },

};
</script>
