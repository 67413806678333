<template>
  <div
    :class="{
      'stack-panels-layer': true,
      'stack-panels-layer--second-layer': secondary,
      'stack-panels-layer--first-layer' : !secondary,
    }"
    :style="{
      zIndex: secondary ? 1 : 2,
    }">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    secondary: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>
