import BoxGtmAdapter from './BoxGtmAdapter';

export default function addPaymentInfoGtmData(store) {
  const subscriptionBasketProduct = store.getters['subscriptionBasket/getCustomerSubscriptionProduct'];
  const products = subscriptionBasketProduct ? [subscriptionBasketProduct] : store.getters['basket/getProducts'];
  // if similar products are in the products, we need to merge them
  // with the quantity attribut of all similar products
  const productsMap = products
    .reduce((map, product) => {
      const { id } = product;
      const existingProduct = map.find((p) => p.id === id);
      if (existingProduct) {
        existingProduct.quantity += 1;
      } else {
        map.push({ ...product, quantity: 1 });
      }
      return map;
    }, []);

  return {
    value: subscriptionBasketProduct ? store.getters['subscriptionBasket/getTotal'] : store.getters['basket/getTotal'],
    items: productsMap.map((box) => new BoxGtmAdapter(store, { box })),
  };
}
