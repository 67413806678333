<template>
  <div class="flex flex-col items-center max-w-full cursor-auto max-h-[90%]">
    <potager-button
      v-if="canBeClosed"
      class="mb-5"
      is-circle
      @onClick="close">
      <template #icon>
        <icon-close class="w-3.5 h-3.5" />
      </template>
    </potager-button>

    <div
      :class="{
        'modal-container__content': true,
        'modal-container__content--is-large': isLarge,
        'modal-container__content--is-small': isSmall,
        'modal-container__content--is-video': isVideo,
      }">
      <header
        v-if="!isVideo && !withoutHeader"
        ref="header"
        :class="[{
          'modal-container__header': true,
          'modal-container__header--scrolled': isScrolled,
        }, headerExtraClasses]">
        <slot
          :close="close"
          name="header">
          <p class="modal-container__title text-lg font-bold">
            <slot name="title">
              {{ title }}
            </slot>
          </p>
        </slot>
      </header>

      <section
        :class="[
          'modal-container__body',
          {
            'modal-container__body--without-footer': withoutFooter || isVideo,
            'modal-container__body--without-header': withoutHeader || isVideo,
            'modal-container__body--without-padding': withoutPadding,
          }, bodyExtraClasses]"
        data-scroll="true">
        <div class="modal-container__body-inner">
          <slot
            :close="close"
            name="body">
            <span v-html="text" />
          </slot>
        </div>
      </section>

      <footer
        v-if="showFooter"
        ref="footer"
        :class="[{
          'modal-container__footer z-10 align-center justify-center': true,
          'modal-container__footer--has-large-buttons': hasLargeButtons,
        }, footerExtraClasses]">
        <div class="flex flex-col flex-wrap gap-4">
          <slot
            :close="close"
            name="footer">
            <potager-button
              v-if="canBeClosed"
              :theme="btnTheme"
              :to="btnRoute"
              full-width
              @onClick="onButtonClick">
              {{ btnLabel }}
            </potager-button>

            <p
              v-if="btnSuffix"
              class="text-xs.5 text-warm-grey mt-2 italic"
              v-html="btnSuffix" />
          </slot>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import GtmMixin from 'Mixins/GtmMixin';

import { stuckPage } from 'Classes/utils/ScrollUtils';
import { KEYCODE_ESCAPE } from 'Classes/Constants';

import IconClose from 'Icons/bold/IconClose';

import PotagerButton from 'UI/PotagerButton';
import { propsBuilder } from 'UI/Tools';

import Cookies from 'js-cookie';

export const props = {
  name: {
    type: String,
    required: false,
    default: undefined,
  },
  title: {
    type: String,
    required: false,
    default: 'Avertissement',
  },
  text: {
    type: String,
    required: false,
    default: '',
  },
  canBeClosed: {
    type: Boolean,
    required: false,
    default: true,
  },
  isLarge: {
    type: Boolean,
    required: false,
    default: false,
  },
  isSmall: {
    type: Boolean,
    required: false,
    default: false,
  },
  isVideo: {
    type: Boolean,
    required: false,
    default: false,
  },
  hasLargeButtons: {
    type: Boolean,
    required: false,
    default: false,
  },
  withoutFooter: {
    type: Boolean,
    required: false,
    default: false,
  },
  withoutHeader: {
    type: Boolean,
    required: false,
    default: false,
  },
  withoutPadding: {
    type: Boolean,
    required: false,
    default: false,
  },
  headerExtraClasses: {
    type: String,
    required: false,
    default: '',
  },
  bodyExtraClasses: {
    type: String,
    required: false,
    default: '',
  },
  footerExtraClasses: {
    type: String,
    required: false,
    default: '',
  },
  showOnceCookie: {
    type: Boolean,
    default: false,
  },
  showOnceExpiration: {
    type: Number,
    default: 365,
  },
  showIterationExpiration: {
    type: Number,
    default: 365,
  },
  btnCallback: {
    type: Function,
    required: false,
    default: () => {
    },
  },
  btnRoute: {
    type: [String, Object],
    required: false,
    default: undefined,
  },
  btnLabel: {
    type: String,
    required: false,
    default: 'J\'ai compris !',
  },
  btnTheme: {
    type: String,
    required: false,
    default: undefined,
  },
  btnSuffix: {
    type: String,
    required: false,
    default: undefined,
  },
};

export default {
  mixins: [
    GtmMixin,
  ],

  components: {
    IconClose,
    PotagerButton,
  },

  props: propsBuilder(props),
  emits: ['close'],

  data: () => ({
    isScrolled: false,
    hasScroll: false,
    footerHeight: 0,
    maxHeight: null,
  }),

  computed: {
    showOnceCondition() {
      if (!this.name && this.showOnceCookie) console.error('Modal must have an name for showOnceCookie to work');
      return this.showOnceCookie && this.name;
    },
    showFooter() {
      return !this.withoutFooter && !this.isVideo;
    },
  },

  watch: {
    '$potagerRoute.name'() {
      this.close();
    },
    '$mq.bp640': {
      handler() {
        this.calcMaxHeight();
      },
    },
    showFooter: {
      handler(showFooter) {
        this.$nextTick(() => {
          const bottomExtraPadding = 24;
          this.footerHeight = this.$refs.footer && showFooter ? this.$refs.footer.getBoundingClientRect().height + bottomExtraPadding : 0;
        });
      },
      immediate: true,
    },
  },

  methods: {
    onButtonClick() {
      if (this.btnCallback) this.btnCallback();
      this.close();
    },
    close() {
      if (this.canBeClosed) {
        this.$modal.close();
        this.trackModal('dismiss');
        this.$modal.close({ type: 'dismiss' });
        this.$emit('close', { type: 'dismiss' });
        const iterations = Cookies.get(`${this.name}-iteration`);
        Cookies.set(`${this.name}-iteration`, parseInt(iterations, 10) + 1, {
          expires: this.showIterationExpiration,
          sameSite: 'Lax'
        });
        if (this.showOnceCondition) {
          Cookies.set(this.name, true, {
            expires: this.showOnceExpiration,
            sameSite: 'Lax'
          });
        }
      }
    },
    onKeyUp(e) {
      if (e && e.keyCode === KEYCODE_ESCAPE && this.canBeClosed) {
        this.close();
      }
    },
    calcMaxHeight() {
      const {
        header,
        footer
      } = this.$refs;
      const FH = footer ? footer.getBoundingClientRect().height : 0;

      if (!this.$mq.bp640) {
        const HH = header ? header.getBoundingClientRect().height : 0;
        const offset = 96;
        this.maxHeight = window.innerHeight - HH - FH - offset;
      } else {
        this.maxHeight = null;
      }
    },
    trackModal(value) {
      const datas = this.$parent.tracking;
      if (datas?.name) {
        const {
          event,
          name,
          params
        } = datas;
        this.trackAction({
          event: event || 'Modal',
          name,
          value,
          params,
        });
      }
    },
  },

  mounted() {
    this.calcMaxHeight();
    this.trackModal('open');
    stuckPage();

    document.addEventListener('keyup', this.onKeyUp);

    if (!Cookies.get(`${this.name}-iteration`)) {
      Cookies.set(`${this.name}-iteration`, 0, {
        expires: 365,
        sameSite: 'Lax'
      });
    }
  },

  beforeUnmount() {
    if (this.$potagerRoute?.meta) {
      stuckPage((this.$potagerRoute.meta.isPanel || this.$potagerRoute.meta.isFlying) || false);
    } else {
      stuckPage(false);
    }

    document.removeEventListener('keyup', this.onKeyUp);
  },

};
</script>
