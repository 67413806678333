import {
  UPDATE_USER_SEARCH,
  RESET_USER_SEARCH,
  UPDATE_USER_POSITION,
  UPDATE_USER_ADDRESS,
  RESET_SEARCH_RESULT,
  UPDATE_SELECTED_DELIVERY_POINT,
  UPDATE_PUBLIC_SEARCH_RESULT,
  UPDATE_PRIVATE_SEARCH_RESULT,
  UPDATE_PUBLIC_DELIVERY_POINTS,
  TOGGLE_LIST_VISIBILITY,
  SET_FILTERS,
  UPDATE_FILTERS,
  UPDATE_WOOS_SCRIPT_LOADED,
  UPDATE_WOOS_MAP,
  UPDATE_WOOS_STORE_OVERLAY,
  UPDATE_WOOS_DATA,
  UPDATE_WOOS_SEARCH_RESULT,
} from 'Stores/types/mapMutationsTypes';

import {
  SEARCH_WOOS_DELIVERY_POINTS,
  SEARCH_PUBLICS_DELIVERY_POINTS,
  SEARCH_PRIVATES_DELIVERY_POINTS,
  LOCATE_USER,
  FETCH_DELIVERY_POINT,
  FETCH_PUBLIC_DELIVERY_POINTS,
  RESET_MAP,
} from 'Stores/types/mapActionsTypes';

import {
  DELIVERY_FUNCTIONAL_TYPE_PUBLIC,
  DELIVERY_FUNCTIONAL_TYPE_PRIVATE,
} from 'Classes/Constants';

import axios from 'axios';
import { geocodeLatLng } from 'Classes/Woosmap';

import { api } from 'Plugins/potagerApiClient';
import { notify } from '@kyvg/vue3-notification';
import { toRaw } from 'vue';

export const ALLOWED_ADDRESS_TYPES = [
  'premise',
  'street_address',
  'locality',
  'sublocality',
  'postal_code',
  'park',
  'route',
  'house_number',
  'address',
];

export default {

  namespaced: true,

  state: {
    userSearch: null, // contains user search and suggestions
    userPosition: null, // contains user position
    userAddress: null, // contains user selected address
    selectedDeliveryPoint: null, // contains selected delivery point
    filters: [2, 3, 4, 5]
      .map((e) => ({
        dayIndex: e,
        label: ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'][e],
        value: false,
      })),
    listVisible: true,
    woosScriptIsLoaded: undefined,
    woosMap: undefined,
    woosStoreOverlay: undefined,
    woosSearchResult: [], // list of stores from woosmap
    privateSearchResult: [], // list of searched stores. Retrieved from ptcy API
    publicSearchResult: [], // list of searched stores. Retrieved from ptcy API
    allDlpList: [], // list of ALL stores. Retrieved from ptcy API
  },

  getters: {
    getUserSearch: (state) => state.userSearch,
    getUserSearchSuggestions: (state) => state.userSearch?.suggestions || [],
    getUserPosition: (state) => state.userPosition,
    getUserAddress: (state) => state.userAddress,
    getSelectedDeliveryPoint: (state) => state.selectedDeliveryPoint,
    getFilters: (state) => state.filters,
    getDeliveryPointById: (state) => (regionId, id) => state.allDlpList
      .find((dp) => dp.id === Number(id) && dp.regionId === Number(regionId)),
    isListVisible: (state) => state.listVisible,

    getWoosScriptIsLoaded: (state) => state.woosScriptIsLoaded,
    getWoosMap: (state) => toRaw(state.woosMap),
    getWoosStoreOverlay: (state) => toRaw(state.woosStoreOverlay),
    getWoosData: (state) => toRaw(state.woosData),
    getWoosDlpList: (state) => state.woosSearchResult?.features || [],

    getPrivateSearchResult: (state) => state.privateSearchResult,
    getPublicSearchResult: (state) => state.publicSearchResult,

    // list of stores, mapping woosmap store id to ptcy store id
    getMapPublicSearchResult: (state, getters) => {
      const { getWoosDlpList } = getters;
      const {
        publicSearchResult,
        allDlpList,
      } = state;
      const filteredTags = state.filters
        .filter((filter) => filter.value)
        .map((filter) => filter.label);

      return getWoosDlpList
        .map((woosDlp) => {
          const [woosDlpRegionId, woosDlpId] = woosDlp.properties.store_id.split('_');
          const ptcyDlp = publicSearchResult.length ? publicSearchResult : allDlpList
            .find((dp) => dp.id === Number(woosDlpId) && dp.regionId === Number(woosDlpRegionId));

          return ptcyDlp ? {
            ...ptcyDlp,
            woosDlp,
          } : null;
        })
        .filter((dlp) => dlp)
        .filter((dlp) => !filteredTags.length || dlp.woosDlp.properties.tags
          .some((tag) => filteredTags
            .includes(tag)));
    },
  },

  mutations: {
    [UPDATE_WOOS_SCRIPT_LOADED](state, loaded) {
      state.woosScriptIsLoaded = loaded;
    },
    [UPDATE_WOOS_STORE_OVERLAY](state, overlay) {
      state.woosStoreOverlay = overlay;
    },
    [UPDATE_WOOS_DATA](state, data) {
      state.woosData = data;
    },
    [UPDATE_WOOS_SEARCH_RESULT](state, list) {
      state.woosSearchResult = list;
    },
    [UPDATE_USER_SEARCH](state, {
      search,
      suggestions = [],
    }) {
      const suggestionList = suggestions
        .filter((address) => {
          const { types } = address;
          return types.some((type) => ALLOWED_ADDRESS_TYPES.includes(type)) || address.store_id;
        });

      state.userSearch = {
        search,
        suggestions: suggestionList,
      };
    },
    [RESET_USER_SEARCH](state) {
      state.userSearch = null;
    },
    [UPDATE_USER_POSITION](state, coords) {
      state.userPosition = coords;
    },
    [UPDATE_USER_ADDRESS](state, address) {
      // we reset it before to trigger watchers when select address1 > store1 > address1
      state.userAddress = null;
      state.userAddress = address;
    },
    [RESET_SEARCH_RESULT](state) {
      state.woosSearchResult = [];
      state.publicSearchResult = [];
      state.privateSearchResult = [];
    },
    [UPDATE_SELECTED_DELIVERY_POINT](state, deliveryPoint) {
      // we reset it before to trigger watchers when select store1 > address1 > store1
      state.selectedDeliveryPoint = null;
      state.selectedDeliveryPoint = deliveryPoint;
    },
    [UPDATE_PUBLIC_SEARCH_RESULT](state, deliveryPoints = []) {
      state.publicSearchResult = deliveryPoints;
    },
    [UPDATE_PRIVATE_SEARCH_RESULT](state, deliveryPoints = []) {
      state.privateSearchResult = deliveryPoints;
    },
    [UPDATE_PUBLIC_DELIVERY_POINTS](state, deliveryPoints = []) {
      state.allDlpList = deliveryPoints;
    },
    [TOGGLE_LIST_VISIBILITY](state) {
      state.listVisible = !state.listVisible;
    },
    [SET_FILTERS](state, filters) {
      state.filters = filters;
    },
    [UPDATE_FILTERS](state, {
      dayIndex,
      value,
    }) {
      state.filters.forEach((filter) => {
        if (filter.dayIndex === dayIndex) {
          filter.value = value;
        }
      });

      // if all filters are true, we reset them
      if (state.filters.every((filter) => filter.value)) {
        state.filters.forEach((filter) => {
          filter.value = false;
        });
      }
    },
    [UPDATE_WOOS_MAP](state, map) {
      state.woosMap = map;
    },
  },

  actions: {
    [SEARCH_WOOS_DELIVERY_POINTS]({
      dispatch,
      commit,
    }, {
      lat,
      lng,
      radius,
      type,
      tag,
      city,
      country,
      name,
      idstore,
      user,
    }) {
      dispatch('wait/start', SEARCH_WOOS_DELIVERY_POINTS, { root: true });

      return new Promise((resolve, reject) => {
        axios
          .get('https://api.woosmap.com/stores/search', {
            params: {
              key: import.meta.env.VITE_WOOS_MAP_KEY,
              lat,
              lng,
              radius,
              type,
              tag,
              city,
              country,
              name,
              idstore,
              user,
            },
          })
          .then((response) => {
            commit(UPDATE_WOOS_SEARCH_RESULT, response.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          })
          .finally(() => {
            dispatch('wait/end', SEARCH_WOOS_DELIVERY_POINTS, { root: true });
          });
      });
    },
    [SEARCH_PUBLICS_DELIVERY_POINTS]({
      dispatch,
      commit,
    }, value) {
      return new Promise((resolve, reject) => {
        dispatch('wait/start', SEARCH_PUBLICS_DELIVERY_POINTS, { root: true });
        api.deliveryPoints.search({
          term: value,
          type: DELIVERY_FUNCTIONAL_TYPE_PUBLIC,
        })
          .then(({ data }) => {
            commit(UPDATE_PUBLIC_SEARCH_RESULT, data.map((dlp) => ({
              ...dlp.item,
              ...{ type: 'dlp' },
            })));

            resolve(data.map((dlp) => ({
              ...dlp.item,
              ...{ type: 'dlp' },
            })));
          })
          .catch(() => {
            reject(new Error('Une erreur est survenue'));
          })
          .finally(() => {
            dispatch('wait/end', SEARCH_PUBLICS_DELIVERY_POINTS, { root: true });
          });
      });
    },
    [SEARCH_PRIVATES_DELIVERY_POINTS]({
      dispatch,
      commit,
    }, value) {
      return new Promise((resolve, reject) => {
        dispatch('wait/start', SEARCH_PRIVATES_DELIVERY_POINTS, { root: true });
        api.deliveryPoints.search({
          term: value,
          type: DELIVERY_FUNCTIONAL_TYPE_PRIVATE,
        })
          .then(({ data }) => {
            commit(UPDATE_PRIVATE_SEARCH_RESULT, data.map((dlp) => ({
              ...dlp.item,
              ...{ type: 'dlp' },
            })));

            resolve(data.map((dlp) => ({
              ...dlp.item,
              ...{ type: 'dlp' },
            })));
          })
          .catch(() => {
            reject(new Error('Une erreur est survenue'));
          })
          .finally(() => {
            dispatch('wait/end', SEARCH_PRIVATES_DELIVERY_POINTS, { root: true });
          });
      });
    },
    [FETCH_DELIVERY_POINT]({
      dispatch,
      commit,
    }, {
      regionId,
      id,
    }) {
      return new Promise((resolve, reject) => {
        dispatch('wait/start', FETCH_DELIVERY_POINT, { root: true });
        api.deliveryPoints.findOneById(regionId, id)
          .then((response) => {
            commit(UPDATE_SELECTED_DELIVERY_POINT, response.data);
            resolve(response.data);
          })
          .catch(reject)
          .finally(() => {
            dispatch('wait/end', FETCH_DELIVERY_POINT, { root: true });
          });
      });
    },
    [FETCH_PUBLIC_DELIVERY_POINTS]({
      dispatch,
      commit,
    }) {
      return new Promise((resolve, reject) => {
        dispatch('wait/start', FETCH_PUBLIC_DELIVERY_POINTS, { root: true });

        api.deliveryPoints.findAllPublic()
          .then((data) => {
            commit(UPDATE_PUBLIC_DELIVERY_POINTS, data.data);
            resolve(data.data);
          })
          .catch((err) => {
            reject(new Error(err));
          })
          .finally(() => {
            dispatch('wait/end', FETCH_PUBLIC_DELIVERY_POINTS, { root: true });
          });
      });
    },
    [LOCATE_USER]({
      dispatch,
      commit,
    }, {
      geocodeAddress,
      navigatorLocalisation,
    }) {
      return new Promise((resolve, reject) => {
        let userPosition;

        const setUserPosition = (latitude, longitude) => {
          if (geocodeAddress) {
            geocodeLatLng(latitude, longitude)
              .then((address) => {
                userPosition = address;
                commit(UPDATE_USER_POSITION, userPosition);
                resolve(userPosition);
              })
              .catch((err) => {
                reject(err);
              });
          } else {
            userPosition = {
              geometry: {
                location: {
                  lat: latitude,
                  lng: longitude,
                },
              },
            };
            commit(UPDATE_USER_POSITION, userPosition);
            resolve(userPosition);
          }
        };

        const woosGeolocate = () => new Promise((res, rej) => {
          dispatch('wait/start', LOCATE_USER, { root: true });

          axios.get('https://api.woosmap.com/geolocation/position', {
            params: {
              key: import.meta.env.VITE_WOOS_MAP_KEY,
            },
          })
            .then((resp) => {
              const {
                latitude,
                longitude,
              } = resp.data;
              setUserPosition(latitude, longitude);
              res(resp);
            })
            .catch((error) => {
              console.error(error);
              rej(error);
              reject(error);
            })
            .finally(() => {
              dispatch('wait/end', LOCATE_USER, { root: true });
            });
        });

        const navigatorGeolocate = () => new Promise(() => {
          dispatch('wait/start', LOCATE_USER, { root: true });

          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                setUserPosition(position.coords.latitude, position.coords.longitude);
                dispatch('wait/end', LOCATE_USER, { root: true });
              },
              (error) => {
                console.info(error?.message);

                notify({
                  title: 'Avertissement',
                  text: 'Nous ne pouvons pas vous localiser précisément car vous n\'avez pas autorisé la localisation dans les paramètres de votre navigateur.<br><b>Changez votre configuration et réessayez ou entrez directement une adresse dans le champ de recherche.</b>',
                  type: 'warning',
                  duration: 12000,
                });

                woosGeolocate();
              },
              {
                enableHighAccuracy: true,
                maximumAge: 0,
                timeout: 5000,
              },
            );
          } else {
            woosGeolocate();
          }
        });

        if (navigatorLocalisation) {
          navigatorGeolocate();
        } else {
          woosGeolocate();
        }
      });
    },
    [RESET_MAP]({
      commit,
    }) {
      commit(UPDATE_WOOS_MAP, undefined);
      commit(UPDATE_WOOS_STORE_OVERLAY, undefined);
      commit(UPDATE_WOOS_DATA, undefined);
      commit(UPDATE_WOOS_SCRIPT_LOADED, undefined);
    },
  },
};
