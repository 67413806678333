<template>
  <timeslots-list
    :skeleton="!isUserLoaded"
    :time-slots="isUserLoaded ? getHistoricalOrders : [1, 2, 3]" />
</template>

<script>
import { mapGetters } from 'vuex';
import TimeslotsList from 'Components/timeSlots/TimeSlotsList';

export default {
  name: 'HistoricalOrdersTimeSlotsList',
  components: { TimeslotsList },
  computed: {
    ...mapGetters('user', [
      'getHistoricalOrders',
      'isUserLoaded',
    ]),
  },
};
</script>
