<template>
  <tunnel-body class="text-center">
    <h1 class="text-xl font-extrabold mb-3 mx-10">
      Mot de passe oublié ?
    </h1>

    <p class="mb-10">
      Pas de panique ! ✋<br>
      Nous allons vous envoyer un email afin que vous puissiez en générer un tout neuf&nbsp;😉
    </p>

    <div class="max-w-640 w-full mx-auto">
      <potager-form
        id="forgot-password"
        @onSubmit="sendEmailPassword">
        <potager-input
          v-model="emailPassword"
          :constraints="[
            { constraint: 'required', field: 'emailPassword' },
            { constraint: 'email', field: 'emailPassword' },
          ]"
          label="Adresse e-mail"
          name="emailPassword"
          type="email" />
        <potager-button
          :is-loading="isLoading"
          full-width
          theme="go-green"
          type="submit">
          Envoyer
        </potager-button>
      </potager-form>
    </div>
  </tunnel-body>
</template>

<script>
import { mapGetters } from 'vuex';
import { REQUEST_FORGOT_PASSWORD_EMAIL_ACTION, RESET_PASSWORD_ACTION } from 'Stores/types/sessionActionsTypes';

import TunnelBody from 'Components/tunnel/TunnelBody';

import PotagerButton from 'UI/PotagerButton';
import PotagerForm from 'UI/Form/PotagerForm';
import PotagerInput from 'UI/Form/PotagerInput';

import MetaInfosService from 'Classes/services/MetaInfoService';
import ModalInfo from 'Modals/ModalInfo';

export default {

  components: {
    TunnelBody,
    PotagerButton,
    PotagerForm,
    PotagerInput,
  },

  data: () => ({
    emailPassword: '',
  }),

  computed: {
    ...mapGetters('temporaryCustomer', [
      'getTemporaryCustomerId',
    ]),
    isLoading() {
      return this.$wait.is([
        REQUEST_FORGOT_PASSWORD_EMAIL_ACTION,
        RESET_PASSWORD_ACTION,
      ]);
    },
  },

  methods: {
    sendEmailPassword() {
      this.$store.dispatch(`session/${REQUEST_FORGOT_PASSWORD_EMAIL_ACTION}`, {
        emailPassword: this.emailPassword,
        temporaryCustomerId: this.getTemporaryCustomerId,
        from: null,
        resetPasswordUrl: null,
      })
        .then(() => {
          this.$modal.open(ModalInfo, {
            title: 'Consultez vos emails !',
            text: 'Pour vérifier votre identité, nous vous avons envoyé un email où vous pourrez définir un nouveau mot de passe. Cet email arrive parfois dans les spams, vérifiez bien votre dossier de spams si vous ne l’apercevez pas dans votre boite de réception.',
          });
        });
    },
  },

  head: MetaInfosService.generate({
    title: 'Entrez votre adresse email pour réinitialiser votre mot de passe',
  }),
};
</script>
