import { mapGetters, mapMutations } from 'vuex';
import {
  UPDATE_DELIVERY_POINT_ORDER_ACTION,
  UPDATE_DELIVERY_POINT_SUBCRIPTION_ACTION,
} from 'Stores/types/userActionsTypes';
import { UPDATE_DELIVERY_POINT_BASKET_ACTION } from 'Stores/types/basketActionsTypes';
import { CONFIRM_SUBSCRIPTION_BASKET_ACTION } from 'Stores/types/subscriptionBasketActionsTypes';

import { UPDATE_SELECTED_DELIVERY_POINT } from 'Stores/types/mapMutationsTypes';

import { POPIN_ERROR_EVENT } from 'Classes/Constants';
import { ORD0016 } from 'Classes/error-handler/ErrorsMapper';
import { ABONNEMENT, CANCELLED } from 'Classes/OrderStatus';
import { convertAddressToParameter } from 'Classes/utils/DlpUtils';
import { capitalize } from 'Classes/utils/WordingUtils';
import { tmsToFormat } from 'Classes/utils/DatesUtils';
import { getRouteParams } from 'Classes/utils/RouteUtils';

import ModalOrderPdlChangeOutOfStock from 'Modals/ModalOrderPdlChangeOutOfStock';

import SubscriptionModalMixin from 'Mixins/SubscriptionModalMixin';
import DlpUpdateMixin from 'Mixins/DlpUpdateMixin';
import gtmMixin from 'Mixins/GtmMixin';
import PaymentMethodsMixin from 'Mixins/payments/PaymentMethodsMixin';

import ModalInfo from 'Modals/ModalInfo';

import { events } from 'Plugins/potagerEvents';
import { Context, SubscriptionContexts } from 'potagerlogic/dist/Enums/Context';

export default {
  mixins: [
    SubscriptionModalMixin,
    DlpUpdateMixin,
    PaymentMethodsMixin,
    gtmMixin,
  ],
  computed: {
    ...mapGetters('session', [
      'getDeliveryPoint',
      'getAddress',
    ]),
    ...mapGetters('homeDelivery', {
      getEditingAddress: 'getAddress',
    }),
    ...mapGetters('map', [
      'getSelectedDeliveryPoint',
    ]),
    ...mapGetters('user', [
      'getOrders',
      'getOrderById',
    ]),
    contextualDeliveryPoint() {
      return this.getSelectedDeliveryPoint || this.getDeliveryPoint(this.context);
    },
    contextualAddress() {
      return this.getEditingAddress || this.getAddress(this.context);
    },
  },
  methods: {
    ...mapMutations('map', {
      UPDATE_SELECTED_DELIVERY_POINT,
    }),
    gddSubmit(gdd, context) {
      const getSubmitFn = () => {
        switch (context) {
          case Context.Basket:
          case Context.User:
            return this.submitBasket;
          case Context.Order:
            return this.submitOrder;
          case Context.Subscription:
            return this.submitSubscription;
          default:
            return this.submitFirstSubscription;
        }
      };

      const address = this.contextualDeliveryPoint?.isHomeDelivery
        ? convertAddressToParameter(this.contextualAddress)
        : null;

      getSubmitFn()({
        gdd,
        address,
      })
        .then(() => {
          this.UPDATE_SELECTED_DELIVERY_POINT(null);

          if (this.contextualDeliveryPoint?.isHomeDelivery) {
            // Tracking
            const timeSlot = SubscriptionContexts.includes(this.context?.toUpperCase()) ? gdd.timeSlot : gdd.currentWeekTheoricalTimeSlot;
            const { date } = timeSlot;

            this.trackAction({
              event: 'homeDeliveryDay',
              params: {
                day: capitalize(tmsToFormat(date, 'iiii')),
              },
            });
          }
        })
        .catch((errors) => {
          console.error(errors);

          if (errors.response?.status === 404) {
            this.showNotFoundSubscriptionForRegion();
          } else if (errors.response?.status === 403) {
            this.showUserNotInRegion();
          }
        });
    },
    submitBasket({
      gdd,
      address,
    }) {
      const {
        regionId,
        id: groupDeliveryDayId,
      } = gdd;
      return new Promise((resolve, reject) => {
        this.$store.dispatch(`basket/${UPDATE_DELIVERY_POINT_BASKET_ACTION}`, {
          regionId,
          groupDeliveryDayId,
          address,
        })
          .then(() => {
            this.onSuccess({ gdd });
            resolve();
          })
          .catch(reject);
      });
    },
    submitOrder({
      gdd,
      address,
    }) {
      const {
        regionId,
        id: groupDeliveryDayId,
      } = gdd;
      const orderId = getRouteParams(this.$route)?.orderId;
      const callApi = (simulation) => this.$store.dispatch(`user/${UPDATE_DELIVERY_POINT_ORDER_ACTION}`, {
        orderId,
        regionId,
        groupDeliveryDayId,
        simulation,
        address,
      });

      return new Promise((resolve, reject) => {
        callApi(true)
          .then((resp) => {
            const { warning } = resp.data;
            const callApiForReal = () => callApi(false)
              .then(() => {
                this.onSuccess({ gdd });
                resolve();

                this.$nextTick(() => {
                  const newOrder = this.getOrderById(orderId);
                  this.notifyOrderStatus(newOrder, this.context, false);
                });
              });

            if (!warning.length) {
              callApiForReal();
            } else {
              const products = warning.map((warn) => (warn.code === ORD0016 ? warn.metadata : null));
              const order = this.getOrders.filter((o) => o.id === parseInt(orderId, 10))?.[0];

              events.emit(POPIN_ERROR_EVENT, {
                component: ModalOrderPdlChangeOutOfStock,
                order,
                products,
                onConfirmed: callApiForReal,
              });
            }
          })
          .catch(reject);
      });
    },
    submitSubscription({
      gdd,
      address,
    }) {
      const {
        regionId,
        id: groupDeliveryDayId,
        isTooLate,
        isNextDeliveryAvailable,
      } = gdd;

      return new Promise((resolve, reject) => {
        this.$store.dispatch(`user/${UPDATE_DELIVERY_POINT_SUBCRIPTION_ACTION}`, {
          regionId,
          groupDeliveryDayId,
          address,
        })
          .then((resp) => {
            const editableOrders = resp.data.data
              ?.orders
              .filter((order) => order.canal === ABONNEMENT
                && !order.isLimiteDateExpired
                && order.status !== CANCELLED);

            if (editableOrders.length && !(!isTooLate && isNextDeliveryAvailable)) {
              this.$modal.open(ModalInfo, {
                title: 'Avertissement',
                text: `Le point de retrait lié à votre abonnement a bien été modifié et sera pris en
                  compte pour vos prochaines commandes. Nous vous informons néanmoins que cette modification
                  n’a pas été appliquée sur votre commande en cours.`,
                closeBtnText: 'Voir mes commandes en cours',
                onCloseRedirect: { name: 'mon-compte_orders' },
              });
              this.onSuccess({ gdd });
              resolve();
            } else {
              this.onSuccess({ gdd });
              resolve();
            }
          })
          .catch(reject);
      });
    },
    submitFirstSubscription({
      gdd,
      address,
    }) {
      const {
        regionId,
        id: groupDeliveryDayId,
      } = gdd;

      return new Promise((resolve, reject) => {
        this.$store.dispatch(`subscriptionBasket/${CONFIRM_SUBSCRIPTION_BASKET_ACTION}`, {
          regionId,
          groupDeliveryDayId,
          address,
        })
          .then(() => {
            this.onSuccess({ gdd });
            resolve();
          })
          .catch(reject);
      });
    },
    onSuccess({ gdd }) {
      let type;
      if (this.contextualDeliveryPoint?.isHomeDelivery) {
        type = 'homeDelivery';
      } else if (this.contextualDeliveryPoint?.private) {
        type = 'PrivatePoint';
      } else {
        type = 'PublicPoint';
      }

      this.trackDeliveryInfo({ type });

      this.$emit('onGddSubmit', {
        updateOthersDlps: this.canUpdateSomething && this.canUpdateBasket,
        dlpId: this.contextualDeliveryPoint?.id,
        gddId: gdd?.id,
      });
    },
  },
};
