import { UPDATE_USER } from 'Stores/types/userMutationsTypes';
import { UPDATE_BASKET } from 'Stores/types/basketMutationsTypes';

import {
  REMOVE_PRODUCT_SHADOW_BASKET_ACTION,
  CLEAR_SHADOW_BASKET_ACTION,
  CONFIRM_SHADOW_BASKET_ACTION,
  REPLACE_SUBSCRIPTION_PRODUCT,
} from 'Stores/types/shadowBasketActionsTypes';

import { api }  from 'Plugins/potagerApiClient';

export default {

  namespaced: true,

  actions: {
    [REMOVE_PRODUCT_SHADOW_BASKET_ACTION]({ dispatch }, { productId, orderId }) {
      dispatch('wait/start', REMOVE_PRODUCT_SHADOW_BASKET_ACTION, { root: true });
      return new Promise((resolve, reject) => api.shadowbasket.removeAllProducts(productId, orderId)
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error))
        .finally(() => dispatch('wait/end', REMOVE_PRODUCT_SHADOW_BASKET_ACTION, { root: true })));
    },
    [CLEAR_SHADOW_BASKET_ACTION]({ dispatch }) {
      dispatch('wait/start', CLEAR_SHADOW_BASKET_ACTION, { root: true });
      return new Promise((resolve, reject) => api.shadowbasket.clear()
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error))
        .finally(() => dispatch('wait/end', CLEAR_SHADOW_BASKET_ACTION, { root: true })));
    },
    [CONFIRM_SHADOW_BASKET_ACTION]({ commit, dispatch }) {
      dispatch('wait/start', CONFIRM_SHADOW_BASKET_ACTION, { root: true });
      return new Promise((resolve, reject) => api.shadowbasket.confirm()
        .then(({ data }) => {
          commit(`user/${UPDATE_USER}`, data.data, { root: true });
          commit(`basket/${UPDATE_BASKET}`, data.data.basket, { root: true });
          resolve(data.data.orders);
        })
        .catch((error) => reject(error))
        .finally(() => dispatch('wait/end', CONFIRM_SHADOW_BASKET_ACTION, { root: true })));
    },
    [REPLACE_SUBSCRIPTION_PRODUCT]({ dispatch }, { productIdToReplace, productId, orderId }) {
      dispatch('wait/start', REPLACE_SUBSCRIPTION_PRODUCT, { root: true });
      dispatch('wait/start', `${REPLACE_SUBSCRIPTION_PRODUCT}_${productId}`, { root: true });
      return new Promise((resolve, reject) => api.shadowbasket.replaceProduct(productIdToReplace, productId, orderId)
        .then(({ data }) => resolve(data.data))
        .catch((error) => reject(error))
        .finally(() => {
          dispatch('wait/end', REPLACE_SUBSCRIPTION_PRODUCT, { root: true });
          dispatch('wait/end', `${REPLACE_SUBSCRIPTION_PRODUCT}_${productId}`, { root: true });
        }));
    },
  },
};
