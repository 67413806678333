<template>
  <div class="product-list">
    <ul
      v-if="products"
      class="product-list__wrapper">
      <li
        v-for="(product, index) in products"
        :key="index"
        class="product-list__item">
        <potager-link
          :class="{
            'product-list__item__name': true,
            'product-list__item__name--is-link': product.link && !disableLink,
          }"
          :event="product.link && !disableLink ? 'click' : ''"
          :tag="product.link && !disableLink ? 'a' : 'span'"
          :target="target"
          :to="getProductLink(product)">
          {{ product.name }}
        </potager-link>

        <potager-badge
          v-if="product.isBio"
          class="ml-2"
          size="small"
          theme="forest">
          <template #icon>
            <icon-ptcy-bio />
          </template>
          Bio
        </potager-badge>

        <span
          v-if="getProductQuantity(product)"
          class="product-list__item__quantity">
          : {{ getProductQuantity(product) }}
        </span>

        <p
          v-if="product.supplier"
          class="product-list__item__origin">
          <potager-link
            :target="target"
            :to="getSupplierLink(product)">
            <span v-html="getSupplierName(product)" />
          </potager-link>
          {{ getOrigin(product) }}
        </p>

        <p
          v-if="getProductCaliber(product)"
          class="product-list__item__calibers">
          {{ getProductCaliber(product) }}
        </p>

        <ul
          v-if="product.specificities && product.specificities.length"
          class="product-list__item__specificities">
          <li
            v-for="(spec, i) in product.specificities"
            :key="i"
            class="product-list__item__specificities__item">
            {{ spec }}
          </li>
        </ul>
      </li>
    </ul>

    <template v-if="showMentions">
      <p
        v-if="showMentionCategory"
        class="product-list__legend">
        {{ mentionCategory }}
      </p>
      <p
        v-if="showMentionWeight"
        class="product-list__legend">
        {{ mentionWeight }}
      </p>
    </template>

    <potager-button
      v-if="hasMentions"
      :label="`${showMentions ? 'Masquer' : 'Afficher'} les informations complémentaires`"
      class="product-list__toggle-specificities"
      size="small"
      theme="stroke-grey"
      @onClick="showMentions = !showMentions" />
  </div>
</template>

<script>
import IconPtcyBio from 'Icons/regular/IconPtcyBio';

import PotagerBadge from 'UI/PotagerBadge';
import PotagerButton from 'UI/PotagerButton';

import { getProductCaliber, getProductQuantity } from 'Classes/utils/ProductUtils';
import { getResolvedUrl, calcSlug } from 'Classes/utils/RouteUtils';
import { WORDINGS } from 'Classes/Wordings';

export default {

  components: {
    IconPtcyBio,
    PotagerBadge,
    PotagerButton,
  },

  data: () => ({
    showMentions: false,
    mentionCategory: WORDINGS.MENTION_CATEGORY,
    mentionWeight: WORDINGS.MENTION_WEIGHT,
  }),

  props: {
    products: {
      type: Array,
      required: false,
      default: null,
    },
    showMentionCategory: {
      type: Boolean,
      required: false,
      default: false,
    },
    showMentionWeight: {
      type: Boolean,
      required: false,
      default: false,
    },
    target: {
      type: String,
      required: false,
      default: '',
    },
    flyingPage: {
      type: Boolean,
      required: false,
      default: false,
    },
    orderId: {
      type: Number,
      required: false,
      default: null,
    },
    disableLink: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    hasMentions() {
      return this.showMentionCategory || this.showMentionWeight;
    },
  },

  methods: {
    getProductQuantity,
    getProductCaliber,
    getProductLink(product) {
      return product.link && !this.disableLink ? {
        name: this.flyingPage ? 'variety-detail-item' : 'produits',
        params: {
          slug: calcSlug(product.link.title || product.name),
          id: product.link.id,
          orderId: this.flyingPage && this.orderId ? this.orderId : null,
        },
      } : {};
    },
    getSupplierLink(product) {
      if (product.supplier.hasArticle && !this.disableLink) {
        if (product.supplier.id) {
          return getResolvedUrl('producteurs', {
            slug: calcSlug(product.supplier.title),
            id: product.supplier.id,
          });
        }
        return getResolvedUrl('suppliers-list');
      } else {
        return null;
      }
    },
    getSupplierName(product) {
      return product.supplier ? product.supplier.substituteName || product.supplier.firstname : null;
    },
    getOrigin(product) {
      if (product.origin) {
        // revert on https://potagercity.atlassian.net/jira/software/c/projects/PTCY/issues/PTCY-3913
        // if (!this.showMentions) {
        //   return `, ${product.origin.split(', ').slice(-1)[0]}`;
        // }
        return `, ${product.origin}`;
      }
      return '';
    },
  },

};
</script>
