export default function gtmPlugin(app) {
  return (store) => {
    store.subscribe((mutation) => {
      if (!mutation.payload || !mutation.payload.meta) {
        return;
      }

      const track = mutation.payload.meta.gtm;
      try {
        const gtm = app?.config?.globalProperties?.$gtm;
        if (track && gtm) {
          gtm.trackEvent(track);
        } else if (!gtm && track) {
          console.warn('GTM not found to track :', track);
        }
      } catch (e) {
        throw new Error(`Error with gtm.trackEvent: ${e}`);
      }
    });
  };
}
