// USAGE:
// v-gtm
// v-gtm="Survey"
// v-gtm="'annulation de commande'"
// v-gtm="{ prefix: 'prefix', label: 'annulation de commande', ...Survey }"
import { useGtm } from '@gtm-support/vue-gtm';
const gtmMap = new Map();

class Gtm {
  constructor(el, binding) {
    this.el = el;
    this.$gtm = useGtm();
    this.update(binding);
  }

  update(binding) {
    this.binding = binding;
  }

  getLabel() {
    let label = '';
    let prefix = '';

    if (this.binding.value) {
      if (typeof this.binding.value === 'string') {
        label = this.binding.value;
      } else if (typeof this.binding.value === 'object') {
        const { label: lbl, prefix: pre } = this.binding.value;
        label = lbl || this.el.textContent || '';
        prefix = pre ? `${pre} ` : '';
      }
    } else {
      label = this.el.textContent || this.binding?.value || '';
    }

    return `${prefix}${label.replace(/[\n\t\r ]+/g, ' ').trim().toLowerCase()}`;
  }

  getRoute() {
    return this.el?.href || '';
  }

  getExtraData() {
    if (this.binding.value && typeof this.binding.value === 'object') {
      const { label, prefix, ...extraData } = this.binding?.value;
      return extraData;
    }
    return '';
  }

  sendToGtm(binding) {
    try {
      const fields = {
        ...(this.getLabel() && { label: this.getLabel() }),
        ...(this.getRoute() && { route: this.getRoute() }),
        ...(this.getExtraData() && this.getExtraData()),
      };

      this.$gtm.trackEvent({
        event: binding.value?.event || 'clickAction',
        ...fields,
      });
    } catch (e) {
      console.error('Failed to send to GTM:', e);
    }
  }
}

export default {
  beforeMount(el, binding, vnode) {
    const gtm = new Gtm(el, binding, vnode);
    const fn = (e) => {
      gtm.sendToGtm(binding);
      e.stopPropagation();
    };

    el.addEventListener('click', fn);

    // Store Gtm instance and event listener function in the map
    gtmMap.set(el, { gtm, fn });
  },

  updated(el, binding) {
    const { gtm } = gtmMap.get(el) || {};
    if (gtm) gtm.update(binding);
  },

  unbind(el) {
    const { fn } = gtmMap.get(el) || {};
    if (fn) el.removeEventListener('click', fn);

    // Remove instance and function from map
    gtmMap.delete(el);
  },
};
