<template>
  <section class="product-detail__section">
    <div
      v-if="box.loading"
      class="product-detail__title h-8 w-1/3 skeleton" />
    <h1
      v-else
      class="product-detail__title">
      {{ box.name }}
    </h1>

    <div
      v-if="box.loading"
      class="product-detail__baseline product-detail__associated-content-wrapper h-8 w-1/4 skeleton" />
    <div
      v-else-if="baseline"
      class="product-detail__baseline product-detail__associated-content-wrapper">
      <p v-html="baseline" />
    </div>

    <p
      v-if="box.loading"
      class="product-detail__description h-8 w-3/4 skeleton" />
    <template v-else>
      <p
        v-for="(paragraph, index) in box.descriptionOfWeek.split('\r\n')"
        :key="index"
        class="product-detail__description"
        v-html="paragraph" />
    </template>
  </section>
</template>

<script>
import { getWeightAndBoxPeopleQuantity } from 'Classes/utils/BoxesUtils';
import { pluralize } from 'Classes/utils/WordingUtils';
import ToolsMixin from 'Mixins/ToolsMixin';

export default {
  mixins: [
    ToolsMixin,
  ],

  props: {
    box: {
      type: Object,
      required: true,
    },
  },

  computed: {
    baseline() {
      return this.box && getWeightAndBoxPeopleQuantity(this.box, { format: 'long' });
    },
  },

  methods: {
    pluralize,
  },
};
</script>
