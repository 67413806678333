import axios from 'axios';

export const geocodeLatLng = (lat, lng) => new Promise((resolve, reject) => {
  axios.get('https://api.woosmap.com/address/geocode/json', {
    params: {
      key: import.meta.env.VITE_WOOS_MAP_KEY,
      latlng: `${lat},${lng}`,
    },
  })
    .then((geocode) => {
      const { status, results } = geocode.data;
      if (status === 'OK') {
        resolve(results[0]);
      } else {
        console.error('geocode status : ', status);
        reject(new Error('geocode status : ', status));
      }
    })
    .catch((error) => {
      console.error(error);
      reject(error);
    });
});

export const fetchLocalitiesDetails = (publicId) => new Promise((resolve, reject) => {
  axios.get('https://api.woosmap.com/localities/details', {
    params: {
      key: import.meta.env.VITE_WOOS_MAP_KEY,
      public_id: publicId,
    },
  })
    .then((resp) => {
      const { status, statusText, data } = resp;
      if (status === 200) {
        resolve(data.result);
      } else {
        console.error('geocode status : ', status, statusText);
        reject(new Error('geocode status : ', status, statusText));
      }
    })
    .catch((error) => {
      console.error(error);
      reject(error);
    });
});
