import { POPIN_ERROR_EVENT } from 'Classes/Constants';

export default {
  default: {
    eventType: POPIN_ERROR_EVENT,
    title: 'Avertissement',
    text: `
      Une erreur a été rencontrée lors de la validation. Veuillez réessayer plus tard ou
      <a href="https://www.potagercity.fr/contact">contacter le service client</a> si le problème persiste.
    `,
  },
};
