import { routerResolve } from 'Services/routerServices';
import slugify from '@sindresorhus/slugify';

/**
 * Parses a given URL string and returns a URL object.
 *
 * @param {string} url - The URL string to parse.
 * @returns {URL|boolean} - Returns a URL object if the URL is valid, otherwise false.
 */
export const getUrlAttributes = (url) => {
  try {
    return new URL(url);
  } catch (_) {
    return false;
  }
};

/**
 * Retrieves the value of a specified URL parameter.
 *
 * @param {string} value - The name of the URL parameter to retrieve.
 * @returns {string|null} - The value of the URL parameter, or null if not found.
 */
export const getUrlParameter = (value) => {
  const name = value.replace(/[\[]/, '\\[')
    .replace(/[\]]/, '\\]');
  const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
  const results = regex.exec(document.location.search);
  return (results === null) ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

/**
 * Checks if the given URL uses the HTTP or HTTPS protocol.
 *
 * @param {string} url - The URL to check.
 * @returns {boolean} - Returns true if the URL uses HTTP or HTTPS, otherwise false.
 */
export const isHttpUrl = (url) => {
  const urlObj = getUrlAttributes(url);
  return urlObj?.protocol === 'http:' || urlObj?.protocol === 'https:';
};

/**
 * Extracts the domain from a given URL, removing the 'www.' prefix if present.
 *
 * @param {string} url - The URL from which to extract the domain.
 * @returns {string|undefined} - The domain of the URL, or undefined if the URL is invalid.
 */
export const getUrlDomain = (url) => {
  const urlObj = getUrlAttributes(url);
  return urlObj?.hostname.replace('www.', '');
};

/**
 * Checks if the given URL belongs to the same domain as the current window location.
 *
 * @param {string} url - The URL to check.
 * @returns {boolean} - Returns true if the URL is from the same domain, otherwise false.
 */
export const isSameDomain = (url) => url && (!isHttpUrl(url) || getUrlDomain(window.location.href) === getUrlDomain(url));

/**
 * Checks if a given URL is an external link.
 *
 * @param {string} url - The URL to check.
 * @returns {boolean} - Returns true if the URL is external, otherwise false.
 */
export const isExternalLink = (url) => isHttpUrl(url) && !isSameDomain(url);

/**
 * Generates a resolved URL based on the given route name and parameters.
 *
 * @param {string} name - The name of the route to resolve.
 * @param {Object} [params={}] - The parameters to include in the route.
 * @param {boolean} [absolute=true] - Whether to generate an absolute URL.
 * @returns {string} - The resolved URL.
 */
export const getResolvedUrl = (name, params = {}, absolute = true) => {
  const { origin } = document.location;
  const route = routerResolve({
    name,
    params
  });
  let url = absolute ? origin : '';
  url += route.meta.isPanel ? '#' : '';
  url += route.href;
  return url;
};

/**
 * Converts a given string to a URL-friendly slug.
 *
 * @param {string} toSlug - The string to be converted to a slug.
 * @returns {string} - The generated slug or an empty string if the input is falsy.
 */
export const calcSlug = (toSlug) => {
  if (toSlug) {
    let to = toSlug.toLowerCase();
    to = to.replace(/&/g, 'et');
    return slugify(to);
  }
  return '';
};

/**
 * This method retrieves the vue-router route based on the item.
 *
 * @param {Object} item - The item object containing details for generating the link.
 * @param {string} [orderId] - The order ID to include in the link parameters.
 * @param {boolean} [flyingPage=false] - Flag to determine if the link is for a flying page.
 * @returns {Object|null|undefined} - The generated link object or null/undefined if invalid.
 */
export const getContentLink = (item, orderId, flyingPage = false) => {
  const id = item.target?.id || item.contentId || item.id;
  const slug = calcSlug(item.target?.name || item.target?.title || item.title || item.name);
  if (!id || !slug) return null;

  const params = {
    id,
    slug,
    idRegion: item.target?.regionId || item.target?.region?.id || item.regionId || item.region?.id,
    ...(orderId && { orderId })
  };

  const itemNames = {
    supplier: 'producteurs',
    report: 'reports',
    recipe: 'recette',
    'supplier-product': 'produits',
    product: 'detail-product-item',
  };

  const itemDetailNames = {
    supplier: 'supplier-detail-item',
    report: 'article-detail-item',
    recipe: 'recette-detail-item',
    'supplier-product': 'variety-detail-item',
    product: 'detail-product-item',
  };

  return itemNames[item.type] ? {
    name: flyingPage ? itemDetailNames[item.type] : itemNames[item.type],
    params,
  } : undefined;
};

/**
 * Retrieves the combined parameters from the route and its potagerRoute meta.
 *
 * @param {Object} route - The route object containing parameters and meta information.
 * @returns {Object} - The combined parameters from the route and potagerRoute.
 */
export const getRouteParams = (route) => {
  const potagerRoute = route?.meta?.potagerRoute;
  return { ...route?.params, ...potagerRoute?.params };
};

/**
 * Retrieves the combined query parameters from the route and its potagerRoute meta.
 *
 * @param {Object} route - The route object containing query parameters and meta information.
 * @returns {Object} - The combined query parameters from the route and potagerRoute.
 */
export const getRouteQuery = (route) => {
  const potagerRoute = route?.meta?.potagerRoute;
  return { ...route?.query, ...potagerRoute?.query };
};
