<template>
  <card-layout
    :redirect="getRedirect"
    :small="isSmall"
    class="min-h-[350px] relative h-full bp425:min-h-[220px]">
    <potager-picture
      :alt="article.title"
      :src="article.image"
      background
      class="rounded-lg overflow-hidden bg-white-rock z-10 h-full"
      placeholder>
      <div
        v-if="article.type && article.displayPicto && getIcon || getTitle && article.displayTitle"
        class="p-3 flex flex-col justify-between items-center h-full">
        <div
          v-if="article.type && article.displayPicto && getIcon"
          :class="[
            'w-10 h-10 bg-white rounded-full flex items-center justify-center mb-2',
            'text-xl'
          ]">
          <component :is="getIcon" />
        </div>

        <h3
          v-if="getTitle && article.displayTitle"
          class="bg-white rounded p-3 mx-auto mt-auto max-w-[240px] text-charcoal text-xs.5 font-bold font-lore-alternates text-center"
          v-html="getTitle" />
      </div>
    </potager-picture>
  </card-layout>
</template>

<script>
import { getContentLink } from 'Classes/utils/RouteUtils';
import { cleanSpaces } from 'Classes/utils/WordpressUtils';

import IconFruitApple from 'Icons/regular/IconFruitApple';
import IconBookFlipPage from 'Icons/regular/IconBookFlipPage';
import IconChefGearHat from 'Icons/regular/IconChefGearHat';
import IconFarmingWheat from 'Icons/regular/IconFarmingWheat';

import CardLayout from 'Components/cards/UI/CardLayout';
import PotagerPicture from 'UI/PotagerPicture';

export default {

  components: {
    PotagerPicture,
    CardLayout,
  },

  props: {
    article: {
      type: Object,
      required: true,
    },
    small: {
      type: [Boolean, String],
      required: false,
      default: 'auto',
    },
  },

  computed: {
    getTitle() {
      return this.article.title && cleanSpaces(this.article.title);
    },
    getIcon() {
      switch (this.article.type) {
        case 'recipe':
          return IconChefGearHat;
        case 'supplier':
          return IconFarmingWheat;
        case 'report':
        case 'supplier-product':
          return IconBookFlipPage;
        case 'product':
          return IconFruitApple;
        default:
          return undefined;
      }
    },

    isHref() {
      return this.article.type === 'href';
    },
    getRedirect() {
      if (this.isHref) return this.article.link;
      return this.article.target ? getContentLink(this.article, null, true) : this.article.link;
    },
    isSmall() {
      return this.small === 'auto' ? this.$mq.bp768 : this.small;
    },
  },

};
</script>
