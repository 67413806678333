import ConfigurationManager from 'Classes/ConfigurationManager';

/**
 * Download a file with the given data and filename.
 *
 * @param {BlobPart} data - The data to be included in the file.
 * @param {string} filename - The name of the file to be downloaded.
 */
export const downloadFile = (data, filename) => {
  const datatype = { type: 'application/pdf' };
  const blob = new Blob([data], datatype);

  if (ConfigurationManager.getByName('isMobileTablet')) {
    const reader = new FileReader();
    reader.onload = () => {
      window.location.href = reader.result;
    };
    reader.readAsDataURL(blob);
  } else if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;

    document.body.appendChild(link);
    link.click();
    link.remove();
  }
};
