<template>
  <div
    v-if="mustShowIndication"
    class="order-indication">
    <div v-if="frontStatus === PREPARATION">
      <p class="order-indication__title">
        Bientôt le grand jour !
      </p>
      <p class="order-indication__text">
        Il est trop tard pour faire des modifications car il nous faut 24h pour commander auprès de nos
        producteurs et préparer vos paniers.
      </p>
    </div>

    <div v-if="frontStatus === CANCELED_CAN_RESUME">
      <p class="order-indication__title">
        Commande annulée
      </p>
      <p class="order-indication__text">
        Votre commande à été annulée.
        <template v-if="isSubscriptionActive && order?.isResumable">
          Vous pouvez la réactiver au plus tard 2 jours avant la livraison.
        </template>
        Si vous avez utilisé un coupon pour cette commande : il vous a été rendu.
      </p>

      <potager-button
        v-if="order?.isResumable"
        :is-loading="isLoading"
        class="order-indication__btn"
        label="Reprendre la commande"
        @onClick="resume" />
    </div>

    <div v-if="frontStatus === REFUNDED">
      <p class="order-indication__title">
        Commande annulée
      </p>

      <p class="order-indication__text">
        L'annulation de votre commande a bien été prise en compte.
      </p>
      <p class="order-indication__text">
        Le montant a été transformé en un coupon que vous pourrez utiliser pour vos prochains achats.
      </p>
      <p class="order-indication__text">
        Vous pouvez le retrouver dans la rubrique <potager-link
          :to="{name: 'mon-compte_coupons'}"
          class="order-indication__link">
          Mes coupons
        </potager-link> de l'espace
        <potager-link
          :to="{name: 'mon-compte'}"
          class="order-indication__link">
          Mon compte
        </potager-link>
      </p>
    </div>

    <div v-if="frontStatus === UNPAYED">
      <p class="order-indication__title">
        Paiement refusé
      </p>

      <p class="order-indication__text">
        Votre commande a été annulée car elle a rencontré une erreur lors de son paiement.
      </p>
      <p class="order-indication__text">
        Nous sommes désolés et vous invitons à vérifier les coordonnées de votre carte bancaire
        depuis la rubrique <potager-link
          :to="{ name: 'mon-compte_payment-methods' }"
          class="underline">
          Moyens de paiement
        </potager-link>
        dans l'espace <potager-link
          :to="{name: 'mon-compte'}"
          class="underline">
          Mon compte
        </potager-link>
      </p>
      <p class="order-indication__text">
        Si le problème persiste, veuillez vous rapprocher de votre banque ou contacter notre
        <potager-link
          :to="{name: 'contact'}"
          class="underline">
          Service Client
        </potager-link>
      </p>
    </div>
  </div>
</template>

<script>
import {
  VALIDATED,
  PAYED,
  PREPARATION,
  DELIVERED,
  REFUNDED,
  CANCELED_CAN_RESUME,
  CANCELED_CAN_NOT_RESUME,
  UNPAYED,
} from 'Classes/OrderStatus';

import { mapGetters } from 'vuex';
import PotagerButton from 'UI/PotagerButton';

export default {
  components: {
    PotagerButton,
  },

  props: {
    frontStatus: {
      type: String,
      required: true,
      default: VALIDATED,
    },
    order: {
      type: Object,
      required: true,
      default: () => {
      },
    },
  },
  data() {
    return {
      PAYED,
      VALIDATED,
      REFUNDED,
      DELIVERED,
      PREPARATION,
      CANCELED_CAN_RESUME,
      CANCELED_CAN_NOT_RESUME,
      UNPAYED,
    };
  },
  computed: {
    ...mapGetters('user', [
      'isSubscriptionActive',
    ]),
    mustShowIndication() {
      return ([PREPARATION, CANCELED_CAN_RESUME, REFUNDED, UNPAYED].indexOf(this.frontStatus) !== -1);
    },
    isLoading() {
      return this.$wait.is([`RESUME_ORDER_ACTION_${this.order.id}`]);
    },

  },
  methods: {
    resume() {
      this.$emit('resume');
    },
  },
};

</script>
