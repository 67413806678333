<template>
  <panel
    :back-route="routes.myAccount"
    title="Définir comme carte par défaut ?">
    <div class="panel-section text-center">
      <div class="panel-section__line">
        <icon-credit-card-check class="text-6xl mr-auto ml-auto block" />

        <p class="uppercase break-words text-lg leading-normal font-semibold mb-8">
          Définir comme carte par défaut&nbsp;?
        </p>

        <p class="mb-8">
          Confirmez si vous souhaitez que cette carte de
          paiement soit utilisée par défaut lors de
          vos prochains paiements.
        </p>

        <potager-button
          :is-loading="$wait.is(`${SET_DEFAULT_CARD_ACTION}_${cardId}`)"
          class="mb-4"
          full-width
          label="Confirmer"
          @onClick="changeDefaultCard(cardId)" />

        <potager-button
          full-width
          label="Non merci"
          theme="stroke"
          @onClick="redirect" />
      </div>
    </div>
  </panel>
</template>

<script>
import IconCreditCardCheck from 'Icons/regular/IconCreditCardCheck';

import Panel from 'Components/panel/Panel';
import PotagerButton from 'UI/PotagerButton';

import { SET_DEFAULT_CARD_ACTION } from 'Stores/types/userActionsTypes';
import MetaInfosService from 'Classes/services/MetaInfoService';

export default {

  components: {
    IconCreditCardCheck,
    Panel,
    PotagerButton,
  },

  data() {
    return {
      SET_DEFAULT_CARD_ACTION,
      cardId: this.$potagerRoute.params.cardId,
      routes: {
        creditCards: { name: 'mon-compte_payment-methods' },
        myAccount: { name: 'mon-compte' },
        home: { name: 'home' },
      },
    };
  },

  methods: {
    changeDefaultCard(cardId) {
      this.$store.dispatch(`user/${SET_DEFAULT_CARD_ACTION}`, { cardId })
        .then(() => {
          this.$notify({
            type: 'success',
            title: 'Carte par défaut définie',
            text: 'Votre carte de paiement a bien été définie comme carte par défaut.',
          });

          this.redirect();
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Erreur',
            text: 'Une erreur est survenue lors de la définition de la carte par défaut.',
          });
        });
    },
    redirect() {
      const route = this.$potagerRoute.query.back
        ? JSON.parse(decodeURIComponent(this.$potagerRoute.query.back))
        : this.routes.creditCards;

      this.$router.push(route);
    },
  },

  head: MetaInfosService.generate({
    title: 'Cartes de paiement',
  }),

};
</script>
