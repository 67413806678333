<template>
  <div class="mb-8 relative z-10">
    <potager-container class="b2b-service py-24 flex flex-col justify-center relative z-10">
      <h2
        v-if="content.title"
        class="font-black text-4xl bp1024:text-3xl.5 bp1024:leading-tight mb-10 w-5/12 bp768:w-full pr-8 bp768:pr-0"
        v-html="content.title" />

      <potager-slider
        :key="`b2b-slider-${content.id}`"
        :items="content.items"
        align="start"
        class="mx-0"
        name="b2b-slider"
        overflow-visible
        slides-per-view="auto">
        <template #default="{ item }">
          <div
            :style="`max-width: ${$mq.bp768 ? '17.75' : '19'}rem`"
            class="bg-bianca rounded-xl shadow-xl flex flex-col items-center h-full">
            <div class="scotch bg-porcelain/60 block h-8 w-32 -mt-4" />
            <div class="p-8 -mt-4 tracking-normal">
              <highlights-icon :icon="item.icon" />
              <p class="leading-extra-tight font-bold text-xl.5 pb-4 mt-6">
                {{ item.title }}
              </p>
              <p
                class="leading-normal"
                v-html="item.description" />
            </div>
          </div>
        </template>
      </potager-slider>
    </potager-container>

    <potager-picture
      v-if="$mq.bp768"
      :src="content.image"
      background
      class="absolute z-0 bottom-0 left-0 right-0 h-80" />
  </div>
</template>

<script>
import PotagerContainer from 'UI/PotagerContainer';
import PotagerSlider from 'UI/PotagerSlider';
import PotagerPicture from 'UI/PotagerPicture';
import HighlightsIcon from 'Components/highlightsSlider/HighlightsIcon';

export default {

  components: {
    PotagerContainer,
    PotagerSlider,
    PotagerPicture,
    HighlightsIcon,
  },

  props: {
    content: {
      type: Object,
      required: true,
      default: null,
    },
  },
};
</script>

<style lang="scss">
.b2b-service {
  .swiper-slide {
    &:nth-child(odd) {
      .scotch {
        clip-path: polygon(5px 4px, 94.53% 5px, 91.41% 103.13%, 9px 93.75%);
      }
    }

    &:nth-child(even) {
      .scotch {
        clip-path: polygon(12px 4px, 90.62% 7px, 91.41% 103.13%, 9px 93.75%);
      }
    }

    b {
      @apply bg-rossabel;
    }
  }
}
</style>
