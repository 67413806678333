<template>
  <div v-if="getSearchResult && !getSearchResult.length && !!getUserSearch && !isLoading">
    <p>
      Désolés, nous n’avons trouvé aucun résultat pour votre recherche
      «&nbsp;<b>{{ getUserSearch?.search }}</b>&nbsp;»
    </p>
    <div class="mt-2">
      <potager-button
        size="small"
        theme="stroke"
        @onClick="UPDATE_USER_SEARCH">
        Faire une nouvelle recherche
      </potager-button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { UPDATE_USER_SEARCH } from 'Stores/types/mapMutationsTypes';

import {
  SEARCH_PRIVATES_DELIVERY_POINTS,
  SEARCH_PUBLICS_DELIVERY_POINTS,
} from 'Stores/types/mapActionsTypes';

import PotagerButton from 'UI/PotagerButton';

export default {
  props: {
    lookingFor: {
      type: String,
      default: 'publics',

    },
  },
  components: {
    PotagerButton,
  },

  computed: {
    ...mapGetters('map', [
      'getUserSearch',
      'getPrivateSearchResult',
      'getPublicSearchResult',
      'getMapPublicSearchResult',
    ]),
    isLoading() {
      return this.$wait.is(SEARCH_PRIVATES_DELIVERY_POINTS) || this.$wait.is(SEARCH_PUBLICS_DELIVERY_POINTS);
    },
    getSearchResult() {
      if (this.lookingFor === 'privates' && this.getPrivateSearchResult) {
        return this.getPrivateSearchResult;
      } if (this.getPublicSearchResult) {
        return this.getPublicSearchResult;
      }
      return this.getMapPublicSearchResult;
    },
  },

  methods: {
    ...mapMutations('map', {
      UPDATE_USER_SEARCH,
    }),
  },

};
</script>
