<template>
  <potager-modal
    without-header
    without-footer>
    <template #body>
      <div class="text-left">
        <p class="mb-2 uppercase">
          Ajouter un coupon de réduction
        </p>

        <potager-form
            id="coupons-manager"
            @onSubmit="onSubmit">
          <potager-row condensed>
            <potager-col>
              <potager-input
                name="code"
                v-model="couponCode"
                label="Code coupon"
                reset
                uppercase
                :constraints="constraints" />
            </potager-col>

            <potager-col>
              <potager-button
                type="submit"
                full-width
                :is-loading="isLoading">
                Ajouter
              </potager-button>
            </potager-col>
          </potager-row>
        </potager-form>
      </div>
    </template>
  </potager-modal>
</template>

<script>
import PotagerModal from 'UI/PotagerModal';
import PotagerInput from 'UI/Form/PotagerInput';
import PotagerForm from 'UI/Form/PotagerForm';
import PotagerRow from 'UI/PotagerRow';
import PotagerCol from 'UI/PotagerCol';
import PotagerButton from 'UI/PotagerButton';

import { ADD_COUPON_ON_USER_ACTION } from 'Stores/types/userActionsTypes';

export default {

  components: {
    PotagerModal,
    PotagerInput,
    PotagerForm,
    PotagerRow,
    PotagerCol,
    PotagerButton,
  },

  data: () => ({
    couponCode: null,
    constraints: [
      { constraint: 'required', field: 'code' },
      { constraint: 'length', field: 'code', options: { min: 3 } },
    ],
  }),

  props: {
    submit: {
      type: Function,
      required: true,
    },
  },

  computed: {
    isLoading() {
      return this.$wait.is([
        ADD_COUPON_ON_USER_ACTION,
      ]);
    },
  },

  methods: {
    onSubmit() {
      const { couponCode } = this;
      this.submit(couponCode)
        .then(() => {
          this.$modal.close();
        });
    },
  },

};
</script>
