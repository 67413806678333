<template>
  <section class="overflow-hidden">
    <potager-container>
      <div class="flex flex-col py-10 my-8 bg-beryl-green text-british-racing rounded-xl overflow-hidden text-center">
        <h2 class="home-page__title mb-6 bp425:px-8">
          <strong>
            C'est simple comme <span class="underline">bon</span>jour
          </strong>
        </h2>

        <div
          v-if="$mq.bp768"
          class="max-w-[320px] relative mx-auto bp425:mx-8 overflow-hidden">
          <potager-slider
            :items="sliderItems"
            :settings="{
              navigation: {
                nextEl: '.home-page-order-process__navigation-button--next',
                prevEl: '.home-page-order-process__navigation-button--prev',
              }
            }"
            :slides-per-view="1"
            align="center"
            name="order-process"
            space-between="60">
            <template #default="{ item }">
              <div class="w-[272px] flex-col items-center bp1024:w-[180px] mx-auto">
                <component
                  :is="item.icon"
                  class="text-[160px] mb-2 bp-1024:text-[140px]" />

                <div
                  class="text-base"
                  v-html="item.text" />
              </div>
            </template>
          </potager-slider>

          <div class="home-page-order-process__navigation">
            <potager-button
              ref="prev"
              aria-label="Précédent"
              class="absolute left-0 transform top-1/2 -translate-y-1/2 z-20 home-page-order-process__navigation-button--prev"
              is-circle
              shadow
              theme="stroke">
              <template #icon>
                <icon-arrow-left />
              </template>
            </potager-button>

            <potager-button
              ref="next"
              aria-label="Suivant"
              class="absolute right-0 transform top-1/2 -translate-y-1/2 z-20 home-page-order-process__navigation-button--next"
              is-circle
              shadow
              theme="stroke">
              <template #icon>
                <icon-arrow-right />
              </template>
            </potager-button>
          </div>
        </div>

        <div
          v-else
          class="flex justify-center gap-10">
          <div
            v-for="(item, index) in sliderItems"
            :key="`slider-item-${index}`"
            class="w-[272px] flex-col items-center bp1024:w-[180px]">
            <component
              :is="item.icon"
              class="text-[160px] mb-2 bp-1024:text-[140px]" />

            <div
              class="text-base"
              v-html="item.text" />
          </div>
        </div>

        <potager-button
          :to="isSubscriptionActive ? {name: 'mon-compte_subscription'} : { name: 'tunnel' }"
          class="mt-6 mx-auto"
          theme="british-racing">
          {{ isSubscriptionActive ? 'Voir mon abonnement' : 'Je m\'abonne' }}
        </potager-button>
      </div>
    </potager-container>
  </section>
</template>

<script>
import PotagerContainer from 'UI/PotagerContainer';
import PotagerSlider from 'UI/PotagerSlider';
import PotagerButton from 'UI/PotagerButton';

import IconIlluPrNum from 'Icons/graphics/IconIlluPrNum';
import IconIlluDeliveryNum from 'Icons/graphics/IconIlluDeliveryNum';
import IconIlluBasketNum from 'Icons/graphics/IconIlluBasketNum';
import IconArrowLeft from 'Icons/regular/IconArrowLeft';
import IconArrowRight from 'Icons/regular/IconArrowRight';
import { mapGetters } from 'vuex';

export default {
  components: {
    PotagerButton,
    PotagerContainer,
    PotagerSlider,
    IconIlluPrNum,
    IconIlluDeliveryNum,
    IconIlluBasketNum,
    IconArrowLeft,
    IconArrowRight,
  },

  data: () => ({
    sliderItems: [
      {
        text: '<b>Je choisis le point relais</b> <br class="bp1024:hidden">le plus proche de chez moi.',
        icon: 'IconIlluPrNum',
      },
      {
        text: '<b>Je choisis un panier composé</b> <br class="bp1024:hidden">ou compose le mien.',
        icon: 'IconIlluBasketNum',
      },
      {
        text: '<b>Je récupère ma commande</b> <br class="bp1024:hidden">et profite de mes produits frais&nbsp;!',
        icon: 'IconIlluDeliveryNum',
      },
    ],
  }),

  computed: {
    ...mapGetters('user', [
      'isSubscriptionActive',
    ]),
  },
};
</script>
