<template>
  <section class="home-page-concept">
    <potager-container class="pt-16">
      <potager-row class="mb-0">
        <potager-col
          col="4"
          bp1024="6"
          offset="1"
          offset-bp1024="0"
          bp640="12">
          <div
            :class="[
              'pb-20 pl-8',
              'bp1280:pl-0',
              'bp640:pb-0'
            ]">
            <h1 class="home-page__title text-left mb-2">
              <strong>Livraison de paniers</strong> de fruits et légumes
            </h1>

            <div class="mb-6">
              <potager-badge
                :size="$mq.bp768 ? 'medium' : 'large'"
                theme="beryl-green">
                et pas que&nbsp;!
              </potager-badge>
            </div>

            <div class="leading-normal mb-12">
              <p>
                <strong>Chaque semaine, découvrez les trouvailles de l’équipe et faites votre marché&nbsp;!</strong>
              </p>

              <p>
                Pour les plus pressés : des paniers de fruits & légumes tout faits et composés pour vos recettes de la
                semaine. Et pour les plus indépendants, des produits en barquettes, en sachets ou en vrac, pour composer
                votre panier vous-même au gré de vos coups de coeur !
              </p>
            </div>

            <potager-button
              v-gtm="{ prefix: 'homepage-store-section' }"
              :to="{ name: 'dashboard' }"
              theme="go-green">
              Voir la boutique
            </potager-button>
          </div>
        </potager-col>

        <potager-col
          class="pb-0 flex items-end relative bp1280:-mr-6 bp1280:left-6 bp640:mr-0 bp640:left-0"
          bp1280="6"
          col="7"
          bp1024="6"
          bp640="12">
          <potager-picture
            :height="$mq.bp640 ? undefined : '500px'"
            :src="`homepage-product-cards${$mq.bp640 ? '-mobile' : ''}`"
            class="home-page-concept__picture w-full -z-10"
            no-lazy-load />
        </potager-col>
      </potager-row>

      <potager-row class="-mt-12 bp640:mt-0 relative">
        <potager-col
          class="py-0 mb-16 bp1024:mb-14 bp768:mb-9 bp640:mb-12"
          bp1280="10"
          offset-bp1280="1">
          <highlights-slider
            :highlights="highlights"
            class="m-auto"
            theme="white" />
        </potager-col>
      </potager-row>
    </potager-container>
  </section>
</template>

<script>
import PotagerContainer from 'UI/PotagerContainer';
import PotagerRow from 'UI/PotagerRow';
import PotagerCol from 'UI/PotagerCol';
import PotagerPicture from 'UI/PotagerPicture';
import PotagerBadge from 'UI/PotagerBadge';
import PotagerButton from 'UI/PotagerButton';

import HighlightsSlider from 'Components/highlightsSlider/HighlightsSlider';

export default {

  components: {
    HighlightsSlider,
    PotagerButton,
    PotagerBadge,
    PotagerContainer,
    PotagerRow,
    PotagerCol,
    PotagerPicture,
  },

  data() {
    return {
      highlights: [
        {
          icon: '🤝',
          description: 'Les pépites de nos producteurs et artisans partenaires',
        },
        {
          icon: '🍓',
          description: 'Fruits et légumes en barquette, en botte ou à la pièce ',
        },
        {
          icon: '🍯',
          description: 'Petits déjeuners, céréales et légumineuses, pâte à tartiner…',
        },
      ],
    };
  },

};
</script>

<style lang="scss" scoped>
.home-page-concept {
  &__picture {
    @include bp640() {
      @apply relative overflow-hidden h-auto w-auto min-w-0 m-auto;
      max-width: 480px;
    }

    :deep() .potager-picture__img--loaded {

      @include bp640() {
        @apply object-contain;
        @apply w-full;
        @apply h-auto;
      }
    }
  }
}
</style>
