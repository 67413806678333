<template>
  <!-- Template Required -->
</template>

<script>
import { POPIN_ERROR_EVENT } from 'Classes/Constants';
import ModalInfo from 'Modals/ModalInfo';

export default {
  methods: {
    alert(errorConfig) {
      const Component = errorConfig.component || ModalInfo;
      this.$modal.open(Component, {
        ...errorConfig,
        text: `
          ${errorConfig.text}
          <p class="text-warm-grey text-xs.5 font-semibold mt-4">
            Code erreur : ${errorConfig.code}
          </p>
        `,
      });
    },
  },
  mounted() {
    this.$events.on(POPIN_ERROR_EVENT, this.alert);
  },
  beforeUnmount() {
    this.$events?.off(POPIN_ERROR_EVENT);
  },
};
</script>
