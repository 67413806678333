export const LUNCHEON = 'LUNCHEON';
export const FIDELITY = 'FIDELITY';
export const VOUCHER = 'VOUCHER';
export const SPONSORSHIP = 'SPONSORSHIP';
export const REFUND = 'REFUND';
export const PARTICIPATION = 'PARTICIPATION';
export const PERCENT = 'PERCENT';
export const VALUE = 'VALUE';
export const PRODUCT = 'PRODUCT';

/**
 * Returns a message corresponding to the coupon type.
 *
 * @param {Object} coupon - The coupon object.
 * @param {string} coupon.couponType - The type of the coupon.
 * @param {string} [coupon.couponValueType] - The value type of the coupon (optional).
 * @returns {string|null} The message corresponding to the coupon type, or null if the type is not found.
 */
export const getCouponTypeMsg = (coupon) => {
  const mapping = {
    [SPONSORSHIP]: 'parrainage',
    [LUNCHEON]: 'titre restaurant',
    [PARTICIPATION]: 'carte de participation',
    [REFUND]: 'avoir',
    [FIDELITY]: 'fidélité',
    [VOUCHER]: coupon.couponValueType === PRODUCT ? 'ajout produit' : 'bon de réduction',
  };
  return coupon.couponType ? mapping[coupon.couponType] : null;
};

/**
 * Returns an array of forbidden coupon types based on the given type.
 *
 * @param {string} type - The type of the coupon.
 * @returns {string[]} An array of forbidden coupon types.
 */
export const getForbiddenTypes = (type) => {
  const mapping = {
    [SPONSORSHIP]: [VOUCHER, SPONSORSHIP],
    [VOUCHER]: [SPONSORSHIP],
  };
  return mapping[type] || [];
};

/**
 * Groups coupons by their type and returns an array of grouped coupons.
 *
 * @param {Object[]} coupons - The array of coupon objects.
 * @param {number} coupons[].id - The ID of the coupon.
 * @param {string} coupons[].couponType - The type of the coupon.
 * @returns {Object[]} An array of grouped coupons with titles, legends, and coupons.
 */
export const getCouponsGroupedByType = (coupons) => {
  const sortedCoupons = coupons.sort((a, b) => a.id - b.id);
  return [
    {
      title: 'Titres restaurant',
      legend: 'Vous pouvez utiliser jusqu\'à 2 titres restaurant par commande.',
      coupons: sortedCoupons.filter((c) => c.couponType === LUNCHEON),
    },
    {
      title: 'Cartes de participation',
      coupons: sortedCoupons.filter((c) => c.couponType === PARTICIPATION),
    },
    {
      title: 'Avoirs',
      coupons: sortedCoupons.filter((c) => c.couponType === REFUND),
    },
    {
      title: 'Bons de réduction et parrainage',
      legend: 'Ces coupons ne sont pas cumulables à l\'exception des bons d\'achat Potager City. Faites votre choix parmi vos coupons disponibles :',
      coupons: [
        ...sortedCoupons.filter((c) => c.couponType === SPONSORSHIP),
        ...sortedCoupons.filter((c) => c.couponType === VOUCHER),
      ],
    },
    {
      title: 'Fidélité',
      coupons: sortedCoupons.filter((c) => c.couponType === FIDELITY),
    },
  ];
};

/**
 * Returns the unit representation of the coupon value.
 *
 * @param {Object} coupon - The coupon object.
 * @param {string} coupon.couponValueType - The value type of the coupon.
 * @param {number} coupon.couponValue - The value of the coupon.
 * @param {string} [coupon.giftedProductName] - The name of the gifted product (optional).
 * @returns {string} The unit representation of the coupon value.
 */
export const couponUnit = ({
  couponValueType,
  couponValue,
  giftedProductName
}) => {
  if (couponValueType === PERCENT) return `-${couponValue}%`;
  if (couponValueType === PRODUCT) return `x${couponValue} ${giftedProductName}`;
  return `-${couponValue}€`;
};
