<template>
  <potager-link
    v-gtm
    :to="{ name: 'mon-compte-sponsorship-my-code' }"
    class="panel-section bg-lavender-blush rounded-lg overflow-hidden block">
    <potager-picture
      background
      background-size="contain"
      position="right"
      src="sponsorship-small">
      <span class="w-full p-6 flex items-center justify-start gap-4">
        <icon-gift-box class="text-byzantine text-2xl" />

        <span class="font-semibold">
          Parrainage
          <br>
          <span class="text-xs font-medium">
            <b>10€</b> pour vous, <b>18€</b> pour eux !
          </span>
        </span>
      </span>
    </potager-picture>
  </potager-link>
</template>

<script>
import IconGiftBox from 'Icons/regular/IconGiftBox';
import PotagerPicture from 'UI/PotagerPicture';

export default {
  name: 'AccountSponsorshipSection',

  components: {
    PotagerPicture,
    IconGiftBox,
  },
};
</script>
