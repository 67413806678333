<template>
  <div>
    <heading
      :background="null"
      :modifiers="['text-black', 'smaller']">
      <template #title>
        Notre index égalité professionnelle femmes & hommes 2023
      </template>
    </heading>

    <blog-body>
      <blog-section>
        <cms>
          <p>
            Depuis le 1er mars 2020, les entreprises françaises de plus de 50 salariés doivent avoir mesuré quatre indicateurs et publié leur note globale selon la méthodologie de calcul de l’index égalité professionnelle femmes & hommes.
          </p>

          <p class="mb-2">
            <b>
              Dans ce contexte, Potager City obtient en 2024 un résultat de 99/100 (résultat au titre des données 2023).
            </b>
          </p>

          <p class="mb-2">
            Le détail des indicateurs&nbsp;:
          </p>

          <ul class="pl-6">
            <li>
              Indicateur relatif à l'écart de rémunération : 39/40
            </li>

            <li>
              Indicateur relatif à l'écart de taux d'augmentations individuelles : 35/35
            </li>

            <li>
              Indicateur relatif au % de salariées ayant bénéficié d'une augmentation dans l'année suivant leur retour de congé maternité : 15/15 (Absence de retour de congé maternité)
            </li>

            <li>
              Indicateur relatif au nombre de salariés du sexe sous-représenté parmi les 10 salariés ayant perçu les plus hautes rémunérations : 10/10
            </li>
          </ul>
        </cms>
      </blog-section>
    </blog-body>
  </div>
</template>

<script>
import ToolsMixin from 'Mixins/ToolsMixin';

import Heading from 'Components/heading/Heading';
import BlogBody from 'Components/blog/BlogBody';
import BlogSection from 'Components/blog/BlogSection';
import Cms from 'Components/cms/Cms';

export default {

  mixins: [
    ToolsMixin,
  ],

  components: {
    Heading,
    BlogBody,
    BlogSection,
    Cms,
  },

  head() {
    return this.generateMetaIfPanelClosed({
      title: 'Index égalité professionnelle',
      description: 'Différence de rémunération, promotion professionnelle, augmentation salariale… La loi "Avenir professionnel" du 5 septembre 2018 oblige les entreprises à calculer un index, mesurant les écarts de traitement entre femmes et hommes.',
    });
  },
};
</script>
