<template>
  <div class="enterprise__packshot">
    <potager-container>
      <potager-picture
        :src="image.url"
        :title="title"
        placeholder />
    </potager-container>

    <potager-wave
      :index="3"
      :height="['8rem', '3rem']"
      :modifiers="['on-top', 'invert-y']" />

    <potager-wave
      :index="4"
      :height="['9rem', '3rem']"
      :modifiers="['on-bottom', 'invert-y']" />
  </div>
</template>

<script>
import PotagerContainer from 'UI/PotagerContainer';
import PotagerPicture from 'UI/PotagerPicture';
import PotagerWave from 'UI/PotagerWave';

export default {

  components: {
    PotagerContainer,
    PotagerPicture,
    PotagerWave,
  },

  props: {
    image: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: true,
      default: null,
    },
  },

};
</script>
