<template>
  <flying-page>
    <div class="subscription-detail">
      <div class="subscription-detail__poster subscription-detail__poster--error">
        <icon-road-sign-warning class="subscription-detail__poster--error__icon" />
      </div>

      <div class="subscription-detail__content subscription-detail__content--error">
        <section class="subscription-detail__section">
          <p class="subscription-detail__title">
            Le point de retrait lié à votre abonnement est indisponible
          </p>
          <p class="subscription-detail__description">
            <template v-if="getOrderDeliveryDate(getSubscriptionOrder)">
              Le jour de retrait de votre point habituel sélectionné n’est pas disponible pour recevoir de commandes le
              <b>{{ getOrderDeliveryDate(getSubscriptionOrder) }}.</b>
            </template>
            <template v-else>
              Le jour de retrait de votre point habituel sélectionné n’est plus disponible.
            </template>
            Nous n'avons donc pas pu créer automatiquement de commande contenant votre panier en abonnement.
            Pour continuer à recevoir votre panier en abonnement, vous devez modifier votre point ou jour de retrait.
          </p>
        </section>
      </div>
    </div>

    <template #footer>
      <div class="subscription-detail__footer">
        <div class="subscription-detail__footer__container">
          <potager-row>
            <potager-col>
              <potager-button
                :to="redirect"
                full-width
                label="Modifier"
                theme="default" />
            </potager-col>
          </potager-row>
        </div>
      </div>
    </template>
  </flying-page>
</template>

<script>
import SubscriptionMixin from 'Mixins/SubscriptionMixin';

import IconRoadSignWarning from 'Icons/regular/IconRoadSignWarning';

import FlyingPage from 'Components/flyingPage/FlyingPage';
import PotagerButton from 'UI/PotagerButton';
import PotagerRow from 'UI/PotagerRow';
import PotagerCol from 'UI/PotagerCol';

import { getOrderDeliveryDate } from 'Classes/utils/OrderUtils';
import { Context } from 'potagerlogic/dist/Enums/Context';

export default {
  methods: { getOrderDeliveryDate },

  mixins: [
    SubscriptionMixin,
  ],

  components: {
    FlyingPage,
    PotagerButton,
    PotagerRow,
    PotagerCol,
    IconRoadSignWarning,
  },

  computed: {
    redirect() {
      if (this.getSubscriptionOrder?.deliveryPoint) {
        return {
          name: `account-dlp-search-step3-${Context.Subscription}`,
          params: {
            name: this.getSubscriptionOrder.deliveryPoint.groupName,
            latlng: `${this.getSubscriptionOrder.deliveryPoint.lat},${this.getSubscriptionOrder.deliveryPoint.lng}`,
          },
        };
      }
      return { name: 'mon-compte_subscription' };
    },
  },
};
</script>
