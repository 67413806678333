<template>
  <potager-modal>
    <template #title>
      Oups !
    </template>

    <template #body>
      <span v-html="message" />

      <p
        v-if="errorCode"
        class="text-warm-grey text-xs.5 font-semibold mt-4"
        v-html="errorCode" />
    </template>

    <template #footer>
      <div class="flex flex-col">
        <potager-button
          full-width
          label="J'ai compris"
          @onClick="retry" />
        <potager-button
          :to="{ name: 'contact' }"
          size="small"
          target="_blank"
          theme="link">
          Si le problème persiste, contactez-nous
        </potager-button>
      </div>
    </template>
  </potager-modal>
</template>

<script>
import PotagerModal from 'UI/PotagerModal';
import PotagerButton from 'UI/PotagerButton';

export default {

  components: {
    PotagerModal,
    PotagerButton,
  },

  props: {
    error: {
      type: Object,
      required: false,
      default: null,
    },
    message: {
      type: String,
      required: true,
    },
  },

  computed: {
    errorCode() {
      const errorCode = this.error?.code || this.error?.errorCode || this.error?.stripePayment?.errorCode;
      const declineCode = this.error?.decline_code;

      if (errorCode || declineCode) {
        return `Code erreur${errorCode ? ' : ' + errorCode : ''}${declineCode ? ' - ' + declineCode : ''}`;
      }

      return null;
    },
  },

  methods: {
    retry() {
      this.$modal.close();
    },
  },
};
</script>
