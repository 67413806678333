<template>
  <transition
    :css="false"
    @afterEnter="afterEnter"
    @afterLeave="afterLeave"
    @beforeEnter="beforeEnter"
    @beforeLeave="beforeLeave"
    @enter="enter"
    @enterCancelled="enterCancelled"
    @leave="leave">
    <div
      v-if="isVisible"
      :class="{
        'upselling-slider-wrapper': true,
        'upselling-slider-wrapper--flying print:hidden': flying,
        'upselling-slider-wrapper--slider-disable': disableSlider,
      }">
      <div
        ref="collapsibleBody"
        :class="{
          'upselling-slider': true,
          'upselling-slider--flying': flying,
        }">
        <potager-container :size="flying ? 'wide' : undefined">
          <h2
            v-if="getTitle"
            class="upselling-slider__title">
            {{ getTitle }}
          </h2>
          <h3
            v-if="getSubtitle"
            class="upselling-slider__subtitle">
            {{ getSubtitle }}
          </h3>

          <potager-slider
            :disabled="disableSlider"
            :items="getProducts"
            :overflow-visible="$mq.bp1024 || flying"
            :settings="settings"
            :slides-per-view="slidesPerView"
            class="upselling-slider__slider"
            name="upselling-slider"
            navigation
            virtual>
            <template #default="vProps">
              <card-product
                :box="vProps.item"
                no-lazy-load />
            </template>
          </potager-slider>
        </potager-container>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';
import PotagerContainer from 'UI/PotagerContainer';
import PotagerSlider from 'UI/PotagerSlider';
import CardProduct from 'Components/cards/CardProduct';

export default {

  components: {
    CardProduct,
    PotagerContainer,
    PotagerSlider,
  },

  props: {
    title: {
      type: [String, Boolean],
      required: false,
      default: 'Le petit marché',
    },
    subtitle: {
      type: [String, Boolean],
      required: false,
      default: 'Complétez votre commande avec des produits à la carte',
    },
    slidesPerView: {
      type: Object,
      required: false,
      default() {
        return {
          default: 1.5,
          768: 3,
        };
      },
    },
    flying: {
      type: Boolean,
      required: false,
      default: false,
    },
    exclude: {
      type: Number,
      required: false,
      default: -1,
    },
    section: {
      type: Object,
      required: false,
      default: null,
    },
    disableSlider: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    ...mapGetters('dashboard', [
      'getMiniBoxes',
    ]),
    ...mapGetters('basket', [
      'canContainMiniBox',
    ]),
    ...mapGetters('session', [
      'isLoggedIn',
    ]),
    settings() {
      const settings = {
        spaceBetween: 20,
      };

      if (this.$mq.bp768) {
        settings.centeredSlides = true;
        settings.initialSlide = 1;
      }

      return settings;
    },
    getTitle() {
      return this.section ? this.section.title : this.title;
    },
    getSubtitle() {
      return this.section ? this.section.subtitle : this.subtitle;
    },
    getProducts() {
      let products = this.section ? this.section.items : this.getMiniBoxes;
      return products
        .filter((e) => e.id !== this.exclude)
        .slice(0, 20);
    },
    isVisible() {
      return this.getProducts.length;
    },
  },

  methods: {
    beforeEnter(el) {
      el.style.height = 0;
    },
    enter(el, done) {
      const body = this.$refs.collapsibleBody;
      const h = this.getElementHeightWithMargin(body);
      const onTransitionEnd = (e) => {
        done();
        e.target.removeEventListener('transitionend', onTransitionEnd);
      };

      el.addEventListener('transitionend', onTransitionEnd, false);

      this.$nextTick(() => { // Just to be sure
        el.style.height = `${h}px`;
      });
    },
    afterEnter(el) {
      el.style.height = null;
    },
    enterCancelled(el) {
      el.style.height = null;
    },

    beforeLeave(el) {
      el.style.height = `${el.offsetHeight}px`;
    },
    leave(el, done) {
      const onTransitionEnd = (e) => {
        done();
        e.target.removeEventListener('transitionend', onTransitionEnd);
      };

      el.addEventListener('transitionend', onTransitionEnd, false);

      setTimeout(() => { // Just to be sure, $nextTick is not enough
        el.style.height = '0px';
      }, 0);
    },
    afterLeave(el) {
      el.style.height = null;
    },
    getElementHeightWithMargin($el) {
      const styles = window.getComputedStyle($el);
      const h = $el.offsetHeight;
      const mt = parseInt(styles.marginTop, 10);
      const mb = parseInt(styles.marginBottom, 10);
      return h + mt + mb;
    },
  },
};
</script>
