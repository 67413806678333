<template>
  <div class="manifesto-productors flex bp425:justify-between">
    <potager-link
      v-for="(productor, key) of productors"
      :class="{
        'max-w-[140px] ml-4 first:ml-0 flex-1': true,
        'bp1280:hidden': key > 2,
      }"
      :to="productor.link"
      :key="key">
      <potager-picture
        class="manifesto-productors__picture mb-2"
        extra-img-class="rounded-xl overflow-hidden"
        :src="`manifesto-productor-${productor.picture}`" />

      <div class="manifesto-productors__text">
        <p>
          {{ productor.name }}
        </p>
      </div>
    </potager-link>
  </div>
</template>

<script>
import PotagerPicture from 'UI/PotagerPicture';

export default {
  name: 'ManifestoProductors',
  components: { PotagerPicture },
  data() {
    return {
      productors: [
        {
          name: 'Paola N.',
          picture: 'paola',
          link: '/producteurs/l-aventure-vitaminee-de-paola/262',
        },
        {
          name: 'Laurent B.',
          picture: 'laurent',
          link: '/producteurs/la-bonne-humeur-et-les-legumes-de-laurent/21',
        },
        {
          name: 'Christine V.',
          picture: 'christine',
          link: '/producteurs/christine-notre-productrice-de-haricots-et-de-framboises-a-la-passion-du-produit-et-le-gout-des-autres/772',
        },
        {
          name: 'Thierry A.',
          picture: 'thierry',
          link: '/producteurs/aux-portes-du-luberon-thierry-est-dans-ses-vignes/780',
        },
      ],
    };
  },
};
</script>
