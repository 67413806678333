<template>
  <potager-picture v-bind="$props">
    <slot />
  </potager-picture>
</template>

<script>
import PotagerPicture, { props as PotagerPictureProps } from 'UI/PotagerPicture';
import { propsBuilder } from 'UI/Tools';

const props = { ...PotagerPictureProps };
props.seo.default = false;
props.background.default = true;

export default {

  components: {
    PotagerPicture,
  },

  props: propsBuilder(props),

};
</script>
