<template>
  <potager-modal :has-large-buttons="!(buttonYesText === 'Oui' && buttonNoText === 'Non')">
    <template #title>
      {{ title }}
    </template>

    <template #body>
      <span v-html="text" />
    </template>

    <template #footer>
      <potager-button
        @onClick="no()"
        theme="stroke"
        :full-width="true"
        :is-loading="$wait.is(noIsLoading)"
        :label="buttonNoText" />
      <potager-button
        @onClick="yes()"
        :full-width="true"
        :is-loading="$wait.is(yesIsLoading)"
        :label="buttonYesText" />
    </template>

  </potager-modal>
</template>

<script>
import PotagerModal from 'UI/PotagerModal';
import PotagerButton from 'UI/PotagerButton';

export default {

  components: {
    PotagerModal,
    PotagerButton,
  },

  props: {
    text: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    buttonYesText: {
      type: String,
      required: false,
      default: 'Oui',
    },
    buttonNoText: {
      type: String,
      required: false,
      default: 'Non',
    },
    yesCallback: {
      type: Function,
      required: true,
    },
    noCallback: {
      type: Function,
      required: true,
    },
    noIsLoading: {
      type: [String, Array],
      required: false,
      default: () => ([]),
    },
    yesIsLoading: {
      type: [String, Array],
      required: false,
      default: () => ([
        'CANCEL_ORDER_ACTION',
      ]),
    },
  },

  methods: {
    yes() {
      this.yesCallback(() => {
        this.$modal.close();
      });
    },
    no() {
      this.noCallback(() => {
        this.$modal.close();
      });
    },
  },

};
</script>
