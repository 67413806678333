import { mapActions, mapGetters } from 'vuex';
import { OPEN_POPUP_ACTION } from 'Stores/types/popupsActionsTypes';
import { POPUP_NEWSLETTER_NAME } from 'Classes/Constants';
import NewsletterSection from 'Components/newsletter/NewsletterSection';

export default {
  data() {
    return {
      hasIntersected: false,
    };
  },

  computed: {
    ...mapGetters('session', [
      'getOptanonChoiceSaved',
    ]),
    ...mapGetters('user', [
      'isFirstOrder',
    ]),
    showPopinCondition() {
      return this.getOptanonChoiceSaved && this.hasIntersected && this.isFirstOrder;
    },
  },

  methods: {
    ...mapActions('popups', [
      OPEN_POPUP_ACTION,
    ]),
  },

  watch: {
    showPopinCondition: {
      handler(condition) {
        if (condition) {
          setTimeout(() => {
            this.OPEN_POPUP_ACTION({
              name: POPUP_NEWSLETTER_NAME,
              component: NewsletterSection,
              expiration: 1,
            });
          }, 5000);
        }
      },
      immediate: true,
    },
  },
};
