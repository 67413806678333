export default {
  name: 'email',

  valid(value, constraint) {
    if (!value) return null;

    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (regex.test(String(value).toLowerCase())) return null;

    return {
      field: constraint.field,
      message: "Cette valeur n'est pas un email valide",
      code: null,
    };
  },
};
