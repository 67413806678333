<template>
  <div class="panel-section mt-6">
    <p class="panel-section__subtitle">
      Récapitulatif
    </p>

    <potager-receipt-details
      :lines="receiptLines"
      class="mb-4" />

    <subscriber-benefits-navigator :order="order" />

    <template
      v-if="order.isEditable || order.coupons.length">
      <div class="panel-section__separator" />

      <p class="panel-section__subtitle flex justify-between">
        Code promo

        <potager-button
          v-if="order.isEditable"
          :to="{
            name: 'mon-compte_orders_coupons',
            params: { orderId: order.id },
          }"
          class="underline not-italic"
          label="Ajouter"
          size="small"
          theme="link">
          {{ order?.coupons.length ? 'Modifier' : 'Ajouter' }}
        </potager-button>
      </p>

      <potager-receipt-details
        v-if="order?.coupons.length"
        :lines="couponsLines" />
    </template>

    <div class="panel-section__separator" />

    <potager-receipt-details
      :lines="[totalLine]" />
  </div>
</template>

<script>
import PotagerReceiptDetails from 'UI/PotagerReceiptDetails';
import PotagerButton from 'UI/PotagerButton';

import SubscriberBenefitsNavigator from 'Components/subscriberBenefits/SubscriberBenefitsNavigator';

import { getCouponsListLines, getReceiptLines, getTotalLine } from 'Classes/utils/ReceiptDetailsUtils';

export default {

  components: {
    PotagerButton,
    SubscriberBenefitsNavigator,
    PotagerReceiptDetails,
  },

  props: {
    order: {
      type: Object,
      required: true,
    },
  },

  computed: {
    receiptLines() {
      return getReceiptLines(this.order);
    },
    couponsLines() {
      return getCouponsListLines(this.order);
    },
    totalLine() {
      return getTotalLine(this.order);
    },
  },
};
</script>
