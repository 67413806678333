export const test = ({
  rootGetters,
  expectedValue
}) => {
  return !!rootGetters['session/getRegionId'] === expectedValue;
};

export default {
  type: 'hasRegion',
  values: [true, false],
  test,
};
