/**
 * Ce module isole l'accès au store pour éviter les embrouilles
 * avec les dépendances circulaires.
 * On importe le store seulement quand on en a besoin
 */
import store from 'Stores';

export const storeGetters = (getterName, payload = null) => {
  return payload ? store.getters[getterName](payload) : store.getters[getterName];
};

export const storeDispatch = (actionName, payload = null) => {
  return store.dispatch(actionName, payload);
};

export const storeCommit = (mutationName, payload = null) => {
  return store.commit(mutationName, payload);
};
