<template>
  <panel
    :back-route="{ name: 'mon-compte' }"
    title="Notifications">
    <div
      v-for="(group, indexgroup) in getDisplayedNotifs"
      :key="indexgroup"
      class="panel-section">
      <p class="panel-section__p">
        <b>{{ group.title }}</b>
      </p>

      <div class="panel-section__line">
        <potager-switch
          v-if="group.items.length > 1"
          :is-disabled="isLoading"
          :model-value="isOptinEmailAllChecked(indexgroup)"
          class="mt-0"
          @update:modelValue="onAllEmailCheck(indexgroup)">
          Tous
        </potager-switch>

        <div
          :class="[{'border-l-2 ml-3 pl-4 border-white-smoke': group.items.length > 1}]">
          <potager-switch
            v-for="(notif, index) in group.items"
            :key="index"
            :is-disabled="isLoading"
            :model-value="notif.value"
            class="mt-0"
            @update:modelValue="onClickInputSwitch(indexgroup, index, $event)">
            {{ notif.message }}
          </potager-switch>
        </div>
      </div>
    </div>
  </panel>
</template>

<script>
import { mapGetters } from 'vuex';

import PotagerSwitch from 'UI/Form/PotagerSwitch';

import Panel from 'Components/panel/Panel';

import { UPDATE_OPTINS_ACTION } from 'Stores/types/userActionsTypes';

import MetaInfosService from 'Classes/services/MetaInfoService';

export const notifs = [
  {
    title: 'Je souhaite recevoir par e-mail :',
    groupName: 'email',
    items: [
      {
        value: false,
        message: 'Un rappel pour personnaliser votre commande avant la date limite',
        key: 'optinRappelOrderEmail',
      },
      {
        value: false,
        message: 'La composition hebdomadaire des paniers et de la boutique',
        key: 'optinCompositionBoxEmail',
      },
      {
        value: false,
        message: 'Les contenus et offres exclusives de Potager City',
        key: 'optinCommunicationCampaign',
      },
      {
        value: false,
        message: 'Nos meilleurs conseils et recettes pour conserver et cuisiner les produits de votre panier le jour de sa livraison',
        key: 'optinGazetteEmail',
      },
      {
        value: false,
        message: 'Les bons plans et contenus exclusifs de nos partenaires',
        key: 'optinPartnership',
      },
    ],
  },
  {
    title: 'Je souhaite recevoir par SMS :',
    groupName: 'sms',
    items: [
      {
        value: false,
        message: 'Des exclusivités et promotions sur vos prochaines commandes',
        key: 'optinCommunicationCampaignSms',
      },
    ],
  },
  {
    title: 'Je souhaite recevoir par notifications pushs de l\'app mobile :',
    groupName: 'mobile',
    items: [
      {
        value: false,
        message: 'Un rappel pour personnaliser votre commande avant la date limite',
        key: 'optinRappelOrderPushnotif',
      },
      {
        value: false,
        message: 'Un message pour me signaler que ma commande a été confirmée',
        key: 'optinOrderValidatedPushnotif',
      },
      {
        value: false,
        message: 'Un message pour me signaler que ma commande a été livrée',
        key: 'optinOrderShippedPushnotif',
      },
      {
        value: false,
        message: 'La composition hebdomadaire des paniers et de la boutique',
        key: 'optinCompositionBoxPushnotif',
      },
      {
        value: false,
        message: 'Les contenus et offres exclusives de Potager City',
        key: 'optinCommunicationAppMobile',
      },
      {
        value: false,
        message: 'Nos meilleurs conseils et recettes pour conserver et cuisiner les produits de votre panier le jour de sa livraison',
        key: 'optinGazettePushnotif',
      },
      {
        value: false,
        message: 'Les bons plans et contenus exclusifs de nos partenaires',
        key: 'optinPartnershipPushnotif',
      },
    ],
  },
];

export default {

  components: {
    Panel,
    PotagerSwitch,
  },

  data: () => ({
    notifs,
  }),

  computed: {
    ...mapGetters('user', [
      'getOptins',
      'getHasMobileApp',
      'getPhone',
    ]),
    isLoading() {
      return this.$wait.is(UPDATE_OPTINS_ACTION);
    },
    getDisplayedNotifs() {
      return this.notifs.filter((notif) => {
        if (notif.groupName === 'mobile' && !this.getHasMobileApp) return false;
        return !(notif.groupName === 'sms' && !this.getPhone);
      });
    },
  },

  watch: {
    getOptins() {
      this.setViewOptins();
    },
  },

  methods: {
    isOptinEmailAllChecked(indexGroup) {
      let isChecked = true;

      this.getDisplayedNotifs[indexGroup].items.forEach((elem) => {
        if (elem.value === false) {
          isChecked = false;
        }
      });
      return isChecked;
    },
    collectOptinsForBackEnd() {
      const r = {};

      this.notifs.forEach((group) => {
        group.items.forEach((item) => {
          r[item.key] = item.value;
        });
      });

      return r;
    },
    setViewOptins() {
      this.notifs.forEach((group) => {
        group.items.forEach((item) => {
          if (typeof this.getOptins[item.key] !== 'undefined') {

            item.value = this.getOptins[item.key];
          }
        });
      });
    },
    onClickInputSwitch(groupIndex, index, value) {
      const newObj = this.getDisplayedNotifs[groupIndex].items[index];
      newObj.value = value;
      this.getDisplayedNotifs[groupIndex].items[index] = newObj;
      this.$store.dispatch(`user/${UPDATE_OPTINS_ACTION}`, this.collectOptinsForBackEnd());
    },
    onAllEmailCheck(groupIndex) {
      const currentValue = this.isOptinEmailAllChecked(groupIndex);

      this.getDisplayedNotifs[groupIndex].items.forEach((elem) => {
        elem.value = !currentValue;
      });

      this.$store.dispatch(`user/${UPDATE_OPTINS_ACTION}`, this.collectOptinsForBackEnd());
    },
  },

  mounted() {
    if (Object.values(this.getOptins).length) {
      this.setViewOptins();
    }
  },

  head: MetaInfosService.generate({
    title: 'Notifications',
  }),

};
</script>
