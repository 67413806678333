<template>
  <ul class="product-detail__tags">
    <potager-badge
      v-if="primaryTag"
      :theme="primaryTag.color"
      tag="li">
      {{ primaryTag.label }}
    </potager-badge>

    <potager-badge
      v-for="(tag, index) in tags"
      :key="index"
      :theme="tag.color"
      tag="li">
      <template
        v-if="tag.icon"
        #icon>
        <component :is="tag.icon" />
      </template>

      {{ tag.label }}
    </potager-badge>
  </ul>
</template>

<script>
import IconPtcyBio from 'Icons/regular/IconPtcyBio';

import PotagerBadge from 'UI/PotagerBadge';

export default {

  components: {
    IconPtcyBio,
    PotagerBadge,
  },

  props: {
    box: {
      type: Object,
      required: true,
    },
  },

  computed: {
    tags() {
      const { tags } = this.box;
      return tags?.sort((a) => (a.type === 'primary' ? -1 : 1));
    },
    primaryTag() {
      const localTag = this.box?.isLocal ? {
        label: 'Local',
        color: 'british-racing',
      } : null;
      const voucherTag = this.box?.isVoucher ? {
        label: 'Bons d\'achat',
        color: 'british-racing',
      } : null;

      return localTag || voucherTag;
    },
  },
};
</script>
