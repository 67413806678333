<template>
  <panel
    :back-route="backRoute"
    is-bianca
    title="Cartes de paiement">
    <div class="panel-section">
      <div class="panel-section__line">
        <bank-cards-selection-list
          v-if="getBankCards.length > 0"
          :selected-card-id="cardId"
          @onChange="onChange" />

        <potager-button
          v-if="cardId"
          :to="{
            ...backRoute,
            params: { cardId },
          }"
          class="mt-4"
          full-width
          label="Choisir cette carte"
          theme="wild-willow" />
      </div>

      <potager-navigator :to="{ name: 'basket_payment_add_card' }">
        Ajouter une carte de paiement
      </potager-navigator>
    </div>
  </panel>
</template>

<script>
import { mapGetters } from 'vuex';
import PotagerButton from 'UI/PotagerButton';
import PotagerNavigator from 'UI/PotagerNavigator';
import Panel from 'Components/panel/Panel';
import BankCardsSelectionList from 'Components/bankCard/BankCardsSelectionList';

export default {

  components: {
    PotagerButton,
    PotagerNavigator,
    Panel,
    BankCardsSelectionList,
  },

  data: () => ({
    backRoute: { name: 'basket_payment' },
    cardId: null,
  }),

  computed: {
    ...mapGetters('user', [
      'getBankCards',
    ]),
  },

  methods: {
    onChange(val) {
      this.cardId = val;
    },
  },

  mounted() {
    this.cardId = this.$potagerRoute?.params?.cardId;
  },

};
</script>
