import { mapGetters } from 'vuex';
import { CLEAR_SUBSCRIPTION_BASKET_ACTION } from 'Stores/types/subscriptionBasketActionsTypes';

import ModalInfo from 'Modals/ModalInfo';

import { inAppWebView } from 'Classes/ConfigurationManager';

export default {
  computed: {
    ...mapGetters('session', [
      'isLoggedIn',
    ]),
    ...mapGetters('user', [
      'getSubscriptionBasket',
      'getRegionId',
      'isSubscriptionActive',
    ]),
    ...mapGetters('tunnel', [
      'getStepRouteByName',
    ]),
  },

  methods: {
    // Si un utilisateur est
    // - loggué
    // - non-abonné
    // - avec un panier d'abonnement valide (panier + pdr)
    // - pas déjà dans le tunnel ou sur les CGVs
    // => Alors on lui propose de retourner à l'étape de paiement du tunnel.
    // => S'il annule, on supprime son panier d'abonnement.
    trySubscriptionRecovery() {
      // if webview, we don't show the modal
      if (inAppWebView()) return;
      // Si on est déjà dans le tunnel, on arrète ici
      // Idem pour les CGVs, pour éviter que la modal pop lors des redirections du tunnel vers les CGVs
      if (this.$route.matched?.filter((r) => ['tunnel', 'cgv'].includes(r.name))?.length) return;
      // On ajoute une 2e vérification "au cas ou",
      // il arrive que la modal pop quand même... ¯\_(ツ)_/¯
      if (document.location.pathname.match(/\/(abonnement|cgv)/)) return;

      // Si l'utilisateur n'est pas loggué ou s'il est déjà abonné, on arrète ici
      if (!this.isLoggedIn || this.isSubscriptionActive) return;

      const gdd = this.getSubscriptionBasket?.customerSubscription?.groupDeliveryDay;
      const product = this.getSubscriptionBasket?.customerSubscription?.product;
      const hasProduct = product?.id;
      const hasGDD = gdd?.id;

      // On considère le créneau valide si :
      // - il a un ID
      // - il est dans la même région que le user
      // - il n'est pas considéré comme full pour l'abonnement
      // - il a un timeSlot (si OK) ou un notFullTimeslot (si full mais pas pour l'abo)
      const isGDDValid = hasGDD
        && this.getRegionId === gdd.regionId
        && !gdd.isFullForSubscription
        && (gdd.isFull ? gdd.notFullTimeslot : gdd.timeSlot);

      // Si le panier d'abo ne contient pas de box ou si on considère que le point de retrait
      // n'est pas valide, alors on s'arrète ici
      if (!hasProduct || !isGDDValid) return;

      // Tout est OK, on affiche alors la modale
      // S'il valide, on le redirige à l'étape de paiement du tunnel
      // Si annule, on nettoie son panier d'abo pour ne pas afficher de nouveau cette modale plus tard.
      this.$modal.open(ModalInfo, {
          title: 'Il est encore temps de valider votre&nbsp;commande&nbsp;!&nbsp;🌱',
          text: `
          Nous avons conservé le panier et le point relais que vous aviez choisis pour votre abonnement.
          <br>
          Plus qu’une étape avant de recevoir vos fruits & légumes en direct des producteurs pour toute la semaine ! 👩🏼‍🌾
        `,
          closeBtnText: 'C’est parti',
          cancelBtnText: 'Non merci',
          showCancel: true,
        },
        (e) => {
          switch (e.params?.type) {
            case 'close':
              this.$router.push(this.getStepRouteByName(this.$mq.bp1024 ? 'subscriptionSummary' : 'subscriptionPayment'));
              break;
            case 'cancel':
            case 'dismiss':
              this.$store.dispatch(`subscriptionBasket/${CLEAR_SUBSCRIPTION_BASKET_ACTION}`);
              break;
            default:
              break;
          }
        });
    },
  },
};
