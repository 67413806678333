import webviewGuard from 'Router/guards/webviewGuard';

import StaticPage from 'Pages/static/StaticPage';
import PaymentMethodAddPage from 'Pages/static/webview/PaymentMethodAddPage';
import Secure3DPage from 'Pages/static/webview/3DSecurePage';

const defaultMeta = {
  scrollToTop: true,
  header: false,
  footer: false,
  webview: true,
  requiresAuth: true,
  disable401: true,
};

// see PanelDeliveryPointsRoutes.js for delivery points webviews
export default {
  name: 'webview',
  path: '/webview',
  component: StaticPage,
  beforeEnter: webviewGuard, // add ?utm_source=app to url to test without been redirected
  children: [
    {
      name: 'payment-methods_add',
      path: 'paiement/:name/ajouter',
      components: {
        content: PaymentMethodAddPage,
      },
      meta: {
        ...defaultMeta,
        isWindowHeight: true,
      },
    },
    {
      name: 'payment-methods_3ds',
      path: 'paiement/3ds/:orderId',
      components: {
        content: Secure3DPage,
      },
      meta: {
        ...defaultMeta,
        isWindowHeight: true,
      },
    },
  ],
};
