<template>
  <potager-switch-button
    v-model="typeSwitchValue"
    class="mb-4">
    <template #left>
      Point&nbsp;relais
    </template>

    <template #right>
      Entreprise
    </template>
  </potager-switch-button>

  <transition
    mode="out-in"
    name="fade-fast-translate-y">
    <dlp-search-public
      v-if="typeSwitchValue"
      :context="context"
      @onSearch="onSearchPublic" />

    <dlp-search-private
      v-else
      :context="context"
      @onSearch="onSearchPrivate" />
  </transition>
</template>

<script>
import PotagerSwitchButton from 'UI/PotagerSwitchButton.vue';
import DlpSearchPrivate from 'Components/deliveryPoint/step2/DlpSearchPrivate';
import DlpSearchPublic from 'Components/deliveryPoint/step2/DlpSearchPublic';

import {
  DELIVERY_FUNCTIONAL_TYPE_PRIVATE_PATH,
  DELIVERY_FUNCTIONAL_TYPE_PUBLIC_PATH
} from 'Classes/Constants';
import { getDlpDetailsRoute, getDlpListRoute } from 'Classes/utils/DlpRouteUtils';
import { DELIVERY_PRIVATE_TYPE, DELIVERY_PUBLIC_TYPE } from 'Classes/workflow/TunnelTypesConstants';

import MobileAppMixin from 'Mixins/MobileAppMixin';

export default {
  name: 'DlpSearch',

  mixins: [
    MobileAppMixin,
  ],

  components: {
    DlpSearchPublic,
    DlpSearchPrivate,
    PotagerSwitchButton
  },

  props: {
    context: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      typeSwitchValue: this.$potagerRoute?.params?.type === DELIVERY_FUNCTIONAL_TYPE_PUBLIC_PATH,
    };
  },

  watch: {
    typeSwitchValue(type) {
      this.$router.push({
        ...this.$potagerRoute,
        params: {
          ...this.$potagerRoute.params,
          type: type ? DELIVERY_FUNCTIONAL_TYPE_PUBLIC_PATH : DELIVERY_FUNCTIONAL_TYPE_PRIVATE_PATH,
        },
      });
    },
  },

  methods: {
    onSearchPublic({ result }) {
      // if selection is a store
      let route;
      if (result.groupDeliveryDay) {
        // it's a store
        if (this.isInAppWebView) {
          this.redirectWebApp({
            dlpId: result.id,
            dlpName: result.groupName,
            dlpGroupCode: result.groupCode,
            regionId: result.regionId,
          });
        } else {
          route = getDlpDetailsRoute({
            context: this.context,
            deliveryPoint: result,
            type: DELIVERY_PUBLIC_TYPE,
            currentRoute: this.$potagerRoute,
          });
        }
      } else {
        // it's a address
        const {
          lat,
          lng,
        } = result.geometry.location;
        const params = {
          latlng: `${lat},${lng}`,
        };
        route = getDlpListRoute({
          context: this.context,
          type: DELIVERY_PUBLIC_TYPE,
          params,
          currentRoute: this.$potagerRoute,
        });
      }

      this.$router.push(route);
    },
    onSearchPrivate({ search }) {
      this.$router.push(getDlpListRoute({
        context: this.context,
        type: DELIVERY_PRIVATE_TYPE,
        params: {
          search,
        },
        currentRoute: this.$potagerRoute,
      }));
    },
  },
};
</script>
