<template>
  <payment-processing class="mx-auto text-center mt-6" />
</template>

<script>
import { mapGetters } from 'vuex';

import MobileAppMixin from 'Mixins/MobileAppMixin';
import PaymentMethodsMixin from 'Mixins/payments/PaymentMethodsMixin';

import PaymentProcessing from 'Components/paymentMethods/PaymentProcessing';

export default {
  components: { PaymentProcessing },

  data() {
    return {
      success: false,
    };
  },

  mixins: [
    MobileAppMixin,
    PaymentMethodsMixin,
  ],

  computed: {
    ...mapGetters('user', [
      'isUserLoaded',
      'getOrderById',
    ]),
    orderId() {
      return this.$route.params.orderId;
    },
    order() {
      const { orderId } = this.$route.params;
      return this.getOrderById(orderId);
    },
    checkConditions() {
      return this.isUserLoaded && !!this.order;
    },
  },
  watch: {
    checkConditions: {
      handler(check) {
        if (check) {
          this.do3DS();
        }
      },
      immediate: true,
    },
    isUserLoaded: {
      handler(loaded) {
        this.$nextTick(() => {
          if (loaded && !this.order) {
            this.redirectWebApp({
              success: false,
              error: 'order not found',
              orderId: this.orderId,
            });
          }
        });
      },
      immediate: true,
    },
  },
  methods: {
    do3DS() {
      if (!this.isPaymentLoading) {
        this.doOrderRequiresAction(this.order)
          .then(() => {
            this.redirectWebApp({
              success: true,
              orderId: this.orderId,
            });
          })
          .catch((err) => {
            this.redirectWebApp({
              success: false,
              error: err,
              orderId: this.orderId,
            });
          });
      }
    },
  },
};
</script>
