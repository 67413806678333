<template>
  <p
    :class="[
      'potager-info',
      `potager-info--${size}`,
      `potager-info--${status}`,
      `potager-info--${fontStyle}`,
      `potager-info--align-${align}`,
    ]">
    <template v-if="hasIcon">
      <span class="potager-info__icon">
        <slot name="icon" />
      </span>
    </template>

    <span class="flex-1">
      <slot />
    </span>
  </p>
</template>

<script>
import { propsBuilder } from 'UI/Tools';

export const props = {
  size: {
    type: String,
    required: false,
    default: 'default',
    values: ['default', 'small'],
  },
  status: {
    type: String,
    required: false,
    default: 'default',
    values: ['default', 'info', 'warning', 'success', 'legend'],
  },
  fontStyle: {
    type: String,
    required: false,
    default: 'italic',
    values: ['normal', 'italic'],
  },
  align: {
    type: String,
    required: false,
    default: 'start',
    values: ['start', 'center', 'end'],
  },
};

export default {

  props: propsBuilder(props),

  computed: {
    hasIcon() {
      return this.$slots.icon;
    },
  },

};
</script>
