<template>
  <potager-container>
    <div class="py-8 mx-auto max-w-1024">
      <potager-form
        id="api-switcher"
        @onSubmit="onSubmit">
        <potager-input
          v-model="value"
          class="text-charcoal"
          inline-submit="OK"
          placeholder="http://..."
          reset
          @onReset="onReset" />
      </potager-form>

      <potager-row
        :nowrap="!$mq.bp640"
        class="mt-1"
        condensed>
        <potager-col
          v-for="(shortcut, i) in shortcuts"
          :key="i"
          col="auto">
          <potager-button
            :theme="shortcut.url === value ? 'go-green' : 'white'"
            size="small"
            @onClick="onClick(shortcut.url)">
            {{ shortcut.label }}
          </potager-button>
        </potager-col>

        <potager-col col="stretch">
          <potager-button
            :full-width="!$mq.bp640"
            size="small"
            theme="stroke-white"
            @onClick="share">
            Partager
          </potager-button>
        </potager-col>
      </potager-row>

      <potager-row class="mt-4">
        <potager-col>
          <payment-gateway-switch />
        </potager-col>
      </potager-row>
    </div>
  </potager-container>
</template>

<script>
import { shortcuts } from 'Classes/ApiSwitcher';

import PotagerContainer from 'UI/PotagerContainer';
import PotagerButton from 'UI/PotagerButton';
import PotagerRow from 'UI/PotagerRow';
import PotagerCol from 'UI/PotagerCol';
import PotagerModal from 'UI/PotagerModal';
import PotagerForm from 'UI/Form/PotagerForm';
import PotagerInput from 'UI/Form/PotagerInput';

import PaymentGatewaySwitch from 'Components/paymentMethods/PaymentGatewaySwitch';

export default {

  components: {
    PaymentGatewaySwitch,
    PotagerContainer,
    PotagerInput,
    PotagerButton,
    PotagerForm,
    PotagerRow,
    PotagerCol,
  },

  data: () => ({
    value: null,
    shortcuts,
  }),

  methods: {
    onSubmit(e) {
      if (e || this.value) {
        localStorage.setItem('api-switcher', e || this.value);
        this.refresh();
      }
    },
    onClick(e) {
      if (!e) return;

      if (e === this.value) {
        this.onReset();
      } else {
        this.onSubmit(e);
      }
    },
    onReset() {
      localStorage.removeItem('api-switcher');
      this.refresh();
    },
    refresh() {
      document.location.reload();
    },
    share() {
      const url = new URL(document.location.href);
      if (this.value) url.searchParams.set('apiswitcher', this.shortcuts.filter((e) => e.url === this.value)?.[0]?.label);
      this.$modal.open({
        components: {
          PotagerModal,
          PotagerInput
        },
        template: `
          <potager-modal without-header without-footer>
            <template #body>
              <p class="text-left text-xs.5 mb-2">
                En partageant cette URL, vous arriverez directement sur la page souhaitée
                et avec la version de l'API sélectionnée.
              </p>
              <potager-input value="${url}" />
            </template>
          </potager-modal>
        `,
      });
    },
  },

  mounted() {
    this.value = localStorage.getItem('api-switcher');
  },
};
</script>
