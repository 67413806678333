<template>
  <potager-row
    ref="collapsibleBody"
    class="subscriber-bar__row subscriber-bar__row--dlp">
    <potager-col
      bp768="12"
      class="subscriber-bar__col subscriber-bar__preview--box"
      col="4">
      <subscriber-bar-box-preview :routes="routes" />
    </potager-col>

    <template v-if="shouldShowDetails">
      <potager-col
        bp768="12"
        class="subscriber-bar__col"
        col="4">
        <subscriber-bar-item
          :legend="deliveryPointAddress"
          :redirect="routes.mySubscriptionOrder"
          :text="deliveryPointName"
          ellipsis>
          <template #icon>
            <icon-home v-if="subscriptionOrderOrSubscription.deliveryPoint.isHomeDelivery" />
            <icon-shop v-else />
          </template>
        </subscriber-bar-item>
      </potager-col>

      <potager-col
        v-if="getNextDeliveryDate"
        bp768="12"
        class="subscriber-bar__col"
        col="4">
        <subscriber-bar-item
          :legend="getOrderNextDeliveryHours(getSubscriptionOrder)"
          :redirect="routes.mySubscriptionOrder"
          :text="tmsToFormat(getNextDeliveryDate, 'iiii dd MMMM')"
          ellipsis>
          <template #icon>
            <icon-calendar-check />
          </template>
        </subscriber-bar-item>
      </potager-col>
    </template>

    <potager-col
      v-if="shouldShowOrderNote"
      class="subscriber-bar__col"
      col="12">
      <subscriber-bar-item
        :is-warning="getOrderTimeSlot(subscriptionOrderOrSubscription)?.hasSubstitute"
        :legend="getOrderNote(subscriptionOrderOrSubscription)"
        :redirect="routes.mySubscriptionOrder"
        ellipsis
        text="Remarque">
        <template #icon>
          <icon-information-circle />
        </template>
      </subscriber-bar-item>
    </potager-col>

    <subscriber-bar-message :routes="routes" />
  </potager-row>

  <div
    v-if="this.$mq.bp768 && !isCancel"
    class="subscriber-bar__preview__collapsible__toggler-wrapper">
    <potager-button
      :label="showDetails ? 'Réduire' : 'Voir plus'"
      class="subscriber-bar__preview__collapsible__toggler"
      size="small"
      theme="white"
      @onClick="toggleDetails" />
  </div>
</template>

<script>
import SubscriptionMixin from 'Mixins/SubscriptionMixin';

import IconShop from 'Icons/regular/IconShop';
import IconHome from 'Icons/regular/IconHome';
import IconCalendarCheck from 'Icons/regular/IconCalendarCheck';
import IconInformationCircle from 'Icons/regular/IconInformationCircle';

import PotagerButton from 'UI/PotagerButton';
import PotagerRow from 'UI/PotagerRow';
import PotagerCol from 'UI/PotagerCol';

import SubscriberBarItem from 'Components/subscriberBar/SubscriberBarItem';
import SubscriberBarBoxPreview from 'Components/subscriberBar/SubscriberBarBoxPreview';
import SubscriberBarMessage from 'Components/subscriberBar/SubscriberBarMessage';

import { isOrderCanceledStatus } from 'potagerlogic/dist/Utils/Order/OrderStatus';

import { getOrderNextDeliveryHours, getOrderNote, getOrderTimeSlot } from 'Classes/utils/OrderUtils';
import { tmsToFormat } from 'Classes/utils/DatesUtils';

export default {
  mixins: [
    SubscriptionMixin,
  ],

  components: {
    SubscriberBarMessage,
    PotagerButton,
    SubscriberBarBoxPreview,
    IconShop,
    IconHome,
    IconCalendarCheck,
    IconInformationCircle,
    SubscriberBarItem,
    PotagerRow,
    PotagerCol,
  },

  props: {
    routes: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      showDetails: false,
    };
  },

  computed: {
    shouldShowDetails() {
      return (!this.$mq.bp768 || this.showDetails) && (!this.getSubscriptionOrder || !isOrderCanceledStatus(this.getSubscriptionOrder));
    },
    shouldShowOrderNote() {
      const orderNoteExists = !!getOrderNote(this.subscriptionOrderOrSubscription);
      const isCanceled = !this.getSubscriptionOrder || isOrderCanceledStatus(this.getSubscriptionOrder);
      const isSmallScreenOrDetailsShown = !this.$mq.bp768 || this.showDetails;
      const hasSubstitute = getOrderTimeSlot(this.subscriptionOrderOrSubscription)?.hasSubstitute;

      return orderNoteExists && !isCanceled && (isSmallScreenOrDetailsShown || hasSubstitute);
    },
    deliveryPointName() {
      const {
        deliveryPoint,
        address
      } = this.subscriptionOrderOrSubscription;
      if (deliveryPoint.isHomeDelivery) {
        const {
          firstname,
          lastname
        } = address;
        return `${firstname} ${lastname}`;
      }
      return deliveryPoint.groupName;
    },
    deliveryPointAddress() {
      const {
        deliveryPoint,
        address
      } = this.subscriptionOrderOrSubscription;
      if (deliveryPoint.isHomeDelivery) {
        const {
          streetName,
          postalCode,
          city
        } = address;
        return `${streetName} ${postalCode} ${city}`;
      }
      return deliveryPoint.groupDeliveryAddress;
    },
  },

  methods: {
    tmsToFormat,
    getOrderTimeSlot,
    getOrderNote,
    getOrderNextDeliveryHours,
    isOrderCanceledStatus,
    toggleDetails() {
      this.showDetails = !this.showDetails;
    },
  },
};
</script>
