<template>
  <div>
    <heading
      :background="null"
      :modifiers="['text-black', 'smaller']">
      <template #title>
        Politique de gestion des Cookies
      </template>
    </heading>

    <blog-body>
      <blog-section>
        <cms>
          <h2>
            1. Qu’est-ce qu’un cookie ?
          </h2>
          <p>
            Parmi les Données Personnelles, Potager City est amenée à collecter des données résultant du dépôt de
            cookies.
          </p>
          <p>
            Un cookie est un petit fichier texte, image ou logiciel, qui est placé et stocké sur l’ordinateur ou le
            smartphone de l’Utilisateur ou du Membre Associé, ainsi que sur tout appareil leur permettant de naviguer
            sur Internet (« <b>Terminal</b> »).
          </p>
          <p>
            Très utiles, les cookies permettent à un site Internet de reconnaître l’Utilisateur ou le Membre de signaler
            leur passage sur telle ou telle page et de leur apporter ainsi un service additionnel : l’amélioration de
            leur confort de navigation, la sécurisation de leur connexion ou l’adaptation du contenu d’une page à leurs
            centres d’intérêt.
          </p>
          <p>
            Les informations enregistrées par les cookies, pendant une durée de validité limitée, portent notamment sur
            les pages visitées, les publicités sur lesquelles l’Utilisateur ou le Membre ont cliqué, le type de
            navigateur qu’ils utilisent, leur adresse IP, les informations qu’ils ont saisies sur les Services (afin
            d‘éviter de les saisir à nouveau).
          </p>
          <p>
            Les cookies ne sont pas des dossiers actifs, et ne peuvent donc pas héberger de virus. Pour en savoir plus,
            vous pouvez vous rendre sur <a
              href="https://www.allaboutcookies.org"
              target="_blank">
              www.allaboutcookies.org
            </a>.
          </p>

          <h2>
            2. A quoi servent les cookies émis sur les Sites ?
          </h2>
          <p>
            Seul l’émetteur d’un cookie est susceptible de lire ou de modifier des informations qui y sont contenues.
          </p>
          <p>
            Les cookies sont utilisés aux fins décrites ci-dessous, sous réserve de vos choix que vous pouvez exprimer
            et modifier à tout moment via les paramètres du logiciel de navigation utilisé lors de votre navigation sur
            les Sites (ci-après les « <b>Cookies</b> »).
          </p>
          <h3>
            a. Les Cookies fonctionnels
          </h3>
          <p>
            Les Cookies fonctionnels permettent d’améliorer les performances de Potager City afin de fournir une
            meilleure utilisation des Sites. Ces Cookies ne requièrent pas l’information de l’Utilisateur et du Membre,
            ni leur accord préalable pour être déposés sur le Terminal.
          </p>
          <p>
            Les Cookies fonctionnels permettent :
          </p>
          <ul>
            <li>
              d’adapter la présentation des Sites aux préférences d’affichage du Terminal (par exemple, langue utilisée,
              résolution d’affichage, système d’exploitation utilisé, etc…) lors des visites des utilisateurs, sur les
              Sites, selon les matériels et les logiciels de visualisation ou de lecture que le Terminal comporte ;
            </li>
            <li>
              de mémoriser les préférences d’utilisation, les paramètres d’affichage et lecteurs que les Utilisateur et
              du Membre utilisent afin de faciliter leur navigation lors de leur prochaine visite sur les Sites. Ils
              permettent notamment l’accès à un espace réservé soumis à un identifiant ou un mot de passe ;
            </li>
            <li>
              de mémoriser les informations saisies sur certains formulaires présents sur le Site afin d’éviter de les
              renseigner de nouveau lors de leur prochaine visite.
            </li>
          </ul>
          <h3>
            b. Les Cookies de mesure d'audience
          </h3>
          <p>
            Afin d’adapter les Sites aux besoins de l’Utilisateur et du Membre, Potager City mesure le nombre de
            visites, le nombre de pages consultées ainsi que l’activité de l’Utilisateur et du Membre sur les Sites et
            leur fréquence de retour. Ces Cookies permettent d’établir des statistiques d’analyse de la fréquentation à
            partir desquels les contenus des Sites sont améliorés en fonction du succès rencontré par telle ou telle
            page auprès des Utilisateurs ou des Membres.
          </p>
          <p>
            Les résultats de ces analyses sont Traitées de manière anonyme et à des fins exclusivement statistiques.
          </p>
          <h3>
            c. Les Cookies publicitaires
          </h3>
          <p>
            Potager City dispose d’emplacements publicitaires sur certains sites Tiers, réseaux partenaires, médias et
            réseaux sociaux comme Facebook ou Google. Ces derniers peuvent être amenés à déposer un Cookie lors de votre
            navigation sur notre Site, notamment afin de limiter le nombre d'affichages des publicités, de personnaliser
            les publicités selon votre comportement, de récolter des statistiques pour optimiser nos campagnes
            publicitaires, etc…
          </p>
          <p>
            Ces Tiers peuvent récolter des Données Personnelles comme votre adresse IP, l'URL de provenance, la date et
            l'heure de visite sur notre Site, les pages consultées, l'identifiant publicitaire Google, l'ID de
            l'utilisateur Facebook, etc…
          </p>
          <p>
            Lorsque des informations susceptibles d’identifier un Utilisateur ou un Membre sont partagées avec les
            réseaux sociaux, elles peuvent soit prendre la forme de l’identifiant de l’Utilisateur ou du Membre tel
            qu’il est inscrit au service du réseau social concerné, soit faire l’objet d’un hachage irréversible
            préalable. C'est le cas de Facebook, qui peut collecter de manière sécurisée certaines Données Personnelles
            renseignées lors de la création d'un Compte Client (adresse de courrier électronique, nom, prénom, etc…).
            Facebook utilise la méthode dite du « hachage », qui permet de transformer les données pour des raisons de
            sécurité. Cela n'a pas pour but de recueillir des données confidentielles, telles que les mots de passe et
            les données financières, mais de mieux faire correspondre les Utilisateurs et membres visitant notre Site à
            celles sur Facebook. Ces Cookies poursuivent une finalité d'analyse de nos performances publicitaires et
            celle du confort de l’Utilisateur et du Membre vis-à-vis des publicités qui lui sont proposées.
          </p>

          <!-- OneTrust Cookies List start -->
          <div id="ot-sdk-cookie-policy" />
          <!-- OneTrust Cookies List end -->

          <h2>
            3. Durée maximale de validité des Cookies
          </h2>
          <p>
            Les Cookies émis sur les Sites sont des Cookies de session (dont la durée est limitée au temps d’une
            connexion aux Sites) et des Cookies persistants (dont la durée, toutefois limitée, est supérieure à la durée
            d’une connexion).
          </p>
          <p>
            Les Cookies de session ne sont actifs que le temps de la visite et sont supprimés lorsque l’Utilisateur ou
            le Membre ferme son navigateur. Les Cookies persistants restent enregistrés sur le disque dur du Terminal de
            l’Utilisateur ou du Membre une fois son navigateur fermé.
          </p>
          <p>
            Conformément à la Règlementation Applicable et aux recommandations de la Commission Nationale Informatique
            et Libertés (« CNIL »), les Cookies sont conservés pour une durée maximale de 13 (treize) mois après leur
            premier dépôt. A l’expiration de ce délai, le Consentement de l’Utilisateur ou du Membre devra être
            recueilli de nouveau pour la collecte des Cookies soumis au Consentement.
          </p>

          <h2>
            4. Vos choix concernant les Cookies
          </h2>
          <p>
            L’ensemble des droits reconnus à l’Article 1.6 ci-dessus sont également applicables à l’Utilisateur ou au
            Membre.
          </p>
          <p>
            Plusieurs possibilités leurs sont offertes pour gérer les Cookies. L’Utilisateur et le Membre comprennent
            que les Cookies améliorent le confort de navigation sur les Sites et sont indispensables pour accéder à
            certains espaces sécurisés. Tout paramétrage que l’Utilisateur ou le Membre peut entreprendre sera
            susceptible de modifier sa navigation sur les Sites et ses conditions d’accès à certains services
            nécessitant l’utilisation de Cookies. Potager City ne saurait être tenue pour responsable des conséquences
            d’un fonctionnement moins efficace des Sites dû à l’impossibilité d’installer ou de lire des Cookies
            nécessaires à leur bon fonctionnement, dès lors que l’Utilisateur ou le Membre les a rejetés ou supprimés.
          </p>
          <p>
            Les Utilisateurs et les Membres peuvent configurer leur logiciel de navigation de manière à ce que les
            Cookies soient enregistrés dans leur Terminal ou, au contraire, rejetés, soit systématiquement, soit selon
            leur émetteur. Les Utilisateurs et les Membres peuvent également configurer leur logiciel de navigation de
            manière à ce que l’acceptation ou le refus des Cookies leur soient proposés ponctuellement, avant qu’un
            Cookie soit susceptible d’être enregistré sur leur Terminal.
          </p>
          <p>
            Le menu d'aide ou la rubrique dédiée de votre navigateur vous permettra de savoir de quelle manière exprimer
            ou modifier vos préférences en matière de Cookies :
          </p>
          <h3>
            a. Si vous utilisez Internet Explorer
          </h3>
          <p>
            Dans le menu « Outils », sélectionnez « Options Internet »<br>
            Cliquez ensuite sur l’onglet « Confidentialité »<br>
            Cliquez sur le bouton « Avancé » pour faire apparaître la fenêtre « Paramètres de confidentialité avancés ».<br>
            Cochez ensuite la case « Ignorer la gestion automatique des cookies », puis sélectionnez « Refuser » et
            sauvegardez vos préférences en cliquant sur « OK ».<br>
            <a
              href="https://support.microsoft.com/fr-fr/help/17442/windows-internet-explorer-delete-manage-cookies"
              target="_blank">
              Consulter l’aide sur le site de Microsoft
            </a>
          </p>
          <h3>
            b. Si vous utilisez Mozilla Firefox
          </h3>
          <p>
            Dans le menu en haut de la page cliquez sur « Outils », puis « Options »<br>
            Sélectionnez l’onglet « Vie privée »<br>
            Paramétrer le menu « Règles de conservation », cliquez sur « utiliser les paramètres personnalisés pour
            l’historique »<br>
            Décochez la case « Accepter les cookies tiers » et sauvegardez vos préférences en cliquant sur « OK ».<br>
            <a
              href="https://support.mozilla.org/fr/kb/activer-desactiver-cookies-preferences?redirectlocale=fr&redirectslug=Activer+et+d%C3%A9sactiver+les+cookies"
              target="_blank">
              Consulter l’aide sur le site de Mozilla
            </a>
          </p>
          <h3>
            c. Si vous utilisez Google Chrome
          </h3>
          <p>
            Dans le menu, cliquez sur « Paramètres » puis sur « Afficher les paramètres avancés »<br>
            Sélectionnez l’onglet « Confidentialité » puis le menu « Paramètres de contenus ».<br>
            Cochez la case « Bloquer les cookies et données de sites tiers » et sauvegardez vos préférences en cliquant
            sur « OK ».<br>
            <a
              href="https://support.google.com/chrome/answer/95647?hl=fr&hlrm=en"
              target="_blank">
              Consulter l’aide sur le site de Google
            </a> (pour votre ordinateur)<br>
            <a
              href="https://support.google.com/chrome/answer/95647?hl=fr&ref_topic=7438325&co=GENIE.Platform%3DAndroid&oco=1"
              target="_blank">
              Consulter l’aide sur le site de Google
            </a> (pour votre smartphone)
          </p>
          <h3>
            d. Si vous utilisez Safari
          </h3>
          <p>
            Dans le menu en haut de la page, cliquez sur « Safari », puis « Préférences »<br>
            Cliquez sur l’onglet « Confidentialité »<br>
            Décochez la case « Accepter les cookies » et sauvegardez vos préférences.<br>
            <a
              href="https://support.apple.com/kb/PH21411?viewlocale=fr_FR&locale=fr_FR"
              target="_blank">
              Consulter l’aide sur le site d’Apple
            </a> (pour votre ordinateur)<br>
            <a
              href="https://support.apple.com/fr-fr/HT201265"
              target="_blank">
              Consulter l’aide sur le site d’Apple
            </a> (pour votre smartphone)
          </p>
          <h3>
            e. Si vous utilisez Opera™
          </h3>
          <p>
            <a
              href="https://help.opera.com/en/latest/web-preferences/#cookies"
              target="_blank">
              https://help.opera.com/en/latest/web-preferences/#cookies
            </a>
          </p>
          <h3>
            f. Si vous utilisez iOS
          </h3>
          <p>
            <a
              href="https://support.apple.com/fr-fr/HT201265"
              target="_blank">
              https://support.apple.com/fr-fr/HT201265
            </a>
          </p>
          <h3>
            g. Si vous utilisez Android
          </h3>
          <p>
            <a
              href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DAndroid&hl=fr"
              target="_blank">
              https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DAndroid&hl=fr
            </a>
          </p>
          <h3>
            h. Si vous utilisez Windows Phone
          </h3>
          <p>
            <a
              href="https://support.microsoft.com/fr-fr/help/17442/windows-internet-explorer-delete-manage-cookies"
              target="_blank">
              https://support.microsoft.com/fr-fr/help/17442/windows-internet-explorer-delete-manage-cookies
            </a>
          </p>
          <p>
            Pour une meilleure connaissance et maîtrise des Cookies de toute origine et pas seulement ceux de notre
            Site, l’Utilisateur et les Membres sont invités à consulter le site Youronlinechoices, édité par
            l’Interactive Advertising Bureau France (IAB) afin de connaitre les entreprises inscrites à cette
            plate-forme et qui offrent la possibilité de refuser ou d’accepter les Cookies utilisés par ces entreprises
            pour adapter les informations de navigation les publicités susceptibles d’être affichées sur le
            Terminal.<br>
            <a
              href="http://www.youronlinechoices.com/be-fr/controler-ses-cookies"
              target="_blank">
              http://www.youronlinechoices.com/be-fr/controler-ses-cookies
            </a>
          </p>
          <p>
            Pour en savoir plus sur les cookies et les droits accordés aux utilisateurs :<br>
            <a
              href="http://www.cnil.fr/vos-droits/vos-traces/les-cookies/"
              target="_blank">
              http://www.cnil.fr/vos-droits/vos-traces/les-cookies/
            </a>
          </p>

          <h2>
            5. Confidentialité et données personnelles
          </h2>
          <p>
            Dans le cadre de l’utilisation des supports numériques de Potager City par les Utilisateurs et les
            Consommateurs, Potager City est amené à collecter et traiter certaines données à caractère personnel les
            concernant.
          </p>
          <p>
            Potager City met à disposition de ses Utilisateurs et ses Consommateurs une
            <potager-link :to="{ name:'confidentialite-et-donnees-personnelles' }">
              politique de confidentialité et des données personnelles.
            </potager-link>
          </p>
          <p>
            Conformément à la réglementation applicable à la protection des données à caractère personnel et en
            particulier le règlement Européen 2016/679 du 27 avril 2016, dit Règlement général sur la protection des
            données (le «&nbsp;RGPD&nbsp;»), les Utilisateurs et Consommateurs disposent d’un droit d’accès, de
            rectification,
            d’effacement, et à la portabilité des données les concernant et peuvent également, pour des motifs
            légitimes, s’opposer au traitement et, dans les cas prévus par la loi, demander la limitation du traitement
            de ces données. Pour exercer ces droits, Potager City invite chaque Utilisateur et Consommateur à contacter
            le service client en utilisant l’un de ces canaux de communication&nbsp;:
          </p>
          <ul>
            <li>
              Par email à <a
                href="mailto:droitsdespersonnes@potagercity.fr"
                target="_blank">
                droitsdespersonnes@potagercity.fr
              </a>
            </li>
            <li>
              Via notre formulaire de contact&nbsp;:
              <potager-link :to="{name:'contact'}">https://www.potagercity.fr/contact/</potager-link>
            </li>
            <li>
              Par courrier&nbsp;: Potager City - Droit sur les Données Personnelles - Lumen Cardinal Workside - 60&nbsp;Quai&nbsp;Perrache
              - 69002&nbsp;Lyon
            </li>
          </ul>
        </cms>
      </blog-section>
    </blog-body>
  </div>
</template>

<script>
import ToolsMixin from 'Mixins/ToolsMixin';

import Heading from 'Components/heading/Heading';
import BlogBody from 'Components/blog/BlogBody';
import BlogSection from 'Components/blog/BlogSection';
import Cms from 'Components/cms/Cms';

export default {

  mixins: [
    ToolsMixin,
  ],

  components: {
    Heading,
    BlogBody,
    BlogSection,
    Cms,
  },

  mounted() {
    if (typeof window.OneTrust?.initializeCookiePolicyHtml === 'function') {
      window.OneTrust.initializeCookiePolicyHtml();
    }
  },

  head() {
    return this.generateMetaIfPanelClosed({
      title: 'Politique de gestion des Cookies',
    });
  },

};
</script>
