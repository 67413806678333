<template>
  <div
    v-if="items && items.length"
    :class="[
      'product-detail__section',
      'product-detail__section--always-with-border',
      'print:hidden',
    ]">
    <h3 class="product-detail__subtitle">
      {{ title }}
    </h3>
    <p
      v-if="subtitle"
      class="product-detail__thirdtitle">
      {{ subtitle }}
    </p>

    <potager-slider
      name="associated-content-slider"
      class="editorial-slider__slider"
      :slides-per-view="{
        default: 1,
        768: 2,
        1024: 3,
      }"
      :items="items"
      overflow-visible
      navigation>
      <template #default="{ item }">
        <card-content
          :content="item"
          condensed
          open-in-flying-page
          :inline="false" />
      </template>
    </potager-slider>

  </div>
</template>

<script>
import PotagerSlider from 'UI/PotagerSlider';
import CardContent from 'Components/cards/CardContent';
import UpsellingSlider from 'Components/sliders/UpsellingSlider.vue';

export default {

  components: {
    UpsellingSlider,
    PotagerSlider,
    CardContent,
  },

  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: false,
      default: null,
    },
    items: {
      type: Array,
      required: true,
    },
    orderId: {
      type: Number,
      required: false,
      default: null,
    },
  },
};
</script>
