<template>
  <div class="bg-bianca text-center p-6 mb-0">
    <div
      :class="[
        'py-6',
        { 'border-b-2 border-white-rock pb-2 mb-5': !isMobileTablet },
      ]">
      <p class="text-xs.5">
        Mon code parrain
      </p>

      <potager-tooltip
        :disable="!codeCopied"
        always
        message="Code&nbsp;copié&nbsp;!"
        theme="dark">
        <component
          :is="canCopy ? 'potager-button' : 'div'"
          ref="sponsorshipCode"
          class="font-extrabold mb-4 text-lg"
          theme="reset"
          @onClick="copyCode">
          {{ getMySponsorshipCode }}
        </component>
      </potager-tooltip>

      <potager-button
        v-if="isMobileTablet"
        :disabled="!canShare"
        full-width
        theme="wild-willow"
        @onClick="shareMyCode">
        Partager mon code
      </potager-button>
    </div>

    <template v-if="!isMobileTablet">
      <p class="font-extrabold text-xl.5 pb-6">
        Partagez votre lien
      </p>

      <div class="relative">
        <potager-input
          ref="shareUrl"
          :model-value="shareUrl"
          readonly
          truncate
          type="text"
          @onClick="$refs.shareUrl.select()" />

        <potager-button
          v-if="canCopy"
          v-gtm="'copier mon lien'"
          :disabled="!canShare"
          full-width
          theme="byzantine"
          @onClick="copyUrl()">
          {{ urlCopied ? 'Copié !' : 'Copier mon lien' }}
        </potager-button>
      </div>

      <p class="font-extrabold text-lg pt-8 pb-6">
        Invitez vos amis
      </p>
      <p class="flex justify-around">
        <s-facebook
          ref="Facebook"
          v-gtm="'shareFacebook'"
          :share-options="{
            url: shareUrl,
            quote: getMessage,
          }">
          <potager-button
            is-circle
            theme="white">
            <template #icon>
              <icon-social-media-facebook class="text-facebook" />
            </template>
          </potager-button>
        </s-facebook>

        <s-whats-app
          ref="Whatsapp"
          :share-options="{
            text: getMessage,
          }">
          <potager-button
            is-circle
            theme="white">
            <template #icon>
              <icon-messaging-whatsapp class="text-whatsapp" />
            </template>
          </potager-button>
        </s-whats-app>

        <s-email
          ref="Email"
          :share-options="{
            subject: '18€ de réduction sur tes premières commandes',
            body: getMessage,
          }">
          <potager-button
            is-circle
            theme="white">
            <template #icon>
              <icon-email-action-unread class="text-charcoal" />
            </template>
          </potager-button>
        </s-email>
      </p>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import ConfigurationManager from 'Classes/ConfigurationManager';

import PotagerButton from 'UI/PotagerButton.vue';
import PotagerInput from 'UI/Form/PotagerInput.vue';
import PotagerTooltip from 'UI/PotagerTooltip.vue';

import IconSocialMediaFacebook from 'Icons/bold/IconSocialMediaFacebook';
import IconSocialMediaTwitter from 'Icons/bold/IconSocialMediaTwitter';
import IconMessagingWhatsapp from 'Icons/bold/IconMessagingWhatsapp';
import IconEmailActionUnread from 'Icons/bold/IconEmailActionUnread';

import {
  SFacebook,
  SWhatsApp,
  SEmail,
} from 'vue-socials';

import { getResolvedUrl } from 'Classes/utils/RouteUtils';

export default {

  components: {
    PotagerTooltip,
    PotagerButton,
    PotagerInput,
    IconSocialMediaFacebook,
    IconSocialMediaTwitter,
    IconMessagingWhatsapp,
    IconEmailActionUnread,
    SFacebook,
    SWhatsApp,
    SEmail,
  },

  data() {
    return {
      urlCopied: false,
      codeCopied: false,
    };
  },

  computed: {
    ...mapGetters('user', [
      'getFirstName',
      'getMySponsorshipCode',
    ]),
    shareUrl() {
      return `${getResolvedUrl('sponsorship')}?code=${this.getMySponsorshipCode}`;
    },
    getMessage() {
      return `Je t’offre 18€ de réduction sur tes premières commandes pour tester Potager City ! Si tu veux profiter de l’offre de parrainage clique ici : ${this.shareUrl}`;
    },
    canShare() {
      return this.getFirstName && this.getMySponsorshipCode;
    },
    canCopy() {
      return navigator.clipboard;
    },
    isMobileTablet() {
      return ConfigurationManager.getByName('isMobileTablet') && navigator.share;
    },
  },

  methods: {
    shareMyCode() {
      navigator.share({
        text: this.getMessage,
      });
    },
    copyUrl() {
      navigator.clipboard?.writeText(this.shareUrl)
        .then(() => {
          this.urlCopied = true;
          setTimeout(() => {
            this.urlCopied = false;
          }, 2000);
        });
    },
    copyCode() {
      navigator.clipboard?.writeText(this.getMySponsorshipCode)
        .then(() => {
          this.codeCopied = true;
          setTimeout(() => {
            this.codeCopied = false;
          }, 2000);
        });
    },
  },
};
</script>
