<template>
  <tunnel-body class="text-center">
    <h1 class="text-xl font-extrabold mb-3 mx-10">
      Votre adresse
    </h1>

    <p class="mb-10">
      👍&nbsp;Vous pourrez modifier votre choix quand vous voudrez.
    </p>

    <dlp-search-public
      :context="context"
      class="mb-8"
      @onSearch="onSearch" />
  </tunnel-body>
</template>

<script>
import { mapGetters } from 'vuex';

import DlpSearchPublic from 'Components/deliveryPoint/step2/DlpSearchPublic';
import TunnelBody from 'Components/tunnel/TunnelBody';
import { Context } from 'potagerlogic/dist/Enums/Context';

export default {
  components: {
    DlpSearchPublic,
    TunnelBody,
  },

  props: {
    context: {
      type: String,
      default: Context.SubscriptionBasket,
    },
  },

  computed: {
    ...mapGetters('tunnel', [
      'getNextStepRoute',
      'getStepRouteByName',
    ]),
  },

  methods: {
    onSearch({ result }) {
      // if selection is a store
      let route;
      if (result.groupDeliveryDay) {
        // it's a store
        // skip to gdd step
        const params = {
          deliveryPointRegionId: result.regionId,
          deliveryPointId: result.id,
        };

        route = this.getStepRouteByName('deliveryPointPublicGdd', { params });
      } else {
        // it's a address
        const {
          lat,
          lng
        } = result.geometry.location;
        const params = {
          latlng: `${lat},${lng}`,
        };

        route = this.getNextStepRoute({ params });
      }

      this.$router.push(route);
    },
  },
};
</script>
