<template>
  <component
    :is="logo"
    class="potager-logo block"
    title="Logo de Potager City"
    :style="{ fontSize: computedHeight }" />
</template>

<script>
import PotagerPicture from 'UI/PotagerPicture';

import { propsBuilder } from 'UI/Tools';

import IconLogoPotagerMini from 'Icons/bold/IconLogoPotagerMini';
import IconLogoPotagerVertical from 'Icons/bold/IconLogoPotagerVertical';
import IconLogoPotagerHorizontal from 'Icons/bold/IconLogoPotagerHorizontal';

export const props = {
  minimal: {
    type: Boolean,
    required: false,
    default: false,
  },
  height: {
    type: Number,
    required: false,
    default: undefined,
  },
  orientation: {
    type: String,
    required: false,
    default: 'horizontal',
    values: ['horizontal', 'vertical'],
  },
};

export default {

  components: {
    PotagerPicture,
  },

  props: propsBuilder(props),

  computed: {
    computedHeight() {
      if (this.height) return `${this.height}px`;
      return this.orientation === 'portrait' ? '4rem' : '2rem';
    },
    logo() {
      if (this.minimal) return IconLogoPotagerMini;
      return this.orientation === 'horizontal' ? IconLogoPotagerHorizontal : IconLogoPotagerVertical;
    },
  },

};
</script>

<style lang="scss" scoped>
.potager-logo {
  color: $britishRacing;
}
</style>
