// plugins to add $events with mitt
import mitt from 'mitt';

export const events = mitt();

export default {
  install(app) {
    app.config.globalProperties.$events = events;
  }
};
