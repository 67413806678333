/**
 * Smoothly scrolls an element horizontally to a specified position.
 *
 * @param {HTMLElement} element - The element to scroll.
 * @param {number} to - The horizontal position to scroll to.
 * @param {number} duration - The duration of the scroll animation in milliseconds.
 * @param {Function} [callback] - Optional callback function to be called after the scroll animation completes.
 */
export const horizontalAnimateScrollTo = (element, to, duration, callback) => {
  const start = element.scrollLeft;
  const change = to - start;
  let currentTime = 0;
  const increment = 20;

  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    return t < 1 ? c / 2 * t * t + b : -c / 2 * (--t * (t - 2) - 1) + b;
  };

  const animateScroll = () => {
    currentTime += increment;
    element.scrollLeft = easeInOutQuad(currentTime, start, change, duration);

    if (currentTime < duration) {
      setTimeout(animateScroll, increment);
    } else if (typeof callback === 'function') {
      callback();
    }
  };

  animateScroll();
};

/**
 * Gets the current vertical scroll position of the window.
 *
 * @returns {number} The vertical scroll position in pixels.
 */
export const getWindowScrollTop = () => {
  return window.pageYOffset || document.documentElement.scrollTop;
};

let scrollPos = 0;
/**
 * Toggles the stickiness of the page, preventing or allowing scrolling.
 *
 * @param {boolean} [yes=true] - If true, the page will be stuck (scrolling disabled). If false, the page will be unstuck (scrolling enabled).
 */
export const stuckPage = (yes = true) => {
  const {
    body,
    documentElement: html
  } = document;
  const stickies = document.querySelectorAll('.sticky-element');

  if (body) {
    const toggleStickiness = (add) => {
      if (add) {
        scrollPos = html.scrollTop || body.scrollTop;
        const documentHeight = body.clientHeight;
        let scrollBarWidth = documentHeight > window.innerHeight ? calculateScrollbarWidth() : 0;

        if (!body.classList.contains('dontmove')) {
          body.classList.add('dontmove');
          body.style.paddingRight = `${scrollBarWidth}px`;
        } else {
          scrollBarWidth = parseInt(body.style.paddingRight, 10);
        }

        stickies.forEach(sticky => {
          if (sticky.style.position === 'absolute') {
            sticky.style.marginBottom = `-${documentHeight - window.innerHeight - scrollPos}px`;
          }
          sticky.style.width = `calc(100% - ${scrollBarWidth}px)`;
        });
      } else {
        body.classList.remove('dontmove');
        body.style.paddingRight = '';
        [html, body].forEach(el => el.scrollTop = scrollPos);
        body.style.top = '';

        stickies.forEach(sticky => {
          sticky.style.width = '';
          sticky.style.marginBottom = '';
        });
      }
    };

    toggleStickiness(yes);
  }
};

/**
 * Calculates the width of the scrollbar.
 *
 * @returns {number} The width of the scrollbar in pixels.
 */
export const calculateScrollbarWidth = () => {
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.width = '100px';
  outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps

  document.body.appendChild(outer);

  const widthNoScroll = outer.offsetWidth;
  // force scrollbars
  outer.style.overflow = 'scroll';

  // add innerdiv
  const inner = document.createElement('div');
  inner.style.width = '100%';
  outer.appendChild(inner);

  const widthWithScroll = inner.offsetWidth;

  // remove divs
  outer.parentNode.removeChild(outer);

  return widthNoScroll - widthWithScroll;
};
