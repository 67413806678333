<template>
  <potager-modal :can-be-closed="false">
    <template #title>
      Ooops !
    </template>

    <template #body>
      Pour accéder à cette page, vous devez être connecté à votre compte.
    </template>

    <template #footer>
      <potager-button
        @onClick="goToHomepage"
        theme="stroke"
        :full-width="true"
        label="Annuler" />
      <potager-button
        @onClick="goToConnexion"
        :full-width="true"
        label="Me connecter" />
    </template>
  </potager-modal>
</template>

<script>
import PotagerModal from 'UI/PotagerModal';
import PotagerButton from 'UI/PotagerButton';

export default {

  components: {
    PotagerModal,
    PotagerButton,
  },

  methods: {
    goToConnexion() {
      this.$router.push({ name: 'login' }).catch(() => {});
      this.$modal.close();
    },
    goToHomepage() {
      this.$router.push({ name: 'home' }).catch(() => {});
      this.$modal.close();
    },
  },

};
</script>
