import ContentPage from 'Pages/content/ContentPage';
import BonCoingHub from 'Pages/content/boncoing/BonCoingHub';
import BonCoingList from 'Pages/content/boncoing/BonCoingList';
import BonCoingRecettePage from 'Pages/content/boncoing/BonCoingRecettePage';
import BonCoingVarietyPage from 'Pages/content/boncoing/BonCoingVarietyPage';
import BonCoingProducteurPage from 'Pages/content/boncoing/BonCoingProducteurPage';
import BonCoingArticlePage from 'Pages/content/boncoing/BonCoingArticlePage';
import BonCoingCustomPage from 'Pages/content/boncoing/BonCoingCustomPage';

export default {
  name: 'content',
  path: '/contenu',
  redirect: { name: 'content-list' },
  component: ContentPage,
  children: [
    /*
     * Hub
     */
    {
      name: 'content-list',
      path: '/contenus/:search?',
      components: {
        content: BonCoingHub,
      },
      props: {
        content: (route) => ({
          search: route.params.search || '',
        }),
      },
      meta: {
        scrollToTop: true,
        type: 'contentlist',
        typeLabel: 'contenus',
        bottomBar: true,
      },
    },

    /*
     * Recettes
     */
    {
      name: 'recettes-list',
      path: '/recettes/:page(\\d+)?/:search?',
      components: {
        content: BonCoingList,
      },
      props: {
        content: (route) => ({
          page: parseInt(route.params.page, 10) || 1,
          search: route.params.search || '',
          context: 'recipe',
        }),
      },
      meta: {
        scrollToTop: true,
        type: 'recipeslist',
        typeLabel: 'recettes',
        title: 'Les recettes de Potager City',
        description: 'L’équipe vous accompagne en cuisine avec nos recettes préférées pour cuisiner vos fruits & légumes avec des recettes simples et variées. On vous donne aussi nos meilleures astuces de chefs pour des plats réussis à coup sûr !',
        image: 'https://www.potagercity.fr/images/meta_recettes_1200_628.png',
      },
    },
    {
      name: 'recette',
      path: '/recettes/:slug/:id/',
      components: {
        content: BonCoingRecettePage,
      },
      meta: {
        scrollToTop: true,
        type: 'single',
        typeLabel: 'recettes',
      },
    },

    /*
     * Variétés (Produits)
     */
    {
      name: 'products-list',
      path: '/produits/:page(\\d+)?/:search?',
      components: {
        content: BonCoingList,
      },
      props: {
        content(route) {
          return {
            page: parseInt(route.params.page, 10) || 1,
            search: route.params.search || '',
            context: 'supplierProducts',
          };
        },
      },
      meta: {
        scrollToTop: true,
        type: 'productslist',
        typeLabel: 'variétés',
        title: 'Les variétés de Potager City',
        description: 'Découvrez la liste et les caractéristiques de toutes les variétés que nous allons chercher chez les producteurs les plus proches.',
      },
    },
    {
      name: 'produits',
      path: '/produits/:slug/:id/',
      components: {
        content: BonCoingVarietyPage,
      },
      meta: {
        scrollToTop: true,
        type: 'single',
        typeLabel: 'variétés',
      },
    },

    /*
     * Producteurs
     */
    {
      name: 'suppliers-list',
      path: '/producteurs/:page(\\d+)?/:search?',
      components: {
        content: BonCoingList,
      },
      props: {
        content(route) {
          return {
            page: parseInt(route.params.page, 10) || 1,
            search: route.params.search || '',
            context: 'supplier',
          };
        },
      },
      meta: {
        scrollToTop: true,
        type: 'supplierslist',
        typeLabel: 'producteurs',
        title: 'Les producteurs partenaires de Potager City',
        description: 'Les maraîchers et arboriculteurs partenaires ont été rigoureusement sélectionnés pour leur savoir-faire et leur engagement en faveur d’une production responsable.',
      },
    },
    {
      name: 'producteurs',
      path: '/producteurs/:slug/:id/',
      components: {
        content: BonCoingProducteurPage,
      },
      meta: {
        scrollToTop: true,
        type: 'single',
        typeLabel: 'producteurs',
      },
    },

    /*
     * Articles
     */
    {
      name: 'reports-list',
      path: '/articles/:page(\\d+)?/:search?',
      components: {
        content: BonCoingList,
      },
      props: {
        content(route) {
          return {
            page: parseInt(route.params.page, 10) || 1,
            search: route.params.search || '',
            context: 'report',
          };
        },
      },
      meta: {
        scrollToTop: true,
        type: 'articleslist',
        typeLabel: 'articles',
        title: 'Les articles de Potager City',
        description: 'Découvrez tous les articles de Potager City pour en savoir plus sur nos fruits et légumes, nos producteurs partenaires et nos offres.',
      },
    },
    {
      name: 'reports',
      path: '/article/:slug/:id',
      components: {
        content: BonCoingArticlePage,
      },
      meta: {
        scrollToTop: true,
        type: 'single',
        typeLabel: 'articles',
      },
    },

    /*
     * Custom pages
     */
    {
      name: 'custom-pages',
      path: '/:url',
      alias: '/custompage/:url',
      components: {
        content: BonCoingCustomPage,
      },
      meta: {
        scrollToTop: true,
        type: 'single',
        typeLabel: 'articles',
      },
    },
  ],
};
