import { POPIN_ERROR_EVENT } from 'Classes/Constants';
import { UPDATE_BASKET_ACTION } from 'Stores/types/basketActionsTypes';
import { UPDATE_USER } from 'Stores/types/userMutationsTypes';
import { storeCommit, storeDispatch } from 'Services/storeServices';

export const BT015_PAY_BASKET_ERROR = {
  eventType: POPIN_ERROR_EVENT,
  handle: (app, response, error) => {
    const { customer } = response.data.data;
    const errorMessage = response.data?.errors?.globals?.[0]?.message;
    error.text = (errorMessage ? `${errorMessage} ` : '') + 'Veuillez vérifier votre panier et recommencer.';
    storeDispatch('basket/' + UPDATE_BASKET_ACTION, customer.basket);
    storeCommit('user/' + UPDATE_USER, customer);
  }
};

export default {
  default: {
    text: 'Un produit de votre panier n\'est plus disponible. Veuillez vérifier votre panier et recommencer.',
  },
  'PaymentApi.payBasketByPaiementMethods': BT015_PAY_BASKET_ERROR,
};
