<template>
  <div
    :class="[
      'dashboard-slider',
      { 'bg-bianca': !subscriptionMode }
    ]">
    <!-- SKELETON -->
    <potager-container
      v-if="!isSlidesLoaded"
      :class="['relative', { 'p-0': subscriptionMode }]">
      <section
        class="rounded-md overflow-hidden">
        <div
          class="dashboard-slider__image dashboard-slider__image--skeleton skeleton overflow-hidden" />
      </section>
    </potager-container>
    <!-- END SKELETON -->

    <potager-container
      v-else
      :class="[{ 'p-0': subscriptionMode }]">
      <section class="dashboard-slider__container relative rounded-xl overflow-hidden">
        <potager-slider
          ref="slider"
          :autoplay="5000"
          :items="slides"
          :settings="getSliderSettings"
          :slides-per-view="1"
          class="bg-white-rock"
          name="dashboard-slider">
          <template #default="{ item, index }">
            <component
              :is="dynamicLink(item) ? 'potager-link' : 'span'"
              :target="item.targetUrlType"
              :to="dynamicLink(item)"
              class="transition duration-300"
              @click="onSlideClick(index, item)">
              <potager-picture
                :src="item.src"
                :style="{'background-color': item.backgroundColor}"
                :title="item.src.alt"
                class="dashboard-slider__image block rounded-xl overflow-hidden"
                height="100%"
                no-lazy-load />
            </component>
          </template>

          <template #navigation>
            <potager-button
              class="js-dashboard-slider__prev absolute block bottom-5 right-5 z-10 mr-14 transition-all shadow-md"
              is-circle
              size="small"
              theme="white-rock">
              <template #icon>
                <icon-arrow-left />
              </template>
            </potager-button>

            <potager-button
              class="js-dashboard-slider__next absolute block bottom-5 right-5 z-10 transition-all shadow-md"
              is-circle
              size="small"
              theme="white-rock">
              <template #icon>
                <icon-arrow-right />
              </template>
            </potager-button>
          </template>
        </potager-slider>
      </section>
    </potager-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { FETCH_DASHBOARD_SLIDES_ACTION, FETCH_DASHBOARD_ACTION } from 'Stores/types/dashboardActionTypes';

import { isExternalLink, isHttpUrl, getUrlAttributes } from 'Classes/utils/RouteUtils';
import { getContentLink } from 'Classes/utils/RouteUtils';

import Skeleton from 'Pages/flyingPages/Skeleton';

import GtmMixin from 'Mixins/GtmMixin';

import PotagerSlider from 'UI/PotagerSlider';
import PotagerButton from 'UI/PotagerButton';
import PotagerContainer from 'UI/PotagerContainer';
import PotagerPicture from 'UI/PotagerPicture';

import IconArrowLeft from 'Icons/bold/IconArrowLeft';
import IconArrowRight from 'Icons/bold/IconArrowRight';

export default {

  props: {
    subscriptionMode: {
      type: Boolean,
      default: false,
    },
    condensed: {
      type: Boolean,
      default: false,
    },
  },

  mixins: [
    GtmMixin,
  ],

  components: {
    Skeleton,
    PotagerPicture,
    PotagerButton,
    PotagerSlider,
    PotagerContainer,
    IconArrowLeft,
    IconArrowRight,
  },

  methods: {
    isExternalLink,
    isHttpUrl,
    getUrlAttributes,
    dynamicLink(slide) {
      if (slide.target) {
        const contentLink = getContentLink(slide, null, slide.targetUrlType === '_self');
        const link = slide.link && slide.link !== '' && slide.link !== '#';
        return contentLink || link || null;
      }

      if (slide.link) {
        const attr = getUrlAttributes(slide.link);
        return (isExternalLink(slide.link) || !attr?.pathname)
          ? slide.link
          : `${attr.pathname}${attr.hash || ''}`;
      }

      return null;
    },
    onSlideClick(slideIndex, slide) {
      this.trackDashboardSlider(slideIndex + 1, true, slide.label);
    },
    fetchSlides() {
      this.$store.dispatch(`dashboard/${FETCH_DASHBOARD_SLIDES_ACTION}`);
    },
  },

  watch: {
    isLoggedIn: {
      handler(isLoggedIn) {
        if (isLoggedIn) {
          this.fetchSlides();
        }
      },
    },
    isSubscriptionActive: {
      handler() {
        if (this.isLoggedIn) {
          this.fetchSlides();
        }
      },
    },
  },

  computed: {
    ...mapGetters('dashboard', [
      'getSlides',
      'isSlidesLoaded',
    ]),
    ...mapGetters('session', [
      'isLoggedIn',
    ]),
    ...mapGetters('user', [
      'isSubscriptionActive',
    ]),
    slides() {
      return this.getSlides
        .filter((slide) => !this.subscriptionMode || slide.isSubscriptionSlide)
        .map((slide) => {
          let imageVersion;
          if (this.$mq.bp425) {
            imageVersion = 'mobileImage';
          } else if (this.$mq.bp768 || this.condensed) {
            imageVersion = 'mediumImage';
          } else {
            imageVersion = 'desktopImage';
          }

          return {
            ...slide,
            src: slide[imageVersion]
          };
        });
    },
    getSliderSettings() {
      return {
        navigation: {
          nextEl: '.js-dashboard-slider__next',
          prevEl: '.js-dashboard-slider__prev',
        },
        slideToClickedSlide: true,
        speed: 400,
      };
    },
  },

  mounted() {
    // fetch slides if not already fetched by itself or by dashboard on /boutique
    if (this.isLoggedIn && !this.getSlides?.length && !this.$wait.is(FETCH_DASHBOARD_ACTION) && !this.$wait.is(FETCH_DASHBOARD_SLIDES_ACTION)) {
      this.fetchSlides();
    }
  },
};
</script>

<style lang="scss" scoped>
.dashboard-slider {
  :deep() &__image {
    min-height: 260px;

    // uncomment if tablet slider height !== desktop slider height
    //@include bp768() {
    //  height: 260px;
    //}

    @include bp425() {
      height: auto;
      width: 100%;
    }
  }

  &__image {
    &--skeleton {
      @include bp425() {
        height: 100vw * math.div(318, 360); // mobile image ratio
      }
    }
  }
}
</style>
