import { mapGetters } from 'vuex';
import OrderActionMixin from 'Mixins/OrderActionMixin';

export default {
  mixins: [
    OrderActionMixin,
  ],

  computed: {
    ...mapGetters('user', [
      'getSubscription',
      'getSubscriptionOrder',
      'isUserLoaded',
      'isSubscriptionActive',
      'getNextGenerationDate',
      'getNextDeliveredTimeslot',
      'isSubscriptionSuspended',
      'getSubscriptionNextOrder',
    ]),
    subscriptionOrderOrSubscription() {
      return this.getSubscriptionOrder || this.getSubscription;
    },
    isNotYetCreated() {
      return this.getNextGenerationDate && !this.getSubscriptionOrder;
    },
    getNextDeliveryDate() {
      return (this.getSubscriptionOrder?.timeSlot || this.getNextDeliveredTimeslot)?.date;
    },
  },
};
