export const test = ({ rootGetters, expectedValue }) => {
  const customerSubscriptionProduct = rootGetters['subscriptionBasket/getCustomerSubscriptionProduct'];
  if (customerSubscriptionProduct) {
    const { productSelectorId } = customerSubscriptionProduct;
    const product = rootGetters['subscriptionBasket/getProductSelectorsV2'].find(
      (p) => p.id === productSelectorId,
    );

    return !!product?.productOptions.length === expectedValue;
  }
  return false;
};

export default {
  type: 'hasCustomerSubscriptionProductOptions',
  values: [false, true],
  test,
};
