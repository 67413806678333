<template>
  <potager-container
    :class="{
      'howto': true,
      'howto--narrow': narrow,
    }">
    <div
      v-if="narrow"
      class="howto__desc">
      Potager City prépare et livre des paniers de fruits et légumes en privilégiant le circuit court.
      Un service simple, rapide et 100% en ligne.
      <potager-link :to="{ name: 'home' }">
        En savoir plus
      </potager-link>
    </div>

    <component
      :is="['home', 'devenir-point-de-retrait'].includes($route.name) ? 'h2' : 'p'"
      class="howto__title">
      Comment ça marche&nbsp;?
    </component>

    <p
      v-if="subtitle"
      class="howto__subtitle">
      {{ subtitle }}
    </p>

    <potager-slider
      name="howto"
      class="howto__slider"
      :slides-per-view="narrow ? 1 : [items.length, 1, 1]"
      :items="items"
      :navigation="!narrow"
      pagination>
      <template v-slot:default="vProps">
        <article
          :class="[
            'howto__slider__item',
            `howto__slider__item--${vProps.index + 1}`,
          ]">
          <component
            v-if="vProps.item.icon"
            :is="vProps.item.icon"
            class="howto__slider__icon" />

          <p class="howto__slider__title">
            <span class="howto__slider__title-num">{{ vProps.index + 1 }}</span>
            {{ vProps.item.title }}
          </p>
          <p class="howto__slider__desc">
            {{ vProps.item.desc }}
          </p>

          <template v-if="vProps.item.button">
            <potager-button
              v-if="vProps.item.button.to"
              :to="vProps.item.button.to"
              theme="stroke"
              :label="vProps.item.button.label" />

            <potager-button
              v-else-if="vProps.item.button.action"
              theme="stroke"
              :label="vProps.item.button.label"
              @onClick="vProps.item.button.action" />
          </template>
        </article>
      </template>
    </potager-slider>
  </potager-container>
</template>

<script>
import IconShoppingBagSmile from 'Icons/regular/IconShoppingBagSmile';
import IconShop from 'Icons/regular/IconShop';
import IconRestaurantForkKnife from 'Icons/regular/IconRestaurantForkKnife';

import PotagerContainer from 'UI/PotagerContainer';
import PotagerButton from 'UI/PotagerButton';
import PotagerSlider from 'UI/PotagerSlider';

export default {

  components: {
    PotagerContainer,
    PotagerButton,
    PotagerSlider,
  },

  props: {
    narrow: {
      type: Boolean,
      required: false,
      default: false,
    },
    items: {
      type: Array,
      required: false,
      default: () => ([{
        icon: IconShoppingBagSmile,
        title: 'Choisissez',
        desc: 'une formule d’abonnement adaptée à vos besoins pour couvrir vos repas de la semaine',
        button: {
          to: { name: 'tunnel' },
          label: 'Je découvre les paniers',
        },
      }, {
        icon: IconShop,
        title: 'Recevez',
        desc: 'votre commande dans un point de retrait proche de chez vous ou dans votre entreprise',
      }, {
        icon: IconRestaurantForkKnife,
        title: 'Cuisinez',
        desc: 'à partir des recettes et des précieux conseils de conservation inclus dans les paniers',
      }]),
    },
    subtitle: {
      type: String,
      required: false,
      default: null,
    },
  },

};
</script>
