<template>
  <div>
    <heading
      :background="null"
      :modifiers="['text-black', 'smaller']">
      <template #title>
        Mentions légales
      </template>
    </heading>

    <blog-body>
      <blog-section>
        <cms>
          <h2>
            Éditeur
          </h2>
          <p>
            Les sites Potagercity.fr / Potagercity.com / Potagercity.net / Potagercity.org / Potager-city.fr / Potager-city.com / Potager-city.net / Potager-city.org et l’application Potager City sont édités par la société Potager City SAS au capital de 31 037€, immatriculée au RCS de Lyon sous le numéro : 499 600 781.
          </p>
          <p>
            Numéro de SIRET&nbsp;: 49960078100144<br>
            Numéro de TVA intracommunautaire&nbsp;: FR65499600781.<br>
            <b>Adresse du siège social</b>&nbsp;: POTAGER CITY, Espace Cardinal Workside, 60 quai Perrache, 69002 LYON<br>
            <b>Directeur de la publication</b>&nbsp;: Yoann Alarçon, Directeur général de Potager City
          </p>
          <p>
            Si vous êtes un client particulier, vous pouvez nous contacter via la rubrique Contact en cliquant <potager-link
              :to="{ name: 'contact' }"
              target="_blank">
              ici
            </potager-link> ou par téléphone au <a href="tel:+33974592040">09 74 59 20 40</a>.<br>
            Si vous êtes un client entreprise ou un point relais, vous pouvez nous contacter par téléphone au <a href="tel:+33974592040">09 74 59 20 40</a>.
          </p>

          <h2>
            Hébergeur
          </h2>
          <p>
            Potager City fait appel à Amazon Web Services LLC pour l’hébergement de ses services.<br>
            Adresse postale : P.O. Box 81226, Seattle, WA 98108-1226, USA<br>
            <a
              href="http://aws.amazon.com"
              target="_blank">
              http://aws.amazon.com
            </a>
          </p>
        </cms>
      </blog-section>
    </blog-body>
  </div>
</template>

<script>
import ToolsMixin from 'Mixins/ToolsMixin';

import Heading from 'Components/heading/Heading';
import BlogBody from 'Components/blog/BlogBody';
import BlogSection from 'Components/blog/BlogSection';
import Cms from 'Components/cms/Cms';

export default {

  mixins: [
    ToolsMixin,
  ],

  components: {
    Heading,
    BlogBody,
    BlogSection,
    Cms,
  },

  head() {
    return this.generateMetaIfPanelClosed({
      title: 'Mentions légales',
      description: 'Retrouvez toutes les mentions légales liées à notre site web et la propriété des contenus qui y sont présentés.',
    });
  },

};
</script>
