<template>
  <panel
    :back-route="{ name: 'mon-compte' }"
    title="Informations">
    <div class="panel-section text-xs.5">
      <p class="panel-section__p">
        Client n°{{ getUserId }}-{{ getRegionId }}
        <br>
        Compte crée le {{ registrationDebitDate }}
      </p>

      <p
        v-if="hasSubscriptionEnterpriseDiscount"
        class="panel-section__p">
        Avantage entreprise : OUI
      </p>
    </div>

    <div class="panel-section">
      <potager-navigator :to="{ name: 'mon-compte_identity' }">
        Identité
      </potager-navigator>

      <potager-navigator :to="{ name: 'mon-compte_email' }">
        E-mail
      </potager-navigator>

      <potager-navigator :to="{ name: 'mon-compte_password' }">
        Mot de passe
      </potager-navigator>

      <potager-navigator :to="{ name: 'mon-compte_supprimer' }">
        Supprimer mon compte
      </potager-navigator>
    </div>
  </panel>
</template>

<script>
import { mapGetters } from 'vuex';

import Panel from 'Components/panel/Panel';

import PotagerNavigator from 'UI/PotagerNavigator';

import MetaInfosService from 'Classes/services/MetaInfoService';
import { tmsToFormat } from 'Classes/utils/DatesUtils';

export default {

  components: {
    Panel,
    PotagerNavigator,
  },

  data: () => ({
    validationClasses: null,
    response: {},
    pending: false,
  }),

  computed: {
    ...mapGetters('user', [
      'getUserId',
      'getRegionId',
      'getRegistrationDate',
      'hasSubscriptionEnterpriseDiscount',
    ]),
    registrationDebitDate() {
      return this.fullDate(this.getRegistrationDate);
    },
  },

  methods: {
    fullDate(date) {
      return tmsToFormat(date, 'dd/MM/yyyy');
    },
  },

  head: MetaInfosService.generate({
    title: 'Informations',
  }),

};
</script>
<script lang="ts" setup>
</script>
