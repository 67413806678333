<template>
  <collapsible-content :is-open="!!didYouKnow">
    <div
      class="
        bg-british-racing text-white border-0 border-t-16 border-solid border-white
        px-8 bp768:px-4 py-4
      ">
      <potager-info v-if="didYouKnow">
        <span class="block font-bold text-sm.5 mb-1 not-italic">
          Le saviez vous ?
        </span>
        <div
          class="text-xs.5"
          v-html="didYouKnow.text" />
        <potager-button
          v-if="didYouKnow.redirect"
          class="mt-4 not-italic"
          :to="didYouKnow.redirect"
          theme="white"
          size="small"
          v-gtm="didYouKnow.btnLabel">
          {{ didYouKnow.btnLabel || 'Cliquez-ici' }}
        </potager-button>
      </potager-info>
    </div>
  </collapsible-content>
</template>

<script>
import PotagerInfo from 'UI/PotagerInfo';
import PotagerButton from 'UI/PotagerButton';
import CollapsibleContent from 'Components/collapsible/CollapsibleContent';

export default {

  components: {
    PotagerInfo,
    PotagerButton,
    CollapsibleContent,
  },

  props: {
    didYouKnow: {
      type: Object,
      required: false,
      default: null,
    },
  },

};
</script>
