<template>
  <div
    :class="[
      'flex-1 overflow-hidden',
      fullscreen ? 'p-0' : 'pb-12 bp768:pb-6',
      { 'max-w-6/12 w-full mx-auto': size !== 'wide' }
    ]">
    <potager-container
      :class="{
        'h-full w-full': true,
        'bp768:h-auto': !fullscreen,
      }"
      :size="size">
      <transition name="fade">
        <icon-ptcy-loader-spinner
          v-if="!isReady && showLoader"
          class="text-4xl z-10 fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
      </transition>

      <div
        :class="{
          'transition duration-300 transform h-full': true,
          'translate-x-4 opacity-0': !isBodyVisible,
        }">
        <slot ref="content" />
      </div>
    </potager-container>

    <template v-if="$slots.footer">
      <div
        class="w-full"
        v-sticky-bottom="{
          always: true,
          stop: '.js-tunnel-footer-stop',
        }">
        <slot name="footer" />
      </div>
      <div class="js-tunnel-footer-stop" />
    </template>
  </div>
</template>

<script>
import IconPtcyLoaderSpinner from 'Icons/regular/IconPtcyLoaderSpinner';

import PotagerContainer, { props as containerProps } from 'UI/PotagerContainer';
import { propsBuilder } from 'UI/Tools';

export default {

  components: {
    IconPtcyLoaderSpinner,
    PotagerContainer,
  },

  data: () => ({
    showLoader: false,
    isBodyVisible: false,
    timer: null,
  }),

  props: propsBuilder({
    ...containerProps,
    isReady: {
      type: Boolean,
      required: false,
      default: true,
    },
    fullscreen: {
      type: Boolean,
      required: false,
      default: false,
    },
  }),

  watch: {
    isReady: {
      handler(val) {
        setTimeout(() => {
          this.isBodyVisible = val;
        }, 0);
        if (val && this.timer) clearTimeout(this.timer);
      },
      immediate: true,
    },
  },

  mounted() {
    if (!this.isReady) {
      this.timer = setTimeout(() => {
        this.showLoader = true;
      }, 500);
    }
  },

};
</script>
