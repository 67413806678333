import { propsBuilder } from 'UI/Tools';
import Validator from 'Classes/validators/Validator';

export const props = {
  autocomplete: {
    type: String,
    required: false,
    default: '',
  },
  constraints: {
    type: Array,
    required: false,
    default: () => ([]),
  },
  constraintsValidation: {
    type: String,
    required: false,
    default: 'onSubmit',
    values: ['onSubmit', 'onInput', 'onBlur'],
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  externalLabel: {
    type: Boolean,
    required: false,
    default: false,
  },
  fullWidth: {
    type: Boolean,
    required: false,
    default: false,
  },
  inlineSubmit: {
    type: [Boolean, String],
    default: false,
  },
  inlineSubmitGtm: {
    type: Object,
    required: false,
    default: null,
  },
  inverted: {
    type: Boolean,
    required: false,
    default: false,
  },
  isLoading: {
    type: Boolean,
    required: false,
    default: false,
  },
  label: {
    type: [String, Boolean],
    required: false,
    default: null,
  },
  list: {
    type: String,
    required: false,
    default: '',
  },
  max: {
    type: [String, Number],
    required: false,
    default: null,
  },
  maxlength: {
    type: [String, Number],
    required: false,
    default: null,
  },
  min: {
    type: [String, Number],
    required: false,
    default: null,
  },
  name: {
    type: String,
    required: false,
    default: null,
  },
  noPlaceholder: {
    type: Boolean,
    required: false,
    default: false,
  },
  options: {
    type: Array,
    required: false,
    default: () => [],
  },
  placeholder: {
    type: String,
    required: false,
    default: null,
  },
  prefix: {
    type: String,
    required: false,
    default: null,
  },
  readonly: {
    type: Boolean,
    required: false,
    default: false,
  },
  reset: {
    type: Boolean,
    required: false,
    default: false,
  },
  rows: {
    type: [String, Number],
    required: false,
    default: 3,
  },
  step: {
    type: String,
    required: false,
    default: null,
  },
  suffix: {
    type: String,
    required: false,
    default: null,
  },
  textarea: {
    type: Boolean,
    required: false,
    default: false,
  },
  truncate: {
    type: Boolean,
    required: false,
    default: false,
  },
  type: {
    type: String,
    required: false,
    default: 'text',
  },
  uppercase: {
    type: Boolean,
    required: false,
    default: false,
  },
  modelValue: {
    type: [String, Number, Array],
    required: false,
    default: null,
  },
};

export default {
  inject: ['formId'],

  data() {
    return {
      errors: [],
      successes: [],
    };
  },

  props: propsBuilder(props),

  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      },
    },
    getClasses() {
      return [
        'potager-input__input',
        `potager-input__input--${this.type}`,
        {
          'potager-input__input--has-reset': this.reset && this.modelValue,
          'potager-input__input--has-suffix': this.suffix,
          'potager-input__input--has-prefix': this.prefix,
          'potager-input__input--has-icon': this.$slots.icon,
          'potager-input__textarea': this.textarea,
          truncate: this.truncate,
        },
      ];
    },
    isError() {
      return this.errors && this.errors.length > 0;
    },
    isSuccess() {
      return this.successes && this.successes.length > 0;
    },
    hasActions() {
      return this.reset || this.$slots.action;
    },
  },

  methods: {
    resetErrors() {
      this.$events.emit(`remove:formError:${this.formId}`, this.name);
      this.errors = [];
    },
    resetSuccess() {
      this.successes = [];
    },
    onInput($event) {
      this.resetErrors();
      this.resetSuccess();
      this.$emit('update:modelValue', $event.target.value);
      this.$nextTick(() => {
        if (this.constraintsValidation === 'onInput') this.showError();
      });
    },
    onBlur($event) {
      this.$emit('onBlur', $event.target.value);

      this.$nextTick(() => {
        if (this.constraintsValidation === 'onBlur') this.showError();
      });
    },
    getErrors() {
      return Validator.valid(this.constraints, this.value);
    },
    showError() {
      const errors = this.getErrors()
        .filter((d) => d.field === this.name || d.field === this.groupName);

      errors.forEach((error) => {
        this.$events.emit(`add:formError:${this.formId}`, error);
      });

      this.errors = errors
        .map((d) => d.message)
        .filter((d) => d);
    },
    showSuccess(successes) {
      this.successes = (successes || [])
        .filter((d) => d.field === this.name || d.field === this.groupName)
        .map((d) => d.message)
        .filter((d) => d);
    },
    scrollIntoView() {
      this.$refs.input.scrollIntoView({ behavior: 'smooth' });
    },
  },
};
