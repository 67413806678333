<template>
  <a
    v-if="isExternalLink"
    :href="to"
    class="cursor-pointer"
    target="_blank"
    v-bind="$props">
    <slot />
  </a>

  <router-link
    v-else-if="to"
    :to="to"
    class="cursor-pointer"
    v-bind="$props">
    <slot />
  </router-link>

  <span
    v-else
    v-bind="$props">
    <slot />
  </span>
</template>

<script>
import { RouterLink } from 'vue-router';

export default {
  name: 'PotagerLink',
  props: {
    ...RouterLink.props,
    to: {
      type: [String, Object],
      default: null,
    },
  },
  computed: {
    isExternalLink() {
      return typeof this.to === 'string' && ['http', 'mailto', 'tel']
        .some(prefix => this.to
          .indexOf(prefix) === 0);
    },
  },
};
</script>
