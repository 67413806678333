<template>
  <div
    :class="{
      'recipe-steps': true,
      'recipe-steps--large': isLarge,
    }">
    <youtube-frame
      v-if="recipe.youtube"
      :url="recipe.youtube"
      class="recipe-steps__video" />

    <ol>
      <li
        v-for="(step, i) in recipe.steps"
        :key="`step-${i}`"
        class="recipe-steps__step">
        <template v-if="step.step">
          {{ step.step }}

          <potager-picture
            v-if="step.image"
            :src="step.image"
            :title="step.step"
            class="recipe-steps__step__img" />
        </template>

        <div
          v-else
          class="skeleton h-6 w-1/3" />
      </li>
    </ol>

    <div
      v-if="recipe.conclusion"
      class="recipe-steps__ending"
      v-html="cleanWP(recipe.conclusion)" />
  </div>
</template>

<script>
import YoutubeFrame from 'Components/videoFrame/YoutubeFrame';
import PotagerPicture from 'UI/PotagerPicture';

import { cleanWordpress } from 'Classes/utils/WordpressUtils';

export default {

  components: {
    YoutubeFrame,
    PotagerPicture,
  },

  props: {
    recipe: {
      type: Object,
      required: false,
      default: null,
    },
    isLarge: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  methods: {
    cleanWP(str) {
      return cleanWordpress(str);
    },
  },
};
</script>
