<template>
  <div class="relative bg-beryl-green">
    <potager-container class="z-10">
      <div class="pt-20 pb-28 bp768:pt-10 bp768:pb-4 bp768:flex bp768:flex-col bp768:items-center">
        <h1
          class="
            font-black text-5xl.5 bp1024:text-4xl
            bp768:text-center mb-8 bp768:mb-4
            max-w-5/12 bp1024:max-w-full bp1024:w-1/2 bp768:w-full
          ">
          Les corbeilles de fruits livrées au bureau
        </h1>
        <div class="bp768:flex bp768:flex-col">
          <potager-button
            :href="TALLY_URL"
            class="mr-2.5 bp768:mb-2.5"
            theme="british-racing"
            target="_blank">
            Obtenir un devis
          </potager-button>
        </div>
      </div>
    </potager-container>

    <potager-picture
      class="
        absolute z-0 top-0 bottom-0 right-0 left-1/2
        bp768:relative bp768:inset-auto bp768:w-full bp768:min-h-12
      "
      :background="!$mq.bp768"
      placeholder="bg-forest-green/10"
      position="center left"
      :src="`b2b-background-banner-${$mq.bp768 ? 'mobile' : 'desktop'}`" />
  </div>
</template>

<script>
import PotagerContainer from 'UI/PotagerContainer';
import PotagerButton from 'UI/PotagerButton';
import PotagerPicture from 'UI/PotagerPicture';

import { TALLY_URL } from 'Classes/Contents';

export default {

  components: {
    PotagerContainer,
    PotagerButton,
    PotagerPicture,
  },

  data: () => ({
    TALLY_URL,
  }),

};
</script>
