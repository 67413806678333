<template>
  <flying-page>
    <div class="subscription-detail">
      <potager-picture
        background
        class="subscription-detail__poster subscription-detail__poster--push-sub"
        src="subscription-lets-go" />

      <div class="subscription-detail__content">
        <section class="subscription-detail__section">
          <p class="subscription-detail__title">
            Essayez l’abonnement&nbsp;!
          </p>
          <p class="subscription-detail__description">
            Vous n’avez pas d’abonnement en cours et c’est bien dommage 😔<br>
            <router-link :to="{ name: 'tunnel' }">
              Abonnez-vous
            </router-link>
            dès maintenant et recevez chaque semaine un panier avec les
            meilleurs <b>fruits et légumes de saison</b> pour cuisiner de bons repas. C’est sans engagement,
            plus économique et résiliable à tout moment, en 1 clic !
          </p>
        </section>

        <div class="static-flying-page__section">
          <potager-row class="static-flying-page__rassurances">
            <potager-col
              v-for="(rassurance, index) in rassuranceItems"
              :key="index"
              bp640="12"
              class="static-flying-page__rassurances__item"
              col="3">
              <potager-picture
                :src="rassurance.image"
                :title="rassurance.alt"
                class="static-flying-page__rassurances__item__image" />
              <div class="static-flying-page__rassurances__item__title">
                {{ rassurance.text }}
              </div>
            </potager-col>
          </potager-row>
        </div>

        <section class="subscription-detail__section">
          <h2 class="subscription-detail__subtitle">
            Chaque semaine, un panier, des recettes. Juste ce qu’il faut pour mieux manger.
          </h2>
          <potager-picture
            class="subscription-detail__concept"
            src="packshot" />
        </section>

        <section class="subscription-detail__section">
          <h2 class="subscription-detail__subtitle">
            Ici, pas d’engagement, vous êtes libre.
          </h2>
          <p class="subscription-detail__text">
            Mettez en pause ou suspendez votre abonnement quand vous le voulez. Chaque semaine, découvrez la composition
            de votre panier en abonnement ainsi qu'une dizaine d'autres paniers de fruits et légumes de saison !
            Complétez, remplacez : vous êtes libre de moduler votre abonnement chaque semaine !
          </p>
        </section>

        <section class="subscription-detail__section">
          <h2 class="subscription-detail__subtitle">
            Un service pratique, livré une fois par semaine, à côté de chez vous.
          </h2>
          <p class="subscription-detail__text">
            Croquez, cuisinez votre assortiment de fruits & légumes frais, de qualité et de saison. Toutes nos paniers
            sont livrés en points de retrait ou directement sur votre lieu de travail.
          </p>
        </section>

        <section class="subscription-detail__section">
          <h2 class="subscription-detail__subtitle">
            Pour une consommation responsable.
          </h2>
          <p class="subscription-detail__text">
            Des paniers de fruits & légumes de saison, issus de productions raisonnées ou bio, en priorité de
            l'agriculture locale et en circuit court : des paniers réalisés avec du sens...
          </p>
        </section>
      </div>
    </div>

    <template #footer>
      <div class="subscription-detail__footer">
        <div class="subscription-detail__footer__container flex items-center justify-center gap-4">
          <potager-button
            :to="{ name: 'tunnel' }"
            label="Je démarre un abonnement"
            theme="go-green" />
        </div>
      </div>
    </template>
  </flying-page>
</template>

<script>
import { RASSURANCES_ITEMS } from 'Classes/Contents';

import FlyingPage from 'Components/flyingPage/FlyingPage';
import PotagerButton from 'UI/PotagerButton';
import PotagerPicture from 'UI/PotagerPicture';
import PotagerRow from 'UI/PotagerRow';
import PotagerCol from 'UI/PotagerCol';

export default {
  components: {
    FlyingPage,
    PotagerButton,
    PotagerPicture,
    PotagerRow,
    PotagerCol,
  },

  data: () => ({
    rassuranceItems: RASSURANCES_ITEMS,
  }),
};
</script>
