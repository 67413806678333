const getValidator = (prop) => {
  if (prop.values && typeof prop.values === 'object' && typeof prop.type !== 'object') {
    return (v) => {
      if (!v && !prop.required) return true;
      const isValid = prop.values.includes(v);
      // eslint-disable-next-line no-console
      if (!isValid) console.error(`Props "${v}" invalid`);
      return isValid;
    };
  }
  return undefined;
};

export const propsHydrator = (prop) => ({
  type: prop.type,
  required: prop.required,
  default: prop.default,
  validator: getValidator(prop),
});

export const propsBuilder = (props) => {
  const hydratedProps = {};

  // eslint-disable-next-line array-callback-return
  Object.keys(props).map((objectKey) => {
    hydratedProps[objectKey] = propsHydrator(props[objectKey]);
  });
  return hydratedProps;
};
