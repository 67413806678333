export const RESET_USER_ACTION = 'RESET_USER_ACTION';
export const UPDATE_USER_ACTION = 'UPDATE_USER_ACTION';
export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';

export const REMOVE_CARD_ACTION = 'REMOVE_CARD_ACTION';
export const SET_DEFAULT_CARD_ACTION = 'SET_DEFAULT_CARD_ACTION';

export const UPDATE_USER_IDENTITY_ACTION = 'UPDATE_USER_IDENTITY_ACTION';
export const UPDATE_EMAIL_ACTION = 'UPDATE_EMAIL_ACTION';
export const CONFIRM_ACTION = 'CONFIRM_ACTION';
export const UPDATE_USER_PASSWORD_ACTION = 'UPDATE_USER_PASSWORD_ACTION';

export const ADD_ADDRESS_ACTION = 'ADD_ADDRESS_ACTION';
export const UPDATE_ADDRESS_ACTION = 'UPDATE_ADDRESS_ACTION';

// coupons automated
export const UPDATE_COUPON_AUTOMATED_VALUE_ACTION = 'UPDATE_COUPON_AUTOMATED_VALUE_ACTION';
export const UPDATE_COUPON_AUTOMATED_CONFIGURATION_ACTION = 'UPDATE_COUPON_AUTOMATED_CONFIGURATION_ACTION';

// coupons user
export const ADD_COUPON_ON_USER_ACTION = 'ADD_COUPON_ON_USER_ACTION';

// coupons basket
export const ADD_COUPON_ON_BASKET_ACTION = 'ADD_COUPON_ON_BASKET_ACTION';
export const REMOVE_COUPON_ON_BASKET_ACTION = 'REMOVE_COUPON_ON_BASKET_ACTION';
// coupons subscription basket
export const ADD_COUPON_ON_SUBSCRIPTION_BASKET_ACTION = 'ADD_COUPON_ON_SUBSCRIPTION_BASKET_ACTION';
export const REMOVE_COUPON_ON_SUBSCRIPTION_BASKET_ACTION = 'REMOVE_COUPON_ON_SUBSCRIPTION_BASKET_ACTION';

// coupons order
export const ADD_COUPON_ON_ORDER_ACTION = 'ADD_COUPON_ON_ORDER_ACTION';
export const REMOVE_COUPON_ON_ORDER_ACTION = 'REMOVE_COUPON_ON_ORDER_ACTION';

// coupons luncheon
export const ADD_COUPON_LUNCHEON_BATCH_ACTION = 'ADD_COUPON_LUNCHEON_BATCH_ACTION';
export const UPDATE_COUPON_LUNCHEON_BATCH_ACTION = 'UPDATE_COUPON_LUNCHEON_BATCH_ACTION';
export const REMOVE_COUPON_LUNCHEON_BATCH_ACTION = 'REMOVE_COUPON_LUNCHEON_BATCH_ACTION';

export const ADD_RECIPE_ACTION = 'ADD_RECIPE_ACTION';
export const REMOVE_RECIPE_ACTION = 'REMOVE_RECIPE_ACTION';

export const UPDATE_SUBSCRIPTION_BASKET_ACTION = 'UPDATE_SUBSCRIPTION_BASKET_ACTION';
export const UPDATE_SUBSCRIPTION_PRODUCT_ACTION = 'UPDATE_SUBSCRIPTION_PRODUCT_ACTION';

export const GET_SUBSCRIPTION_TIME_SLOTS_ACTION = 'GET_SUBSCRIPTION_TIME_SLOTS_ACTION';
export const ADD_SUBSCRIPTION_BREAK_ACTION = 'ADD_SUBSCRIPTION_BREAK_ACTION';
export const DELETE_SUBSCRIPTION_BREAK_ACTION = 'DELETE_SUBSCRIPTION_BREAK_ACTION';
export const CANCEL_ORDER_ACTION = 'CANCEL_ORDER_ACTION';
export const RESUME_ORDER_ACTION = 'RESUME_ORDER_ACTION';
export const UPDATE_USER_ORDER = 'UPDATE_USER_ORDER';

export const LOAD_HISTORICAL_ORDERS = 'LOAD_HISTORICAL_ORDERS';
export const SWITCH_HISTORICAL_ORDERS_FILTER = 'SWITCH_HISTORICAL_ORDERS_FILTER';

export const UPDATE_DELIVERY_POINT_SUBCRIPTION_ACTION = 'UPDATE_DELIVERY_POINT_SUBCRIPTION_ACTION';

export const REMOVE_SUBSCRIPTION_ACTION = 'REMOVE_SUBSCRIPTION_ACTION';

export const UPDATE_DELIVERY_POINT_ORDER_ACTION = 'UPDATE_DELIVERY_POINT_ORDER_ACTION';

export const UPDATE_OPTINS_ACTION = 'UPDATE_OPTINS_ACTION';

export const PAY_FREE_BASKET_ACTION = 'PAY_FREE_BASKET_ACTION';
export const PAY_BASKET_BY_PAYMENT_METHODS_ACTION = 'PAY_BASKET_BY_PAYMENT_METHODS_ACTION';

export const GET_STRIPE_SECRET_CLIENT = 'GET_STRIPE_SECRET_CLIENT';
export const FINALIZE_STRIPE_SETUP_INTENT = 'FINALIZE_STRIPE_SETUP_INTENT';
export const AUTHORIZE_ORDER = 'AUTHORIZE_ORDER';
export const UPDATE_ORDER_3DS_STATUS = 'UPDATE_ORDER_3DS_STATUS';

export const GET_EDENRED_LOGIN_URL = 'GET_EDENRED_LOGIN_URL';
export const LOGOUT_EDENRED = 'LOGOUT_EDENRED';
export const EDIT_EDENRED_SETTINGS = 'EDIT_EDENRED_SETTINGS';

export const GET_SWILE_LOGIN_URL = 'GET_SWILE_LOGIN_URL';
export const LOGOUT_SWILE = 'LOGOUT_SWILE';
export const EDIT_SWILE_SETTINGS = 'EDIT_SWILE_SETTINGS';

export const INIT_PAYGREEN_BUYER = 'INIT_PAYGREEN_BUYER';
export const ADD_PAYGREEN_CARD_ACTION = 'ADD_PAYGREEN_CARD_ACTION';
