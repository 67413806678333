<template>
  <div
    :class="{
      'collapsible': true,
      'collapsible--opened': isOpen || forceOpen,
    }">
    <div
      v-wave="!forceOpen"
      :class="[
        headerClass,
        'collapsible__header',
      ]"
      @click="toggle">
      <slot name="collapsibleHeader" />

      <template v-if="!forceOpen">
        <span class="collapsible__header__icon">
          <slot name="icon">
            <icon-arrow-down1
              :class="{
                'collapsible__header__icon__arrow': true,
                'collapsible__header__icon__arrow--invert': isOpen,
              }" />
          </slot>
        </span>
      </template>
    </div>

    <transition
      :css="false"
      @before-enter="beforeEnter"
      @enter="enter"
      @after-enter="afterEnter"
      @enter-cancelled="enterCancelled"
      @before-leave="beforeLeave"
      @leave="leave"
      @after-leave="afterLeave">
      <div
        v-if="isOpen || forceOpen"
        class="collapsible__body-wrapper">
        <div
          :class="[
            bodyClass,
            'collapsible__body'
          ]">
          <slot name="collapsibleBody" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import IconArrowDown1 from 'Icons/regular/IconArrowDown1';
import { ref } from 'vue';

export default {
  setup() {
    const uid = ref(Date.now());
    return { uid };
  },

  components: {
    IconArrowDown1,
  },

  data() {
    return {
      isOpen: this.openDefault,
    };
  },

  props: {
    forceOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
    onlyOneOpened: {
      type: Boolean,
      required: false,
      default: false,
    },
    openDefault: {
      type: Boolean,
      required: false,
      default: false,
    },
    info: {
      type: Boolean,
      required: false,
      default: false,
    },
    headerClass: {
      type: [String, Array, Object],
      required: false,
      default: '',
    },
    bodyClass: {
      type: [String, Array, Object],
      required: false,
      default: '',
    },
  },

  methods: {
    toggle() {
      if (this.isOpen) {
        this.close();
      } else {
        this.open();
      }
    },
    open() {
      const wasOpened = (this.isOpen === true);

      this.isOpen = true;

      if (!wasOpened) {
        this.$emit('onOpen', this.uid);
        this.$emit('open');
      }
    },
    close() {
      const wasClosed = (this.isOpen === false);

      this.isOpen = false;

      if (!wasClosed) {
        this.$emit('close');
      }
    },

    beforeEnter(el) {
      el.style.height = 0;
    },
    enter(el, done) {
      const h = el.querySelector('.collapsible__body').offsetHeight;
      const onTransitionEnd = (e) => {
        done();
        e.target.removeEventListener('transitionend', onTransitionEnd);
      };

      el.addEventListener('transitionend', onTransitionEnd, false);

      this.$nextTick(() => { // Just to be sure
        el.style.height = `${h}px`;
      });
    },
    afterEnter(el) {
      el.style.height = null;
    },
    enterCancelled(el) {
      el.style.height = null;
    },

    beforeLeave(el) {
      el.style.height = `${el.offsetHeight}px`;
    },
    leave(el, done) {
      const onTransitionEnd = (e) => {
        done();
        e.target.removeEventListener('transitionend', onTransitionEnd);
      };

      el.addEventListener('transitionend', onTransitionEnd, false);

      setTimeout(() => { // Just to be sure, $nextTick is not enough
        el.style.height = '0px';
      }, 0);
    },
    afterLeave(el) {
      el.style.height = null;
    },
  },

};
</script>
