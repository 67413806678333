<template>
  <component
    :is="tag"
    :style="{ ...getDisplay }">
    <span
      ref="wrapper"
      class="potager-spotlight"
      :style="{ ...getDisplay }">
      <svg
        :class="[
          'potager-spotlight__svg',
          `potager-spotlight__svg--${type}`,
          `potager-spotlight__svg--${type}-v${getVariant}`,
        ]"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width="100%"
        :viewBox="viewBox"
        preserveAspectRatio="none">
        <path
          :fill="fill"
          :d="path" />
      </svg>
      <span
        class="potager-spotlight__text"
        :style="{ color }">
        <slot />
      </span>
    </span>
  </component>
</template>

<script>
import { propsBuilder } from 'UI/Tools';

export const props = {
  tag: {
    type: String,
    required: false,
    default: 'span',
  },
  type: {
    type: String,
    required: false,
    default: 'ruban',
    values: ['ruban', 'scribble', 'stroke', 'circle', 'brush'],
    options: {
      nbVariants: {
        stroke: 4,
        circle: 2,
      },
    },
  },
  variant: {
    type: Number,
    required: false,
    default: 0,
  },
  randomize: {
    type: Boolean,
    required: false,
    default: false,
  },
  fill: {
    type: String,
    required: false,
    default: '#fff',
  },
  color: {
    type: String,
    required: false,
    default: 'inherit',
  },
  inlineBlock: {
    type: Boolean,
    required: false,
    default: false,
  },
  block: {
    type: Boolean,
    required: false,
    default: false,
  },
};

export default {

  data: () => ({
    w: 0,
    h: 0,
  }),

  props: propsBuilder(props),

  computed: {
    getVariant() {
      const nbVariant = props.type.options?.nbVariants?.[this.type] || 1;
      const variant = this.variant >= nbVariant ? nbVariant - 1 : this.variant;
      return this.randomize ? Math.floor(Math.random() * nbVariant) : variant;
    },
    viewBox() {
      switch (this.type) {
        case 'scribble':
          return '0 0 734 89';
        case 'stroke':
          switch (this.getVariant) {
            case 1:
              return '0 0 300 8';
            case 2:
              return '0 0 60 4';
            case 3:
              return '0 0 181 19';
            case 0:
            default:
              return '0 0 117 9';
          }
        case 'circle':
          switch (this.getVariant) {
            case 1:
              return '0 0 197 61';
            case 0:
            default:
              return '0 0 161 62';
          }
        case 'brush':
          return '0 0 469 146.98';
        case 'ruban':
        default:
          return `0 0 ${this.w} ${this.h}`;
      }
    },
    path() {
      switch (this.type) {
        case 'scribble':
          return 'M663 70.6C664.1 66.3 665.2 62.1 666.4 57.4 663 55.9 659.1 54.2 655.3 52.4 670.8 32.5 703.6 43.9 728 35.6 729.3 32.8 731.3 28.7 734 23.1 709.6 20.6 687.1 24.6 664.8 25.6 643.5 26.5 622.2 27.7 597.6 29 609.2 18.5 623.8 22.1 635.6 20.8 647.8 19.5 660.2 19.4 673 18.7 677.1 13.8 680.8 9.4 684.6 4.9 678.2-1 668.5-0.2 658.7 0.5 607 4.1 555.3 8.1 503.6 11.1 464.4 13.3 425.1 14.3 385.8 15.7 331.3 17.7 276.8 19.5 222.3 21.3 184.4 22.5 146.5 23.2 108.7 24.7 93.8 25.3 78.9 27.2 64.1 28.8 48.5 30.6 33.1 32.6 13.9 35 9.9 42.9 4.9 52.6 0 62.4 16 67.9 32.2 65.4 47.7 65 59.1 64.7 70 63.1 77.9 69.9 80.5 72.1 90.3 72 96.8 71.9 118.6 71.7 140.4 70.9 162.9 70.4 164.1 74.9 164.2 77.8 165.7 80.4 168.7 86.1 175.1 89.6 186.6 88.9 194.6 88.5 202.5 88.1 210.4 87.8 257.9 86.1 305.3 90 352.8 88.2 372 87.5 391.3 80.8 410.5 80.4 415.3 80.3 420 82 424.7 82.9 504.1 78.8 583.5 74.7 663 70.6';

        case 'stroke':
          switch (this.getVariant) {
            case 1:
              return 'M228.3 6.2C228 6.1 227.7 6 227.4 5.9 227 5.9 226.5 6 226.1 6.1 226.4 6.2 226.8 6.3 227.2 6.4 227.4 6.4 227.9 6.2 228.3 6.2L228.3 6.2ZM138.5 4.9C138.5 5 138.6 5.1 138.6 5.2 139.8 5.2 141.1 5.2 142.3 5.2 142.2 5 142.2 4.9 142.1 4.8 140.9 4.8 139.7 4.8 138.5 4.9L138.5 4.9ZM114.8 5.7C114.9 5.9 115.1 6.1 115.3 6.4 117.3 6.2 119.4 6.1 121.5 6 121.4 5.8 121.2 5.7 121.1 5.5 119 5.6 116.9 5.6 114.8 5.7L114.8 5.7ZM88.9 5.6C92.4 6.1 92.4 6.1 96 5.6L88.9 5.6ZM148.5 4.8C148.4 5 148.3 5.2 148.2 5.3 152.7 6 157.9 5.8 162.8 5.7 166.7 5.7 170.1 5.8 173.9 6.1 178.3 6.5 183.5 6.4 188.3 6.4 190.3 6.4 192 6 191.1 5.1 177 5 162.8 4.9 148.5 4.8L148.5 4.8ZM170.6 1.8C176.1 3.3 176.1 3.2 183.2 3.3 191.1 3.5 199.1 3.7 206.8 3 208.2 2.9 209.9 3 211.5 3 225.1 3.1 238.9 3.6 252.5 2.7 252.7 2.7 253 2.7 253.2 2.7 261.4 2.8 269.5 2.8 277.7 2.8 280.3 2.8 283.2 3 286.8 2.1 232.1 0.9 178.9 0.7 125.7 1.1 123.8 1.1 122 1.2 119.2 1.4 120.8 1.7 121.4 1.9 121.9 1.8 131.8 1.6 141.6 1.7 151.3 2.4 154.6 2.6 158.4 2.4 161.9 2.3 164.9 2.3 167.7 2 170.6 1.8L170.6 1.8ZM299.1 6.2C284.8 8.1 269.7 7.9 254.9 7.9 230.9 7.9 206.9 7.9 182.9 7.9 163.2 7.9 143.4 8 123.7 8 114.2 8 104.6 7.8 95.2 7.6 73.1 7.1 51 6.8 28.8 6.9 21.5 6.9 14.5 6.5 8.6 5.4 6.4 5 4.2 4.5 2.8 4 -3.1 1.6 0.4 0 11.9 0 25.3 0 38.7 0.2 52.1 0.2 82.5 0.2 112.9 0 143.2 0 170.8 0.1 198.3 0.3 225.9 0.6 247.5 0.8 269.1 1.2 290.7 1.5 295 1.5 297.2 1.8 298.4 3 299.7 4.2 300.9 5.2 299.1 6.2L299.1 6.2Z';
            case 2:
              return 'M0.8 3.3C0.5 2.9 0.9 2.7 1.4 2.7 3 2.4 4.6 2.1 6.4 2 11.9 1.6 17.4 1.2 23 0.9 25.8 0.7 28.8 0.7 31.7 0.7 37.7 0.7 43.8 0.8 50.2 1.2 52.8 1.4 55.5 1.7 58.2 2 58.6 2 59 2.1 59.4 2.2 60 2.3 60.3 2.6 60 2.7 59.6 3 59.3 3.3 58.1 3.2 57.1 3.2 56.2 3.2 55.2 3.1 49.9 2.6 44.9 2.6 39.9 2.5 31.7 2.4 23.6 2.4 15.9 2.9 12.2 3 8.5 3.2 4.7 3.4 3.5 3.4 2.2 3.3 0.8 3.3Z';
            case 3:
              return 'M141.9 16.7C139.7 15.5 136.9 15.3 134.2 15.2 127.1 14.8 119.9 14.5 112.8 14.2 108 13.9 103.2 13.7 98.4 13.5 97 13.5 95.6 13.3 94.2 13.3 86.6 13.2 79 13.1 71.4 12.9 63.4 12.7 55.5 12.5 46.5 12.2 49.3 13.8 53.4 13.8 57.2 13.9 59.7 14 62.5 13.8 64.5 14.9 63 15 61.6 15.3 60.3 15.3 57 15.3 53.8 15.3 50.6 15.2 43.8 15 36.9 14.8 30.2 14.4 24.9 14.2 19.7 13.7 14.6 13.2 12.5 13 12.1 12.4 13.2 11.9 13.9 11.5 15.3 11.4 16.2 11.1 13.6 9.4 14.6 8.7 20.4 8.4 27.8 8.1 35.2 8 42.6 7.8 52.9 7.5 63.2 7.2 73.4 7 87.5 6.8 101.7 6.7 115.7 6.3 125.6 6 135.3 5.4 145 4.9 146.9 4.8 148.6 4.5 151.7 4.1 149.2 3.8 148 3.6 147.2 3.6 138.7 4.5 130 4.1 121.4 4.1 113.9 4.1 106.5 4.2 99 4.3 88.1 4.3 77.2 4.3 66.2 4.4 53.1 4.5 40 4.6 26.9 4.8 22.8 4.9 18.6 5.3 14.7 5.8 7.2 6.6-0.7 5 0 2.7 0.3 1.8 2.5 1.3 5 1.1 9.4 0.7 14 0.3 18.5 0.3 33.7 0.1 48.9 0.1 64 0 71.1 0 78.1 0 85.2 0 90.2 0 95.3 0.2 100.3 0.2 121.2 0.3 141.9 1 162.6 1.6 166.2 1.7 169.9 1.7 173.5 1.9 177.6 2 179.9 2.7 180.7 3.9 181.6 5.1 180.5 5.9 176.7 6.5 176.4 6.6 175.9 6.6 175.6 6.7 164.3 9.3 150.7 9.2 137.3 9.5 130.5 9.7 123.7 9.9 116.9 10 104.8 10.3 92.7 10.5 80.6 10.7 77 10.8 73.4 10.8 69.6 11.2 70.8 11.3 72 11.5 73.2 11.5 87 11.9 101 12.2 114.8 12.7 122.9 13 131.2 13.4 138.9 14.3 145.3 15.1 148 16.1 148 17.8 147.9 18.9 146.9 19.2 143.2 18.8 137.9 18.2 132.7 17.7 127 17.5 123.8 17.4 120.6 17.3 117.4 17.1 116.6 17 115.8 16.8 114.5 16.6 117.7 15.7 120.9 15.7 124.4 16 128.3 16.2 132.2 16.5 136.2 16.7 137.7 16.8 139.3 16.7 141.9 16.7M154.1 5.8C154 5.6 153.9 5.5 153.8 5.4 152.3 5.4 150.7 5.5 149.2 5.6 139.5 6.1 129.9 6.8 120.1 7.2 104.1 7.7 88 7.9 71.9 8.2 59.6 8.5 47.3 8.7 35.1 9 33.7 9.1 32.4 9.3 31.1 9.5 31.1 9.6 31.2 9.7 31.2 9.8 35.8 10.2 40.5 10.3 45.3 10.2 59.3 10 73.4 9.8 87.5 9.4 99.3 9.1 111.1 8.7 122.9 8.2 130.8 7.8 138.6 7.3 146.4 6.7 149.1 6.6 151.6 6.1 154.1 5.8';
            case 0:
            default:
              return 'M83 2C83 1.7 83 1.3 82.9 1 82.6 1.2 82.3 1.4 82 1.6 82 1.7 82 1.9 82.1 2L83 2ZM107 2.8C107 2.5 106.9 2.3 106.9 2 106.1 2 105.4 2 104.6 2 102.8 2 101 2 99.3 2.1 98.2 2.2 97.2 2.5 96.2 2.5 92.7 2.4 89.2 2.2 85 2 85.8 2.9 86.1 3.5 86.4 3.5 89.1 3.8 91.7 4 94.4 4 96.7 4 99.1 3.8 101.5 3.6 103.3 3.4 105.2 3.1 107 2.8L107 2.8ZM0.3 8.8C0.5 7.9 0.7 7.2 0.1 6.4 -0.6 5.3 2.3 3.2 4.1 3 6.9 2.7 9.7 2.3 12.5 2.1 15.7 1.9 18.9 1.7 22.1 1.6 26.8 1.4 31.4 1.3 36.1 1.2 47.9 0.8 59.6 0.5 71.4 0.2 76.2 0.1 80.9 0.4 85.7 0.1 90.3-0.1 95 0.1 99.6 0.1 102.7 0.1 105.7 0.2 108.7 0.4 110.6 0.5 112.5 0.9 114.3 1.2 115.6 1.5 117.1 3.3 117 4.2 116.9 4.9 116.3 5.1 115.4 5.1 113.7 5 112 4.9 110.3 4.8 104 4.6 97.8 4.3 91.5 4.1 90.5 4 89.4 4.2 88.4 4.2 86.6 4.2 84.8 4.2 83.1 4.2 80.2 4.2 77.4 4.3 74.6 4.4 65.2 4.7 55.8 5.4 46.5 6.3 41.6 6.8 36.7 6.8 31.8 7.2 27.2 7.5 22.6 8 17.9 8.4 14 8.8 10.1 9.3 6 8.8 4.3 8.6 2.5 8.8 0.3 8.8L0.3 8.8Z';
          }

        case 'circle':
          switch (this.getVariant) {
            case 1:
              return 'M154.3 9.9C154.2 10.2 154.2 10.6 154.1 10.9 155.1 11.3 156.1 11.7 157 12.1 161.3 13.9 165.7 15.4 169.8 17.7 172.5 19.2 175.1 21.5 177.2 23.9 180.2 27.4 180.1 31.4 177.2 35.1 175.9 36.8 174.5 38.5 172.8 39.7 168.3 42.9 163.7 45.9 159.1 48.9 159.6 48.9 160.2 48.9 160.7 48.7 169.9 46.1 178.9 42.8 187.4 37.7 189 36.7 190.5 35.4 191.8 34 194.8 30.8 194.9 27 191.9 24.2 189.7 22 187.2 20 184.5 18.9 178.6 16.5 172.6 14.5 166.6 12.7 162.5 11.5 158.4 10.8 154.3 9.9M28.3 19.3C26.6 20.3 24.8 21.2 23 22.3 19 24.8 18 28.3 19.8 33.4 21.4 37.8 24.2 40.8 27.8 42 35.4 44.7 43.1 47.5 50.9 49.2 60.1 51.2 69.5 52.5 78.9 53.5 91.5 54.8 104.2 55.1 116.8 53.4 127.9 51.9 138.9 49.8 149.9 47.5 158 45.7 165.9 42.5 172.5 36.1 178.7 30 178.5 26.8 171.5 21.9 165.3 17.6 158.3 15.6 151.5 13.8 143.4 11.8 135.2 10.4 127 9.3 116.7 8 106.3 6.8 95.9 6.6 86.8 6.4 77.7 7.4 68.6 8.1 55.2 9.1 42 10.9 28.9 15 22.7 16.9 16.4 18.7 10.4 21.7 8.2 22.9 5.3 23.8 5 27.3 4.7 31.1 7.6 32.7 9.9 34.2 12 35.6 14 38 17 37.5 12.9 22.2 20.6 16.7 28.3 19.3M91.2 0C101.9 0.8 112.6 1.7 123.4 2.4 127.1 2.6 130.8 2.3 134.4 2.6 139.6 3 144.7 3.7 149.8 4.3 153.6 4.8 157.5 5.1 161.2 6.2 169.6 8.8 178.1 11.5 186.3 14.9 189.3 16 192 18.6 194.4 21.2 198.4 25.6 197.7 32 193.2 35.7 183.4 44 172.5 48.4 160.7 50 159.3 50.2 157.9 50.9 156.4 51.1 145.2 53.3 134.1 55.7 122.9 57.4 110.6 59.3 98.2 60.5 85.8 59.8 70.6 59 55.5 57.3 40.6 53.7 34 52 28 48.6 21.9 45.5 17.2 43.1 12.4 40.9 7.8 38.1 4.9 36.3 2.4 33.6 1.5 29.5 0 22.9 1.9 17.7 7.5 14.8 12.6 12.1 17.9 9.6 23.4 8.2 43.7 3.2 64.3 1.3 85 0.7 87 0.6 89.1 0.7 91.1 0.7 91.2 0.4 91.2 0.2 91.2 0';
            case 0:
            default:
              return 'M43.5 54.8C46.6 54.9 49.3 55 52.1 55 58.3 54.9 64.5 54.7 70.7 54.7 76.4 54.7 82.1 55 87.9 54.9 92.2 54.8 96.7 54.1 101 54 114 53.9 127 53.2 140.4 49.7 145 48.5 149.7 46.6 154.3 44.4 160.4 41.5 161.2 33.2 158.6 27.7 156.8 23.9 154.6 20.8 151.7 19.6 150.5 19 149.1 18.7 148.2 17.5 145.9 14.5 142.9 14.2 140.1 13.7 131.3 12.2 122.4 11.2 113.5 10.1 111.9 9.9 110.3 9.8 108.6 9.7 102.3 9.5 95.8 10.2 89.7 8.7 81.1 6.7 72.3 7 63.4 7.1 55.9 7.2 48.4 7.6 40.7 8.9 33.7 10 26.6 12.4 19.6 14.4 17.3 15 14.9 16.5 12.6 16.7 9.9 17 7.5 19.1 5.1 21.7 3.3 23.7 2.8 25.7 3.1 29.1 3.3 32.3 4.2 34.7 5.7 36 8.7 38.8 11.7 41.4 14.9 43.7 23.8 50.2 33.5 53.6 43.5 54.8M33.5 60.2C26.7 59.4 19.9 56.9 13.7 52 10.3 49.3 7.1 46.3 4 42.8 2.5 41.1 1.3 38.3 0.5 35.4 -1.2 29.9 1.8 19.1 5.8 16.7 10.4 13.9 15.1 11.8 19.7 9.9 33.4 4.3 46.8 1.4 60 0.9 73.2 0.4 86.3 0 99.3 0 107.2 0 115.1 0.5 122.5 3.3 124.1 3.9 125.7 4.8 127 6.4 128.4 8.2 130 8.7 131.9 8.9 139.5 9.5 147 10.9 153.5 16 157.1 18.8 159.7 23.4 160.8 30.4 161.9 37.6 159 46.1 154.4 47.9 147.8 50.6 141.1 53.5 134.5 54.6 123.3 56.5 112.2 57.4 101.1 58.3 93.2 59 85.4 58.9 77.6 59.4 74 59.6 70.2 60.8 66.7 60.9 56.4 61.1 45.8 63.2 35.9 61 35.2 60.9 34.5 60.6 33.5 60.2';
          }

        case 'brush':
          return 'M464,136.6c-1.13,4.4-4,6.44-7,6.51-32.66.7-219.31,4.62-262.92,3.74-12.58-.25-24.75-2.2-37.33-2.46-34.15-.73-68.36-.63-102.52-.5-2.78,0-42.45,1.89-43.59.91C7.27,141.93-22.68-.44,35.84,3.56c16.95,1.15,89.79-.51,106.79.51,24.11,1.44,63,.79,86.25-.16C383.79-2.41,445.59-1.15,453.21,8.32,480.61,42.33,464,136.6,464,136.6Z';

        case 'ruban':
        default:
          return `
            M ${this.getRandom(0)} ${this.getRandom(0)}
            L ${this.getRandom(0)} ${this.getRandom(this.h, '-')}
            L ${this.getRandom(this.w, '-')} ${this.getRandom(this.h, '-')}
            L ${this.getRandom(this.w, '-')} ${this.getRandom(0)} Z`;
      }
    },
    getDisplay() {
      if (this.inlineBlock) return { display: 'inline-block' };
      if (this.block) return { display: 'block' };
      return {};
    },
  },

  methods: {
    getRandom(val, sign = '+') {
      return val + (Math.random() * 4 * (sign === '+' ? 1 : -1));
    },
    calcSize() {
      const rect = this.$refs.wrapper.getBoundingClientRect();
      this.w = rect.width;
      this.h = rect.height;
    },
  },

  mounted() {
    this.$nextTick(this.calcSize);
  },

  updated() {
    this.$nextTick(this.calcSize);
  },

};
</script>
