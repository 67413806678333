<template>
  <component
    :is="condensed ? 'div' : 'potager-container'"
    v-if="isLoggedIn && !isSubscriptionActive && subscriptionSlide"
    :class="{
      'push-subscription print:hidden': true,
      'push-subscription--condensed': condensed,
    }"
    size="medium">
    <h2 class="push-subscription__title">
      Activez votre abonnement sans engagement
    </h2>
    <h3 class="push-subscription__subtitle">
      Des prix préférentiels sur tous les paniers, et une gestion en 1 clic depuis votre compte !
    </h3>

    <potager-link :to="subscriptionTo">
      <potager-picture
        :src="subscriptionSlide.src"
        class="push-subscription__image" />
    </potager-link>
  </component>
</template>

<script>
import { mapGetters } from 'vuex';
import { FETCH_DASHBOARD_SLIDES_ACTION } from 'Stores/types/dashboardActionTypes';

import { getUrlAttributes, isExternalLink } from 'Classes/utils/RouteUtils';
import { getContentLink } from 'Classes/utils/RouteUtils';

import DashboardSlider from 'Components/dashboard/DashboardSlider';
import PotagerContainer from 'UI/PotagerContainer';
import PotagerPicture from 'UI/PotagerPicture';

export default {
  components: {
    PotagerPicture,
    DashboardSlider,
    PotagerContainer,
  },

  props: {
    condensed: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data: () => ({
    route: { name: 'tunnel' },
  }),

  computed: {
    ...mapGetters('session', [
      'isLoggedIn',
    ]),
    ...mapGetters('user', [
      'isSubscriptionActive',
    ]),
    ...mapGetters('dashboard', [
      'getSlides',
    ]),
    subscriptionSlide() {
      return this.getSlides
        .map((slide) => {
          let imageVersion;

          if (this.$mq.bp425) {
            imageVersion = 'mobileImage';
          } else if (this.$mq.bp768 || this.condensed) {
            imageVersion = 'mediumImage';
          } else {
            imageVersion = 'desktopImage';
          }

          return {
            ...slide,
            src: slide[imageVersion]
          };
        })
        .find((slide) => slide.isSubscriptionSlide);
    },
    subscriptionTo() {
      if (this.subscriptionSlide.target) {
        const contentLink = getContentLink(this.subscriptionSlide, null, true);
        const link = this.subscriptionSlide.link && this.subscriptionSlide.link !== '' && this.subscriptionSlide.link !== '#';
        return contentLink || link || null;
      }

      if (this.subscriptionSlide.link) {
        const attr = getUrlAttributes(this.subscriptionSlide.link);
        return (isExternalLink(this.subscriptionSlide.link) || !attr?.pathname)
          ? this.subscriptionSlide.link
          : `${attr.pathname}${attr.hash || ''}`;
      }

      return null;
    },
  },

  methods: {
    redirect() {
      this.$router.push(this.route)
        .catch(() => {
        });
    },
  },

  mounted() {
    if (!this.getSlides && this.isLoggedIn && !this.isSubscriptionActive) {
      this.$store.dispatch(`dashboard/${FETCH_DASHBOARD_SLIDES_ACTION}`);
    }
  },
};
</script>
