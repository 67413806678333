<template>
  <potager-container class="pb-24 bp768:pb-16">
    <h2 class="font-black text-4xl bp1024:text-3xl.5 bp1024:leading-tight w-full text-center pb-5">
      Comment ça marche&nbsp;?
    </h2>
    <div class="relative flex flex-col justify-center items-center">
      <potager-picture
        class="w-full max-w-3bp1920 absolute -mt-40 bp1024:hidden"
        src="b2b-comment-ca-marche-dashed-path" />
      <potager-slider
        name="b2b-slider-how-it-works"
        :slides-per-view="$mq.bp768 ? 'auto' : 3"
        :items="steps"
        :key="steps.length"
        overflow-visible
        :settings="{ freeMode: true }"
        class="w-full">
        <template v-slot:default="vProps">
          <div
            class="flex flex-col items-center m-4 mt-0 bp1024:mt-9 bp768:mt-7 px-12 bp1024:px-0"
            :class="(vProps.index === 1) ? 'mt-16' : ''">
            <div class="max-w-70 w-full bp1024:w-52 mb-8 bp1024:mb-5">
              <potager-picture
                :src="`b2b-comment-ca-marche-${vProps.index + 1}`"
                placeholder />
            </div>
            <div class="font-extrabold text-xl.5 pb-2">
              {{ vProps.index + 1 }}
            </div>
            <p class="font-medium text-center tracking-normal text-lg w-80 bp1024:w-52">
              {{ vProps.item }}
            </p>
          </div>
        </template>
      </potager-slider>
    </div>
  </potager-container>
</template>

<script>
import PotagerContainer from 'UI/PotagerContainer';
import PotagerSlider from 'UI/PotagerSlider';
import PotagerPicture from 'UI/PotagerPicture';

export default {

  components: {
    PotagerContainer,
    PotagerSlider,
    PotagerPicture,
  },

  data: () => ({
    steps: [
      'Nos producteurs partenaires cultivent et récoltent les fruits de votre corbeille.',
      'Après avoir reçu et préparé les fruits le matin, notre équipe livre vos locaux.',
      'Vos collaborateurs savourent chaque jour une pause vitaminée.',
    ],
  }),

};
</script>
