import DeliveryPointPage from 'Pages/static/DeliveryPointPage';

export default [
  {
    name: 'devenir-point-de-retrait',
    path: '/devenir-point-de-retrait-partenaire/',
    meta: {
      scrollToTop: true,
    },
    component: DeliveryPointPage,
  },

  {
    name: 'contact',
    path: '/contact/',
    beforeEnter() {
      window.location = 'https://potagercity.force.com/serviceclient/';
    },
  },
];
