export default {
  name: 'length',

  valid(value, constraint) {
    if (!value) return null;

    if (constraint.options.min && value.length < constraint.options.min) {
      return {
        field: constraint.field,
        message: `Veuillez saisir au minimum ${constraint.options.min} caractère${constraint.options.min > 1 ? 's' : ''}`,
        code: null,
      };
    }

    if (constraint.options.max && value.length > constraint.options.max) {
      return {
        field: constraint.field,
        message: `Veuillez saisir au maximum ${constraint.options.max} caractère${constraint.options.max > 1 ? 's' : ''}`,
        code: null,
      };
    }

    if (constraint.options.exact && value.length !== constraint.options.exact) {
      return {
        field: constraint.field,
        message: `Veuillez saisir exactement ${constraint.options.exact} caractère${constraint.options.exact > 1 ? 's' : ''}`,
        code: null,
      };
    }

    return null;
  },
};
