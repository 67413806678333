<template>
  <div class="relative">
    <div class="overflow-hidden">
      <b2b-slider-services-item
        v-for="(service, index) in services"
        ref="item"
        :key="index"
        :content="service" />
    </div>

    <template v-if="!$mq.bp768">
      <div class="absolute z-0 w-full h-full right-0 top-0">
        <potager-container
          class="h-full"
          :size="$mq.bp1024 ? 'wide' : undefined">
          <div
            class="sticky w-7/12 bp1024:w-5/12 ml-auto"
            :style="stickyStyles">
            <transition
              name="fade"
              mode="in-out">
              <potager-picture
                class="absolute inset-0 rounded-xl bp1024:rounded-r-none bg-white-smoke overflow-hidden"
                :key="services[styckyIndex].image"
                :src="services[styckyIndex].image"
                placeholder
                background />
            </transition>
          </div>
        </potager-container>
      </div>
    </template>
  </div>
</template>

<script>
import B2bSliderServicesItem from 'Pages/b2b/B2bSliderServicesItem';
import PotagerPicture from 'UI/PotagerPicture';
import PotagerContainer from 'UI/PotagerContainer';

const getVisibleHeightPx = ($element, viewportHeight) => {
  const rect = $element.getBoundingClientRect();
  const height = rect.bottom - rect.top;
  const visible = {
    top: rect.top >= 0 && rect.top < viewportHeight,
    bottom: rect.bottom > 0 && rect.bottom < viewportHeight,
  };
  let visiblePx = 0;

  if (visible.top && visible.bottom) {
    visiblePx = height;
  } else if (visible.top) {
    visiblePx = viewportHeight - rect.top;
  } else if (visible.bottom) {
    visiblePx = rect.bottom;
  } else if (height > viewportHeight && rect.top < 0) {
    const absTop = Math.abs(rect.top);
    if (absTop < height) visiblePx = height - absTop;
  }

  return visiblePx;
};

const getMostVisible = ($refs) => {
  const viewportHeight = window.innerHeight;
  let index = 0;
  let max = 0;

  if ($refs) {
    $refs.forEach(({ $el }, i) => {
      const visiblePx = getVisibleHeightPx($el, viewportHeight);
      if (visiblePx > max) {
        max = visiblePx;
        index = i;
      }
    });
  }

  return index;
};

export default {

  components: {
    B2bSliderServicesItem,
    PotagerPicture,
    PotagerContainer,
  },

  data: () => ({
    services: [
      {
        title: 'Une livraison de fruits et de bien être',
        image: 'b2b-framboises',
        items: [
          {
            icon: '🤗',
            title: 'Qualité de vie au travail responsable',
            description: 'Offrir des fruits à vos collaborateurs, c’est <b>créer des moments de convivialité autour d’un produit responsable !</b>',
          },
          {
            icon: '🔋',
            title: 'Amélioration de la productivité',
            description: 'Faire des pauses au travail permet d’<b>augmenter sa concentration et la créativité</b>, et c’est encore mieux quand c’est fruité.',
          },
          {
            icon: '❤️',
            title: 'Coup de pouce santé',
            description: 'Dites au revoir aux barres chocolatées ! Croquer dans un fruit c’est prendre <b>un encas sain et simple à portée de main.</b>',
          },
        ],
      },
      {
        title: 'Un service qui s’adapte à votre entreprise',
        image: 'b2b-pommes',
        items: [
          {
            icon: '😎',
            title: 'Simplicité',
            description: 'De la préparation à la livraison des corbeilles, <b>Potager City s’occupe de tout</b>. Un contact unique qui facilite les échanges et qui garantit <b>un accompagnement complet</b>.',
          },
          {
            icon: '⭐️',
            title: 'Personnalisation',
            description: '<b>Notre service s’adapte à votre demande</b> : vous décidez de la fréquence de vos livraisons et du nombre de corbeilles de fruits&nbsp;!',
          },
          {
            icon: '❤️',
            title: 'Flexibilité',
            description: 'Si vos besoins évoluent, nous évoluons avec eux. Besoin de plus de fruits ou d’arrêter les livraisons pendant les vacances ? <b>Nous sommes à votre écoute</b> !',
          },
        ],
      },
    ],
    stickyStyles: {
      height: 0,
      top: 0,
    },
    styckyIndex: 0,
  }),

  methods: {
    setStickyStyles() {
      if (!this.$mq.bp768) {
        const $el = this.$refs.item?.[0].$el;
        if ($el) {
          const H = $el.getBoundingClientRect().height;
          const T = (window.innerHeight - H) / 2;
          this.stickyStyles.height = `${H}px`;
          this.stickyStyles.top = `${T}px`;
        }
      }
    },
    onScroll() {
      this.styckyIndex = !this.$mq.bp768 ? getMostVisible(this.$refs?.item) : 0;
    },
  },

  mounted() {
    this.setStickyStyles();
    this.onScroll();
    window.addEventListener('resize', this.setStickyStyles);
    document.addEventListener('scroll', this.onScroll);
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.setStickyStyles);
    document.removeEventListener('scroll', this.onScroll);
  },

};
</script>
