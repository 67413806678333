<template>
  <tunnel-body class="text-center">
    <h1 class="text-xl font-extrabold mb-3 mx-10">
      Votre adresse
    </h1>

    <p class="mb-10">
      😎&nbsp;La livraison à domicile est disponible dans votre ville.
    </p>

    <div class="text-center">
      <dlp-form-home
        ref="addressForm"
        :back-route="getBackRoute"
        :context="context"
        class="mb-6"
        @onSubmit="onSubmit" />
    </div>
  </tunnel-body>
</template>

<script>
import { mapGetters } from 'vuex';

import TunnelBody from 'Components/tunnel/TunnelBody';
import DlpFormHome from 'Components/deliveryPoint/step3/DlpFormHome';

import SubscriptionModalMixin from 'Mixins/SubscriptionModalMixin';
import { Context } from 'potagerlogic/dist/Enums/Context';

export default {
  components: {
    TunnelBody,
    DlpFormHome,
  },

  props: {
    context: {
      type: String,
      default: Context.SubscriptionBasket,
    },
  },

  mixins: [SubscriptionModalMixin],

  computed: {
    ...mapGetters('tunnel', [
      'getPrevStepRoute',
      'getNextStepRoute',
    ]),
    ...mapGetters('session', [
      'getAddress',
    ]),
    getBackRoute() {
      return this.getPrevStepRoute();
    },
  },

  methods: {
    onSubmit(data) {
      const {
        id,
        regionId
      } = data;
      this.$router.push(this.getNextStepRoute({
        params: {
          deliveryPointRegionId: regionId,
          deliveryPointId: id,
        },
      }));
    },
  },
};
</script>
