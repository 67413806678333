<template>
  <potager-row
    v-if="loaded"
    centered
    class="m-0">
    <potager-card-button
      v-for="dlpType in dlpTypes"
      :key="dlpType.id"
      :class="[
        dlpType.class,
        'dlp-type__item',
      ]"
      :to="dlpType.route"
      allow-overflow>
      <template
        v-if="dlpType.badge"
        #badge>
        {{ dlpType.badge }}
      </template>

      <div class="font-normal w-full">
        <div class="flex justify-between mb-2">
          <span class="font-bold">
            {{ dlpType.label }}
          </span>
          <span class="font-extrabold text-forest-green">
            {{ dlpType.price }}
          </span>
        </div>

        <p
          class="text-xs.5 text-charcoal"
          v-html="dlpType.description" />
      </div>
    </potager-card-button>
  </potager-row>

  <potager-row v-else>
    <div
      v-for="i in 3"
      :key="i"
      :class="[
        'mt-3',
        'bg-white rounded-lg p-2.5 pl-4 mb-2 last:mb-0 w-full min-h-29',
        'flex items-center justify-between relative',
        'animate-pulse',
      ]" />
  </potager-row>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { RESET_HOME_DELIVERY } from 'Stores/types/homeDeliveryMutationsTypes';
import { RESET_SEARCH_RESULT, UPDATE_USER_SEARCH, RESET_USER_SEARCH } from 'Stores/types/mapMutationsTypes';

import { LADWording } from 'Classes/Constants';

import PotagerRow from 'UI/PotagerRow';
import PotagerCardButton from 'UI/PotagerCardButton';

export default {
  components: {
    PotagerRow,
    PotagerCardButton,
  },

  data() {
    return {
      loaded: true,
    };
  },

  props: {
    homeRoute: {
      type: [Object, String],
      default: undefined,
    },
    publicRoute: {
      type: [Object, String],
      default: undefined,
    },
    privateRoute: {
      type: [Object, String],
      default: undefined,
    },
    context: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapGetters('session', [
      'isLoggedIn',
      'getRegionId',
    ]),
    ...mapGetters('homeDelivery', [
      'isLADEnable',
    ]),
    ...mapGetters('app', [
      'getHomeDeliveryCost',
      'getHomeDeliveryLimit',
      'getHomeDeliveryRegionsIds',
    ]),
    deliveryCost() {
      return `+ ${new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR'
      }).format(this.getHomeDeliveryCost)}`;
    },
    deliveryLimit() {
      return new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR'
      }).format(this.getHomeDeliveryLimit);
    },
    LADBadgeMessage() {
      if (this.isLADEnable) {
        if (LADWording[this.getRegionId]) {
          return LADWording[this.getRegionId].main;
        }
        return LADWording.default.main;
      }
      return undefined;
    },
    dlpTypes() {
      const dlpTypes = [
        {
          label: 'En point relais',
          price: 'Offerte',
          route: this.publicRoute,
          description: 'Récupérez votre commande chez un commerçant partenaire près de chez vous.',
        },
        {
          label: 'En entreprise',
          price: 'Offerte',
          route: this.privateRoute,
          description: 'Réservé aux salariés des entreprises en partenariat avec Potager City.',
        },
      ];

      if (this.isLADEnable) {
        const ladDescription = `<span class="block font-extrabold text-forest-green">
            Livraison offerte dès ${this.deliveryLimit} d’achat.
          </span>
          Vos commandes livrées jusqu’à votre porte par un livreur partenaire en vélo cargo. Rapide et responsable.`;

        dlpTypes.unshift({
          label: 'À domicile',
          price: this.deliveryCost,
          route: this.homeRoute,
          description: ladDescription,
          badge: this.LADBadgeMessage,
        });
      }

      return dlpTypes;
    },
  },

  methods: {
    ...mapMutations('map', {
      RESET_USER_SEARCH,
      RESET_SEARCH_RESULT,
      UPDATE_USER_SEARCH,
    }),
    ...mapMutations('homeDelivery', [
      RESET_HOME_DELIVERY,
    ]),
  },

  mounted() {
    this.RESET_USER_SEARCH();
    this.RESET_SEARCH_RESULT();
    this.RESET_HOME_DELIVERY();
  },
};
</script>

<style lang="scss" scoped>
.dlp-type__item {
  //min-height: 6.25rem;
  align-items: flex-start;
}
</style>
