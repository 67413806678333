import store from 'Stores';
import { UPDATE_REQUEST_URL_ACTION } from 'Stores/types/sessionActionsTypes';
import { getPotagerRouteBasePage, getPotagerRouteFromUrl } from 'Plugins/potagerRouting';
import { redirectWebApp } from 'Mixins/MobileAppMixin';

export default function (to, from, next) {
  const route = getPotagerRouteFromUrl(to) || to;

  if (route.meta.requiresNoAuth && store.getters['session/isLoggedIn']) {
    store.dispatch(`session/${UPDATE_REQUEST_URL_ACTION}`, route);
    console.info('shouldBeDisconnected > Go base page', getPotagerRouteBasePage(to));
    redirectWebApp({
      requestUrl: route,
      guard: 'shouldBeDisconnected'
    });
    next(getPotagerRouteBasePage(to));
  }

  next();
}
