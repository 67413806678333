export default {
  name: 'mobile',

  valid(value, constraint, formValues) {
    if (!value) {
      return null;
    }

    if (value.match('^[0]{1}[6-7]{1}(\\d){8}$')) {
      return null;
    }

    return {
      field: constraint.field,
      message: `Cette valeur n'est pas un numéro de téléphone mobile valide`,
      code: null,
    };
  },
};