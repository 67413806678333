<template>
  <panel title="Connexion / Inscription">
    <template #header>
      <p class="text-sm.5 font-medium text-center">
        Créez votre compte ou connectez-vous pour commander.
      </p>
    </template>

    <div class="px-6 text-center">
      <potager-switch-button
        v-model="typeSwitchValue"
        class="mb-8">
        <template #left>
          Connexion
        </template>

        <template #right>
          Inscription
        </template>
      </potager-switch-button>

      <transition
        mode="out-in"
        name="fade-fast-translate-y">
        <!-- login tab -->
        <div v-if="typeSwitchValue">
          <template v-if="!isInFacebookApp">
            <component
              :is="Provider.getLoginButton(this, onLoginSuccess)"
              v-for="(Provider, index) in Providers"
              :key="index"
              class="last:mb-0" />
          </template>

          <div class="flex items-center mb-4">
            <div class="border-b border-0 border-white-rock border-solid w-full" />
            <div class="mx-4 font-bold">
              ou
            </div>
            <div class="border-b border-0 border-white-rock border-solid w-full" />
          </div>

          <potager-form
            id="login-form"
            :models="[emailConnexion, passwordConnexion]"
            name="loginForm"
            @canSubmit="(e) => canLogin = e"
            @onSubmit="onLogin">
            <potager-input
              v-model="emailConnexion"
              :constraints="[
                { constraint: 'required', field: 'email' },
                { constraint: 'email', field: 'email' },
              ]"
              label="Votre email"
              name="email"
              type="email">
              <template #icon>
                <icon-email-action-unread />
              </template>
            </potager-input>

            <potager-input
              v-model="passwordConnexion"
              :constraints="[
                { constraint: 'required', field: 'password' },
              ]"
              label="Votre mot de passe"
              name="password"
              no-password-validator
              type="password">
              <template #icon>
                <icon-lock-6 />
              </template>
            </potager-input>

            <potager-button
              :to="{ name: 'login_reset_password' }"
              class="mt-4 text-go-green underline not-italic mb-8"
              full-width
              size="small"
              theme="link">
              J'ai oublié mon mot de passe
            </potager-button>

            <template #submit>
              <potager-button
                :is-disabled="!canLogin"
                :is-loading="isLoginLoading"
                full-width
                theme="go-green"
                type="submit">
                Me connecter
              </potager-button>
            </template>
          </potager-form>
        </div>

        <!-- register tab -->
        <div
          v-else
          class="text-left">
          <registration-form
            v-if="getDeliveryPoint"
            ref="registrationForm"
            editable-email
            @onEmailAlreadyExist="$router.push({ name: 'login' })"
            @onEmailClick="resetEmail"
            @onSuccess="onLoginSuccess" />

          <div
            v-else
            class="text-center">
            <p class="text-sm.5 mb-10 font-medium px-6 text-center mb-4">
              Pour commencer votre inscription, choisissez d'abord un point de livraison (modifiable à tout moment).
            </p>

            <dlp-resume
              :context="Context.UserRegistration"
              condensed />
          </div>
        </div>
      </transition>
    </div>
  </panel>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { LOGIN_USER_ACTION, UPDATE_REQUEST_URL_ACTION } from 'Stores/types/sessionActionsTypes';

import SubscriptionRecoveryMixin from 'Mixins/SubscriptionRecoveryMixin';
import MobileAppMixin from 'Mixins/MobileAppMixin';

import MetaInfosService from 'Classes/services/MetaInfoService';
import Providers from 'Classes/social-providers/Providers';

import PotagerButton from 'UI/PotagerButton';
import PotagerForm from 'UI/Form/PotagerForm';
import PotagerInput from 'UI/Form/PotagerInput';
import PotagerSwitchButton from 'UI/PotagerSwitchButton';

import Panel from 'Components/panel/Panel';
import FramboiseMessage from 'Components/framboise/FramboiseMessage';
import RegistrationForm from 'Components/registration/RegistrationForm';
import DlpResume from 'Components/deliveryPoint/DlpResume';

import IconEmailActionUnread from 'Icons/bold/IconEmailActionUnread';
import IconLock6 from 'Icons/bold/IconLock6';
import { Context } from 'potagerlogic/dist/Enums/Context';

export default {
  mixins: [
    SubscriptionRecoveryMixin,
    MobileAppMixin,
  ],

  components: {
    DlpResume,
    RegistrationForm,
    PotagerSwitchButton,
    FramboiseMessage,
    Panel,
    PotagerButton,
    PotagerForm,
    PotagerInput,
    IconEmailActionUnread,
    IconLock6,
  },

  data: () => ({
    emailConnexion: '',
    passwordConnexion: '',
    canLogin: false,
    Providers,
    typeSwitchValue: true,
    Context,
  }),

  computed: {
    ...mapGetters('session', [
      'getRequestUrl',
    ]),
    ...mapGetters('basket', [
      'getDeliveryPoint',
    ]),
    ...mapGetters('temporaryCustomer', [
      'getTemporaryCustomerId',
    ]),
    isLoginLoading() {
      return this.$wait.is(LOGIN_USER_ACTION);
    },
  },

  watch: {
    typeSwitchValue(val) {
      const route = val ? 'login' : 'register';
      if (this.$potagerRoute.name !== route) {
        this.$router.replace({ name: route });
      }
    },
    $potagerRoute(to) {
      this.typeSwitchValue = to.name === 'login';
    },
  },

  methods: {
    ...mapActions('session', [
      UPDATE_REQUEST_URL_ACTION,
    ]),
    onLogin() {
      const param = {
        username: this.emailConnexion,
        password: this.passwordConnexion,
        temporaryCustomerId: this.getTemporaryCustomerId,
      };
      this.$store.dispatch(`session/${LOGIN_USER_ACTION}`, param)
        .then(this.onLoginSuccess)
        .catch((error) => {
          console.error(error);
          this.$events.emit(`add:formError:login-form`, error.response
            ? error.response.data.message
            : 'Allô Houspomme, une erreur est survenue. Veuillez contacter notre support.');
        });
    },
    onLoginSuccess() {
      this.$nextTick(() => {
        this.$router.push(this.getRequestUrl || { name: 'dashboard' });

        // clear request url
        this.UPDATE_REQUEST_URL_ACTION();
        this.trySubscriptionRecovery();
      });
    },
    resetEmail() {
      this.$refs.registrationForm.email = undefined;
    },
  },

  mounted() {
    this.typeSwitchValue = this.$potagerRoute.name === 'login';
  },

  head: MetaInfosService.generate({
    title: 'Connectez-vous',
    description: 'Connectez-vous sur Potager City pour gérer votre compte et accéder à vos commandes, vos codes de réduction ou encore vos recettes favorites !',
  }),
};
</script>
