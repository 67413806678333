<template>
  <header
    :class="[
      'px-0 main-header print:hidden',
    ]">
    <potager-container
      class="main-header__primary z-10"
      size="medium-small">
      <div
        class="flex items-center w-3/12">
        <div :class="['main-header__logo z-10 flex items-center']">
          <potager-link
            :to="{ name: 'home' }"
            aria-label="Accueil">
            <transition
              mode="out-in"
              name="appear-from-top">
              <potager-logo
                v-if="!$mq.bp640"
                key="logo"
                :class="$parent.mutators.includes('transparent-bianca') ? 'text-bianca' : undefined" />

              <potager-logo
                v-else
                key="logo-minimal"
                :class="$parent.mutators.includes('transparent-bianca') ? 'text-bianca' : undefined"
                minimal />
            </transition>
          </potager-link>
        </div>
      </div>

      <dlp-resume condensed />

      <main-header-actions />
    </potager-container>

    <div class="border-t border-white-rock">
      <potager-container
        class="main-header__secondary"
        size="medium-small">
        <main-header-navigation
          :menu="menu"
          :theme="$parent.mutators.includes('transparent-bianca') ? 'transparent-bianca' : 'transparent'" />
      </potager-container>
    </div>

    <potager-notifications />

    <main-header-alerts />
  </header>
</template>

<script>
import PotagerContainer from 'UI/PotagerContainer';
import PotagerLogo from 'UI/PotagerLogo';

import MainHeaderActions from 'Components/mainHeader/MainHeaderActions';
import MainHeaderNavigation, { props as navigationProps } from 'Components/mainHeader/MainHeaderNavigation';
import DlpResume from 'Components/deliveryPoint/DlpResume';
import MainHeaderAlerts from 'Components/mainHeader/MainHeaderAlerts';
import PotagerNotifications from 'Components/notification/PotagerNotifications';

export default {
  components: {
    PotagerNotifications,
    MainHeaderAlerts,
    DlpResume,
    MainHeaderNavigation,
    MainHeaderActions,
    PotagerContainer,
    PotagerLogo,
  },
  props: {
    ...navigationProps,
  },
};
</script>

<style lang="scss" scoped>
.main-header {
  &__nav {
    &__item {
      &__trigger {
        &--active {
          @apply after:block after:absolute after:bottom-0 after:left-4 after:right-4 after:h-0.5 after:bg-british-racing after:rounded-full;
        }
      }
    }
  }
}
</style>
