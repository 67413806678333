import { createRouter as _createRouter, createWebHistory } from 'vue-router';
import { Routes } from 'Router/routes';

import store from 'Stores';
import { ADD_ROUTE_TO_HISTORY_ACTION } from 'Stores/types/routeHistoryActionsTypes';
import { UPDATE_ROUTE } from 'Stores/types/routeHistoryMutationsTypes';

import onErrorRouter from 'Router/guards/onErrorRouter';
import customPageGuards from 'Router/guards/customPageGuards';
import mobileGuards from 'Router/guards/mobileGuards';
import requestedRedirectGuards from 'Router/guards/requestedRedirectGuards';
import keepQueryParams from 'Router/guards/keepQueryParamsGuard';
import shouldBeDisconnectedGuard from 'Router/guards/shouldBeDisconnectedGuard';
import shouldBeConnectedGuard from 'Router/guards/shouldBeConnectedGuard';

const onRouterPushError = (err) => {
  // if error is NavigationDuplicated, ignore it
  if (err.name !== 'NavigationDuplicated') {
    throw err;
  }
};

export const createRouter = () => {
  const router = _createRouter({
    history: createWebHistory(),
    routes: Routes,
    scrollBehavior(to, from, savedPosition) {
      if (to.meta.scrollToTop && (to.name !== from.name || to.path !== from.path)) {
        return { top: 0 };
      }

      if (savedPosition) {
        return savedPosition;
      }
    },
  });

  // add navigation guards
  router.beforeEach(shouldBeConnectedGuard);
  router.beforeEach(shouldBeDisconnectedGuard);
  router.beforeEach(customPageGuards);
  router.beforeEach(requestedRedirectGuards);
  router.beforeEach(keepQueryParams);
  router.beforeEach(mobileGuards);
  router.beforeEach((to, from, next) => {
    if (from.name) store.dispatch(`routeHistory/${ADD_ROUTE_TO_HISTORY_ACTION}`, from?.meta?.potagerRoute ?? from);
    next();
  });
  router.onError(onErrorRouter);

  // remove focus on router change
  router.afterEach((to) => {
    store.commit(`routeHistory/${UPDATE_ROUTE}`, to);
  });

  // Override router push to avoid NavigationDuplicated error
  const originalPush = router.push;
  router.push = function push(location) {
    return originalPush.call(this, location)
      .catch(onRouterPushError);
  };
  const originalReplace = router.replace;
  router.replace = function replace(location) {
    return originalReplace.call(this, location)
      .catch(onRouterPushError);
  };

  return router;
};

export default createRouter();
