<template>
  <div class="blog">
    <blog-body :is-loading="!content.id">
      <template #heading>
        <heading
          :back="{ name: 'reports-list' }"
          :background="content.image"
          :is-loading="!content.id"
          modifiers="blog">
          <template #title>
            {{ content.title }}
          </template>
        </heading>
      </template>

      <blog-section
        v-intersect="{
          once: true,
          callback: () => { hasIntersected = true; },
        }">
        <cms with-hat>
          <p v-html="cleanWP(content.description)" />
          <span v-html="cleanWP(content.content)" />
        </cms>
      </blog-section>

      <blog-social
        :item="content"
        context="report" />
    </blog-body>

    <push-subscription class="mb-10 bp768:my-6" />

    <micro-datas
      :content="content"
      context="report" />
  </div>
</template>

<script>
import BlogMixin from 'Mixins/BlogMixin';
import NewsletterPopupMixin from 'Mixins/NewsletterPopupMixin';

export default {
  mixins: [
    BlogMixin,
    NewsletterPopupMixin,
  ],
  head() {
    return this.metaInfos;
  }
};
</script>
