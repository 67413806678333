<template>
  <div
    v-if="!isLoggedIn"
    :class="{
      'newsletter-subscriber': true,
      'newsletter-subscriber--framed': framed,
    }">
    <p
      v-if="title"
      class="newsletter-subscriber__title">
      {{ title }}
    </p>

    <p
      v-if="intro"
      class="newsletter-subscriber__intro">
      {{ intro }}
    </p>

    <potager-form
        id="newsletter-subscriber"
        @onSubmit="onSubscriptionSubmit(email)">
      <potager-input
        v-model="email"
        name="email"
        type="email"
        placeholder="Votre email"
        :is-disabled="isSubscriptionLoading"
        :constraints="[
          { constraint: 'required', field: 'email' },
          { constraint: 'email', field: 'email' },
        ]" />
      <potager-button
        v-if="email"
        full-width
        theme="wild-willow"
        type="submit"
        :is-loading="isSubscriptionLoading">
        Je m'inscris
      </potager-button>
    </potager-form>

    <potager-info size="small">
      <template #icon>
        <icon-information-circle />
      </template>
      <a
        href="#"
        @click.prevent="showCgv">
        En savoir plus sur le traitement de vos données personnelles
      </a>
    </potager-info>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import IconInformationCircle from 'Icons/regular/IconInformationCircle';
import PotagerInput from 'UI/Form/PotagerInput';
import PotagerInfo from 'UI/PotagerInfo';
import PotagerButton from 'UI/PotagerButton';
import PotagerForm from 'UI/Form/PotagerForm';

import NewsletterMixin from 'Mixins/NewsletterMixin';
import ModalCgvForm from 'Modals/ModalCgvForm';

export default {
  mixins: [NewsletterMixin],

  props: {
    title: {
      type: [String, Boolean],
      required: false,
      default: 'Abonnez-vous à notre newsletter',
    },
    intro: {
      type: [String, Boolean],
      required: false,
      default: `
        Recevez, chaque mois par email, des reportages sur nos producteurs,
        des conseils de conservation de produits ainsi que des recettes.
      `,
    },
    framed: {
      type: Boolean,
      required: false,
      default: false,
    },
    source: {
      type: String,
      required: false,
      default: undefined,
    },
  },

  components: {
    IconInformationCircle,
    PotagerInput,
    PotagerInfo,
    PotagerButton,
    PotagerForm,
  },

  data: () => ({
    email: null,
  }),

  computed: {
    ...mapGetters('session', [
      'isLoggedIn',
    ]),
  },

  methods: {
    showCgv() {
      this.$modal.open(ModalCgvForm);
    },
  },
};
</script>
