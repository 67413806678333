import PotagerApi from 'potagerapiclient';
import { setApiUrl } from 'Classes/ApiSwitcher';

export const getApi = () => {
  let baseURL = import.meta.env.VITE_WEB_ROOT_PATH;
  if (import.meta.env.VITE_WEB_ENV !== 'production') {
    setApiUrl();
    baseURL = localStorage.getItem('api-switcher') || baseURL;
  }

  return new PotagerApi({ baseURL });
};

export const api = getApi();

export default {
  install(app) {
    app.config.globalProperties.$api = api;
  },
};
