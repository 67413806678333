<template>
  <potager-modal
    ref="modal"
    :name="name"
    :show-iteration-expiration="42"
    :show-once-expiration="7"
    show-once-cookie
    without-footer
    without-header>
    <template #body>
      <div class="modal-onboarding__steps mb-[30px] text-sm.5">
        <transition name="fade">
          <div
            v-if="isWelcomeStepActive && showWelcomeStep"
            class="modal-onboarding__step-welcome text-left">
            <template v-if="isSubscriptionActive">
              <p class="text-xl font-extrabold mb-6">
                Bienvenue&nbsp;!&nbsp;👋
              </p>

              <p class="mb-6">
                <b>Votre compte client est bien créé</b> et votre 1ère livraison du
                <b>{{ tmsToFormat(subscriptionFirstOrderDate, 'eeee dd MMMM') }}</b> <span v-html="deliveryName" />
                est validée. Vous pouvez maintenant découvrir tous nos rayons&nbsp;!
              </p>

              <div class="p-4 bg-bianca rounded-md">
                ℹ️&nbsp;Complétez votre commande avec des fruits et légumes à
                l’unité ou des produits d’épicerie
                <b>
                  <template v-if="!subscriptionIsCurrentlyOrderable">
                    à partir du {{ tmsToFormat(subscriptionAvailableOrderableDate, 'eeee dd MMMM') }}
                  </template>
                  jusqu'au {{ tmsToFormat(subscriptionLimitOrderDate, 'eeee dd MMMM') }} 23h59.
                </b>
              </div>
            </template>

            <template v-else>
              <p class="text-xl font-extrabold mb-6">
                Bienvenue dans notre Potager !&nbsp;👋
              </p>

              <p class="mb-6">
                <b>
                  Votre compte client est bien créé.
                </b>
                Vous pouvez maintenant faire votre marché ou valider votre panier.
              </p>

              <div class="p-4 bg-bianca rounded-md">
                ℹ️&nbsp;Passez commande
                <template v-if="!nonSubscriptionNextDeliveryAvailable">
                  à partir du <b>{{ tmsToFormat(nonSubscriptionAvailableOrderableDate, 'eeee dd MMMM') }}</b> jusqu'au
                  <b>{{ tmsToFormat(nonSubscriptionLimitOrderDate, 'eeee dd MMMM') }} 23h59</b>
                </template>
                <template v-else>
                  avant <b>{{ tmsToFormat(nonSubscriptionLimitOrderDate, 'eeee dd MMMM') }} 23h59</b>
                </template>
                pour être livré le <b>{{ tmsToFormat(nonSubscriptionDeliveryDate, 'eeee dd MMMM') }}</b> <span
                  v-html="deliveryName" />.
              </div>
            </template>
          </div>

          <div
            v-else-if="isOptinStepActive && showOptinStep"
            class="modal-onboarding__step-optin text-left">
            <p class="text-xl font-extrabold mb-6">
              Le seul mail qu’il vous faut&nbsp;💌
            </p>

            <p class="mb-6">
              Pour profiter pleinement de l’expérience et de notre expertise : <b>
                recevez chaque semaine les plus belles
                nouveautés de la boutique
              </b>, pour ne jamais louper les 1ères récoltes de fraises et autres exclusivités des
              saisons.
            </p>

            <div class="flex items-center mb-6 relative bg-bianca p-4 rounded w-full">
              <potager-checkbox
                v-model="optins.newsletter"
                :is-loading="isLoading"
                class="modal-onboarding__checkbox static">
                Ça m’intéresse&nbsp;!
              </potager-checkbox>
            </div>
          </div>
        </transition>
      </div>

      <div class="modal-onboarding__nav flex bp425:flex-col bp425:items-center gap-[30px]">
        <div
          v-if="hasMultipleSteps"
          class="modal-onboarding__nav-steps w-full flex items-center">
          <div
            ref="navStep1"
            :class="[
              'h-0.5 rounded w-full mr-2',
              isWelcomeStepActive ? 'bg-go-green' : 'bg-white-rock',
            ]" />
          <div
            ref="navStep2"
            :class="[
              'h-0.5 rounded w-full',
              isOptinStepActive ? 'bg-go-green' : 'bg-white-rock',
            ]" />
        </div>

        <div class="flex flex-1 bp425:w-full">
          <potager-button
            v-if="hasMultipleSteps"
            :is-disabled="isWelcomeStepActive || isLoading"
            class="mr-2"
            is-square
            theme="bianca"
            @onClick="goBack">
            <template #icon>
              <icon-arrow-left-1
                class="w-3.5 h-3.5" />
            </template>
          </potager-button>

          <potager-button
            :full-width="!hasMultipleSteps || $mq.bp425"
            :is-disabled="isLoading"
            theme="go-green"
            @onClick="goNext">
            {{ isOptinStepActive ? 'Valider' : !showOptinStep ? 'C\'est noté !' : 'Continuer' }}
          </potager-button>
        </div>
      </div>
    </template>
  </potager-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { UPDATE_OPTINS_ACTION } from 'Stores/types/userActionsTypes';

import PotagerModal from 'UI/PotagerModal';
import PotagerButton from 'UI/PotagerButton';
import PotagerCheckbox from 'UI/Form/PotagerCheckbox';

import IconArrowLeft1 from 'Icons/bold/IconArrowLeft1';

import { MODAL_ONBOARDING_NAME } from 'Classes/Constants';
import { tmsToFormat } from 'Classes/utils/DatesUtils';

export default {
  props: {
    showWelcomeStep: {
      type: Boolean,
      default: true,
    },
    showOptinStep: {
      type: Boolean,
      default: true,
    },
  },

  name: MODAL_ONBOARDING_NAME,

  components: {
    PotagerCheckbox,
    PotagerButton,
    PotagerModal,
    IconArrowLeft1,
  },

  data: () => ({
    name: MODAL_ONBOARDING_NAME,
    tracking: {
      event: 'Modal',
      name: MODAL_ONBOARDING_NAME,
    },
    isWelcomeStepActive: true,
    isOptinStepActive: false,
    optins: {
      newsletter: false,
    },
  }),

  computed: {
    ...mapGetters('user', {
      getHasMobileApp: 'getHasMobileApp',
      isSubscriptionActive: 'isSubscriptionActive',
      subscriptionFirstOrderDate: 'getSubscriptionFirstOrderDate',
      subscriptionAvailableOrderableDate: 'getAvailableOrderableDate',
      subscriptionLimitOrderDate: 'getLimitOrderDate',
      subscriptionNextOrder: 'getSubscriptionNextOrder',
      subscriptionIsCurrentlyOrderable: 'isCurrentlyOrderable',
    }),
    ...mapGetters('basket',
      {
        nonSubscriptionLimitOrderDate: 'getLimitDate',
        nonSubscriptionDeliveryDate: 'getDeliveryDate',
        nonSubscriptionDeliveryPoint: 'getDeliveryPoint',
        nonSubscriptionNextDeliveryAvailable: 'isNextDeliveryAvailable',
        nonSubscriptionAvailableOrderableDate: 'getAvailableOrderableDate',
      }),
    isLoading() {
      return this.$wait.is(UPDATE_OPTINS_ACTION);
    },
    hasMultipleSteps() {
      return this.showWelcomeStep && this.showOptinStep;
    },
    deliveryName() {
      const deliveryPoint = this.subscriptionNextOrder?.deliveryPoint || this.nonSubscriptionDeliveryPoint;
      return deliveryPoint?.isHomeDelivery ? 'chez vous' : `chez votre point relais&nbsp;: <b>${deliveryPoint.groupName}</b>`;
    },
  },

  methods: {
    tmsToFormat,
    updateOptin() {
      this.$store.dispatch(`user/${UPDATE_OPTINS_ACTION}`, {
        optinCompositionBoxEmail: true,
        optinCompositionBoxPushnotif: true,
      })
        .then(() => {
          this.$refs.modal.trackModal('subscribe');

          this.$notify({
            type: 'success',
            title: 'C’est noté !',
            text: `Vous recevrez désormais la composition hebdomadaire par e-mail${this.getHasMobileApp ? ' et par notification push' : ''}.`,
          });
        })
        .catch((err) => {
          this.$refs.modal.trackModal('subscribe-error');

          console.error('Error while updating optins in modal composition', err);

          this.$notify({
            type: 'warning',
            title: 'Oups !',
            text: 'Une erreur est survenue, veuillez réessayer.',
          });
        })
        .finally(() => {
          this.$refs.modal.close();
        });
    },
    goBack() {
      if (this.isOptinStepActive) {
        this.isWelcomeStepActive = true;
        this.isOptinStepActive = false;
      }
    },
    goNext() {
      if (this.isWelcomeStepActive) {
        if (this.showOptinStep) {
          this.isWelcomeStepActive = false;
          this.isOptinStepActive = true;
        } else {
          this.$refs.modal.close();
        }
      } else if (this.isOptinStepActive) {
        if (this.optins.newsletter) {
          this.updateOptin();
        } else {
          this.$refs.modal.close();
        }
      }
    },

  },

  mounted() {
    if (this.showOptinStep && !this.showWelcomeStep) {
      this.isWelcomeStepActive = false;
      this.isOptinStepActive = true;
    }
  },
};
</script>

<style lang="scss" scoped>
.modal-onboarding {
  &__checkbox {
    :deep() .potager-checkbox {
      &__label {
        &:after {
          content: '';
          position: absolute;
          inset: 0;
        }
      }

      &__loader {
        @apply top-4 left-4;
      }
    }
  }
}
</style>
