<template>
  <potager-link
    :class="`relative card-timeslot card-timeslot--${status} panel-section mx-0 mb-2 last:mb-0 flex cursor-pointer`"
    :to="orderLink"
    @click="!order && !isLoading && toggleSubscriptionBreak()">
    <div
      :class="[
        'panel-section__line flex flex-1',
        {
          'bg-white-rock animate-pulse h-[112px]': isSkeleton,
        }
      ]">
      <template v-if="!isSkeleton">
        <div
          class="card-timeslot__date rounded-lg text-xs font-medium w-[80px] h-[80px] flex items-center flex-col justify-center p-2 text-center leading-none mr-3 capitalize">
          <div>
            {{ tmsToFormat(timeSlot.date, 'eee') }}
          </div>

          <div class="text-2xl font-bold">
            {{ tmsToFormat(timeSlot.date, 'd') }}
          </div>

          <div>
            {{ tmsToFormat(timeSlot.date, 'MMM') }}
          </div>
        </div>

        <div class="flex-auto flex flex-col justify-between">
          <div class="flex justify-between items-center">
            <div
              class="card-timeslot__status potager-button potager-button--rounded potager-button--size-small cursor-default	">
              {{ statusWording }}
            </div>

            <div
              v-if="price"
              class="card-timeslot__price text-xs.5 font-bold">
              {{ price }}
            </div>
          </div>

          <div class="flex justify-between items-center gap-2">
            <div class="panel-section__separator w-full" />

            <potager-button
              v-if="!isUnavailable"
              :is-circle="isCircleButton"
              :is-loading="isLoading"
              :to="orderLink"
              class="m-0"
              is-rounded
              theme="stroke">
              <template
                v-if="isCircleButton"
                #icon>
                <icon-arrow-right class="text-xs" />
              </template>

              <template v-if="!isCircleButton">
                {{ buttonWording }}
              </template>
            </potager-button>
          </div>
        </div>
      </template>
    </div>
  </potager-link>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  ADD_SUBSCRIPTION_BREAK_ACTION,
  DELETE_SUBSCRIPTION_BREAK_ACTION,
  GET_SUBSCRIPTION_TIME_SLOTS_ACTION,
} from 'Stores/types/userActionsTypes';
import { TimeSlotStatus } from 'potagerlogic/dist/Enums/TimeSlot';

import PotagerButton from 'UI/PotagerButton';

import IconArrowRight from 'Icons/bold/IconArrowRight';

import { getTotalOrder } from 'Classes/utils/PricesUtils';
import { tmsToFormat } from 'Classes/utils/DatesUtils';

import { getOrderStatusLabel } from 'potagerlogic/dist/Utils/Order/OrderStatus';

export default {
  name: 'CardTimeslot',
  components: {
    PotagerButton,
    IconArrowRight,
  },

  props: {
    timeSlot: {
      type: [Object, Number], // number is for skeleton
      required: true,
    },
    order: {
      type: Object,
      default: null,
    },
    skeleton: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      TimeSlotStatus,
    };
  },

  computed: {
    ...mapGetters('user', [
      'getSubscription',
    ]),
    isSkeleton() {
      return !(this.order || (!this.order && this.getSubscription)) || this.skeleton;
    },
    isLoading() {
      return this.timeSlot && this.$wait.is([
        ADD_SUBSCRIPTION_BREAK_ACTION + this.timeSlot.date,
        DELETE_SUBSCRIPTION_BREAK_ACTION + this.timeSlot.date,
        GET_SUBSCRIPTION_TIME_SLOTS_ACTION + this.timeSlot.date,
      ]);
    },
    price() {
      if (this.order) {
        return getTotalOrder(this.order) + '€';
      } else if (this.getSubscription?.theoricalPrice) {
        return this.getSubscription.theoricalPrice.total + '€';
      } else {
        return undefined;
      }
    },
    status() {
      return this.order?.frontStatus || this.timeSlot?.status;
    },
    isUnavailable() {
      return this.status === TimeSlotStatus.unavailable;
    },
    buttonWording() {
      if (this.order) {
        return this.order?.isEditable || this.order?.isResumable ? 'Modifier' : 'Reprendre';
      } else {
        switch (this.status) {
          case TimeSlotStatus.scheduled:
            return 'Suspendre la livraison';
          // should never occur
          // unavailable timeslots doesn't exist on back

          // -> quand un point de retrait est fermé / férié, le back va essayer de transférer la livraison sur un autre PR
          // ou déplacer le jour de livraison. Donc un timeslot est toujours disponible
          case TimeSlotStatus.unavailable:
            return 'Indisponible';
          default:
            return 'Réactiver';
        }
      }
    },
    orderLink() {
      return this.order ? {
        name: 'mon-compte_orders_detail',
        params: { orderId: this.order.id },
        query: {
          back: JSON.stringify({
            name: this.$potagerRoute.name,
            params: this.$potagerRoute.params,
          }),
        }
      } : null;
    },
    isCircleButton() {
      return this.order && !(this.order?.isEditable || this.order?.isResumable);
    },
    statusWording() {
      return getOrderStatusLabel(this.order, this.timeSlot);
    },
  },

  methods: {
    tmsToFormat,
    ...mapActions('user', [
      ADD_SUBSCRIPTION_BREAK_ACTION,
      DELETE_SUBSCRIPTION_BREAK_ACTION,
    ]),
    toggleSubscriptionBreak() {
      const date = this.timeSlot?.date; // store it in a variable to make sure that it doesn’t change between start and end
      if (this.timeSlot?.status === TimeSlotStatus.scheduled) {
        this.$wait.start(ADD_SUBSCRIPTION_BREAK_ACTION + date);
        this.ADD_SUBSCRIPTION_BREAK_ACTION(date)
          .finally(() => this.$wait.end(ADD_SUBSCRIPTION_BREAK_ACTION + date));
      } else {
        this.$wait.start(DELETE_SUBSCRIPTION_BREAK_ACTION + date);
        this.DELETE_SUBSCRIPTION_BREAK_ACTION(date)
          .finally(() => this.$wait.end(DELETE_SUBSCRIPTION_BREAK_ACTION + date));
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.card-timeslot {
  $this: &;

  &__date,
  &__status {
    @apply bg-bianca;
  }

  // see status in Constants.js
  &--unavailable,
  &--refunded,
  &--canceled_can_resume,
  &--canceled_can_not_resume {
    #{$this} {
      &__date,
      &__status {
        @apply bg-rossabel text-cinnabar;
      }

      &__price {
        @apply line-through;
      }
    }
  }

  &--suspended,
  &--paiement_failed {
    #{$this} {
      &__date,
      &__status {
        @apply bg-sandy-beach text-mango-tango;
      }

      &__price {
        @apply line-through;
      }
    }
  }

  &--validated,
  &--payed,
  &--preparation,
  &--delivered {
    #{$this} {
      &__status {
        @apply bg-beryl-green text-british-racing;
      }
    }
  }
}
</style>
