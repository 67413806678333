/**
 * Workflow documentation
 * https://potagercity.atlassian.net/browse/PTCY-3685?focusedCommentId=22081
 */
import {
  DELIVERY_HOME_TYPE,
  DELIVERY_PRIVATE_TYPE,
  DELIVERY_PUBLIC_TYPE,
  DELIVERY_SKIP_TYPE,
  DELIVERY_TYPE,
  ORDER_ALC_TYPE,
  ORDER_TYPE,
  REGISTRATION_LOGIN_TYPE,
  REGISTRATION_REGISTER_TYPE,
  REGISTRATION_TYPE,
} from 'Classes/workflow/TunnelTypesConstants';

import { TUNNEL_DIRECTION_BACKWARD } from 'Classes/Constants';

import TunnelWorkflow from './TunnelWorkflow';

/* Defining the steps of the tunnel. */
export const TunnelSteps = [
  {
    name: 'eligibility',
    steps: [
      {
        name: 'introduction',
        route: 'tunnel-introduction',
      },
      {
        name: 'regions',
        route: 'tunnel-regions',
        constraints: [
          {
            name: 'isLoggedIn',
            value: false,
          },
          {
            name: 'hasGroupDeliveryDay',
            value: false,
          },
          {
            name: ORDER_TYPE,
            value: ORDER_ALC_TYPE,
            operator: 'not',
          }
        ],
      },
    ],
  },
  // {
  //   name: 'orderType',
  //   route: 'tunnel-order-type',
  //   constraints: [
  //     {
  //       name: 'isLoggedIn',
  //       value: false,
  //     },
  //     {
  //       name: 'isInWebview',
  //       value: true,
  //     },
  //   ],
  // },
  {
    name: 'box',
    constraints: [
      {
        name: ORDER_TYPE,
        value: ORDER_ALC_TYPE,
        operator: 'not',
      },
      {
        name: 'hasRegion',
        value: true,
      }
    ],
    steps: [
      {
        name: 'oac',
        route: 'tunnel-oac',
        hidden: true, // accessible using getStepRouteByName getter
      },
      {
        name: 'boxSlider',
        route: 'tunnel-box-slider',
      },
      {
        name: 'boxOptions',
        route: 'tunnel-box-options',
        constraints: [{
          name: 'hasCustomerSubscriptionProductOptions',
          value: true,
        }],
      },
    ],
  },
  {
    name: 'delivery',
    constraints: [{
      name: 'hasRegion',
      value: true,
    }],
    steps: [
      {
        name: 'deliveryTypeResume',
        constraints: [{
          name: 'hasDeliveryPoint',
          value: true,
        },
          {
            name: 'hasGroupDeliveryDay',
            value: true,
          },
        ],
        route: 'tunnel-delivery-type-resume',
      },
      {
        name: 'deliveryType',
        route: 'tunnel-delivery-type-step1',
        constraints: [
          {
            name: DELIVERY_TYPE,
            value: DELIVERY_SKIP_TYPE,
            operator: 'not',
          },
          {
            name: 'hasGroupDeliveryDay',
            value: false,
            direction: TUNNEL_DIRECTION_BACKWARD,
          }
        ],
      },
      {
        name: 'deliveryPoint',
        constraints: [{
          name: DELIVERY_TYPE,
          value: DELIVERY_SKIP_TYPE,
          operator: 'not',
        }],
        steps: [
          {
            name: 'deliveryPointHome',
            constraints: [{
              name: DELIVERY_TYPE,
              value: DELIVERY_HOME_TYPE,
            }],
            steps: [
              {
                name: 'deliveryPointHomeSearch',
                route: 'tunnel-delivery-point-home-search-step2',
              },
              {
                name: 'deliveryPointHomeForm',
                constraints: [{
                  name: 'hasHomeDeliveryPostalCode',
                  value: true,
                }],
                route: 'tunnel-delivery-point-home-form-step3',
              },
              {
                name: 'deliveryPointHomeGdd',
                constraints: [{
                  name: 'hasHomeDeliveryAddress',
                  value: true,
                }],
                route: 'tunnel-delivery-point-home-gdd-step4',
              },
            ],
          },
          {
            name: 'deliveryPointPublic',
            constraints: [{
              name: DELIVERY_TYPE,
              value: DELIVERY_PUBLIC_TYPE,
            }],
            steps: [
              {
                name: 'deliveryPointPublicSearch',
                route: 'tunnel-delivery-point-public-search-step2',
              },
              {
                name: 'deliveryPointPublicList',
                route: 'tunnel-delivery-point-public-list-step3',
              },
              {
                name: 'deliveryPointPublicGdd',
                route: 'tunnel-delivery-point-public-gdd-step4',
              },
            ],
          },
          {
            name: 'deliveryPointPrivate',
            constraints: [{
              name: DELIVERY_TYPE,
              value: DELIVERY_PRIVATE_TYPE,
            }],
            steps: [
              {
                name: 'deliveryPointPrivateSearch',
                route: 'tunnel-delivery-point-private-search-step2',
              },
              {
                name: 'deliveryPointPrivateList',
                route: 'tunnel-delivery-point-private-list-step3',
              },
              {
                name: 'deliveryPointPrivateGdd',
                route: 'tunnel-delivery-point-private-gdd-step4',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: 'account',
    constraints: [
      {
        name: 'isLoggedIn',
        value: false,
      },
      {
        name: 'hasRegion',
        value: true,
      },
      {
        name: 'hasGroupDeliveryDay',
        value: true,
      }
    ],
    steps: [
      {
        name: 'registrationType',
        route: 'tunnel-registration-type',
      },
      {
        name: 'registration',
        constraints: [{
          name: 'hasEmail',
          value: true,
        }],
        steps: [
          {
            name: 'registrationRegister',
            constraints: [{
              name: REGISTRATION_TYPE,
              value: REGISTRATION_REGISTER_TYPE,
            }],
            route: 'tunnel-registration-register',
          },
          {
            name: 'registrationLogin',
            constraints: [{
              name: REGISTRATION_TYPE,
              value: REGISTRATION_LOGIN_TYPE,
            }],
            steps: [
              {
                name: 'registrationLoginForm',
                route: 'tunnel-registration-login-form',
              },
              {
                name: 'registrationLoginForgetPassword',
                route: 'tunnel-registration-login-forget-password',
                hidden: true, // accessible using getStepRouteByName getter
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: 'subscription',
    constraints: [
      {
        name: ORDER_TYPE,
        value: ORDER_ALC_TYPE,
        operator: 'not',
      },
      {
        name: 'hasRegion',
        value: true,
      },
      {
        name: 'hasDeliveryPoint',
        value: true,
      },
      {
        name: 'hasSubscriptionBasket',
        value: true,
      }
    ],
    steps: [
      {
        name: 'subscriptionSummary',
        constraints: [{
          name: 'mediaQueries',
          value: 'bp1024',
        }],
        route: 'tunnel-subscription-summary',
      },
      {
        name: 'subscriptionPayment',
        route: 'tunnel-subscription-payment',
      },
    ],
  },
  {
    name: 'success',
    constraints: [{
      name: 'showSuccessPage',
      value: true,
    }],
    steps: [
      {
        name: 'subscriptionSuccess',
        route: 'tunnel-subscription-success',
      },
    ],
  },
];

export default () => TunnelWorkflow(TunnelSteps);
