<template>
  <div>
    <div class="panel-section">
      <p class="panel-section__p panel-section__p--without-mb">
        En activant cette option, vous permettez à notre robot d'appliquer
        de manière automatique vos coupons (hors coupons de type pourcentage)
        lorsque vos commandes sont créées. L'algorithme appliquera autant
        de coupons que possible, en prenant ceux ayant les valeurs les plus
        hautes en premier. Vous aurez jusqu'à l'avant-veille de la livraison
        de votre commande pour modifier cette application si elle ne vous convient pas.
      </p>
    </div>

    <div class="panel-section">
      <p class="panel-section__subtitle">
        Paramétrer les options
      </p>

      <div class="panel-section__line">
        <potager-input
          v-model="acceptableLoss"
          :min="0"
          label="Perte Acceptable"
          suffix="€"
          type="number" />

        <potager-input
          v-model="maxCouponAutomated"
          :options="options"
          label="Nombre max. de coupons par commande"
          name="maxCouponAutomated"
          type="select" />
      </div>
    </div>
  </div>
</template>

<script>
import PotagerInput from 'UI/Form/PotagerInput';
import { UPDATE_COUPON_AUTOMATED_CONFIGURATION_ACTION } from 'Stores/types/userActionsTypes';

export default {

  components: {
    PotagerInput,
  },

  data() {
    return {
      acceptableLoss: 0,
      maxCouponAutomated: -1,
      options: [{
        label: 'Pas de limite',
        value: -1,
      }, {
        label: 1,
        value: 1,
      }, {
        label: 2,
        value: 2,
      }],
    };
  },

  methods: {
    onConfigOk() {
      this.$store.dispatch(`user/${UPDATE_COUPON_AUTOMATED_CONFIGURATION_ACTION}`, {
        isCouponAutomated: 1,
        acceptableLoss: this.acceptableLoss,
        maxCouponAutomated: this.maxCouponAutomated,
      })
        .then((resp) => {
          this.$emit('configUpdated', resp);
        });
    },
  },

};
</script>
