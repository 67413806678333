<template>
  <transition name="fade-fast-translate-y">
    <div
      v-if="!isBasketEmpty && diffPrice > 0 && !isSubscription"
      class="panel-section bg-sandy-beach mx-0 mt-0 mb-3 text-xs.5 font-medium">
      <div class="panel-section__line">
        Plus que <b class="font-bold">
          {{ formatPrice(diffPrice) }}
        </b> pour commander (min. {{ formatPrice(getOneShotMinimumAmount) }})
        <div class="progress-bar mt-2.5 h-1 bg-white rounded overflow-hidden">
          <div
            :style="{ transform: `translateX(-${100 - percentage}%)` }"
            class="progress-bar__fill bg-mango-tango h-full w-full rounded transition ease-out duration-300" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';
import { formatPrice } from 'Classes/utils/PricesUtils';

export default {
  name: 'BasketMinimumAmount',
  methods: { formatPrice },
  computed: {
    ...mapGetters('app', ['getOneShotMinimumAmount']),
    ...mapGetters('basket', ['getSubTotal', 'isSubscription']),
    diffPrice() {
      return this.getOneShotMinimumAmount - this.getSubTotal;
    },
    percentage() {
      return Math.round((this.getSubTotal / this.getOneShotMinimumAmount) * 100);
    },
    isBasketEmpty() {
      return Number(this.getSubTotal) === 0;
    },
  },
};
</script>
