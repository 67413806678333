<template>
  <section class="home-page-manifesto bg-british-racing h-[400px] relative">
    <potager-picture
      title="Un potager vu de dessus"
      background
      class="w-full h-full absolute top-0 left-0 flex flex-col justify-center items-center text-center px-6"
      src="background-potager">
      <potager-logo
        :height="80"
        class="mb-4 text-white"
        minimal />

      <h2 class="text-white text-4xl.5 bp640:text-2xl.5 leading-snug font-semibold">
        Dénicheurs de <b class="font-extrabold">
          bon.
        </b>
      </h2>

      <p class="text-white text-xl.5 bp640:text-base leading-snug mb-6 font-semibold">
        Une histoire de pépites bien produites et bien choisies.
      </p>

      <potager-button
        :to="{ name: 'manifesto' }"
        theme="bianca"
        v-gtm>
        Découvrir le manifeste du bon
      </potager-button>
    </potager-picture>
  </section>
</template>

<script>
import PotagerButton from 'UI/PotagerButton';
import PotagerLogo from 'UI/PotagerLogo';
import PotagerPicture from 'UI/PotagerPicture';

export default {
  components: {
    PotagerPicture,
    PotagerLogo,
    PotagerButton,
  },
};
</script>
