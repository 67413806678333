<template>
  <div class="bg-bianca min-h-screen">
    <potager-container
      size="xxsmall"
      class="app-page__container pt-14">
      <framboise-message size="large">
        <template #title>
          Coucou, moi c’est Framboise&nbsp;👋
        </template>

        Découvrez ma boutique en ligne de fruits et légumes en panier ou à l’unité, avec ou sans abonnement.
      </framboise-message>

      <div class="flex justify-evenly bp1280:justify-between mb-10">
        <potager-app-button
          class="inline-block flex justify-center bp1280:justify-start app-page__app-button"
          store="apple"
          theme="stroke" />

        <potager-app-button
          class="inline-block flex justify-center bp1280:justify-end app-page__app-button"
          store="google"
          theme="stroke" />
      </div>

      <div class="flex">
        <potager-picture
          src="phone-dashboard.png"
          class="w-full mx-auto" />
      </div>
    </potager-container>
  </div>
</template>

<script>
import PotagerContainer from 'UI/PotagerContainer';
import PotagerAppButton from 'UI/PotagerAppButton';
import PotagerPicture from 'UI/PotagerPicture';

import FramboiseMessage from 'Components/framboise/FramboiseMessage';

import ToolsMixin from 'Mixins/ToolsMixin';

export default {
  name: 'AppPage',

  components: {
    PotagerPicture, PotagerAppButton, FramboiseMessage, PotagerContainer,
  },

  mixins: [
    ToolsMixin,
  ],

  head() {
    return this.generateMetaIfPanelClosed({
      title: 'Bienvenue chez Potager City !',
      description: 'Découvrez ma boutique en ligne de fruits et légumes en panier ou à l’unité, avec ou sans abonnement.',
    });
  },
};
</script>

<style scoped lang="scss">
.app-page {
  &__container {
    min-width: calc(320vw / 1440 * 100); // calc to be 320px until 1440px screen and then scale up based on screen size
  }
}
</style>
