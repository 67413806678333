// If you browse the site while a new build has been deployed,
// you get a "Loading <> chunk <> failed" error.
// In this case, we force the refresh of the page.

export default function (error) {
  const pattern = /Loading chunk (\d)+ failed/g;
  const isChunkLoadFailed = error?.message?.match(pattern);
  if (isChunkLoadFailed) {
    window.location.reload();
  }
}
