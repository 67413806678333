<template>
  <potager-modal>
    <template #body>
      Depuis l’annulation de votre commande,
      {{
        isPlural
          ? 'plusieurs paniers se sont épuisés, victimes de leur succès.'
          : 'un panier s’est épuisé, victime de son succès.'
      }}
      En poursuivant cette étape, vous validez la reprise de votre commande sans
      {{ isPlural ? 'les paniers épuisés.' : 'le panier épuisé.' }}

      <div class="modal-container__out-of-stock-list">
        <p class="modal-container__out-of-stock-list__subtitle">
          {{ isPlural ? 'PANIERS ÉPUISÉS QUI SERONT SUPPRIMÉS' : 'PANIER ÉPUISÉ QUI SERA SUPPRIMÉ' }}
          DE VOTRE COMMANDE :
        </p>
        <p
          v-for="(product, index) in products"
          :key="index"
          class="modal-container__out-of-stock-list__box">
          <span class="modal-container__out-of-stock-list__box__name">{{ product.boxName }}</span>
          <span class="modal-container__out-of-stock-list__box__price">-{{ formatPrice(product.price) }}</span>
        </p>
      </div>
    </template>

    <template #footer>
      <potager-button
        :full-width="true"
        :is-loading="$wait.is([
          `RESUME_ORDER_ACTION_${orderId}`,
          'RESUME_ORDER_ACTION',
        ])"
        label="Valider la reprise"
        @onClick="onOk" />
    </template>
  </potager-modal>
</template>

<script>
import { formatPrice } from 'Classes/utils/PricesUtils';

import PotagerModal from 'UI/PotagerModal';
import PotagerButton from 'UI/PotagerButton';

export default {

  components: {
    PotagerModal,
    PotagerButton,
  },

  props: {
    products: {
      type: Array,
      required: false,
      default: () => [],
    },
    orderId: {
      type: Number,
      required: true,
      default: 0,
    },
  },

  computed: {
    isPlural() {
      return this.products.length > 1;
    },
  },

  methods: {
    formatPrice,
    onOk() {
      this.$store.dispatch('user/RESUME_ORDER_ACTION', {
        id: this.orderId,
        simulation: false,
      })
        .finally(() => {
          this.$modal.close();
        });
    },
  },

};
</script>
