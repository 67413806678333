import {
  addDays,
  fromUnixTime,
  getDay,
  getUnixTime,
  isDate,
  setDay,
  startOfWeek,
  startOfYear
} from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { timeZone } from 'Classes/Constants';

/**
 * Format a timestamp or Date object to a specified format string in a given time zone.
 *
 * @param {number|Date} tms - The timestamp (in seconds) or Date object to format.
 * @param {string} formatString - The format string to use for formatting the date.
 * @returns {string|undefined} The formatted date string, or undefined if the input is invalid.
 */
export const tmsToFormat = (tms, formatString) => {
  if (!tms) return undefined;
  const date = tms instanceof Date ? tms : fromUnixTime(tms);
  return formatInTimeZone(date, timeZone, formatString);
};

/**
 * Format a time slot object to a specified format string.
 *
 * @param {Object} timeSlot - The time slot object containing startHour, endHour, and date.
 * @param {Object} [options] - Formatting options.
 * @param {boolean} [options.condensed=false] - Whether to use a condensed date format.
 * @param {boolean} [options.showHours=true] - Whether to include hours in the formatted string.
 * @returns {string|null} The formatted time slot string, or null if the input is invalid.
 */
export const formatTimeSlot = (timeSlot, options = {
  condensed: false,
  showHours: true
}) => {
  if (timeSlot?.startHour && timeSlot?.endHour) {
    const {
      condensed,
      showHours
    } = options;
    const dateFormat = condensed ? 'eee dd MMM' : 'iiii dd MMMM';
    const dateFormatted = tmsToFormat(timeSlot.date, dateFormat);
    if (condensed) return dateFormatted;
    if (showHours) {
      const startHourFormatted = tmsToFormat(timeSlot.startHour, 'HH\'h\'mm');
      const endHourFormatted = tmsToFormat(timeSlot.endHour, 'HH\'h\'mm');
      return `${dateFormatted} entre ${startHourFormatted} et ${endHourFormatted}`;
    }
    return dateFormatted;
  }
  return null;
};

/**
 * Format a group delivery day (GDD) object to a specified format string.
 *
 * @param {Object} gdd - The group delivery day object containing dayLabel, startHour, and endHour.
 * @param {Object} [options] - Formatting options.
 * @param {boolean} [options.condensed=false] - Whether to use a condensed date format.
 * @returns {string} The formatted GDD string.
 */
export const formatGDD = (gdd, options = { condensed: false }) => {
  const dayLabel = gdd.dayLabel?.toLowerCase();
  if (options.condensed) return `les ${dayLabel}s`;

  const startHour = tmsToFormat(gdd.startHour, 'HH\'h\'mm');
  const endHour = tmsToFormat(gdd.endHour, 'HH\'h\'mm');
  return `tous les ${dayLabel}s, entre ${startHour} et ${endHour}`;
};

/**
 * Get the date of the next Thursday.
 *
 * @returns {Date} The date of the next Thursday.
 */
export const getNextThursday = () => {
  const dayINeed = 4; // Thursday
  const today = new Date();
  const currentDay = getDay(today);

  return setDay(today, currentDay <= dayINeed ? dayINeed : dayINeed + 7);
};

/**
 * Check if a given date has passed.
 *
 * @param {number|Date} date - The date to check, either as a Unix timestamp (in seconds) or a Date object.
 * @returns {boolean} True if the date has passed, false otherwise.
 */
export const isDatePassed = (date) => {
  const now = new Date().setHours(0, 0, 0, 0);
  const d = new Date(date * 1000).setHours(0, 0, 0, 0);
  return d < now;
};

/**
 * Check if a given date is in the next potager week.
 *
 * @param {number|Date} date - The date to check, either as a Unix timestamp (in seconds) or a Date object.
 * @returns {boolean} True if the date is in the next potager week, false otherwise.
 */
export const isNextPotagerWeek = (date) => {
  const nextThursdayTimestamp = getUnixTime(getNextThursday());
  const dateTimestamp = getUnixTime(isDate(date) ? date : fromUnixTime(date));
  return dateTimestamp > nextThursdayTimestamp;
};

/**
 * Check if a given date is in the next week.
 *
 * @param {number|Date} date - The date to check, either as a Unix timestamp (in seconds) or a Date object.
 * @returns {boolean} True if the date is in the next week, false otherwise.
 */
export const isNextWeek = (date) => {
  const nextMondayTimestamp = getUnixTime(addDays(startOfWeek(new Date()), 7));
  const dateTimestamp = isDate(date) ? getUnixTime(date) : date;
  return dateTimestamp > nextMondayTimestamp;
};

/**
 * Get the minimum delivery date for the current week.
 *
 * @param {Object} gdd - The group delivery day object.
 * @param {Object} gdd.currentWeekTheoricalTimeSlot - The theoretical time slot for the current week.
 * @param {number} gdd.currentWeekTheoricalTimeSlot.date - The Unix timestamp of the theoretical time slot date.
 * @returns {string|null} The formatted minimum delivery date, or null if the date is not defined.
 */
export const getMinimumDeliveryDate = (gdd) => {
  if (!gdd?.currentWeekTheoricalTimeSlot?.date) return null;

  const TDate = fromUnixTime(gdd.currentWeekTheoricalTimeSlot.date);
  const daysUntilSaturday = (5 - getDay(TDate));
  return addDays(TDate, daysUntilSaturday);
};

/**
 * Get the maximum validity date for a given group delivery day (GDD).
 *
 * @param {Object} gdd - The group delivery day object.
 * @param {number} gdd.year - The year of the GDD.
 * @param {number} gdd.week - The week number of the GDD.
 * @returns {string} The formatted maximum validity date in 'yyyy-MM-dd' format.
 */
export const getMaximumValidityDate = (gdd) => {
  const startOfYearDate = startOfYear(new Date(gdd.year, 0, 1));
  const startOfWeekDate = startOfWeek(startOfYearDate);
  return addDays(startOfWeekDate, (gdd.week - 1) * 7 + 1);
};

/**
 * Add a specified number of days to a Unix timestamp.
 *
 * @param {number} timestamp - The Unix timestamp (in seconds) to which days will be added.
 * @param {number} days - The number of days to add to the timestamp.
 * @returns {number} The new Unix timestamp (in seconds) after adding the specified number of days.
 */
export const addDaysToTimestamp = (timestamp, days) => {
  const date = fromUnixTime(timestamp);
  const newDate = addDays(date, days);
  return getUnixTime(newDate);
};
