<template>
  <div class="mt-6 pt-6 border-solid border-0 border-t border-white-rock">
    <p class="text-xs.5 bp768:text-left max-w-1280 mx-auto mb-6 italic">
      En validant cette étape, vous acceptez l’intégralité de
      <potager-link
        :to="{ name: 'cgv' }"
        class="underline"
        target="_blank">
        nos conditions générales de vente
      </potager-link>
      et notre politique de gestion de vos informations personnelles.
    </p>

    <potager-button
      :is-disabled="isDisabled"
      :is-loading="isLoading"
      class="potager-button--tunnel"
      lowercase
      theme="go-green"
      @onClick="$emit('onSubmit')">
      Payer
    </potager-button>
  </div>
</template>

<script>
import PotagerButton from 'UI/PotagerButton';

export default {

  components: {
    PotagerButton,
  },

  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ['onSubmit'],
};
</script>
