<template>
  <div class="dlp-suggest">
    <component
      :is="isInAppWebView ? 'potager-button' : 'a'"
      :href="isInAppWebView ? undefined : 'https://tally.so/r/mRxkrP'"
      class="whitespace-nowrap underline router-link potager-button--reset"
      target="_blank"
      theme="reset"
      @onClick="onClick">
      Suggérer un point de livraison
    </component>
  </div>
</template>

<script>

import MobileAppMixin from 'Mixins/MobileAppMixin';
import PotagerButton from 'UI/PotagerButton';

export default {
  name: 'DlpSuggest',

  components: {
    PotagerButton,
  },

  mixins: [
    MobileAppMixin,
  ],

  methods: {
    onClick() {
      if (this.isInAppWebView) {
        this.postMessage({
          event: 'openSurvey',
        });
      }
    },
  },
};
</script>
