<template>
  <panel
    :back-btn="Survey.canGoToPreviousQuestion()"
    :back-callback="() => Survey.goToPreviousQuestion()"
    :back-route=" Survey.canGoToPreviousQuestion() ? undefined : { name: 'mon-compte_orders_detail', params: { orderId } }"
    :title="getTitle">
    <survey-form :survey="Survey" />

    <template #footer>
      <span class="flex flex-col items-center">
        <potager-button
          v-gtm="getSavedLabels"
          :is-loading="isLoading"
          full-width
          @onClick="Survey.submit({ orderId })">
          Annuler ma commande
        </potager-button>

        <potager-button
          :to="{ name: 'dashboard' }"
          size="small"
          theme="link">
          J'ai changé d'avis
        </potager-button>
      </span>
    </template>
  </panel>
</template>

<script>
import { mapGetters } from 'vuex';
import Panel from 'Components/panel/Panel';
import SurveyForm from 'Components/surveys/SurveyForm';
import PotagerButton from 'UI/PotagerButton';

import Survey from 'Classes/surveys/CancelOrderSurvey';
import { CANCEL_ORDER_ACTION } from 'Stores/types/userActionsTypes';
import { getRouteParams } from 'Classes/utils/RouteUtils';

export default {

  components: {
    Panel,
    PotagerButton,
    SurveyForm,
  },

  data: () => ({
    Survey: null,
  }),

  watch: {
    isReady: {
      immediate: true,
      handler() {
        this.$nextTick(() => {
          this.Survey.init();
        });
      },
    },
  },

  computed: {
    ...mapGetters('user', [
      'getOrderById',
    ]),
    isReady() {
      return this.order && this.Survey;
    },
    orderId() {
      return getRouteParams(this.$potagerRoute)?.orderId;
    },
    order() {
      return this.getOrderById(this.orderId);
    },
    isLoading() {
      return this.$wait.is(CANCEL_ORDER_ACTION);
    },
    getTitle() {
      return this.order ? `Commande n°${this.order?.number}` : 'Annuler ma commande';
    },
    getSavedLabels() {
      return this.Survey.savedChoices
        .map((item) => item.choices
          .map((choice) => choice.label));
    },
  },

  created() {
    this.Survey = Survey(this);
  }
};
</script>
