<template>
  <div>

    <heading
      :modifiers="['smaller', 'text-black']"
      :background="null">
      <template #title>
        Politique de protection des données personnelles
      </template>
    </heading>

    <blog-body>
      <blog-section>
        <cms>
          <h2>1. Objet</h2>
          <p>
            Lors de l’utilisation des Services de Potager City (les « <b>Services</b> ») et des sites accessibles aux
            adresses Potagercity.fr ; Potagercity.com ; Potagercity.net ; Potagercity.org ; Potager-city.fr ;
            Potager-city.com ; Potager-city.net ; et Potager-city.org (le(s) « <b>Site(s)</b> »), Potager City est
            amenée à
            collecter des données personnelles des Utilisateurs et des Membres du Site (les « <b>Données
              Personnelles</b> »).
          </p>
          <p>
            Nous nous sommes engagés dans une politique d’adoption et de respect de normes exigeantes en matière de
            déontologie et d’éthique de la relation client, et notamment de protection des données personnelles.
          </p>
          <p>
            À ce titre, nous traitons vos données personnelles dans le respect des principes fixés par la réglementation
            applicable à la protection des données personnelles, et notamment le Règlement (UE) 2016/679 du Parlement
            européen et du Conseil du 27 avril 2016 relatif à la protection des personnes physiques à l'égard du
            traitement des données personnelles et à la libre circulation de ces données personnelles, et la Loi n°
            78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés, telle que modifiée
            (ci-après ensemble la « <b>Règlementation Données Personnelles</b> »).
          </p>
          <p>
            Conformément à la Règlementation Données Personnelles, le terme « donnée personnelle » désigne, dans le
            cadre de la présente politique, toute information vous concernant et permettant de vous identifier
            directement ou indirectement en qualité de personne physique.
          </p>
          <p>
            Cette politique de protection des données personnelles pourra être amenée à évoluer en fonction du contexte
            légal et réglementaire applicable ou de l’évolution de notre activité, et notamment des produits ou services
            que nous vous proposons.
          </p>

          <h2>2. Définitions</h2>
          <p>
            Les termes « données à caractère personnel » (« <b>Données Personnelles</b> »), « <b>Traiter/Traitement</b>
            », « <b>Responsable du Traitement</b> », « <b>Sous-Traitant</b> », « <b>Destinataire(s)</b> », « <b>Consentement</b>
            », ont la même signification que celle énoncée à l’Article 4 du RGPD.
          </p>
          <p>
            « <b>Membre(s)</b> » désigne toute personne physique disposant d’un Compte Client lui permettant d’accéder à
            des fonctionnalités propres au Site ou Services de Potager City ;
          </p>
          <p>
            « <b>Utilisateur(s)</b> » désigne toute personne physique accédant au(x) Site(s) et Services sans avoir créé
            de Compte Client, et n’utilisant que certaines fonctionnalités du/des Site(s) ou Services de Potager City ;
          </p>
          <p>
            « <b>Tiers</b> » désigne toute personne physique ou morale tiers à Potager City, à un Utilisateur ou à un
            Membre ;
          </p>
          <p>
            « <b>Compte Client</b> » désigne tout compte client créé conformément à l’Article « B. Inscription » des
            Conditions générales d’utilisation (« <b>CGU</b> ») et permettant à un Membre d’accéder à des
            fonctionnalités propres ;
          </p>
          <p>
            « <b>Services</b> » désignent tout ou partie des services proposés par Potager City sur ses Sites ;
          </p>
          <p>
            « <b>Site(s)</b> » désigne le(s) site(s) internet édité(s) par Potager City et les Services disponibles aux
            adresses Potagercity.fr ; Potagercity.com ; Potagercity.net ; Potagercity.org ; Potager-city.fr ;
            Potager-city.com ; Potager-city.net ; et Potager-city.org, ainsi que l’application mobile Potager City
            disponible sur iOS et Android.
          </p>

          <h2>
            3. Finalités et Responsable de Traitement des Données Personnelles
          </h2>
          <p>
            Nous attachons la plus grande importance au respect de votre vie privée et à la protection de vos Données
            Personnelles. C’est pourquoi Potager City traite vos Données Personnelles, dans le respect de la
            Règlementation Applicable, à des fins :
          </p>
          <ul>
            <li>
              de prise de contact avec les Utilisateurs et de gestion de la relation avec les Utilisateurs et les
              Membres ;
            </li>
            <li>
              d’informer les Utilisateurs et les Membres des actualités évènements, et autres activités en relation avec
              l’objet social de Potager City ;
            </li>
            <li>
              d’offrir des Services en relation avec les activités de Potager City et du secteur qu’elle représente ;
            </li>
            <li>
              de consulter et conserver les informations relatives à la navigation sur les Sites susceptibles d’être
              enregistrées dans des fichiers « cookies » (définis à l’Article 2 ci-dessous).
            </li>
          </ul>
          <p>
            Pour ces finalités uniquement, le Responsable de Traitement des Données Personnelles est Potager City,
            société par actions simplifiée, enregistrée au RCS de Lyon sous le numéro 499 600 781, dont le siège social
            est situé Espace Cardinal Workside, 60 quai Perrache, 69002 LYON.
          </p>

          <h2>
            4. Principes applicables au traitement de vos données personnelles
          </h2>
          <p>
            Dans le cadre de la collecte et du traitement de vos données personnelles, nous nous attachons au respect
            des principes suivants :
          </p>
          <p>
            <b>Licéité&nbsp;:</b> vos données personnelles sont collectées pour des finalités déterminées, explicites et
            légitimes, et sur le fondement d’une base légale appropriée ;
          </p>
          <p>
            <b>Transparence&nbsp;:</b> vous êtes informé de chaque traitement que Nous mettons en œuvre et des
            caractéristiques de ces traitements au moyen de notices d’information, aucun traitement de vos données
            personnelles n’étant mis en œuvre à votre insu ;
          </p>
          <p>
            <b>Minimisation&nbsp;:</b> Nous Nous engageons à ne collecter et traiter que les données personnelles vous
            concernant qui sont strictement nécessaires à l’objectif que Nous poursuivons et à les mettre à jour
            régulièrement ;
          </p>
          <p>
            <b>Protection des données dès la conception et par défaut&nbsp;:</b> lors de l'élaboration, de la
            conception, de la configuration et de l'utilisation d'applications, de services et de produits qui reposent
            sur le traitement de données personnelles, Nous prenons en compte votre droit à la protection des données
            personnelles et Nous Nous assurons auprès de nos partenaires qu’ils répondent aux prescriptions légales et
            permettent d’assurer effectivement la protection des données personnelles qui seront traitées ;
          </p>
          <p>
            <b>Sécurité des données personnelles&nbsp;:</b> Nous avons mis en place des mesures techniques et
            organisationnelles, adaptées en fonction du degré de sensibilité des données personnelles collectées, en vue
            d’assurer l’intégrité et la confidentialité de vos données personnelles et de les protéger contre toute
            intrusion malveillante, perte, altération ou divulgation à des tiers non autorisés. En particulier, Nous
            recourons à des techniques de chiffrement et/ou de pseudonymisation de vos données personnelles dès que cela
            est possible, utile, ou nécessaire ;
          </p>
          <p>
            <b>Engagements de nos prestataires et partenaires&nbsp;:</b> Nous choisissons nos sous-traitants,
            prestataires et partenaires avec soin et leurs imposons : (a) d’assurer un niveau de protection de vos
            données personnelles équivalent au nôtre, (b) d’utiliser vos données personnelles dans la seule mesure
            nécessaire à la fourniture des services que nous leurs avons confiés, (c) de respecter la Règlementation
            Données Personnelles.
          </p>

          <h2>
            5. Quelles sont les Données Personnelles collectées, pour quelles finalités&nbsp;?
          </h2>
          <p>
            Lors de la collecte des Données Personnelles, l’Utilisateur ou le Membre sera informé si certaines Données
            Personnelles doivent être obligatoirement renseignées ou si elles sont facultatives. A défaut de
            renseignement des Données Personnelles qui doivent être obligatoirement collectées, l’accès à certaines
            fonctionnalités des Services sera impossible. Potager City pourra être amenée à collecter les catégories de
            Données Personnelles suivantes :
          </p>
          <ul>
            <li>
              <b>Concernant les Utilisateurs avec une base légale d’Exécution des mesures précontractuelles prises à
                votre demande et/ou exécution du contrat :</b>
              <ul>
                <li>
                  Les nom, prénom, adresse de courrier électronique, région, sujet de la demande en vue <b>d’entrer en
                    contact avec un interlocuteur</b> du service client de Potager City. La déclaration de Consentement
                  passe par la transmission volontaire de ces informations de l’Utilisateur dans chacun des champs prévu
                  à cet effet du formulaire de contact dans l’onglet « <b>Nous contacter</b> ».
                </li>
                <li>
                  Toute autre information constituant des Données Personnelles au sens de la Réglementation Applicable
                  renseignée volontairement dans la partie « <b>Message</b> » et/ou contenue dans un fichier téléchargé
                  dans la partie « <b>Pièces jointes</b> » du formulaire de contact et par tout autre moyen approprié.
                </li>
              </ul>
            </li>
            <li>
              <b>Concernant les Utilisateurs avec une base légale de consentement :</b>
              <ul>
                <li>
                  L’adresse de courrier électronique en vue de recevoir la newsletter de Potager City relative aux
                  compositions des nouveaux paniers et aux bons plans, tarifs préférentiels ou de codes de réduction.
                  La déclaration de Consentement passe par la transmission volontaire de ces informations de
                  l’Utilisateur dans le formulaire mis à sa disposition sur le Site. L’Utilisateur a la possibilité, à
                  tout moment, de refuser l'envoi de newsletters par le lien correspondant dans la newsletter ou par
                  l'envoi d'un message au service client. L’adresse de courrier électronique sera alors effacée de la
                  liste de distribution de Potager City pour des fins de prospection commerciale.
                </li>
              </ul>
            </li>
            <li>
              <b>Concernant les Utilisateurs avec une base légale d’intérêt légitime pour les données permettant la
                reconnaissance de l’utilisateur sur le site de Potager City ou une base légale de consentement pour les
                données utilisées à des fins de publicité personnalisée et/ou mesure d’audience :</b>
              <ul>
                <li>
                  Les données de navigation telles que détaillées sur la
                  <potager-link :to="{ name: 'cookies-page' }">page Politique sur les cookies</potager-link>
                  .
                </li>
              </ul>
            </li>
            <li>
              <b>Concernant les Membres avec une base légale d’Exécution des mesures précontractuelles prises à votre
                demande et/ou exécution du contrat :</b>
              <ul>
                <li>
                  Les données d’identification recueillies lors de la création d’un Compte Client et notamment, nom,
                  prénom, adresse de courrier électronique, numéro de téléphone, code parrain et mot de passe encrypté.
                </li>
                <li>
                  Les informations constituant des Données Personnelles au sens de la Réglementation Applicable
                  volontairement renseignées par le Membre dans le formulaire « <b>Informations personnelles</b> »,
                  notamment la date de naissance, et le numéro de téléphone ; ou encore dans les formulaires « Codes promo et
                  titres restaurant » dans lesquels le Membre renseigne volontairement ses codes promo et numéros de
                  titres restaurant.
                </li>
                <li>
                  Les données de paiement (numéro de carte bancaire, nom et prénom du titulaire de la carte bancaire,
                  date d’expiration et numéro CVC) renseignées volontairement par le Membre directement dans le Compte
                  Client à la rubrique « <b>Carte de paiement</b> » ou au moment de la souscription de l’abonnement,
                  afin que le Membre paye ses commandes chaque semaine, et le cas échéant se faire rembourser.
                </li>
                <li>
                  Les nom, prénom, adresse de courrier électronique, région, sujet de la demande en vue d’entrer en
                  contact avec un interlocuteur du service client de Potager City. La déclaration de Consentement passe
                  par la transmission volontaire de ces informations de l’Utilisateur dans chacun des champs prévu à cet
                  effet du formulaire de contact dans l’onglet « Nous Contacter ».
                </li>
                <li>
                  Toute autre information constituant des Données Personnelles au sens de la Réglementation Applicable
                  renseignée volontairement dans la partie « Message » et/ou contenue dans un fichier téléchargé dans la
                  partie « Pièces jointes » du formulaire de contact et par tout autre moyen approprié. Ces Données
                  personnelles peuvent également être recueillies lorsque le Membre répond aux questionnaires de
                  satisfaction sur les Services qui lui sont ponctuellement adressés, afin que Potager City puisse
                  améliorer ses Services.
                </li>
                <li>
                  L’historique des commandes, c’est-à-dire le détail de la composition des paniers commandés par le
                  Membre, disponible dans la rubrique « <b>Commandes</b> », afin d’une part que le Membre puisse
                  consulter toutes ses commandes depuis son compte client et puisse notamment télécharger une facture de
                  ses commandes respectives au format PDF ; d’autre part, pour que le service client puisse vérifier
                  avec le Membre l’exactitude des informations et trouver une solution plus rapidement ; et enfin afin
                  d’accélérer et de faciliter le remboursement des Membres le cas échéant.
                </li>
                <li>
                  Le numéro de client généré au moment de la création d’un Compte Client et la date de création du
                  Compte Client disponibles dans la rubrique « <b>Informations personnelles</b> » du Compte Client.
                </li>
              </ul>
            </li>
            <li>
              <b>Concernant les Membres avec une base légale de consentement :</b>
              <ul>
                <li>
                  Les nom, prénom, adresse de courrier électronique et numéro de téléphone en vue de recevoir <b>la
                    newsletter</b> de Potager City, par courrier électronique et/ou SMS, relative aux compositions des
                  nouveaux paniers et aux bons plans, tarifs préférentiels ou de codes de réduction. La déclaration de
                  Consentement passe par la transmission volontaire de ces informations du Membre dans chacun des champs
                  prévus à cet effet au moment de la création du Compte Client. Le Membre a la possibilité, à tout
                  moment, de refuser l'envoi de newsletters
                  <ul>
                    <li>
                      par courrier électronique : par le lien correspondant dans la newsletter ou par l'envoi d'un
                      message au service client. L’adresse de courrier électronique sera alors effacée de la liste de
                      distribution de Potager City pour des fins de prospection commerciale.
                    </li>
                    <li>
                      par SMS : en envoyant « SMSSTOP » au numéro de téléphone cité dans le SMS que le Membre a reçu. Le
                      numéro de téléphone du Membre ne sera plus utilisé pour l’envoi de SMS commerciaux de la part de
                      Potager City
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              <b>Concernant les Membres et avec une base légale d’intérêt légitime pour les données permettant la
                reconnaissance de l’utilisateur sur le site de Potager City ou une base légale de consentement pour les
                données utilisées à des fins de publicité personnalisée et/ou mesure d’audience :</b>
              <ul>
                <li>
                  Les données de navigation telles que détaillées sur la
                  <potager-link :to="{ name: 'cookies-page' }">page Politique sur les cookies</potager-link>
                  .
                </li>
              </ul>
            </li>
          </ul>

          <h2>
            6. Les destinataires des Données Personnelles
          </h2>
          <p>
            La base de Données Personnelles constituée à l’occasion de votre utilisation des Services est strictement
            confidentielle. Potager City s’engage à prendre toutes les précautions utiles, mesures organisationnelles et
            techniques appropriées pour préserver la sécurité, l’intégrité et la confidentialité des Données
            Personnelles, et notamment empêcher qu’elles soient déformées, endommagées ou que des Tiers non autorisés y
            aient accès.
          </p>
          <h3>
            a. Données transférées aux autorités et/ou organismes publics
          </h3>
          <p>
            Conformément à la Réglementation Applicable, les Données Personnelles peuvent être transmises aux autorités
            compétentes sur requête et notamment aux organismes publics, exclusivement pour répondre aux obligations
            légales, les auxiliaires de justice, les officiers ministériels et les organismes chargés d’effectuer le
            recouvrement de créances.
          </p>
          <h3>
            b. Données accessibles à des Tiers
          </h3>
          <p>
            Les Données Personnelles pourront être utilisées par Potager City, ses Sous-Traitants, les co-Responsables
            du Traitement ses affiliés et/ou, le cas échéant, par ses partenaires, pour les finalités décrites aux
            Articles 1.3 et 1.4 ci-dessus.
          </p>
          <ul>
            <li>
              Le personnel habilité de Potager City de par leurs fonctions, les services chargés du contrôle
              (commissaire aux comptes notamment) et les Sous-Traitants de Potager City auront accès aux Données
              Personnelles collectées dans le cadre de l’utilisation des Services ;
            </li>
            <li>
              Les partenaires de Potager City qui l’assistent dans la fourniture des Services ;
            </li>
            <li>
              Réseaux sociaux : si l'Utilisateur ou le Membre possède un compte sur des sites des réseaux sociaux et
              qu'il accède aux Services, ceux-ci peuvent inclure des plugiciels (plugins) tels que des boutons « J’aime
              » ou des boutons de partage ou de redirection, permettant de publier des contenus des Services sur les
              réseaux sociaux. Le cas échéant, les réseaux sociaux concernés peuvent recevoir des informations relatives
              à l’utilisation des Services par l’Utilisateur ou le Membre. Le réseau social est seul Responsable des
              Traitements ultérieurs des Données Personnelles de l’Utilisateur et du Membre collectées depuis le Site
              par le réseau social via le plugin, et de tels Traitements sont soumis aux conditions d’utilisation de ces
              réseaux sociaux auxquels l’Utilisateur et le Membre ont souscrit en créant un compte.
            </li>
          </ul>
          <h3>
            c. Transferts hors de l’Union européenne
          </h3>
          <p>
            Les Données Personnelles de l’Utilisateur, et du Membre peuvent être Traitées en dehors de l’Union
            européenne, y compris via un accès à distance. Potager City s’engage à ne réaliser aucun transfert des
            Données Personnelles hors de l’Union européenne sans mettre en œuvre les garanties appropriées conformément
            à la Réglementation Applicable.
          </p>

          <h2>
            7. Les droits de l’Utilisateur et du Membre
          </h2>
          <p>
            Sous réserve d’une demande expresse et de justifier de leur identité auprès de Potager City, l’Utilisateur
            et le Membre disposent d’un droit d’accès, de rectification et d'opposition sur leurs Données Personnelles,
            ainsi qu’un droit à l’effacement, dans les conditions de la Règlementation Applicable, en s’adressant à
            Potager City à l’adresse postale ou électronique mentionnées à l’Article 1.9 ci-dessous.
          </p>
          <p>
            En cas d’exercice du droit d’opposition, Potager City cessera le Traitement des Données Personnelles, sauf
            en cas de motif(s) légitime(s) et impérieux pour le Traitement, ou pour assurer la constatation, l’exercice
            ou la défense de ses droits en justice, conformément à la Règlementation Applicable. Le cas échéant, Potager
            City informera l’Utilisateur ou le Membre des motifs pour lesquels les droits qu’il exerce ne sauraient être
            satisfaits en tout ou partie.
          </p>
          <p>
            Sous réserve d’une demande expresse et de justifier de leur identité auprès de Potager City, l’Utilisateur
            et le Membre disposent également du droit de récupérer leurs Données Personnelles dans un format structuré,
            couramment utilisé et lisible par machine, afin de les transmettre à un autre Responsable du Traitement.
          </p>
          <p>
            L’Utilisateur et le Membre ont la faculté de fournir à Potager City des directives relatives au sort de
            leurs Données Personnelles après leur décès.
          </p>
          <p>
            Le cas échéant, Potager City informera l’Utilisateur ou le Membre des motifs pour lesquels les droits qu’il
            exerce ne sauraient être satisfaits en tout ou en partie.
          </p>

          <h2>
            8. La durée de conservation des Données Personnelles
          </h2>
          <p>
            Les Données Personnelles des Utilisateurs et des Membres ne sont pas conservées au-delà de la durée
            strictement nécessaire aux finalités poursuivies telles qu'énoncées à l’Article 3 ci-dessus, conformément à
            la Règlementation Applicable.
          </p>
          <p>
            Notamment :
          </p>
          <ul>
            <li>
              Les Données Personnelles recueillies pour gérer les demandes d’opposition au Traitement sont stockées
              pendant une durée maximale de 5 (cinq) ans à compter de la date d’exercice du droit d’opposition ;
            </li>
            <li>
              Les Données Personnelles Traitées pour gérer les demandes d’accès et de rectification des Données
              Personnelles sont stockées pendant une durée maximale de 5 (cinq) ans à compter de la dernière interaction
              de l’Utilisateur ou du Membre avec le service juridique ou le service client ;
            </li>
            <li>
              Les Données Personnelles récoltées à des fins d’envoi d’informations ou d’offres (notamment par l’envoi de
              newsletters) sont stockées pendant une durée maximale de 3 (trois) ans à compter de la dernière
              interaction entre l’Utilisateur ou du Membre et Potager City ;
            </li>
            <li>
              Les Données Personnelles relatives à la gestion des interactions avec des interlocuteurs de Potager City
              (notamment par la rubrique « Nous Contacter ») sont conservées pendant une durée maximale de 5 (cinq) ans
              à compter de la dernière interaction initiée par l’Utilisateur ou le Membre.
            </li>
            <li>
              Les Données Personnelles relatives à la prise en charge et l’exécution des Services sont, conformément aux
              obligations légales incombant à Potager City, conservées pendant une durée maximale de 10 (dix) ans
              révolus à compter de la dernière année comptable ou administrative au cours de laquelle le Service a été
              fourni au Membre.
            </li>
          </ul>
          <p>
            Les Données Personnelles pourront être archivées au-delà des délais décrits ci-dessus dans la mesure où ils
            sont nécessaires pour les besoins de la recherche, la constatation, et de la poursuite des infractions
            pénales dans le seul but de permettre, en tant que de besoin, la mise à disposition de ces Données
            Personnelles à l’autorité judiciaire, ou encore pour d’autres obligations de conservation, notamment à des
            fins comptables ou fiscales. L'archivage implique que ces Données Personnelles feront l’objet de
            restrictions d’accès et seront conservées sur un support autonome et sécurisé.
          </p>

          <h2>
            9. Sécurité
          </h2>
          <p>
            Conformément à la Réglementation Applicable, Potager City Traite les Données Personnelles en toute sécurité
            et confidentialité.
          </p>
          <p>
            En particulier, Potager City met en œuvre toutes les mesures techniques et organisationnelles nécessaires
            pour garantir la sécurité et la confidentialité des Données Personnelles collectées et Traitées, et
            notamment empêcher qu'elles soient déformées, endommagées ou communiquées à des Tiers non autorisés, en
            assurant un niveau de sécurité adapté aux risques liés au Traitement et à la nature des Données Personnelles
            à protéger, eu égard au niveau technologique et au coût de mise en œuvre.
          </p>
          <p>
            Potager City ne peut toutefois garantir la confidentialité des Données Personnelles rendues publiques par
            l’Utilisateur et le Membre dans les parties publiques du Site et des Services.
          </p>
          <p>
            Le Site est susceptible d’inclure des liens vers des sites Internet ou des sources externes. L’Utilisateur
            et le Membre reconnaissent que la Politique ne s’applique que pour les Services et l’utilisation des Sites,
            et ne couvre en aucun cas les informations collectées et/ou Traitées sur les sites ou sources externes, dont
            le lien figure sur le Site. En conséquence, Potager City ne saurait être Responsable des pratiques de ces
            sites ou sources externes en matière de collecte et de Traitement de Données Personnelles, qui sont régies,
            le cas échéant, par les politiques de données personnelles propre à chacun de ces sites ou sources externes.
          </p>

          <h2>
            10. Contact et réclamations
          </h2>
          <p>
            Pour toute demande ou en cas de différend entre Potager City et l’Utilisateur et/ou le Membre, concernant le
            Traitement de leurs Données Personnelles, ces derniers peuvent adresser leur demande ou leur réclamation au
            service client de Potager City en le contactant&nbsp;:
          </p>
          <ul>
            <li>
              Par email à <a
                href="mailto:droitsdespersonnes@potagercity.fr"
                target="_blank">
                droitsdespersonnes@potagercity.fr
              </a>
            </li>
            <li>
              Via notre formulaire de contact&nbsp;:
              <potager-link :to="{name:'contact'}">https://www.potagercity.fr/contact/</potager-link>
            </li>
            <li>
              Par courrier&nbsp;: Potager City - Droit sur les Données Personnelles - Lumen Cardinal Workside - 60&nbsp;Quai&nbsp;Perrache
              - 69002&nbsp;Lyon
            </li>
          </ul>
          <p>
            Potager City s’efforcera de trouver une solution satisfaisante pour assurer le respect de la Règlementation
            Applicable.
          </p>
          <p>
            En l’absence de réponse de Potager City ou si le différend persiste malgré la proposition de Potager City,
            l’Utilisateur et/ou le Membre ont la possibilité d’introduire une réclamation auprès de la CNIL ou de
            l’autorité de contrôle de l’État Membre de l’Union européenne au sein duquel l’Utilisateur ou le Membre
            réside habituellement et de mandater une association ou une organisation mentionnée au IV de l’article 43
            ter de la loi n°78-17 du 6 janvier 1978 modifiée.
          </p>
        </cms>
      </blog-section>
    </blog-body>
  </div>
</template>

<script>
import ToolsMixin from 'Mixins/ToolsMixin';

import Heading from 'Components/heading/Heading';
import BlogBody from 'Components/blog/BlogBody';
import BlogSection from 'Components/blog/BlogSection';
import Cms from 'Components/cms/Cms';

export default {

  mixins: [
    ToolsMixin,
  ],

  components: {
    Heading,
    BlogBody,
    BlogSection,
    Cms,
  },

  head() {
    return this.generateMetaIfPanelClosed({
      title: 'Confidentialité',
      description: 'Découvrez l\'ensemble de nos critères de confidentialité et de respect de vos données personnelles sur cette page.',
    });
  },
};
</script>
