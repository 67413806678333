import GtmMixin from 'Mixins/GtmMixin';
import PaymentMethodsMixin from 'Mixins/payments/PaymentMethodsMixin';

import { CANCEL_ORDER_ACTION, RESUME_ORDER_ACTION } from 'Stores/types/userActionsTypes';
import { POPIN_ERROR_EVENT } from 'Classes/Constants';

import ModalOrderResumeOutOfStock from 'Modals/ModalOrderResumeOutOfStock';
import { ABONNEMENT, ONESHOT } from 'Classes/OrderStatus';

import ModalInfo from 'Modals/ModalInfo';
import ModalDeleteOrder from 'Modals/ModalDeleteOrder';

export default {
  mixins: [
    PaymentMethodsMixin,
    GtmMixin,
  ],

  methods: {
    cancelOrder(orderId, success = null, errorCallBack = null) {
      this.$store.dispatch(`user/${CANCEL_ORDER_ACTION}`, {
        orderId,
      })
        .then((orders) => {
          if (success) {
            const orderCanceled = orders.find((o) => o.id === orderId);
            this.trackRefundTransaction(orderCanceled);

            if (typeof success === 'function') success(orderCanceled);
          }
        })
        .catch((errors) => {
          if (errorCallBack) errorCallBack(errors);
        });
    },
    deleteOrder(order) {
      if (order.canal === ONESHOT) {
        this.deleteOrderOneShot(order);
      } else if (order.canal === ABONNEMENT) {
        this.tryToDeleteOrderSubscription(order);
      }
    },
    deleteOrderOneShot({ id }) {
      this.$modal.open(ModalDeleteOrder, {
        title: 'Êtes-vous sûr ?',
        text: `Si vous avez payé cette commande avec
          des coupons, ils vous seront rendus. Si vous
          avez effectué un paiement par carte bancaire,
          vous serez remboursé sous forme de coupon.`,
        yesCallback: (callback) => {
          this.cancelOrder(id, () => {
            this.$modal.open(ModalInfo, {
              title: 'C\'est tout bon !',
              text: `L'annulation a bien été prise en compte. Si vous aviez payé cette commande
                avec des coupons, ils vous ont été rendus. Si vous aviez effectué un paiement par
                carte bancaire, vous avez été remboursé sous forme de coupon.`,
              closeBtnText: 'Ok',
              showCancel: true,
              cancelBtnText: 'Voir mes coupons',
              onCancelRedirect: { name: 'mon-compte_coupons' },
            });
            if (typeof callback === 'function') callback();
          }, () => {
            this.$modal.open(ModalInfo, {
              title: 'Oups !',
              text: `Nous avons rencontré une erreur lors de l'annulation de votre commande. Vous pouvez
          ré-essayer. Si cela ne fonctionne toujours pas, contactez le service client.`,
            });
          });
        },
        noCallback: (callback) => {
          if (typeof callback === 'function') callback();
        },
      });
    },
    deleteOrderSubscription(order, config = {}) {
      const text = config.text || 'Êtes-vous sûr de vouloir annuler cette commande ?';
      const buttonYesText = config.confirm || 'Oui';
      const buttonNoText = config.cancel || 'Non';

      this.$modal.open(ModalDeleteOrder, {
        title: 'Avertissement',
        text,
        buttonYesText,
        buttonNoText,
        yesCallback: (callback) => {
          this.cancelOrder(order.id, () => {
            this.$notify({
              type: 'success',
              title: 'Commande annulée',
              text: `L'annulation a bien été prise en compte. Si vous aviez payé cette commande
                avec des coupons, ils vous ont été rendus. Si vous aviez effectué un paiement par
                carte bancaire, vous avez été remboursé sous forme de coupon.`,
            });
            if (typeof callback === 'function') callback();
          }, () => {
            this.$modal.open(ModalInfo, {
              title: 'Oups !',
              text: `Nous avons rencontré une erreur lors de l'annulation de votre commande. Vous pouvez
          ré-essayer. Si cela ne fonctionne toujours pas, contactez le service client.`,
            });
          });
        },
        noCallback: (callback) => {
          if (typeof callback === 'function') callback();
        },
      });
    },
    tryToDeleteOrderSubscription(order) {
      if (order.boxes.length === 1 && order.containsSubscriptionBox) {
        this.$router.push({
          name: 'mon-compte_order_suspend_form',
          params: {
            orderId: order.id,
          },
        });
      } else {
        this.deleteOrderSubscription(order);
      }
    },
    resumeOrder(orderId, simulation = true) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(`user/${RESUME_ORDER_ACTION}`, {
          id: orderId,
          simulation
        })
          .then((resp) => {
            if (!resp.data.success) reject(resp.data.errors);

            if (!simulation) {
              const order = resp.data.data.orders.filter((o) => o.id === orderId);
              if (order && order[0]) this.notifyOrderStatus(order[0]);
            }

            resolve({
              orderId,
              response: resp.data
            });
          })
          .catch((err) => {
            reject(err, orderId);
          });
      });
    },
    resumeOrderError() {
      this.$modal.open(ModalInfo, {
        title: 'Oups !',
        text: `Une erreur s'est produite lors de la reprise de votre commande. Vous
          pouvez réessayer et contacter le service client si le problème persiste`,
      });
    },
    resumeSubscriptionOrder() {
      this.resumeOrder(this.getSubscriptionOrder.id, false)
        .catch(() => {
          this.$modal.open(ModalInfo, {
            title: 'Oups !',
            text: `Une erreur s'est produite lors de la reprise de votre commande. Vous
          pouvez réessayer et contacter le service client si le problème persiste`,
          });
        });
    },
    resumeOrderSimulateResponse(orderId, response) {
      const errors = response.warning;

      if (errors.length > 0 && errors[0].code === 'BT018') {
        const products = errors.map((warn) => (warn.code === 'BT018' ? warn.metadata : null));

        this.$events.emit(POPIN_ERROR_EVENT, {
          component: ModalOrderResumeOutOfStock,
          products,
          orderId,
        });
      } else {
        // Si on a pas de warning on resume sans simulation
        this.resumeOrder(orderId, false)
          .catch((err) => {
            console.error(err);
            this.$modal.open(ModalInfo, {
              title: 'Oups !',
              text: `Une erreur s'est produite lors de la reprise de votre commande. Vous
          pouvez réessayer et contacter le service client si le problème persiste`,
            });
          });
      }
    },
  },
};
