<template>
  <ul class="potager-receipt-total">
    <potager-receipt-line :type="type">
      {{ label }}
      <template #value>
        <b class="font-extrabold">
          {{ value }}
        </b>
      </template>
    </potager-receipt-line>

    <potager-receipt-line
      class="potager-receipt-total__subtotal"
      v-for="(subTotal, i) in subTotals"
      :key="i"
      :type="subTotals.type">
      {{ subTotal.label }}
      <template #value>
        {{ subTotal.value }}
      </template>
    </potager-receipt-line>
  </ul>
</template>

<script>
import PotagerReceiptLine, { props as propsReceiptLine } from 'UI/PotagerReceiptLine';

import { propsBuilder } from 'UI/Tools';

export const props = {
  label: {
    type: String,
    required: false,
    default: 'TOTAL',
  },
  value: {
    type: String,
    required: true,
  },
  subTotals: {
    type: [Array, Boolean],
    required: false,
    default: null,
  },
  type: propsReceiptLine.type,
};

export default {

  components: {
    PotagerReceiptLine,
  },

  props: propsBuilder(props),

};
</script>
