<template>
  <ul
    :class="{
      'ingredient-list': true,
      'ingredient-list--large': isLarge,
    }">
    <template v-for="(ingredient, i) in ingredients">
      <li
        v-if="ingredient.product"
        :key="`ingredient-${i}`"
        class="ingredient-list__item">
        <potager-link
          :event="hasLink(ingredient) ? 'click' : ''"
          :tag="hasLink(ingredient) ? 'a' : 'span'"
          :to="hasLink(ingredient) ? getLink(ingredient) : ''"
          class="ingredient-list__item__name">
          {{ ingredient.product }}
        </potager-link>

        <span v-if="ingredient.quantity">
          : {{ ingredient.quantity }} {{ ingredient.unit }}
        </span>
      </li>

      <li
        v-else
        :key="i"
        class="ingredient-list__item skeleton h-6 w-1/3" />
    </template>
  </ul>
</template>

<script>
import { calcSlug } from 'Classes/utils/RouteUtils';

export default {

  props: {
    ingredients: {
      type: Array,
      required: false,
      default: null,
    },
    orderId: {
      type: Number,
      required: false,
      default: null,
    },
    isLarge: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  methods: {
    hasLink(ingredient) {
      return ingredient.title;
    },
    getLink(ingredient) {
      return {
        name: 'variety-detail-item',
        params: {
          slug: calcSlug(ingredient.title),
          id: ingredient.supplierProductId,
          orderId: this.orderId,
        },
      };
    },
  },

};
</script>
