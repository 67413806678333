<template>
  <div class="blog">
    <blog-body :is-loading="!content.id">
      <template #heading>
        <heading
          :back="{ name: 'products-list' }"
          :background="content.image"
          :is-loading="!content.id"
          modifiers="blog">
          <template #title>
            {{ content.title }}
          </template>
        </heading>
      </template>

      <blog-section v-if="content.article && content.article.length">
        <wordpress-article :sections="content.article" />
      </blog-section>

      <blog-section title="Conseils de conservation et préparation">
        <cms :content="content.advice">
          <youtube-frame
            v-if="content.youtube"
            :url="content.youtube" />
        </cms>
      </blog-section>

      <calendar-product-seasons
        v-intersect="{
          once: true,
          callback: () => { hasIntersected = true; },
        }"
        :seasons="content.season"
        :title="content.name ? content.name : ''" />

      <blog-section :title="`Caractéristiques - ${content.name}`">
        <cms :content="content.description" />
      </blog-section>

      <blog-section title="Le saviez-vous ?">
        <cms :content="content.anecdotes" />
      </blog-section>

      <blog-social
        :item="content"
        context="supplier-product" />

      <blog-cross-content
        :research="research"
        type="recipe" />

      <blog-cross-content
        :data="content.season?.length <= 2 ? content.linkedBySeasonProducts : undefined"
        :exclude="content.id"
        :research="research"
        type="supplier-product" />

      <blog-cross-content
        :max="3"
        type="supplier" />
    </blog-body>

    <push-subscription class="mb-10 bp768:my-6" />

    <micro-datas
      :content="content"
      context="product" />
  </div>
</template>

<script>

import BlogMixin from 'Mixins/BlogMixin'; // Where the magic happened
import NewsletterPopupMixin from 'Mixins/NewsletterPopupMixin';

import YoutubeFrame from 'Components/videoFrame/YoutubeFrame';
import CalendarProductSeasons from 'Components/calendarProductSeasons/CalendarProductSeasons';
import WordpressArticle from 'Components/article/WordpressArticle';
import { getContentResearchWord } from 'Classes/utils/WordpressUtils';

export default {

  mixins: [
    BlogMixin,
    NewsletterPopupMixin,
  ],

  components: {
    WordpressArticle,
    YoutubeFrame,
    CalendarProductSeasons,
  },

  computed: {
    research() {
      return getContentResearchWord(this.content);
    },
  },

  head() {
    return this.metaInfos;
  }
};
</script>
