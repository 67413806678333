export default {
  name: 'regex',

  valid(value, constraint) {
    const match = constraint.match === undefined ? true : constraint.match;
    if (!value || constraint.regex.test(value) === match) {
      return null;
    }

    return {
      field: constraint.field,
      message: constraint.message || 'Cette valeur n\'est pas valide',
      code: null,
    };
  },
};
