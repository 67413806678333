// based on https://github.com/EstebanFuentealba/vue-wait-vue3
const uniqArray = (array) => {
  return array.filter((el, index, arr) => index === arr.indexOf(el));
};

const is = (waitingFor, waiter) => {
  if (typeof waiter === 'string' && waiter.match(/[\*\!]/)) {
    return waitingFor.filter(w => w === waiter).length > 0;
  }
  return Array.isArray(waiter)
    ? waiter.some(w => is(waitingFor, w))
    : waitingFor.includes(waiter);
};

const progress = (progresses, waiter, current, total = 100) => {
  if (current > total) {
    return endProgress(progresses, waiter);
  }

  return {
    ...progresses,
    [waiter]: {
      current,
      total,
      percent: (100 * current) / total
    }
  };
};

const endProgress = (progresses, waiter) => {
  const {
    [waiter]: omit,
    ...omittedProgresses
  } = progresses;
  return omittedProgresses;
};

const percent = (progresses, waiter) => {
  const progress = progresses[waiter];
  if (!progress) return 0;
  return progress.percent;
};

const mutations = {
  START: 'START',
  END: 'END',
  PROGRESS: 'PROGRESS'
};

export default {
  namespaced: true,
  state: {
    waitingFor: [],
    progresses: {}
  },
  getters: {
    is: state => waiter => is(state.waitingFor, waiter),
    any: state => state.waitingFor.length > 0,
    percent: state => waiter => percent(state.progresses, waiter)
  },
  actions: {
    start: ({ commit }, waiter) => commit(mutations.START, waiter),
    end: ({ commit }, waiter) => commit(mutations.END, waiter),
    progress: ({ commit }, progress) => commit(mutations.PROGRESS, progress)
  },
  mutations: {
    [mutations.START](state, waiter) {
      state.waitingFor = uniqArray([...state.waitingFor, waiter]);
    },
    [mutations.END](state, waiter) {
      state.waitingFor = uniqArray(state.waitingFor)
        .filter(l => l !== waiter);
      state.progresses = endProgress(state.progresses, waiter);
    },
    [mutations.PROGRESS](state, {
      waiter,
      current,
      total
    }) {
      state.progresses = progress(state.progresses, waiter, current, total);
    }
  }
};
