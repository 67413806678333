<template>
  <tunnel-body
    :is-ready="isReady"
    class="text-center">
    <h1 class="text-xl font-extrabold mb-3 mx-10">
      Votre région
    </h1>

    <p class="mb-10">
      📍&nbsp;J’ai besoin de connaître votre région, car nous ne livrons pas encore toute la France.
    </p>

    <tunnel-regions-selector @onReady="isReady = true" />
  </tunnel-body>
</template>

<script>
import GtmMixin from 'Mixins/GtmMixin';

import TunnelBody from 'Components/tunnel/TunnelBody';
import TunnelRegionsSelector from 'Components/tunnel/TunnelRegionsSelector';

import MetaInfosService from 'Classes/services/MetaInfoService';

export default {

  mixins: [
    GtmMixin,
  ],

  components: {
    TunnelBody,
    TunnelRegionsSelector,
  },

  data: () => ({
    isReady: false,
  }),

  head: MetaInfosService.generate({
    title: 'Démarrez un abonnement sans engagement',
  }),
};
</script>
