import { EDIT_SWILE_SETTINGS, LOGOUT_SWILE, GET_SWILE_LOGIN_URL } from 'Stores/types/userActionsTypes';
import IconPtcySwile from 'Icons/regular/IconPtcySwile';
import IconPtcySwileApp from 'Icons/bold/IconPtcySwileApp';
import IconPtcySwileGradient from 'Icons/color/IconPtcySwileGradient';

import { inAppWebView } from 'Classes/ConfigurationManager';
import { getResolvedUrl } from 'Classes/utils/RouteUtils';

import ModalPedagogyTicketResto from 'Modals/ModalPedagogyTicketResto';

import { openModal } from 'Plugins/potagerModals';

import { storeDispatch } from 'Services/storeServices';

import { PaymentMethods } from 'potagerlogic/dist/Enums/PaymentMethods';

export default {
  ...PaymentMethods.Swile,
  route: { name: 'mon-compte_payment-methods_swile' },
  defaultIcon: IconPtcySwileApp,
  icons: [IconPtcySwileGradient],
  alternativeIcons: [IconPtcySwile],
  loaders: {
    login: GET_SWILE_LOGIN_URL,
    logout: LOGOUT_SWILE,
    editSettings: EDIT_SWILE_SETTINGS,
  },
  actions: {
    openAddModal(app, onSuccess, onClose) {
      openModal(ModalPedagogyTicketResto,
        {
          name: PaymentMethods.Swile.name,
          cardIconComponent: IconPtcySwileGradient,
          cardIconFullComponent: IconPtcySwile,
        },
        undefined,
        (e) => {
          if (typeof onClose === 'function') onClose();
          if (e.params?.type === 'success' && typeof onSuccess === 'function') onSuccess();
        });
    },
    redirect: (routeName = 'mon-compte_payment-methods_swile') => {
      const redirectUrl = `${getResolvedUrl(routeName)}?from=swile${inAppWebView() ? `&utm_source=app&token=${localStorage.getItem('token')}` : ''}`;
      storeDispatch(`user/${GET_SWILE_LOGIN_URL}`, redirectUrl)
        .then(({ data }) => {
          document.location.href = data;
        });
    },
    logout: () => {
      storeDispatch(`user/${LOGOUT_SWILE}`);
    },
    setDefault: ({ isDefault }) => {
      storeDispatch(`user/${EDIT_SWILE_SETTINGS}`, {
        usableForSubscription: !!isDefault,
      });
    },
  },
};
