<template>
  <component
    :is="tag"
    :class="[
      {
        'potager__row': true,
        'potager__row--center': centered,
        'potager__row--condensed': condensed,
        'potager__row--collapse-y': collapseY,
        'potager__row--nowrap': nowrap,
      },
      ...align ? [
        `potager__row--align-${align}`,
      ] : [],
    ]">
    <slot />
  </component>
</template>

<script>
import { propsBuilder } from 'UI/Tools';

export const props = {
  centered: {
    type: Boolean,
    required: false,
    default: false,
  },
  align: {
    type: String,
    required: false,
    default: null,
    values: ['start', 'center', 'end'],
  },
  tag: {
    type: String,
    required: false,
    default: 'div',
  },
  condensed: {
    type: Boolean,
    required: false,
    default: false,
  },
  collapseY: {
    type: Boolean,
    required: false,
    default: false,
  },
  nowrap: {
    type: Boolean,
    required: false,
    default: false,
  },
};

export default {

  name: 'PotagerRow', // Usefull for PotagerForm

  props: propsBuilder(props),

};
</script>
