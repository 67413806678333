<template>
  <div class="text-white">
    <div class="flex items-center justify-between">
      <p
        class="text-lg bp1024:text-base font-bold py-8 pr-4"
        v-html="item.question" />
      <potager-button
        is-circle
        theme="beryl-green"
        @onClick="isOpen = !isOpen">
        <template #icon>
          <component :is="getIcon" />
        </template>
      </potager-button>
    </div>
    <collapsible-content
      :is-open="isOpen"
      class="border-0 border-b border-solid border-beryl-green">
      <div
        class="pb-8 text-base bp1024:text-sm.5 tracking-normal"
        v-html="item.answer" />
    </collapsible-content>
  </div>
</template>

<script>
import CollapsibleContent from 'Components/collapsible/CollapsibleContent';

import PotagerButton from 'UI/PotagerButton';

import IconAdd from 'Icons/bold/IconAdd';
import IconSubtract from 'Icons/bold/IconSubtract';

export default {

  components: {
    CollapsibleContent,
    PotagerButton,
  },

  data: () => ({
    isOpen: false,
  }),

  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  computed: {
    getIcon() {
      return this.isOpen ? IconSubtract : IconAdd;
    },
  },

};
</script>
