export const test = ({
  rootGetters,
  expectedValue
}) => !!rootGetters['user/getSubscriptionOrder'] === expectedValue;

export default {
  type: 'hasSubscriptionOrder',
  values: [false, true],
  test,
};
