<template>
  <ul class="flex justify-between items-start py-4 px-1 bp640:px-0 bg-white">
    <li
      v-for="(step, index) in getSteps"
      :key="index"
      :class="[
        'text-center text-xxs w-1/4 relative',
        index < getSteps.length - 1 ? 'account-order-breadcrumb__dashed' : '',
        step.isWarning
          ? 'text-warning'
          : (step.isActive
            ? 'text-charcoal'
            : 'text-warm-grey'
          ),
      ]">
      <component
        :is="step.icon"
        class="text-xl.5 mb-1" />
      <p class="font-bold">
        {{ step.label }}
      </p>
      <p v-if="step.date">
        {{ step.date }}
      </p>
    </li>
  </ul>
</template>

<script>
import { fromUnixTime, isSameDay } from 'date-fns';

import {
  VALIDATED,
  PAYED,
  PREPARATION,
  DELIVERED,
  REFUNDED,
  CANCELED_CAN_RESUME,
  CANCELED_CAN_NOT_RESUME,
  UNPAYED,
} from 'Classes/OrderStatus';
import { tmsToFormat } from 'Classes/utils/DatesUtils';

import IconDelete1 from 'Icons/regular/IconDelete1';
import IconCreditCardCheck from 'Icons/regular/IconCreditCardCheck';
import IconSavingPiggyCoinsAlternate from 'Icons/regular/IconSavingPiggyCoinsAlternate';
import IconCreditCardRemove from 'Icons/regular/IconCreditCardRemove';
import IconShoppingBagDownload from 'Icons/regular/IconShoppingBagDownload';
import IconDeliveryTruck2 from 'Icons/regular/IconDeliveryTruck2';
import IconTaskListClock from 'Icons/regular/IconTaskListClock';

export default {

  props: {
    order: {
      type: Object,
      required: true,
    },
  },

  computed: {
    getStatus() {
      return this.order.frontStatus;
    },
    isDefinitiveCanceled() {
      return this.getStatus === REFUNDED
        || this.getStatus === CANCELED_CAN_NOT_RESUME
        || this.getStatus === UNPAYED;
    },
    getCurrentStep() {
      switch (this.getStatus) {
        case VALIDATED:
        case CANCELED_CAN_NOT_RESUME:
        case CANCELED_CAN_RESUME:
          return 1;
        case PAYED:
        case REFUNDED:
        case UNPAYED:
          return 2;
        case PREPARATION:
          return 3;
        case DELIVERED:
          return 4;
        default:
          return 1;
      }
    },
    getSteps() {
      return [
        (() => {
          const isActive = this.getCurrentStep >= 1;
          switch (this.getStatus) {
            case CANCELED_CAN_RESUME:
            case CANCELED_CAN_NOT_RESUME:
            case REFUNDED:
              return {
                label: 'Annulée',
                icon: IconDelete1,
                isActive,
              };
            default:
              return {
                label: 'En cours',
                icon: IconTaskListClock,
                date: tmsToFormat(this.order.date, 'dd/MM'),
                isActive,
              };
          }
        })(),
        (() => {
          const isActive = this.getCurrentStep === 2 && this.order?.stripePayment?.isPayable && !this.order.stripePayment?.canCustomerWaitForBatch
            ? false
            : this.getCurrentStep >= 2;
          switch (this.getStatus) {
            case REFUNDED:
              return {
                label: 'Remboursée',
                icon: IconSavingPiggyCoinsAlternate,
                isActive,
              };
            case UNPAYED:
              return {
                label: 'Non payée',
                icon: IconCreditCardRemove,
                isActive,
                isWarning: true,
              };
            default:
              return {
                label: 'Payée',
                icon: IconCreditCardCheck,
                date: this.getStatus !== CANCELED_CAN_NOT_RESUME && tmsToFormat(this.order.paymentDate, 'dd/MM'),
                isActive,
              };
          }
        })(),
        {
          label: 'Préparée',
          icon: IconShoppingBagDownload,
          date: !this.isDefinitiveCanceled && tmsToFormat(this.order.timeSlot.date, 'dd/MM'),
          isActive: this.getCurrentStep >= 3 && isSameDay(fromUnixTime(this.order.timeSlot.date), new Date()),
        },
        {
          label: 'Livrée',
          icon: IconDeliveryTruck2,
          date: !this.isDefinitiveCanceled && tmsToFormat(this.order.timeSlot.date, 'dd/MM'),
          isActive: this.getCurrentStep >= 4,
        },
      ];
    },
  },

};
</script>

<style lang="scss">
.account-order-breadcrumb__dashed:after {
  content: '';
  display: block;
  position: absolute;
  height: 1px;
  width: 1.5rem;
  background: $porcelain;
  left: 100%;
  top: .8125rem;
  margin-left: -.75rem;
}
</style>
