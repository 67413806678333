/**
 * Get the caliber of a product.
 *
 * @param {Object} product - The product object.
 * @param {number} [product.caliberMin] - The minimum caliber of the product.
 * @param {number} [product.caliberMax] - The maximum caliber of the product.
 * @returns {string} The caliber of the product in the format "Calibre : min-max".
 */
export const getProductCaliber = (product) => {
  if (!product.caliberMin && !product.caliberMax) return '';
  return `Calibre : ${product.caliberMin || ''}${product.caliberMin && product.caliberMax ? '-' : ''}${product.caliberMax || ''}mm`;
};

/**
 * Get the quantity of a product in pieces and weight.
 *
 * @param {Object} product - The product object.
 * @param {number} [product.pieces] - The number of pieces of the product.
 * @param {number} [product.weight] - The weight of the product in kilograms.
 * @returns {string} The quantity of the product in pieces and weight.
 */
export const getProductQuantity = (product) => {
  if (!product) return '';

  const piecesMsg = product.pieces ? `${product.pieces} pièce${product.pieces > 1 ? 's' : ''}` : '';
  const weightMsg = product.weight ? `~${product.weight}kg` : '';

  return [piecesMsg, weightMsg].filter(Boolean)
    .join(', ');
};
