import BREAKPOINTS from 'potagerlogic/dist/Enums/Breakpoints';

const {
  bp375,
  bp425,
  bp480,
  bp640,
  bp768,
  bp840,
  bp1024,
  bp1168,
  bp1280,
  bp1920,
} = BREAKPOINTS;

export const MediaQueriesCalculator = () => {
  const width = window.innerWidth;

  return {
    bp375: width < bp375,
    bp425: width < bp425,
    bp480: width < bp480,
    bp640: width < bp640,
    bp768: width < bp768,
    bp840: width < bp840,
    bp1024: width < bp1024,
    bp1168: width < bp1168,
    bp1280: width < bp1280,
    bp1920: width < bp1920,
    width,
  };
};
