<template>
  <potager-receipt-line
    v-for="(item, i) in lines"
    :key="`item-${i}`"
    :helper="item.helper"
    :is-sub-cat-section="Array.isArray(item.value)"
    :is-title-section="!item.value && !!item.label"
    :type="item.type">
    {{ item.label }}

    <template #value>
      <template v-if="!Array.isArray(item.value)">
        {{ item.value }}
      </template>

      <template v-else>
        <PotagerReceiptDetailsSubCat
          :lines="item.value"
          class="pl-6 mb-3" />
      </template>
    </template>
  </potager-receipt-line>
</template>

<script>
import PotagerReceiptLine from 'UI/PotagerReceiptLine';
import { propsBuilder } from 'UI/Tools';

// Lines format sample
// [
//     {
//       "label": "Sous-total",
//       "value": "17,90€"
//     },
//   {
//     "label": "Frais de livraison",
//     "value": "Inclus",
//     "type": "success"
//   },
//   {
//     value: [
//       {
//         "label": "Sous-total",
//         "value": "17,90€"
//       },
//       {
//         "label": "Frais de livraison",
//         "value": "Inclus",
//         "type": "success"
//       }
//     ]
//   }
// ]

export const props = {
  lines: {
    type: Array,
    required: true,
  },
};

export default {

  components: {
    PotagerReceiptLine,
    PotagerReceiptDetailsSubCat: () => import('UI/PotagerReceiptDetails'),
  },

  props: propsBuilder(props),

};
</script>
