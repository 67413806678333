<template>
  <potager-modal>
    <template #body>
      <p>
        Vous souhaitez changer de jour de retrait et
        {{ isPlural ? 'il se trouve que des éléments présents' : 'il se trouve qu\'un élément présent' }} dans votre
        panier
        {{ isPlural ? 'sont épuisés' : 'est épuisé' }} pour ce jour en particulier.
      </p>

      <div class="modal-container__out-of-stock-list">
        <p class="modal-container__out-of-stock-list__subtitle">
          {{ textBoxOutOfStock }}
        </p>
        <p
          v-for="(product, index) in products"
          :key="index"
          class="modal-container__out-of-stock-list__box">
          <span class="modal-container__out-of-stock-list__box__name">{{ product.boxName }}</span>
          <span class="modal-container__out-of-stock-list__box__price">{{ formatPrice(product.price) }}</span>
        </p>
      </div>

      <p>
        En confirmant le changement de jour de retrait,
        {{ isPlural ? 'les éléments épuisés seront supprimés' : 'l\'élément épuisé sera supprimé' }} de votre panier. En
        annulant, vous conserverez l’ancien jour de retrait et
        {{ isPlural ? 'les éléments seront toujours présents' : 'l\'élément sera toujours présent' }} dans votre panier.
      </p>
    </template>

    <template #footer>
      <potager-button
        :full-width="true"
        label="Annuler"
        theme="stroke"
        @onClick="close" />
      <potager-button
        :full-width="true"
        label="Confirmer"
        @onClick="confirm" />
    </template>
  </potager-modal>
</template>

<script>
import { mapGetters } from 'vuex';

import { formatPrice } from 'Classes/utils/PricesUtils';

import PotagerModal from 'UI/PotagerModal';
import PotagerButton from 'UI/PotagerButton';

export default {

  components: {
    PotagerModal,
    PotagerButton,
  },

  props: {
    products: {
      type: Array,
      require: false,
      default: () => [],
    },
    action: {
      type: Function,
      required: true,
    },
    cancelHandler: {
      type: Function,
      required: false,
      default: () => {
      },
    },
  },

  computed: {
    ...mapGetters('basket', [
      'getGroupDeliveryDay',
    ]),
    isPlural() {
      return this.products.length > 1;
    },
    textBoxOutOfStock() {
      if (this.isPlural) return 'PANIERS ÉPUISÉS :';
      return 'PANIER ÉPUISÉ :';
    },
  },

  methods: {
    formatPrice,
    confirm() {
      if (this.action instanceof Promise) {
        this.action.then(() => {
          this.close();
        });
      } else {
        this.action();
        this.close();
      }
    },
    close() {
      if (this.cancelHandler() instanceof Promise) {
        this.cancelHandler()
          .then(() => {
            this.$modal.close();
          });
      } else {
        this.$modal.close();
      }
    },
  },
};
</script>
