import Skeleton from 'Pages/flyingPages/Skeleton';

import FlyingPage from 'Components/flyingPage/FlyingPage';
import CardProduct from 'Components/cards/CardProduct';
import SocialMedia from 'Components/widget/SocialMedia';
import PushSubscription from 'Components/pushs/PushSubscription';
import AssociatedContentSlider from 'Components/sliders/AssociatedContentSlider';
import UpsellingSlider from 'Components/sliders/UpsellingSlider';
import MicroDatas from 'Components/microDatas/MicroDatas';

import { FETCH_DASHBOARD_ACTION } from 'Stores/types/dashboardActionTypes';
import { mapGetters } from 'vuex';

export default {

  components: {
    FlyingPage,
    Skeleton,
    CardProduct,
    SocialMedia,
    PushSubscription,
    AssociatedContentSlider,
    UpsellingSlider,
    MicroDatas,
  },

  computed: {
    ...mapGetters('user', [
      'isSubscriptionActive',
    ]),
    ...mapGetters('dashboard', [
      'isLoaded',
      'getDashboardProducts',
    ]),
    ...mapGetters('session', [
      'isLoggedIn',
    ]),
  },

  methods: {
    fetchDashboard() {
      if (this.isLoggedIn) {
        this.$store.dispatch(`dashboard/${FETCH_DASHBOARD_ACTION}`);
      }
    },
  },
};
