<template>
  <div class="nps-layout text-center flex flex-col items-center justify-center ">
    <potager-picture
      class="w-full mb-12 h-[250px]"
      src="poire" />

    <div class="max-w-[600px] mx-auto">
      <router-view />
    </div>
  </div>
</template>

<script>
import PotagerPicture from 'UI/PotagerPicture';

export default {
  components: { PotagerPicture },
};
</script>

<style lang="scss">
.nps-survey {
  max-width: calc(100vw - (24px * 2));
}
</style>
