export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGOUT = 'LOGOUT';
export const UPDATE_REQUEST_URL = 'UPDATE_REQUEST_URL';
export const RESET_SESSION = 'RESET_SESSION';
export const UPDATE_OPTANON_ACTIVE_GROUPS = 'UPDATE_OPTANON_ACTIVE_GROUPS';
export const UPDATE_OPTANON_CHOICE_SAVED = 'UPDATE_OPTANON_CHOICE_SAVED';

export const UPDATE_TEST_MODE = 'UPDATE_TEST_MODE';
export const UPDATE_TEST_MODE_DATE = 'UPDATE_TEST_MODE_DATE';

export const ADD_REDIRECTED_PAGE = 'ADD_REDIRECTED_PAGE';
export const UPDATE_OAC_FLOW = 'UPDATE_OAC_FLOW';
export const UPDATE_OAC_RECOMMENDED_REF = 'UPDATE_OAC_RECOMMENDED_REF';
