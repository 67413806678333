<template>
  <div class="tunnel-choice-block">
    <div
      v-wave="clickable"
      :class="{
        'tunnel-choice-block__body': true,
        'tunnel-choice-block__body--clickable': clickable
      }"
      @click="$emit('onClick')">
      <div class="tunnel-choice-block__body__content">
        <div class="tunnel-choice-block__icon">
          <slot name="icon" />
        </div>

        <p class="tunnel-choice-block__title">
          <slot name="title" />
        </p>

        <p v-if="$slots.default">
          <slot />
        </p>
      </div>

      <div
        v-if="$slots.action"
        class="tunnel-choice-block__action">
        <slot name="action" />
      </div>
    </div>

    <div
      v-if="$slots.more"
      class="tunnel-choice-block__more">
      <slot name="more" />
    </div>
  </div>
</template>

<script>

export default {

  props: {
    clickable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

};
</script>

<style lang="scss">
.tunnel-choice-block {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__body {
    background: $white;
    border: solid 1px $porcelain;
    padding: 1.25rem 1.75rem;
    border-radius: .25rem;
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
    transition: box-shadow .3s;
    position: relative;
    flex-grow: 2;
    @apply text-xs.5;
    line-height: $baseLh;
    @include disableTap();

    @include bp768() {
      padding: 1rem;
    }

    &--clickable {
      cursor: pointer;

      @include hover() {
        box-shadow: 0 0 .375rem rgba($black, .1);
      }
    }

    &__content {
      flex-grow: 2;
    }
  }

  &__icon {
    @apply text-4xl;
    margin: 0 0 .75rem 0;
  }

  &__title {
    @apply text-xs.5;
    font-weight: $weightBold;
    text-transform: uppercase;
    margin-bottom: 1rem;

    &:last-child { margin-bottom: 0; }
  }

  &__action {
    width: 100%;
    margin-top: 2rem;
  }

  &__more {
    text-align: center;
    margin-top: .5rem;
  }
}
</style>
