export const test = ({
  rootGetters,
  expectedValue
}) => {
  return !!rootGetters['subscriptionBasket/getSubscriptionBasket'] && !!rootGetters['subscriptionBasket/getCustomerSubscriptionProduct'] === expectedValue;
};

export default {
  type: 'hasSubscriptionBasket',
  values: [false, true],
  test,
};
