export const CREATE_TEMPORARY_CUSTOMER_ACTION = 'CREATE_TEMPORARY_CUSTOMER_ACTION';
export const RESET_TEMPORARY_CUSTOMER_ACTION = 'RESET_TEMPORARY_CUSTOMER_ACTION';
export const GET_TEMPORARY_CUSTOMER_BY_TOKEN_ACTION = 'GET_TEMPORARY_CUSTOMER_BY_TOKEN_ACTION';
export const UPDATE_EMAIL_ACTION = 'UPDATE_EMAIL_ACTION';
export const UPDATE_TEMPORARY_CUSTOMER_SEND_MAIL_ACTION = 'UPDATE_TEMPORARY_CUSTOMER_SEND_MAIL_ACTION';
export const REGISTER_USER_INFOS_SEND_MAIL = 'REGISTER_USER_INFOS_SEND_MAIL';
export const GET_TEMPORARY_CUSTOMER_ACTION = 'GET_TEMPORARY_CUSTOMER_ACTION';
export const AFTER_CONFIRM_LOGGIN_ACTION = 'AFTER_CONFIRM_LOGGIN_ACTION';
export const UPDATE_SPONSOR_ACTION = 'UPDATE_SPONSOR_ACTION';
export const VALID_SPONSOR_CODE_ACTION = 'VALID_SPONSOR_CODE_ACTION';
export const UPDATE_TEMPORARY_CUSTOMER_REGION_ACTION = 'UPDATE_TEMPORARY_CUSTOMER_REGION_ACTION';
export const UPDATE_TEMPORARY_CUSTOMER_ACTION = 'UPDATE_TEMPORARY_CUSTOMER_ACTION';
export const UPDATE_TEMPORARY_CUSTOMER_DATA = 'UPDATE_TEMPORARY_CUSTOMER_DATA';
