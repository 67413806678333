/**
 * Ce module isole l'accès au routeur pour éviter les embrouilles
 * avec les dépendances circulaires.
 * On importe le routeur seulement quand on en a besoin.
 */
import router from 'Router';

export const routerResolve = (route) => {
  return router.resolve(route);
};

export const routerPush = (route) => {
  return router.push(route);
};
