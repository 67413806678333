<template>
  <section class="bg-beryl-green py-20 bp1024:py-16 bp768:py-12 bp768:pb-8 overflow-hidden">
    <potager-container class="bp1024:pr-0 bp640:pr-6">
      <potager-row class="mb-0">
        <potager-col
          bp1024="6"
          bp1280="4"
          bp640="12"
          class="bp640:mb-8 flex items-center"
          col="5"
          offset="1"
          offset-bp1024="0">
          <div
            :class="[
              'pl-8',
              'bp1280:pl-0',
            ]">
            <h2 class="home-page__title text-left mb-4">
              <strong>Des recettes et des conseils</strong> au quotidien
            </h2>

            <div class="leading-normal pr-4">
              <p class="mb-4">
                <strong>À vous qui planifiez vos repas dès le contenu de votre panier dévoilé,<br>
                  ou à vous qui cherchez une idée de repas pour ce soir alors qu’on est déjà ce soir...</strong>
              </p>

              <p class="mb-4">
                L’équipe vous accompagne avec des recettes simples et saines, et des conseils pour ne pas faire chou
                blanc devant une variété que vous n’avez pas encore domptée.
              </p>

              <p class="mb-7">
                Vous ne saviez pas qu’il fallait couper les fânes des radis pour les conserver plus longtemps ?
                Maintenant, oui.
              </p>
            </div>

            <potager-button
              v-gtm="{ prefix: 'homepage-receipts-section' }"
              :to="{ name: 'recettes-list' }"
              theme="go-green">
              Découvrir les recettes
            </potager-button>
          </div>
        </potager-col>

        <potager-col
          bp640="12"
          class="flex items-center bp640:justify-center relative"
          col="6">
          <div class="home-page-receipts__recipe-wrapper relative w-full">
            <div
              :class="
                ['flex flex-col bp640:static z-10 absolute left-0 top-1/2 transform -translate-y-1/2 bp640:translate-y-0 min-h-64',
                 { 'animate-pulse home-page-receipts__card home-page-receipts__slider rounded-lg overflow-hidden bg-wild-willow shadow-lg': isReceiptsLoading }
                ]">
              <potager-slider
                v-if="!isReceiptsLoading"
                ref="cardSlider"
                :autoplay="7000"
                :items="receiptsList"
                :overflow-visible="$mq.bp640"
                :settings="getSliderSettings"
                :slides-per-view="1"
                class="home-page-receipts__slider h-full bp640:m-auto"
                controller
                name="home-page-receipts"
                @onInit="($event) => {
                  cardSlider = $event.slider;
                  onInit('Card', $event);
                }"
                @onSlideChange="onSlideChange">
                <template #default="{ item }">
                  <card-layout class="w-full mx-auto home-page-receipts__card relative bg-bianca">
                    <card-picture
                      :src="item?.image"
                      class="rounded-b-none"
                      no-lazy-load />

                    <card-body class="px-5 pt-4 pb-5">
                      <card-title class="home-page-receipts__card-title">
                        {{ item?.title }}
                      </card-title>

                      <div class="mt-2.5">
                        <potager-link
                          v-if="item?.redirect"
                          :to="item.redirect"
                          class="home-page-receipts__recipe-link underline text-xs.5 inline-block">
                          Voir la recette
                        </potager-link>
                      </div>
                    </card-body>
                  </card-layout>
                </template>
              </potager-slider>

              <div class="home-page-receipts__pagination mt-5 flex justify-center" />
            </div>

            <div
              v-if="!$mq.bp640"
              :class="[
                'home-page-receipts__recipe-background-wrapper w-full h-full ml-auto relative rounded-lg overflow-hidden',
                'bp1024:rounded-r-none',
                { 'animate-pulse bg-wild-willow': isReceiptsLoading }
              ]">
              <potager-slider
                v-if="!isReceiptsLoading"
                ref="backgroundSlider"
                :items="receiptsList"
                :settings="getBackgroundSliderSettings"
                :slides-per-view="1"
                class="w-full h-full"
                controller
                name="home-page-receipts-background"
                @onInit="($event) => {
                  backgroundSlider = $event.slider;
                  onInit('Background', $event);
                }">
                <template #default="{ item }">
                  <potager-picture
                    :src="item?.backgroundImage"
                    :title="item?.title"
                    class="home-page-receipts__recipe-background bg-wild-willow"
                    no-lazy-load
                    placeholder />
                </template>
              </potager-slider>
            </div>
          </div>
        </potager-col>

        <potager-col
          v-if="!$mq.bp1280"
          col="1" />
      </potager-row>
    </potager-container>
  </section>
</template>

<script>
import WordpressApiMixin, { RECEIPTS_CONTENT_TYPE } from 'Mixins/WordpressApiMixin';

import { getContentLink } from 'Classes/utils/RouteUtils';

import PotagerContainer from 'UI/PotagerContainer';
import PotagerRow from 'UI/PotagerRow';
import PotagerCol from 'UI/PotagerCol';
import PotagerButton from 'UI/PotagerButton';
import PotagerSlider from 'UI/PotagerSlider';
import PotagerPicture from 'UI/PotagerPicture';

import CardBody from 'Components/cards/UI/CardBody';
import CardLayout from 'Components/cards/UI/CardLayout';
import CardPicture from 'Components/cards/UI/CardPicture';
import CardTitle from 'Components/cards/UI/CardTitle';
import { toRaw } from 'vue';

export default {
  data() {
    return {
      receiptsIdList: [24816, 30790, 18845],
      receiptsList: [],
      isReceiptsLoading: true,
      activeSlide: 1,
      cardSlider: null,
      backgroundSlider: null,
    };
  },

  mixins: [
    WordpressApiMixin,
  ],

  components: {
    PotagerPicture,
    PotagerSlider,
    CardTitle,
    CardBody,
    CardPicture,
    CardLayout,
    PotagerButton,
    PotagerCol,
    PotagerRow,
    PotagerContainer,
  },

  methods: {
    getReceipts() {
      const receipts = [];
      Promise.all(
        this.receiptsIdList
          .map((id) => {
            return this.getCallApi(id, RECEIPTS_CONTENT_TYPE)
              .then(({ data }) => receipts.push(data))
              .catch((err) => console.error(err));
          }),
      )
        .finally(() => {
          // sort to match the order of the receiptsIdList
          this.receiptsList = this.receiptsIdList
            .map((id) => receipts
              .map((recipe) => ({
                ...recipe,
                redirect: getContentLink(recipe),
                backgroundImage: `rassurance-receipts-recipe-${recipe.id}`,
              }))
              .find((recipe) => recipe.id === id));

          this.isReceiptsLoading = false;
        });
    },
    onInit(slider, params) {
      this[`is${slider}SliderInit`] = true;
      this.onSlideChange(params);
    },
    onSlideChange(arg) {
      if (typeof arg === 'object') {
        this.$nextTick(() => {
          this.activeSlide = arg.slideIndex;
          if (this.cardSlider) {
            const cardSlider = toRaw(this.cardSlider);
            const backgroundSlider = toRaw(this.backgroundSlider);
            cardSlider.controller.control = backgroundSlider;
            if (backgroundSlider) {
              backgroundSlider.controller.control = cardSlider;
            }
          }
        });
      } else {
        this.activeSlide = this.$refs.cardSlider;
      }
    },
  },

  mounted() {
    this.getReceipts();
  },

  computed: {
    getSliderSettings() {
      const getMobileCreativeEffect = (mode = 'prev') => ({
        translate: [
          `calc(${mode === 'prev' ? '-' : ''}100% ${mode === 'prev' ? '-' : '+'} 0.625rem)`,
          0,
          0,
        ],
        opacity: 0.2,
      });

      const mobileSettings = {
        effect: 'creative',
        initialSlide: 1,
        creativeEffect: {
          limitProgress: 2,
          prev: getMobileCreativeEffect('prev'),
          next: getMobileCreativeEffect('next'),
        },
      };

      const desktopSettings = {
        effect: 'fade',
        allowTouchMove: false,
      };

      return {
        pagination: {
          el: '.home-page-receipts__pagination',
          bulletClass: 'home-page-receipts__bullet',
          bulletActiveClass: 'home-page-receipts__bullet--active',
          clickable: true,
        },
        ...(this.$mq.bp640 ? mobileSettings : desktopSettings),
      };
    },
    getBackgroundSliderSettings() {
      return {
        allowTouchMove: false,
        spaceBetween: 0,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.home-page-receipts {
  &__slider {
    width: 230px !important;

    :deep() .swiper {
      &-slide {
        pointer-events: none;

        &-active {
          pointer-events: initial;
        }
      }
    }
  }

  &__card-title {
    @include multilineEllipsis(1rem, $baseLh, 3);
    @apply text-sm.5 leading-5 h-[60px];
  }

  &__card {
    @apply text-center font-bold;

    $base: '.card-v2';

    :deep() #{$base} {
      &__pic {
        border-bottom-right-radius: 0;
      }

      &__wrapper {
        box-shadow: none;
      }

      &__body {
        &__header {
          @apply flex flex-col justify-center;
        }
      }
    }
  }

  &__recipe-wrapper {
    // fix height here for skeleton
    height: 488px;
    padding-left: 9vw;

    @include bp1024() {
      padding-left: 14vw;
    }

    @include bp640() {
      padding-left: inherit;
      height: auto;
    }

    @include min-bp1280() {
      padding-left: 170px;
    }
  }

  &__recipe-background {
    height: 488px;
    @apply min-w-full;
    @apply w-full;

    :deep() .potager-picture__img--loaded {
      @apply h-full;
      @apply w-full;
      @apply object-cover;
    }
  }

  :deep() &__bullet {
    @apply inline-block;
    @apply py-2;
    @apply cursor-pointer;

    &::after {
      content: '';
      @apply block;
      @apply h-0.75;
      @apply w-10;
      @apply bp768:w-8;
      @apply rounded;
      @apply mx-1;
      @apply bg-white;
      @apply transition;
    }

    &--active {
      &::after {
        @apply bg-british-racing;
      }
    }
  }

  &__recipe-link {
    &::before {
      content: '';
      display: block;
      position: absolute;
      inset: 0;
      z-index: 1;
    }
  }
}
</style>
