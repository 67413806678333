<template>
  <div class="homepage z-10">
    <home-page-banner-slider />

    <home-page-payment-methods-available />

    <home-page-highlighted-products />

    <home-page-order-process />

    <home-page-highlighted-products :section-index="1" />

    <home-page-subscription-benefits />

    <home-page-concept />

    <home-page-manifesto />

    <home-page-receipts />

    <!-- <home-page-price-chart /> -->

    <customer-reviews-slider />

    <push-download-app />
  </div>
</template>

<script>
import ToolsMixin from 'Mixins/ToolsMixin';

import HomePageBannerSlider from 'Components/homePage/HomePageBannerSlider';
import HomePageHighlightedProducts from 'Components/homePage/HomePageHighlightedProducts';
import HomePageReceipts from 'Components/homePage/HomePageReceipts';
import HomePageConcept from 'Components/homePage/HomePageConcept';
import HomePageManifesto from 'Components/homePage/HomePageManifesto';
// import HomePagePriceChart from 'Components/homePage/HomePagePriceChart';
import HomePagePaymentMethodsAvailable from 'Components/homePage/HomePagePaymentMethodsAvailable';

import CustomerReviewsSlider from 'Components/sliders/CustomerReviewsSlider';
import PushDownloadApp from 'Components/pushs/PushDownloadApp';
import HomePageOrderProcess from 'Components/homePage/HomePageOrderProcess';
import HomePageSubscriptionBenefits from 'Components/homePage/HomePageSubscriptionBenefits';

import { getResolvedUrl } from 'Classes/utils/RouteUtils';

export default {

  mixins: [
    ToolsMixin,
  ],

  data: () => ({
    metaImg: null,
  }),

  components: {
    HomePageSubscriptionBenefits,
    HomePageOrderProcess,
    CustomerReviewsSlider,
    HomePageBannerSlider,
    HomePageConcept,
    HomePageHighlightedProducts,
    HomePageManifesto,
    HomePagePaymentMethodsAvailable,
    // HomePagePriceChart,
    HomePageReceipts,
    PushDownloadApp,
  },

  head() {
    return this.generateMetaIfPanelClosed({
      title: 'Potager City - Des paniers de fruits et légumes vraiment bons',
      link: [{
        rel: 'canonical',
        href: getResolvedUrl('home'),
      }],
    });
  },
};
</script>

<style lang="scss" scoped>
.homepage {
  :deep() > section {
    @apply bg-bianca;
  }
}

.fake-banner {
  // manage sticky state of hp-slider
  // desktop banner height = $hp-slider-height
  // tablet (bp1024) banner height = $hp-slider-height--tablet
  // mobile (bp640 & bp1168) banner height = $hp-slider-height--mobile
  // header height : $height_header
  // mobile (bp1168) header height : $height_header--mobile

  $default-height: $hp-slider-height - $height_header;
  height: $default-height;
  @apply w-full;

  + * {
    @apply block;
    margin-top: -$default-height;

    @include bp1168() {
      $height: $hp-slider-height--mobile - $height_header--mobile;
      margin-top: -#{$height};
    }

    @include bp1024() {
      $height: $hp-slider-height--tablet - $height_header;
      margin-top: -#{$height};
    }

    @include bp640() {
      margin-top: -#{$hp-slider-height--mobile - $height_header};
    }
  }

  @include bp1168() {
    height: $hp-slider-height--mobile - $height_header--mobile;
  }

  @include bp1024() {
    height: $hp-slider-height--tablet - $height_header;
  }

  @include bp640() {
    height: $hp-slider-height--mobile - $height_header;
  }
}
</style>
