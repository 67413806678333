import { PLACEHOLDER_DASHBOARD_SECTIONS } from 'Classes/Placeholders';
import { RESET_DASHBOARD, UPDATE_DASHBOARD, UPDATE_DASHBOARD_SLIDES } from 'Stores/types/dashboardMutationsTypes';
import { FETCH_DASHBOARD_ACTION, FETCH_DASHBOARD_SLIDES_ACTION } from 'Stores/types/dashboardActionTypes';

import { api } from 'Plugins/potagerApiClient';
import { DashboardItemType, DashboardSectionType } from 'potagerlogic/dist/Enums/Dashboard';

export default {
  namespaced: true,
  state: {
    deliveryDate: {},
    region: null,
    sections: PLACEHOLDER_DASHBOARD_SECTIONS,
    slides: [],
  },

  getters: {
    isSlidesLoaded: (state, getters, rootState, rootGetters) => {
      const isLoading = rootGetters['wait/is'](FETCH_DASHBOARD_SLIDES_ACTION) || rootGetters['wait/is'](FETCH_DASHBOARD_ACTION);
      return state.slides?.length > 0 && !isLoading;
    },
    isLoaded: (state, getters, rootState, rootGetters) => {
      const isLoading = rootGetters['wait/is'](FETCH_DASHBOARD_ACTION);
      return !isLoading && state.sections?.some((section) => !section.loading);
    },
    getDashboardSections: (state) => state.sections
      .filter((section) => section.items.length > 0)
      .filter((s) => s.type !== DashboardSectionType.Homepage)
      .filter((s) => s.items.some((item) => item.itemType !== DashboardItemType.Thumbnail)),
    getHomepageSections: (state) => state.sections
      .filter((s) => s.type === DashboardSectionType.Homepage),
    getDashboardProducts: (state) =>
      state.sections
        .filter((section) => !section.loading)
        .flatMap((section) => section.items)
        .filter((item) => item.itemType !== DashboardItemType.Thumbnail),
    getDashboardRegion: (state) => state.region,
    getMiniBoxes: (state) => state.sections
      .map((section) => section.items)
      .flat()
      .filter((item) => item.itemType === DashboardItemType.Minibox)
      .filter((item, index, self) => self.findIndex((t) => t.id === item.id) === index),
    getSlides: (state) => state.slides,
    getDeliveryDate: (state) => state.deliveryDate,
    isClosedStore: (state, getters) => (getters.getDashboardSections?.length <= 0),
    isClosedStoreAndRegion: (state, getters, rootState, rootGetters) => getters.isClosedStore && rootGetters['session/isClosedRegion'],
  },

  mutations: {
    [UPDATE_DASHBOARD](state, data) {
      state.deliveryDate = data.deliveryDate;
      state.region = data.region;
      state.sections = data.sections;
      state.slides = data.slides;
    },
    [UPDATE_DASHBOARD_SLIDES](state, slides) {
      state.slides = slides;
    },
    [RESET_DASHBOARD](state) {
      state.deliveryDate = {};
      state.region = null;
      state.sections = PLACEHOLDER_DASHBOARD_SECTIONS;
      state.slides = [];
    }
  },

  actions: {
    [FETCH_DASHBOARD_ACTION]({
      commit,
      dispatch,
      rootGetters
    }, force = false) {
      dispatch('wait/start', FETCH_DASHBOARD_ACTION, { root: true });
      if (force) commit(RESET_DASHBOARD);

      return new Promise((resolve) => {
        const apiCall = rootGetters['session/isLoggedIn']
          ? api.dashboard.findOneByBasket(undefined, rootGetters['session/getTestModeDate'])
          : api.dashboard.findOneByRegion(rootGetters['session/getRegionId']);

        apiCall
          .then((resp) => {
            commit(UPDATE_DASHBOARD, resp.data);
            resolve(resp);
          })
          .finally(() => dispatch('wait/end', FETCH_DASHBOARD_ACTION, { root: true }));
      });
    },
    [FETCH_DASHBOARD_SLIDES_ACTION]({
      commit,
      dispatch,
      rootGetters
    }) {
      dispatch('wait/start', FETCH_DASHBOARD_SLIDES_ACTION, { root: true });
      return new Promise((resolve) => {
        api.dashboard.getSlides(rootGetters['session/getTestModeDate'])
          .then((resp) => {
            commit(UPDATE_DASHBOARD_SLIDES, resp.data.data.results);
            resolve(resp.data.data.results);
          })
          .finally(() => dispatch('wait/end', FETCH_DASHBOARD_SLIDES_ACTION, { root: true }));
      });
    },
  },
};
