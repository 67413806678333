<template>
  <transition name="sidepanel--anim">
    <div
      v-if="isOpen"
      class="sidepanel">
      <potager-notifications class="panel-notification pointer-events-auto" />

      <router-view :route="$potagerRoute" />
    </div>
  </transition>
</template>

<script>
import PotagerNotifications from 'Components/notification/PotagerNotifications';

export default {
  components: { PotagerNotifications },

  computed: {
    isOpen() {
      return this.$potagerRoute && this.$potagerRoute.meta.isPanel;
    },
  },
};
</script>

<style lang="scss" scoped>
.panel-notification {
  @apply fixed left-0 w-auto #{!important};
  right: $panel_w !important;

  @include bp768() {
    right: 0 !important;
  }
}
</style>
