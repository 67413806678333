<template>
  <section
    v-if="box.loading || box.products.length > 0"
    class="product-detail__section">
    <div
      v-if="box.loading"
      class="product-detail__subtitle h-8 w-1/2 skeleton" />
    <h2
      v-else
      class="product-detail__subtitle">
      Composition
    </h2>

    <template v-if="box.loading">
      <div class="product-list__item h-8 w-3/4 skeleton ml-0" />
      <div class="product-list__item h-8 w-1/4 skeleton ml-0" />
      <div class="product-list__item h-8 w-2/3 skeleton ml-0" />
    </template>
    
    <product-list
      :disable-link="disableLink"
      :products="box.products"
      :show-mention-category="box.categoryMentionCategory"
      :show-mention-weight="box.categoryMentionWeight"
      class="product-detail__product-list"
      flying-page />
  </section>
</template>

<script>
import ProductList from 'Components/lists/ProductList';

export default {

  components: {
    ProductList,
  },

  props: {
    box: {
      type: Object,
      required: true,
    },
    disableLink: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

};
</script>
