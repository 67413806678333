<template>
  <div class="potager-receipt-line panel-section__line mb-0 text-xs.5 font-medium">
    <template v-if="!isSubCatSection">
      <p
        :class="[
          'potager-receipt-line__title flex-[2_2_0%] truncate',
          `potager-receipt-line__title--${type}`,
          { 'mb-3': isTitleSection }
        ]">
        <slot />
        <icon-question-circle
          v-if="helper"
          v-wave
          class="potager-receipt-line__helper"
          name="help"
          @click="openHelper" />
      </p>

      <p
        v-if="!isTitleSection"
        :class="[
          'potager-receipt-line__value flex-1 truncate text-right',
          `potager-receipt-line__value--${type}`,
        ]">
        <slot name="value" />
      </p>
    </template>
    <template v-else>
      <slot name="value" />
    </template>
  </div>
</template>

<script>
import IconQuestionCircle from 'Icons/regular/IconQuestionCircle';

import { propsBuilder } from 'UI/Tools';

export const props = {
  type: {
    type: String,
    required: false,
    default: 'default',
    values: ['default', 'discount', 'success', 'total'],
  },
  helper: {
    type: Object,
    required: false,
    default: null,
  },
  isTitleSection: {
    type: Boolean,
    required: false,
    default: false,
  },
  isSubCatSection: {
    type: Boolean,
    required: false,
    default: false,
  },
};

export default {

  components: {
    IconQuestionCircle,
  },

  props: propsBuilder(props),

  methods: {
    openHelper() {
      this.$modal.open(this.helper);
    },
  },

};
</script>

<style lang="scss" scoped>
.potager-receipt-line {
  display: flex;
  justify-content: space-between;
  margin-bottom: .25rem;

  &:last-child {
    margin-bottom: 0;
  }

  &__helper {
    cursor: pointer;
    vertical-align: middle;
  }

  &__title {
    &--total {
      @apply font-bold uppercase;
    }
  }

  &__value {
    margin-left: .5rem;
    font-weight: $weightBold;

    &--discount {
      color: $forestGreen;
    }

    &--success {
      @apply text-forest-green;
      @apply font-bold;
    }
  }
}
</style>
