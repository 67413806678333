<template>
  <potager-modal
      v-bind="$props"
      ref="modal">
    <template #title>
      <span v-html="title" />
    </template>

    <template #body>
      <div
        v-for="(item, index) in items"
        :key="`modal-item--${index}`"
        class="modal-list__item flex pb-4 mb-4 border-b border-0 border-solid border-white-rock last:border-b-0 last:mb-0 last:pb-0">
        <div class="modal-list__item-icon flex items-start justify-center mr-4">
          <div
            class="text-xl.5 w-14 h-14 bg-bianca rounded-md flex items-center justify-center"
            v-html="item.icon" />
        </div>
        <div class="modal-list__item-description text-left">
          <div class="modal-list__item-title font-bold mb-1">
            <span v-html="item.title" />
          </div>
          <div class="modal-list__item-description">
            <div
              v-html="item.description" />
          </div>
        </div>
      </div>
    </template>
  </potager-modal>
</template>

<script>
import PotagerModal, { props } from 'UI/PotagerModal';
import { propsBuilder } from 'UI/Tools';

export default {
  components: {
    PotagerModal,
  },

  props: propsBuilder({
    items: {
      type: Array,
      required: true,
      default: () => ([]),
    },
    ...props,
  }),
};
</script>
