export default {
  name: 'required',

  valid(value, constraint) {
    return !value
      ? {
        field: constraint.field,
        message: 'Ce champ est requis.',
        code: null,
      }
      : null;
  },
};
