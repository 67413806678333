// when route have `keepQuery` meta, the query will be kept when route change
export default function (to, from, next) {
  const newTo = {
    ...to,
    query: {
      ...from.query,
      ...to.query,
      ...from.meta.potagerRoute?.query,
      ...to.meta.potagerRoute?.query,
    },
  };

  if (JSON.stringify(to.query) !== JSON.stringify(newTo.query) && (to.meta.keepQuery || to.meta.potagerRoute?.meta?.keepQuery)) {
    console.info('keepQueryParamGuard > Redirecting to', newTo);
    next(newTo);
  } else {
    next();
  }
}
