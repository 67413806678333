<template>

  <div
    class="goo">
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      class="goo__svg"
      :id="`goo__svg-${seed}`">

      <defs>
        <filter :id="`goo__distord-${seed}`">
          <feTurbulence
            baseFrequency=".012"
            type="fractalNoise"
            :seed="seed" />
          <feColorMatrix
            type="hueRotate"
            :values="hueRotate" />
          <feDisplacementMap
            in="SourceGraphic"
            xChannelSelector="R"
            yChannelSelector="B"
            scale="64" />
          <feGaussianBlur
            stdDeviation="3" />
          <feComponentTransfer result="main">
            <feFuncA
              type="gamma"
              amplitude="64"
              exponent="8" />
          </feComponentTransfer>
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 -10"
            result="goo" />
          <feComposite
            operator="over"
            in="main" />
        </filter>
      </defs>

      <mask
        v-if="src"
        :id="`goo__mask-${seed}`">
        <ellipse
          v-if="ellipse"
          :filter="`url(#goo__distord-${seed})`"
          :cx="ellipse.cx"
          :cy="ellipse.cy"
          :rx="ellipse.rx"
          :ry="ellipse.ry"
          :fill="fill" />
      </mask>

      <ellipse
        v-else-if="ellipse"
        :filter="`url(#goo__distord-${seed})`"
        :cx="ellipse.cx"
        :cy="ellipse.cy"
        :rx="ellipse.rx"
        :ry="ellipse.ry"
        :fill="fill" />

      <ellipse
        class="goo__shadow"
        v-if="ellipse && shadow"
        :filter="`url(#goo__distord-${seed})`"
        :cx="ellipse.cx"
        :cy="ellipse.cy"
        :rx="ellipse.rx"
        :ry="ellipse.ry"
        :fill="shadow" />

      <g
        v-if="src"
        :mask="`url(#goo__mask-${seed})`">
        <image
          class="goo__image"
          :alt="alt"
          :xlink:href="src"
          x="0"
          y="0"
          :height="h * 2"
          :width="w * 2" />
      </g>
    </svg>
  </div>

</template>

<script>
export default {

  data: () => ({
    ellipse: null,
    h: 0,
    w: 0,
    hueRotate: 0,
    timer: 0,
  }),

  props: {
    src: {
      type: String,
      required: false,
      default: null,
    },
    fill: {
      type: String,
      required: false,
      default: '#fff',
    },
    shadow: {
      type: [String, Boolean],
      required: false,
      default: false,
    },
    index: {
      type: Number,
      required: false,
      default: 0,
    },
    animate: {
      type: Boolean,
      required: false,
      default: false,
    },
    alt: {
      type: String,
      required: false,
      default: null,
    },
  },

  computed: {
    seed() {
      return (this.index * 100) + Math.random();
    },
  },

  watch: {
    animate: {
      handler(val) {
        if (val) {
          this.timer = setInterval(() => {
            this.hueRotate += 0.5;
          }, 1000 / 64);
        } else {
          clearInterval(this.timer);
        }
      },
      immediate: true,
    },
  },

  methods: {
    getRandomInteger(min, max) {
      return Math.floor(Math.random() * (max - min)) + min;
    },
    init() {
      const rect = this.$el.getBoundingClientRect();
      this.w = rect.width / 2;
      this.h = rect.height / 2;
      const offsetX = this.w / 5;
      const offsetY = this.h / 5;
      const cx = this.w;
      const cy = this.h;
      const rx = this.w - offsetX;
      const ry = this.h - offsetY;

      this.ellipse = {
        cx,
        cy,
        rx,
        ry,
      };
    },
  },

  mounted() {
    this.$nextTick(this.init);

    window.addEventListener('resize', this.init);
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.init);
    clearInterval(this.timer);
  },

};
</script>
