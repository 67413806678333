<template>
  <panel
    :back-route="backRoute"
    title="Identité">
    <div class="panel-section">
      <div class="panel-section__line">
        <potager-input
          v-model="firstname"
          label="Prénom"
          name="customerFirstname" />
        <potager-input
          v-model="lastname"
          label="Nom"
          name="customerLastname" />
        <potager-date-picker
          :model-value="getBirthDate"
          label="Date de naissance"
          name="birthDate"
          @update:modelValue="setBirthDate" />
        <potager-input
          v-model="phone"
          label="Téléphone"
          name="customerPhone" />

        <potager-button
          :disabled="!hasModifications"
          :full-width="true"
          :is-loading="$wait.is('UPDATE_USER_IDENTITY_ACTION')"
          label="Valider"
          @onClick="onRegisterUserInfos" />
      </div>
    </div>
  </panel>
</template>

<script>
import Panel from 'Components/panel/Panel';
import PotagerButton from 'UI/PotagerButton';
import PotagerDatePicker from 'UI/Form/PotagerDatePicker';
import PotagerInput from 'UI/Form/PotagerInput';

import { mapGetters } from 'vuex';
import { UPDATE_USER_IDENTITY_ACTION } from 'Stores/types/userActionsTypes';
import MetaInfosService from 'Classes/services/MetaInfoService';

export default {

  components: {
    Panel,
    PotagerButton,
    PotagerDatePicker,
    PotagerInput,
  },

  data: () => ({
    backRoute: { name: 'mon-compte_info_perso' },
    firstname: '',
    lastname: '',
    birthDate: '',
    phone: '',
  }),

  computed: {
    ...mapGetters('user', [
      'getFirstName',
      'getLastName',
      'getBirthDate',
      'getPhone',
    ]),
    hasModifications() {
      if (this.firstname !== this.getFirstName && this.firstname !== '') return true;
      if (this.lastname !== this.getLastName && this.lastname !== '') return true;
      if (this.phone !== this.getPhone && this.phone !== '') return true;
      if (this.birthDate !== this.formatDate(new Date(this.getBirthDate)) && this.birthDate !== '') return true;
      return false;
    },
  },

  methods: {
    setBirthDate(value) {
      this.birthDate = this.formatDate(value);
    },
    formatDate(value) {
      if (value !== null) {
        const date = new Date(value);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${year}-${month}-${day}`;
      }
      return null;
    },
    onRegisterUserInfos() {
      this.$store.dispatch(`user/${UPDATE_USER_IDENTITY_ACTION}`, {
        customerFirstname: this.firstname !== '' ? this.firstname : this.getFirstName,
        customerLastname: this.lastname !== '' ? this.lastname : this.getLastName,
        birthDate: this.birthDate !== '' ? this.birthDate : this.getBirthDate,
        customerPhone: this.phone !== '' ? this.phone : this.getPhone,
      })
        .then(() => {
          this.$notify({
            type: 'success',
            title: 'Informations personnelles modifiées',
            text: 'Vos informations personnelles ont bien été modifiées.',
          });

          this.$router.push(this.backRoute)
            .catch(() => {
            });
        });
    },
  },

  mounted() {
    this.firstname = this.getFirstName || '';
    this.lastname = this.getLastName || '';
    this.phone = this.getPhone || '';
    this.setBirthDate(new Date(this.getBirthDate));
  },

  head: MetaInfosService.generate({
    title: 'Identité',
  }),

};
</script>
