import {
  openModal as jenOpenModal,
  closeModal as jenCloseModal,
  config,
} from 'jenesius-vue-modal';

let beforeCloseMethod = null;

export const openModal = (component, componentProps, beforeClose, namespace) => {
  return jenOpenModal(component, componentProps, namespace)
    .then(() => {
      beforeCloseMethod = beforeClose;
    });
};

export const closeModal = (params, namespace) => {
  const close = () => {
    jenCloseModal({ namespace })
      // avoid error when multiple close calls
      .catch(() => {
      })
      .finally(() => {
        beforeCloseMethod = null;
      });
  };

  if (beforeCloseMethod) {
    if (typeof beforeCloseMethod === 'function') {
      beforeCloseMethod({ params });
      close();
    } else if (typeof beforeCloseMethod === 'object') {
      beforeCloseMethod({ params })
        .then(() => {
          close();
        });
    }
  } else {
    close();
  }
};

export default {
  install(app) {
    config({
      store: app.config.globalProperties.$store,
      router: app.config.globalProperties.$router,
      animation: 'fade-popin',
    });

    app.config.globalProperties.$modal = {
      open: openModal,
      close: closeModal
    };
  }
};
