<template>
  <panel
    :back-route="{ name: 'mon-compte_luncheons' }"
    title="Imprimer le bordereau">
    <div class="panel-section">
      <p class="panel-section__p">
        Imprimez le bordereau téléchargé et envoyez-le à l’adresse suivante, accompagné des titres restaurant :
      </p>
      <p class="panel-section__p">
        <b>POTAGER CITY, Espace Cardinal Workside <br> 60 quai Perrache <br> CS 30333 <br> 69285 LYON Cedex 02</b>
      </p>
      <p class="panel-section__p panel-section__p--without-mb">
        Afin d’éviter d’éventuels pertes ou vols de titres restaurant, nous vous invitons à utiliser un envoi en lettre
        recommandée R2 de 20g ou 50g.
      </p>
    </div>

    <div class="panel-section">
      <potager-button
        :is-loading="$wait.is('DOWNLOAD_COUPON_LUNCHEON_BATCH')"
        full-width
        label="Téléchargez le bordereau"
        theme="stroke"
        @onClick="downloadBatch" />
    </div>

    <template #footer>
      <potager-button
        :full-width="true"
        :to="{ name: 'mon-compte_luncheons' }"
        label="OK, j'ai compris" />
    </template>
  </panel>
</template>

<script>
import Panel from 'Components/panel/Panel';
import PotagerButton from 'UI/PotagerButton';
import { downloadFile } from 'Classes/utils/FileDownloadUtils';
import ModalInfo from 'Modals/ModalInfo';

export default {

  components: {
    Panel,
    PotagerButton,
  },

  computed: {
    batchId() {
      return this.$potagerRoute.params.batchId;
    }
  },

  methods: {
    downloadBatch() {
      this.$wait.start('DOWNLOAD_COUPON_LUNCHEON_BATCH');

      this.$api.user.downloadCouponLuncheonBatch(this.batchId)
        .then((resp) => {
          const { data } = resp;
          downloadFile(data, `Bordereau-ticket-restaurants-${this.batchId}.pdf`);
        })
        .catch(() => {
          this.$modal.open(ModalInfo, {
            title: 'Oups !',
            text: 'Désolé, une erreur inattendue est survenue, veuillez réessayer plus tard. Si le problème persiste, vous pouvez contacter <b><a href="https://www.potagercity.fr/contact" target="_blank">notre service client</a></b>.',
          });
        })
        .finally(() => {
          this.$wait.end('DOWNLOAD_COUPON_LUNCHEON_BATCH');
        });
    },
  },

};
</script>
