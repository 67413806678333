<template>
  <div
    v-if="seasons"
    class="calendar-product-seasons">
    <h2 class="calendar-product-seasons__title">
      Calendrier de saison - {{ title }}
    </h2>

    <slot name="desc">
      <div class="cms">
        Nous nous engageons à respecter les saisons de tous les produits en vente sur notre site.
        Et puis de toute façon, c’est toujours meilleur !
      </div>
    </slot>

    <ul class="calendar-product-seasons__calendar">
      <li
        v-for="(season, key) in allSeasons"
        :key="key"
        class="calendar-product-seasons__calendar__item">
        <span
          :class="[
            'calendar-product-seasons__calendar__item__content',
            ...getItemContentClasses(key),
          ]">
          <icon-check-circle1 class="calendar-product-seasons__calendar__item__check" />
          <span class="calendar-product-seasons__calendar__item__name">
            {{ season }}
          </span>
        </span>
      </li>
    </ul>

    <ul class="calendar-product-seasons__legend print:hidden">
      <li class="calendar-product-seasons__legend__item">
        <i class="calendar-product-seasons__legend__item__ico calendar-product-seasons__legend__item__ico--not" />
        <span class="calendar-product-seasons__legend__item__text">
          Pas de saison
        </span>
      </li>
      <li class="calendar-product-seasons__legend__item">
        <i class="calendar-product-seasons__legend__item__ico calendar-product-seasons__legend__item__ico--in" />
        <span class="calendar-product-seasons__legend__item__text">
          Pleine saison
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
import sortBy from 'lodash/sortBy';

import IconCheckCircle1 from 'Icons/regular/IconCheckCircle1';

export default {

  components: {
    IconCheckCircle1,
  },

  data: () => ({
    allSeasons: [
      'printemps',
      'été',
      'automne',
      'hiver',
    ],
    orderedSeasons: [],
  }),

  props: {
    title: {
      type: String,
      required: true,
    },
    seasons: {
      type: Array,
      default: () => ([]),
    },
  },

  methods: {
    getOrderedSeasons(pSeasons) {
      if (!pSeasons || !pSeasons.length) return [];

      const r = [];
      const sIndexs = [];

      pSeasons.forEach((seasonName) => {
        sIndexs.push({
          name: seasonName,
          order: this.allSeasons.indexOf(seasonName.toLowerCase()),
        });
      });

      const orderedLs = sortBy(sIndexs, ['order']);

      orderedLs.forEach((item) => {
        r.push(item.name);
      });

      return r;
    },
    isInCalendar(seasonIndex) {
      if (seasonIndex < 0 || (seasonIndex >= this.allSeasons.length)) return -1;
      return this.orderedSeasons.indexOf(this.allSeasons[seasonIndex].toLowerCase());
    },
    getItemContentClasses(seasonIndex) {
      const r = [];
      const index = this.isInCalendar(seasonIndex);

      let enter = false;
      let leave = false;

      if (index >= 0) {
        r.push('calendar-product-seasons__calendar__item__content--in');

        if (this.allSeasons.length > 1) {
          if ((seasonIndex === 0) || (this.isInCalendar(seasonIndex - 1) < 0)) {
            enter = true;
          }

          if ((seasonIndex === this.allSeasons.length - 1) || (this.isInCalendar(seasonIndex + 1) < 0)) {
            leave = true;
          }
        } else {
          r.push('calendar-product-seasons__calendar__item__content--in--both');
        }

        if (enter && leave) {
          r.push('calendar-product-seasons__calendar__item__content--in--both');
        } else if (enter && !leave) {
          r.push('calendar-product-seasons__calendar__item__content--in--enter');
        } else if (!enter && leave) {
          r.push('calendar-product-seasons__calendar__item__content--in--leave');
        }
      }

      return r;
    },

  },

  watch: {
    seasons(newSeasons) {
      this.orderedSeasons = this.getOrderedSeasons(newSeasons);
    },
  },

  mounted() {
    this.orderedSeasons = this.getOrderedSeasons(this.seasons);
  },

};
</script>
