export const UPDATE_USER = 'UPDATE_USER';

export const SET_DEFAULT_CARD = 'SET_DEFAULT_CARD';

export const UPDATE_USER_IDENTITY = 'UPDATE_USER_IDENTITY';
export const UPDATE_USER_EMAIL = 'UPDATE_USER_EMAIL';

export const UPDATE_SUBSCRIPTION = 'UPDATE_SUBSCRIPTION';

export const UPDATE_USER_COUPONS = 'UPDATE_USER_COUPONS';
export const UPDATE_COUPON_AUTOMATED_VALUE = 'UPDATE_COUPON_AUTOMATED_VALUE';
export const UPDATE_COUPON_AUTOMATED_CONFIGURATION = 'UPDATE_COUPON_AUTOMATED_CONFIGURATION';

export const ADD_RECIPE = 'ADD_RECIPE';
export const REMOVE_RECIPE = 'REMOVE_RECIPE';

export const UPDATE_SUBSCRIPTION_TIME_SLOTS = 'UPDATE_SUBSCRIPTION_TIME_SLOTS';
export const UPDATE_DELIVERY_POINT_SUBSCRIPTION = 'UPDATE_DELIVERY_POINT_SUBSCRIPTION';

export const UPDATE_USER_ORDER_SUBSCRIPTION = 'UPDATE_USER_ORDER_SUBSCRIPTION';
export const UPDATE_SUBSCRIPTION_NEXT_ORDER = 'UPDATE_SUBSCRIPTION_NEXT_ORDER';

export const UPDATE_COUPON_LUNCHEONS_BATCH = 'UPDATE_COUPON_LUNCHEONS_BATCH';
export const ADD_COUPON_LUNCHEON_BATCH = 'ADD_COUPON_LUNCHEON_BATCH';
export const UPDATE_LOCAL_COUPON_LUNCHEONS_BATCH = 'UPDATE_LOCAL_COUPON_LUNCHEONS_BATCH';
export const ADD_LOCAL_COUPON_LUNCHEON_BATCH = 'ADD_LOCAL_COUPON_LUNCHEON_BATCH';
export const DELETE_LOCAL_COUPON_LUNCHEON_BATCH = 'DELETE_LOCAL_COUPON_LUNCHEON_BATCH';

export const UPDATE_ORDER = 'UPDATE_ORDER';
export const ADD_ORDER = 'ADD_ORDER';
export const UPDATE_HISTORICAL_ORDER = 'UPDATE_HISTORICAL_ORDER';
export const RESET_HISTORICAL_ORDER = 'RESET_HISTORICAL_ORDER';
