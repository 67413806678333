export default {
  name: 'number',

  valid(value, constraint) {
    if (!value) {
      return null;
    }

    const intValue = typeof value === 'string' ? Number(value.replace(/\D/g, '')) : value;
    const intMin = constraint.options.min && Number(constraint.options.min);
    const intMax = constraint.options.max && Number(constraint.options.max);

    if (intMin && intValue < intMin) {
      return {
        field: constraint.field,
        message: constraint.options.message || `Cette valeur doit être supérieur ou égale à ${intMin}`,
        code: null,
      };
    }

    if (intMax && intValue > intMax) {
      return {
        field: constraint.field,
        message: constraint.options.message || `Cette valeur doit être inférieur ou égale à ${intMax}`,
        code: null,
      };
    }

    return null;
  },
};
