<template>
  <div class="bg-beryl-green text-center overflow-hidden">
    <potager-container
      class="flex items-center justify-center flex-col py-8 relative"
      size="xsmall">
      <div class="w-full z-10 flex flex-col items-center leading-6 w-[460px] max-w-[85%] bp425:max-w-full">
        <div class="w-full max-w-[300px] mb-4">
          <div class="text-british-racing text-center font-extrabold text-2xl.5 mb-1">
            15% de réduction
          </div>

          <p>
            sur votre 1ère commande pour toute inscription à notre newsletter.
          </p>
        </div>

        <potager-form
          id="newsletter-subscription"
          class="flex items-center justify-center w-full flex-wrap -m-1.5"
          @onSubmit="() => {
            onSubscriptionSubmit(email, source)
              .then(() => {
                email = undefined;
              });
          }">
          <potager-input
            v-model="email"
            :constraints="[
              { constraint: 'required', field: 'email-newsletter' },
              { constraint: 'email', field: 'email-newsletter' },
            ]"
            :is-disabled="isSubscriptionLoading"
            :is-loading="isSubscriptionLoading"
            class="w-full flex-1 basis-[320px] m-1.5"
            inline-submit="S'inscrire"
            name="email-newsletter"
            placeholder="Votre adresse email*"
            type="email">
            <template #icon>
              <icon-email-action-unread />
            </template>
          </potager-input>
        </potager-form>
      </div>

      <div
        class="flex justify-between absolute -inset-x-10 bp425:-inset-x-[120px] inset-y-0 items-end text-beryl-green">
        <potager-picture
          extra-img-class="object-right"
          height="186px"
          no-lazy-load
          src="bg-pattern-1"
          width="191px" />

        <div class="flex-1 basis-[230px] shrink-0" />

        <potager-picture
          extra-img-class="object-left"
          height="186px"
          no-lazy-load
          src="bg-pattern-2"
          width="191px" />
      </div>
    </potager-container>
  </div>
</template>

<script>
import PotagerContainer from 'UI/PotagerContainer';
import PotagerInput from 'UI/Form/PotagerInput';
import PotagerForm from 'UI/Form/PotagerForm';
import PotagerPicture from 'UI/PotagerPicture';

import IconEmailActionUnread from 'Icons/regular/IconEmailActionUnread';

import NewsletterMixin from 'Mixins/NewsletterMixin';

export default {
  name: 'NewsletterSection',

  mixins: [NewsletterMixin],

  props: {
    source: {
      type: String,
      default: undefined,
    },
  },

  components: {
    PotagerPicture,
    PotagerForm,
    PotagerInput,
    PotagerContainer,
    IconEmailActionUnread,
  },

  data() {
    return {
      email: undefined,
    };
  },
};
</script>
