import { ORDER_ALC_TYPE, ORDER_TYPE } from 'Classes/workflow/TunnelTypesConstants';

export const test = ({
  state, rootGetters, expectedValue,
}) => (!!rootGetters['user/isSubscriptionActive'] || state.constraints[ORDER_TYPE].value === ORDER_ALC_TYPE) === expectedValue;

export default {
  type: 'showSuccessPage', // match what you'll use in constraint property in TunnelSteps.js
  values: [false, true], // list of potential values.
  test, // function that will be called to test the constraint
};
