<template>
  <component
    :is="clickable ? 'button' : 'div'"
    v-wave="clickable"
    :class="[
      'potager-banner rounded-md py-4 px-5 w-full flex flex-column justify-between items-center',
      `potager-banner--${theme}`,
      {
        'potager-button--reset': clickable
      }
    ]"
    theme="reset"
    @click="onClick">
    <div class="potager-banner__content text-xs.5">
      <slot />
    </div>
    <slot name="icon">
      <div
        v-if="icon"
        class="potager-banner__icon ml-3 flex items-center">
        <component
          :is="icon" />
      </div>
    </slot>
  </component>
</template>

<script>
import IconInformationCircle from 'Icons/bold/IconInformationCircle';

import PotagerButton from 'UI/PotagerButton';
import { propsBuilder } from 'UI/Tools';

export const props = {
  theme: {
    type: String,
    required: false,
    default: 'default',
    values: [
      'default',
      'beryl-green',
    ],
  },
  type: {
    type: String,
    required: false,
    default: undefined,
    values: [
      'info',
    ],
  },
  clickable: {
    type: Boolean,
    required: false,
    default: false,
  },
};

export default {
  name: 'PotagerBanner',
  components: {
    PotagerButton,
    IconInformationCircle,
  },
  props: propsBuilder(props),
  computed: {
    icon() {
      switch (this.type) {
        case 'info':
          return IconInformationCircle;
        default:
          return undefined;
      }
    },
  },
  methods: {
    onClick() {
      if (this.clickable) this.$emit('click');
    },
  },
};
</script>

<style lang="scss" scoped>
.potager-banner {
  &--default {
    @apply bg-white-rock text-charcoal;
  }

  &--beryl,
  &--beryl-green {
    @apply bg-beryl-green text-british-racing;
  }
}
</style>
