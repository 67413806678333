<template>
  <div class="px-4 pt-1.5 pb-4 max-w-6/12 w-full mx-auto z-100">
    <div class="mb-6">
      <div class="absolute inset-x-0 top-0 py-1.5 px-4 bg-bianca flex justify-center z-10">
        <div class="h-1 w-full bg-white-rock overflow-hidden rounded-full max-w-6/12">
          <div
            :style="{ transform: `scale(${percentDone}, 1)` }"
            class="h-full w-full bg-british-racing transition duration-500 origin-left" />
        </div>
      </div>
    </div>

    <div class="flex w-full">
      <potager-button
        v-if="backRoute"
        :to="backRoute"
        is-circle
        class="bg-bianca text-charcoal"
        theme="stroke">
        <template #icon>
          <icon-arrow-left class="text-xs" />
        </template>
      </potager-button>

      <div
        v-if="$slots.title"
        class="mx-auto">
        <slot name="title" />
      </div>

      <potager-button
        :class="['tunnel-header__close bg-bianca text-charcoal', {'ml-auto': !$slots.title}]"
        is-circle
        theme="stroke"
        @onClick="onExit">
        <template #icon>
          <icon-close class="text-xs" />
        </template>
      </potager-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import MobileAppMixin from 'Mixins/MobileAppMixin';

import { DELIVERY_TYPE } from 'Classes/workflow/TunnelTypesConstants';

import PotagerButton from 'UI/PotagerButton';

import IconArrowLeft from 'Icons/bold/IconArrowLeft';
import IconClose from 'Icons/bold/IconClose';

export default {

  mixins: [
    MobileAppMixin,
  ],

  components: {
    PotagerButton,
    IconArrowLeft,
    IconClose,
  },

  computed: {
    ...mapGetters('tunnel', [
      'getPrevStepRoute',
      'getStepByRouteName',
      'getLastStepRoute',
    ]),
    ...mapGetters('map', [
      'getUserSearch',
      'getSelectedDeliveryPoint',
    ]),
    ...mapGetters('homeDelivery', [
      'getDeliveryPoint',
    ]),
    deliveryPoint() {
      const paramDlpId = parseInt(this.$route.params.deliveryPointId, 10);
      if (paramDlpId) {
        if (this.getSelectedDeliveryPoint?.id === paramDlpId) {
          return this.getSelectedDeliveryPoint;
        }
        if (this.getDeliveryPoint?.id === paramDlpId) {
          return this.getDeliveryPoint;
        }
      }
      return undefined;
    },
    params() {
      const params = {};

      if (this.deliveryPoint) {
        params.latlng = `${this.deliveryPoint.lat},${this.deliveryPoint.lng}`;
      }

      return params;
    },
    backRoute() {
      if (this.$route.name === 'tunnel-subscription-success') {
        // hide back button on success page
        return undefined;
      }

      const newTypes = this.$route.query.onlyGDD ? [{ [DELIVERY_TYPE]: undefined }] : undefined;
      return this.getPrevStepRoute({
        newTypes,
        params: this.params
      });
    },
    percentDone() {
      return this.getStepByRouteName(this.$route.name)?.percent;
    },
  },

  methods: {
    onExit() {
      this.$emit('onExit');
    },
  },
};
</script>
