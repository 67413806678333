<template>
  <timeslots-list
    v-if="isActiveOrdersLoading || getOrders?.length"
    :skeleton="isActiveOrdersLoading"
    :time-slots="isActiveOrdersLoading ? [1] : getOrders" />

  <transition
    mode="out-in"
    name="fade-fast-translate-y">
    <div v-if="subscriptionTimeSlots?.length || isSubscriptionTimeSlotsLoading">
      <div
        v-if="isActiveOrdersLoading || getOrders?.length"
        :class="['panel-section__separator my-8', { 'animate-pulse': isSubscriptionTimeSlotsLoading }]" />

      <timeslots-list
        :skeleton="isSubscriptionTimeSlotsLoading"
        :time-slots="isSubscriptionTimeSlotsLoading ? [1, 2, 3] : subscriptionTimeSlots" />
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';
import { GET_SUBSCRIPTION_TIME_SLOTS_ACTION } from 'Stores/types/userActionsTypes';

import TimeslotsList from 'Components/timeSlots/TimeSlotsList';

export default {
  name: 'OrdersTimeSlotsList',
  components: { TimeslotsList },
  data() {
    return {
      GET_SUBSCRIPTION_TIME_SLOTS_ACTION,
    };
  },
  computed: {
    ...mapGetters('user', [
      'getSubscription',
      'getOrders',
      'subscriptionTimeSlots',
      'isUserLoaded',
    ]),
    isActiveOrdersLoading() {
      return !this.getOrders?.length && !this.isUserLoaded;
    },
    isSubscriptionTimeSlotsLoading() {
      return this.$wait.is(GET_SUBSCRIPTION_TIME_SLOTS_ACTION) && !this.subscriptionTimeSlots?.length || !this.isUserLoaded;
    },
  },
};
</script>
