import { notify } from '@kyvg/vue3-notification';

import Survey from 'Classes/surveys';

import { REMOVE_SUBSCRIPTION_ACTION } from 'Stores/types/userActionsTypes';
import { UPDATE_SUBSCRIPTION_BASKET_CUSTOMER_SUBSCRIPTION_ACTION } from 'Stores/types/subscriptionBasketActionsTypes';
import ModalInfo from 'Modals/ModalInfo';

import { openModal } from 'Plugins/potagerModals';

const QUESTIONS = {
  1: {
    title: 'Quelle est la cause principale de votre désabonnement ?',
    desc: 'Nous sommes tristes de vous voir partir et aimerions en connaître la raison pour nous améliorer. Parmi ces choix, lequel vous a poussé à stopper votre abonnement ? Merci beaucoup pour votre aide.',
    type: 'radio',
    choices: [
      {
        label: 'Le retrait de mon panier.',
        comment: {
          type: 'select',
          label: 'Voulez-vous nous dire pourquoi ?',
          choices: [
            { label: 'Le lieu n\'est pas pratique.' },
            { label: 'Le créneau n\'est pas pratique.' },
            { label: 'Autres.' },
          ],
        },
      },
      {
        label: 'La quantité des produits de mon panier.',
        comment: {
          type: 'select',
          label: 'Voulez-vous nous dire pourquoi ?',
          choices: [
            { label: 'Elle est trop importante.' },
            { label: 'Elle est trop faible.' },
            { label: 'Autres.' },
          ],
        },
      },
      {
        label: 'La qualité des produits.',
        comment: {
          type: 'select',
          label: 'Voulez-vous nous dire pourquoi ?',
          choices: [
            { label: 'Les produits manquent de goût.' },
            { label: 'Les produits manquent de fraîcheur.' },
            { label: 'Les produits sont abîmés.' },
            { label: 'Autres.' },
          ],
        },
      },
      {
        label: 'La composition des paniers.',
        comment: {
          type: 'select',
          label: 'Voulez-vous nous dire pourquoi ?',
          choices: [
            { label: 'Elle manque de variété.' },
            { label: 'Elle manque de produits régionaux.' },
            { label: 'Elle manque de produits Bio.' },
            { label: 'Autres.' },
          ],
        },
      },
      {
        label: 'Le tarif de mon panier.',
        comment: {
          type: 'select',
          label: 'Voulez-vous nous dire pourquoi ?',
          choices: [
            { label: 'Faible rapport qualité/prix.' },
            { label: 'Faible rapport quantité/prix.' },
            { label: 'Autres.' },
          ],
        },
      },
      {
        label: 'Le principe d\'abonnement.',
        comment: {
          type: 'select',
          label: 'Voulez-vous nous dire pourquoi ?',
          choices: [
            { label: 'Je préfère acheter mes produits à la carte.' },
            { label: 'La fréquence ne me convient pas.' },
            { label: 'Le fonctionnement est compliqué (annulation, pause, remplacement).' },
          ],
        },
      },
      {
        label: 'Je pars en vacances.',
      },
      {
        label: 'Je suis absent pour une longue durée.',
      },
      {
        label: 'Je déménage.',
      },
      {
        label: 'Le site et l\'app sont difficiles à utiliser.',
      },
      {
        label: 'Autres.',
        comment: {
          type: 'input',
        },
      },
    ],
  },
};

const CONFIG = {
  onSubmit: (ctx) => {
    const {
      savedChoices,
      store,
      router
    } = ctx;
    const reason = savedChoices?.[0]?.choices?.[0]?.label;
    const subReason = savedChoices?.[0]?.choices?.[0]?.comment;

    const launchConfirmModal = (orders) => {
      const text = orders.length > 1
        ? 'Vous avez plusieurs commandes en cours. Si vous suspendez votre abonnement, ces commandes, bénéficiant de vos avantages d\'abonné, seront automatiquement annulées. Que souhaitez-vous faire ?'
        : 'Vous avez une commande en cours. Si vous suspendez votre abonnement, cette commande, bénéficiant de vos avantages d\'abonné, sera automatiquement annulée. Que souhaitez-vous faire ?';

      openModal(ModalInfo, {
        title: 'Avertissement',
        text,
        closeBtnText: 'Confirmer la suspension',
        onClose: onSubmitSuspensionDef.bind(this, false),
        showCancel: true,
        cancelBtnText: 'Conserver mon abonnement',
      });
    };

    const takeSubscriptionBack = () => new Promise((resolve, reject) => {
      const regionId = store.getters['session/getRegionId'];
      const product = store.getters['user/getSubscription']?.product;

      if (regionId && product) {
        store
          .dispatch(`subscriptionBasket/${UPDATE_SUBSCRIPTION_BASKET_CUSTOMER_SUBSCRIPTION_ACTION}`,
            {
              regionId,
              product,
            })
          .then(() => {
            router.push(store.getters['tunnel/getStepRouteByName']('subscriptionSummary'));
            resolve();
          })
          .catch(() => {
            reject(new Error('Une erreur est survenue'));
          });
      } else {
        resolve();
      }
    });

    const sendNotification = () => {
      notify({
        type: 'success',
        title: 'Vous nous manquez déjà !',
        text: reason || subReason ? 'Merci de vos retours, votre abonnement a bien été suspendu.'
          : 'Votre abonnement a bien été suspendu.',
        data: {
          buttons: [{
            label: 'Je regrette mon choix',
            isLoading: UPDATE_SUBSCRIPTION_BASKET_CUSTOMER_SUBSCRIPTION_ACTION,
            onClick: takeSubscriptionBack,
            waitBeforeClosing: true,
          }],
        },
      });
    };

    const onSubmitSuspensionDef = (simulation = true) => {
      store.dispatch(`user/${REMOVE_SUBSCRIPTION_ACTION}`, {
        params: {
          customerUnsubscriptionReason: reason,
          customerUnsubscriptionSubReason: subReason,
        },
        simulation,
      })
        .then(({ data }) => {
          if (simulation) {
            if (data.data.orders.length) {
              launchConfirmModal(data.data.orders);
            } else {
              onSubmitSuspensionDef(false);
            }
          } else {

            sendNotification();
            router.push({ name: 'dashboard' });
          }
        });
    };

    onSubmitSuspensionDef();
  },
};

export default (app) => new Survey(QUESTIONS, CONFIG, app);
