<template>
  <potager-container class="enterprise__clients">

    <h2 class="enterprise__title">
      Ils nous font confiance
    </h2>
    <h3 class="enterprise__subtitle">
      Nous travaillons déjà avec plus de 2000 entreprises
    </h3>

    <potager-row>

      <template v-if="sort === 'categorized'">
        <potager-col
          v-for="(col, i) in categorized"
          :key="i"
          :class="[
            'enterprise__clients__group',
            'enterprise__clients__group--categorized',
          ]"
          col="4"
          bp1024="12">
          <potager-row class="enterprise__clients__list">
            <potager-col
              v-for="(src, name, j) in col.clients"
              :key="j"
              col="6"
              class="enterprise__clients__list__item">
              <img
                class="enterprise__clients__logo"
                :title="name"
                :alt="name"
                :src="src">
            </potager-col>
          </potager-row>
          <p class="enterprise__clients__baseline">
            {{ col.baseline }}
          </p>
        </potager-col>
      </template>

      <template v-else>
        <potager-col
          v-for="(src, name, j) in alphabetical"
          :key="j"
          :class="[
            'enterprise__clients__group',
            'enterprise__clients__group--alphabetical',
          ]"
          col="2"
          bp1024="3"
          bp640="6">
          <potager-row class="enterprise__clients__list">
            <potager-col class="enterprise__clients__list__item">
              <img
                class="enterprise__clients__logo"
                :title="name"
                :alt="name"
                :src="src">
            </potager-col>
          </potager-row>
        </potager-col>
        <potager-col
          :class="[
            'enterprise__clients__group',
            'enterprise__clients__group--alphabetical',
          ]"
          col="12"
          bp1024="12"
          bp640="12">
          <potager-row class="enterprise__clients__list">
            <potager-col class="enterprise__clients__list__item">
              <span class="enterprise__clients__list__item__more">...</span>
            </potager-col>
          </potager-row>
        </potager-col>
      </template>

    </potager-row>
  </potager-container>
</template>

<script>
import PotagerContainer from 'UI/PotagerContainer';
import PotagerRow from 'UI/PotagerRow';
import PotagerCol from 'UI/PotagerCol';

import aquaboulevard from 'Images/enterprise/clients/aquaboulevard.jpg';
import arte from 'Images/enterprise/clients/arte.jpg';
import ccigrenoble from 'Images/enterprise/clients/ccigrenoble.jpg';
import croixrougefrancaise from 'Images/enterprise/clients/croixrougefrancaise.jpg';
import decathlon from 'Images/enterprise/clients/decathlon.jpg';
import loreal from 'Images/enterprise/clients/loreal.jpg';
import meteofrance from 'Images/enterprise/clients/meteofrance.jpg';
import moulinex from 'Images/enterprise/clients/moulinex.jpg';
import opinel from 'Images/enterprise/clients/opinel.jpg';
import sanofi from 'Images/enterprise/clients/sanofi.jpg';
import somfy from 'Images/enterprise/clients/somfy.jpg';
import squarehabitat from 'Images/enterprise/clients/squarehabitat.jpg';

export default {

  components: {
    PotagerContainer,
    PotagerRow,
    PotagerCol,
  },

  props: {
    sort: {
      type: String,
      required: false,
      default: 'categorized', // categorized || alphabetical
    },
  },

  data: () => ({
    categorized: [
      {
        baseline: 'Petites ou moyennes entreprises',
        clients: {
          opinel,
          aquaboulevard,
          squarehabitat,
          ccigrenoble,
        },
      },
      {
        baseline: 'Entreprises de taille moyenne',
        clients: {
          arte,
          meteofrance,
          moulinex,
          somfy,
        },
      },
      {
        baseline: 'Grandes entreprises',
        clients: {
          loreal,
          decathlon,
          sanofi,
          croixrougefrancaise,
        },
      },
    ],
  }),

};
</script>
