import { mapActions, mapGetters } from 'vuex';
import { UPDATE_WOOS_SCRIPT_LOADED } from 'Stores/types/mapMutationsTypes';
import { RESET_MAP } from 'Stores/types/mapActionsTypes';

export default {
  data() {
    return {
      loadedResources: {
        multisearchWidget: false,
        map: false,
        style: false,
      },
    };
  },

  computed: {
    ...mapGetters('map', ['getWoosScriptIsLoaded']),
  },

  methods: {
    ...mapActions('map', {
      RESET_MAP,
    }),
    loadResource(url, resourceName, isStyle = false) {
      return new Promise((resolve, reject) => {
        if (!this.loadedResources[resourceName]) {
          let resource;
          if (isStyle) {
            resource = document.createElement('link');
            resource.rel = 'stylesheet';
            resource.href = url;
          } else {
            resource = document.createElement('script');
            resource.src = url;
          }
          resource.onload = () => {
            this.loadedResources[resourceName] = true;
            resolve();
          };
          resource.onerror = () => {
            reject(new Error(`Failed to load the resource ${resourceName}`));
          };
          document.head.append(resource);
        } else {
          resolve();
        }
      });
    },
    initializeMap() {
      return new Promise((resolve, reject) => {
        const url1 = 'https://sdk.woosmap.com/multisearch/multisearch.js';
        const url2 = `https://sdk.woosmap.com/map/map.js?key=${import.meta.env.VITE_WOOS_MAP_KEY}&callback=initMap`;
        const urlStyle = 'https://sdk.woosmap.com/localities/style.2.0.css';

        Promise.all([
          this.loadResource(url1, 'multisearchWidget'),
          this.loadResource(url2, 'map'),
          this.loadResource(urlStyle, 'style', true),
        ])
          .then(() => {
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },

  mounted() {
    if (this.getWoosScriptIsLoaded === undefined) {
      this.$store.commit(`map/${UPDATE_WOOS_SCRIPT_LOADED}`, false);

      this.initializeMap()
        .then(() => {
          this.$store.commit(`map/${UPDATE_WOOS_SCRIPT_LOADED}`, true);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  },
};
