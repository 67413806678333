<template>
  <div class="nps-survey w-full">
    <h1 class="font-black text-2xl text-british-racing mb-6">
      Quelle est la probabilité que vous recommandiez Potager City&nbsp;?
    </h1>

    <p class="mb-6">
      Votre avis est essentiel pour améliorer continuellement notre service et mieux répondre à vos besoins.
    </p>

    <potager-form
      id="nps-survey"
      @on-submit="onSubmit">
      <div class="flex mb-3">
        <potager-radio
          v-for="npsValue in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
          :key="`nps-${npsValue}`"
          :class="[
            'nps-survey__radio',
            'py-3 rounded font-bold mr-1 last:mr-0 mt-0 relative flex-1',
            {
              'bg-light-red': npsValue < 7,
              'bg-yellow-crayola': npsValue >= 7 && npsValue < 9,
              'bg-beryl-green': npsValue >= 9,
            }
          ]"
          :disabled="isLoading"
          :label="`Notez ${npsValue}`"
          :name="`nps-${npsValue}`"
          :model-value="npsValue === nps"
          @update:model-value="nps = npsValue">
          {{ npsValue }}
        </potager-radio>
      </div>

      <div class="flex justify-between items-center w-full mb-6">
        <div class="flex items-center font-bold text-xs">
          <icon-smiley-sad class="text-lg text-maximum-red mr-2" />
          1 - Peu probable
        </div>

        <div class="flex items-center font-bold text-xs">
          10 - Très probable
          <icon-smiley-happy class="text-lg text-go-green ml-2" />
        </div>
      </div>

      <potager-button
        :disabled="!nps"
        :is-loading="isLoading"
        theme="forest"
        type="submit">
        Valider
      </potager-button>
    </potager-form>
  </div>
</template>

<script>
import PotagerRadio from 'UI/Form/PotagerRadio';
import PotagerForm from 'UI/Form/PotagerForm';

import IconSmileyHappy from 'Icons/bold/IconSmileyHappy';
import IconSmileySad from 'Icons/bold/IconSmileySad';
import PotagerButton from 'UI/PotagerButton';

export const SEND_NPS = 'SEND_NPS';

export default {
  components: {
    PotagerButton,
    PotagerForm,
    PotagerRadio,
    IconSmileyHappy,
    IconSmileySad,
  },

  data() {
    return {
      nps: null,
    };
  },

  computed: {
    isLoading() {
      return this.$wait.is(SEND_NPS);
    },
    token() {
      return this.$route.params.token;
    },
  },

  methods: {
    onSubmit() {
      this.$wait.start(SEND_NPS);

      this.$api.app.sendNpsScore(this.token, this.nps)
        .then(() => {
          this.$notify({
            type: 'success',
            title: 'Merci pour votre retour !',
            text: this.nps >= 7
              ? 'Nous sommes ravis que vous soyez satisfait de nos services. À très bientôt !'
              : 'Nous sommes désolés que vous n\'ayez pas été satisfait de nos services. N\'hésitez pas à <a href="https://potagercity.force.com/serviceclient/s/contactsupport" class="underline">nous contacter</a> pour nous en dire plus.',
          });

          this.$router.push({ name: 'nps-thanks' });
        })
        .catch((err) => {
          const message = (err?.globals && err.globals[0]?.message) || 'Une erreur est survenue, veuillez réessayer plus tard ou contacter <a href="https://potagercity.force.com/serviceclient/s/contactsupport" class="underline">notre service client</a>';
          this.$notify({
            type: 'error',
            title: 'Houspomme ! On a un problème',
            text: `${message}<br><small>Code erreur : ${err?.code || err?.message || 'N/A'}</small>`,
          });
        })
        .finally(() => {
          this.$wait.end(SEND_NPS);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.nps-survey {
  $this: &;

  &__radio {
    @include outlineFocus();

    > :deep() * {
      @apply flex-col items-center;
    }

    :deep().potager-checkbox {
      &__input-wrapper {
        @apply mr-0 mb-1.5;
        width: 1rem;
        height: 1rem;
        max-width: 1rem;
        max-height: 1rem;
        min-width: 1rem;
        min-height: 1rem;
      }

      &__label {
        @apply after:absolute after:inset-0;
      }
    }
  }
}
</style>
