<template>
  <potager-container>
    <potager-picture
      background
      placeholder="bg-beryl-green"
      class="w-full rounded-xl overflow-hidden py-20 bp1024:py-12 bp768:py-10 relative"
      :src="src">
      <potager-container>
        <potager-row>
          <potager-col
            col="10"
            offset="1"
            class="content-background-picture__text flex items-center justify-center text-3xl.5 bp768:text-xl.5 font-extrabold text-white text-center py-0">
            <slot />
          </potager-col>
        </potager-row>
      </potager-container>
    </potager-picture>
  </potager-container>
</template>

<script>
import PotagerContainer from 'UI/PotagerContainer';
import PotagerRow from 'UI/PotagerRow';
import PotagerCol from 'UI/PotagerCol';
import PotagerPicture from 'UI/PotagerPicture';

export default {
  name: 'ContentBackgroundPicture',
  components: {
    PotagerRow, PotagerCol, PotagerContainer, PotagerPicture,
  },
  props: {
    src: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.content-background-picture {
  &__text {
    text-shadow: 0 0 60px rgba(0, 0, 0, 1);
  }
}
</style>
