<template>
  <potager-modal>
    <template #title>
      Information
    </template>

    <template #body>
      <cms is-small>
        <p>
          Potager City traite vos données personnelles aux fins de vous fournir les services de Potager City, vous adresser des informations sur les activités et les services de Potager City et de vous proposer des offres adaptées à vos centres intérêts.
        </p>
        <p>
          Vous pouvez demander à vous opposer à tout moment au traitement de vos données personnelles, et notamment à recevoir des informations de la part de Potager City en utilisant le lien de désabonnement figurant dans chaque message provenant de Potager City, demander à accéder, à faire rectifier, à faire supprimer les informations vous concernant, ou à demander la portabilité de vos données, dans les cas prévus par la loi, par Potager City. Pour exercer vos droits auprès de Potager City, écrivez à <a
            href="mailto:framboise@potagercity.fr"
            target="_blank">framboise@potagercity.fr</a> ou directement au sein du <potager-link
              :to="{ name: 'contact' }"
              target="_blank">formulaire de contact</potager-link>. Pour en savoir plus sur le traitement de vos données personnelles par Potager City et sur vos droits, consultez notre <potager-link
                :to="{ name: 'confidentialite-et-donnees-personnelles' }"
                target="_blank">politique de protection des données personnelles</potager-link>. Les informations identifiées par un astérisque sont obligatoires.
        </p>
      </cms>
    </template>
  </potager-modal>
</template>

<script>
import PotagerModal from 'UI/PotagerModal';
import Cms from 'Components/cms/Cms';

export default {

  components: {
    PotagerModal,
    Cms,
  },

};
</script>
