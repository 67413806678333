<template>
  <div
    :class="[
      'max-w-10/12 rounded-xl px-8',
      'bp1024:bg-opacity-0 bp1024:px-0',
      `highlights-slider--${theme}`,
      {
        'shadow-custom-light': theme === 'white'
      }
    ]">
    <potager-slider
        name="highlights-slider"
        :key="highlights.length"
        :class="[
        'highlights-slider__slider rounded-lg mb-0 py-4 overflow-hidden',
        'bp768:px-4',
      ]"
        :items="highlights"
        :settings="{
        autoplay: { delay: 3000 },
        allowTouchMove: false,
      }"
      :slides-per-view="{
              default: 1,
              768: 1,
              1024: 3,
            }">
      <template #default="vProps">
        <div
          :class="[
            'flex justify-center items-center max-w-1024 h-full',
            'bp1024:max-w-full'
          ]">
          <highlights-icon
            :icon="vProps.item.icon"
            class="m-1" />

          <p class="text-sm.5 font-medium leading-5 tracking-normal">
            {{ vProps.item.description }}
          </p>
        </div>
      </template>
    </potager-slider>
  </div>
</template>

<script>
import PotagerSlider from 'UI/PotagerSlider';
import { propsBuilder } from 'UI/Tools';
import HighlightsIcon from './HighlightsIcon';
import UpsellingSlider from 'Components/sliders/UpsellingSlider.vue';

export const props = {
  theme: {
    type: String,
    required: false,
    default: 'bianca',
    validator: (val) => ['white', 'bianca'].includes(val),
  },
  highlights: {
    type: Array,
    required: false,
    default: () => [
      {
        icon: '🍊',
        description: 'Une attention quotidienne pour vos collaborateurs.',
      },
      {
        icon: '🌱',
        description: 'Des produits vraiment bons et de saison.',
      },
      {
        icon: '😎',
        description: 'Un service sur-mesure et sans engagement.',
      },
    ],
  },
};

export default {
  props: propsBuilder(props),

  components: {
    UpsellingSlider,
    PotagerSlider,
    HighlightsIcon,
  },
};
</script>

<style lang="scss" scoped>
.highlights-slider {
  $this: &;

  &--white {
    @apply bg-white;

    @include bp1024() {
      #{$this} {
        &__slider {
          @apply bg-white;
        }
      }
    }
  }

  &--bianca {
    @apply bg-bianca;

    @include bp1024() {
      #{$this} {
        &__slider {
          @apply bg-bianca;
        }
      }
    }
  }
}
</style>
