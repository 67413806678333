<template>
  <div class="carnet">
    <potager-button
      :class="{
        'carnet__btn': true,
        'carnet__btn--added': isAdded,
      }"
      :is-loading="isLoading"
      :to="!isLoggedIn ? { name: 'login' } : null"
      is-circle
      prevent
      theme="white"
      @onClick="toggleRecipe">
      <template #icon>
        <icon-love-it />
      </template>
    </potager-button>
    <span
      v-if="showLabel"
      class="carnet__label">
      {{ isAdded ? 'Ajoutée' : 'Ajouter' }} à mon
      <router-link :to="{ name: 'mon-compte_recipes' }">
        carnet de recettes
      </router-link>
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { ADD_RECIPE_ACTION, REMOVE_RECIPE_ACTION } from 'Stores/types/userActionsTypes';
import IconLoveIt from 'Icons/bold/IconLoveIt';
import PotagerButton from 'UI/PotagerButton';
import find from 'lodash/find';

export default {

  components: {
    IconLoveIt,
    PotagerButton,
  },

  props: {
    id: {
      type: Number,
      required: true,
    },
    showLabel: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  computed: {
    ...mapGetters('user', [
      'getRecipes',
    ]),
    ...mapGetters('session', [
      'isLoggedIn',
    ]),
    isAdded() {
      return find(this.getRecipes, (o) => o.id === this.id);
    },
    isLoading() {
      return this.$wait.is([
        `${ADD_RECIPE_ACTION}_${this.id}`,
        `${REMOVE_RECIPE_ACTION}_${this.id}`,
      ]);
    },
  },

  methods: {
    toggleRecipe() {
      if (this.isLoggedIn) {
        if (this.isAdded) {
          this.removeRecipeFromCarnet();
        } else {
          this.addRecipeToCarnet();
        }
      }
    },
    removeRecipeFromCarnet() {
      this.$store.dispatch(`user/${REMOVE_RECIPE_ACTION}`, {
        id: this.id,
      });
    },
    addRecipeToCarnet() {
      this.$store.dispatch(`user/${ADD_RECIPE_ACTION}`, {
        id: this.id,
      });
    },
  },

};
</script>
