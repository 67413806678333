<template>
  <panel
    v-show="isVisible"
    ref="panel"
    :back-route="backRoute"
    :is-loading="isLoading"
    footer-extra-classes="p-0"
    title="Point de livraison">
    <template #header>
      <div class="text-center">
        <dlp-search-input
          :context="context"
          class="mb-4"
          @onSearch="onSelect($event.result)" />

        <div class="flex items-center">
          <dlp-filters
            :condensed="$mq.bp768"
            class="w-full" />

          <dlp-list-switcher class="ml-4" />
        </div>
      </div>
    </template>

    <div class="bg-bianca px-6">
      <dlp-list
        v-if="!isLoading"
        :context="context"
        @onSelect="onSelect" />
    </div>

    <template
      v-if="isLADEnable && !isLoading"
      #footer>
      <potager-button
        :class="[
          'w-full bg-beryl-green rounded-none',
          'text-british-racing font-bold',
          'px-6 py-2',
        ]"
        @onClick="redirectHomeDelivery">
        <div class="flex justify-between items-center">
          Livraison à domicile disponible&nbsp;!

          <div class="w-10 h-10 border border-charcoal rounded-full flex items-center justify-center">
            <IconArrowRight />
          </div>
        </div>
      </potager-button>
    </template>
  </panel>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { UPDATE_SELECTED_DELIVERY_POINT, TOGGLE_LIST_VISIBILITY } from 'Stores/types/mapMutationsTypes';
import { MAP_STORE_LOADERS } from 'Classes/Loaders';

import PotagerButton from 'UI/PotagerButton';

import Panel from 'Components/panel/Panel';
import DlpSearchInput from 'Components/deliveryPoint/DlpSearchInput';
import DlpFilters from 'Components/deliveryPoint/DlpFilters';

import DlpListSwitcher from 'Components/deliveryPoint/DlpListSwitcher';
import DlpList from 'Components/deliveryPoint/DlpList';

import IconArrowRight from 'Icons/regular/IconArrowRight';
import { getDlpSearchRoute } from 'Classes/utils/DlpRouteUtils';
import { DELIVERY_HOME_TYPE } from 'Classes/workflow/TunnelTypesConstants';

import MobileAppMixin from 'Mixins/MobileAppMixin';

export default {
  components: {
    DlpFilters,
    DlpList,
    DlpListSwitcher,
    DlpSearchInput,
    IconArrowRight,
    Panel,
    PotagerButton,
  },

  props: {
    context: {
      type: String,
      required: true,
    },
    backRoute: {
      type: [String, Object],
      default: undefined,
    },
  },

  mixins: [
    MobileAppMixin,
  ],

  emits: ['onDlpSelect', 'onHomeDeliveryRedirect'],

  watch: {
    getMapPublicSearchResult: {
      handler() {
        this.$refs.panel?.scrollToTop();
      },
      deep: true,
    },
  },

  computed: {
    ...mapGetters('homeDelivery', [
      'isLADEnable',
    ]),
    ...mapGetters('map', [
      'getSelectedDeliveryPoint',
      'getMapPublicSearchResult',
      'isListVisible',
      'getWoosMap',
    ]),
    ...mapGetters('session', [
      'getRegionId',
    ]),
    isLoading() {
      return this.$wait.is(MAP_STORE_LOADERS);
    },
    isVisible() {
      return !this.$mq.bp768 || this.isListVisible;
    },
    regionId() {
      return this.getMapPublicSearchResult?.[0]?.regionId || this.getRegionId;
    },
  },

  methods: {
    ...mapMutations('map', {
      UPDATE_SELECTED_DELIVERY_POINT,
      TOGGLE_LIST_VISIBILITY,
    }),
    selectDlp(deliveryPoint) {
      this.UPDATE_SELECTED_DELIVERY_POINT(deliveryPoint);
    },
    onSelect(deliveryPoint) {
      // check if it's a store or an address
      if (deliveryPoint.groupDeliveryDay) {
        this.selectDlp(deliveryPoint);
        this.$emit('onDlpSelect', deliveryPoint);
      }
    },
    redirectHomeDelivery() {
      if (this.isInAppWebView) {
        this.redirectWebApp({
          redirect: 'homeDelivery',
          regionId: this.regionId,
        });
      } else {
        this.$emit('onHomeDeliveryRedirect', this.regionId);
      }
    },
  },

  mounted() {
    // in desktop we unselect the dlp by default
    // but in mobile we want to show the map with the selected dlp
    if (!this.$mq.bp768) {
      this.selectDlp(null);
    } else if (this.getSelectedDeliveryPoint && this.isVisible) {
      this.TOGGLE_LIST_VISIBILITY();
    }
  },
};
</script>
