<template>
  <potager-slider
    name="editorial-slider"
    :slides-per-view="{
      default: 1,
      768: 4,
      1024: 6,
    }"
    :items="section.items"
    overflow-visible>
    <template #default="{ item }">
      <card-content
        :content="item"
        condensed
        open-in-flying-page
        :inline="false" />
    </template>
  </potager-slider>
</template>

<script>
import PotagerSlider from 'UI/PotagerSlider';
import CardContent from 'Components/cards/CardContent';

export default {

  components: {
    PotagerSlider,
    CardContent,
  },

  props: {
    section: {
      type: Object,
      required: true,
    },
  },

};
</script>
