<template>
  <potager-checkbox
    v-bind="$props"
    v-model="value"
    type="radio">
    <slot />
  </potager-checkbox>
</template>

<script>
import { propsBuilder } from 'UI/Tools';
import PotagerCheckbox, { props as PotagerCheckboxProps } from 'UI/Form/PotagerCheckbox';

const { type, ...filteredProps } = PotagerCheckboxProps;
export const props = filteredProps;

export default {

  components: {
    PotagerCheckbox,
  },

  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },

  props: propsBuilder(props),
};
</script>
