export const test = ({ rootGetters, params, expectedValue }) => {
  const ABTestVariante = parseInt(rootGetters['ABTest/getABTestValueByName'](params.key), 10);

  return ABTestVariante === expectedValue;
};

export default {
  type: 'abTest', // match what you'll use in constraint property in TunnelSteps.js
  // values: [false, true], // list of potential values.
  test, // function that will be called to test the constraint
};
