const createScriptTag = (src, charset, dataDomainScript) => {
  const script = document.createElement('script');
  script.src = src;
  script.type = 'text/javascript';
  if (charset) script.charset = charset;
  if (dataDomainScript) script.dataset.domainScript = dataDomainScript;
  return script;
};

if (import.meta.env.VITE_GOOGLE_TAG_ID) {
  // Gtm
  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js'
    });
    const f = d.getElementsByTagName(s)[0];
    const j = d.createElement(s);
    const
      dl = l != 'dataLayer' ? `&l=${l}` : '';
    j.async = true;
    j.src = `//www.googletagmanager.com/gtm.js?id=${i}${dl}`;
    f.parentNode.insertBefore(j, f);
  }(window, document, 'script', 'dataLayer', import.meta.env.VITE_GOOGLE_TAG_ID));
}

// Create script tags
if (import.meta.env.VITE_ONETRUST_DOMAIN) {
  // Début de la mention OneTrust de consentement aux cookies du domaine : potagercity.fr
  const dynamicKey = import.meta.env.VITE_ONETRUST_DOMAIN;
  const script1 = document.createElement('script');
  script1.type = 'text/javascript';
  script1.src = `https://cdn.cookielaw.org/consent/${dynamicKey}/OtAutoBlock.js`;

  const script2 = document.createElement('script');
  script2.type = 'text/javascript';
  script2.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
  script2.charset = 'UTF-8';
  script2.dataset.domainScript = `${dynamicKey}`;

  // Ajouter les scripts au <head>
  document.head.appendChild(script1);
  document.head.appendChild(script2);

  let OTPrivacyUpdate = 0;
  window.OptanonWrapper = function () {
    // Modal principal : Bonjour et bienvenue
    // Passage du bouton "Tout refuser" dans le header & màj du label
    const popin = document.getElementById('onetrust-policy-title');
    const rejectAll = document.getElementById('onetrust-reject-all-handler');
    if (popin && rejectAll) {
      rejectAll.innerHTML = 'Continuer sans accepter';
      popin.appendChild(rejectAll);
    }

    // Modal secondaire : Paramétrer les cookies
    // Passage du bouton "Tout refuser" à côté du bouton "Tout accepter"
    const reject = document.querySelector('.ot-pc-refuse-all-handler');
    const content = document.getElementById('ot-pc-content');
    const accept = document.getElementById('accept-recommended-btn-handler');
    if (reject && content && accept) content.insertBefore(reject, accept);

    Optanon.OnConsentChanged((e) => {
      OTPrivacyUpdate += 1;
    });
  };

  // Fin de la mention OneTrust de consentement aux cookies du domaine : potagercity.fr -->
}
