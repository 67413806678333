<template>
  <!-- Section CB. Si le panier est gratuit, on affiche pas -->
  <div
    v-if="total > 0"
    class="panel-section">
    <!-- IF ABO -->
    <template v-if="isSubscription">
      <p class="panel-section__p">
        Afin de faciliter vos achats,
        <b>
          le montant de {{ formatPrice(total) }}
          sera débité automatiquement
        </b>
        le {{ getDebitDate }}, sur le mode de paiement
        {{ willBeMerge ? 'suivant' : 'associé à votre abonnement' }} :
      </p>

      <payment-card
        v-if="getDefaultCard"
        :card="getDefaultCard"
        class="panel-section__line"
        standalone />
    </template>

    <!-- IF NO ABO -->
    <template v-else>
      <p class="panel-section__subtitle">
        Cartes de paiement
      </p>

      <payment-card
        v-if="getDefaultCard"
        :card="getDefaultCard"
        class="panel-section__line"
        standalone />

      <potager-navigator
        :to="{
          name: 'basket_payment_manage_card',
          params: { cardId },
        }"
        class="mt-4">
        Ajouter / changer de carte
      </potager-navigator>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { formatPrice, getTotalOrder } from 'Classes/utils/PricesUtils';

import PaymentCard from 'Components/paymentCard/PaymentCard';

import PotagerNavigator from 'UI/PotagerNavigator';

export default {

  components: {
    PaymentCard,
    PotagerNavigator,
  },

  props: {
    cardId: {
      type: [Number, String],
      required: false,
      default: undefined,
    },
    linkedCard: {
      type: Object,
      required: false,
      default: undefined,
    },
  },

  computed: {
    ...mapGetters('user', [
      'getCardById',
    ]),
    ...mapGetters('basket', [
      'getBasket',
      'isSubscription',
      'getDebitDate',
      'getLinkedOrder',
      'getSimulatedOrder',
    ]),
    willBeMerge() {
      return this.getLinkedOrder?.id;
    },
    getDefaultCard() {
      if (!this.cardId) return null;
      return this.getCardById(this.cardId);
    },
    total() {
      return getTotalOrder(this.getSimulatedOrder || this.getBasket);
    },
  },

  methods: {
    formatPrice,
  },
};
</script>
