<template>
  <potager-modal>
    <template #body>
      <span v-html="text" />

      <div class="modal-container__out-of-stock-list">
        <p class="modal-container__out-of-stock-list__subtitle">
          {{ pluralize(products.length, 'panier épuisé', 'paniers épuisés') }} :
        </p>
        <p
          v-for="(product, index) in getGroupedProducts"
          :key="index"
          class="modal-container__out-of-stock-list__box">
          <span class="modal-container__out-of-stock-list__box__name">
            {{ product[0].boxName }}
            <b>
              x{{ product.length }}
            </b>
          </span>
          <span class="modal-container__out-of-stock-list__box__price">
            -{{ formatPrice(product[0].price * product.length) }}
          </span>
        </p>
      </div>
    </template>

    <template #footer>
      <potager-button
        :full-width="true"
        :is-loading="$wait.is(isLoading)"
        label="Voir mon panier"
        @onClick="close" />
    </template>
  </potager-modal>
</template>

<script>
import { pluralize } from 'Classes/utils/WordingUtils';
import { formatPrice } from 'Classes/utils/PricesUtils';

import PotagerModal from 'UI/PotagerModal';
import PotagerButton from 'UI/PotagerButton';

import groupBy from 'lodash/groupBy';

export default {

  components: {
    PotagerModal,
    PotagerButton,
  },

  props: {
    text: {
      type: String,
      required: false,
      default: '',
    },
    onCloseRedirect: {
      type: Object,
      required: false,
      default: null,
    },
    onClose: {
      type: Function,
      required: false,
      default: () => {
      },
    },
    isLoading: {
      type: [String, Array],
      required: false,
      default: '',
    },
    products: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  computed: {
    getGroupedProducts() {
      return groupBy(this.products, 'boxId');
    },
  },

  methods: {
    pluralize,
    formatPrice,
    close() {
      Promise.resolve(this.onClose.bind(this)())
        .then(() => {
          this.$modal.close();

          if (this.onCloseRedirect) {
            this.$router.push(this.onCloseRedirect)
              .catch(() => {
              });
          }
        })
        .catch(() => {
        });
    },
  },

};
</script>
