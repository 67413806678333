$
<template>
  <component
    :is="isInteractive ? 'potager-button' : 'div'"
    :class="[
      'mb-2 last:mb-0 w-full',
      'flex justify-between relative',
      'p-2 text-left',
      className,
      {
        'rounded-lg bg-white hover:bg-beryl-green transition text-charcoal': isInteractive,
        'overflow-hidden': !allowOverflow,
        'cursor-pointer': isSelectable,
        'pointer-events-none': isLock || isDisabled,
        'opacity-50': isDisabled,
        'py-2': !isInteractive,
        'bg-beryl-green': isSelected,
      },
    ]"
    :to="to"
    theme="transparent"
    @click="onClick"
    @onClick="onClick">
    <potager-badge
      v-if="$slots.badge"
      class="absolute uppercase left-4 top-0 transform -translate-y-1/2"
      size="small"
      theme="sandy-beach">
      <slot name="badge" />
    </potager-badge>

    <div class="flex w-full items-center">
      <div
        :class="[
          'potager-card-button__wrapper flex py-2 flex-auto',
          {
            'items-center': !!item?.name,
            'self-start pl-2': isInteractive,
          }
        ]">
        <potager-radio
          v-if="isSelectable"
          :is-loading="isLoading"
          :model-value="isSelected" />

        <div
          :class="[
            'w-full font-bold',
            {'text-british-racing': isSelected},
            {'text-british-racing flex items-center': !item},
          ]">
          <template v-if="item?.name || $slots.name">
            <p class="mb-2 last:mb-0">
              <slot name="name">
                {{ item.name }}
              </slot>
            </p>
          </template>

          <template v-if="item?.supplier">
            <p class="text-xs.5 italic mb-2">
              <span class="font-semibold">{{ getSupplierName }}</span>{{ getOrigin }}
            </p>
          </template>

          <template v-if="getProductQuantity(item)">
            <p class="text-xs.5 font-bold">
              {{ getProductQuantity(item) }}
            </p>
          </template>

          <slot />
        </div>
      </div>

      <div
        v-if="getImage"
        class="relative">
        <div class="potager-card-button__image pb-full relative rounded-lg overflow-hidden bg-white-smoke">
          <potager-picture
            :src="getImage"
            background
            class="h-full w-full top-0 left-0 absolute"
            placeholder />
        </div>

        <potager-badge
          v-if="item?.isBio || isBio"
          class="absolute top-2 left-1/2 transform -translate-x-1/2 z-10 font-extrabold whitespace-nowrap"
          size="small"
          standalone
          theme="forest">
          <template #icon>
            <icon-ptcy-bio />
          </template>
          Bio
        </potager-badge>
      </div>
    </div>
  </component>
</template>

<script>
import PotagerPicture from 'UI/PotagerPicture';
import PotagerBadge from 'UI/PotagerBadge';
import PotagerButton from 'UI/PotagerButton';
import PotagerRadio from 'UI/Form/PotagerRadio';
import IconPtcyBio from 'Icons/regular/IconPtcyBio';

import { getProductQuantity } from 'Classes/utils/ProductUtils';

export default {

  components: {
    PotagerButton,
    PotagerPicture,
    PotagerBadge,
    PotagerRadio,
    IconPtcyBio,
  },

  props: {
    to: {
      type: [Object, String],
      required: false,
      default: null,
    },
    item: {
      type: Object,
      required: false,
      default: undefined,
    },
    image: {
      type: String,
      required: false,
      default: null,
    },
    isBio: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    isSelectable: {
      type: Boolean,
      required: false,
      default: false,
    },
    isClickable: {
      type: Boolean,
      required: false,
      default: false,
    },
    isSelected: {
      type: Boolean,
      required: false,
      default: false,
    },
    isLock: {
      type: Boolean,
      required: false,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    className: {
      type: String,
      required: false,
      default: '',
    },
    allowOverflow: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    getImage() {
      return this.item?.image || this.image;
    },
    getSupplierName() {
      return this.item?.supplier ? this.item.supplier.substituteName || this.item.supplier.firstname : null;
    },
    getOrigin() {
      return this.item?.origin ? `, ${this.item.origin}` : '';
    },
    isInteractive() {
      return this.isClickable || this.to || this.isSelectable;
    },
  },

  methods: {
    getProductQuantity,
    onClick() {
      if (!this.isLock && !this.isDisabled) this.$emit('onClick');
    },
  },

};
</script>

<style lang="scss" scoped>
.potager-card-button {
  &__wrapper {
    min-height: 2.25rem;
  }

  &__image {
    width: 84px;
  }
}
</style>
