import SponsorshipPage from 'Pages/sponsorship/SponsorshipPage';

export default {
  name: 'sponsorship',
  path: '/parrainage',
  alias: '/parrainage/demarrer',
  meta: {
    scrollToTop: true,
    hideNewsletter: true,
  },
  component: SponsorshipPage,
};
