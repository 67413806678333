<template>
  <tunnel-body class="text-center">
    <h1 class="text-xl font-extrabold mb-3 mx-10">
      Votre éligibilité
    </h1>

    <p
      class="mb-10"
      v-html="LADMessage" />

    <dlp-search-home
      class="mb-8"
      @onSubmit="onSubmit" />
  </tunnel-body>
</template>

<script>
import { mapGetters } from 'vuex';

import { LADWording } from 'Classes/Constants';

import DlpSearchHome from 'Components/deliveryPoint/step2/DlpSearchHome';
import TunnelBody from 'Components/tunnel/TunnelBody';
import { Context } from 'potagerlogic/dist/Enums/Context';

export default {
  props: {
    context: {
      type: String,
      default: Context.SubscriptionBasket,
    },
  },

  components: {
    TunnelBody,
    DlpSearchHome,
  },

  computed: {
    ...mapGetters('session', [
      'getRegionId',
    ]),
    ...mapGetters('tunnel', [
      'getNextStepRoute',
    ]),
    ...mapGetters('homeDelivery', [
      'isLADEnable',
    ]),
    LADMessage() {
      if (this.isLADEnable) {
        if (LADWording[this.getRegionId]) {
          return LADWording[this.getRegionId].libelle;
        }
        return LADWording.default.libelle;
      }
      return undefined;
    },
  },

  methods: {
    onSubmit() {
      this.$router.push(this.getNextStepRoute());
    },
  },
};
</script>
