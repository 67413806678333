<template>
  <potager-container class="video-frame-wrapper">
    <potager-background
      class="video-frame"
      :src="poster"
      placeholder
      @click="launchVideo">
      <p class="video-frame__title">
        {{ title }}
      </p>

      <button
        v-wave
        aria-label="Play"
        class="video-frame__play"
        @click.stop="launchVideo" />
    </potager-background>
  </potager-container>
</template>

<script>
import PotagerContainer from 'UI/PotagerContainer';
import PotagerBackground from 'UI/PotagerBackground';

import ModalVideoPromotional from 'Modals/ModalVideoPromotional';

export default {

  components: {
    PotagerContainer,
    PotagerBackground,
  },

  props: {
    title: {
      type: String,
      required: false,
      default: '30 sec pour comprendre Potager City !',
    },
    poster: {
      type: String,
      required: false,
      default: 'background-mission-video',
    },
    videoId: {
      type: String,
      required: false,
      default: 'zPX7o9qzC8U',
    },
  },

  methods: {
    launchVideo() {
      this.$modal.open(ModalVideoPromotional, {
        videoId: this.videoId,
      });
    },
  },

};
</script>

<style lang="scss">
.video-frame {
  justify-content: center;
  position: relative;
  z-index: 2;
  border-radius: .625rem;
  text-align: center;
  color: $white;
  margin-bottom: 5.5rem;
  padding: 2.75rem 1.5rem;
  cursor: pointer;
  min-height: 20rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $bianca;

  @include bp768() {
    min-height: 16rem;
  }

  &-wrapper {
    position: relative;
    z-index: 2;
  }

  &__title {
    @apply text-4xl;
    line-height: 1em;
    margin-bottom: 1rem;
    color: $white;
    font-weight: $weightExtrabold;
    text-shadow: 0 0 .5rem rgba($black, .5);
    max-width: 30rem;

    @include bp640() {
      @apply text-xl.5;
    }
  }

  &__play {
    width: 4.375rem;
    height: 4.375rem;
    background: rgba($white, .25);
    font-size: 0; line-height: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: none;
    padding: 0;
    transition: background .25s;
    @include disableTap();

    &:before {
      content: '';
      border: 0 solid transparent;
      border-bottom-width: .9rem;
      border-top-width: .9rem;
      border-left: 1.5rem solid $white;
      transform: scale(.8);
      margin-left: .25rem;
      transition: transform .25s;
    }

    &:hover {
      background: rgba($black, .6);
      &:before { transform: scale(1); }
    }
  }

}
</style>
