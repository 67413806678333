// set a this.$wait in the Vue instance that is the wait store getters

export default {
  install: (app, store) => {
    app.config.globalProperties.$wait = {
      is: (waiter) => store.getters['wait/is'](waiter),
      any: () => store.getters['wait/any'],
      percent: (waiter) => store.getters['wait/percent'](waiter),
      start: (waiter) => store.dispatch('wait/start', waiter),
      end: (waiter) => store.dispatch('wait/end', waiter),
    };
  },
};
