<template>
  <div
    :class="{
      'card-v2__pic rounded-lg overflow-hidden': true,
      'animate-pulse': skeleton,
      'card-v2__pic--gradient': gradient,
      'w-1/2 mr-3': landscape,
      'mb-3': !landscape,
    }"
    :style="getStyles">
    <potager-picture
      v-if="src"
      :extra-img-class="extraImgClass"
      :no-lazy-load="noLazyLoad"
      :alt="getAlt"
      :src="src"
      class="card-v2__pic__img"
      placeholder
      @onLoaded="$emit('onLoaded')" />

    <div
      v-else
      class="card-v2__pic__img" />

    <slot />

    <div
      v-if="$slots.title"
      class="card-v2__pic__title">
      <slot name="title" />
    </div>
  </div>
</template>

<script>
import PotagerPicture from 'UI/PotagerPicture';

export default {

  components: {
    PotagerPicture,
  },

  props: {
    alt: {
      type: String,
      required: false,
      default: undefined,
    },
    extraImgClass: {
      type: String,
      required: false,
      default: '',
    },
    gradient: {
      type: Boolean,
      required: false,
      default: false,
    },
    noLazyLoad: {
      type: Boolean,
      required: false,
      default: false,
    },
    ratio: {
      type: String,
      required: false,
      default: '1:1',
    },
    src: {
      type: [Object, String],
      required: false,
      default: null,
    },
    landscape: {
      type: Boolean,
      required: false,
      default: false,
    },
    skeleton: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    getStyles() {
      if (this.ratio.includes(':')) {
        const ratio = this.ratio.split(':');
        return { paddingBottom: `${(ratio[1] / ratio[0]) * 100}%` };
      }
      if (this.ratio.includes('%')) {
        return { height: this.ratio };
      }
      return null;
    },
    getAlt() {
      let srcAlt = this.src && this.src.alt;
      if (srcAlt) {
        srcAlt = srcAlt.replace(/-/g, ' ');
      }
      return this.alt || srcAlt;
    },
  },
};
</script>

<style lang="scss" scoped>
.card-v2__pic {
  position: relative;
  overflow: hidden;
  transition: transform .5s, opacity .5s;
  background-color: $whiteRock;
  z-index: 1;
  max-width: 100%;

  &:last-child {
    border-bottom-right-radius: 0;
    overflow: initial;

    .card-v2__pic__img {
      height: calc(100% + .5rem);
    }
  }

  @include print() {
    padding-bottom: 0 !important;
  }

  &__img {
    position: absolute;
    height: calc(100% + 2px); // 1px to avoid a white line glitch on the top
    width: 100%;
    top: -1px;
    left: 0;

    .card-v2__pic--gradient &:before {
      content: '';
      position: absolute;
      top: 0; left: 0;
      height: 100%; width: 100%;
      background: linear-gradient(rgba($black, 0) 33%, rgba($black, .6));
      z-index: 1;
    }
  }
}

.card-v2--small .card-v2__pic {
  border-bottom-right-radius: 1rem;
}

.card-v2--landscape .card-v2__pic {
  min-width: 135px;
  max-width: 135px;
  height: 135px;
  padding-bottom: inherit !important;

  @include bp375() {
    min-width: 110px;
    max-width: 110px;
    height: 110px;
  }
}
</style>
