<template>
  <div
    class="flex flex-col gap-6">
    <template
      v-for="(benefit, k) in subscriptionBenefits"
      :key="`benefit-${k}`">
      <div>
        <h2
          class="text-zest font-bold text-base">
          {{ benefit.title }}
        </h2>

        <ul class="text-sm">
          <li
            v-for="(item, key) in benefit.list"
            :key="`benefit-item-${k}-${key}`"
            class="mt-1.5 flex">
            <icon-check-circle1 class="text-go-green text-lg min-w-[18px] min-h-[18px] mr-2 mt-[2px]" />
            <span v-html="item" />
          </li>
        </ul>
      </div>

      <div
        v-if="separators && k !== subscriptionBenefits.length - 1"
        :key="`separator-${k}`"
        class="panel-section__separator m-0 p-0" />
    </template>
  </div>
</template>
<script>
import IconCheckCircle1 from 'Icons/bold/IconCheckCircle1';

export default {
  name: 'SubscriberBenefitsList',

  components: {
    IconCheckCircle1,
  },

  props: {
    separators: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      subscriptionBenefits: [
        {
          title: 'Moins cher',
          list: [
            '<b>1€ de réduction</b> sur les paniers composés',
            '<b>10% de réduction</b> sur les produits à l’unité',
            '<b>0€ de minimum d’achat</b> (non abonné : 13,50€)',
          ],
        },
        {
          title: 'Plus pratique',
          list: [
            '<b>Arrêtez votre abo</b> quand vous le souhaitez',
            '<b>Sautez vos livraisons</b> en 1 clic',
            '<b>Remplacez/composez votre panier</b> s’il ne vous convient pas',
          ],
        },
      ],
    };
  },
};
</script>
