<template>
  <transition name="collapse">
    <div
      v-if="needUserAction(order) && showAlert"
      ref="wrapper"
      :style="{ height }"
      class="payment-order-action">
      <announcement-card
        is-centered
        theme="warning">
        <template #title>
          Avertissement
        </template>

        <template #text>
          <icon-ptcy-loader-spinner
            v-if="isPaymentLoading"
            class="text-md mr-1" />

          <!-- paygreen-container needed on paygreen gateway -->
          <!-- used by PaygreenJs to trigger 3DS -->
          <div id="paygreen-container" />
          <div id="paygreen-methods-container" />
          <div id="paygreen-pan-frame" />
          <div id="paygreen-cvv-frame" />
          <div id="paygreen-exp-frame" />
          <div id="paygreen-reuse-checkbox-container" />

          <span
            class="payment-order-action__text"
            v-html="getPaymentError(order)" /> <span v-html="error" />
        </template>

        <template #more-content>
          <potager-button
            :is-loading="isPaymentLoading"
            class="mb-2 last:mb-0"
            full-width
            theme="white"
            @onClick="retryOrderRequiresAction(order)">
            {{ getBtnLabel }}
          </potager-button>

          <template v-if="need3DS(order) || canRetry(order) || error">
            <potager-button
              full-width
              size="small"
              theme="stroke-white"
              @onClick="redirectSelectCard(order)">
              Utiliser une autre carte
            </potager-button>
          </template>
        </template>
      </announcement-card>
    </div>
  </transition>
</template>

<script>
import PaymentMethodsMixin from 'Mixins/payments/PaymentMethodsMixin';
import MobileAppMixin from 'Mixins/MobileAppMixin';

import AnnouncementCard from 'Components/announcementCard/AnnouncementCard';
import PotagerButton from 'UI/PotagerButton';

import IconPtcyLoaderSpinner from 'Icons/regular/IconPtcyLoaderSpinner';

export default {

  mixins: [
    PaymentMethodsMixin,
    MobileAppMixin,
  ],

  components: {
    AnnouncementCard,
    PotagerButton,
    IconPtcyLoaderSpinner,
  },

  data: () => ({
    height: 'auto',
    error: null,
    showAlert: true,
  }),

  props: {
    order: {
      type: Object,
      required: true,
    },
  },

  computed: {
    getBtnLabel() {
      if (this.need3DS(this.order) || this.canRetry(this.order) || this.needFrontPayment(this.order)) return 'Réessayer';
      return 'Choisir une carte de paiement';
    },
  },

  methods: {
    setHeight() {
      const { height } = this.$refs.wrapper.getBoundingClientRect();
      this.height = `${height}px`;
    },
    resetHeight() {
      this.height = 'auto';
    },
    retryOrderRequiresAction(order) {
      this.error = null;

      this.doOrderRequiresAction(order)
        .then(() => {
          this.showAlert = false;
        })
        .catch((err) => {
          const errorCode = `<small>Code d'erreur&nbsp;:&nbsp;${err.code}</small>`;
          this.error = `<b>Veuillez réessayer plus tard ou contacter <a href="https://potagercity.force.com/serviceclient/s/contactsupport" class="underline">notre service client</a> si le problème persiste.</b><br><br>${errorCode}`;
        })
        .finally(() => {
          if (this.isInAppWebView) {
            this.redirectWebApp();
          }
        });
    },
  },

  mounted() {
    if ((this.needUserAction(this.order) || this.needFrontPayment(this.order)) && this.$potagerRoute.query.autoRetry) {
      this.$nextTick(() => {
        this.retryOrderRequiresAction(this.order);
      });
    }
  },
};
</script>
