<template>
  <potager-button
    :class="[
      'dlp-search-input__result-line text-left',
      { 'dlp-search-input__result-line--store': isStore },
      { 'dlp-search-input__result-line--address': !isStore },
    ]"
    full-width
    theme="transparent">
    <template #icon>
      <component
        :is="isStore ? 'icon-shop' : 'icon-two-pin-marker'" />
    </template>

    <span v-html="result.highlight" />
  </potager-button>
</template>

<script>
import PotagerButton from 'UI/PotagerButton';

import IconShop from 'Icons/bold/IconShop';
import IconTwoPinMarker from 'Icons/bold/IconTwoPinMarker';

export default {
  components: {
    PotagerButton,
    IconShop,
    IconTwoPinMarker,
  },
  props: {
    result: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isStore() {
      return this.result.item.store_id;
    },
  },
};
</script>

<style lang="scss">
.dlp-search-input {
  &__result-line {
    mark {
      @apply bg-transparent font-bold;
    }
  }
}

.dlp-search-input__result-line {
  &--selected {
    @apply outline rounded-lg outline-white-rock;
  }
}
</style>
