import axios from 'axios';

import { storeCommit, storeDispatch } from 'Services/storeServices';

import { SET_MAINTENANCE } from 'Stores/types/appMutationsTypes';
import { LOGOUT_USER_ACTION } from 'Stores/types/sessionActionsTypes';

import { overrideErrorsMessages } from 'Classes/services/ApiErrorService';
import Errors, { defaultErrorConfig } from 'Classes/error-handler/ErrorsMapper';

import ModalLogout from 'Modals/ModalLogout';
import { shortcuts } from './ApiSwitcher';

import { api } from 'Plugins/potagerApiClient';
import { openModal } from 'Plugins/potagerModals';
import { events } from 'Plugins/potagerEvents';

import { redirectWebApp } from 'Mixins/MobileAppMixin';

import axiosBetterStacktrace from 'axios-better-stacktrace';

axiosBetterStacktrace(axios);

const allow401Modal = (app) => {
  const route = app.config.globalProperties.$potagerRoute?.name ? app.config.globalProperties.$potagerRoute : app.config.globalProperties.$route;
  return route.meta.disable401 !== undefined ? !route.meta.disable401 : true;
};

const onError = (err, app) => {
  const globalError = err?.globals?.[0];
  let errorConfig = defaultErrorConfig;

  if (globalError) {
    if (globalError.code && Errors[globalError.code]) {
      const error = Errors[globalError.code];
      // si error est une methode
      let errorSpecificConfig;
      if (typeof error === 'object') {
        const caller = err?.response?.config?.caller;
        errorSpecificConfig = error[caller] || error.default;
      } else {
        errorSpecificConfig = {
          text: error
        };
      }

      errorConfig = {
        ...defaultErrorConfig,
        ...errorSpecificConfig,
        code: globalError.code,
      };

      // On trigger l'event handle (si il existe)
      // pour pouvoir faire des actions spécifiques à l'erreur
      errorConfig.handle?.(app, err.response, errorConfig);
    } else {
      errorConfig = {
        ...defaultErrorConfig,
        text: globalError.message || defaultErrorConfig.text,
        code: globalError.code || defaultErrorConfig.code,
      };
    }

    events.emit(errorConfig.eventType, errorConfig);
  }

  if (err?.fields?.length > 0) {
    // Si l'api retourne des erreurs dans le fields, on trigger les erreurs liées aux inputs
    err.fields.forEach((field) => {
      events.emit(`request:inputError:${field.field}`, field?.message);
    });
  }
};

export default (app) => {
  // Interceptor après chaque requete
  // On gère l'affichage des erreurs
  axios.interceptors.response.use(
    (response) => response,
    async (err) => {
      console.error(err?.response?.data || err);

      // Override error message from api with more explicit from the front ErrorsMapping.
      if (err.response && typeof err.response === 'object' && err.response.data?.success === false) {
        // Override the global error messages from the API with more explicit front-end ErrorsMapping.
        if (err.globals?.length) {
          err.globals = overrideErrorsMessages(err.globals);
        }
      }

      // Logout method
      const logout = () => {
        if (err.response.config.url !== api.login.getLoginCheckUrl()) {
          allow401Modal(app)
            ? openModal(ModalLogout, {}, undefined, name)
            : redirectWebApp(err);
        }

        storeDispatch(`session/${LOGOUT_USER_ACTION}`);
      };

      // Refresh token if 401 and logout user if refresh token failed
      // exclude if the 401 is the refresh token request
      if (err.response && err.response.status === 401 && err.response.config.url.indexOf('token/refresh') === -1) {
        if (localStorage.getItem('refresh_token')) {
          localStorage.setItem('token', localStorage.getItem('refresh_token'));

          // Retourner la promesse de l'opération de rafraîchissement du token
          return api.login.refreshToken()
            .then((response) => {
              if (response.status === 200) {
                console.info('%cRefresh token success', 'color: green');
                localStorage.setItem('token', response.data.token);
                localStorage.setItem('refresh_token', response.data.refresh_token);

                // Refaire la requête initiale
                return axios.request(err.response.config);
              } else {
                logout();
                return Promise.reject(err);
              }
            })
            .catch(() => {
              logout();
              return Promise.reject(err);
            });
        }
        logout();
        return Promise.reject(err);
      } else if (err.response && err.response.status === 503) {
        // Maintenance mode
        storeCommit(`app/${SET_MAINTENANCE}`);
        return Promise.reject(err);
      } else {
        onError(err, app);
      }

      return Promise.reject(err);
    });

  // Interceptor avant chaque requete
  // Ajout du token dans le header
  axios.interceptors.request.use((config) => {
    // liste des urls d'api
    const urls = shortcuts
      .map((shortcut) => shortcut.url.replace(/(^\w+:|^)\/\//, ''));

    // on ajoute le token uniquement si l'url est une url d'api ptcy
    if (urls.some((url) => config.url.includes(url))) {
      // if the url is private (/private/), we add the token
      const token = localStorage.getItem('token');
      const isPrivate = config.url.includes('/private/');
      const isRefreshToken = config.url.includes('token/refresh');
      if (token && (isPrivate || isRefreshToken)) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }

    return config;
  }, (err) => {
    console.error(err);
    return Promise.reject(err);
  });
}
