<template>
  <a
    class="bg-white p-6 bp768:p-4 rounded-lg border border-solid border-black/[.07] block h-full text-left relative"
    :href="review.link"
    target="_blank">

    <div class="flex items-center justify-between mb-4">
      <p class="text-base bp768:text-sm.5 leading-tight bp768:leading-tight font-extrabold underline text-british-racing pr-2 overflow-ellipsis overflow-hidden whitespace-nowrap">
        {{ review.name }}
      </p>

      <potager-ratings
        class="leading-0"
        size="mini"
        :score="review.score" />
    </div>

    <p class="bp768:text-xs.5">
      {{ review.comment }}
    </p>

  </a>
</template>

<script>
import PotagerRatings from 'UI/PotagerRatings';

export default {

  components: {
    PotagerRatings,
  },

  props: {
    review: {
      type: Object,
      required: true,
    },
  },

};
</script>
