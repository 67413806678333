import { UPDATE_BASKET_ACTION } from 'Stores/types/basketActionsTypes';
import { UPDATE_USER } from 'Stores/types/userMutationsTypes';
import { POPIN_ERROR_EVENT } from 'Classes/Constants';
import groupBy from 'lodash/groupBy';
import ModalBasketOutOfStock from 'Modals/ModalBasketOutOfStock';
import store from 'Stores';

export const BT018_DEFAULT_ERROR = {
  eventType: POPIN_ERROR_EVENT,
  text: 'Oops ! Un produit présent dans votre panier est victime de son succès et n\'est desormais plus disponible.',
  handle(app, response) {
    const data = response.data?.data || response.data;
    const basket = data?.basket;
    const user = data?.customer || data;
    store.dispatch(`basket/${UPDATE_BASKET_ACTION}`, basket);
    store.commit(`user/${UPDATE_USER}`, user);
  },
};

export const BT018_PAY_BASKET_ERROR = {
  eventType: POPIN_ERROR_EVENT,
  component: ModalBasketOutOfStock,
  text: `Le temps que vous validiez votre panier, la vente d'un élément s'est totalement
      écoulée. Il a été supprimé de votre panier que nous avons mis à jour.`,
  isLoading: 'EDIT_BOX_QUANTITY_BASKET_ACTION',
  onCloseRedirect: { name: 'basket' },
  handle(app, response, error) {
    const data = response.data?.data || response.data;
    const errors = response.data?.errors || response.errors;

    const products = errors.globals.map((err) => (err.code === 'BT018' ? err.metadata : false));
    error.products = products;

    const funcs = [];
    for (const [id, pool] of Object.entries(groupBy(products, 'boxId'))) {
      funcs.push(() => store.dispatch('basket/EDIT_BOX_QUANTITY_BASKET_ACTION', {
        idRegion: data.customer.region.id,
        idBox: pool[0].boxId,
        quantity: 0,
      }));
    }

    const promiseSerial = (f) => f.reduce((promise, func) => promise.then((result) => func()
      .then(Array.prototype.concat.bind(result))), Promise.resolve([]));

    error.onClose = () => new Promise((resolve, reject) => {
      promiseSerial(funcs)
        .then((resp) => resolve(resp))
        .catch((err) => reject(err));
    });
  },
};

export const BT018_CONFIRM_SHADOW_BASKET_ERROR = {
  eventType: POPIN_ERROR_EVENT,
  title: 'Victime de son succès',
  text: 'Oh nooooon… Le dernier élément vient d\'être vendu et n’est désormais plus disponible. Nous vous suggérons de choisir autre chose sur la boutique, il y a forcément quelque chose qui vous plaira 🙂',
  onCloseRedirect: { name: 'mon-compte_orders' }, // Un peu rude
};

export const BT018_RESUME_ORDER_ERROR = {
  eventType: POPIN_ERROR_EVENT,
  title: 'Avertissement',
  text: `Votre commande ne peut malheureusement pas être reprise car tous les éléments présents
      sont épuisés. Nous vous suggérons de faire une nouvelle commande à partir des paniers proposés
      et disponibles cette semaine.`,
  onCloseRedirect: { name: 'dashboard' },
  closeBtnText: 'Aller sur la boutique',
};

export default {
  default: BT018_DEFAULT_ERROR,
  'PaymentApi.payBasketByPaiementMethods': BT018_PAY_BASKET_ERROR,
  'ShadowbasketApi.confirm': BT018_CONFIRM_SHADOW_BASKET_ERROR,
  'PaymentApi.tryFreePaymentBasket': BT018_PAY_BASKET_ERROR,
  'UserApi.resumeOrder': BT018_RESUME_ORDER_ERROR,
};
