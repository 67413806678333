<template>
  <aside class="blog__aside print:hidden">
    <div
      :class="{
        'blog__aside__sticky': !showNewsletterSubscriber,
        'blog__aside__sticky--loggedin': !showNewsletterSubscriber && isLoggedIn,
      }">
      <div
        v-if="!isLoggedIn"
        class="blog__aside__container blog__aside__container--howto">
        <howto :narrow="true" />
      </div>

      <div
        v-else-if="(relatedRecipeBoxes && relatedRecipeBoxes.length) || (products && products.length)"
        class="blog__aside__container">
        <template v-if="relatedRecipeBoxes && relatedRecipeBoxes.length">
          <p class="blog__aside__title">
            Les paniers qui vous aideront à cuisiner cette recette
          </p>

          <potager-row>
            <potager-col
              v-for="(product, index) in relatedRecipeBoxes"
              :key="index">
              <card-product :box="product" />
            </potager-col>
          </potager-row>
        </template>

        <template v-if="products && products.length">
          <p class="blog__aside__title">
            Nos {{ relatedRecipeBoxes && relatedRecipeBoxes.length ? 'autres ' : '' }}paniers disponibles cette semaine
          </p>

          <potager-row>
            <potager-col
              v-for="(product, index) in products"
              :key="index"
              col="6"
              bp1024="12"
              bp640="6">
              <card-product :box="product" />
            </potager-col>
          </potager-row>

          <br>

          <potager-button
            :to="{ name: 'home' }"
            :full-width="true"
            label="Voir tous nos paniers" />
        </template>
      </div>
    </div>

    <div
      v-if="showNewsletterSubscriber"
      :class="{
        'blog__aside__sticky': true,
        'blog__aside__sticky--loggedin': isLoggedIn,
      }">
      <newsletter-subscriber
        :intro="newsletterIntro"
        :class="[
          'blog__aside__container',
          'blog__aside__container--important',
        ]" />
    </div>

    <slot />
  </aside>
</template>

<script>
import { mapGetters } from 'vuex';

import PotagerRow from 'UI/PotagerRow';
import PotagerCol from 'UI/PotagerCol';
import PotagerButton from 'UI/PotagerButton';

import Howto from 'Components/howto/Howto';
import CardProduct from 'Components/cards/CardProduct';
import NewsletterSubscriber from 'Components/newsletter/NewsletterSubscriber';

import { FETCH_DASHBOARD_ACTION } from 'Stores/types/dashboardActionTypes';

import differenceBy from 'lodash/differenceBy';

export default {

  components: {
    PotagerButton,
    PotagerRow,
    PotagerCol,
    Howto,
    CardProduct,
    NewsletterSubscriber,
  },

  props: {
    context: {
      type: String,
      required: false,
      default: 'single',
    },
    relatedRecipeBoxes: {
      type: Array,
      required: false,
      default: () => ([]),
    },
  },

  computed: {
    ...mapGetters('session', [
      'isLoggedIn',
    ]),
    ...mapGetters('dashboard', [
      'isLoaded',
      'getDashboardProducts',
    ]),
    showNewsletterSubscriber() {
      return !(this.context === 'single' && this.$mq.bp768) && !this.isLoggedIn;
    },
    isRecipe() {
      const { name } = this.$route;
      return name === 'recettes-list' || name === 'recette';
    },
    newsletterIntro() {
      return this.isRecipe ? 'Envie de recettes de saison ? Recevez une sélection de nos meilleures recettes chaque mois par email ! Découvrez-y également comment conserver au mieux vos fruits et légumes, ainsi que nos producteurs partenaires.' : undefined;
    },
    products() {
      const nb = 2 * Math.round((6 - this.relatedRecipeBoxes.length) / 2);
      return differenceBy(this.getDashboardProducts, this.relatedRecipeBoxes).slice(0, nb);
    },
  },
};
</script>
