<template>
  <panel
    :back-route="{ name: 'basket' }"
    title="Paiement">
    <payment-processing v-if="order" />

    <template v-else>
      <basket-payment-card-preview
        :card-id="paymentMethods?.primary?.payment_id" />

      <div class="panel-section">
        <p class="panel-section__subtitle">
          Cartes titres restaurant
        </p>

        <div
          v-if="getPaymentAccounts.length"
          class="panel-section__line">
          <template v-for="PM in SecondaryLoginPaymentMethodsArray">
            <payment-methods-selectable-item
              v-if="getPaymentAccountByName(PM.name)"
              :key="PM.name"
              :is-loading="$wait.is(PM.loaders.editSettings)"
              :model-value="getPaymentAccountByName(PM.name).usableForSubscription"
              :payment="{ payment_type: PM.name }"
              class="mb-4 last:mb-0"
              @update:modelValue="(e) => PM.actions.setDefault({isDefault: e})">
              <template #type>
                {{ PM.label }}
              </template>
              <template #icon>
                <component
                  :is="icon"
                  v-for="(icon, index) in PM.icons"
                  :key="`${PM.name}-${index}`"
                  :class="`text-${PM.name}`" />
              </template>
              <template #details>
                {{ getPaymentAccountByName(PM.name).email }}
              </template>
            </payment-methods-selectable-item>
          </template>
        </div>

        <div
          v-if="getRestaurantCards.length"
          class="panel-section__p">
          <payment-methods-selectable-item
            v-for="card in getRestaurantCards"
            :key="card.id"
            :is-disabled="$wait.is(getPaymentMethodByCard(card).loaders.editSettings)"
            :is-loading="$wait.is(`${SET_DEFAULT_CARD_ACTION}_${card.id}`)"
            :model-value="card.id === paymentMethods.secondary?.payment_id"
            :payment="{ payment_type: PaymentMethodIntegration.Paygreen, payment_id: card.id }"
            class="mb-4 last:mb-0"
            @update:modelValue="(value) => getPaymentMethodByCard(card).actions.setDefault({isDefault: value, cardId: card.id})">
            <template #type>
              <div class="first-letter:uppercase">
                {{ getPaymentMethodByCard(card).label }}
              </div>
            </template>

            <template #icon>
              <component
                :is="icon"
                v-for="(icon, index) in getPaymentMethodByCard(card).icons"
                :key="`${card.issuer}-${index}`"
                :style="{ color: getPaymentMethodByCard(card).colors?.primary }" />
            </template>

            <template #details>
              {{ card.number.replace(/XXXX/g, '•••• ') }}
            </template>
          </payment-methods-selectable-item>
        </div>

        <template v-if="!getPaymentAccounts.length && !getRestaurantCards.length">
          <p class="panel-section__p">
            Aucune carte titres restaurant n'est liée à votre compte.
          </p>
        </template>

        <potager-navigator
          :to="{
            name: 'mon-compte_payment-methods',
            query: { back: JSON.stringify({name: 'basket_payment'}) },
          }">
          Ajouter une carte titres restaurant
        </potager-navigator>
      </div>

      <basket-receipt-details />

      <basket-payment-reassurance />
    </template>

    <template
      v-if="!order"
      #footer>
      <div class="flex items-center">
        <span class="text-british-racing text-sm mr-2 potager-button potager-button--small bg-white font-normal">
          Total&nbsp;:&nbsp;<b>{{ formatPrice(total) }}</b>
        </span>

        <potager-button
          v-if="!getBankCards.length && total > 0"
          :to="{ name: 'basket_payment_add_card' }"
          full-width
          theme="wild-willow">
          Ajouter une carte de paiement
        </potager-button>

        <potager-button
          v-else
          :is-disabled="!canSubmit"
          :is-loading="isLoading"
          disabled-message="Veuillez sélectionner une carte de paiement par défaut"
          full-width
          theme="go-green"
          @onClick="submitBasketPayment">
          {{ getSubmitBtnLabel }}
        </potager-button>
      </div>

      <potager-info size="small">
        En poursuivant cette étape, vous acceptez l'intégralité de nos
        <potager-link
          :to="{ name: 'cgv' }"
          target="_blank">
          conditions générales de vente.
        </potager-link>
      </potager-info>
    </template>
  </panel>
</template>

<script>
import { mapGetters } from 'vuex';
import { SET_DEFAULT_CARD_ACTION } from 'Stores/types/userActionsTypes';

import BasketPaymentMixin from 'Mixins/BasketPaymentMixin';
import GtmMixin from 'Mixins/GtmMixin';

import { PaymentMethodIntegration } from 'potagerlogic/dist/Enums/PaymentMethods';
import { SecondaryLoginPaymentMethodsArray, getPaymentMethodByCard } from 'Classes/payment-methods';
import MetaInfoService from 'Classes/services/MetaInfoService';
import { formatPrice, getTotalOrder } from 'Classes/utils/PricesUtils';

import PotagerButton from 'UI/PotagerButton';
import PotagerInfo from 'UI/PotagerInfo';
import PotagerNavigator from 'UI/PotagerNavigator';

import Panel from 'Components/panel/Panel';
import PaymentMethodsSelectableItem from 'Components/paymentMethods/PaymentMethodsSelectableItem';
import PaymentOrderAction from 'Components/paymentMethods/PaymentOrderAction';
import PaymentProcessing from 'Components/paymentMethods/PaymentProcessing';

import BasketReceiptTotal from 'Pages/basket/components/BasketReceiptTotal';
import BasketPaymentCardPreview from 'Pages/basket/components/BasketPaymentCardPreview';
import BasketPaymentReassurance from 'Pages/basket/components/BasketPaymentReassurance';
import BasketReceiptDetails from 'Pages/basket/components/BasketReceiptDetails';

export default {

  mixins: [
    BasketPaymentMixin,
    GtmMixin,
  ],

  components: {
    BasketReceiptDetails,
    PaymentProcessing,
    BasketPaymentCardPreview,
    BasketPaymentReassurance,
    BasketReceiptTotal,
    Panel,
    PaymentMethodsSelectableItem,
    PaymentOrderAction,
    PotagerButton,
    PotagerInfo,
    PotagerNavigator,
  },

  data: () => ({
    PaymentMethodIntegration,
    SecondaryLoginPaymentMethodsArray,
    SET_DEFAULT_CARD_ACTION,
  }),

  computed: {
    ...mapGetters('user', [
      'getPaymentAccounts',
      'getPaymentAccountByName',
      'getRestaurantCards',
    ]),
    ...mapGetters('basket', [
      'getBasket',
      'getSimulatedOrder',
      'isSubscription',
    ]),
    total() {
      return getTotalOrder(this.getSimulatedOrder || this.getBasket);
    },
    canSubmit() {
      return this.isPrimaryCardValid || this.total <= 0;
    },
    getSubmitBtnLabel() {
      if (this.total === 0) return 'Valider mon panier';
      return this.isSubscription ? 'J\'accepte' : 'Payer';
    },
  },

  methods: {
    getPaymentMethodByCard,
    formatPrice,
  },

  mounted() {
    this.trackCheckoutStep();
  },

  head: MetaInfoService.generate({
    title: 'Paiement',
  }),
};
</script>
