<template>
  <div
    :class="[
      'potager-ratings',
      `potager-ratings--${size}`,
    ]">
    <div class="potager-ratings__stars">
      <component
        class="potager-ratings__star"
        v-for="i in [1, 2, 3, 4, 5]"
        :key="i"
        :is="getIcon(i)" />
    </div>
    <p
      v-if="$slots.default"
      class="potager-ratings__legend">
      <span class="potager-ratings__legend__score">
        {{ score }}
      </span>
      <slot />
    </p>
  </div>
</template>

<script>
import IconPtcyRatingStarEmpty from 'Icons/color/IconPtcyRatingStarEmpty';
import IconPtcyRatingStarFull from 'Icons/color/IconPtcyRatingStarFull';
import IconPtcyRatingStarHalf from 'Icons/color/IconPtcyRatingStarHalf';
import { propsBuilder } from 'UI/Tools';

export const props = {
  score: {
    type: Number,
    required: false,
    default: 5,
  },
  size: {
    type: String,
    required: false,
    default: 'default',
    values: ['default', 'small', 'mini'],
  },
};

export default {

  props: propsBuilder(props),

  methods: {
    getIcon(i) {
      if (this.score >= i) return IconPtcyRatingStarFull;
      if (this.score < i && Math.ceil(this.score) >= i) return IconPtcyRatingStarHalf;
      return IconPtcyRatingStarEmpty;
    },
  },

};
</script>
