import { POPIN_ERROR_EVENT } from 'Classes/Constants';

export default {
  default: {
    eventType: POPIN_ERROR_EVENT,
    title: 'Avertissement',
    text: `Votre entreprise ne vous autorise à utiliser qu’une seule carte (coupon) de
      participation par commande. Veuillez vous rapprocher de la personne de votre
      entreprise qui gère le contrat pour en savoir plus.`,
  },
};
