<template>
  <ul class="card-v2__composition">
    <li
      v-for="product in products"
      :key="product.id"
      class="card-v2__products__item"
      v-text="product.name" />
  </ul>
</template>

<script>
export default {

  props: {
    products: {
      type: Array,
      required: true,
    },
  },

};
</script>

<style lang="scss" scoped>
.card-v2__composition {
  @apply text-xxs;
  line-height: $baseLh;
  color: $warmGrey;
  margin-top: .625rem;

  &__item {
    display: inline;

    &:before {
      content: ', ';
      white-space: pre;
    }

    &:first-child:before {
      content: '';
    }
  }
}
</style>
