<template>
  <div
    v-if="paymentMethod"
    class="panel-section mb-0 pb-4 max-w-2xl mx-auto">
    <div class="panel-section__line">
      <paygreen-new-card-form
        v-show="paymentMethod.integration === PaymentMethodIntegration.Paygreen"
        :name="name"
        :set-primary="setPrimary"
        legend-cgv
        @success="onSuccess" />

      <stripe-new-card-form
        v-show="paymentMethod.integration === PaymentMethodIntegration.Stripe"
        :name="name"
        :set-primary="setPrimary"
        legend-cgv
        @success="onSuccess" />
    </div>
  </div>

  <div
    v-else
    class="panel-section mb-0 pb-4 max-w-2xl mx-auto text-warning">
    <p class="text-center panel-section__p">
      Cette méthode de paiement n'est pas disponible.
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getPaymentMethodByName, PaymentMethods } from 'Classes/payment-methods';
import { PaymentMethodIntegration, PaymentMethodType } from 'potagerlogic/dist/Enums/PaymentMethods';

import PaygreenNewCardForm from 'Components/paygreen/PaygreenNewCardForm';
import StripeNewCardForm from 'Components/stripe/StripeNewCardForm';

import { already_added } from 'Classes/StripeErrors';

import MobileAppMixin from 'Mixins/MobileAppMixin';

export default {
  mixins: [
    MobileAppMixin,
  ],

  components: {
    StripeNewCardForm,
    PaygreenNewCardForm,
  },

  data() {
    return {
      PaymentMethodIntegration,
    };
  },

  computed: {
    ...mapGetters('user', [
      'getPaymentGateway',
    ]),
    name() {
      const routeParamsName = this.$route.params.name;

      if (routeParamsName === PaymentMethodType.BankCard) {
        const gatewayName = this.$route.query.gateway || this.getPaymentGateway;
        return gatewayName === PaymentMethodIntegration.Paygreen ? PaymentMethods.PaygreenBankCard.name : PaymentMethods.Stripe.name;
      }

      return routeParamsName;
    },
    paymentMethod() {
      return getPaymentMethodByName(this.name);
    },
    setPrimary() {
      return this.$route.query.setPrimary === 'true';
    },
  },

  methods: {
    onSuccess(newCard) {
      if (newCard) {
        this.redirectWebApp({
          success: true,
          newCard,
        });
      } else {
        // signifie que la carte n'a pas été ajoutée car elle existe déjà en base
        this.redirectWebApp({
          success: false,
          message: already_added,
        });
      }
    },
  },
};
</script>
