<template>
  <div
    :class="`
      flex
      items-center
      justify-center
      p-3
      mr-4
      relative
      ${getSize}
    `">
    <span
      :class="`
        transform
        ${getRotate}
        ${getSize}
        inset-0
        ${getRadius}
        absolute
        ${getBackground}
        ${extraclass}
      `" />
    <div
      :class="`
        relative
        font-semibold
        text-xl.5
        leading-none
        flex
        items-center
        justify-center
        ${getSize}
      `">
      {{ icon }}
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: false,
      default: '',
    },
    size: {
      type: String,
      required: false,
      default: 'small',
      validator: (val) => ['small', 'medium', 'large'].includes(val),
    },
    direction: {
      type: String,
      required: false,
      default: '',
    },
    theme: {
      type: String,
      required: false,
      default: 'white-rock',
      validator: (val) => ['white-rock', 'white'].includes(val),
    },
    extraclass: {
      type: String,
      required: false,
      default: '',
    },
  },

  computed: {
    getRotate() {
      return (this.direction === 'reverse') ? '-rotate-12' : 'rotate-12';
    },
    getBackground() {
      switch (this.theme) {
        case 'white':
          return 'bg-white';
        case 'white-rock':
        default:
          return 'bg-white-rock';
      }
    },
    getSize() {
      switch (this.size) {
        case 'large':
          return 'h-20 w-20';
        case 'medium':
          return 'h-16 w-16';
        case 'small':
        default:
          return 'h-12 w-12';
      }
    },
    getRadius() {
      return this.size === 'large' ? 'rounded-3xl' : 'rounded-xl';
    },
  },
};
</script>
