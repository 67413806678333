<template>
  <div
    :class="{
      'flying-page': true,
      'flying-page--with-footer': $slots.footer,
      'flying-page--with-basket-preview': showBasket,
    }">
    <basket-preview
      v-if="showBasket"
      :offset="$slots.footer ? 56 : 0" />

    <basket-button
      v-if="!this.$mq.bp1024"
      ref="basketButton"
      class="flying-page__desktop-basket-preview" />

    <transition name="fade">
      <button
        v-if="previous"
        v-wave
        class="flying-page__nav flying-page__nav--previous"
        @click="nav('prev')">
        <icon-arrow-left1 class="flying-page__nav__icon" />
      </button>
    </transition>

    <div
      class="flying-page__content"
      data-scroll="true">
      <potager-button
        class="flying-page__back print:hidden"
        is-circle
        theme="white"
        @onClick="back">
        <template #icon>
          <icon-arrow-left1 />
        </template>
      </potager-button>

      <potager-button
        class="flying-page__close print:hidden"
        is-circle
        theme="white"
        @onClick="close">
        <template #icon>
          <icon-close />
        </template>
      </potager-button>

      <slot />
    </div>

    <transition name="fade">
      <button
        v-if="next"
        v-wave
        class="flying-page__nav flying-page__nav--next"
        @click="nav('next')">
        <icon-arrow-right1 class="flying-page__nav__icon" />
      </button>
    </transition>

    <div class="flying-page__footer">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import IconArrowLeft1 from 'Icons/regular/IconArrowLeft1';
import IconArrowRight1 from 'Icons/regular/IconArrowRight1';
import IconClose from 'Icons/regular/IconClose';

import PotagerButton from 'UI/PotagerButton';

import BasketPreview from 'Components/basketPreview/BasketPreview';
import BasketButton from 'Components/basket/BasketButton';

import { stuckPage } from 'Classes/utils/ScrollUtils';
import {
  KEYCODE_ESCAPE, KEYCODE_RIGHT, KEYCODE_LEFT,
  FLYINGPAGE_TRANSITION_PREV, FLYINGPAGE_TRANSITION_NEXT,
} from 'Classes/Constants';

export default {
  components: {
    BasketButton,
    IconArrowLeft1,
    IconArrowRight1,
    IconClose,
    PotagerButton,
    BasketPreview,
  },

  props: {
    previous: {
      type: Object,
      required: false,
      default: null,
    },
    next: {
      type: Object,
      required: false,
      default: null,
    },
    preview: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  computed: {
    ...mapGetters('session', [
      'isLoggedIn',
    ]),
    showBasket() {
      return this.isLoggedIn && this.$mq.bp1024 && this.preview;
    },
  },

  methods: {
    nav(direction) {
      const isPrev = direction === 'prev';
      const redirect = isPrev ? this.previous : this.next;
      if (redirect) {
        const transition = isPrev ? FLYINGPAGE_TRANSITION_PREV : FLYINGPAGE_TRANSITION_NEXT;
        this.$events.emit('flyingPage:updateTransition', transition);
        this.$router.push(redirect)
          .catch(() => {
          });
      }
    },
    close() {
      this.$events.emit('flyingPage:close');
    },
    onClose() {
      this.$emit('onClose');
    },
    back() {
      this.$events.emit('flyingPage:back');
    },
    onKeyUp(e) {
      if (e && e.keyCode) {
        if (e.keyCode === KEYCODE_ESCAPE) {
          this.close();
        }
        if (e.keyCode === KEYCODE_RIGHT && this.next) {
          this.nav('next');
        }
        if (e.keyCode === KEYCODE_LEFT && this.previous) {
          this.nav('prev');
        }
      }
    },
    swipe(e) {
      const path = e.path || (e.composedPath && e.composedPath());
      if (path) {
        let found = false;
        path.forEach((p) => {
          if (p.classList && p.classList.contains('potager-slider--initialized')) {
            found = true;
          }
        });
        if (!found) {
          this.nav(e.type === 'swiperight' ? 'prev' : 'next');
        }
      }
    },
  },

  mounted() {
    document.addEventListener('keyup', this.onKeyUp);

    if (this.$mq.bp1024) {
      document.addEventListener('swiperight', this.swipe);
      document.addEventListener('swipeleft', this.swipe);
    }

    this.$events.on('flyingPage:close', this.onClose);

    stuckPage();
  },

  beforeUnmount() {
    document.removeEventListener('keyup', this.onKeyUp);

    if (this.$mq.bp1024) {
      document.removeEventListener('swiperight', this.swipe);
      document.removeEventListener('swipeleft', this.swipe);
    }

    this.$events.off('flyingPage:close', this.onClose);

    stuckPage(false);
  },
};
</script>
