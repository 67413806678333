class MetaInfoService {
  constructor(config) {
    this.defaultConfig = config;
  }

  generate(specificConfig = {}) {
    const config = { ...this.defaultConfig, ...specificConfig };

    const metaInfos = {
      title: config.title,
      meta: [],
      link: [],
    };

    if (config.titleTemplate) {
      metaInfos.titleTemplate = config.titleTemplate;
    }

    const appConfig = this.generateAppConfig(config);
    const facebookConfig = this.generateFacebookConfig(config);
    const twitterConfig = this.generateTwitterConfig(config);
    const linkConfig = this.generateLinkConfig(config);
    const metaConfig = this.generateMetaConfig(config);

    metaInfos.meta = this.generateMetasInfos({
      appConfig,
      facebookConfig,
      twitterConfig,
    });

    for (const index in linkConfig) {
      metaInfos.link.push(linkConfig[index]);
    }

    for (const index in metaConfig) {
      metaInfos.meta.push(metaConfig[index]);
    }

    return metaInfos;
  }

  generateAppConfig(config) {
    return {
      name: {
        title: config.title,
        description: config.description || null,
      },
    };
  }

  generateFacebookConfig(config) {
    return {
      property: {
        'og:title': config.title,
        'og:type': config.fb.type || null,
        'og:url': config.url || null,
        'og:image': config.image || null,
        'og:site_name': config.siteName || null,
        'og:description': config.description || null,
        'fb:app_id': config.fb.appId || null,
        'facebook-domain-verification': config.fb.facebookDomainVerification || null,
      },
    };
  }

  generateTwitterConfig(config) {
    return {
      property: {
        'twitter:title': config.title,
        'twitter:description': config.description || null,
        'twitter:image': config.image || null,
        'twitter:card': config.twitter.card || null,
        'twitter:url': config.url || null,
        'twitter:site': config.twitter.site || null,
        'twitter:account_id': config.twitter.accountId || null,
      },
    };
  }

  generateLinkConfig(config) {
    const metaLink = [];

    if (config.link) {

      for (const index in config.link) {
        const link = config.link[index];
        metaLink.push(link);
      }
    }

    return metaLink;
  }

  generateMetaConfig(config) {
    const metas = [];

    if (config.meta) {

      for (const index in config.meta) {
        const meta = config.meta[index];
        metas.push(meta);
      }
    }

    return metas;
  }

  generateMetasInfos(metas) {
    return Object.values(metas)
      .reduce((acc, m) => {
        const metaEntries = Object.entries(m)
          .map((entry) => Object.entries(entry[1])
            .map((meta) => ({
              vmid: meta[0],
              [entry[0]]: meta[0],
              content: meta[1],
            })))
          .flat();

        return [...acc, ...metaEntries];
      }, []);
  }
}

export default new MetaInfoService({
  titleTemplate: '%s | Potager City',
  description: 'La bonne variété au bon moment et le choix du circuit le plus court entre des producteurs passionnés et votre assiette. Choisissez votre 1er panier !',
  url: window.location.href,
  siteName: 'Potager City',
  image: 'https://www.potagercity.fr/images/meta-default.jpg',
  fb: {
    type: 'website',
    appId: '123385305033743',
    facebookDomainVerification: 'iygj2ebonwtdrsvcdzz1mwhjfbwu8u',
  },
  twitter: {
    card: 'summary_large_image',
    site: '@potagercity',
    accountId: '180767737',
  },
});
