<template>
  <div class="b2b-success bg-bianca py-16 -mb-6 flex items-center">
    <potager-container>
      <potager-row centered>
        <potager-col
          col="8"
          bp1024="12">
          <div class="pt-16 bp768:pt-0 pb-24 bp768:pb-0 bp768:text-center max-w-6/12 mx-auto">
            <highlights-icon class="mb-8 bp768:mb-4 bp768:mx-auto">
              🤗
            </highlights-icon>
            <h1 class="font-extrabold text-4xl bp768:text-2xl.5 bp768:mb-4">
              Merci pour votre réponse !
            </h1>
            <h2 class="text-xl.5 bp768:text-lg font-normal mb-8">
              Nous allons préparer votre proposition tarifaire.
            </h2>
            <div class="flex bp768:flex-col items-center">
              <potager-button
                class="text-charcoal underline text-sm.5 p-0"
                theme="transparent"
                size="small"
                :to="{ name: 'b2b-offers' }">
                Retour à l'offre entreprise
              </potager-button>
            </div>
          </div>
        </potager-col>
        <potager-col
          v-if="!$mq.bp1024"
          col="4">
          <div class="relative overflow-hidden rounded-lg h-full">
            <potager-picture
              class="absolute inset-0"
              background
              position="center"
              src="b2b-en-situation" />
          </div>
        </potager-col>
      </potager-row>
    </potager-container>
  </div>
</template>

<script>
import HighlightsIcon from 'Components/highlightsSlider/HighlightsIcon';

import PotagerContainer from 'UI/PotagerContainer';
import PotagerRow from 'UI/PotagerRow';
import PotagerCol from 'UI/PotagerCol';
import PotagerButton from 'UI/PotagerButton';
import PotagerPicture from 'UI/PotagerPicture';

export default {

  components: {
    HighlightsIcon,
    PotagerContainer,
    PotagerRow,
    PotagerCol,
    PotagerButton,
    PotagerPicture,
  },
};
</script>

<style lang="scss">
.b2b-success {
  min-height: calc(100vh - 13.25rem);

  @include bp768() {
    min-height: calc(100vh - 5.25rem);
  }
}
</style>
