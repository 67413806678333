import { pluralize } from 'Classes/utils/WordingUtils';
import { getProductPackagingWeight } from 'Classes/utils/ProductPackagingUtils';
import { WORDINGS } from 'Classes/Wordings';

/**
 * Get the quantity of people a box can serve.
 *
 * @param {Object} item - The item object.
 * @param {number} [item.minPeople] - The minimum number of people the item can serve.
 * @param {number} [item.maxPeople] - The maximum number of people the item can serve.
 * @param {Object} [options] - The options object.
 * @param {string} [options.format='short'] - The format of the output string.
 * @returns {string} The quantity of people the item can serve.
 */
export const getBoxPeopleQuantity = (item, { format = 'short' } = {}) => {
  const { minPeople, maxPeople } = item;
  const shortFormat = format === 'short' ? 'pers.' : 'personne';
  const pluralFormat = format === 'short' ? 'pers.' : 'personnes';

  let str = minPeople && maxPeople && minPeople !== maxPeople ? `${minPeople} à ` : '';
  str += pluralize(maxPeople || minPeople, shortFormat, pluralFormat);
  return str;
};

/**
 * Get the weight and people quantity for a box.
 *
 * @param {Object} box - The box object.
 * @param {number} [box.minPeople] - The minimum number of people the box can serve.
 * @param {number} [box.maxPeople] - The maximum number of people the box can serve.
 * @param {Object} [options] - The options object.
 * @param {string} [options.format='short'] - The format of the output string.
 * @returns {string} The weight and people quantity of the box.
 */
export const getWeightAndBoxPeopleQuantity = (box, { format = 'short' } = {}) => {
  const min = parseInt(box.minPeople, 10);
  const max = parseInt(box.maxPeople, 10);

  let str = getProductPackagingWeight(box, { format });
  if (min || max) {
    str += str ? ' - ' : '';
    str += getBoxPeopleQuantity(box, { format });
  }
  return str;
};

/**
 * Get the array of suppliers for a box.
 *
 * @param {Object} box - The box object.
 * @param {Array} [box.suppliers] - The array of suppliers.
 * @param {Array} box.products - The array of products in the box.
 * @returns {Array} The array of unique suppliers.
 */
export const getBoxSuppliersArray = (box) => {
  const suppliers = box.suppliers?.length
    ? box.suppliers
    : box.products.map(p => p.supplier);
  const uniqueSuppliers = new Map(suppliers
    .filter(s => s).map(s => [s.id, s]));
  return Array.from(uniqueSuppliers.values());
};

/**
 * Get the suppliers of a box as a comma-separated string.
 *
 * @param {Object} box - The box object.
 * @param {Array} [box.suppliers] - The array of suppliers.
 * @param {Array} box.products - The array of products in the box.
 * @returns {string} The comma-separated list of supplier names.
 */
export const getBoxSuppliers = (box) => getBoxSuppliersArray(box)
  .map(({
    substituteName,
    firstname
  }) => substituteName || firstname)
  .filter(Boolean)
  .join(', ');

/**
 * Get the lock message for a box.
 *
 * @param {Object} box - The box object.
 * @param {Object} [box.deliveryDate] - The delivery date object.
 * @param {boolean} [box.deliveryDate.isCurrentWeek] - Whether the delivery date is in the current week.
 * @param {boolean} [box.isSoldOut] - Whether the box is sold out.
 * @returns {string|undefined} The lock message for the box.
 */
export const getBoxLockStatus = (box) => {
  if (!box?.deliveryDate?.isCurrentWeek) return WORDINGS.BOX_LOCKED.STATUS;
  if (box?.isSoldOut) return 'Épuisé';
  return undefined;
};
