import store from 'Stores';

export default function (to, from, next) {
  if (!store.getters['temporaryCustomer/getEmail']) {
    console.info('hasEmailGuard > Redirecting to previous step');
    next(store.getters['tunnel/getPrevStepRoute']());
  } else {
    next();
  }
}
