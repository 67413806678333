<template>
  <div class="bg-british-racing mt-0 text-white">
    <potager-container class="py-32 bp1024:py-28 bp768:py-24">
      <potager-row centered>
        <potager-col :col="$mq.bp768 ? 12 : ($mq.bp1024 ? 10 : 8)">
          <h2 class="font-black text-4xl bp1024:text-3xl.5 bp1024:leading-tight mt-20 bp1024:mt-0 mb-4 bp1024:mb-2">
            Vous avez des questions ?
          </h2>
          <collapsible-faq
            v-for="(item, i) in B2B_FAQ"
            :key="i"
            :item="item" />
        </potager-col>
      </potager-row>
    </potager-container>
  </div>
</template>

<script>
import PotagerContainer from 'UI/PotagerContainer';
import PotagerRow from 'UI/PotagerRow';
import PotagerCol from 'UI/PotagerCol';
import CollapsibleFaq from 'Components/collapsible/CollapsibleFaq';
import { B2B_FAQ } from 'Classes/Contents';

export default {

  components: {
    CollapsibleFaq,
    PotagerContainer,
    PotagerRow,
    PotagerCol,
  },

  data: () => ({
    B2B_FAQ,
  }),

};
</script>
