<template>
  <potager-modal>
    <template #title>
      <span v-html="getTitle" />
    </template>

    <template #body>
      <div class="max-w-768">
        <potager-info
          v-for="(rule, i) in getRules"
          :key="i"
          align="center">
          <template #icon>
            <component
              :is="IconShoppingBagCheck"
              class="text-2xl.5 mr-2" />
          </template>
          <p
            class="wysiwygModalCouponPolicy"
            v-html="rule" />
        </potager-info>
      </div>
    </template>

    <template #footer>
      <slot name="footer" />
    </template>
  </potager-modal>
</template>

<script>
import PotagerModal from 'UI/PotagerModal';
import PotagerInfo from 'UI/PotagerInfo';
import IconShoppingBagCheck from 'Icons/regular/IconShoppingBagCheck';
import { SPONSORSHIP } from 'Classes/utils/CouponsUtils';

export default {

  components: {
    PotagerModal,
    PotagerInfo,
  },

  props: {
    promotedCoupon: {
      type: [Object, Boolean],
      required: true,
      default: false,
    },
  },

  data: () => ({
    IconShoppingBagCheck,
  }),

  computed: {
    getTitle() {
      return `Conditions de l'offre${this.promotedCoupon.couponType === SPONSORSHIP ? ' de parrainage' : `<br>${this.promotedCoupon.couponCode}`}`;
    },
    getRules() {
      return this.promotedCoupon.promotedDescription
        // clean html & catch li content
        .replace(/[\n\r]/g, '')
        .trim()
        .match(/<li>(.*?)<\/li>/g)
        // clean unused html tags
        ?.map((rule) => rule.replace('<li>', '')
          .replace('</li>', '')
          .replace('<i></i>', '')
          .replace('<b></b>', '')
          .trim());
    },
  },

};
</script>

<style lang="scss">
.wysiwygModalCouponPolicy {
  @apply text-sm.5 font-bold not-italic;

  b {
    @apply text-sushi;
  }

  i {
    @apply text-xs.5 font-normal block;
  }

  br {
    @apply hidden;
  }
}
</style>
