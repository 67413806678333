import { POPIN_ERROR_EVENT } from 'Classes/Constants';
import { UPDATE_USER } from 'Stores/types/userMutationsTypes';
import store from 'Stores';

export default {
  'UserApi.setDeliveryPointOrder': {
    eventType: POPIN_ERROR_EVENT,
    text: 'Oops ! Un produit présent dans votre commande est victime de son succès  et n\'est plus disponible pour le jour de livraison choisi. Si vous décidez appliquez ce changement, nous devront le retirer de votre commande.',
    handle: (app, response) => {
      let { data } = response;
      store.commit('user/' + UPDATE_USER, data);
    }
  },
};
