<template>
  <div class="py-24 bp768:py-12 -mb-12 bp768:-mb-6 bg-british-racing">
    <potager-container>
      <div class="max-w-6/12 w-full mx-auto">
        <local-title-cpt>
          On s’adapte à votre quotidien&nbsp;!&nbsp;<span class="font-normal">👌</span>
        </local-title-cpt>

        <div class="text-center pb-8 bp640:flex whitespace-nowrap">
          <potager-button
            v-for="(tab, i) in tabs"
            :key="i"
            :class="{
              'rounded-l-none border-0 border-l border-solid border-porcelain': i > 0,
              'rounded-r-none': i < tabs.length - 1,
              'pr-2 pl-2 w-full': $mq.bp640,
            }"
            :theme="i === currentTabIndex ? 'beryl-green' : 'bianca'"
            @onClick="currentTabIndex = i">
            {{ tab.name }}
          </potager-button>
        </div>

        <div class="flex bp640:block text-white">
          <div class="w-1/2 bp640:w-full mr-6 bp640:mr-0 bp640:mb-4">
            <div class="pb-full rounded-lg relative overflow-hidden">
              <transition
                mode="in-out"
                name="fade">
                <potager-picture
                  v-if="showGif"
                  :key="currentTabIndex"
                  :src="gifSrc"
                  background
                  class="absolute inset-0" />
              </transition>
            </div>
          </div>

          <div class="w-1/2 bp640:w-full ml-6 bp640:ml-0 relative flex justify-center flex-col">
            <transition
              :name="$mq.bp640 ? 'slide-right-to-left-25pc' : 'slide-top-to-bottom-2rem'"
              mode="out-in">
              <div
                :key="currentTabIndex"
                class="w-full">
                <p
                  class="text-base leading-tight font-extrabold mb-4"
                  v-html="tabs[currentTabIndex].title" />
                <p v-html="tabs[currentTabIndex].text" />
              </div>
            </transition>
          </div>
        </div>

        <local-separator-cpt />

        <local-title-cpt>
          Pssst ! C’est ici qu’on répond à vos questions&nbsp;<span class="font-normal">🔎</span>
        </local-title-cpt>
        <collapsible-faq
          v-for="(item, i) in SUBSCRIPTION_FAQ"
          :key="i"
          :item="item" />
      </div>
    </potager-container>
  </div>
</template>

<script>
import PotagerButton from 'UI/PotagerButton';
import PotagerContainer from 'UI/PotagerContainer';
import PotagerPicture from 'UI/PotagerPicture';
import CollapsibleFaq from 'Components/collapsible/CollapsibleFaq';
import { SUBSCRIPTION_FAQ } from 'Classes/Contents';

const localTitleCpt = {
  template: `
    <p class="text-xl font-bold text-white mb-8 text-center">
      <slot />
    </p>
  `,
};

const localSeparatorCpt = {
  template: `
    <div class="text-center my-14">
      <span class="h-px w-24 bg-beryl-green inline-block" />
    </div>
  `,
};

export default {

  components: {
    PotagerButton,
    PotagerContainer,
    PotagerPicture,
    CollapsibleFaq,
    localTitleCpt,
    localSeparatorCpt,
  },

  data: () => ({
    SUBSCRIPTION_FAQ,
    currentTabIndex: 0,
    showGif: true,
    gifKey: 0,
    tabs: [
      {
        name: 'Remplacer',
        title: 'Remplacez le panier de votre abonnement à tout moment&nbsp;!',
        text: `
          2 restos la semaine dernière et il vous en reste dans le frigo&nbsp;?<br>
          Pas de panique&nbsp;! Vous pouvez remplacer votre panier en abonnemennt à tout moment.
          Parfait pour en prendre un plus petit la semaine prochaine et éviter le gaspi&nbsp;!
        `,
        image: 'tunnel-replace.gif',
      },
      {
        name: 'Faire une pause',
        title: 'Mettez votre abonnement en pause et planifiez vos absences&nbsp;!',
        text: `
          En vacances les pieds en éventail&nbsp;?<br>
          Vous pouvez annuler vos commandes comme vous le souhaitez, mettre en pause votre abonnnement,
          programmer vos absences, ou l’arrêter définitivement à tout moment&nbsp;! Facile&nbsp;!
        `,
        image: 'tunnel-pause.gif',
      },
    ],
  }),

  computed: {
    gifSrc() {
      return `${this.tabs[this.currentTabIndex].image}?v=${this.gifKey}`;
    },
  },

  watch: {
    currentTabIndex() {
      // showGif = false to trigger the transition
      this.showGif = false;
      // gifKey = tricks the browser to reload the gif from beginning
      this.gifKey += 1;

      setTimeout(() => {
        this.showGif = true;
      });
    },
  },

};
</script>
