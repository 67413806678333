<template>
  <panel
    :back-btn="Survey.canGoToPreviousQuestion()"
    :back-callback="() => Survey.goToPreviousQuestion()"
    :back-route=" Survey.canGoToPreviousQuestion() ? undefined : { name: 'mon-compte_subscription_suspend' }"
    title="Abonnement">
    <survey-form :survey="Survey" />

    <template #footer>
      <span class="flex flex-col items-center">
        <potager-button
          :is-loading="isFormLoading"
          full-width
          @onClick="Survey.submit()">
          Suspendre mon abonnement
        </potager-button>

        <potager-button
          :to="{ name: 'mon-compte_subscription' }"
          size="small"
          theme="link">
          J'ai changé d'avis
        </potager-button>
      </span>
    </template>
  </panel>
</template>

<script>
import { mapGetters } from 'vuex';
import { REMOVE_SUBSCRIPTION_ACTION } from 'Stores/types/userActionsTypes';

import Panel from 'Components/panel/Panel';
import SurveyForm from 'Components/surveys/SurveyForm';
import PotagerButton from 'UI/PotagerButton';

import Survey from 'Classes/surveys/CancelSubscriptionSurvey';

export default {

  components: {
    Panel,
    PotagerButton,
    SurveyForm,
  },

  data: () => ({
    Survey: undefined,
  }),

  watch: {
    getSubscription: {
      immediate: true,
      handler(subscription) {
        if (subscription) {
          this.$nextTick(() => {
            this.Survey.init();
          });
        }
      },
    },
  },

  computed: {
    ...mapGetters('user', [
      'getSubscription',
    ]),
    isFormLoading() {
      return this.$wait.is(REMOVE_SUBSCRIPTION_ACTION);
    },
  },

  created() {
    this.Survey = Survey(this);
  }
};
</script>
