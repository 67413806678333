export const LOGIN_USER_ACTION = 'loginUser-action';
export const SOCIAL_LOGIN_USER_ACTION = 'SOCIAL_LOGIN_USER_ACTION';
export const ON_LOGIN_SUCCESS = 'ON_LOGIN_SUCCESS';
export const ON_LOGIN_ERROR = 'ON_LOGIN_ERROR';
export const RESET_PASSWORD_ACTION = 'RESET_PASSWORD_ACTION';
export const LOGOUT_USER_ACTION = 'logoutUser-action';
export const REQUEST_FORGOT_PASSWORD_EMAIL_ACTION = 'REQUEST_FORGOT_PASSWORD_EMAIL_ACTION';
export const RESET_SESSION_ACTION = 'RESET_SESSION_ACTION';
export const UPDATE_REQUEST_URL_ACTION = 'UPDATE_REQUEST_URL_ACTION';
export const UPDATE_SESSION_USER_DATA_ACTION = 'UPDATE_SESSION_USER_DATA_ACTION';
