import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import Provider from 'Classes/social-providers';
import IconOsSystemApple from 'Icons/bold/IconOsSystemApple';
import { storeGetters } from 'Services/storeServices';

export default new Provider({

  name: 'apple',
  icon: IconOsSystemApple,
  color: '#000000',

  getProviderInstance: () => new firebase.auth.OAuthProvider('apple.com'),

  getLoginData: (data) => (data.platform
    ? {
      platform: data.platform,
      code: data.code,
    }
    : {
      accessToken: data.credential.accessToken,
      idToken: data.credential.idToken,
      refreshToken: data.user.refreshToken,
      email: data.user.email,
      resourceOwnerId: data.additionalUserInfo.profile.sub,
      expires: data.additionalUserInfo.profile.exp,
      tokenType: 'Bearer',
    }),

  getUserData: (data) => {
    const name = data.user.displayName?.split(' ');
    const firstName = name?.[0] || storeGetters('temporaryCustomer/getFirstName') || null;
    const lastName = name?.slice(1, name.length)
      ?.join(' ') || storeGetters('temporaryCustomer/getLastName') || null;
    const email = data.user.email || storeGetters('temporaryCustomer/getEmail') || null;
    const appleId = data.additionalUserInfo.profile.sub;
    return {
      firstName,
      lastName,
      email,
      appleId,
    };
  },
});
