import { mapActions } from 'vuex';
import { SUBSCRIBE_NEWSLETTER } from 'Stores/types/appActionTypes';
import { CLOSE_POPUP_ACTION } from 'Stores/types/popupsActionsTypes';
import { POPUP_NEWSLETTER_NAME } from 'Classes/Constants';

export default {
  computed: {
    isSubscriptionLoading() {
      return this.$wait.is(SUBSCRIBE_NEWSLETTER);
    },
  },
  methods: {
    ...mapActions('popups', [CLOSE_POPUP_ACTION]),
    onSubscriptionSubmit(email, source = 'Content') {
      const params = {
        email,
        source,
        url: this.$route.path,
      };

      return new Promise((resolve, reject) => {
        this.$store.dispatch(`app/${SUBSCRIBE_NEWSLETTER}`, params)
          .then(() => {
            this.$notify({
              type: 'success',
              title: 'Inscription réussie',
              text: 'Votre inscription à la newsletter a bien été prise en compte.',
            });

            this.CLOSE_POPUP_ACTION(POPUP_NEWSLETTER_NAME);
            this.$modal.close();
            resolve();
          })
          .catch((error) => {
            this.$notify({
              type: 'error',
              title: 'Erreur',
              text: error.message,
            });
            reject(error);
          });
      });
    },
  },
};
