<template>
  <potager-modal
    ref="modal"
    v-bind="$props">
    <template #title>
      <span v-html="title" />
    </template>

    <template #body>
      <div class="text-left">
        <potager-card-button
          v-for="product in items"
          :key="product.id"
          :item="product"
          class="border-b border-white-rock border-solid border-0 rounded-none last:border-b-0 first:-mt-2" />
      </div>
    </template>
  </potager-modal>
</template>

<script>
import PotagerModal, { props } from 'UI/PotagerModal';
import PotagerCardButton from 'UI/PotagerCardButton';
import { propsBuilder } from 'UI/Tools';

export default {
  components: {
    PotagerModal,
    PotagerCardButton,
  },

  props: propsBuilder({
    items: {
      type: Array,
      required: true,
      default: () => ([]),
    },
    ...props,
  }),
};
</script>
