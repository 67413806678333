<template>
  <div
    ref="appContent"
    :class="{
      'app-content': true,
      'print:hidden': isFlyingPageOpened,
    }"
    :style="{
      ...(isWindowHeight && { height: appContentHeight + 'px' }),
    }">
    <icon-logo-potager-mini
      v-if="!$route.name"
      class="animate-pulse text-white-rock leading-none text-[96px] my-auto" />

    <template v-else>
      <page-not-found v-if="pageIsNotFound" />
      <router-view v-else />
    </template>
  </div>
</template>

<script>
import PageNotFound from 'Pages/404';

import IconLogoPotagerMini from 'Icons/bold/IconLogoPotagerMini';

export default {

  components: {
    PageNotFound,
    IconLogoPotagerMini,
  },

  data: () => ({
    pageIsNotFound: false,
    appContentHeight: 0,
  }),

  computed: {
    isFlyingPageOpened() {
      return !!this.$potagerRoute.meta?.isFlying || !!this.$potagerRoute.meta?.isPanel;
    },
    isWindowHeight() {
      return this.$potagerRoute.meta?.isWindowHeight;
    },
  },

  watch: {
    $route: {
      handler() {
        this.pageIsNotFound = false;
        this.setHeight();
      },
      immediate: true,
    },
    $mq() {
      this.setHeight();
    },
  },

  methods: {
    onPageNotFound() {
      this.pageIsNotFound = true;
    },
    setHeight() {
      setTimeout(() => {
        const isFullHeight = this.$route.meta?.isWindowHeight;
        const { appContent } = this.$refs;
        const appContentTop = appContent?.getBoundingClientRect().top;
        const windowHeight = window.innerHeight * (isFullHeight ? 1 : 0.75); // we want to keep some space at the bottom
        const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        const appTop = appContentTop + scrollTop;
        this.appContentHeight = windowHeight - appTop;
      }, 100);
    },
  },

  created() {
    this.$events.on('404', this.onPageNotFound);
  },
};
</script>

<style lang="scss">
.app-content {
  @apply flex min-h-[75vh] w-full;

  > * {
    @apply flex-1 max-w-full;
  }
}
</style>
