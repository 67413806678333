import panelsLoginRoutes from 'Router/routes/panels/panelsLoginRoutes';
import panelsAccountRoutes from 'Router/routes/panels/panelsAccountRoutes';
import panelsBasketRoutes from 'Router/routes/panels/panelsBasketRoutes';
import panelsDeliveryPointsRoutes from 'Router/routes/panels/panelsDeliveryPointsRoutes';

const routes = [
  ...panelsLoginRoutes,
  ...panelsAccountRoutes,
  ...panelsBasketRoutes,
  ...panelsDeliveryPointsRoutes,
];

export default routes;
