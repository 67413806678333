<template>
  <div class="test-button">
    <potager-button
      v-if="canTest"
      :to="{ name: 'test-mode' }"
      class="test-button"
      is-circle
      shadow
      theme="zest">
      <template #icon>
        <icon-lab-flask-experiment />
      </template>
    </potager-button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import PotagerButton from 'UI/PotagerButton';
import IconLabFlaskExperiment from 'Icons/bold/IconLabFlaskExperiment';

export default {
  components: {
    PotagerButton,
    IconLabFlaskExperiment
  },

  computed: {
    ...mapGetters('user', [
      'canTest',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.test-button {
  $sentryWidgetWidth: 200px;
  @apply fixed opacity-50 hover:opacity-100;
  z-index: $z-index_modal + 1;
  right: $sentryWidgetWidth;
  bottom: $tooling_bottom;

  @include min-bp1920() {
    right: calc((100vw - 1920px) / 2 + $sentryWidgetWidth);
  }
}
</style>
