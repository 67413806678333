/**
 * Capitalize the first letter of a string.
 * @param {string} str - The string to capitalize.
 * @returns {string} The capitalized string.
 */
export const capitalize = (str) => {
  if (!str) return '';
  return str.charAt(0)
    .toUpperCase() + str.slice(1);
};

/**
 * Pluralize a given value.
 * @param {number} value - The number to determine singular or plural.
 * @param {string} singular - The singular form of the word.
 * @param {string} [plural=`${singular}s`] - The plural form of the word.
 * @returns {string} The pluralized form of the word.
 */
export const pluralize = (value, singular, plural = `${singular}s`) => {
  return `${value} ${value > 1 ? plural : singular}`;
};
