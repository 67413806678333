<template>
  <transition name="fade">
    <div
      v-if="hasOverlay"
      :class="{
        'card-v2__overlay': true,
      }">
      <transition name="fade">
        <div
          v-if="isSoldout"
          class="card-v2__overlay__warning-msg">
          Victime de son succès
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>

export default {

  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    box: {
      type: [Object, Boolean],
      required: false,
      default: false,
    },
  },

  computed: {
    isSoldout() {
      return this.box?.isSoldOut && !this.isLoading;
    },
    hasOverlay() {
      return this.isSoldout;
    },
  },

};
</script>

<style lang="scss" scoped>
.card-v2__overlay {
  @apply absolute top-0 left-0 w-full flex items-center justify-center z-50 py-4 px-2;
  @apply bg-british-racing/80 text-white text-xs.5 font-bold text-center;
}
</style>

<style lang="scss">
.card-v2__overlay {
  .card-v2--landscape & {
    @apply bp425:h-full;
  }
}
</style>
