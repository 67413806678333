export default {
  mounted(el, binding) {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
      ...binding.value?.options
    };

    const callback = (entries) => {
      entries.forEach((entry) => {
        // Appelle la méthode spécifiée avec true si l'élément est visible, sinon false
        const isVisible = entry.isIntersecting;
        if (typeof binding.value === 'function') {
          binding.value(isVisible);
        } else if (binding.value?.callback) {
          binding.value.callback(isVisible);
        } else {
          console.warn('La valeur de la directive observe-visibility doit être une fonction');
        }

        if(isVisible && binding.value?.once) {
          el.__observeVisibilityObserver.disconnect();
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);
    observer.observe(el);

    el.__observeVisibilityObserver = observer;
  },
  unmounted(el) {
    if (el.__observeVisibilityObserver) {
      el.__observeVisibilityObserver.disconnect();
      delete el.__observeVisibilityObserver;
    }
  }
};
