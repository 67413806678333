<template>
  <potager-button
    v-if="$mq.bp768"
    theme="charcoal"
    @onClick="TOGGLE_LIST_VISIBILITY">
    <template #icon>
      <icon-maps-pin1 v-if="isListVisible" />
      <icon-list-bullets v-else />
    </template>
    {{ isListVisible ? 'Carte' : 'Liste' }}
  </potager-button>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { TOGGLE_LIST_VISIBILITY } from 'Stores/types/mapMutationsTypes';

import PotagerButton from 'UI/PotagerButton';

import IconListBullets from 'Icons/regular/IconListBullets';
import IconMapsPin1 from 'Icons/regular/IconMapsPin1';

export default {
  name: 'DlpListSwitcher',

  components: {
    PotagerButton,
    IconListBullets,
    IconMapsPin1,
  },

  computed: {
    ...mapGetters('map', [
      'getMapPublicSearchResult',
      'isListVisible',
    ]),
  },

  methods: {
    ...mapMutations('map', {
      TOGGLE_LIST_VISIBILITY,
    }),
  },
};
</script>
