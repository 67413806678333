import LoginRegisterPage from 'Pages/login/LoginRegisterPage';
import LoginPasswordForgottenPage from 'Pages/login/LoginPasswordForgottenPage';
import LoginModifyPasswordPage from 'Pages/login/LoginModifyPasswordPage';

export default [
  {
    name: 'login',
    path: '/connexion',
    alias: '/login',
    component: LoginRegisterPage,
    meta: {
      requiresNoAuth: true,
      isPanel: true,
      cantBeUsedForLoginRedirect: true,
      disable401: true,
    },
  },
  {
    name: 'login_reset_password',
    path: '/connexion/reinitialiser',
    alias: '/login/reinitialiser',
    component: LoginPasswordForgottenPage,
    meta: {
      isPanel: true,
      cantBeUsedForLoginRedirect: true,
    },
  },
  {
    name: 'login_reset_password_modify',
    path: '/connexion/modifier/:token',
    alias: '/login/modifier/:token',
    component: LoginModifyPasswordPage,
    meta: {
      isPanel: true,
      cantBeUsedForLoginRedirect: true,
    },
  },
  {
    name: 'register',
    path: '/inscription',
    component: LoginRegisterPage,
    meta: {
      requiresNoAuth: true,
      isPanel: true,
      cantBeUsedForLoginRedirect: true,
      disable401: true,
    },
  },
];
