<template>
  <!--  a button switch using 2 potager-button -->
  <div
    :class="[
      'inline-flex bg-white-rock rounded-full border-4 border-solid border-white-rock',
      'potager-switch-button',
      {
        'potager-switch-button--left': value === values[0],
        'potager-switch-button--right': value === values[1],
      }
    ]">
    <potager-button
      ref="leftButton"
      :aria-label="ariaLabels[0] || undefined"
      :disabled="isDisabled"
      :ripple="value === values[0]"
      class="potager-switch-button__left outline-none shadow-none font-bold z-10"
      is-rounded
      theme="transparent"
      @onClick="value = values[0]">
      <slot name="left">
        <slot name="left-icon">
          <icon-ptcy-loader-spinner
            v-if="isLoading"
            class="potager-switch-button__spinner" />
        </slot>
        <slot v-if="!$slots.left && !isLoading" />
      </slot>
    </potager-button>

    <div class="w-0 relative">
      <div
        :style="{
          width: activeButtonWidth,
        }"
        class="potager-switch-button__slider" />
    </div>

    <potager-button
      ref="rightButton"
      :aria-label="ariaLabels[1] || undefined"
      :disabled="isDisabled"
      :ripple="value === values[1]"
      class="potager-switch-button__right outline-none shadow-none font-bold z-10"
      is-rounded
      theme="transparent"
      @onClick="value = values[1]">
      <slot name="right">
        <slot name="right-icon">
          <icon-ptcy-loader-spinner
            v-if="isLoading"
            class="potager-switch-button__spinner" />
        </slot>
        <slot v-if="!$slots.right && !isLoading" />
      </slot>
    </potager-button>
  </div>
</template>

<script>
import IconPtcyLoaderSpinner from 'Icons/regular/IconPtcyLoaderSpinner';
import PotagerButton from 'UI/PotagerButton';

export default {
  components: {
    PotagerButton,
    IconPtcyLoaderSpinner,
  },

  props: {
    modelValue: {
      type: [Boolean, String],
      required: false,
      default: true,
    },
    values: {
      type: Array,
      required: false,
      default: () => [true, false],
    },
    ariaLabels: {
      type: Array,
      required: false,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      activeButtonWidth: '50%',
    };
  },

  emits: ['update:modelValue'],

  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },

  watch: {
    value() {
      this.activeButtonWidth = this.$refs[this.value ? 'leftButton' : 'rightButton'].$el.offsetWidth + 'px';
    },
  },

  mounted() {
    this.activeButtonWidth = this.$refs[this.value ? 'leftButton' : 'rightButton'].$el.offsetWidth + 'px';
  },
};
</script>

<style lang="scss" scoped>
.potager-switch-button {
  $this: &;
  @apply relative;

  &__slider {
    @apply absolute left-0 bg-go-green rounded-full w-1/2 transform -translate-x-full h-full;
    @apply duration-100 ease-out transition-all;
  }

  &--left {
    :deep().potager-switch-button__left {
      &.potager-button {
        &, &:hover {
          @apply text-white;
        }
      }
    }
  }

  &--right {
    :deep().potager-switch-button__right {
      &.potager-button {
        &, &:hover {
          @apply text-white;
        }
      }
    }

    .potager-switch-button__slider {
      @apply translate-x-0;
    }
  }
}
</style>
