import {
  UPDATE_DELIVERY_POINT,
  UPDATE_GROUP_DELIVERY_DAY,
  UPDATE_ADDRESS,
  UPDATE_POSTAL_CODE,
  RESET_HOME_DELIVERY,
  UPDATE_STREET_LIST,
} from 'Stores/types/homeDeliveryMutationsTypes';
import {
  UPDATE_GROUP_DELIVERY_DAY_ACTION,
  LOAD_STREET_LIST_ACTION,
  FETCH_ADDRESS_ELIGIBILITY_LAD
} from 'Stores/types/homeDeliveryActionsTypes';

import { convertAddressToParameter } from 'Classes/utils/DlpUtils';
import { DELIVERY_OPERATIONAL_LAD_LOCAL, DELIVERY_OPERATIONAL_LAD_CHRONO } from 'Classes/Constants';

import { api } from 'Plugins/potagerApiClient';

export default {
  namespaced: true,

  state: {
    deliveryPoint: null,
    groupDeliveryDay: null,
    address: null,
    postalCode: null,
    streetList: null,
  },

  getters: {
    getDeliveryPoint: (state) => state.deliveryPoint,
    getDeliveryPointPostalCode: (state, g) => g.getDeliveryPoint?.postalCode,
    getGroupDeliveryDay: (state) => state.groupDeliveryDay,
    getAddress: (state) => state.address,
    getAddressFirstName: (state, g) => g.getAddress?.firstName,
    getAddressLastName: (state, g) => g.getAddress?.lastName,
    getAddressPhone: (state, g) => g.getAddress?.phone,
    getPostalCode: (state) => state.postalCode,
    getStreetList: (state) => state.streetList,
    isLADEnable: (state, g, rs, rootGetters) => rootGetters['app/getHomeDeliveryRegionsIds'].includes(rootGetters['session/getRegionId']),
  },

  mutations: {
    [UPDATE_DELIVERY_POINT](state, deliveryPoint) {
      state.deliveryPoint = deliveryPoint;
    },
    [UPDATE_GROUP_DELIVERY_DAY](state, groupDeliveryDay) {
      state.groupDeliveryDay = groupDeliveryDay;
    },
    [UPDATE_ADDRESS](state, address) {
      state.address = address;
    },
    [UPDATE_POSTAL_CODE](state, postalCode) {
      state.postalCode = postalCode;
    },
    [RESET_HOME_DELIVERY](state) {
      state.deliveryPoint = null;
      state.groupDeliveryDay = null;
      state.address = null;
      state.streetList = null;
    },
    [UPDATE_STREET_LIST](state, streetList) {
      state.streetList = streetList;
    },
  },

  actions: {
    [UPDATE_GROUP_DELIVERY_DAY_ACTION]({ commit }, groupDeliveryDay) {
      commit(UPDATE_GROUP_DELIVERY_DAY, groupDeliveryDay);
    },
    [LOAD_STREET_LIST_ACTION]({
      commit,
      dispatch
    }, postalCode) {
      dispatch('wait/start', LOAD_STREET_LIST_ACTION, { root: true });

      api.deliveryPoints.getLADAddresses({ codePostal: postalCode })
        .then(({ data }) => {
          commit(UPDATE_STREET_LIST, data);
        })
        .finally(() => {
          dispatch('wait/end', LOAD_STREET_LIST_ACTION, { root: true });
        });
    },
    [FETCH_ADDRESS_ELIGIBILITY_LAD]({
      dispatch,
      commit
    }, {
      address,
      lat,
      lng
    }) {
      return new Promise((resolve, reject) => {
        dispatch('wait/start', FETCH_ADDRESS_ELIGIBILITY_LAD, { root: true });
        api.deliveryPoints.getLADEligibility({
          address: convertAddressToParameter(address),
          lat,
          lng,
        })
          .then(({ data }) => {
            const {
              normalizedAddress,
              availableDeliveryType,
              searchLatitude,
              searchLongitude,
            } = data;
            // 1. mettre à jour l'adresse LAD avec l'adresse normalisée retournée par l'API
            commit(`homeDelivery/${UPDATE_ADDRESS}`, {
              ...normalizedAddress,
              lat: searchLatitude,
              lng: searchLongitude
            }, { root: true });
            // 2. mettre à jour le dlp avec le `availableDeliveryType` de type `lad`
            const homeDlp = availableDeliveryType
              .find((dlp) => [DELIVERY_OPERATIONAL_LAD_CHRONO, DELIVERY_OPERATIONAL_LAD_LOCAL]
                .includes(dlp.type))?.availablePrs[0].group;
            if (homeDlp) {
              commit(UPDATE_DELIVERY_POINT, homeDlp);
              resolve(homeDlp);
            } else {
              reject(new Error('Adresse non éligible. Veuillez corriger votre adresse ou choisir un autre mode de livraison.'));
            }
          })
          .catch(() => {
            reject(new Error('Allô Houspomme, une erreur est survenue. Veuillez contacter notre support.'));
          })
          .finally(() => {
            dispatch('wait/end', FETCH_ADDRESS_ELIGIBILITY_LAD, { root: true });
          });
      });
    },
  },
};
