<template>
  <panel
    :back-route="backRoute"
    title="Codes promo">
    <template #header>
      <p class="text-center">
        Saisissez et sélectionnez les codes promos que vous souhaitez ajouter à votre commande.
      </p>
    </template>

    <coupons-manager
      :context="Context.Order"
      :order="order">
      <template #legend>
        Les codes promo sélectionnés seront appliqués à votre commande.
      </template>
    </coupons-manager>

    <template #footer>
      <div class="flex items-center">
        <span
          v-if="getOrderCoupons.length"
          class="text-british-racing text-sm mr-2 potager-button potager-button--small bg-white font-normal">
          Total&nbsp;:&nbsp;<b>{{ getValue }}</b>
        </span>

        <potager-button
          :is-loading="$wait.is('ORDER_COUPON_ACTION')"
          :label="getOrderCoupons.length ? 'Appliquer' : 'Continuer'"
          full-width
          theme="go-green"
          @onClick="redirect" />
      </div>
    </template>
  </panel>
</template>

<script>
import PotagerButton from 'UI/PotagerButton';

import Panel from 'Components/panel/Panel';
import CouponsManager from 'Components/coupons/CouponsManager';

import MetaInfosService from 'Classes/services/MetaInfoService';
import { formatPrice, getCouponsTotalOrder, getTotalOrder } from 'Classes/utils/PricesUtils';

import PaymentMethodsMixin from 'Mixins/payments/PaymentMethodsMixin';

import { Context } from 'potagerlogic/dist/Enums/Context';

export default {

  mixins: [
    PaymentMethodsMixin,
  ],

  components: {
    Panel,
    PotagerButton,
    CouponsManager,
  },

  data: () => ({
    initialOrder: null,
    Context,
  }),

  computed: {
    order() {
      return this.$store.getters['user/getOrderById'](this.$potagerRoute.params.orderId);
    },
    getOrderCoupons() {
      return this.$store.getters['user/getOrderCoupons'](this.order.id);
    },
    backRoute() {
      if (this.order && this.order.id) {
        return {
          name: 'mon-compte_orders_detail',
          params: {
            orderId: this.order.id,
          },
        };
      }
      return { name: 'mon-compte_orders' };
    },
    getValue() {
      return `-${formatPrice(this.getTotalCouponsOrder)}`;
    },
    getTotalCouponsOrder() {
      return getCouponsTotalOrder(this.order);
    },
  },

  methods: {
    redirect() {
      this.$router.push(this.backRoute)
        .catch(() => {
        });
      if (this.getOrderPrice(this.order) !== this.getOrderPrice(this.initialOrder)) {
        this.notifyOrderStatus(this.order, Context.Order);
      }
    },
    getOrderPrice(order) {
      return getTotalOrder(order);
    },
  },

  mounted() {
    this.initialOrder = this.order;
  },

  head: MetaInfosService.generate({
    title: 'Coupons',
  }),

};
</script>
