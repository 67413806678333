<template>
  <span class="print:hidden">

    <associated-content-slider
      ref="recipes"
      title="Nos idées recettes"
      subtitle="Avec ce panier, vous pourrez préparer…"
      :items="box.recipes" />

    <associated-content-slider
      ref="suppliers"
      title="Faites la connaissance de nos producteurs"
      subtitle="Qui fournissent certains produits de ce panier"
      :items="box.suppliers" />

  </span>
</template>

<script>
import AssociatedContentSlider from 'Components/sliders/AssociatedContentSlider';

export default {

  components: {
    AssociatedContentSlider,
  },

  props: {
    box: {
      type: Object,
      required: true,
    },
  },

};
</script>
