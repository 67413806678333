<template>
  <div class="blog">
    <potager-container>
      <heading
        v-if="content.title"
        :background="null"
        modifiers="simple">
        <template #title>
          {{ content.title }}
        </template>
      </heading>
    </potager-container>

    <blog-body :is-loading="!content.content">
      <blog-section>
        <cms
          :content="content.content"
          with-hat />
      </blog-section>
    </blog-body>

    <push-subscription class="mb-10 bp768:my-6" />

    <micro-datas
      :content="content"
      context="customPages" />
  </div>
</template>

<script>
import ToolsMixin from 'Mixins/ToolsMixin';

import PotagerContainer from 'UI/PotagerContainer';
import Heading from 'Components/heading/Heading';
import Cms from 'Components/cms/Cms';
import BlogBody from 'Components/blog/BlogBody';
import BlogSection from 'Components/blog/BlogSection';

import MicroDatas from 'Components/microDatas/MicroDatas';
import PushSubscription from 'Components/pushs/PushSubscription';
import { LOGIN } from 'Stores/types/sessionMutationsTypes';
import { isPotagerRoute } from 'Plugins/potagerRouting';

export default {

  mixins: [
    ToolsMixin,
  ],

  components: {
    PushSubscription,
    PotagerContainer,
    Heading,
    Cms,
    BlogBody,
    BlogSection,
    MicroDatas,
  },

  data: () => ({
    content: {},
  }),

  methods: {
    updateData(route) {
      const endpoint = route.params.url;

      this.$api.common.findCustomPage(endpoint)
        .then((response) => {
          if (response.data.success) {
            this.content = response.data.data;
          }
        });

      window.history.replaceState({}, '', `/${endpoint}`);
    },
  },

  created() {
    this.updateData(this.$route);
  },

  beforeRouteUpdate(to, from, next) {
    // If we are on the same page, we don't need to check
    // needed for potagerRouting (on opening panels)
    if (to.path !== from.path) {
      this.updateData(to);
    }
    next();
  },

  head() {
    return this.generateMetaIfPanelClosed({
      title: this.content.title ? this.content.title.replace('– Potager City', '') : null,
      description: this.content.meta,
    });
  },

};
</script>
