<template>
  <div
    :class="[
      'announcement-card panel-section',
      `announcement-card--${theme}`,
      {
        'announcement-card--large': isLarge,
        'announcement-card--centered': isCentered,
      },
    ]">
    <p class="announcement-card__title panel-section__line">
      <slot name="title" />
    </p>

    <p
      v-if="$slots.text"
      class="announcement-card__text panel-section__line">
      <slot name="text" />
    </p>

    <div
      v-if="$slots['more-content']"
      class="panel-section__line">
      <slot name="more-content" />
    </div>
  </div>
</template>

<script>

export default {
  props: {
    theme: {
      type: String,
      required: false,
      default: 'default',
      validator: (val) => ['default', 'dark', 'warning', 'craft'].includes(val),
    },
    isLarge: {
      type: Boolean,
      required: false,
      default: false,
    },
    isCentered: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.announcement-card {
  text-align: left;
  @apply text-xs.5;

  &__title {
    @apply text-sm;
    font-weight: $weightBold;
    line-height: $baseLh;
  }

  &__text {
    font-weight: $weightMedium;
  }

  &--default {
    background: $white;
    color: $britishRacing;
  }

  &--dark {
    background: $charcoal;
    color: $white;
  }

  &--warning {
    background: $warning;
    color: $white;
  }

  &--craft {
    background: $whiteRock;
    color: $britishRacing;
  }

  &--large {
    @apply text-lg;
  }

  &--centered {
    text-align: center;
  }
}
</style>
