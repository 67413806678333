<template>
  <potager-container class="dashboard__section">
    <div
      :class="[
        'flex rounded bg-white-rock p-6',
        'bp640:flex-col'
      ]">
      <div
        v-if="!$mq.bp640"
        :class="[
          'mr-6',
        ]">
        <icon-ptcy-framboise class="text-5xl" />
      </div>

      <div>
        <div class="flex bp640:mb-4 items-center">
          <div
            v-if="$mq.bp640"
            class="text-[50px] mr-6">
            <icon-ptcy-framboise class="block" />
          </div>

          <h2 class="text-sm.5 font-bold mb-2 bp640:mb-0">
            Au fait, Potager City, c'est quoi&nbsp;?
          </h2>
        </div>

        <p>
          Potager City prépare et livre des paniers de fruits et légumes de saison toutes les semaines, chez vous, dans
          un point relais partenaire ou directement sur votre lieu de travail. Que vous soyez à Paris, Bordeaux,
          Marseille, Lyon, Nantes ou dans l’une des 5 régions où nous assurons la livraison, il n’a jamais
          été aussi simple de manger des produits frais, issus de l’agriculture locale et bio quand cela est possible.
          Nous sélectionnons rigoureusement nos agriculteurs et producteurs partenaires, valorisons leur savoir-faire
          tout en leur assurant une rémunération juste. Une fois votre panier récupéré, retrouvez comment conserver vos
          fruits et légumes, des informations sur plus de 800 producteurs et près de 2000 recettes sur notre site et
          notre application.
        </p>
      </div>
    </div>
  </potager-container>
</template>

<script>
import PotagerContainer from 'UI/PotagerContainer';
import IconPtcyFramboise from 'Icons/color/IconPtcyFramboise';

export default {
  name: 'DashboardSeoContent',

  components: {
    PotagerContainer,
    IconPtcyFramboise,
  },
};
</script>
