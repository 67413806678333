import RequiredValidator from 'Classes/validators/RequiredValidator';
import EmailValidator from 'Classes/validators/EmailValidator';
import RepeatValidator from 'Classes/validators/RepeatValidator';
import LengthValidator from 'Classes/validators/LengthValidator';
import RegexValidator from 'Classes/validators/RegexValidator';
import PhoneValidator from 'Classes/validators/PhoneValidator';
import MobileValidator from 'Classes/validators/MobileValidator';
import EqualValidator from 'Classes/validators/EqualValidator';
import NumberValidator from 'Classes/validators/NumberValidator';
import PasswordValidator from 'Classes/validators/PasswordValidator';
import DatalistValidator from 'Classes/validators/DatalistValidator';

const validatorClasses = [
  RequiredValidator,
  EmailValidator,
  RepeatValidator,
  LengthValidator,
  RegexValidator,
  PhoneValidator,
  MobileValidator,
  EqualValidator,
  NumberValidator,
  PasswordValidator,
  DatalistValidator,
];

export default class {
  static getValidator(name) {
    const validators = validatorClasses.filter((validator) => validator.name === name);

    if (validators.length > 1) {
      throw 'Validator name must be unique';
    }

    if (validators.length) {
      return validators[0];
    }

    throw `No validator with name ${name} exist`;
  }

  static valid(constraints, fieldValue, formValues) {
    const errors = [];

    constraints.forEach((constraint) => {
      const validator = this.getValidator(constraint.constraint);
      const error = validator.valid(fieldValue, constraint, formValues);
      if (error) {
        errors.push(error);
      }
    });

    return errors;
  }
}
