<template>
  <panel>
    <div class="panel-section">
      <p class="panel-section__subtitle">
        Définir un nouveau mot de passe
      </p>

      <p class="panel-section__p">
        Vous avez demandé à réinitialiser votre mot de passe.
        Pour cela, veuillez saisir votre nouveau mot de passe.
      </p>

      <p class="panel-section__p">
        Cette fois c'est sur, vous ne l'oublierez plus !
      </p>

      <potager-form
        id="reset-password"
        :models="[password, confirmPassword]"
        class="panel-section__line"
        @canSubmit="(e) => isDisabled = !e"
        @onSubmit="resetPassword">
        <potager-input
          v-model="password"
          :constraints="[
            { constraint: 'required', field: 'password' },
          ]"
          label="Mot de passe"
          name="password"
          type="password" />

        <potager-input
          v-model="confirmPassword"
          :constraints="[
            { constraint: 'equal', field: 'confirmPassword', options: { value: password } },
          ]"
          constraints-validation="onInput"
          label="Confirmez votre mot de passe"
          name="confirmPassword"
          type="password" />

        <potager-button
          :is-disabled="isDisabled"
          :is-loading="isLoading"
          full-width
          theme="go-green"
          type="submit">
          Valider
        </potager-button>
      </potager-form>
    </div>
  </panel>
</template>

<script>
import { mapGetters } from 'vuex';
import { RESET_PASSWORD_ACTION } from 'Stores/types/sessionActionsTypes';
import { GET_TEMPORARY_CUSTOMER_BY_TOKEN_ACTION } from 'Stores/types/temporaryCustomerActionsTypes';

import PotagerButton from 'UI/PotagerButton';
import PotagerForm from 'UI/Form/PotagerForm';
import PotagerInput from 'UI/Form/PotagerInput';

import Panel from 'Components/panel/Panel';

import MetaInfosService from 'Classes/services/MetaInfoService';

export default {

  components: {
    Panel,
    PotagerButton,
    PotagerForm,
    PotagerInput,
  },

  data: () => ({
    password: '',
    confirmPassword: '',
    isDisabled: false,
  }),

  computed: {
    ...mapGetters('temporaryCustomer', [
      'getFrom',
    ]),
    isLoading() {
      return this.$wait.is([
        RESET_PASSWORD_ACTION,
        GET_TEMPORARY_CUSTOMER_BY_TOKEN_ACTION,
      ]);
    },
    token() {
      return this.$route.params.token || this.$potagerRoute.params.token;
    },
    getBackRoute() {
      return { name: this.getFrom || 'login' };
    },
  },

  methods: {
    resetPassword() {
      const {
        token,
        password
      } = this;
      this.$store.dispatch(`session/${RESET_PASSWORD_ACTION}`, {
        token,
        password
      })
        .then(() => {
          this.$notify({
            type: 'success',
            title: 'Mot de passe modifié',
            text: 'Votre mot de passe a bien été modifié.',
          });

          this.$router.push(this.getBackRoute)
            .catch(() => {
            });
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },

  head: MetaInfosService.generate({
    title: 'Mot de passe oublié ?',
  }),

};
</script>
