<template>
  <potager-form
    id="postal-code-select"
    :models="[value]"
    @onSubmit="submit">
    <div class="flex">
      <potager-input
        v-model="value"
        :disabled="isLoading"
        :options="postalCodes"
        full-width
        name="postalCode"
        type="select"
        @update:modelValue="$emit('onInput', value)">
        <template #icon>
          <icon-style-two-pin-marker />
        </template>
      </potager-input>

      <potager-button
        v-if="value && submitButton"
        :is-loading="isLoading"
        class="m-0 rounded-l-none"
        is-square
        size="small"
        theme="go-green"
        type="submit">
        Ok
      </potager-button>
    </div>
  </potager-form>
</template>

<script>
import { mapGetters } from 'vuex';

import PotagerForm from 'UI/Form/PotagerForm';
import PotagerButton from 'UI/PotagerButton';
import PotagerInput from 'UI/Form/PotagerInput';

import IconStyleTwoPinMarker from 'Icons/regular/IconStyleTwoPinMarker';

export default {
  components: {
    PotagerInput,
    PotagerForm,
    PotagerButton,
    IconStyleTwoPinMarker,
  },

  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    submitButton: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['onSubmit'],

  data() {
    return {
      value: null,
    };
  },

  computed: {
    ...mapGetters('app', [
      'getHomeDeliveryByRegionId',
    ]),
    ...mapGetters('session', [
      'isLoggedIn',
      'getRegionId',
    ]),
    regionId() {
      return this.$potagerRoute?.params?.regionId || this.getRegionId;
    },
    postalCodes() {
      const postalCodes = this.getHomeDeliveryByRegionId(this.regionId)?.postalCodes;
      return postalCodes?.length && [
        {
          value: null,
          label: 'Votre code postal',
          disabled: true,
        },
        ...postalCodes
          .map((e) => ({
            value: e.postalCode,
            label: `${e.postalCode} - ${e.city}`,
          })),
      ];
    },
  },

  methods: {
    onReset() {
      this.value = '';
    },

    submit() {
      const found = this.getHomeDeliveryByRegionId(this.regionId)
        ?.postalCodes
        .find((e) => e.postalCode.toString() === this.value.toString());
      if (found) {
        this.$emit('onSubmit', {
          ...found,
          regionId: this.regionId,
        });
      }
    },
  },
};
</script>
