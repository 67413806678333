<template>
  <section class="why-become-delivery-point">

    <potager-wave
      :height="['15.625rem', '3rem']"
      :index="9"
      :modifiers="['on-bottom', 'invert-y']" />

    <potager-container id="pourquoi-devenir-partenaire">

      <h2 class="why-become-delivery-point__title">
        Pourquoi devenir point relais pour Potager City ?
      </h2>
      <p class="why-become-delivery-point__text">
        En devenant point relais partenaire de Potager City, vous attirerez de nouveaux clients dans votre enseigne et
        doperez vos ventes. L'installation est gratuite et il n'y a pas d'engagement. De plus, nous vous offrons jusqu'à
        un panier "Fruits et Légumes".
      </p>

      <potager-slider
        name="why-become-delivery-point"
        :items="items"
        :slides-per-view="{
          default: 1,
          1024: 3,
        }"
        navigation
        pagination>
        <template v-slot:default="vProps">
          <article class="why-become-delivery-point__list__item">
            <!-- <img
            :src="item.image"
            :alt="item.alt"
            :title="item.name"> -->
            <potager-picture
              :src="vProps.item.image"
              :title="vProps.item.alt"
              placeholder="bg-beryl-green" />
            <div class="why-become-delivery-point__list__item__text">
              <p class="why-become-delivery-point__list__item__name">
                {{ vProps.item.name }}
              </p>

              <p class="why-become-delivery-point__list__item__city">
                {{ vProps.item.city }}
              </p>
            </div>
          </article>
        </template>
      </potager-slider>

    </potager-container>

  </section>
</template>

<script>
import PotagerContainer from 'UI/PotagerContainer';
import PotagerWave from 'UI/PotagerWave';
import PotagerSlider from 'UI/PotagerSlider';
import PotagerPicture from 'UI/PotagerPicture';

export default {

  components: {
    PotagerContainer,
    PotagerWave,
    PotagerSlider,
    PotagerPicture,
  },

  data: () => ({
    items: [
      {
        name: 'C Gastronomie',
        city: 'Lyon',
        image: 'point-retrait-boutique-lyon',
        alt: 'C Gastronomie est devenu point relais de paniers de fruits et légumes',
      },
      {
        name: 'La petite chocolatière',
        city: 'Paris',
        image: 'point-retrait-boutique-paris',
        alt: 'La petite chocolatière est devenu point relais de paniers de fruits et légumes',
      },
      {
        name: 'Fromagerie Deruelle',
        city: 'Bordeaux',
        image: 'point-retrait-boutique-bordeaux',
        alt: 'Fromagerie Deruelle est devenu point relais de paniers de fruits et légumes',
      },
    ],
  }),

};
</script>
