<template>
<div
    v-if="recipe.loading"
    class="preparation h-6 w-1/3 skeleton mb-2" />

  <ul
    v-else-if="recipe"
    class="preparation">
    <li class="preparation__item">
      <icon-kitchenware-whisk class="preparation__item__icon" />
      {{ recipe.preparationTime }} min
    </li>
    <li class="preparation__item">
      <icon-pasta-plate-warm class="preparation__item__icon" />
      {{ recipe.cookTime }} min
    </li>
    <li class="preparation__item">
      <icon-restaurant-fork-knife class="preparation__item__icon" />
      {{ recipe.coverNumber }} pers.
    </li>
  </ul>
</template>

<script>
import IconKitchenwareWhisk from 'Icons/bold/IconKitchenwareWhisk';
import IconPastaPlateWarm from 'Icons/bold/IconPastaPlateWarm';
import IconRestaurantForkKnife from 'Icons/bold/IconRestaurantForkKnife';

export default {

  components: {
    IconKitchenwareWhisk,
    IconPastaPlateWarm,
    IconRestaurantForkKnife,
  },

  props: {
    recipe: {
      type: Object,
      required: false,
      default: null,
    },
  },

};
</script>
