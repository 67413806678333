import {
  ADD_POPUP,
  REMOVE_POPUP,
  SHOW_POPUP,
  HIDE_POPUP,
} from 'Stores/types/popupsMutationsTypes';

import {
  ADD_POPUP_ACTION,
  REMOVE_POPUP_ACTION,
  SHOW_POPUP_ACTION,
  OPEN_POPUP_ACTION,
  HIDE_POPUP_ACTION,
  CLOSE_POPUP_ACTION,
} from 'Stores/types/popupsActionsTypes';

import Cookies from 'js-cookie';

export default {
  namespaced: true,

  state: {
    popups: [],
    showedPopups: [],
  },

  mutations: {
    [ADD_POPUP](state, popup) {
      state.popups
        .push(popup);
    },
    [REMOVE_POPUP](state, name) {
      state.popups = state.popups
        .filter((popup) => popup.name !== name);
    },
    [SHOW_POPUP](state, name) {
      if (state.showedPopups.includes(name)) return;
      state.showedPopups.push(name);
    },
    [HIDE_POPUP](state, name) {
      state.showedPopups = state.showedPopups
        .filter((popupName) => popupName !== name);
    },
  },

  actions: {
    [ADD_POPUP_ACTION]({
      commit,
      dispatch
    }, {
      name,
      component,
      props,
      position,
      transition,
      transitionDuration,
      expiration,
    }) {
      commit(REMOVE_POPUP, name);

      commit(ADD_POPUP, {
        name,
        component,
        props,
        position,
        transition,
        transitionDuration,
        expiration,
        close: () => {
          dispatch(CLOSE_POPUP_ACTION, name);
        },
      });
    },
    [REMOVE_POPUP_ACTION]({ commit }, name) {
      commit(REMOVE_POPUP, name);
    },
    [SHOW_POPUP_ACTION]({ commit }, name) {
      commit(SHOW_POPUP, name);
    },
    [HIDE_POPUP_ACTION]({ commit }, name) {
      commit(HIDE_POPUP, name);
    },

    // USE THIS ACTION TO OPEN POPUP
    [OPEN_POPUP_ACTION]({ dispatch }, {
      name,
      component,
      props,
      position,
      transition = 'slide-bottom-to-bottom',
      transitionDuration = 300,
      expiration = 0,
    }) {
      dispatch(ADD_POPUP_ACTION, {
        name,
        component,
        props,
        position,
        transition,
        transitionDuration,
        expiration,
      });

      setTimeout(() => {
        dispatch(SHOW_POPUP_ACTION, name);
      }, transitionDuration);
    },
    // USE THIS ACTION TO CLOSE POPUP
    [CLOSE_POPUP_ACTION]({ dispatch }, name) {
      const popup = this.getters['popups/getPopupByName'](name);
      if (!popup) return;

      dispatch(HIDE_POPUP_ACTION, name);

      setTimeout(() => {
        dispatch(REMOVE_POPUP_ACTION, name);
        Cookies.set(name, 'true', {
          expires: popup.expiration,
          sameSite: 'Lax'
        });
      }, popup.transitionDuration);
    },
  },

  getters: {
    getPopupByName: (state) => (name) => state.popups
      .find((popup) => popup.name === name),
    getPopups: (state) => state.popups,
    getShowedPopups: (state, getters) =>
      // on affiche les popups qui ne sont pas dans les cookies
      state.showedPopups
        .filter((popupName) => !Cookies.get(popupName)),
  },
};
