import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

import Provider from 'Classes/social-providers';
import IconPtcyGoogle from 'Icons/color/IconPtcyGoogle';

export default new Provider({

  name: 'google',
  icon: IconPtcyGoogle,
  color: '#4285F4',

  getProviderInstance: () => {
    const instance = new firebase.auth.GoogleAuthProvider();
    instance.addScope('https://www.googleapis.com/auth/user.phonenumbers.read');
    instance.setCustomParameters({
      prompt: 'select_account',
    });
    return instance;
  },

  getLoginData: (data) => ({
    accessToken: data.credential.accessToken,
  }),

  getUserData: (data) => {
    const { profile } = data.additionalUserInfo;
    return {
      firstName: profile.given_name,
      lastName: profile.family_name,
      email: profile.email,
      googleId: profile.id,
    };
  },
});
