/**
 * Retrieves the backend error message based on the response and error code.
 *
 * @param {Object} response - The response object from the backend.
 * @param {string} code - The error code to look for in the response.
 * @returns {string|null} - The error message if found, otherwise null.
 */
export const getBackendErrorMessage = (response, code) => {
  const errors = response.errors?.globals;
  if (errors) {
    const error = errors.find(err => err.code === code);
    return error?.message || null;
  }
  return null;
};
