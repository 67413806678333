export default {
  name: 'repeat',

  valid(value, constraint, formValues) {
    const modelValue = formValues[constraint.options.model];
    if (!modelValue || modelValue === value) {
      return null;
    }

    return {
      field: constraint.field,
      message: 'Mot de passe non identique',
      code: null,
    };
  },
};
