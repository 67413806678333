import { POPIN_ERROR_EVENT } from 'Classes/Constants';

const DEFAULT_WORDING = `
  Vous ne pouvez pas choisir ce jour de retrait car il est actuellement complet.
  Veuillez sélectionner un autre jour et/ou un autre point de retrait.`;

export const BT021_UPDATE_SUBSCRIPTION_BASKET_ERROR = {
  eventType: POPIN_ERROR_EVENT,
  text: DEFAULT_WORDING,
  closeBtnText: 'Changer mon point de retrait',
  onCloseRedirect: { name: 'tunnel-delivery-type-step1' },
};

export default {
  default: {
    text: DEFAULT_WORDING,
  },
  'TemporaryCustomerApi.addBasketSubscription': BT021_UPDATE_SUBSCRIPTION_BASKET_ERROR,
  'UserApi.addSubscriptionToSubscriptionBasket': BT021_UPDATE_SUBSCRIPTION_BASKET_ERROR,
  'UserApi.setSubscriptionProduct': BT021_UPDATE_SUBSCRIPTION_BASKET_ERROR,
};
