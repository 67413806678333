<template>
  <panel
    :back-route="{ name: 'mon-compte' }"
    title="Paramètres & confidentialité">
    <div class="panel-section">
      <potager-navigator
        v-for="link in settingsLinks"
        :key="link.label"
        :to="link.route">
        {{ link.label }}
      </potager-navigator>

      <potager-navigator @click="toggleCookiesSettings">
        Paramètres des cookies
      </potager-navigator>
    </div>
  </panel>
</template>

<script>
import Panel from 'Components/panel/Panel';
import PotagerNavigator from 'UI/PotagerNavigator';
import MetaInfosService from 'Classes/services/MetaInfoService';

export const settingsLinks = [
  {
    route: { name: 'mentions-legales' },
    label: 'Mentions légales',
    shortLabel: 'Mentions légales',
  },
  {
    route: { name: 'professional-equality-index' },
    label: 'Index égalité professionnelle',
    shortLabel: 'Index égalité',
  },
  {
    route: 'https://potagercity.ethicspoint.com/',
    label: 'Alerte éthique',
    shortLabel: 'Alerte éthique',
  },
  {
    route: { name: 'cgv' },
    label: 'Conditions générales de vente',
    shortLabel: 'CGV',
  },
  {
    route: { name: 'confidentialite-et-donnees-personnelles' },
    label: 'Confidentialité et données personnelles',
    shortLabel: 'Confidentialité et données',
  },
  {
    route: { name: 'cookies-page' },
    label: 'Politique sur les cookies',
    shortLabel: 'Politique cookies',
  }
];

export default {
  components: {
    Panel,
    PotagerNavigator,
  },

  data() {
    return {
      settingsLinks,
    };
  },

  methods: {
    toggleCookiesSettings() {
      if (typeof window.OneTrust?.ToggleInfoDisplay === 'function') {
        window.OneTrust.ToggleInfoDisplay();
      }
    },
  },

  head() {
    return MetaInfosService.generate({
      title: 'Paramètres',
    });
  },
};
</script>
