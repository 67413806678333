<template>
  <potager-modal>
    <template #title>
      JOUR FÉRIÉ VENDREDI 01/11
    </template>

    <template #body>
      <div class="flex flex-col gap-6 text-left">
        <p>
          Les livraisons du 1er novembre ne seront pas assurées, mais nous avons fait le maximum pour vous livrer cette même semaine.
        </p>

        <p class="font-bold">
          Vérifiez dans <potager-link
            :to="{name: 'mon-compte_orders'}"
            class="text-go-green underline">Mes commandes</potager-link> si votre commande de la semaine a été créée.
        </p>

        <p>
          <b>Cas 1 : Votre commande n’apparaît pas&nbsp;:</b><br>
          Nous n’avons pas pu décaler votre livraison dans votre point relais. Vous pouvez créer une nouvelle commande sur un autre point relais livré cette même semaine !
        </p>

        <p>
          <b>Cas 2 : Votre commande à été décalée&nbsp;:</b><br>
          Nous avons pu exceptionnellement décaler le jour de livraison dans votre point relais. Vérifiez dans votre commande si cela vous convient.
        </p>
      </div>
    </template>

    <template #footer>
      <potager-button
        :to="{name: 'mon-compte_orders'}"
        full-width
        theme="british-racing">
        Vérifier si ma commande a été créée
      </potager-button>
    </template>
  </potager-modal>
</template>

<script>
import PotagerModal from 'UI/PotagerModal';
import PotagerButton from 'UI/PotagerButton';

export default {
  name: 'ModalPublicHolidays',
  components: { PotagerButton, PotagerModal }
};
</script>
