<template>
  <div class="stack-panels-layer-section">
    <panel
      ref="panel"
      :transition="false"
      v-bind="$props"
      @panelClosed="onPanelClose">
      <slot name="header" />
      <slot />
      <slot name="footer" />
    </panel>
  </div>
</template>

<script>
import Panel, { props as panelProps } from 'Components/panel/Panel';

export default {
  components: {
    Panel,
  },

  props: panelProps,

  emits: ['panelClose'],

  methods: {
    onPanelClose() {
      this.$emit('panelClose');
    },
  },
};
</script>
